import React from 'react';

import { convertAmount } from '@monorepo/common';
import { useCurrencyTransform } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';

import styles from './index.module.css';

type TPreciseCell = CustomFC<{
  value: number;
  currencyName?: string;
  precise?: number;
  styled?: boolean;
}>;

const PreciseCell: TPreciseCell = ({
  value,
  precise = 2,
  styled = false,
  currencyName = ''
}) => {
  let decreased = false;
  let increased = false;

  if (styled && value) {
    decreased = value < 0;
    increased = value > 0;
  }

  const formatted = value.toFixed(precise);
  const convertedValue = convertAmount(formatted);
  const result = styled && value > 0 ? `+${convertedValue}` : convertedValue;
  const currencyTransformed = useCurrencyTransform(currencyName);
  const fullResult = currencyName ? `${result} ${currencyTransformed}` : result;
  return (
    <span
      className={`${styles.root} ${decreased && styles.decreased} ${
        increased && styles.increased
      }`}
    >
      {fullResult}
    </span>
  );
};

export default PreciseCell;
