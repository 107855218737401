import useGetPlayerInformation from '../useGetPlayerInformation';

const getCustomButtonData = ({ data }) =>
  data ? { data: data?.custom_button_id } : { data: null };

const useProfileCustomButton = () =>
  useGetPlayerInformation({
    selectFromResult: getCustomButtonData
  });

export default useProfileCustomButton;
