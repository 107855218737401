import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSignUpActions } from '@monorepo/auth';

const useSaveFormFields = () => {
  const { watch } = useFormContext();
  const fields = watch();
  const { setSignUpData } = useSignUpActions();
  const name = JSON.stringify(fields);

  useEffect(() => {
    setSignUpData(fields);
  }, [name]);
};
export default useSaveFormFields;
