import { TAddChatInfoRequest } from '../../../types';

const addChatInfo = () => ({
  query: ({ roomId, avatarUrl }: TAddChatInfoRequest) => ({
    url: `/chat_info/${roomId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: { avatar_url: avatarUrl }
  })
});

export default addChatInfo;
