import { TRawTransactions, TWithdrawalsHistory } from '../../../../types';

const withdrawalsHistoryAdapter = (
  history: TRawTransactions
): TWithdrawalsHistory[] =>
  history.map(
    ({
      amount,
      id,
      date,
      paid_time,
      payment_system_name,
      name,
      is_cancelable,
      status,
      payment_info
    }) => ({
      date,
      id,
      amount,
      statusCode: status,
      paidTime: paid_time,
      statusName: name,
      paymentSystemName: payment_system_name,
      isCancelable: is_cancelable,
      paymentInfo: payment_info
    })
  );

export default withdrawalsHistoryAdapter;
