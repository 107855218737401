import jid from '@xmpp/jid';

const isJIDsEqual = (
  firstJid: string | jid.JID,
  secondJid: string | jid.JID,
  withResource?: boolean
) => {
  const firstAddr = typeof firstJid === 'string' ? jid(firstJid) : firstJid;
  const secondAddr = typeof secondJid === 'string' ? jid(secondJid) : secondJid;

  if (withResource) return firstAddr.equals(secondAddr);

  return firstAddr.bare().equals(secondAddr.bare());
};

export default isJIDsEqual;
