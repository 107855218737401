import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_COOKIES_SETTINGS } from '../constants';
import { TCookies } from '../types';

type TState = {
  isLimitVisited: boolean;
  timeForVerifyNotification: number | null;
  timeForLimitNotification: number | null;
  timeToRealityCheckNotification: number | null;
  timeForLogoutFreeze: number | null;
  timeForEndSMSTimer: number | null;
  remainTimeForVerify: number;
  remainTimeForLogoutFreeze: number;
  isShowCookiesSettings: boolean;
  cookies: TCookies;
};

export const userNotifications = createSlice({
  name: 'userNotifications',
  initialState: {
    isLimitVisited: false,
    timeForVerifyNotification: 0,
    timeForLimitNotification: 0,
    timeToRealityCheckNotification: 0,
    timeForLogoutFreeze: 0,
    remainTimeForVerify: 0,
    remainTimeForLogoutFreeze: 0,
    timeForEndSMSTimer: 0,
    isShowCookiesSettings: false,
    cookies: DEFAULT_COOKIES_SETTINGS
  } as TState,
  reducers: {
    setShowCookiesSettings: (state) => {
      state.isShowCookiesSettings = true;
      return state;
    },
    setHideCookiesSettings: (state) => {
      state.isShowCookiesSettings = false;
      return state;
    },
    setCookiesSettings: (state, { payload }) => {
      const { alwaysActive, targetingData, perfomanceData } = payload;
      if (
        alwaysActive === null &&
        targetingData === null &&
        perfomanceData === null
      ) {
        state.cookies = DEFAULT_COOKIES_SETTINGS;
        return state;
      }
      state.cookies = payload;
      return state;
    },
    setCleareCookiesSettings: (state) => {
      state.cookies = DEFAULT_COOKIES_SETTINGS;
      state.isShowCookiesSettings = false;
      return state;
    },
    setIsLimitVisited: (state, { payload }) => {
      state.isLimitVisited = payload;
      return state;
    },
    setTimeForEndSMSTimer: (state, { payload }) => {
      state.timeForEndSMSTimer = payload;
      return state;
    },
    setRemainTimeForVerify: (state, { payload }) => {
      state.remainTimeForVerify = payload;
      return state;
    },
    setRemainTimeForLogoutFreeze: (state, { payload }) => {
      state.remainTimeForLogoutFreeze = payload;
      return state;
    },
    setTimeForLimitNotification: (state, { payload }) => {
      state.timeForLimitNotification = payload;
      return state;
    },
    setTimeForVerifyNotification: (state, { payload }) => {
      state.timeForVerifyNotification = payload;
      return state;
    },
    setTimeToRealityCheckNotification: (state, { payload }) => {
      state.timeToRealityCheckNotification = payload;
      return state;
    },
    setTimeForLogoutFreeze: (state, { payload }) => {
      state.timeForLogoutFreeze = payload;
      return state;
    },
    setClearNotifications: (state) => {
      state.isLimitVisited = false;
      state.timeForLimitNotification = 0;
      state.timeForVerifyNotification = 0;
      state.timeToRealityCheckNotification = 0;
      state.timeForLogoutFreeze = 0;
      state.remainTimeForVerify = 0;
    },
    setClearTimeForSMSTimer: (state) => {
      state.timeForEndSMSTimer = 0;
    },
    setClearLogout: (state) => {
      state.timeToRealityCheckNotification = 0;
    },
    setClearLogoutFreeze: (state) => {
      state.timeForLogoutFreeze = 0;
      state.remainTimeForLogoutFreeze = 0;
    }
  }
});

// Action creators are generated for each case reducer function
export const { actions } = userNotifications;

export default userNotifications.reducer;
