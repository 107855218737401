import get from 'lodash/get';

import { SPORTS_QUERY_KEYS } from '../../../constants';

const getSuggestedGames = () => ({
  query: () => ({
    body: {
      command: 'get_suggested_bets',
      params: {
        ab_tests: 0
      }
    }
  }),
  providesTags: [SPORTS_QUERY_KEYS.SUGGESTED_GAMES],
  transformResponse(response: any): null | number {
    return get(response, 'data', null);
  }
});

export default getSuggestedGames;
