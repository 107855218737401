import { camelizeKeys } from 'humps';

import { useGetPartnerConfig, useGetUser } from '@monorepo/auth';
import { useSiteSettings } from '@monorepo/site-settings';

import {
  useGetJackpotPoolMetaDataQuery,
  useSubscribeToJackpotQuery
} from '../../store/casinoJackpots';
import {
  TPoolMetaDataDataResponse,
  TSubscribeToJackpotData
} from '../../types';
import getCombinedJackpotData from '../../utils/getCombinedJackpotData';

const useGetDefaultProvidersData = (jackpotId: number) => {
  const { siteId } = useSiteSettings();
  const { data: profile } = useGetUser();
  const { currencyName } = profile || {};
  const { data: partnerConfig } = useGetPartnerConfig();
  const { currency: partnerCurrency } = partnerConfig || {};
  const { data: metaData, isFetching: isFetching1 } =
    useGetJackpotPoolMetaDataQuery(
      {
        partnerId: siteId,
        currency: currencyName || partnerCurrency,
        jackpotId
      },
      { skip: !jackpotId || !currencyName }
    );

  const { data: subscribedData, isFetching: isFetching2 } =
    useSubscribeToJackpotQuery(
      {
        jackpotId,
        playerId: siteId,
        currency: currencyName || partnerCurrency,
        intensity: 0
      },
      { skip: !jackpotId }
    );

  const fullJackpotData = getCombinedJackpotData(
    subscribedData as TSubscribeToJackpotData,
    metaData as TPoolMetaDataDataResponse[]
  );

  return camelizeKeys({
    data: { ...fullJackpotData },
    isFetching: isFetching1 || isFetching2
  });
};

export default useGetDefaultProvidersData;
