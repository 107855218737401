import React, { ReactElement } from 'react';

import { useSearchParamsWithParsing } from '@monorepo/helpers/src';
import { NAMESPACES, useI18n } from '@monorepo/i18n/src';
import { CustomFC } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit/src';

import PreviewItem from './components/PreviewItem';

import styles from './index.module.css';

type TCategory = 'wallet' | 'bets';

type TFiltersPreviewProps = {
  filterTypes: any[];
  filterCategory: TCategory;
};

const FiltersPreview: CustomFC<TFiltersPreviewProps> = ({
  filterTypes,
  filterCategory
}): ReactElement => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { searchParams } = useSearchParamsWithParsing();
  const {
    category = '',
    operationType = '',
    id = '',
    period = '',
    types = '',
    fromDate,
    toDate
  } = searchParams;

  const range = `${fromDate} - ${toDate}`;
  const timeRange = period ? `${period} hours` : range;
  const actualType = filterCategory === 'wallet' ? operationType : types;
  const currentType =
    filterTypes.find(
      ({ value }: Record<string, string>) => value === actualType
    ) || filterTypes[0];

  const timeValue = filterCategory === 'wallet' ? range : timeRange;
  const timeLabel =
    filterCategory === 'wallet'
      ? t(`${filterCategory}.range_label`)
      : t(`${filterCategory}.period_label`);

  const typeValue =
    filterCategory === 'wallet'
      ? t(
          `${filterCategory}.transactiontype.${currentType?.label.toLowerCase()}`
        )
      : t(`${filterCategory}.transactiontype.${currentType.label}`);

  const dataField =
    filterCategory === 'wallet'
      ? t(`${filterCategory}.categories.${category.toLowerCase()}`)
      : id;
  const dataFieldLabel =
    filterCategory === 'wallet'
      ? t(`${filterCategory}.category_label`)
      : t(`${filterCategory}.ID_label`);

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.root}>
      <PreviewItem value={dataField} label={dataFieldLabel} />
      <PreviewItem
        value={typeValue}
        label={t(`${filterCategory}.type_label`)}
      />
      <PreviewItem value={timeValue} label={timeLabel} />
    </FlexBox>
  );
};

export default FiltersPreview;
