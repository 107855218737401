import React, { ChangeEvent, useRef } from 'react';

import { useDebounce } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { Input, InputProps } from '@monorepo/ui-kit';

type Props = {
  onChange: (value: string) => void;
  defaultValue?: string;
} & Omit<InputProps, 'onClean' | 'onChange' | 'defaultValue' | 'ref'>;

const SearchInput: Component<Props> = ({
  onChange,
  defaultValue = '',
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      onChange('');
    }
  };

  const handleSearch = useDebounce((e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value)
  );

  return (
    <Input
      ref={inputRef}
      onChange={handleSearch}
      defaultValue={defaultValue}
      onClean={handleClearSearch}
      {...props}
    />
  );
};

export default SearchInput;
