import Element from 'ltx/src/Element';

import { mamMessageParse } from '../../../../utils/stanzaParsers';
import MessageHandler from '../MessageHandler';

class MamMessageHandler {
  messageHandler: MessageHandler;

  constructor(handler: MessageHandler) {
    this.messageHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { addMessage } = this.messageHandler.reduxActions;
    const message = mamMessageParse(stanza, this.messageHandler.jidString);

    if (message?.text) {
      addMessage(message);
    }
  };
}

export default MamMessageHandler;
