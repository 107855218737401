import uniq from 'lodash/uniq';
import {
  BET_STATUS_RESULT,
  BET_TYPE_RESULT,
  BET_VALUES,
  BETSLIP_TYPE,
  MARKET_DISPLAY_KEYS,
  ODD_MODE,
  ODD_TYPE,
  ODD_TYPES,
  OUTCOME_STATUS_RESULT,
  OUTCOMES,
  OUTCOMES_VALUES,
  PRICE_DELTA,
  SPORT_ALIAS_WITH_DRAW
} from '../constants';
import { MARKET_LAYOUTS } from '../store/api/selectors/marketsSelector';
import {
  TAddBetFunction,
  TBetInfo,
  TBets,
  TFormattedBet,
  TGameInfo
} from '../types';

import nameFormatter from './nameFormatter';

export const formatIconNameFromAlias = (iconName = '') =>
  iconName.replaceAll(' ', '-').toLowerCase();

export const marketBaseNameFromDisplayKey = (displayKey: string): string => {
  if (displayKey === MARKET_DISPLAY_KEYS.HANDICAP) {
    return 'H';
  }
  if (displayKey === MARKET_DISPLAY_KEYS.TOTALS) {
    return 'T';
  }
  return '';
};

export const createBetInfoCollector =
  (game: any, market: any, actionFn: any): TAddBetFunction =>
  (eventId, price: number, eventType, eventName, base) => {
    // todo maybe delete cause deprecated
    const {
      id: gameId,
      startTs,
      team1Name,
      team2Name,
      textInfo,
      isBlocked,
      isLive
    } = game;

    const { name: marketName, type: marketType } = market;

    const betInfo = {
      gameId,
      startTs,
      team1Name,
      team2Name,
      textInfo,
      isBlocked,
      isLive,
      marketName,
      marketType,
      base,
      price,
      eventName,
      eventType,
      eventId,
      isDeleted: false,
      error: ''
    };

    actionFn(betInfo);
  };

export const getDefaultBetsAmount = (
  bets: TBetInfo[],
  amount: number,
  currentValues: Record<string, number> = {},
  type?: BETSLIP_TYPE
) =>
  bets.reduce((acc, bet) => {
    const { gameId, eventId } = bet;
    if (type === BETSLIP_TYPE.MULTIPLE) {
      return {
        ...acc,
        [BETSLIP_TYPE.MULTIPLE]: currentValues[BETSLIP_TYPE.MULTIPLE] || amount
      };
    }
    return {
      ...acc,
      [eventId]: currentValues[eventId] || amount
    };
  }, {});

export const toFixed = (num: number, fixed: number): string | undefined =>
  // const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed || -1}})?`);
  // return num.toString().match(re)?.[0];
  num.toFixed(fixed);

export const formatScore = (score1: string, score2?: string) =>
  `${score1}${score2 || Number.isInteger(score2) ? `:${score2}` : ''}`;

export const formatLiveTeamScore = (
  team1Value: string | null,
  team2Value: string | null
) => {
  if (!team1Value && !team2Value) {
    return '';
  }
  return ` ${team1Value}${team2Value ? ` : ${team2Value}` : ''}`;
};

export const formatGameTime = (info?: TGameInfo) => {
  if (info) {
    const { currentGameTime, addMinutes } = info;

    return currentGameTime
      ? ` ${currentGameTime}'${
          addMinutes && (currentGameTime === '45' || currentGameTime === '90')
            ? `(+${addMinutes}')`
            : ''
        }`
      : '';
  }
  return '';
};

const formatScoreSet = (
  scoreSet: Record<string, any>,
  isSingleTeam: boolean
) => {
  if (scoreSet) {
    const { team1Value, team2Value } = scoreSet;
    return isSingleTeam
      ? `(${formatScore(team1Value)})`
      : `(${formatScore(team1Value, team2Value)})`;
  }
  return '';
};

const formatScoreSets = (
  stats: Record<string, any>,
  isSingleTeam: boolean,
  key = 1
): string => {
  const scoreSet = stats[`scoreSet${key}`];
  if (scoreSet) {
    const nextSet = formatScoreSets(stats, isSingleTeam, key + 1);
    return `${formatScoreSet(scoreSet, isSingleTeam)}${
      nextSet ? `, ${nextSet}` : ''
    }`;
  }
  return '';
};

export const formatGameScoreSets = (
  stats?: Record<string, any>,
  isSingleTeam = false
) => {
  if (stats) {
    return formatScoreSets(stats, isSingleTeam);
  }
  return '';
};

export const getOutcomeString = (value: number): string =>
  OUTCOMES[value as keyof typeof OUTCOMES] || '';

export const getOddTypeString = (value: number | null): string => {
  if (value === null) return ODD_TYPES.DECIMAL;
  return ODD_TYPE[value as keyof typeof ODD_TYPE] || '';
};

export const getBetType = (value: number): string =>
  BET_TYPE_RESULT[value as keyof typeof BET_TYPE_RESULT] || '';

export const getTypeClassName = (value: number): string =>
  OUTCOME_STATUS_RESULT[value as keyof typeof OUTCOME_STATUS_RESULT] ||
  'notResulted';

export const getTypeBetClassName = (value: number): string =>
  BET_STATUS_RESULT[value as keyof typeof BET_STATUS_RESULT] || 'notResulted';

export const betFormat = (bets: TBets[]): TFormattedBet[] =>
  bets.map((bet) => {
    const { type, oddType } = bet;
    const betType = getBetType(type);
    const oddTypeString = getOddTypeString(oddType);

    return {
      betType,
      oddTypeString,
      ...bet
    };
  });

export const isSportWithDraw = (sportAlias: string) =>
  SPORT_ALIAS_WITH_DRAW.includes(sportAlias?.toLowerCase());

export const getMarketLabel = ({
  name,
  base = '',
  marketType,
  team1Name,
  team2Name
}: {
  name: string;
  base?: string;
  withLabel: boolean;
  marketType: string;
  team1Name?: string;
  team2Name?: string;
}): string => {
  const paramOutcome =
    marketType?.toLowerCase().includes('handicap') && parseFloat(base) > 0
      ? `+${base}`
      : base;
  const nameRegex = new RegExp(/W[1,2]/, 'g');

  if (marketType?.toLowerCase().includes('timeandtotalgoals')) {
    return `${name} ${paramOutcome}`;
  }

  if (name?.match(nameRegex)) {
    return nameFormatter(name, team1Name, team2Name);
  }

  if (paramOutcome === '') {
    return name;
  }

  return paramOutcome ?? name;
};

export const getTranslateKey = (
  oddMode: number,
  isChanged: boolean,
  changedBets: TBetInfo[]
) => {
  const isCoeficientGoDown = changedBets.some(
    (bet) => bet.priceDelta === PRICE_DELTA.DOWN
  );

  const isIncreased =
    isChanged && isCoeficientGoDown && oddMode === ODD_MODE.INCREASED;
  const alwaysAsk = isChanged && oddMode === ODD_MODE.NO_CHANGE;

  if (alwaysAsk || isIncreased) return 'sportsbook.betslip.placebetwithchanges';
  return 'sportsbook.betslip.placebet';
};

export const getOutcomeStatusIcon = (
  outcome: number,
  isEvent: boolean = false
): string => {
  if (outcome === OUTCOMES_VALUES.WON) {
    return isEvent ? 'check_filter-1' : 'won';
  }
  if (outcome === OUTCOMES_VALUES.LOST) {
    return isEvent ? 'close_0' : 'lost';
  }
  if (outcome === OUTCOMES_VALUES.NOT_RESULTED) {
    return 'question';
  }
  if (outcome === OUTCOMES_VALUES.WON_RETURN) {
    return isEvent ? 'win-return' : 'cashed-out';
  }
  if (outcome === OUTCOMES_VALUES.LOST_RETURN) {
    return isEvent ? 'lost-return' : 'cashed-out';
  }
  if (outcome === OUTCOMES_VALUES.RETURNED) {
    return 'returned-bet-1';
  }
  return isEvent ? 'question-2' : 'question';
};

export const getBetStatusIcon = (
  outcome: number,
  isEvent: boolean = false
): string => {
  if (outcome === BET_VALUES.WON) {
    return isEvent ? 'check_filter-1' : 'won';
  }
  if (outcome === BET_VALUES.LOST) {
    return isEvent ? 'close_0' : 'lost';
  }
  if (outcome === BET_VALUES.NOT_RESULTED) {
    return 'question';
  }
  if (outcome === BET_VALUES.CASHED_OUT) {
    return 'cashed-out';
  }
  if (outcome === BET_VALUES.RETURNED) {
    return 'returned-bet-1';
  }
  return isEvent ? 'question-2' : 'question';
};

export const getMarketLayout = (marketType: string) => {
  if (['CorrectScore'].includes(marketType)) {
    return MARKET_LAYOUTS.COLUMNS;
  }
  return MARKET_LAYOUTS.GENERIC;
};

export const checkAllElementsAreNotNull = (
  arr: number[] | string[]
): boolean => {
  return arr.every(function (i) {
    return i !== null;
  });
};

export const checkAllUniqueElementsOffArray = (
  arr: number[] | string[]
): boolean => {
  if (arr.length === 1) return true;
  const unique = uniq(arr);
  return unique.length === arr.length && checkAllElementsAreNotNull(arr);
};

export const convertTotalOdds = (value: number, decimals: number): string => {
  const formatted = value.toFixed(decimals);

  const ensureTwoDecimals = (str: string) =>
    str.match(/\.\d$/) ? `${str}0` : str;

  if (decimals > 2) {
    const formattedValue = formatted
      .replace(/(\.[0-9]*[1-9])0+$/, '$1')
      .replace(/\.0+$/, '.00');
    return ensureTwoDecimals(formattedValue);
  }

  return formatted;
};
