import Element from 'ltx/src/Element';

import PresenceHandler from '../../handlers/PresenceHandler';

const presenceStanzaCallback =
  (presenceHandler: PresenceHandler) => (stanza: Element) => {
    if (stanza.is('presence')) {
      return presenceHandler.processStanza(stanza);
    }
    return stanza;
  };

export default presenceStanzaCallback;
