import { Context, createContext } from 'react';

import { XMPPClient } from '../../utils/instance';

type TXMPPContext = {
  xmppInstance: XMPPClient;
  isServerOnline: boolean;
};

export const initialState: TXMPPContext = {
  xmppInstance: {} as XMPPClient,
  isServerOnline: false
};

const XMPPContext: Context<TXMPPContext> = createContext(initialState);

export default XMPPContext;
