import React, { ReactElement } from 'react';

import { ComponentWithConfig } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import BannersCms from '../BannersCms';

import styles from './index.module.css';

type TBanners = {
  categoryId: number;
  visual?: string;
  withTutorials?: boolean;
};

const CasinoBannerCms: ComponentWithConfig<TBanners> = ({
  config
}): ReactElement => (
  <Box className={styles.topGame}>
    <BannersCms config={config} />
  </Box>
);

export default CasinoBannerCms;
