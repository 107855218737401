import Element from 'ltx/src/Element';

import { IQ_TYPE, XML_NAMESPACES } from '@monorepo/xmpp';

import { MAM_QUERY_STATUS } from '../../../../constants';
import { parseRsm, parseXDada } from '../../../../utils/stanzaParsers';
import IqHandler from '../IqHandler';

class IqMamSentHandler {
  iqHandler: IqHandler;

  constructor(handler: IqHandler) {
    this.iqHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { setMamQuery } = this.iqHandler.reduxActions;
    const { type, id, to } = stanza.attrs;
    if (type === IQ_TYPE.SET) {
      const query = stanza.getChild('query');
      const { queryid: queryId } = query?.attrs || {};
      const xData = query?.getChild('x', XML_NAMESPACES.X_DATA);
      const { with: withJid = to } = parseXDada(xData);
      const set = query?.getChild('set', XML_NAMESPACES.RSM);
      const { max } = parseRsm(set);

      setMamQuery({
        id,
        queryId,
        withJid,
        max,
        status: MAM_QUERY_STATUS.LOADING
      });
    }
  };
}

export default IqMamSentHandler;
