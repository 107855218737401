import React from 'react';
import { NavLink } from 'react-router-dom';

import { Component } from '@monorepo/type';
import { Accordion, Box, TMenuItem } from '@monorepo/ui-kit';

import styles from './index.module.css';

const MenuMobile: Component<{
  items: TMenuItem[];
}> = ({ items = [] }) => (
  <Box>
    {items.map(({ title, items: subItems }) => (
      <Accordion
        className={styles.root}
        bodyClassName={styles.body}
        headerClassName={styles.header}
        buttonClassName={styles.button}
        isDefaultOpen
        label={title}
        key={`footer-menu${title}`}
      >
        {subItems?.map(({ title: titleLink, link, target = '' }) => (
          <NavLink
            to={link}
            target={target}
            className={styles.link}
            key={`footer-menu-link${link}`}
          >
            {titleLink}
          </NavLink>
        ))}
      </Accordion>
    ))}
  </Box>
);
export default MenuMobile;
