import {
  TGetJackpotsListRequest,
  TGetJackpotsListResponse
} from '../../../types';
import getJackpotsListAdapter from '../adapters/getJackpotsListAdapter';

const getJackpotsList = () => ({
  query: ({ partnerId, currency, sourceId }: TGetJackpotsListRequest) => ({
    body: {
      Command: 'getjackpots',
      Data: {
        PartnerId: partnerId,
        Currency: currency,
        ...(sourceId && { SourceId: sourceId })
      }
    }
  }),
  transformResponse(response: TGetJackpotsListResponse): number[] | [] {
    return !response ? [] : getJackpotsListAdapter(response);
  }
});
export default getJackpotsList;
