import React from 'react';

import { Component } from '@monorepo/type';
import { FLEX_DIRECTION, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import TeamSkeleton from '../Team/TeamSkeleton';

const TeamsSkeleton: Component = ({ className = '' }) => (
  <FlexBox
    direction={FLEX_DIRECTION.column}
    justify={FLEX_JUSTIFY.center}
    className={className}
  >
    <TeamSkeleton />
    <TeamSkeleton />
  </FlexBox>
);

export default TeamsSkeleton;
