import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const LanguageSwitcher = lazy(() => import('./LanguageSwitcher'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <LanguageSwitcher config={config} />
  </Suspense>
);
