import { CustomFC } from '@monorepo/type';

import CustomNotification from '../components/CustomNotification';
import { TNotificationProps } from '../types';

export enum NOTIFICATION_TYPES {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  CUSTOM = 'CUSTOM'
}

export const COMPONENTS_MAP: Partial<
  Record<NOTIFICATION_TYPES, CustomFC<TNotificationProps>>
> = {
  CUSTOM: CustomNotification
};

export const NOTIFICATIONS_LIST_TYPES = {
  WHATS_NEW: 'whats_new',
  HEADER_INFO: 'header_info',
  MESSAGES: 'messages',
  SOCIAL: 'social'
};

export const LIMIT_HEADER_NOTIFICATIONS = 3;

export const COOKIES_SETTINGS = {
  ALWAYSACTIVE: 'alwaysActive',
  PERFOMANCEDATA: 'perfomanceData',
  TARGETINGDATA: 'targetingData'
};

export const DEFAULT_COOKIES_SETTINGS = {
  alwaysActive: false,
  perfomanceData: false,
  targetingData: false,
  value: ''
};
