import { TAmountConverter } from '../../types';
import convertAmountHelper from '../convertAmountHelper';

const valuesQuantityValidator = (
  compareWith: () => string | number,
  message = 'validators.compareValidator',
  inverse: boolean,
  converter: TAmountConverter
) => ({
  validate: (fieldValue: string) => {
    const comparison =
      convertAmountHelper(fieldValue, converter) >
      convertAmountHelper(compareWith(), converter);
    return (inverse ? !comparison : comparison) ? message : null;
  }
});
export default valuesQuantityValidator;
