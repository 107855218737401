import { useGetStreamsQuery } from '../../store/sportsbook';

const useGetAllStreams = (options = {}) =>
  useGetStreamsQuery(undefined, {
    selectFromResult: (result) => ({
      isFetching: result?.isFetching,
      data: result?.data
    }),
    ...options
  });

export default useGetAllStreams;
