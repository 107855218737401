import React, { ChangeEvent } from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';

import Spinner from '../Spinner';

import styles from './index.module.css';

type Props = {
  id?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => any;
  inputClassName?: string;
  iconClassName?: string;
  disabled?: boolean;
  processing?: boolean;
};
const AvatarUpload: Component<Props> = ({
  onChange,
  id,
  className = '',
  inputClassName = '',
  iconClassName = '',
  disabled,
  processing,
  ...props
}) => (
  <label htmlFor={id} className={`${styles.wrap} ${className}`} {...props}>
    <input
      className={`${styles.input} ${inputClassName}`}
      type="file"
      accept=".png, .jpg, .jpeg"
      id={id}
      onChange={onChange}
      disabled={disabled || processing}
    />
    {processing ? (
      <Spinner className={`${styles.icon} ${iconClassName}`} />
    ) : (
      <Icon className={`${styles.icon} ${iconClassName}`} name="camera_0" />
    )}
  </label>
);

export default AvatarUpload;
