import SportsSuggestedItem from '../../components/SportsPopularSlider/components/SportsSuggestedItem';
import SportsRecommendedItem from '../SportsRecommendedItem';

import { SportSliderItem, SportsSliderItemType } from './types';

const sportsSliderItemsMap = {
  [SportsSliderItemType.Recommended]: SportsRecommendedItem,
  [SportsSliderItemType.Suggested]: SportsSuggestedItem
};

const getSportSliderItemByType = (
  type: SportsSliderItemType
): SportSliderItem => sportsSliderItemsMap[type];

export default getSportSliderItemByType;
