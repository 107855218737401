import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const RegistrationModal = lazy(() => import('./RegistrationModal'));

export default () => (
  <Suspense fallback={<DefaultSkeleton />}>
    <RegistrationModal />
  </Suspense>
);
