import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { classNameProp, CustomFC } from '@monorepo/type';
import { Button, BUTTON_INTENT, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import CategoryIcon from '../CategoryIcon';

import styles from './index.module.css';

type Props = {
  title: string;
  categoryId: string;
  totalCount?: number;
  isViewAll?: boolean;
  isWithButtons?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  goToCustomLink?: () => void;
  onPrev: () => void;
  onNext: () => void;
} & classNameProp;

const Header: CustomFC<Props> = ({
  title,
  categoryId,
  totalCount: allTotalCount = 0,
  isViewAll = false,
  isWithButtons = false,
  goToCustomLink = undefined,
  onPrev,
  onNext,
  isStart,
  isEnd,
  className = ''
}) => {
  const { t } = useI18n(NAMESPACES.CASINO);
  const { generalRoutes } = useSiteSettings();
  const { casinoCategory } = generalRoutes;
  const noButtons = isEnd && isStart;
  const { search } = useLocation();
  const totalCount = allTotalCount - 10;
  const titleLink =
    totalCount > 0
      ? t('gamesslider.viewall.count', { totalCount })
      : t('gamesslider.viewall');
  const allLink = goToCustomLink ? (
    <span className={styles.viewAll} onClick={goToCustomLink}>
      {titleLink}
      <Icon name="arrows" title="" className={styles.arrows} />
    </span>
  ) : (
    isViewAll && (
      <Link
        to={`${casinoCategory}/${categoryId}${search}`}
        className={styles.viewAll}
      >
        {titleLink}
        <Icon name="arrows" title="" className={styles.arrows} />
      </Link>
    )
  );
  return (
    <FlexBox
      className={`${styles.header} ${className}`}
      align={FLEX_ALIGN.center}
    >
      <FlexBox align={FLEX_ALIGN.center} className={styles.title}>
        <CategoryIcon categoryId={categoryId} className={styles.icon} />
        {title}
      </FlexBox>
      {allLink}
      {isWithButtons && !noButtons && (
        <FlexBox className={styles.buttons}>
          <Button
            onClick={onPrev}
            isOnlyIcon
            intent={BUTTON_INTENT.transparent}
            disabled={isStart}
            className={styles.button}
          >
            <Icon name="close_1" title="" />
          </Button>
          <Button
            onClick={onNext}
            isOnlyIcon
            intent={BUTTON_INTENT.transparent}
            disabled={isEnd}
            className={styles.button}
          >
            <Icon name="close_1" title="" className={styles.next} />
          </Button>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default Header;
