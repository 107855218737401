import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNavigateUnauthenticated } from '@monorepo/common';
import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  EllipsisText
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Config = {
  loginToRedirect?: string;
};

const HeaderUserMenuUnAuthorized: ComponentWithConfig<Config> = ({
  config
}) => {
  const { loginToRedirect } = config;
  const { t } = useI18n(NAMESPACES.COMMON);
  const navigate = useNavigate();
  const { navigateToLogin } = useNavigateUnauthenticated();
  const { isModalSignUp, generalRoutes } = useSiteSettings();
  const { registration } = generalRoutes;

  const handleLogin = useCallback(
    () => navigateToLogin(loginToRedirect),
    [loginToRedirect, navigateToLogin]
  );
  const { setSearchParamsObject } = useSearchParamsWithParsing();
  const handleSign = () => {
    if (isModalSignUp) {
      setSearchParamsObject({ signup: 'on' }, true);
    } else {
      navigate(registration);
    }
  };
  return (
    <>
      <Button
        intent={BUTTON_INTENT.tertiary}
        className={`${styles.button} ${styles.login}`}
        size={BUTTON_SIZE.h28}
        onClick={handleLogin}
        data-custom="login-button"
      >
        <EllipsisText>{t('headerusermenu.login')}</EllipsisText>
      </Button>
      <Button
        intent={BUTTON_INTENT.primary}
        className={`${styles.button} ${styles.signup}`}
        size={BUTTON_SIZE.h28}
        onClick={handleSign}
        data-custom="signup-button"
      >
        <EllipsisText>{t('headerusermenu.signup')}</EllipsisText>
      </Button>
    </>
  );
};

export default HeaderUserMenuUnAuthorized;
