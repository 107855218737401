import React from 'react';

import { PHONE_MASK } from '../../constants';
import { TFormField } from '../../types';
import MaskedField from '../MaskedField';

const MaskedPhone = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  props: TFormField<TFormValues>
) => <MaskedField<TFormValues> mask={PHONE_MASK} {...props} />;

export default MaskedPhone;
