import React from 'react';

import { Component } from '@monorepo/type';

import { FIELD_TONE } from '../../constants';
import { TStatusMark } from '../../types';
import Spinner from '../Spinner';

import styles from './index.module.css';

const StatusMark: Component<TStatusMark> = ({
  isHaveStatus = false,
  isActive = false,
  isPending = false,
  toneType = FIELD_TONE.dark,
  className = '',
  children
}) => {
  const activityClass = isActive ? styles.active : styles.inactive;
  const networkClass = isHaveStatus ? activityClass : styles.offline;
  const classes = `${styles.mark}
    ${styles[toneType]}
    ${isPending ? '' : networkClass}
    ${className}`;

  if (isPending) {
    return <Spinner className={classes} />;
  }

  return <div className={classes}>{children}</div>;
};

export default StatusMark;
