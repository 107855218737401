import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';

import { FAVORITE_TEAM_ALIAS } from '../../../../constants';
import SportMenuItem from '../SportMenuItem';

type Props = {
  className?: string;
};

const FavoriteTeam: Component<Props> = ({ className = '' }) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { prematch } = generalRoutes;
  const location = useLocation();

  const handleClick = useCallback(() => {
    navigate(`${prematch}/${FAVORITE_TEAM_ALIAS}`);
  }, []);

  return (
    <SportMenuItem
      iconName={FAVORITE_TEAM_ALIAS}
      name={t('sportsbook.favorite.team')}
      isActive={location.pathname === `${prematch}/${FAVORITE_TEAM_ALIAS}`}
      onClick={handleClick}
      className={className}
      alias=""
    />
  );
};

export default FavoriteTeam;
