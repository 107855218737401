import React from 'react';

import { usePageContext } from '@monorepo/page';

import BottomNav from '../../../BottomNav';

const BottomNavigation = ({ componentId = '' }) => {
  const { componentsMap } = usePageContext();
  const Component = componentsMap[componentId] || BottomNav;

  return <Component key={componentId} />;
};

export default BottomNavigation;
