import React from 'react';

import { usePageContext } from '@monorepo/page';
import { classNameProp, CustomFC } from '@monorepo/type';
import { FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import Column from '../Column';

import styles from './index.module.css';

const PersonalOffice: CustomFC<classNameProp> = ({ className = '' }) => {
  const { page } = usePageContext();
  if (page) {
    const { layout } = page;
    const { columns, type } = layout;

    return (
      <FlexBox
        className={`${styles.wrap} ${styles[type] || ''} ${className}`}
        justify={FLEX_JUSTIFY.center}
      >
        <div className={styles.grid}>
          <Column
            key={columns[0].join('/')}
            column={columns[0]}
            className={styles.column}
          />
          <Column
            key={columns[1].join('/')}
            column={columns[1]}
            className={styles.column}
          />
        </div>
      </FlexBox>
    );
  }

  return null;
};

export default PersonalOffice;
