export enum POST_MESSAGE_TYPE {
  EVENT_CHAT_INIT = 'social-iframe-eventChatInit',
  FRIENDS_LIST_INIT = 'social-iframe-friendsListInit',
  MESSENGER_INIT = 'social-iframe-messengerInit',
  CHANGE_LANGUAGE = 'social-iframe-changeLanguage'
}

export enum SENT_POST_MESSAGE_TYPE {
  FRAME_INIT = 'social-iframe-initiated',
  REDIRECT = 'social-iframe-redirect'
}

export enum MESSENGER_VIEW {
  PERSONAL = 'personal',
  COMMUNITY = 'community'
}

export enum SOCIAL_FRAME_NAME {
  FRIENDS_LIST = 'friendsList',
  MESSENGER = 'messenger',
  EVENT_CHAT = 'eventChat'
}

export const SOCIAL_FRAME_LIST = [
  SOCIAL_FRAME_NAME.FRIENDS_LIST,
  SOCIAL_FRAME_NAME.EVENT_CHAT,
  SOCIAL_FRAME_NAME.MESSENGER
];
