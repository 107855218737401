import React from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useGetUser } from '@monorepo/auth';
import { useGetPopups } from '@monorepo/cms';
import { getLocalStorageValue } from '@monorepo/helpers';
import { RegistrationPopup, RuntimePopup } from '@monorepo/notification';

const CMSPopups = () => {
  const { isMobile } = useDeviceDetector();
  const device = isMobile ? 1 : 0;
  const { data: regPopups } = useGetPopups('after_registration', device);
  const { data: runtimePopups } = useGetPopups('per_page', device);
  const { data } = useGetUser();
  const { id } = data || {};
  const userId = id?.toString();
  const blockedPopups =
    (userId
      ? getLocalStorageValue(`blockedPopups_${userId}`)
      : getLocalStorageValue(`blockedPopups_guest`)) || [];
  const notBlockedRunTimePopups = blockedPopups?.length
    ? runtimePopups?.filter((i) => blockedPopups?.indexOf(i.id) < 0)
    : runtimePopups || [];
  const notBlockedRegistrationPopups =
    id && blockedPopups?.length
      ? regPopups?.filter((i) => blockedPopups?.indexOf(i.id) < 0)
      : regPopups || [];

  return (
    <>
      {notBlockedRegistrationPopups?.map((popup) => (
        <RegistrationPopup popup={popup} key={popup.id} />
      ))}
      {notBlockedRunTimePopups?.map((popup) => (
        <RuntimePopup popup={popup} key={popup.id} />
      ))}
    </>
  );
};

export default CMSPopups;
