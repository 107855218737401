import React from 'react';

import { CustomFC } from '@monorepo/type';
import { Box, Skeleton } from '@monorepo/ui-kit';

type TDefaultSkeletonProps = {};

const DefaultSkeleton: CustomFC<TDefaultSkeletonProps> = () => (
  <Box>
    <Skeleton />
  </Box>
);

export default DefaultSkeleton;
