import { RefObject, useEffect } from 'react';

const useOnClickOutside = <
  Element extends HTMLElement = HTMLDivElement,
  Trigger extends HTMLElement = HTMLDivElement
>(
  ref: RefObject<Element>,
  handler: (e: Event) => void,
  triggerRef?: RefObject<Trigger>
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event?.target as Node)) {
        return;
      }
      if (
        triggerRef &&
        (!triggerRef.current ||
          triggerRef.current.contains(event?.target as Node))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [handler, ref]);
};

export default useOnClickOutside;
