import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import { Component, TActionsConfig, TQueryResult } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import useGetLineGames from '../../hooks/useGetLineGames';
import { selectSportRegionCompetitionOrderedGames } from '../../store/api/selectors';
import { TNormalizedSports } from '../../types';
import LineGameCard from '../LineGameCard';
import LineMarketsList from '../LineMarketsList';

import styles from './index.module.css';

type Props = {
  sportId: number;
  regionId: number;
  competitionId: number;
} & TActionsConfig;

const LineGamesList: Component<Props> = ({
  sportId,
  regionId,
  competitionId,
  className = ''
}) => {
  const { data: games } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectSportRegionCompetitionOrderedGames(
        result,
        sportId,
        regionId,
        competitionId
      )
    }))
  });

  if (isEmpty(games)) {
    return null;
  }

  return (
    <Box className={className}>
      {games.map((gameId: number) => (
        <LineGameCard
          key={gameId}
          gameId={gameId}
          sportId={sportId}
          regionId={regionId}
          competitionId={competitionId}
          className={styles.card}
        >
          <LineMarketsList
            competitionId={competitionId}
            sportId={sportId}
            regionId={regionId}
            gameId={gameId}
          />
        </LineGameCard>
      ))}
    </Box>
  );
};

export default LineGamesList;
