import { convertParams } from '@monorepo/helpers';

import {
  TGetGamesImagesRequest,
  TImages,
  TImagesResponse
} from '../../../types';
import getGamesImagesAdapter from '../adapters/getGamesImagesAdapter';

const getGamesImages = () => ({
  query: ({
    partnerId,
    isMobile,
    language,
    useWebp,
    country,
    onlyImages,
    externalIds
  }: TGetGamesImagesRequest) => {
    const params = {
      partner_id: partnerId,
      lang: language,
      is_mobile: isMobile,
      use_webp: useWebp,
      country,
      external_id: externalIds,
      only_images: onlyImages
    };
    return `getGames?${convertParams(params)}`;
  },
  transformResponse(response: TImagesResponse): TImages {
    return !response ? [] : getGamesImagesAdapter(response);
  }
});
export default getGamesImages;
