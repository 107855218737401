import { useMemo } from 'react';

import { useSiteSettings } from '@monorepo/site-settings';

import { TNormalizedEvent } from '../../types';

const useCreateGameLink = (
  competitionId: number,
  game?: Pick<TNormalizedEvent, 'isLive' | 'sportAlias' | 'regionAlias' | 'id'>,
  isLivePage: boolean = false
) => {
  const { generalRoutes } = useSiteSettings();
  const { prematch, live } = generalRoutes;

  return useMemo(() => {
    if (game && competitionId) {
      const { isLive, sportAlias, regionAlias, id } = game;
      if (sportAlias && regionAlias && competitionId && id) {
        const base = isLive || isLivePage ? live : prematch;
        return `${base}/${sportAlias}/${regionAlias}/${competitionId}/${id}`;
      }
    }
    return '';
  }, [game, competitionId, prematch, live]);
};

export default useCreateGameLink;
