import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { EditorAvatar, LIKE_TYPE } from '@monorepo/common';
import { formatUnixDate } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Box, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import { TPromotion, TPromotionsListConfig } from '../../types';
import PromotionInfo from '../PromotionInfo';
import PromotionTitle from '../PromotionTitle';

import styles from './index.module.css';

type Props = Pick<TPromotion, 'id' | 'description' | 'url' | 'createTime'> &
  TPromotionsListConfig;

const PromotionCard: Component<Props> = ({
  id,
  description,
  url,
  createTime,
  withLike,
  withComment,
  withStream,
  withShare,
  withFavorite,
  className = ''
}) => {
  const { generalRoutes } = useSiteSettings();
  const { promo } = generalRoutes;

  const date = useMemo(
    () =>
      createTime
        ? formatUnixDate(createTime, 'dd-MM-yyyy HH:mm:ss')
        : createTime,
    [createTime]
  );
  return (
    <Box className={`${styles.wrap} ${className}`}>
      <Link to={`${promo}/slots/${id}`} className={styles.link} />
      {url && (
        <Box className={styles.videoWrapper}>
          <video controls className={styles.videoPlayer}>
            <source src={url} type="video/mp4" />
          </video>
        </Box>
      )}
      <PromotionInfo
        className={styles.info}
        type={LIKE_TYPE.VIDEO}
        id={id}
        href={`${promo}/slots/${id}`}
        eventDate={date}
        withShare={withShare}
        withFavorite={withFavorite}
        withComment={withComment}
        withLike={withLike}
        withStream={withStream}
      />
      <FlexBox align={FLEX_ALIGN.center} className={styles.titleWrap}>
        <EditorAvatar className={styles.avatar} />
        <PromotionTitle className={styles.title} title={description} />
      </FlexBox>
    </Box>
  );
};

export default PromotionCard;
