import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';

import account from './translations/account.json';
import casino from './translations/casino.json';
import common from './translations/common.json';
import countries from './translations/countries.json';
import currencies from './translations/currencies.json';
import dynamic from './translations/dynamic.json';
import favorites from './translations/favorites.json';
import forms from './translations/forms.json';
import messenger from './translations/messenger.json';
import pages from './translations/pages.json';
import social from './translations/social.json';
import sportsbook from './translations/sportsbook.json';

const partnerId = process.env.REACT_APP_FORCE_PARTNER_ID;

const backendOptions = {
  loadPath: `{{lng}}/{{ns}}`,
  stringify: JSON.stringify,
  allowMultiLoading: false,
  requestOptions: {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'default'
  },
  parse: (data: string) => {
    const parsedData = JSON.parse(data);
    return { ...parsedData };
  },
  fetch: (url: string, payload: object) => {
    const [language, namespace] = url.split('/');
    return fetch(
      `/api/v1/translation?language=${language}&namespace=${namespace}&partner_id=${partnerId}`,
      {
        ...payload,
        method: 'GET'
      }
    );
  }
};

export const config = {
  react: {
    useSuspense: false
  },
  backend: backendOptions,
  debug: process.env.NODE_ENV !== 'production',
  partialBundledLanguages: true,
  compatibilityJSON: 'v4',
  ns: ['common'],
  keySeparator: '.',
  resources: {
    fallback: {
      common: {
        ...common
      },
      casino: {
        ...casino
      },
      forms: {
        ...forms
      },
      sportsbook: {
        ...sportsbook
      },
      account: {
        ...account
      },
      dynamic: {
        ...dynamic
      },
      countries: {
        ...countries
      },
      currencies: {
        ...currencies
      },
      pages: {
        ...pages
      },
      social: {
        ...social
      },
      messenger: {
        ...messenger
      },
      favorites: {
        ...favorites
      }
    }
  },
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  fallbackLng: 'fallback',
  fallbackNS: false,
  load: 'languageOnly'
};

i18n.use(Fetch).use(LanguageDetector).use(initReactI18next); // passes i18n down to react-i18next

export default i18n;
