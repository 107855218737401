import { BONUS_DATA } from '../../../constants';

const getBonusesHistory = () => ({
  query: ({ from, end }: { from: number; end: number }) => ({
    body: {
      command: 'get_client_bonus_requests',
      params: {
        from_date: from,
        to_date: end
      }
    }
  }),
  providesTags: [BONUS_DATA.BONUS_REQUEST]
});
export default getBonusesHistory;
