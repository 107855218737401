import React, { useMemo } from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { FlexBox, getTooltipDataAttributes } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  text?: string;
};

const LimitTooltip: Component<Props> = ({ text, className = '' }) => {
  const tooltipData = useMemo(
    () => getTooltipDataAttributes({ content: text, delayShow: 10 }),
    [text]
  );

  return (
    <FlexBox className={className} {...tooltipData}>
      <Icon name="question" className={styles.icon} />
    </FlexBox>
  );
};

export default LimitTooltip;
