import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useClaimBonusMutation } from '../../store/user';

const useClaimBonus = () => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { successNotification, errorNotification } = useNotifications();
  const [claimBonus, { isLoading, isError }] = useClaimBonusMutation();

  const formatError = (code: number) => `error.${code}`;

  const onErrorDefault = (code: number) => {
    const formattedError = formatError(code);
    errorNotification(t(formattedError || ''));
  };

  const onSuccess = () => {
    successNotification(t('bonus.claimed'));
  };

  const onClaimBonus = async (partnerBonusId: number, bonusId: number) => {
    const response = await claimBonus({ partnerBonusId, bonusId });
    const { data = {} } = response;
    if (data?.result) {
      onErrorDefault(data.result);
    } else {
      onSuccess();
    }
  };

  return { onClaimBonus, isLoading, isError, formatError };
};

export default useClaimBonus;
