import React, { useRef } from 'react';
import { ValidationMode } from 'react-hook-form';

import { Form } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT, BUTTON_SIZE } from '@monorepo/ui-kit';

import useEditProfile from '../../hooks/useEditProfile';
import useGetUser from '../../hooks/useGetUser';
import { TProfileRequest } from '../../types';
import SubmitButton from '../SubmitButton';

import styles from './index.module.css';

type TFormProps = {
  children: React.ReactNode;
  hideSubmitButton?: boolean;
  hideUndoChanges?: boolean;
  defaultValues?: {};
  mode?: keyof ValidationMode;
};

const EditProfileForm = ({
  hideSubmitButton,
  hideUndoChanges,
  defaultValues = {},
  mode = 'onChange',
  children
}: TFormProps) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const formContextRef = useRef(null);
  const { data: profile } = useGetUser();

  const onReset = () => formContextRef.current?.reset();

  const { onEditProfile, isLoading } = useEditProfile();

  const save = (data: TProfileRequest) => {
    onEditProfile(data, formContextRef);
  };

  if (!profile) {
    return null;
  }

  return (
    <Form<TProfileRequest>
      onSubmit={save}
      formSettings={{
        defaultValues: { ...profile, ...defaultValues },
        mode
      }}
      ref={formContextRef}
    >
      {children}
      {!hideSubmitButton && (
        <SubmitButton processing={isLoading} mode={mode}>
          {t('updateprofile.form.submit')}
        </SubmitButton>
      )}
      {!hideUndoChanges && (
        <Button
          intent={BUTTON_INTENT.tertiary}
          onClick={onReset}
          className={styles.undoChanges}
          size={BUTTON_SIZE.h30}
        >
          {t('updateprofile.form.undochanges')}
        </Button>
      )}
    </Form>
  );
};

export default EditProfileForm;
