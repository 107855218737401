import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const EventTitleWrapper = lazy(() => import('./EventTitleWrapper'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <EventTitleWrapper {...props} />
  </Suspense>
);
