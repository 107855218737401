import regExpValidator from './regExpValidator';

const lettersRegExpValidator = (
  message: string = 'validators.letters.format'
) => {
  const regexp = new RegExp(/^\p{L}+$/gu);

  return regExpValidator(regexp, message);
};

export default lettersRegExpValidator;
