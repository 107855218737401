import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const ChatsRightEmpty = lazy(() => import('./ChatsRightEmpty'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ChatsRightEmpty config={config} />
  </Suspense>
);
