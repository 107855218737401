import React from 'react';

import { Icon } from '@monorepo/icons';
import { classNameProp, CustomFC } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import GameTitle from '../GameTitle';

import styles from './index.module.css';

type Props = {
  name: string;
} & classNameProp;

const GamePlaceholder: CustomFC<Props> = ({ className = '', name }) => (
  <FlexBox
    className={`${styles.wrapper} ${className}`}
    align={FLEX_ALIGN.center}
    direction={FLEX_DIRECTION.column}
  >
    <FlexBox className={styles.header} align={FLEX_ALIGN.center}>
      <GameTitle className={styles.title}>{name}</GameTitle>
    </FlexBox>
    <FlexBox
      className={styles.image}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
    >
      <Icon className={styles.icon} name="roulette_2" title="" />
    </FlexBox>
  </FlexBox>
);

export default GamePlaceholder;
