import React from 'react';

import { Component } from '@monorepo/type';
import { Box, PaddingWrapper } from '@monorepo/ui-kit';

import styles from './index.module.css';

const Wrapper: Component = ({ children }) => (
  <PaddingWrapper isOnlyMobile className={styles.root}>
    <Box>{children}</Box>
  </PaddingWrapper>
);

export default Wrapper;
