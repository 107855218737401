import React from 'react';

import { useComponentsConfig } from '@monorepo/page';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox, Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {};

const MenuItemSkeleton: Component<Props> = ({ className = '' }) => {
  const { withIcons } = useComponentsConfig<{
    withIcons?: boolean;
  }>();

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      className={`${styles.wrap} ${className}`}
    >
      {withIcons && <Skeleton className={styles.icon} />}
      <Skeleton className={styles.title} />
    </FlexBox>
  );
};

export default MenuItemSkeleton;
