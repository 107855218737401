import React from 'react';
import { Link } from 'react-router-dom';

import { AVATAR_SIZE } from '@monorepo/files';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  Box,
  FIELD_TONE,
  NotificationLayout,
  NotificationText,
  TEXT_SIZE
} from '@monorepo/ui-kit';
import { getIdFromJID } from '@monorepo/xmpp';

import { SOCIAL_NOTIFICATION_TYPE } from '../../constants';
import useSelectRoomInbox from '../../hooks/selectors/useSelectRoomInbox';
import { TNotificationProps } from '../../types';
import FriendAvatar from '../FriendAvatar';

import CommunityTitle from './components/CommunityTitle';
import ContactTitle from './components/ContactTitle';

import styles from './index.module.css';

const UnreadMessageNotification: Component<TNotificationProps> = ({
  roomJid,
  userJid,
  type,
  closeToast,
  className = '',
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small
}) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { profile, personalChats, communityChats } = generalRoutes;
  const isCommunity =
    type === SOCIAL_NOTIFICATION_TYPE.COMMUNITY_UNREAD_MESSAGE;
  const userId = getIdFromJID(userJid);
  const roomId = getIdFromJID(roomJid);
  const item = useSelectRoomInbox(roomJid);
  const { unread: count = '', text = '' } = item || {};

  const friendLink = `${profile}/${userId}`;
  const roomLink = `${isCommunity ? communityChats : personalChats}/${roomId}`;

  const handleClick = () => {
    if (closeToast) {
      closeToast();
    }
  };

  return (
    <NotificationLayout
      className={`${styles.wrap} ${className}`}
      image={
        <Link to={friendLink} className={styles.avatarWrap}>
          <FriendAvatar
            id={userId}
            className={styles.avatar}
            avatarClass={styles.avatarImg}
            isWithStatus={false}
            size={AVATAR_SIZE.S}
          />
        </Link>
      }
    >
      {isCommunity ? (
        <CommunityTitle
          roomId={roomId}
          userId={userId}
          className={styles.head}
          toneType={toneType}
          textSize={textSize}
        />
      ) : (
        <ContactTitle
          userId={userId}
          className={styles.head}
          toneType={toneType}
          textSize={textSize}
        />
      )}
      <Link to={roomLink} className={styles.content} onClick={handleClick}>
        <NotificationText
          className={`${styles[textSize]} ${styles[toneType]} ${styles.text}`}
        >
          {text}
        </NotificationText>
        <Box
          className={`${styles[textSize]} ${styles[toneType]} ${styles.link}`}
        >
          {t('notification.unread_message.link')}
          {Number(count) > 1 && (
            <span className={styles.count}>{`(${count})`}</span>
          )}
        </Box>
      </Link>
    </NotificationLayout>
  );
};

export default UnreadMessageNotification;
