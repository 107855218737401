import { TListRange } from '@monorepo/common';

const getNextRangePeriod = (page: number | null, pagesList: TListRange[]) => {
  const arrLength = pagesList.length - 1;

  if (!pagesList.length || page === null) {
    return { nextFromDate: '', nextToDate: '' };
  }
  const nextRangeIndex = page + 1;
  if (nextRangeIndex > arrLength) {
    return { nextFromDate: '', nextToDate: '' };
  }

  const nextPage = pagesList.filter((item) => item.page === nextRangeIndex);
  const [{ currentFromDate, currentToDate }] = nextPage;

  return {
    nextFromDate: currentFromDate,
    nextToDate: currentToDate
  };
};

export default getNextRangePeriod;
