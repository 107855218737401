import { useLocation } from 'react-router-dom';

import { useSiteSettings } from '@monorepo/site-settings';

import useGetUser from '../useGetUser';

const useIsRestricted = () => {
  const { data } = useGetUser();
  const location = useLocation();
  const { pathname } = location;
  const { parentOnlyShow } = useSiteSettings();
  return parentOnlyShow.includes(pathname) && data?.ownerId;
};

export default useIsRestricted;
