import React, { lazy, Suspense } from 'react';

import { ProfileSkeleton } from '@monorepo/common';

const EditProfileChangePassword = lazy(
  () => import('./EditProfileChangePassword')
);

export default ({ config }: any) => (
  <Suspense fallback={<ProfileSkeleton />}>
    <EditProfileChangePassword config={config} />
  </Suspense>
);
