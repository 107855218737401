import jid from '@xmpp/jid';

const parseJid = (address: string | jid.JID) => {
  const addr = typeof address === 'string' ? jid(address) : address;

  return {
    locale: addr.getLocal(),
    domain: addr.getDomain(),
    resource: addr.getResource()
  };
};

export default parseJid;
