import { createApi } from '@reduxjs/toolkit/query/react';

import { wsBaseQuery } from '@monorepo/websocket';

import { WALLET_QUERY_KEYS } from '../constants';
import {
  TGetPaymentServicesList,
  TMakeDeposit,
  TPaymentAction,
  TPaymentServices,
  TTransaction,
  TTransactionsHistoryRequest,
  TWithdrawableBalance,
  TWithdrawalsHistory,
  TWithdrawalsHistoryRequest
} from '../types';

import cancelWithdraw from './api/cancelWithdraw';
import checkClientForDeposit from './api/checkClientForDeposit';
import getPaymentServices from './api/getPaymentServices';
import getPaymentServicesList from './api/getPaymentServicesList';
import getTransactionsHistory from './api/getTransactionsHistory';
import getWithdrawableBalance from './api/getWithdrawableBalance';
import getWithdrawalsHistory from './api/getWithdrawalsHistory';
import makeDeposit from './api/makeDeposit';
import makeWithdraw from './api/makeWithdraw';

export const walletApi = createApi({
  reducerPath: 'walletApi',
  baseQuery: wsBaseQuery(),
  tagTypes: [
    WALLET_QUERY_KEYS.GET_PAYMENTS_DETAILS,
    WALLET_QUERY_KEYS.GET_PAYMENTS_LIST
  ],
  endpoints: (builder) => ({
    getTransactionsHistory: builder.query<
      TTransaction[],
      TTransactionsHistoryRequest
    >(getTransactionsHistory()),
    getWithdrawalsHistory: builder.query<
      TWithdrawalsHistory[],
      TWithdrawalsHistoryRequest
    >(getWithdrawalsHistory()),
    getPaymentServices: builder.query<TPaymentServices, undefined>(
      getPaymentServices()
    ),
    getPaymentServicesList: builder.query<TGetPaymentServicesList, undefined>(
      getPaymentServicesList()
    ),
    getWithdrawableBalance: builder.query<TWithdrawableBalance, undefined>(
      getWithdrawableBalance()
    ),
    makeDeposit: builder.mutation<TPaymentAction, TMakeDeposit>(makeDeposit()),
    makeWithdraw: builder.mutation<TPaymentAction, TMakeDeposit>(
      makeWithdraw()
    ),
    checkClientForDeposit: builder.query<any, any>(checkClientForDeposit()),
    cancelWithdraw: builder.mutation(cancelWithdraw())
  })
});

export const {
  useGetTransactionsHistoryQuery,
  useGetPaymentServicesQuery,
  useGetPaymentServicesListQuery,
  useMakeDepositMutation,
  useMakeWithdrawMutation,
  useGetWithdrawableBalanceQuery,
  useGetWithdrawalsHistoryQuery,
  useCheckClientForDepositQuery,
  useCancelWithdrawMutation
} = walletApi;
