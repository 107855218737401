import Element from 'ltx/src/Element';

import { MESSAGE_MARK } from '../../../../constants/messages';
import MessageHandler from '../MessageHandler';

class ReceivedMessageHandler {
  messageHandler: MessageHandler;

  constructor(handler: MessageHandler) {
    this.messageHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { updateMessage } = this.messageHandler.reduxActions;
    const received = stanza.getChild('received');
    const { id } = received?.attrs || {};

    updateMessage({ id, mark: MESSAGE_MARK.RECEIVED });
  };
}

export default ReceivedMessageHandler;
