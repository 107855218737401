import React from 'react';

import { CardsSkeletonList, useCountries } from '@monorepo/common';
import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Box,
  FLEX_DIRECTION,
  FlexBox,
  PaddingWrapper,
  UI_SIZES
} from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../constants';
import usePaymentServiceData from '../../hooks/usePaymentServiceData';
import AllCardsSystem from '../common/AllCardsSystem';
import CardSystem from '../common/CardSystem';
import DepositMenu from '../common/DepositMenu';
import EmptyMethod from '../common/EmptyMethod';
import PaymentMenu from '../PaymentMenu';

import styles from './index.module.css';

type TDeposit = {
  imageFromCms?: boolean;
  isWithProfileCheck?: boolean;
  defaultCountry: string;
  withdrawInfoPage?: string;
};
const Deposit: ComponentWithConfig<TDeposit> = ({ config }) => {
  const {
    imageFromCms = false,
    isWithProfileCheck = false,
    defaultCountry,
    withdrawInfoPage = ''
  } = config;
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const {
    isFetching,
    listData: list,
    systemId,
    setSystemId
  } = usePaymentServiceData(SERVICE_TYPES.DEPOSIT, true);

  const { searchParams } = useSearchParamsWithParsing();
  const { group = '' } = searchParams;
  const countriesFromJSON = useCountries();

  if (isFetching) {
    return (
      <CardsSkeletonList
        rootClassName={styles.root}
        skeletonClassName={styles.skeleton}
        size={3}
        itemClassName={styles.skeletonSlide}
      />
    );
  }

  if (list?.length) {
    const [defaultId] = list;

    return (
      <PaddingWrapper className={styles.wrap}>
        <Box>
          <FlexBox className={styles.root}>
            <FlexBox direction={FLEX_DIRECTION.column} className={styles.list}>
              <Box mb={UI_SIZES.m}>
                <PaymentMenu
                  serviceType={SERVICE_TYPES.DEPOSIT}
                  withdrawInfoPage={withdrawInfoPage}
                />
              </Box>
              {group === 'all' || !group ? (
                <AllCardsSystem
                  setDepositSystem={setSystemId}
                  serviceType={SERVICE_TYPES.DEPOSIT}
                  activeSystem={systemId}
                  imageFromCms={imageFromCms}
                />
              ) : (
                <CardSystem
                  setDepositSystem={setSystemId}
                  serviceType={SERVICE_TYPES.DEPOSIT}
                  activeSystem={systemId}
                  imageFromCms={imageFromCms}
                />
              )}
            </FlexBox>
            <DepositMenu
              id={systemId || defaultId}
              countriesFromJSON={countriesFromJSON}
              isWithProfileCheck={isWithProfileCheck}
              defaultCountry={defaultCountry}
            />
          </FlexBox>
        </Box>
      </PaddingWrapper>
    );
  }

  return (
    <PaddingWrapper className={styles.wrap}>
      <Box>
        <FlexBox className={`${styles.root} ${styles.empty}`}>
          <EmptyMethod
            head={t('wallet.payment.nodeposit.head')}
            text={t('wallet.payment.nodeposit.text')}
            supportButtonText={t('navigation.support')}
          />
        </FlexBox>
      </Box>
    </PaddingWrapper>
  );
};
export default Deposit;
