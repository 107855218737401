import { WALLETS_KEYS } from '../../../constants';

const getChildBalances = () => ({
  query: ({ currencyId }) => ({
    body: {
      command: 'get_child_session',
      params: {
        currency_id: currencyId
      }
    }
  }),
  invalidatesTags: [WALLETS_KEYS.BALANCES],
  transformResponse(rawResponse) {
    return rawResponse.details;
  }
});

export default getChildBalances;
