import { useEffect, useState } from 'react';

import { useSearchParamsWithParsing } from '@monorepo/helpers';

import { PAYMENT_GROUP_ALL, SERVICE_TYPES } from '../../constants';
import usePaymentServicesGroups from '../usePaymentServicesGroups';

const usePaymentServiceData = (
  serviceType: SERVICE_TYPES,
  withSetDefaultSystem: boolean = false
) => {
  const { isFetching, data } = usePaymentServicesGroups(serviceType);
  const { groupKeys } = data;
  const { searchParams } = useSearchParamsWithParsing();
  const { group = '' } = searchParams;
  const activeGroup = groupKeys.includes(group.toString())
    ? group.toString()
    : PAYMENT_GROUP_ALL;
  const listData = data[activeGroup as keyof typeof data];
  const [systemId, setSystemId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (withSetDefaultSystem && listData?.length) {
      const [defaultId] = listData as string[];
      if (!systemId || !listData.includes(systemId)) {
        setSystemId(defaultId);
      }
    }
  }, [listData]);

  return {
    isFetching,
    listData,
    groupKeys,
    activeGroup,
    systemId,
    setSystemId
  };
};
export default usePaymentServiceData;
