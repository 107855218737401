import React, { lazy, Suspense } from 'react';

import { ProfileSkeleton } from '@monorepo/common';

const EditProfilePersonalData = lazy(() => import('./EditProfilePersonalData'));

export default ({ config }: any) => (
  <Suspense fallback={<ProfileSkeleton />}>
    <EditProfilePersonalData config={config} />
  </Suspense>
);
