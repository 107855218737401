import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TCardsSkeleton = {
  size?: number;
  itemClassName?: string;
};

const CardsSkeleton = ({ size = 5, itemClassName = '' }: TCardsSkeleton) => (
  <>
    {createSkeletonArray(size).map((i) => (
      <Skeleton className={`${styles.skeleton} ${itemClassName}`} key={i} />
    ))}
  </>
);

export default CardsSkeleton;
