import differenceInYears from 'date-fns/differenceInYears';

const calculateAge = (birthDate: any) => {
  if (!birthDate) {
    return;
  }
  const splitDate = birthDate.split('-');
  const newDate =
    splitDate[0].length !== 4
      ? new Date(splitDate[2], splitDate[1], splitDate[0])
      : new Date(splitDate[0], splitDate[1], splitDate[2]);

  return differenceInYears(new Date(), newDate);
};

export default calculateAge;
