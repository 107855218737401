import React from 'react';

import styles from './index.module.css';

const UserBalanceLoader = () => (
  <div className={styles.loaderWrap}>
    <div className={styles.dots}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
    <div className={styles.loader} />
  </div>
);
export default UserBalanceLoader;
