import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';

import Outcome from '../Outcome';
import SportsBookButtonSkeleton from '../SportsBookButton/SportsBookButtonSkeleton';

type ItemProps = { isFetching?: boolean; isSuspended?: boolean };

const ItemComponent: Component<ItemProps> = ({
  isFetching = false,
  isSuspended = false,
  className = ''
}) =>
  isFetching ? (
    <SportsBookButtonSkeleton className={className} />
  ) : (
    <Outcome isSuspended={isSuspended} isEmpty className={className} />
  );

type Props = {
  selectionCount?: number;
} & ItemProps;

const LineEmptyOutcomeList: Component<Props> = ({
  className = '',
  isFetching = false,
  isSuspended = false,
  selectionCount = 2
}) => {
  const array = createSkeletonArray(selectionCount);

  return (
    <>
      {array.map((key) => (
        <ItemComponent
          key={key}
          isSuspended={isSuspended}
          isFetching={isFetching}
          className={className}
        />
      ))}
    </>
  );
};

export default LineEmptyOutcomeList;
