import React, { MouseEvent } from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { useIsActiveFavoriteId, useUpdateFavoritesIds } from '@monorepo/common';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TFavoriteButtonProps = {
  id: string | number;
  type: string;
  size?: 'small' | 'medium' | 'big' | 'large';
  className?: string;
  dataCustom?: string;
};

const BaseFavoriteButton: Component<TFavoriteButtonProps> = ({
  id,
  type,
  size = 'big',
  className = '',
  dataCustom = ''
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { onRemoveFavIds, onSaveFavIds } = useUpdateFavoritesIds();

  const isActive = useIsActiveFavoriteId(id as string, type as string);
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (isActive) {
      onRemoveFavIds(id, type);
    } else {
      onSaveFavIds(id, type);
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box onClick={handleClick} className={className} data-custom={dataCustom}>
      <Icon
        name={isActive ? 'star_3' : 'star_3_1'}
        className={`${styles.icon} ${styles[size]} ${
          isActive ? styles.active : ''
        }`}
      />
    </Box>
  );
};

export default BaseFavoriteButton;
