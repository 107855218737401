import React, { memo, useCallback } from 'react';
import { memoize } from 'proxy-memoize';

import { Component } from '@monorepo/type';

import useGetMenuSports from '../../hooks/useGetMenuSports';
import { selectSportBySportAlias } from '../../store/api/selectors';
import BackButton from '../BackButton';
import BackButtonSkeleton from '../BackButton/BackButtonSkeleton';

type TBackButtonProps = {
  activeSport: string;
  handleClick: (sport: string) => void;
};

const SportBackHeader: Component<TBackButtonProps> = ({
  activeSport,
  className,
  handleClick
}) => {
  const { data: sportName, isLoading } = useGetMenuSports(undefined, {
    selectFromResult: memoize((result: any) => ({
      isLoading: result?.isLoading,
      data: selectSportBySportAlias(result, activeSport)?.name
    }))
  });

  const onClick = useCallback(() => {
    handleClick('');
  }, [handleClick]);

  if (isLoading) {
    return <BackButtonSkeleton />;
  }

  return (
    <BackButton sportName={sportName} onClick={onClick} className={className} />
  );
};

export default memo(SportBackHeader);
