import React from 'react';

import { ModalWrapper } from '@monorepo/notification';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_DIRECTION,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const ConfirmSwitch = ({
  header,
  confirmText,
  goToLink,
  confirmButton,
  closeText,
  handleHide,
  confirmRef
}: {
  header: string;
  confirmText: string;
  closeText: string;
  confirmButton: string;
  goToLink: () => void;
  handleHide: () => void;
  confirmRef: any;
}) => (
  <ModalWrapper
    classOverlayModal={styles.overlay}
    header={header}
    titleClassName={styles.title}
    handleClose={handleHide}
    ref={confirmRef}
  >
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.modal}>
      <Box className={styles.text}>{confirmText}</Box>
      <FlexBox className={styles.control} direction={FLEX_DIRECTION.column}>
        <Button
          className={styles.button}
          size={BUTTON_SIZE.h30}
          onClick={goToLink}
        >
          {confirmButton}
        </Button>
      </FlexBox>
    </FlexBox>
  </ModalWrapper>
);

export default ConfirmSwitch;
