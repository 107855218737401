import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

type TProps = {
  id: string;
  onChange: (e: any) => any;
  className: string;
  inputClassName?: string;
  title?: string;
  accept?: string;
};
const UploadInput: ForwardRefRenderFunction<any, TProps> = (
  {
    onChange,
    id,
    className,
    inputClassName,
    title,
    accept = '.png, .jpg, .jpeg, .gif'
  },
  ref
) => (
  <label htmlFor={id} className="custom-file-upload">
    <input
      className={className}
      type="file"
      accept={accept}
      id={id}
      ref={ref}
      onChange={onChange}
    />
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={inputClassName}
    >
      {title}
    </FlexBox>
  </label>
);

export default forwardRef(UploadInput);
