import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TGetRecommendedGamesRequest } from '../types';

import getGamesIds from './api/getGamesIds';

const casinoRecommenderHost = process.env.REACT_APP_RECOMMENDER_HOST;

export const casinoRecommenderApi = createApi({
  reducerPath: 'casinoRecommenderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${casinoRecommenderHost}/api/v3/recommendations-casino/partners/`
  }),
  endpoints: (builder) => ({
    getGamesIds: builder.query<number[], TGetRecommendedGamesRequest>(
      getGamesIds()
    )
  })
});

export const { useGetGamesIdsQuery } = casinoRecommenderApi;
