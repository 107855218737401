import { AUTH_QUERY_KEYS } from '../../../constants';
import { TBonusCardData } from '../../../types';
import freeSpinsGameAdapter from '../adapters/freeSpinsGameAdapter';

const getFreeSpins = () => ({
  query: (id) => ({
    body: {
      command: 'get_bonus_definition_games',
      params: { bonus_id: id }
    }
  }),
  providesTags: [AUTH_QUERY_KEYS.FREESPINS_GAMES],
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },

  transformResponse: (response: TBonusCardData): Promise<any> | any =>
    freeSpinsGameAdapter(response)
});
export default getFreeSpins;
