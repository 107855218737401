import { LAYOUT_TYPE } from '@monorepo/page';
import { CustomFC } from '@monorepo/type';

import AuthPage from '../components/AuthPage';
import Chats from '../components/Chats';
import MultiColumn from '../components/MultiColumn';
import OneColumn from '../components/OneColumn';
import OneColumnSide from '../components/OneColumnSide';
import PersonalOffice from '../components/PersonalOffice';
import SportPage from '../components/SportPage';
import WithSidebar from '../components/WithSidebar';

const layoutsMap: { [type: string]: CustomFC } = {
  [LAYOUT_TYPE.ONE_COLUMN]: OneColumn,
  [LAYOUT_TYPE.ONE_COLUMN_SIDE]: OneColumnSide,
  [LAYOUT_TYPE.TWO_COLUMN_50X50]: MultiColumn,
  [LAYOUT_TYPE.TWO_COLUMN_FLUID]: MultiColumn,
  [LAYOUT_TYPE.PERSONAL_OFFICE]: PersonalOffice,
  [LAYOUT_TYPE.AUTH_PAGE]: AuthPage,
  [LAYOUT_TYPE.SPORT_PAGE]: SportPage,
  [LAYOUT_TYPE.WITH_SIDEBAR]: WithSidebar,
  [LAYOUT_TYPE.CHATS]: Chats
};

export default layoutsMap;
