const unsubscribe = () => ({
  query: (subid) => ({
    body: {
      command: 'unsubscribe',
      params: {
        subid
      }
    }
  })
});
export default unsubscribe;
