import React, { useCallback } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';

import SportMenuItem from '../SportMenuItem';

type Props = {
  activeSport: string;
  setSport: (sportAlias: string) => void;
};

const FavoriteItem: Component<Props> = ({
  className = '',
  activeSport,
  setSport
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  const handleClick = useCallback(() => setSport('favorite'), [setSport]);

  return (
    <SportMenuItem
      iconName="favorite"
      name={t('sportsbook.favorite')}
      isActive={activeSport === 'favorite'}
      onClick={handleClick}
      className={className}
    />
  );
};

export default FavoriteItem;
