import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

import { FIELD_TONE } from '../../constants';

import styles from './index.module.css';

type TPopper = {
  children: ReactNode;
  isShowPopper: boolean;
  toneType?: FIELD_TONE.dark | FIELD_TONE.light;
  classWrapper?: string;
  classInner?: string;
  popperClass?: string;
};

const Popper: ForwardRefRenderFunction<HTMLDivElement, TPopper> = (
  {
    children,
    isShowPopper,
    toneType = FIELD_TONE.dark,
    classWrapper = '',
    classInner = '',
    popperClass = ''
  },
  ref
) => {
  const wrapperClasses = `${styles.wrapper} ${classWrapper}`;
  const innerClasses = `${styles.inner}
  ${styles[toneType]}
  ${popperClass}
  ${classInner}`;

  if (!isShowPopper) return null;

  return (
    <div className={wrapperClasses} ref={ref}>
      <div className={innerClasses}>{children}</div>
    </div>
  );
};

export default forwardRef(Popper);
