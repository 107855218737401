import React from 'react';

import { usePageContext } from '@monorepo/page';

import Header from '../../../Header';

const HeaderNavigation = ({ componentId = '' }) => {
  const { componentsMap } = usePageContext();
  const Component = componentsMap[componentId] || Header;

  return <Component key={componentId} />;
};
export default HeaderNavigation;
