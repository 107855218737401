import { useGetNotifications, useSelectNotificationsData } from '@monorepo/cms';
import { useCountSocialNotifications } from '@monorepo/socials';

import xor from 'lodash/xor';
import has from 'lodash/has';

import useGetUnreadMessages from '../useGetUnreadMessages';

const useCountNotifications = () => {
  const count = useCountSocialNotifications();
  const { data: data1 = [] } = useGetNotifications('header_info');
  const headerInfoNotificationIdx = data1.map(
    (notification) => notification.id
  );
  const { data: data2 = [] } = useGetNotifications('whats_new');
  const whatsNewNotificationIdx = data2.map((notification) => notification.id);
  const { unreadMessages = [] } = useGetUnreadMessages();
  const readNotificationData = useSelectNotificationsData();
  const headerInfoNotificationUnreadMessagesIdx = has(
    readNotificationData,
    'header_info'
  )
    ? xor(headerInfoNotificationIdx, readNotificationData.header_info)
    : data1;
  const whatsNewNotificationUnreadMessagesIdx = has(
    readNotificationData,
    'whats_new'
  )
    ? xor(whatsNewNotificationIdx, readNotificationData.whats_new)
    : data2;

  return {
    header_info: headerInfoNotificationUnreadMessagesIdx.length,
    whats_new: whatsNewNotificationUnreadMessagesIdx.length,
    messages: unreadMessages.length,
    social: count,
    totalCount:
      count +
      headerInfoNotificationUnreadMessagesIdx.length +
      whatsNewNotificationUnreadMessagesIdx.length +
      unreadMessages.length
  };
};

export default useCountNotifications;
