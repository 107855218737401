import { useIsAuthenticated } from '@monorepo/auth';

import { useGetBetsCountQuery } from '../../store/sportsbook';

const useGetBetsCount = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const { data } = useGetBetsCountQuery(undefined, {
    skip: !isAuthenticated
  });
  return data || null;
};

export default useGetBetsCount;
