import { skipToken } from '@reduxjs/toolkit/query/react';

import { useGetUserQuery } from '../../store/user';
import useIsAuthenticated from '../useIsAuthenticated';

const useGetUser = () => {
  const { isAuthenticated } = useIsAuthenticated();
  return useGetUserQuery(isAuthenticated ? undefined : skipToken, {
    selectFromResult: (result) => ({
      isLoading: result?.isLoading,
      isFetching: result?.isFetching,
      data: result?.data?.profile
    })
  });
};

export default useGetUser;
