import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetGameRequest } from '../../../types';
import { normalizeMarketGroupsResponse } from '../adapters/marketGroupsAdapter';
import marketGroupsUpdater from '../updaters/marketGroupsUpdater';

const getMarketGroups = (api) => ({
  query: ({ id }: TGetGameRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          market: ['group_id', 'group_name', 'group_order']
        },
        where: {
          game: {
            id
          }
        },
        subscribe: true
      }
    }
  }),
  providesTags: [SPORTS_QUERY_KEYS.MARKET_GROUPS],
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => marketGroupsUpdater(draft, newData));
        },
        () =>
          dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.MARKET_GROUPS]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse: normalizeMarketGroupsResponse
});
export default getMarketGroups;
