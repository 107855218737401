import React from 'react';

import { Component } from '@monorepo/type';
import { Box, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../../../constants';
import usePaymentService from '../../../../../hooks/usePaymentService';
import PaymentImage from '../../../PaymentImage';

import styles from '../../index.module.css';

type Props = {
  id: string;
  onClick: (id: string) => void;
  serviceType: SERVICE_TYPES;
  imageFromCms?: boolean;
};

const Item: Component<Props> = ({
  id,
  serviceType,
  className = '',
  imageFromCms = false,
  onClick
}) => {
  const { system } = usePaymentService(serviceType, id);

  const handleClick = () => {
    onClick(id);
  };

  if (system) {
    const { displayName, PaymentSystemIconUrl: imgSrc } = system;

    return (
      <FlexBox
        className={className}
        onClick={handleClick}
        justify={FLEX_JUSTIFY.between}
      >
        <PaymentImage
          imgSrc={imgSrc}
          displayName={displayName}
          serviceId={id}
          imageFromCms={imageFromCms}
        />
        <FlexBox align={FLEX_ALIGN.center} className={styles.container}>
          <Box className={styles.name}>{displayName}</Box>
        </FlexBox>
      </FlexBox>
    );
  }

  return null;
};

export default Item;
