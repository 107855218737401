import Element from 'ltx/src/Element';

import { PRESENCE_TYPE, xmppInstance } from '@monorepo/xmpp';

import { isFromCommunity, isPresence } from '../../../utils/isStanzaHelper';
import { parseMucUserPresence } from '../../../utils/stanzaParsers';
import { socialsApi } from '../../socialsApi';

const streaming = () => ({
  queryFn: () => ({ data: {} }),
  keepUnusedDataFor: 1,
  async onCacheEntryAdded(arg: undefined, { dispatch, cacheEntryRemoved }) {
    const callback = (stanza: Element) => {
      if (isPresence(stanza) && isFromCommunity(stanza)) {
        // todo move to presence handler
        const presence = parseMucUserPresence(stanza);
        const { roomId, userId, type, nickname } = presence;
        if (type !== PRESENCE_TYPE.ERROR) {
          dispatch(
            socialsApi.util.updateQueryData(
              'roomPresence',
              { roomName: roomId, nickname: nickname || userId },
              (draft) => Object.assign(draft || {}, presence)
            )
          );
        }
      }
    };
    // if (!instance.isConnected()) {
    //   instance.connect();
    // }
    xmppInstance.createListener('stanza', callback);

    await cacheEntryRemoved;
    xmppInstance.removeListener('stanza', callback);
    // instance.stop();
  }
});

export default streaming;
