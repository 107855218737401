import React, { useCallback, useEffect, useRef } from 'react';
import getTime from 'date-fns/getTime';
import subSeconds from 'date-fns/subSeconds';

import { useGetUser } from '@monorepo/auth';
import { useSetTimeout } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  getNotificationsTime,
  ModalNotification,
  userNotificationActions,
  useSelectTimeLeftToVisitVerify
} from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';
import { TModalHandler } from '@monorepo/ui-kit';

const UserModals = () => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const {
    setTimeForVerifyNotification,
    setClearNotifications,
    setRemainTimeForVerify
  } = userNotificationActions();

  const { verifyTimeShow } = useSiteSettings();

  const currentDate = new Date();

  // Set default time for notifications when component mount
  const difForVerifyNotif = subSeconds(currentDate, -verifyTimeShow);

  const timeToShowVerify = useSelectTimeLeftToVisitVerify();

  const { data } = useGetUser();
  const { isVerified } = data || {};

  const { generalRoutes } = useSiteSettings();
  const { profilePersonalData } = generalRoutes;

  const verifyRef = useRef<TModalHandler>(null);

  const timeDifForVerify = getNotificationsTime(
    timeToShowVerify,
    isVerified,
    1.5
  );

  const handleOpenVerifyNotif = useCallback(() => {
    if (verifyRef?.current) {
      verifyRef.current.setIsShow(true);
      setRemainTimeForVerify(0);
    }
  }, []);

  const handleCloseVerifyNotif = useCallback((delay?: number) => {
    const current = new Date();
    const delayTime = delay ? -delay : -verifyTimeShow;
    const difference = subSeconds(current, delayTime);

    setTimeForVerifyNotification(getTime(difference));
    if (verifyRef?.current) {
      verifyRef.current.setIsShow(false);
      setRemainTimeForVerify(getTime(difference));
    }
  }, []);

  useEffect(() => {
    if (!timeToShowVerify)
      setTimeForVerifyNotification(getTime(difForVerifyNotif));

    return () => {
      setClearNotifications();
    };
  }, []);

  useSetTimeout(handleOpenVerifyNotif, timeDifForVerify, [timeToShowVerify]);

  return (
    <>
      {!isVerified && (
        <ModalNotification
          header={t('notifications.verification.reminder.header')}
          description={t('notifications.verification.reminder.description')}
          buttonText={t('notifications.verification.reminder.button')}
          handleClose={handleCloseVerifyNotif}
          ref={verifyRef}
          page={profilePersonalData}
        />
      )}
    </>
  );
};

export default UserModals;
