import React from 'react';

import { ComponentWithConfig } from '@monorepo/type';

import LineParametersProvider from '../../common/LineParametersProvider';
import SportsNavigationCommon from '../../common/SportNavigation';
import useGetRecommendedSportsGameIds from '../../hooks/useGetRecommendedSportsGameIds';
import { TSportMenuConfig } from '../../types';

const SportsRecommendedNavigation: ComponentWithConfig<
  TSportMenuConfig
> = () => {
  const { data: gameIds } = useGetRecommendedSportsGameIds();

  return (
    <LineParametersProvider params={{ gameIds }}>
      <SportsNavigationCommon />
    </LineParametersProvider>
  );
};

export default SportsRecommendedNavigation;
