import { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useGeoIP } from '@monorepo/cms';

import { useGetGamesByIdsQuery } from '../../store/casino';
import { TGetGamesRequest } from '../../types';
import useRequestParams from '../useRequestParams';

const useGetGamesByIds = (params: Pick<TGetGamesRequest, 'externalId'>) => {
  const requestParams = useRequestParams();
  const { data: dataGeoIP, isLoading } = useGeoIP();
  const [cachedParams, setCachedParams] = useState(params);

  const queryParams = {
    ...requestParams,
    ...cachedParams,
    country: dataGeoIP?.countryCode
  };

  const { data, ...result } = useGetGamesByIdsQuery(queryParams, {
    skip: !queryParams.externalId || isLoading
  });

  useEffect(() => {
    if (isEmpty(cachedParams.externalId) && !isEmpty(params.externalId)) {
      setCachedParams(params);
    }
  }, [params.externalId]);

  const filteredData = useMemo(
    () =>
      data?.filter((item) => (params.externalId || []).includes(item.gameId)),
    [data, params.externalId]
  );

  return { data: filteredData, ...result };
};

export default useGetGamesByIds;
