const checkClientForDeposit = () => ({
  query: () => ({
    body: {
      command: 'check_client_for_deposit',
      params: {}
    }
  }),
  transformResponse(response: any): any {
    return response; // TODO possible add or remove when fix problem with payment system
  }
});
export default checkClientForDeposit;
