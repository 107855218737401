import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TExternalParam } from '../../../types/userLocal';

const getExternalParam = (state: any) =>
  state[userLocalSlice.name].externalParam;

const useExternalParam = (): TExternalParam =>
  useTypedSelector(getExternalParam);

export default useExternalParam;
