import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FLEX_WRAP,
  FlexBox
} from '@monorepo/ui-kit';

import { PAYMENT_MAP, SERVICE_TYPES } from '../../../constants';
import usePaymentServicesGroups from '../../../hooks/usePaymentServicesGroups';
import Item from '../CardSystem/components/Item';

import styles from './index.module.css';

type Props = {
  setDepositSystem: React.Dispatch<React.SetStateAction<string | any>>;
  serviceType: SERVICE_TYPES;
  activeSystem?: string;
  isMobile?: boolean;
  imageFromCms?: boolean;
};

const AllCardsSystem: Component<Props> = ({
  setDepositSystem,
  serviceType,
  activeSystem,
  isMobile = false,
  imageFromCms = false
}) => {
  const { isFetching, data } = usePaymentServicesGroups(serviceType);
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { groupKeys } = data;

  if (isFetching) {
    return null;
  }

  return (
    <FlexBox
      wrap={FLEX_WRAP.wrap}
      align={FLEX_ALIGN.start}
      justify={FLEX_JUSTIFY.start}
      className={isMobile ? styles.mobileWrapper : styles.wrapper}
    >
      <>
        {groupKeys.map((id) => {
          if (id === 'all') {
            return null;
          }
          return (
            <FlexBox
              direction={FLEX_DIRECTION.column}
              className={styles.rowWrapper}
              key={id}
            >
              <FlexBox className={styles.title} direction={FLEX_DIRECTION.row}>
                {t(`wallet.payment.${PAYMENT_MAP[id].toLowerCase()}`)}
              </FlexBox>

              <FlexBox
                wrap={FLEX_WRAP.wrap}
                align={FLEX_ALIGN.start}
                justify={FLEX_JUSTIFY.start}
                className={isMobile ? styles.mobileRowWrapper : styles.wrapper}
              >
                {data[id as keyof typeof data].map((index) => (
                  <Item
                    serviceType={serviceType}
                    imageFromCms={imageFromCms}
                    id={index}
                    className={`${styles.item} ${
                      index === activeSystem ? styles.active : ''
                    }`}
                    key={index}
                    onClick={setDepositSystem}
                  />
                ))}
              </FlexBox>
            </FlexBox>
          );
        })}
      </>
    </FlexBox>
  );
};

export default AllCardsSystem;
