export const SYSTEM_UID = '00000000-0000-0000-0000-000000000000';

export const BOT_USERNAME = 'betbot';

export enum CHAT_TYPE {
  DIALOG = 'dialog',
  DIALOG_ENCRYPTED = 'dialog_encrypted',
  CHANNEL = 'channel',
  GROUP = 'group',
  GENERAL = 'general'
}

export enum CHAT_ROLE {
  OWNER = 'owner',
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  MEMBER = 'member',
  DIALOG_MEMBER = 'dialog_member',
  SUBSCRIBER = 'subscriber'
}

export const CHAT_ROLE_BADGE = {
  [CHAT_ROLE.OWNER]: '',
  [CHAT_ROLE.ADMIN]: '',
  [CHAT_ROLE.MODERATOR]: 'moderator',
  [CHAT_ROLE.MEMBER]: '',
  [CHAT_ROLE.DIALOG_MEMBER]: '',
  [CHAT_ROLE.SUBSCRIBER]: ''
};

export enum CHATS_ORDER {
  ASC = 'asc',
  DESC = 'desc'
}

export enum REPLY_ACTION {
  REPLY = 'reply',
  START_THREAD = 'start_thread'
}

export enum SERVICE_MSG_ACTION {
  PIN_MESSAGE = 'pin_message',
  NEW_CHAT = 'new_chat',
  RENAME_CHAT = 'rename_chat',
  AVATAR_UPDATE = 'avatar_update',
  ADD_MEMBER = 'add_member',
  UPDATE_MEMBER = 'update_member',
  BLOCK_MEMBER = 'block_member',
  CALL_STARTED = 'call_started',
  CALL_ENDED = 'call_ended'
}

export enum CHAT_MEMBER_ACTION {
  ADD = 'add_member',
  UPDATE = 'update_member',
  DELETE = 'delete_member'
}

export enum PIN_ACTION {
  PIN = 'pin',
  UNPIN = 'unpin'
}

export enum FRIENDSHIP_REQUEST_TYPE {
  SENT = 'sent',
  RECEIVED = 'received'
}

export enum FRIENDSHIP_REQUEST_ACTION {
  APPROVE = 'approve',
  DECLINE = 'decline'
}

export enum RESTRICTION {
  WRITE = 'write',
  ATTACHMENT = 'attachment',
  CALL = 'call'
}

export const ROLE_PRIORITY = {
  [CHAT_ROLE.OWNER]: 1,
  [CHAT_ROLE.ADMIN]: 2,
  [CHAT_ROLE.MODERATOR]: 3,
  [CHAT_ROLE.MEMBER]: 4,
  [CHAT_ROLE.DIALOG_MEMBER]: 5,
  [CHAT_ROLE.SUBSCRIBER]: 6
};

export enum CONFIRMATION {
  BLOCK_TO_WRITE = 'BLOCK_TO_WRITE',
  UNBLOCK_TO_WRITE = 'UNBLOCK_TO_WRITE',
  DELETE_MESSAGE = 'DELETE_MESSAGE'
}

export enum SELECT_MESSAGE_ACTION {
  SELECTED = 'selected',
  EDIT = 'edit',
  REPLY = 'reply'
}
