import { createApi } from '@reduxjs/toolkit/query/react';

import { wsJackpotApiQuery } from '@monorepo/websocket';

import {
  TExternalJackpots,
  TGetExternalJackpotsRequest,
  TGetJackpotPoolMetaDataRequest,
  TGetJackpotsListRequest,
  TPoolMetaDataDataResponse,
  TSubscribeToExternalJackpot,
  TSubscribeToExternalJackpotRequest,
  TSubscribeToJackpotData,
  TSubscribeToJackpotRequest
} from '../types';

import getExternalJackpotsList from './api/getExternalJackpotsList';
import getJackpotPoolMetaData from './api/getJackpotPoolMetaData';
import getJackpotsList from './api/getJackpotsList';
import subscribeToJackpot from './api/subcribeToJackpot';
import subscribeToExternalJackpot from './api/subscribeToExternalJackpot';
import unsubscribeFromJackpot from './api/unsubcribeFromJackpot';

export const casinoJackpots = createApi({
  reducerPath: 'casinoJackpots',
  baseQuery: wsJackpotApiQuery(),
  endpoints: (builder) => ({
    getJackpotsList: builder.query<number[] | [], TGetJackpotsListRequest>(
      getJackpotsList()
    ),
    unsubscribeFromJackpot: builder.mutation<any, string | number>(
      unsubscribeFromJackpot()
    ),
    getJackpotPoolMetaData: builder.query<
      TPoolMetaDataDataResponse[] | [],
      TGetJackpotPoolMetaDataRequest
    >(getJackpotPoolMetaData()),
    getExternalJackpotsList: builder.query<
      TExternalJackpots | [],
      TGetExternalJackpotsRequest
    >(getExternalJackpotsList())
  })
});

casinoJackpots.injectEndpoints({
  endpoints: (builder) => ({
    subscribeToJackpot: builder.query<
      TSubscribeToJackpotData | [],
      TSubscribeToJackpotRequest
    >(subscribeToJackpot(casinoJackpots)),
    subscribeToExternalJackpot: builder.query<
      TSubscribeToExternalJackpot | {},
      TSubscribeToExternalJackpotRequest
    >(subscribeToExternalJackpot(casinoJackpots))
  })
});

export const {
  useGetJackpotsListQuery,
  useSubscribeToJackpotQuery,
  useUnsubscribeFromJackpotQuery,
  useGetJackpotPoolMetaDataQuery,
  useGetExternalJackpotsListQuery,
  useSubscribeToExternalJackpotQuery
} = casinoJackpots;
