/* eslint-disable prefer-object-spread,no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import { TPayload } from '@monorepo/type';

import {
  BETSLIP_STATUS,
  BETSLIP_TYPE,
  ODD_MODE,
  PRICE_DELTA
} from '../constants';

type TBetInfo = {
  eventId: number;
  gameId: number;
  marketId: number;
  price: number;
  startTs: number;
  team1Name: string;
  team2Name: string;
  marketName: string;
  eventName: string;
  isBlocked: boolean;
  isDeleted: boolean;
  isLive: boolean;
  oldPrice: number;
  startPrice?: number;
  priceDelta: PRICE_DELTA.DOWN | PRICE_DELTA.UP;
};

type TState = {
  betsId: string[];
  gamesId: string[];
  betsInfo: TBetInfo[];
  oddMode: number;
  isMultipleMessageVisible: boolean;
  status: BETSLIP_STATUS;
  type: BETSLIP_TYPE;
  isOpened: boolean;
  isOddChanged: boolean;
  changedBets: number[];
  previousIndex: number;
  expBetsTime: number;
  betslipSuccessTime: number;
  singleSavedValue: object;
  multipleSavedValue: null | string;
  expressValues: object;
};

export const betslipSlice = createSlice({
  name: 'betslip',
  initialState: {
    status: BETSLIP_STATUS.IDLE,
    type: BETSLIP_TYPE.SINGLE,
    betsId: [],
    gamesId: [],
    betsInfo: [],
    oddMode: ODD_MODE.NO_CHANGE,
    isMultipleMessageVisible: false,
    isOddChanged: false,
    isOpened: false,
    changedBets: [],
    previousIndex: 0,
    expBetsTime: 0,
    betslipSuccessTime: 0,
    singleSavedValue: {},
    multipleSavedValue: null,
    expressValues: {}
  } as TState,
  reducers: {
    setBetInfo: (state, { payload }) => {
      const { eventId, gameId } = payload;
      const existEventIndex = state.betsInfo.findIndex(
        (bet) => bet.eventId === eventId
      );
      const existGameIndex = state.betsInfo.findIndex(
        (bet) => bet.gameId === gameId
      );

      state.status = BETSLIP_STATUS.IDLE;
      if (state.betsId.length === 2 && existEventIndex !== -1) {
        state.type = BETSLIP_TYPE.SINGLE;
      }

      if (existGameIndex !== -1) {
        state.gamesId[existGameIndex] = gameId;
      }

      if (existEventIndex !== -1) {
        state.betsInfo.splice(existEventIndex, 1);
        state.betsId.splice(existEventIndex, 1);
        state.gamesId.splice(existEventIndex, 1);

        if (state.singleSavedValue.hasOwnProperty(eventId)) {
          delete state.singleSavedValue[eventId];
        }
        if (state.expressValues.hasOwnProperty(gameId)) {
          delete state.expressValues[gameId][eventId];
        }
        if (!Object.keys(state.expressValues[gameId]).length) {
          delete state.expressValues[gameId];
        }
        return state;
      }

      state.betsInfo.push({
        ...payload,
        isBlocked: false,
        isDeleted: false
      });
      state.betsId.push(eventId);
      state.gamesId.push(gameId);

      if (state.betsId.length === 2 && BETSLIP_TYPE.SINGLE) {
        state.type = BETSLIP_TYPE.MULTIPLE;
      }
      return state;
    },
    updateBet: (
      state,
      { payload }: TPayload<Partial<TBetInfo> & Pick<TBetInfo, 'eventId'>>
    ) => {
      const { eventId, gameId, price: newPrice, expressId = null } = payload;
      const betIndex = state.betsInfo.findIndex(
        (bet) => bet.eventId === eventId
      );
      const bet = state.betsInfo[betIndex];
      // TODO Нужно переписать, дублируется логика, зачем вообще нужно changedBets хранить в виде массива событий
      // достаточно будет фалагами обойтись.
      const startPrice = bet.startPrice || bet.price;
      let priceDelta = '';

      if (newPrice) {
        priceDelta = startPrice > newPrice ? PRICE_DELTA.DOWN : PRICE_DELTA.UP;
      }

      if (startPrice === newPrice) {
        priceDelta = '';
      }

      merge(
        state.betsInfo[betIndex],
        payload,
        newPrice && {
          startPrice,
          oldPrice: bet.price === newPrice ? 0 : bet.price,
          priceDelta
        }
      );

      if (state.betsInfo[betIndex].priceDelta) {
        state.changedBets.push(eventId);
      } else if (state.changedBets.indexOf(eventId) !== -1) {
        state.changedBets.splice(state.changedBets.indexOf(eventId), 1);
      }
      if (!state.expressValues.hasOwnProperty(gameId)) {
        state.expressValues = {
          ...state.expressValues,
          [gameId]: { [eventId]: expressId }
        };
      } else {
        const gameIdExpressValues = {
          ...state.expressValues[gameId],
          [eventId]: expressId
        };
        state.expressValues = {
          ...state.expressValues,
          [gameId]: gameIdExpressValues
        };
      }

      return state;
    },
    removeBet: (state, { payload }) => {
      const { eventId, gameId } = payload;
      const isLastBet = state.betsId.length === 1;

      if (isLastBet && state.status === BETSLIP_STATUS.PROCESSING) {
        state.status = BETSLIP_STATUS.SUCCESS;
      }

      if (state.betsId.length === 2 && BETSLIP_TYPE.MULTIPLE) {
        state.type = BETSLIP_TYPE.SINGLE;
      }

      state.gamesId = state.gamesId.filter((id) => id !== gameId);
      state.betsInfo = state.betsInfo.filter((bet) => bet.eventId !== eventId);
      state.betsId = state.betsId.filter((id) => id !== eventId);

      if (state.changedBets.indexOf(eventId) !== -1) {
        state.changedBets.splice(state.changedBets.indexOf(eventId), 1);
      }

      if (state.singleSavedValue.hasOwnProperty(eventId)) {
        delete state.singleSavedValue[eventId];
      }

      if (state.expressValues.hasOwnProperty(gameId)) {
        delete state.expressValues[gameId][eventId];
      }

      if (!state.betsId.length) {
        state.isOddChanged = false;
        state.singleSavedValue = {};
        state.expressValues = {};
        state.multipleSavedValue = null;
      }

      return state;
    },
    clearBets: (state) => {
      state.betsInfo = [];
      state.betsId = [];
      state.gamesId = [];
      state.type = BETSLIP_TYPE.SINGLE;
      state.changedBets = [];
      state.singleSavedValue = {};
      state.expressValues = {};
      state.multipleSavedValue = null;

      return state;
    },
    resetBetslip: (state) => {
      state.status = BETSLIP_STATUS.IDLE;
      return state;
    },
    setBetslipStatus: (state, { payload }) => {
      state.status = payload;
      return state;
    },
    setOddMode: (state, { payload }) => {
      state.oddMode = payload;
      return state;
    },
    setMultipleMessage: (state, { payload }) => {
      state.isMultipleMessageVisible = payload;
      return state;
    },
    setBetslipType: (state, { payload }) => {
      if (payload === BETSLIP_TYPE.MULTIPLE && state.betsId.length < 2) {
        state.isMultipleMessageVisible = true;
        return state;
      }

      state.type = payload;
      return state;
    },
    setBetslipOpened: (state, { payload }) => {
      state.isOpened = payload;
      return state;
    },
    setPreviousIndex: (state, { payload }) => {
      state.previousIndex = payload;
      return state;
    },
    setExpBetsTime: (state, { payload }) => {
      state.expBetsTime = payload;
      return state;
    },
    setBetslipSuccessTime: (state, { payload }) => {
      state.betslipSuccessTime = payload;
      return state;
    },
    resetBetslipSuccessTime: (state) => {
      state.betslipSuccessTime = 0;
      return state;
    },
    saveBetslipValue: (state, { payload }) => {
      const { type, value } = payload;
      if (type === BETSLIP_TYPE.MULTIPLE) {
        state.multipleSavedValue = value;
      } else {
        state.singleSavedValue = { ...state.singleSavedValue, ...value };
      }
      return state;
    }
  }
});

// Action creators are generated for each case reducer function
export const { actions } = betslipSlice;

export default betslipSlice.reducer;
