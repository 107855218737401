import {
  TGetJackpotPoolMetaDataRequest,
  TPoolMetaDataDataResponse,
  TPoolMetaDataResponse
} from '../../../types';
import getJackpotPoolMetaDataAdapter from '../adapters/getJackpotPoolMetaDataAdapter';

const getJackpotPoolMetaData = () => ({
  query: ({
    partnerId,
    currency,
    jackpotId
  }: TGetJackpotPoolMetaDataRequest) => ({
    body: {
      Command: 'getjackpotpoolmetadata',
      Data: { Currency: currency, PartnerId: partnerId, JackPotId: jackpotId }
    }
  }),
  transformResponse(
    response: TPoolMetaDataResponse
  ): TPoolMetaDataDataResponse[] | [] {
    return !response ? [] : getJackpotPoolMetaDataAdapter(response);
  }
});
export default getJackpotPoolMetaData;
