import { PROVIDER_TYPE } from '../../../../constants';
import {
  TSubscribeToExternalJackpot,
  TSubscribeToExternalJackpotResponse
} from '../../../../types';

const subscribeToExternalJackpotAdapter = ({
  Data,
  RequestId
}: TSubscribeToExternalJackpotResponse): TSubscribeToExternalJackpot => ({
  ...Data,
  RequestId,
  Type: PROVIDER_TYPE.EXTERNAL
});

export default subscribeToExternalJackpotAdapter;
