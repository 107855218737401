import React from 'react';
import { Link } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon, SvgIcon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { classNameProp, CustomFC } from '@monorepo/type';
import { Button, BUTTON_INTENT, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  title: string;
  isViewAll?: boolean;
  isWithButtons?: boolean;
  isWithIcon?: boolean;
  isStart?: boolean;
  isEnd?: boolean;
  customLink?: string;
  onPrev: () => void;
  onNext: () => void;
  count?: number;
} & classNameProp;

const SuggestedSliderHeader: CustomFC<Props> = ({
  title,
  isViewAll = false,
  isWithButtons = false,
  isWithIcon = false,
  onPrev,
  onNext,
  isStart,
  isEnd,
  className = '',
  customLink = '',
  count
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes } = useSiteSettings();
  const { prematchPopular } = generalRoutes;
  const titleBlock = t(title);

  return (
    <FlexBox
      className={`${styles.header} ${className}`}
      align={FLEX_ALIGN.center}
    >
      <div className={styles.title}>
        {isWithIcon && <SvgIcon className={styles.icon} name="world-color" />}
        {titleBlock}
      </div>
      {isViewAll && (
        <Link to={customLink || prematchPopular} className={styles.viewAll}>
          {t('suggested.slider.viewall', { totalCount: count })}
          <Icon name="arrows" title="" />
        </Link>
      )}

      {isWithButtons && (
        <FlexBox className={styles.buttons}>
          <Button
            onClick={onPrev}
            isOnlyIcon
            intent={BUTTON_INTENT.tertiary}
            disabled={isStart}
            className={styles.button}
          >
            <Icon name="close_1" title="" />
          </Button>
          <Button
            onClick={onNext}
            isOnlyIcon
            intent={BUTTON_INTENT.tertiary}
            disabled={isEnd}
            className={styles.button}
          >
            <Icon name="close_1" title="" className={styles.next} />
          </Button>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default SuggestedSliderHeader;
