import React, { ReactElement } from 'react';

import { CustomFC } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox, Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

const BackButtonSkeleton: CustomFC = (): ReactElement => (
  <FlexBox className={styles.root} align={FLEX_ALIGN.center}>
    <Skeleton className={styles.buttonSkeleton} />
    <Skeleton className={styles.labelSkeleton} />
  </FlexBox>
);

export default BackButtonSkeleton;
