export const COOKIE_KEYS = {
  X_TOKEN: 'x-token',
  SESSION_TOKEN: 'SESSION_TOKEN',
  COOKIE_AGREE: 'cookie_agree',
  BTAG: 'btag'
};

export const LOCAL_STORAGE_KEYS = {
  LANGUAGE: 'i18nextLng'
};

export const SESSION_STORAGE_KEY = {};
