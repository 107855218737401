import { langLong } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetPromotionsCategoriesQuery } from '../../store';

const useGetPromotionsCategories = () => {
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();

  return useGetPromotionsCategoriesQuery({
    partnerId: siteId,
    lang: languageLongCode
  });
};

export default useGetPromotionsCategories;
