import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TShowZeroBalance } from '../../../types/userLocal';

const getShowZeroBalance = (state: any) =>
  state[userLocalSlice.name].showZeroBalance;

const useShowZeroBalance = (): TShowZeroBalance =>
  useTypedSelector(getShowZeroBalance);

export default useShowZeroBalance;
