import React from 'react';

import { Component } from '@monorepo/type';

import BaseFavoriteButton from '../../common/BaseFavoriteButton';

type TFavoriteButtonProps = {
  id: string | number;
  type: string;
  size?: 'small' | 'medium' | 'big' | 'large';
  className?: string;
  dataCustom?: string;
};

const FavoriteButton: Component<TFavoriteButtonProps> = ({
  id,
  type,
  size = 'big',
  className = '',
  dataCustom = ''
}) => (
  <BaseFavoriteButton
    id={id}
    type={type}
    className={className}
    size={size}
    dataCustom={dataCustom}
  />
);

export default FavoriteButton;
