import getTime from 'date-fns/getTime';

const getNotificationsTime = (
  logined: number | undefined,
  isConfirmed: boolean,
  waitingTime: number
) => {
  if (isConfirmed) return null;
  const current = getTime(new Date());
  const difInHours = 600000 * waitingTime; // TODO change to hours after testing
  if (!logined) return difInHours;

  const timeForShow = logined - current;
  if (timeForShow > 0) return timeForShow;
  return 0;
};

export default getNotificationsTime;
