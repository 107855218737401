import React from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { Component } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Skeleton
} from '@monorepo/ui-kit';

import LineEmptyMarketsList from '../LineMarketsList/LineEmptyMarketsList';
import ShowMoreButtonSkeleton from '../ShowMoreButton/ShowMoreButtonSkeleton';

import TeamsSkeleton from './components/Teams/TeamsSkeleton';

import styles from './index.module.css';

type Props = {
  sportAlias: string;
};

const LineGameCardSkeleton: Component<Props> = ({
  sportAlias,
  className = ''
}) => {
  const { isDesktop } = useDeviceDetector();

  return (
    <FlexBox
      align={FLEX_ALIGN.stretch}
      justify={FLEX_JUSTIFY.between}
      className={`${styles.wrap} ${className}`}
    >
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.info}>
        <FlexBox align={FLEX_ALIGN.center} className={styles.timeWrap}>
          <Skeleton className={`${styles.gameTime} ${styles.skeleton}`} />
          {!isDesktop && (
            <Skeleton className={`${styles.count} ${styles.skeleton}`} />
          )}
        </FlexBox>
        <TeamsSkeleton className={styles.teams} />
      </FlexBox>
      <Box className={styles.markets}>
        <LineEmptyMarketsList sportAlias={sportAlias} isFetching />
      </Box>
      {isDesktop && <ShowMoreButtonSkeleton className={styles.showMore} />}
    </FlexBox>
  );
};

export default LineGameCardSkeleton;
