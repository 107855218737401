import { useContext } from 'react';
import { memoize } from 'proxy-memoize';

import { useSiteSettings } from '@monorepo/site-settings';

import { LIKE_TYPE } from '../../constants';
import LikesContext from '../../store/context';
import { useGetListOfLikesQuery } from '../../store/userGeneratedContentApi';
import { TLike } from '../../types';

const selectFromLikesList = ({ data }: { data?: TLike[] }, likeId: string) =>
  data?.find(({ event_id }) => likeId === event_id) || {
    counter: 0,
    is_liked: false
  };

const useGetLike = (likeId: string, type: LIKE_TYPE) => {
  const likeIds = useContext(LikesContext);
  const { siteId } = useSiteSettings();
  const { data: likes } = useGetListOfLikesQuery(
    { ids: likeIds, likeType: type, siteId },
    {
      skip: !likeIds.length,
      pollingInterval: 30000,
      selectFromResult: memoize((result: any) => ({
        data: selectFromLikesList(result, likeId)
      }))
    }
  );
  return likes;
};

export default useGetLike;
