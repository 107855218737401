import React from 'react';

import { AVATAR_SIZE } from '@monorepo/files';
import { Component } from '@monorepo/type';
import { Avatar, FIELD_TONE } from '@monorepo/ui-kit';

import useChatUser from '../../hooks/useChatUser';
import FriendStatus from '../FriendStatus';

import styles from './index.module.css';

type Props = {
  id: string;
  isWithStatus?: boolean;
  statusClass?: string;
  avatarClass?: string;
  size?: AVATAR_SIZE;
  toneType?: FIELD_TONE;
  isWithCount?: boolean;
  countClass?: string;
};

const FriendAvatar: Component<Props> = ({
  id,
  className = '',
  avatarClass = '',
  statusClass = '',
  isWithStatus = true,
  toneType,
  size,
  isWithCount = false,
  countClass = ''
}) => {
  const { displayName, avatar = '' } = useChatUser(id);
  const avatarSrc = avatar ? `${avatar}/${size}` : '';

  return (
    <Avatar
      className={className}
      avatarClass={avatarClass}
      imgSrc={avatarSrc}
      nickname={displayName}
    >
      {isWithStatus && (
        <FriendStatus
          id={id}
          toneType={toneType}
          className={`${styles.status} ${statusClass}`}
          isWithCount={isWithCount}
          countClass={countClass}
        />
      )}
    </Avatar>
  );
};

export default FriendAvatar;
