const COMPONENT_TYPES = {
  BANNER: 'banner',
  PAGE_404: 'page404',
  BACK_HEADER: 'back-header',
  BACK_HEADER_LOGIN: 'back-header-login',
  BACK_HEADER_ACCOUNT: 'back-header-account',
  TAB_MENU_CAROUSEL: 'tab-menu-carousel',
  BACK_HEADER_PROFILE: 'back-header-profile',
  BACK_HEADER_VIDEO: 'back-header-video',
  BACK_HEADER_TITLE: 'back-header-title',
  HEADER_WALL: 'header-wall',
  BANNERS_CMS: 'banners-cms',
  CASINO_BANNERS_CMS: 'casino_banners_cms',
  BANNERS_CMS_WIDE: 'banners-cms-wide',
  BACK_HEADER_LIMITS: 'back-header-limits',
  HEADER_NAV_MENU: 'header-navigation',
  HEADER_MOBILE_NAV_MENU: 'header-mobile-navigation',
  HEADER_MOB_LINK: 'header-mobile-link',
  FOOTER: 'footer',
  HEADER_DEPOSIT: 'header-deposit',
  HEADER_PROMO: 'header-promo',
  PROMO_BLOCK: 'promo_block',
  HEADER_SEARCH: 'header-search',
  BURGER_MENU: 'burger-menu',
  REDIRECT: 'redirect',
  USER_ACCESS: 'user_access',
  COMING_SOON: 'coming_soon',
  VISIBILITY_MODE: 'visibility-mode',
  LANGUAGE_SWITCHER: 'language-switcher',
  TABS_MENU: 'tabs-menu',
  SEARCH_FORM: 'search-form',
  LOGO: 'logo',
  HEADER_EXCHANGE_WIDGET: 'exchange-widget'
};

export default COMPONENT_TYPES;
