import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

type TFavoritesState = {
  favoriteStorage: Record<string, Array<string | number>>;
};

export const favoritesSlice = createSlice<
  TFavoritesState,
  SliceCaseReducers<TFavoritesState>
>({
  name: 'favorites',
  initialState: {
    favoriteStorage: {}
  },
  reducers: {
    onSaveFavoriteIds: (state, { payload }) => {
      const { id, type } = payload;
      const isIncludeInGames = state.favoriteStorage[type]?.includes(id);
      if (!isIncludeInGames) {
        // eslint-disable-next-line no-param-reassign
        state.favoriteStorage = {
          ...state.favoriteStorage,
          [type]: [...(state.favoriteStorage[type] || []), id]
        };
      }
      return state;
    },

    onRemoveAllFavoriteIds: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.favoriteStorage[payload] = [];
      return state;
    },
    onRemoveFavoriteIds: (state, { payload }) => {
      const { id, type } = payload;
      const favorites = state.favoriteStorage[type]?.filter(
        (favoriteId: number | string) => favoriteId !== id
      );
      // eslint-disable-next-line no-param-reassign
      state.favoriteStorage = { ...state.favoriteStorage, [type]: favorites };
      return state;
    }
  }
});

export const { actions } = favoritesSlice;

export default favoritesSlice.reducer;
