import React from 'react';

import { NAMESPACES, useCurrencyTransform, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { Box, FLEX_DIRECTION, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TMoney = {
  currencyId: string;
  maxAmount: number;
  minAmount: number;
};

type TMoneyReq = {
  currencyName: string;
  moneyRequirenments: Record<string, TMoney>;
};

const MoneyRequirenments: Component<TMoneyReq> = ({
  currencyName,
  moneyRequirenments
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const currencyCode = currencyName.toLowerCase();

  if (!moneyRequirenments)
    return (
      <FlexBox
        className={styles.infoRow}
        direction={FLEX_DIRECTION.row}
        justify={FLEX_JUSTIFY.between}
      >
        <Box>{t('profile.bonuses.minmax.deposit')}</Box>
        <Box className={styles.quantity}>-</Box>
      </FlexBox>
    );
  const currentMoneyRequirements = moneyRequirenments[currencyCode] || {};
  const min = currentMoneyRequirements?.minAmount
    ? currentMoneyRequirements?.minAmount
    : '-';
  const max = currentMoneyRequirements?.maxAmount
    ? currentMoneyRequirements?.maxAmount
    : '-';
  const currency =
    useCurrencyTransform(currentMoneyRequirements.currencyId) || '';
  return (
    <FlexBox
      className={styles.infoRow}
      direction={FLEX_DIRECTION.row}
      justify={FLEX_JUSTIFY.between}
    >
      <Box>{t('profile.bonuses.minmax.deposit')}</Box>
      <Box className={styles.quantity}>
        {min}
        {' / '}
        {max} {currency}
      </Box>
    </FlexBox>
  );
};
export default MoneyRequirenments;
