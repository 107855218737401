import { createApi } from '@reduxjs/toolkit/query/react';

import { unsubscribe } from '@monorepo/common';
import { wsBaseQuery } from '@monorepo/websocket';

import {
  AUTH_QUERY_KEYS,
  BONUS_DATA,
  MESSAGES_QUERY_KEYS,
  WALLETS_KEYS
} from '../constants';
import {
  TBonusCard,
  TChangeMessageStatus,
  TLogoutRequest,
  TMessages,
  TMessagesRequest,
  TTfaResponse
} from '../types';

import cancelBonus from './api/cancelBonus';
import changeLanguage from './api/changeLanguage';
import changeSessionLanguage from './api/changeSessionLanguage';
import claimBonus from './api/claimBonus';
import disableTwoFactor from './api/disableTwoFactor';
import editProfile from './api/editProfile';
import getAvailableCurrencies from './api/getAvailableCurrencies';
import getBonuses from './api/getBonuses';
import getBonusesHistory from './api/getBonusesHistory';
import getBonusesRequest from './api/getBonusesRequest';
import getCasinoBonusesDetails from './api/getCasinoBonusesDetails';
import getChildBalances from './api/getChildBalances';
import getChildSession from './api/getChildSession';
import getDocuments from './api/getDocuments';
import getFreeSpinGame from './api/getFreeSpinGame';
import getFreeSpins from './api/getFreeSpins';
import getJWT from './api/getJWT';
import getMessages from './api/getMessages';
import getPartnerBonuses from './api/getPartnerBonuses';
import getPartnerConfig from './api/getPartnerConfig';
import getTwoFactorApply from './api/getTwoFactorApply';
import getTwoFactorEnable from './api/getTwoFactorEnable';
import getUser from './api/getUser';
import getUserLimits from './api/getUserLimits';
import getUserLossLimits from './api/getUserLossLimits';
import login from './api/login';
import logout from './api/logout';
import makeReadMessage from './api/makeReadMessage';
import resetPassword from './api/resetPassword';
import restoreLogin from './api/restoreLogin';
import restorePassword from './api/restorePassword';
import sendSms from './api/sendSms';
import setCheckInLimits from './api/setCheckInLimits';
import setDocuments from './api/setDocuments';
import setPauseLimits from './api/setPauseLimits';
import setSessionDuration from './api/setSessionDuration';
import setTimeOutLimits from './api/setTimeOutLimits';
import setUserLimits from './api/setUserLimits';
import setUserLossLimits from './api/setUserLossLimits';
import signUp from './api/signUp';
import updatePassword from './api/updatePassword';
import validateRecaptcha from './api/validateRecaptcha';
import verifyEmail from './api/verifyEmail';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: wsBaseQuery(),
  tagTypes: [
    AUTH_QUERY_KEYS.USER,
    AUTH_QUERY_KEYS.BONUSES,
    AUTH_QUERY_KEYS.FREESPINS,
    AUTH_QUERY_KEYS.DOCUMENTS,
    WALLETS_KEYS.BALANCES,
    MESSAGES_QUERY_KEYS.INCOMING,
    BONUS_DATA.BONUS_REQUEST,
    AUTH_QUERY_KEYS.JWT
  ],
  endpoints: (builder) => ({
    getCasinoBonusesDetails: builder.query(getCasinoBonusesDetails()),
    getMessages: builder.query<TMessages, TMessagesRequest>(getMessages()),
    makeReadMessage: builder.mutation<any, TChangeMessageStatus>(
      makeReadMessage()
    ),
    restoreLogin: builder.mutation(restoreLogin()),
    changeSessionLanguage: builder.mutation(changeSessionLanguage()),
    restorePassword: builder.mutation(restorePassword()),
    resetPassword: builder.mutation(resetPassword()),
    updatePassword: builder.mutation(updatePassword()),
    disableTwoFactor: builder.mutation(disableTwoFactor()),
    login: builder.mutation(login()),
    getJWT: builder.query<string, undefined>(getJWT()),
    validateRecaptcha: builder.mutation(validateRecaptcha()),
    sendSms: builder.mutation(sendSms()),
    signUp: builder.mutation(signUp()),
    editProfile: builder.mutation(editProfile()),
    getPartnerConfig: builder.query(getPartnerConfig()),
    verifyEmail: builder.mutation(verifyEmail()),
    claimBonus: builder.mutation(claimBonus()),
    cancelBonus: builder.mutation(cancelBonus()),
    getBonusesHistory: builder.query(getBonusesHistory()),
    unsubscribe: builder.mutation(unsubscribe()),
    setSessionDuration: builder.mutation(setSessionDuration()),
    getUserLimits: builder.query(getUserLimits()),
    setUserLimits: builder.mutation(setUserLimits()),
    getUserLossLimits: builder.query(getUserLossLimits()),
    setUserLossLimits: builder.mutation(setUserLossLimits()),
    setCheckInLimits: builder.mutation(setCheckInLimits()),
    setTimeOutLimits: builder.mutation(setTimeOutLimits()),
    setPauseLimits: builder.mutation(setPauseLimits()),
    setDocuments: builder.mutation(setDocuments()),
    getDocuments: builder.query(getDocuments()),
    getAvailableCurrencies: builder.query(getAvailableCurrencies()),
    getChildBalances: builder.query(getChildBalances()),
    getChildSession: builder.mutation(getChildSession()),
    getPartnerBonuses: builder.query(getPartnerBonuses()),
    getTwoFactorApply: builder.mutation(getTwoFactorApply()),
    getFreeSpinGame: builder.query(getFreeSpinGame()),
    getFreeSpins: builder.query<TBonusCard[], { acceptanceType: number }>(
      getFreeSpins()
    ),
    logout: builder.mutation<any, TLogoutRequest>(logout())
  })
});

userApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query(getUser(userApi)),
    changeLanguage: builder.mutation(changeLanguage(userApi)),
    getBonuses: builder.query(getBonuses(userApi)),
    getTwoFactorEnable: builder.mutation<TTfaResponse, void>(
      getTwoFactorEnable()
    ),
    getBonusesRequest: builder.query(getBonusesRequest(userApi)),
    getFreeSpins: builder.query(getFreeSpins(userApi))
  })
});

export const {
  useGetJWTQuery,
  useMakeReadMessageMutation,
  useGetMessagesQuery,
  useGetUserQuery,
  useGetFreeSpinsQuery,
  useRestoreLoginMutation,
  useLoginMutation,
  useValidateRecaptchaMutation,
  useLogoutMutation,
  useChangeLanguageMutation,
  useChangeSessionLanguageMutation,
  useRestorePasswordMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useDisableTwoFactorMutation,
  useSignUpMutation,
  useEditProfileMutation,
  useGetPartnerConfigQuery,
  useVerifyEmailMutation,
  useGetBonusesQuery,
  useGetBonusesHistoryQuery,
  useGetCasinoBonusesDetailsQuery,
  useGetTwoFactorApplyMutation,
  useGetTwoFactorEnableMutation,
  useCancelBonusMutation,
  useClaimBonusMutation,
  useSetSessionDurationMutation,
  useGetUserLimitsQuery,
  useSetUserLimitsMutation,
  useGetUserLossLimitsQuery,
  useSetUserLossLimitsMutation,
  useSetTimeOutLimitsMutation,
  useSetCheckInLimitsMutation,
  useSetPauseLimitsMutation,
  useSetDocumentsMutation,
  useGetDocumentsQuery,
  useSendSmsMutation,
  useLazyGetBonusesRequestQuery,
  useGetPartnerBonusesQuery,
  useGetAvailableCurrenciesQuery,
  useGetChildBalancesQuery,
  useGetChildSessionMutation,
  useGetFreeSpinGameQuery
} = userApi;
