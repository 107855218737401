import {
  TExternalJackpots,
  TGetExternalJackpotsRequest,
  TGetExternalJackpotsResponse
} from '../../../types';
import getExternalJackpotsAdapter from '../adapters/getExternalJackpotsAdapter';

const getExternalJackpotsList = () => ({
  query: ({ partnerId, currency }: TGetExternalJackpotsRequest) => ({
    body: {
      Command: 'externaljackpotlist',
      Data: {
        PartnerId: partnerId, // TODO change afterto our partberId
        Currency: currency
      }
    }
  }),
  transformResponse(
    response: TGetExternalJackpotsResponse
  ): TExternalJackpots | [] {
    return !response ? [] : getExternalJackpotsAdapter(response);
  }
});
export default getExternalJackpotsList;
