import {
  authLoginApiV2,
  userApi,
  userLocalSlice,
  userSlice
} from '@monorepo/auth';
import { getBrowserFingerprint } from '@monorepo/helpers';
import { store } from '@monorepo/redux';

import openXMPPClient from '../../utils/instance';

const partnerId = process.env.REACT_APP_FORCE_PARTNER_ID || '';

const xmppInstance = () =>
  openXMPPClient({
    service: `${process.env.REACT_APP_XMPP_WS_HOST}/ws-xmpp`,
    domain: process.env.REACT_APP_XMPP_DOMAIN,
    resource: `${getBrowserFingerprint()}${Date.now()}`,
    credentials: async (auth) => {
      const storeState = store.getState();
      const { authData } = storeState[userLocalSlice.name];

      if (authData) {
        const { jwtAuthToken } = storeState[userSlice.name];

        if (jwtAuthToken) {
          await auth({
            username: authData.ownerId || authData.userId,
            password: jwtAuthToken
          });
        } else {
          const { data = '' } = await store.dispatch(
            userApi.endpoints.getJWT.initiate(undefined)
          );
          const creds = await store
            .dispatch(
              authLoginApiV2.endpoints.requestAuthLogin.initiate({
                jwt: data,
                partnerId
              })
            )
            .unwrap();

          const password = creds?.jwt_access;
          if (password) {
            store.dispatch(userSlice.actions.setJwtAuthToken(password));
            await auth({
              username: authData.ownerId || authData.userId,
              password
            });
          }
        }
      }
    }
  });

export default xmppInstance;
