import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react';

import { TPopupHandler } from '../../types';

import styles from './index.module.css';

const PopupWrapper: ForwardRefRenderFunction<TPopupHandler> = (
  { children },
  ref
) => {
  const [isShow, setIsShow] = useState(false);
  useImperativeHandle(ref, () => ({ setIsShow }));

  const setShow = () => {
    setIsShow(false);
  };

  return (
    <div
      className={`${styles.wrap} ${styles[isShow ? 'visible' : 'hidden']}`}
      onClick={setShow}
    >
      <div className={styles.popupWrapper} onClick={(e) => e.stopPropagation()}>
        {isShow ? children : ''}
      </div>
    </div>
  );
};

export default forwardRef(PopupWrapper);
