import { TGetMenuRequest } from '@monorepo/type';

import menuAdapter from '../adapters/menuAdapter';

const getMenu = () => ({
  query: ({ menuId, partnerId, lng }: TGetMenuRequest) =>
    `${lng}/partners/${partnerId}/menus/${menuId}?platform=0`,
  transformResponse(response): any[] {
    return menuAdapter(response);
  }
});
export default getMenu;
