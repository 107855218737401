import { toSeconds } from '@monorepo/helpers';

import { TExchangeResponse } from '../../../types';

const getExchange = () => ({
  query: () => ({
    url: `/marketdepthbtcav.json?v=${toSeconds()}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  keepUnusedDataFor: 0,
  transformResponse: (data: TExchangeResponse) => {
    const pair = data?.response?.entities[0] || {};
    const [currencyFrom, currencyTo] =
      pair?.pair_name.toUpperCase().split('/') || '';
    const lastPrice = pair.last_price || '';
    return { pair, currencyFrom, currencyTo, lastPrice };
  }
});

export default getExchange;
