import React from 'react';
import { VirtuosoGrid } from 'react-virtuoso';

import { Component } from '@monorepo/type';

import { TNormalizedGame } from '../../types';
import GameItem from '../GameItem';
import GameSkeleton from '../GameSkeleton';

import GameListItem from './GameListItem';
import GameListWrapper from './GameListWrapper';
import GamesListSkeleton from './GamesListSkeleton';

import styles from './index.module.css';

type Context = {
  withTutorials: boolean;
  enableFavorite?: boolean;
};

type Props = {
  list?: Array<TNormalizedGame>;
  totalCount?: number;
  limit?: number;
  categoryId?: number;
  isLoading?: boolean;
  onLoad?: () => void;
  listClassName?: string;
  itemClassName?: string;
  withTutorials: boolean;
  enableFavorite?: boolean;
};

const itemContent = (
  i: number,
  game: TNormalizedGame,
  { withTutorials, enableFavorite }: Context
) => (
  <GameItem
    key={game?.gameId}
    game={game || {}}
    enableFavorite={enableFavorite}
    withTutorials={withTutorials}
  />
);

const GamesList: Component<Props> = ({
  list = [],
  limit,
  totalCount,
  isLoading = true,
  onLoad,
  className = '',
  listClassName = '',
  itemClassName = '',
  withTutorials,
  enableFavorite = true
}) => {
  if (isLoading) {
    return (
      <div className={className}>
        <GameListWrapper className={listClassName}>
          <GamesListSkeleton limit={limit} className={itemClassName} />
        </GameListWrapper>
      </div>
    );
  }

  return (
    <VirtuosoGrid<TNormalizedGame, Context>
      data={list}
      context={{ withTutorials, enableFavorite }}
      totalCount={totalCount}
      endReached={onLoad}
      overscan={200}
      useWindowScroll
      className={className}
      listClassName={listClassName}
      itemClassName={itemClassName}
      components={{
        List: GameListWrapper,
        Item: GameListItem,
        ScrollSeekPlaceholder: () => (
          <GameSkeleton className={styles.singleGame} />
        )
      }}
      itemContent={itemContent}
      scrollSeekConfiguration={{
        enter: (velocity: number) => Math.abs(velocity) > 2000,
        exit: (velocity: number) => Math.abs(velocity) < 30
      }}
    />
  );
};

export default GamesList;
