import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TAuthData } from '../../../types';

const getAuthData = (state: any) => state[userLocalSlice.name].authData;

const useSelectAuthData = (): TAuthData | null => useTypedSelector(getAuthData);

export default useSelectAuthData;
