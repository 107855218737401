import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';

import { Component } from '@monorepo/type';
import { FLEX_DIRECTION, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { SCORE_KEYS } from '../../../../constants';
import { TGame } from '../../../../types';
import GameScore from '../../../GameScore';
import Team from '../Team';

import styles from './index.module.css';

type Props = {
  game: TGame;
  gameId: number;
  regionId: number;
  competitionId: number;
  sportId: number;
};

const Teams: Component<Props> = ({
  className = '',
  game,
  sportId,
  regionId,
  competitionId,
  gameId
}) => {
  const { team1Name, team2Name = '', showType = '', isLive } = game;

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      justify={FLEX_JUSTIFY.center}
      className={className}
    >
      <Team name={team1Name}>
        {Boolean(isLive) && (
          <GameScore
            showType={showType}
            gameId={gameId}
            competitionId={competitionId}
            sportId={sportId}
            regionId={regionId}
            scoreKey={SCORE_KEYS.SCORE_1}
            className={styles.score}
          />
        )}
      </Team>
      {Boolean(team2Name) && (
        <Team name={team2Name}>
          {Boolean(isLive) && (
            <GameScore
              showType={showType}
              gameId={gameId}
              competitionId={competitionId}
              sportId={sportId}
              regionId={regionId}
              scoreKey={SCORE_KEYS.SCORE_2}
              className={styles.score}
            />
          )}
        </Team>
      )}
    </FlexBox>
  );
};

export default memo(Teams, (prev, next) => isEqual(prev, next));
