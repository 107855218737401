import React from 'react';

import { CustomFC } from '@monorepo/type';

import LikesContext from '../context';

const LikesProvider: CustomFC<{ list: string[] }> = ({ children, list }) => (
  <LikesContext.Provider value={list}>{children}</LikesContext.Provider>
);
export default LikesProvider;
