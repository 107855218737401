import gamesAdapter from '../adapters/gamesAdapter';
import getGamesRequest from '../query';

const getLobbyGames = () => ({
  query: getGamesRequest,
  keepUnusedDataFor: 300,
  transformResponse: gamesAdapter
});

export default getLobbyGames;
