import { TAmountConverter } from '../../types';

const convertAmountHelper = (
  value: number | string,
  converter: TAmountConverter
) => {
  if (typeof value === 'string') {
    return converter(value);
  }
  return value;
};

export default convertAmountHelper;
