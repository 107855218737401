import {
  BET_VALUES,
  BETS_HISTORY_PATHNAME,
  OUTCOMES_VALUES,
  SPORTS_QUERY_KEYS
} from '../../../constants';
import { TBets, TBetsHistory, TBetsHistoryRequest } from '../../../types';
import getMatchLocalPath from '../../../utils/getMatchLocalPath';
import betsHistoryAdapter from '../adapters/betsHistoryAdapter';

const getBetsHistory = () => ({
  query: ({ fromDate, toDate, types, id, filterPath }: TBetsHistoryRequest) => {
    const activePath = getMatchLocalPath(filterPath);
    const cashedPath = activePath === BETS_HISTORY_PATHNAME.CASHED;
    const openPath =
      activePath === BETS_HISTORY_PATHNAME.OPEN
        ? OUTCOMES_VALUES.NOT_RESULTED
        : '';
    const cashed = BET_VALUES.CASHED_OUT;

    return {
      body: {
        command: 'bet_history',
        params: {
          where: {
            from_date: fromDate,
            to_date: toDate,
            bet_type: types,
            bet_id: id,
            outcome: cashedPath ? cashed : openPath
          }
        }
      }
    };
  },
  keepUnusedDataFor: 0,
  providesTags: [SPORTS_QUERY_KEYS.BETS],
  transformResponse(response: TBetsHistory, meta: any, arg: any): TBets[] {
    const { status = '' } = arg;
    if (!response) {
      return [];
    }
    const result = betsHistoryAdapter(response);
    if (!status) {
      return result;
    }

    return result.filter((el) => el.outcome === Number(status));
  }
});
export default getBetsHistory;
