import isValid from 'date-fns/isValid';

import { formatDate, fromString } from '@monorepo/helpers';

const getFormatedDate = (
  value: string,
  dayFormat: string = 'dd.MM.yyyy',
  chekingFormat: string = 'yyyy-MM-dd'
) => {
  if (!value) {
    return '';
  }

  const parsedDate = fromString(value, chekingFormat);
  if (isValid(parsedDate)) {
    return formatDate(new Date(value), dayFormat);
  }

  const formatedValue = fromString(value);
  if (isValid(formatedValue)) {
    return formatDate(formatedValue, dayFormat);
  }

  return '';
};

export default getFormatedDate;
