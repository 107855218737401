import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { EXCHANGE_QUERY_TAG } from '../constants';

import getExchange from './api/getExchange';

type Result = {
  errors: boolean | any;
} & Record<string, any>;
const validateApiResponse = (response: Response, result: Result) =>
  response.status === 200 && !result?.errors;

const cmsHost = `${process.env.REACT_APP_CMS_IMAGES_HOST}/content`;
export const exchangeApi = createApi({
  reducerPath: 'exchangeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: cmsHost,
    validateStatus: validateApiResponse
  }),
  tagTypes: [EXCHANGE_QUERY_TAG.EXCHANGE_INFO],
  endpoints: (builder) => ({
    getExchange: builder.query<unknown, any>(getExchange())
  })
});

export const { useGetExchangeQuery } = exchangeApi;
