import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export enum MESSENGER_TAG_TYPES {
  CHATS = 'MESSENGER_CHATS',
  CHAT = 'MESSENGER_CHAT',
  CHAT_MESSAGES = 'MESSENGER_CHAT_MESSAGES',
  MESSAGE = 'MESSENGER_MESSAGE',
  CHAT_PINNED_MESSAGES = 'MESSENGER_CHAT_PINNED_MESSAGES',
  CHAT_MEMBERS = 'MESSENGER_CHAT_MEMBERS',
  CHAT_MEMBER_BLOCKED = 'CHAT_MEMBER_BLOCKED',
  GET_FRIENDS = 'MESSENGER_GET_FRIENDS',
  GET_FRIENDSHIP_REQUESTS = 'MESSENGER_GET_FRIENDSHIP_REQUESTS'
}

const messengerApi = createApi({
  reducerPath: 'messengerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1'
  }),
  tagTypes: [
    MESSENGER_TAG_TYPES.CHAT,
    MESSENGER_TAG_TYPES.CHATS,
    MESSENGER_TAG_TYPES.CHAT_MESSAGES,
    MESSENGER_TAG_TYPES.MESSAGE,
    MESSENGER_TAG_TYPES.CHAT_PINNED_MESSAGES,
    MESSENGER_TAG_TYPES.CHAT_MEMBERS,
    MESSENGER_TAG_TYPES.CHAT_MEMBER_BLOCKED,
    MESSENGER_TAG_TYPES.GET_FRIENDS,
    MESSENGER_TAG_TYPES.GET_FRIENDSHIP_REQUESTS
  ],
  endpoints: () => ({})
});

export default messengerApi;
