import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const Registration = lazy(() => import('./Registration'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Registration config={config} />
  </Suspense>
);
