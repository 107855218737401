import React from 'react';

import { CustomFC } from '@monorepo/type';

import { TNavigationProps } from '../../../types';
import FlexBox from '../../FlexBox';
import NavigationItem from '../NavigationItem';

const BaseNavigation: CustomFC<TNavigationProps> = ({
  items,
  listClassName = '',
  linkClassName = '',
  itemClassName = '',
  activeClassName = '',
  isExact = false,
  replace = false,
  active = () => true
}) => (
  <FlexBox className={listClassName}>
    {items.map((item, index) => (
      <NavigationItem
        item={item}
        linkClassName={linkClassName}
        itemClassName={itemClassName}
        activeClassName={activeClassName}
        isExact={isExact}
        replace={replace}
        active={active}
        key={`bn-${index + 1}`}
      />
    ))}
  </FlexBox>
);

export default BaseNavigation;
