import React from 'react';

import { supportChatOpen } from '@monorepo/common';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  Button,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  head: string;
  text: string;
  supportButtonText: string;
};

const EmptyMethod: CustomFC<Props> = ({ head, text, supportButtonText }) => (
  <FlexBox justify={FLEX_JUSTIFY.center} align={FLEX_ALIGN.center}>
    <Box className={styles.noInfo}>
      <Icon name="payment-method-not-found" className={styles.icon} />
      <Box className={styles.h1}>{head}</Box>
      <Box className={styles.content}>{text}</Box>
      <FlexBox justify={FLEX_JUSTIFY.center}>
        <Button className={styles.support} onClick={supportChatOpen}>
          {supportButtonText}
        </Button>
      </FlexBox>
    </Box>
  </FlexBox>
);
export default EmptyMethod;
