import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { SvgIcon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { TListWinners } from '../../../../../../types';

import WinnersItem from './WinnersItem';

import styles from './index.module.css';

type TWinners = { winners: TListWinners; title: string };

const Winners: CustomFC<TWinners> = ({ winners, title }) => {
  const { t } = useI18n(NAMESPACES.CASINO);

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrapper}>
      <FlexBox
        align={FLEX_ALIGN.center}
        className={styles.header}
        justify={FLEX_JUSTIFY.center}
      >
        {title === 'top_winners' ? (
          <>
            <SvgIcon name="layer_winners_left" className={styles.icon} />
            <span className={styles.title}>{t(`casino.${title}`)}</span>
            <SvgIcon name="layer_winners_right" className={styles.icon} />
          </>
        ) : (
          <span className={styles.title}>{t(`casino.${title}`)}</span>
        )}
      </FlexBox>

      <ul className={styles.list}>
        {winners?.map((winner, index) => {
          const { gameId } = winner;
          return (
            <li key={`${gameId}-${index}`} className={styles.item}>
              <WinnersItem winners={winner} />
            </li>
          );
        })}
      </ul>
    </FlexBox>
  );
};

export default Winners;
