import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { Box, FLEX_ALIGN, FlexBox, Skeleton } from '@monorepo/ui-kit';

import LineGameCardSkeleton from '../LineGameCard/LineGameCardSkeleton';

import styles from './index.module.css';

type Props = {
  length?: number;
  sportAlias: string;
};

const LineGamesListGroupedByDateSkeleton: Component<Props> = ({
  length = 5,
  className = '',
  sportAlias
}) => (
  <Box className={className}>
    <Box className={styles.accordion}>
      <FlexBox align={FLEX_ALIGN.center} className={styles.accordionHeader}>
        <Skeleton className={styles.skeleton} />
      </FlexBox>
      {createSkeletonArray(length).map((item) => (
        <LineGameCardSkeleton
          key={item}
          sportAlias={sportAlias}
          className={styles.card}
        />
      ))}
    </Box>
  </Box>
);

export default LineGamesListGroupedByDateSkeleton;
