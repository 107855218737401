import React, { useEffect, useState } from 'react';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import { FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import TimerItem from './components/TimerItem';

import styles from './index.module.css';

type TTimer = {
  finishDate: string;
};

const Timer: CustomFC<TTimer> = ({ finishDate }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const { days, hours, minutes, seconds } = time;

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const targetDate = new Date(finishDate);

      const differenceDays = differenceInDays(targetDate, now);
      const daysCount = addDays(now, differenceDays);
      const differenceHours = differenceInHours(targetDate, daysCount);

      const hoursAmount = addHours(daysCount, differenceHours);
      const differenceMinutes = differenceInMinutes(targetDate, hoursAmount);

      const minutesAmount = addMinutes(hoursAmount, differenceMinutes);
      const differenceSeconds = differenceInSeconds(targetDate, minutesAmount);

      setTime({
        days: differenceDays,
        hours: differenceHours,
        minutes: differenceMinutes,
        seconds: differenceSeconds
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <FlexBox justify={FLEX_JUSTIFY.around} className={styles.wrapper}>
      <TimerItem timeStamp={days} description={t('timer.days')} />
      <TimerItem timeStamp={hours} description={t('timer.hours')} />
      <TimerItem timeStamp={minutes} description={t('timer.minutes')} />
      <TimerItem timeStamp={seconds} description={t('timer.seconds')} />
    </FlexBox>
  );
};

export default Timer;
