import React, { forwardRef } from 'react';

import { IFrameProps } from '../../types';

import styles from './index.module.css';

const Iframe = forwardRef<HTMLIFrameElement, IFrameProps>(
  ({ className = '', title, ...props }, ref) => (
    <iframe
      {...props}
      className={`${styles.iframe} ${className}`}
      title={title}
      ref={ref}
    />
  )
);

export default Iframe;
