import React from 'react';

import { FAVORITE_TYPE, FavoriteButton } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import useGameControls from '../../hooks/useGameControls';
import useHover from '../../hooks/useHover';
import GameTile from '../GameTile';
import GameTitle from '../GameTitle';
import InfoButton from '../InfoButton';
import Popup from '../Popup';

import styles from './index.module.css';

type Props = {
  game: Record<string, any>;
  withTutorials: boolean;
  visual?: string;
};

const GameItem: Component<Props> = ({
  className = '',
  game,
  withTutorials,
  visual = 'sport'
}) => {
  const { name, gameId, img, isRealMode, isFunMode, badge } = game;
  const { t } = useI18n(NAMESPACES.CASINO);
  const { onFreePlay, onRealPlay } = useGameControls(gameId);
  const { showInfoButton, eventHandlers } = useHover();

  return (
    <GameTile
      className={className}
      name={name}
      image={img}
      visual={visual}
      badge={badge?.class}
    >
      <FlexBox
        {...(withTutorials && eventHandlers)}
        direction={FLEX_DIRECTION.column}
        className={styles.hoverWrapper}
      >
        <FlexBox className={styles.header} align={FLEX_ALIGN.center}>
          {showInfoButton && (
            <Popup
              name={name}
              gameId={gameId}
              isRealMode={isRealMode}
              isFunMode={isFunMode}
              className={styles.popupWrapper}
            >
              <InfoButton />
            </Popup>
          )}
          <GameTitle>{name}</GameTitle>
          <FavoriteButton
            type={FAVORITE_TYPE.CASINO}
            id={gameId}
            className={styles.favorite}
          />
        </FlexBox>
        <FlexBox
          className={styles.controls}
          justify={FLEX_JUSTIFY.center}
          align={FLEX_ALIGN.end}
        >
          {isRealMode && (
            <Button
              intent={BUTTON_INTENT.yellow}
              className={styles.button}
              onClick={onRealPlay}
              size={BUTTON_SIZE.h40}
            >
              {t('game.playnow')}
            </Button>
          )}
          {isFunMode && (
            <Button
              className={styles.button}
              onClick={onFreePlay}
              intent={BUTTON_INTENT.secondary}
              size={BUTTON_SIZE.h40}
            >
              {t('game.playfree')}
            </Button>
          )}
        </FlexBox>
      </FlexBox>
    </GameTile>
  );
};

export default GameItem;
