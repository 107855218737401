/* eslint-disable prefer-object-spread */
import { camelizeKeys } from 'humps';
import compact from 'lodash/compact';
import entries from 'lodash/entries';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import flattenDepth from 'lodash/flattenDepth';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';

import { formatUnixTime } from '@monorepo/helpers';
import { TEntries, TQueryResult } from '@monorepo/type';

import { MARKET_DISPLAY_KEYS } from '../../../constants';
import {
  TBoostedOddsResponse,
  TCompetitions,
  TGame,
  TMarket,
  TNormalizedCompetition,
  TNormalizedCompetitions,
  TNormalizedGames,
  TNormalizedLineMarkets,
  TNormalizedPopularCompetitions,
  TNormalizedRegions,
  TNormalizedSports,
  TResultCompetitions
} from '../../../types';

import { getMarketTitles } from './marketsSelector';

const getData = <T extends Record<string, any> = Record<string, any>>(
  res: TQueryResult<T>
) => res?.data;

const getObjectItemByParam = (
  object: Record<string, Record<string, any>>,
  key: string,
  value: any
): Record<string, any> =>
  find(values(object), (item) => item && item[key] === value) || {};

export const getSortedIdsArray = (
  object: Record<string, any>,
  sortingKey = 'order',
  idKey = 'id',
  formatItem = (item) => get(item, idKey)
): number[] => map(compact(values(sortBy(object, sortingKey))), formatItem);

export const selectSportsWithOrder = (
  response: TQueryResult<TNormalizedSports>
) => {
  const data = getData<TNormalizedSports>(response);

  return data?.sport ? getSortedIdsArray(data.sport) : [];
};

export const selectSportRegionsWithOrder = (
  response: TQueryResult<TNormalizedSports>,
  sportId: number
) => {
  const data = getData<TNormalizedSports>(response);
  const regions = data?.sport[sportId]?.region;

  return regions ? getSortedIdsArray(regions) : [];
};

export const selectGameIds = (
  response: TQueryResult<TNormalizedSports>,
  sportId: number
) => {
  const regions = response.data?.sport?.[sportId]?.region || {};
  const competitions = Object.values(regions).flatMap(
    (el: any) => el?.competition || []
  );
  const games = competitions?.flatMap((el: any) => Object.values(el)) || [];
  return games.flatMap((el: any) => Object.keys(el?.game || {})) || [];
};

export const selectSportsBySportId = (
  response: TQueryResult<TNormalizedSports>,
  sportId: number
) => {
  const data = getData<TNormalizedSports>(response);

  return pick(data?.sport[sportId], ['id', 'name', 'alias', 'game']) || {};
};

export const selectSportBySportAlias = (
  response: TQueryResult<TNormalizedSports>,
  sportAlias: string
) => {
  const data = getData<TNormalizedSports>(response);
  if (data?.sport) {
    return getObjectItemByParam(data?.sport, 'alias', sportAlias);
  }
  return {};
};

export const selectRegionsWithOrder = (
  response: TQueryResult<TNormalizedRegions>
) => {
  const data = getData<TNormalizedRegions>(response);
  return data?.region
    ? map(
        compact(values(sortBy(data?.region, 'order'))),
        (item: Record<string, any>) => ({
          id: get(item, 'id'),
          alias: get(item, 'alias')
        })
      )
    : [];
};

export const selectCompetitionsByRegionId = (
  response: TQueryResult<TNormalizedRegions>,
  regionId: number
) => {
  const data = getData<TNormalizedRegions>(response);
  const competitions = data?.region[regionId].competition;

  return competitions ? getSortedIdsArray(competitions) : [];
};

export const selectCompetitionsBySportIdRegionId = (
  response: TQueryResult<TNormalizedSports>,
  sportId: number,
  regionId: number
) => {
  const data = getData<TNormalizedSports>(response);
  const competitions = data?.sport[sportId]?.region[regionId]?.competition;

  return competitions ? getSortedIdsArray(competitions) : [];
};

export const selectCompetitionsByRegionIdCompetitionId = (
  response: TQueryResult<TNormalizedRegions>,
  regionId: number,
  competitionId: number
) => {
  const data = getData<TNormalizedRegions>(response);
  const competition = data?.region[regionId].competition[competitionId];
  return competition ? pick(competition, ['id', 'name', 'game']) : {};
};

export const selectRegionById = (
  response: TQueryResult<TNormalizedRegions>,
  regionId: number
) => {
  const data = getData<TNormalizedRegions>(response);
  const region = data?.region[regionId];
  if (!region) {
    return {};
  }

  const gamesSum = sumBy(
    values(region.competition),
    (competition) => competition?.game || 0
  );
  // https://www.measurethat.net/Benchmarks/ShowResult/295679
  // Object assign is two times faster then spread
  return Object.assign(
    {},
    {
      game: gamesSum
    },
    pick(region, ['id', 'name', 'alias', 'count'])
  );
};

export const selectSportRegionCompetitionOrderedGames = (
  response: TQueryResult<TNormalizedGames>,
  sportId: number,
  regionId: number,
  competitionId: number
): number[] => {
  const data = getData<TNormalizedGames>(response);
  const games =
    data?.sport[sportId]?.region[regionId]?.competition[competitionId]?.game;

  return games ? getSortedIdsArray(games, 'start_ts') : [];
};

export const selectSportCompetitionOrderedGamesGroupedByDate = (
  response: TQueryResult<TNormalizedGames>,
  sportId: number,
  regionId: number,
  competitionId: number
): TEntries<Record<string, Array<number>>> => {
  const data = getData<TNormalizedGames>(response);
  const games =
    data?.sport[sportId]?.region[regionId]?.competition[competitionId]?.game;

  return games
    ? map(
        entries(
          groupBy(sortBy(filter(games, 'start_ts'), 'start_ts'), (item) =>
            formatUnixTime(get(item, 'start_ts'), 'dd.MM.yyyy')
          )
        ),
        ([key, items]) => [key, map(compact(items), (item) => get(item, 'id'))]
      )
    : [];
};

export const selectCompetitionBySportIdRegionIdCompetitionId = (
  response: TQueryResult<TNormalizedGames>,
  sportId: number,
  regionId: number,
  competitionId: number
): Record<string, any> => {
  const data = getData<TNormalizedGames>(response);

  if (data?.sport[sportId]?.region[regionId]?.competition[competitionId]) {
    return {
      competitionName: get(
        data.sport[sportId].region[regionId].competition[competitionId],
        'name'
      ),
      regionAlias: get(data.sport[sportId].region[regionId], 'alias', 'World')
    };
  }
  return {};
};

export const selectGameBySportIdRegionIdCompetitionIdGameId = (
  response: TQueryResult<TNormalizedGames>,
  sportId: number,
  regionId: number,
  competitionId: number,
  gameId: number,
  keys: string[] = []
): TGame | {} => {
  const data = getData<TNormalizedGames>(response);
  const game =
    data?.sport[sportId]?.region[regionId]?.competition[competitionId]?.game[
      gameId
    ];

  return game ? camelizeKeys(pick(game, keys)) : {};
};

const findByDisplayKey = (displayKey: string, markets: TMarket[]) => {
  if (displayKey === 'WINNER') {
    return (
      markets.find((item) => item.type === 'P1XP2') ||
      markets.find((item) => item.type === 'P1P2') ||
      markets.find((item) => get(item, 'display_key') === displayKey) || {
        display_key: displayKey
      }
    );
  }
  return (
    markets.find((item) => get(item, 'display_key') === displayKey) || {
      display_key: displayKey
    }
  );
};

export const selectGameOrderedMarkets = (
  response: TQueryResult<TNormalizedLineMarkets>,
  gameId: number,
  displayKeys: MARKET_DISPLAY_KEYS[]
) => {
  const data = getData<TNormalizedLineMarkets>(response);
  const marketsData = data?.game?.[gameId]?.market;

  return marketsData
    ? map(displayKeys, (key) =>
        camelizeKeys(
          pick(
            findByDisplayKey(
              key,
              compact(values(sortBy(marketsData, 'order')))
            ),
            ['id', 'display_key', 'name', 'type']
          )
        )
      )
    : [];
};

export const selectGameOrderedMarketTitles = (
  response: TQueryResult<TNormalizedLineMarkets>,
  gameId: number,
  displayKeys: MARKET_DISPLAY_KEYS[],
  team1Name: string,
  team2Name: string
) => {
  const data = getData<TNormalizedLineMarkets>(response);
  const marketsData = data?.game[gameId]?.market;

  if (!marketsData) {
    return [];
  }

  return map(displayKeys, (key) =>
    getMarketTitles(
      map(
        values(
          get(
            findByDisplayKey(
              key,
              compact(values(sortBy(marketsData, 'order')))
            ),
            'event',
            {}
          )
        ),
        (item) => pick(item, ['name', 'type_1', 'order'])
      ),
      team1Name,
      team2Name
    )
  );
};

export const selectIsHeadMarketsPresent = (
  response: TQueryResult<TNormalizedLineMarkets>,
  gameId: number
) => {
  const data = getData<TNormalizedLineMarkets>(response);
  const marketsData = data?.game[gameId]?.market;

  return Boolean(marketsData);
};

export const selectGameMarketOrderedOutcomes = (
  response: TQueryResult<TNormalizedLineMarkets>,
  gameId: number,
  marketId: number
) => {
  const data = getData<TNormalizedLineMarkets>(response);
  const events = data?.game[gameId]?.market[marketId]?.event;
  return events ? getSortedIdsArray(events) : [];
};

export const selectGameMarket = (
  response: TQueryResult<TNormalizedLineMarkets>,
  gameId: number,
  marketId: number
) => {
  const data = getData<TNormalizedLineMarkets>(response);
  const market = data?.game[gameId]?.market[marketId];
  return market ? pick(market, ['id', 'type', 'name', 'base']) : {};
};

export const selectOutcomeByGameIdMarketIdOutcomeId = (
  response: TQueryResult<TNormalizedLineMarkets>,
  gameId: number,
  marketId: number,
  outcomeId: number
) => {
  const data = getData<TNormalizedLineMarkets>(response);
  const outcome = data?.game[gameId]?.market[marketId]?.event[outcomeId];

  return outcome ? camelizeKeys(outcome) : {};
};

export const selectOrderedCompetitions = (
  response: TQueryResult<TNormalizedCompetitions>
): Array<TCompetitions> => {
  const data = getData<TNormalizedCompetitions>(response);
  return data?.competition
    ? compact(values(sortBy(data.competition, 'order')))
    : [];
};

export const selectRegionByAlias = (
  response: TQueryResult<TNormalizedRegions>,
  regionAlias: string
) => {
  const data = getData<TNormalizedRegions>(response);
  if (data?.region) {
    return getObjectItemByParam(data.region, 'alias', regionAlias);
  }
  return {};
};

export const selectPopularCompetitions = (
  response: TQueryResult<TNormalizedPopularCompetitions>
) => {
  const data = getData<TNormalizedPopularCompetitions>(response);
  return data?.sport
    ? flatten(
        map(compact(values(sortBy(data.sport, 'order'))), (sport) => {
          const sportId = get(sport, 'id');
          return flatten(
            map(
              compact(values(sortBy(get(sport, 'region', []), 'order'))),
              (region) => {
                const regionId = get(region, 'id');
                return map(
                  compact(
                    values(sortBy(get(region, 'competition', []), 'order'))
                  ),
                  (competition) => ({
                    id: get(competition, 'id'),
                    sportId,
                    regionId
                  })
                );
              }
            )
          );
        })
      )
    : [];
};

export const selectCompetitionsBySportIdRegionIdCompetitionId = (
  response: TQueryResult<TNormalizedPopularCompetitions>,
  sportId: number,
  regionId: number,
  competitionId: number
) => {
  const data = getData<TNormalizedPopularCompetitions>(response);
  const competitionData =
    data?.sport[sportId]?.region[regionId]?.competition[competitionId];
  const competition = competitionData
    ? pick(competitionData, ['id', 'name', 'game'])
    : {};
  const sportAlias = data?.sport[sportId]?.alias;
  const regionAlias = data?.sport[sportId]?.region[regionId]?.alias;

  return { ...competition, sportAlias, regionAlias };
};

export const selectCompetitionBySportAliasRegionAliasCompetitionId = (
  response: TQueryResult<TNormalizedCompetition>,
  sportAlias: string,
  regionAlias: string,
  competitionId: number
) => {
  const data = getData<TNormalizedCompetition>(response);
  if (data?.sport) {
    const sport = getObjectItemByParam(data.sport, 'alias', sportAlias);
    const { name: sportName, region: regions = {} } = sport;
    const region = getObjectItemByParam(regions, 'alias', regionAlias);
    const { name: regionName, competition = {} } = region;
    const { name: competitionName } = competition[competitionId] || {};
    return { sportName, regionName, competitionName };
  }
  return {};
};

export const selectSuggestedOrderedGames = (
  response: TQueryResult<TNormalizedSports>
) => {
  const data = getData<TNormalizedSports>(response);
  return data?.sport
    ? flatten(
        map(compact(values(sortBy(data.sport, 'order'))), (sport) => {
          const sportId = get(sport, 'id');
          return flatten(
            map(
              compact(values(sortBy(get(sport, 'region', []), 'order'))),
              (region) => {
                const regionId = get(region, 'id');
                return flatten(
                  map(
                    compact(
                      values(sortBy(get(region, 'competition', []), 'order'))
                    ),
                    (competition) => {
                      const competitionId = get(competition, 'id');
                      return flatten(
                        map(
                          compact(
                            values(
                              sortBy(get(competition, 'game', []), 'order')
                            )
                          ),
                          (game) => {
                            const gameData = camelizeKeys(
                              pick(game, [
                                'id',
                                'region_alias',
                                'sport_alias',
                                'is_live'
                              ])
                            );
                            return {
                              ...gameData,
                              sportId,
                              regionId,
                              competitionId
                            };
                          }
                        )
                      );
                    }
                  )
                );
              }
            )
          );
        })
      )
    : [];
};

export const selectSuggestedGamesIds = (response: TQueryResult<any>) => {
  const data = getData<any>(response);
  return data ? data.map((game: any) => game.game) : [];
};

export const selectGameIdsFromSports = (
  response: TQueryResult<TNormalizedSports>
): number[] | null => {
  const data = getData<TNormalizedSports>(response);

  return data?.sport
    ? (flattenDepth(
        map(data.sport, ({ region }) =>
          map(region, ({ competition }) =>
            map(competition, ({ game }) => map(game, (g) => g.id))
          )
        ),
        3
      ) as unknown as number[])
    : null;
};

export const selectSportsFromCompetitionsRequest = (
  response: TQueryResult<TResultCompetitions[]>
) => {
  const data = getData<TResultCompetitions[]>(response);

  return data
    ? data.map(({ id, name }) => ({
        label: name,
        value: id
      }))
    : [];
};

export const selectCompetitionsFromCompetitionsRequest = (
  response: TQueryResult<TResultCompetitions[]>,
  sportId?: string
) => {
  const data = getData<TResultCompetitions[]>(response);

  if (data && sportId) {
    const competitionData = data.find(({ id }) => id === Number(sportId));
    const { regions = [] } = competitionData || {};
    return orderBy(
      flatten(
        map(regions, (region) =>
          map(get(region, 'competitions', []), (competition) => ({
            value: get(competition, 'id'),
            label: `${get(region, 'name')} - ${get(competition, 'name')}`
          }))
        )
      ),
      'label'
    );
  }

  return [];
};

export const selectIsOutcomeBoosted = (
  response: TQueryResult<TBoostedOddsResponse>,
  gameId: number,
  outcomeId: number
): boolean => {
  const data = getData<TBoostedOddsResponse>(response);
  if (data) {
    const outcomes = data[gameId] || [];
    return Boolean(outcomes.find((item) => outcomeId === item.Id));
  }
  return false;
};

export const selectAllSportsWithCompetitionsAndNormalize = (
  response: TQueryResult<TNormalizedSports>
) => {
  const data = getData<TNormalizedSports>(response);

  return data?.sport
    ? map(data.sport, (sport) => ({
        ...sport,
        region: map(sport.region, (region) => ({
          ...region,
          competition: map(region.competition, (competition) => competition)
        }))
      }))
    : [];
};

export const selectAllGamesCount = (
  response: TQueryResult<TNormalizedSports>
) => {
  const data = getData<TNormalizedSports>(response);
  if (data?.sport) {
    return Object.values(data.sport).reduce((acc, sport) => {
      Object.values(sport.region).forEach((region) => {
        Object.values(region.competition).forEach(
          (competition) => acc + Number(competition.game)
        );
      });
      return acc;
    }, 0);
  }
  return null;
};
