import { useCallback, useEffect, useRef, useState } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import {
  useKeyBoardOpen,
  useLocalActions,
  useSelectChatOpen
} from '@monorepo/auth';

const useChatHeight = (headerHeight = 0) => {
  const [chatHeight, setChatHeight] = useState('auto');
  const { setChatIsOpen, setKeyBoardIsOpen } = useLocalActions();
  const isChatOpen = useSelectChatOpen();
  const isKeyBoardOpen = useKeyBoardOpen();
  const { isDesktop } = useDeviceDetector();
  const ref = useRef<HTMLDivElement>(null);
  const { isIOS } = useDeviceDetector();
  const scrollToChat = () => {
    setTimeout(() => {
      ref?.current?.scrollIntoView(
        isIOS
          ? true
          : {
              behavior: 'auto',
              block: 'end'
            }
      );
    }, 300);
  };
  const resizeHandler = useCallback(() => {
    const viewport = window.visualViewport.height - Number(headerHeight);
    const heightChat = `${viewport}px`;
    setChatHeight(heightChat);
  }, [isChatOpen, isKeyBoardOpen]);

  useEffect(() => {
    if (!isDesktop) {
      setChatIsOpen(true);
    }
    return () => {
      if (!isDesktop) {
        setChatIsOpen(false);
        setKeyBoardIsOpen(false);
      }
    };
  }, []);

  useEffect(() => {
    if (!isDesktop && isChatOpen) {
      scrollToChat();
    }
  }, [isChatOpen, chatHeight]);

  useEffect(() => {
    if (!isDesktop && isKeyBoardOpen) {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      window.addEventListener('scroll', resizeHandler);
    }
    return () => {
      if (!isDesktop) {
        window.removeEventListener('resize', resizeHandler);
        window.removeEventListener('scroll', resizeHandler);
      }
    };
  }, [isKeyBoardOpen]);
  return { ref, chatHeight };
};
export default useChatHeight;
