import Element from 'ltx/src/Element';

import { debounceEvents } from '@monorepo/common';
import { MESSAGES_TYPES, XML_NAMESPACES, XMPPClient } from '@monorepo/xmpp';

import { TSocialCallbacks, TSocialsActions } from '../../../../types';
import {
  isInboxConversationMessage,
  isInboxMessage
} from '../../../../utils/isStanzaHelper';
import {
  inboxConversationMessageParse,
  inboxParse
} from '../../../../utils/stanzaParsers';
import ChatMessageHandler from '../ChatMessageHandler';
import ChatSentMessageHandler from '../ChatSentMessageHandler';
import ErrorMessageHandler from '../ErrorMessageHandler';
import GroupChatMessageHandler from '../GroupChatMessageHandler';
import MamMessageHandler from '../MamMessageHandler';
import MUCUserXDataMessageHandler from '../MUCUserXDataMessageHandler';
import ReceivedMessageHandler from '../RecivedMessageHandler';

class MessageHandler {
  reduxActions: TSocialsActions;

  instance: XMPPClient;

  callbacks: TSocialCallbacks;

  jidString: string;

  debounceInbox;

  constructor(
    instance: XMPPClient,
    actions: TSocialsActions,
    callbacks: TSocialCallbacks
  ) {
    this.instance = instance;
    this.reduxActions = actions;
    this.callbacks = callbacks;
    this.jidString = instance.jid.toString();
    this.debounceInbox = debounceEvents(
      (evensBatch) => {
        actions.setInbox(
          evensBatch.map((stanza: Element) =>
            inboxParse(stanza, this.jidString)
          )
        );
      },
      2000,
      false
    );
  }

  public processStanza = (stanza: Element) => {
    const { type } = stanza.attrs;
    let handler = null;

    if (type === MESSAGES_TYPES.SINGLE) {
      handler = new ChatMessageHandler(this);
    } else if (type === MESSAGES_TYPES.GROUP) {
      handler = new GroupChatMessageHandler(this);
    } else if (type === MESSAGES_TYPES.ERROR) {
      handler = new ErrorMessageHandler(this);
    } else if (!type || type === MESSAGES_TYPES.NORMAL) {
      const [firstChild] = stanza.getChildElements();

      if (firstChild?.is('received', XML_NAMESPACES.RECEIPTS)) {
        handler = new ReceivedMessageHandler(this);
      } else if (firstChild?.is('result', XML_NAMESPACES.MAM)) {
        handler = new MamMessageHandler(this);
      } else if (firstChild?.is('x', XML_NAMESPACES.MUC_USER)) {
        handler = new MUCUserXDataMessageHandler(this);
      } else if (isInboxMessage(stanza)) {
        this.debounceInbox(stanza);
      } else if (isInboxConversationMessage(stanza)) {
        this.reduxActions.setInboxRead(inboxConversationMessageParse(stanza));
      } else {
        // eslint-disable-next-line no-console
        console.log(`Unprocessed message1 ${stanza}`);
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(`Unprocessed message ${stanza}`);
    }

    return handler?.processStanza(stanza);
  };

  public processSentStanza = (stanza: Element) => {
    const { type } = stanza.attrs;
    let handler = null;

    if (type === MESSAGES_TYPES.SINGLE || type === MESSAGES_TYPES.GROUP) {
      handler = new ChatSentMessageHandler(this);
    }

    return handler?.processStanza(stanza);
  };
}

export default MessageHandler;
