import { convertParams } from '@monorepo/helpers';

import {
  TNormalizedGamesResponse,
  TTournamentGamesRequest,
  TTournamentGamesResponse
} from '../../../types';
import normalizeGame from '../../../utils/normalizeGame';

const getTournamentGames = () => ({
  query: ({
    partnerId,
    isMobile,
    tournamentId,
    limit,
    offset,
    useWebp
  }: TTournamentGamesRequest) => {
    const params = {
      partner_id: partnerId,
      tournament_id: tournamentId,
      with: 'images'
    };
    if (isMobile) {
      params.is_mobile = isMobile;
    }
    if (offset) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    if (useWebp) {
      params.use_webp = useWebp;
    }
    return `getTournamentGames?${convertParams(params)}`;
  },
  transformResponse: (
    response: TTournamentGamesResponse,
    meta: any,
    args: TTournamentGamesRequest
  ): TNormalizedGamesResponse => ({
    games: (response?.items || []).map(normalizeGame),
    totalCount: Number(response?.total_count || 0),
    offset: args.offset || 0,
    limit: args.limit || 0
  })
});
export default getTournamentGames;
