import { TUpdateChatInfoRequest } from '../../../types';
import { userGeneratedContentApi } from '../../userGeneratedContentApi';

const updateChatInfo = () => ({
  query: ({ avatarUrl, roomId }: TUpdateChatInfoRequest) => ({
    url: `/chat_info/${roomId}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: { avatar_url: avatarUrl }
  }),
  async onQueryStarted(
    { roomId, ...rest }: TUpdateChatInfoRequest,
    { dispatch, queryFulfilled }
  ) {
    const patchResult = dispatch(
      userGeneratedContentApi.util.updateQueryData(
        'getChatInfo',
        { roomId },
        (draft) => {
          Object.assign(draft, rest);
        }
      )
    );
    try {
      await queryFulfilled;
    } catch {
      patchResult.undo();
    }
  }
});

export default updateChatInfo;
