import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';

import GameSkeleton from '../GameSkeleton';

import styles from './index.module.css';

type Props = {
  limit?: number;
};

const GamesListSkeleton: Component<Props> = ({
  limit = 10,
  className = ''
}) => {
  const list = createSkeletonArray(limit);

  return (
    <>
      {list.map((game) => (
        <GameSkeleton
          key={game}
          className={`${className} ${styles.singleGame}`}
        />
      ))}
    </>
  );
};

export default GamesListSkeleton;
