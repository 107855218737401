import React from 'react';

import { DATE_LOCALE_MAP, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import { DatePicker as UIDatePicker, TDatePickerProps } from '@monorepo/ui-kit';

const DatePicker: CustomFC<TDatePickerProps> = ({ ...props }) => {
  const { language } = useI18n();
  const localeName = DATE_LOCALE_MAP[language] || language;
  return <UIDatePicker locale={localeName} {...props} />;
};
export default DatePicker;
