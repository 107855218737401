import { submitConfiguration } from '@monorepo/xmpp';

import { SOCIALS_QUERY_TAG } from '../../../constants';
import { TSubmitRoomConfigurationRequest } from '../../../types';
import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const submitRoomConfiguration = () => ({
  query: ({ roomName, fields }: TSubmitRoomConfigurationRequest) =>
    submitConfiguration(roomName, fields),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError,
  invalidatesTags: (
    result: any | undefined,
    error: unknown,
    { roomName }: TSubmitRoomConfigurationRequest
  ) => [{ type: SOCIALS_QUERY_TAG.MUC_ROOM_DISCO_INFO, id: roomName }]
});

export default submitRoomConfiguration;
