import {
  acceptSubscription as acceptSubscriptionMessage,
  cancelSubscription,
  removeRosterItem,
  setRosterItem,
  subscribeTo,
  unsubscribeFrom,
  useXMPPInstance
} from '@monorepo/xmpp';

import { filterLocalStorageRequestsList } from '../../utils/requestsListStorage';
import useActions from '../useActions';

const useRosterActions = () => {
  const xmpp = useXMPPInstance();
  const { removeSubscribeRequests } = useActions();

  const acceptSubscription = async (
    jid: string,
    name: string = jid,
    isMutual = true
  ) => {
    xmpp.send(acceptSubscriptionMessage(jid));
    if (isMutual) {
      await xmpp.send(setRosterItem(jid, name));
      // send mutual subscription
      xmpp.send(subscribeTo(jid));
    }

    removeSubscribeRequests(jid);
  };

  const addContactAndSubscribe = async (jid: string, name: string) => {
    await xmpp.send(setRosterItem(jid, name));
    xmpp.send(subscribeTo(jid));
  };

  const unsubscribeAndRemove = (jid: string) => {
    xmpp.send(unsubscribeFrom(jid));
    xmpp.send(removeRosterItem(jid));

    filterLocalStorageRequestsList(jid);
  };

  const denySubscription = (jid: string) => {
    xmpp.send(cancelSubscription(jid));

    removeSubscribeRequests(jid);
    filterLocalStorageRequestsList(jid);
  };

  const updateContact = (jid: string, name: string = jid) =>
    xmpp.send(setRosterItem(jid, name));

  return {
    acceptSubscription,
    addContactAndSubscribe,
    unsubscribeAndRemove,
    denySubscription,
    updateContact
  };
};

export default useRosterActions;
