export const formatResult = (response: any) => response?.json();

export const get = (url: string, config?: RequestInit) =>
  fetch(url, { ...config, method: 'GET' }).then(formatResult);

export const post = (url: string, body: {}, config?: RequestInit) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    ...config,
    body: JSON.stringify(body),
    method: 'POST'
  };
  return fetch(url, requestOptions).then(formatResult);
};

export const put = (url: string, body: {}, config?: RequestInit) => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
    ...config,
    method: 'PUT',
    body: JSON.stringify(body)
  };
  return fetch(url, requestOptions).then(formatResult);
};
