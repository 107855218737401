import {
  TGameResults,
  TResultsGamesRequest,
  TResultsGamesRespponse
} from '../../../types';
import getResultsGamesAdapter from '../adapters/getResultsGamesAdapter';

const getResultGames = () => ({
  query: ({
    fromDate,
    toDate,
    isLive,
    competitions,
    sport,
    team
  }: TResultsGamesRequest) => ({
    body: {
      command: 'get_result_games',
      params: {
        is_date_ts: 1,
        from_date: fromDate,
        to_date: toDate,
        sport_id: sport,
        ...(competitions && {
          competition_id: competitions
        }),
        live: isLive
      }
    }
  }),
  transformResponse(response: TResultsGamesRespponse): TGameResults[] {
    return !response ? [] : getResultsGamesAdapter(response);
  }
});
export default getResultGames;
