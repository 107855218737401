import React from 'react';

import { BackHeaderCommon } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import { Box, FIELD_TONE, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import AuthWrapper from '../../common/AuthWrapper';
import useSelectSignData from '../../hooks/selectors/useSelectSignData';
import useGetUser from '../../hooks/useGetUser';
import useLocalActions from '../../hooks/useLocalActions';
import { useGetTwoFactorEnableMutation } from '../../store/user';
import { TTfaResponse } from '../../types';
import TwoFactorDisable from '../TwoFactorDisable';
import TwoFactorEnable from '../TwoFactorEnable';

import styles from './index.module.css';

type TTwoFactor = {
  tone?: FIELD_TONE;
};

const TwoFactor: ComponentWithConfig<TTwoFactor> = ({ config }) => {
  const { tone = FIELD_TONE.dark } = config || {};
  const { data: profile } = useGetUser();
  const { isTwoFactorAuthenticationEnabled = false } = profile || {};
  const { t } = useI18n(NAMESPACES.ACCOUNT);

  const [twoFactory] = useGetTwoFactorEnableMutation();
  const { setSignData } = useLocalActions();
  const handleSubmit = async () => {
    const { data } = await twoFactory();
    const { details } = (data as TTfaResponse) || {};

    if (details.QRCodeOrigin) {
      setSignData({
        qrCodeOrigin: details.QRCodeOrigin
      });
    }
  };

  const signData = useSelectSignData();
  const twoFactorEnabled =
    isTwoFactorAuthenticationEnabled && !signData?.qrCodeOrigin;

  return (
    <AuthWrapper>
      <Box className={styles.root}>
        <BackHeaderCommon
          title={t(
            `two.factor.${twoFactorEnabled ? 'deactivate' : 'activate'}.head`
          )}
          backLink={false}
          tone={tone}
          titleClassName={styles.title}
          className={styles.header}
        >
          <Box className={styles.logo} />
        </BackHeaderCommon>
        <FlexBox justify={FLEX_JUSTIFY.center} className={styles.wrapper}>
          {twoFactorEnabled ? (
            <TwoFactorDisable toneType={tone} />
          ) : (
            <TwoFactorEnable
              toneType={tone}
              handleSubmit={handleSubmit}
              qrCode={signData?.qrCodeOrigin || ''}
            />
          )}
        </FlexBox>
      </Box>
    </AuthWrapper>
  );
};
export default TwoFactor;
