const getSlot = () => ({
  query: ({ id, siteId }: { id: string; siteId: number }) => ({
    url: `feature/${id}?partner_id=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
});

export default getSlot;
