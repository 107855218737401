import { convertParams } from '@monorepo/helpers';

import { TCategoriesRequest } from '../../../types';
import providersAdapter from '../adapters/providersAdapter';

const getProviders = () => ({
  query: ({
    partnerId,
    isMobile,
    lang,
    country,
    category
  }: TCategoriesRequest) => {
    const commonParams = {
      partner_id: partnerId,
      is_mobile: isMobile,
      lang,
      only_providers: 1,
      country
    };
    const params = category
      ? { ...commonParams, category_id: category }
      : commonParams;

    return `${
      category ? 'getOptionsByGameOrder' : 'getOptions'
    }?${convertParams(params)}`;
  },
  transformResponse: providersAdapter
});
export default getProviders;
