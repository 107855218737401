import { CUSTOM_API_QUERY_KEYS } from '../../constants';

const getSearchPlayers = () => ({
  query: (search: string) => ({
    url: '/search/player',
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      nickname: search
    }
  }),
  providesTags: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION],
  transformResponse: (response: any) => {
    if (!response || typeof response === 'string') {
      return [];
    }
    return Array.isArray(response) ? response : [response];
  }
});

export default getSearchPlayers;
