import React from 'react';
import map from 'lodash/map';
import values from 'lodash/values';

import { requiredValidator, SelectField } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';

import { TFormFieldValues, TPaymentFormField } from '../../../../../types';
import FieldWrap from '../FieldWrap';

type Props = {
  field: TPaymentFormField;
};

const PaymentSelectField: Component<Props> = ({ field }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { fieldKey, opts = {} } = field;

  const options = map(values(opts), ({ value, text }) => ({
    value,
    label: text
  }));

  return (
    <FieldWrap label={t(`wallet.${fieldKey}`)}>
      <SelectField<TFormFieldValues>
        options={options}
        id={fieldKey}
        name={fieldKey}
        rules={{
          ...requiredValidator()
        }}
      />
    </FieldWrap>
  );
};

export default PaymentSelectField;
