import { TUpdatePlayerInformation } from '../../../../types';

const updateFavorites = (api) => ({
  query: (args: TUpdatePlayerInformation) => ({
    url: '/player',
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      favorites: args.favorites
    }
  }),
  // invalidatesTags: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION],
  async onQueryStarted(data, { dispatch, queryFulfilled }) {
    const { favorites: bodyParams, ...args } = data;
    const patchResult = dispatch(
      api.util.updateQueryData('getPlayerInfo', args, (draft) =>
        Object.assign(draft, { favorites: bodyParams })
      )
    );
    try {
      await queryFulfilled;
    } catch {
      patchResult.undo();
    }
  }
});

export default updateFavorites;
