import { useGetUser } from '@monorepo/auth';
import { getHostName } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

import { PAY_METHOD } from '../../constants';
import { useMakeDepositMutation } from '../../store/wallet';
import formatPaymentError from '../../utils/formatPaymentError';

type TUserData = {
  amount: string | number;
  serviceId: string;
  fields: Record<string, string | number>;
};

const useDeposit = () => {
  const location = getHostName();
  const [makeDeposit, { isLoading, isError }] = useMakeDepositMutation();
  const { successNotification, errorNotification, infoNotification } =
    useNotifications();
  const { t } = useI18n(NAMESPACES.FORMS);
  const { generalRoutes } = useSiteSettings();
  const { deposit } = generalRoutes;
  const { data: user } = useGetUser();
  const currency = user?.currencyName;

  const onDeposit = async (userData: TUserData) => {
    const { amount, serviceId, fields } = userData;
    const { data } = await makeDeposit({
      amount,
      domain: location,
      balancePage: deposit,
      currency,
      serviceId,
      fields
    });

    const method = data?.method?.toLowerCase();

    if (
      method === PAY_METHOD.IFRAME ||
      method === PAY_METHOD.GET ||
      method === PAY_METHOD.POST
    ) {
      return { ...data, method };
    }

    if (method === PAY_METHOD.MESSAGE) {
      if (data.message && typeof data.message === 'string') {
        infoNotification(data.message);
      } else {
        errorNotification(formatPaymentError(t, 'deposit.reject', data));
      }
    } else if (!data || data.error) {
      errorNotification(formatPaymentError(t, 'deposit.reject', data));
    } else {
      successNotification(t('deposit.verify'));
    }

    return null;
  };

  return { onDeposit, isLoading, isError };
};

export default useDeposit;
