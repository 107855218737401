import React, { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { InnerHTMLSnippet } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  BUTTON_SIZE,
  Checkbox,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  LinkButton
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TModalNotification = {
  popup: Record<string, any>;
  handleClose?: () => void;
  addToDontShow?: (e: ChangeEvent<HTMLInputElement>) => void;
  isWithCheckbox: boolean;
};

const CMSPopup: Component<TModalNotification> = ({
  handleClose,
  popup,
  addToDontShow,
  isWithCheckbox
}) => {
  const { id, href, target, content } = popup;

  const { t } = useI18n(NAMESPACES.FORMS);
  const { pathname } = useLocation();

  return (
    <>
      <FlexBox
        direction={FLEX_DIRECTION.column}
        className={styles.popupContentWrapper}
      >
        <a href={href} target={target} aria-label="snippet link">
          <InnerHTMLSnippet html={content} />
        </a>
        <FlexBox justify={FLEX_JUSTIFY.between}>
          <LinkButton
            to={pathname}
            onClick={handleClose}
            size={BUTTON_SIZE.h40}
            className={styles.button}
          >
            {t('popup.ok')}
          </LinkButton>
        </FlexBox>
      </FlexBox>
      {isWithCheckbox && (
        <FlexBox
          justify={FLEX_JUSTIFY.between}
          align={FLEX_ALIGN.center}
          className={styles.wrapper}
        >
          <Checkbox
            label={t('popup.dontshow')}
            id={id}
            name="dontshow"
            className={styles.checkbox}
            toneType={FIELD_TONE.light}
            onChange={addToDontShow}
          />
        </FlexBox>
      )}
    </>
  );
};
export default CMSPopup;
