import {
  TCMSResponse,
  TCommonCMSRequest,
  TPromotionCategory
} from '../../../types';

const getPromotionsCategories = () => ({
  query: ({ lang, partnerId }: TCommonCMSRequest) =>
    `${lang}/partners/${partnerId}/categories/promotion?platform=0`,
  transformResponse: (
    response: TCMSResponse<Array<TPromotionCategory>>
  ): TPromotionCategory[] => response.data
});

export default getPromotionsCategories;
