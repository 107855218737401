import React, { useCallback, useEffect } from 'react';
import { useMatch } from 'react-router-dom';

import { useIsAuthenticated } from '@monorepo/auth';
import { NOTIFICATIONS_LIST_TYPES, useToast } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';
import { getIdFromJID, SUBSCRIPTION_TYPE } from '@monorepo/xmpp';

import SocialNotification from '../../common/SocialNotification';
import {
  FRIENDS_MODAL_TIMEOUT,
  SOCIAL_NOTIFICATION_TYPE
} from '../../constants';
import { TDisplaySocialToast, TStoreNotification } from '../../types';
import {
  addRequestToStorage,
  getRequestsListFromStorage
} from '../../utils/requestsListStorage';
import useSelectActiveRoom from '../selectors/useSelectActiveRoom';
import useSelectRoster from '../selectors/useSelectRoster';
import useRosterActions from '../useRosterActions';

const useSocialToasts = (): TDisplaySocialToast => {
  const { generalRoutes } = useSiteSettings();
  const { isAuthenticated } = useIsAuthenticated();
  const { notifications } = generalRoutes;
  const matchNotificationSocial = useMatch({
    path: `${notifications}/${NOTIFICATIONS_LIST_TYPES.SOCIAL}`,
    end: false
  });
  const isNotificationSocial = Boolean(matchNotificationSocial);
  const { acceptSubscription } = useRosterActions();
  const { showToast, hideAllToasts } = useToast();
  const roster = useSelectRoster();
  const activeRoom = useSelectActiveRoom();

  useEffect(() => {
    if (isNotificationSocial) {
      hideAllToasts();
    }
  }, [isNotificationSocial]);

  const displayToast = useCallback(
    (notification: TStoreNotification) => {
      if (isNotificationSocial || !isAuthenticated) {
        return null;
      }
      const { id, type, userJid } = notification;

      return showToast(
        (props) => <SocialNotification {...notification} {...props} />,
        id,
        {
          autoClose: FRIENDS_MODAL_TIMEOUT,
          onClose: () => {
            if (type === SOCIAL_NOTIFICATION_TYPE.FRIEND_REQUEST) {
              addRequestToStorage(userJid);
            }
          }
        }
      );
    },
    [showToast, isNotificationSocial, isAuthenticated]
  );

  const handleFriendRequest = useCallback(
    (notification: TStoreNotification) => {
      const { userJid } = notification;
      const userId = getIdFromJID(userJid);
      const { subscription, jid, name, id } = roster?.[userId] || {};

      if (jid && subscription === SUBSCRIPTION_TYPE.TO) {
        return acceptSubscription(jid, name || id, false);
      }
      if (getRequestsListFromStorage()?.includes(userJid)) {
        return null;
      }
      return displayToast(notification);
    },
    [roster, acceptSubscription, displayToast]
  );

  const handleUnreadMessage = useCallback(
    (notification: TStoreNotification) => {
      if (activeRoom === notification.roomJid) {
        return null;
      }
      return displayToast(notification);
    },
    [displayToast, activeRoom]
  );

  return useCallback(
    (notification: TStoreNotification) => {
      if (notification.type === SOCIAL_NOTIFICATION_TYPE.FRIEND_REQUEST) {
        return handleFriendRequest(notification);
      }
      if (notification.type === SOCIAL_NOTIFICATION_TYPE.UNREAD_MESSAGE) {
        return handleUnreadMessage(notification);
      }
      if (
        notification.type === SOCIAL_NOTIFICATION_TYPE.COMMUNITY_INVITE ||
        notification.type === SOCIAL_NOTIFICATION_TYPE.COMMUNITY_DECLINE_INVITE
      ) {
        return displayToast(notification);
      }
      return null;
    },
    [handleUnreadMessage, handleFriendRequest, displayToast]
  );
};

export default useSocialToasts;
