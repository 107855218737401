import { useIsAuthenticated } from '@monorepo/auth';

import { MESSAGES_TYPES } from '../../constants';
import { useGetMessagesQuery } from '../../store/user';

const useGetUnreadMessages = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const { data, isFetching } = useGetMessagesQuery(
    {
      type: MESSAGES_TYPES.INCOMING
    },
    {
      skip: !isAuthenticated
    }
  );

  const unreadMessages = data?.filter((el) => el.checked === 0);

  return { unreadMessages, isFetching };
};

export default useGetUnreadMessages;
