import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useLocalActions } from '../../index';
import { useGetChildSessionMutation } from '../../store/user';

const useGetChildSession = () => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { setAuthData } = useLocalActions();
  const { errorNotification } = useNotifications();
  const [getChildSession, { isLoading }] = useGetChildSessionMutation();

  const onError = (error: string, prefix = '') => {
    errorNotification(t(`${prefix}.${error?.toLowerCase()}`));
  };

  const onGetChildSession = async (currencyId = '') => {
    const response = await getChildSession({ currencyId });
    const { data = {} } = response;
    if (data?.length === 0) {
      onError('error');
    } else {
      setAuthData({ authToken: data?.Token, userId: data?.UserId });
    }
  };

  return {
    onGetChildSession,
    isLoading
  };
};

export default useGetChildSession;
