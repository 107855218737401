import { createRoomJid, enterRoom } from '@monorepo/xmpp';

import { TEnterRoomRequest, TRoomRequest } from '../../../types';
import {
  parseMucUserPresence,
  parseStanzaTypeError
} from '../../../utils/stanzaParsers';
import { actions } from '../../socialsSlice';

export const enterQuery = {
  query: ({ roomName, nickname, history, password }: TEnterRoomRequest) =>
    enterRoom(createRoomJid(roomName), nickname, history, password),
  transformResponse: parseMucUserPresence,
  transformErrorResponse: parseStanzaTypeError
};

const enter = () => ({
  ...enterQuery,
  async onQueryStarted(
    { roomName }: TRoomRequest,
    { dispatch, queryFulfilled }
  ) {
    const patchResult = dispatch(actions.enterRoom(createRoomJid(roomName)));
    queryFulfilled.catch(patchResult.undo);
  }
});

export default enter;
