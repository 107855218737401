import { TMakeDeposit, TPaymentAction } from 'types';

const makeDeposit = () => ({
  query: ({
    currency,
    domain,
    balancePage,
    amount,
    serviceId,
    fields = {}
  }: TMakeDeposit) => ({
    body: {
      command: 'deposit',
      params: {
        amount,
        currency,
        service: serviceId,
        payer: {
          ...fields,
          status_urls: {
            cancel: `${domain}${balancePage}`,
            fail: `${domain}${balancePage}?status=fail`,
            success: `${domain}${balancePage}?status=success&amount=${amount}&currency=${currency}`
          }
        }
      }
    }
  }),
  transformResponse: (res: any) => (res?.details || {}) as TPaymentAction
});
export default makeDeposit;
