import React from 'react';

import { FlexBox, PaddingWrapper } from '@monorepo/ui-kit';

import HeaderPromo from '../HeaderPromo';

import styles from './index.module.css';

const PromoBlock = () => (
  <PaddingWrapper isOnlyMobile className={styles.root}>
    <FlexBox>
      <HeaderPromo className={styles.link} />
    </FlexBox>
  </PaddingWrapper>
);
export default PromoBlock;
