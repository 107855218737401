import React from 'react';

import { Component } from '@monorepo/type';
import { Box, Iframe } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  videoId: string;
};

const YoutubeVideo: Component<Props> = ({ videoId, className = '' }) => (
  <Box className={`${styles.wrap} ${className}`}>
    <Iframe
      className={styles.frame}
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Box>
);

export default YoutubeVideo;
