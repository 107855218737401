import React from 'react';

import { FIELD_TONE } from '../../../constants';
import { LabelProps } from '../../../types';

import styles from './index.module.css';

const Label = ({
  children,
  htmlFor,
  toneType = FIELD_TONE.dark,
  className = '',
  ...props
}: LabelProps) => (
  <label
    htmlFor={htmlFor}
    className={`${styles.label} ${styles[toneType]} ${className}`}
    {...props}
  >
    {children}
  </label>
);

export default Label;
