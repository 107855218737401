// import { memoize } from 'proxy-memoize';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { PlatformType /* , ShowTo */ } from '../../constants';
// import { useIsAuthenticated } from '@monorepo/auth';
import { useGetPromotionsListQuery } from '../../store';
import { TGetPromotionsRequest /* , TPromotion */ } from '../../types';

const useGetPromotionsList = ({
  paginate,
  isGetAll,
  ...params
}: Omit<
  TGetPromotionsRequest,
  'partnerId' | 'platform' | 'useWebp' | 'lang' | 'exclude' | 'limit'
> = {}) => {
  const { isIOSOld, isDesktop } = useDeviceDetector();
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();
  // const { isAuthenticated } = useIsAuthenticated();
  const defaultLimit = isDesktop ? 16 : 3;
  const limitAmount = isGetAll ? 0 : defaultLimit;

  return useGetPromotionsListQuery(
    {
      partnerId: siteId,
      lang: languageLongCode,
      useWebp: isIOSOld ? 0 : 1,
      platform: PlatformType.web,
      exclude: 'content',
      limit: limitAmount,
      paginate: paginate || 1,
      ...params
    }
    // {
    //   selectFromResult: memoize(({ data, isFetching }) => ({
    //     isFetching,
    //     data: (data || []).filter((item: TPromotion) =>
    //       isAuthenticated
    //         ? item.showTo !== ShowTo.guest
    //         : item.showTo !== ShowTo.authorized
    //     )
    //   }))
    // }
  );
};

export default useGetPromotionsList;
