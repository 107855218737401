import React from 'react';

import { AVATAR_SIZE } from '@monorepo/files';
import { CustomFC } from '@monorepo/type';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import FriendAvatar from '../../../../common/FriendAvatar';
import useChatUser from '../../../../hooks/useChatUser';

import styles from './index.module.css';

type Props = {
  id: string;
  isHorizontal?: boolean;
};

const AvatarWithName: CustomFC<Props> = ({ id, isHorizontal }) => {
  const classes = `${styles.item}
    ${isHorizontal ? styles.horizontal : styles.vertical}`;
  const { displayName } = useChatUser(id);

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={classes}
    >
      <FriendAvatar
        id={id}
        className={styles.avatar}
        statusClass={styles.status}
        avatarClass={styles.friendAvatar}
        size={AVATAR_SIZE.L}
        isWithCount
      />
      <EllipsisText className={styles.name}>{displayName}</EllipsisText>
    </FlexBox>
  );
};

export default AvatarWithName;
