import { useContext } from 'react';

import XMPPContext from '../../store/context';

const useXMPPInstance = () => {
  const { xmppInstance } = useContext(XMPPContext);

  return xmppInstance;
};

export default useXMPPInstance;
