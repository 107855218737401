import React, { Fragment } from 'react';

import {
  ComponentsConfigProvider,
  TPageComponentProps,
  usePageContext
} from '@monorepo/page';
import { CustomFC } from '@monorepo/type';

type Props = {
  componentIds?: string[];
};

const ComponentsArray: CustomFC<Props> = ({ componentIds = [] }) => {
  const { componentsMap, page } = usePageContext();

  if (page) {
    const { components } = page;

    return (
      <>
        {componentIds.map((componentId) => {
          const componentData = components[componentId];
          const type = componentData?.type;

          if (type && componentsMap[type]) {
            const Component: CustomFC<TPageComponentProps> =
              componentsMap[type];

            return (
              <Fragment key={componentId}>
                <ComponentsConfigProvider params={componentData.config}>
                  <Component config={componentData.config || {}} />
                </ComponentsConfigProvider>
              </Fragment>
            );
          }

          return null;
        })}
      </>
    );
  }

  return null;
};

export default ComponentsArray;
