import { useContext } from 'react';

import XMPPContext from '../../store/context';

const useIsServerOnline = () => {
  const { isServerOnline } = useContext(XMPPContext);

  return isServerOnline;
};

export default useIsServerOnline;
