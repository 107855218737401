const sendLog = () => ({
  query: (arg: any) => ({
    url: `/bot-api/${arg.type}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: arg
  })
});

export default sendLog;
