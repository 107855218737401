import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const ChatsTopic = lazy(() => import('./ChatsTopic'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ChatsTopic config={config} />
  </Suspense>
);
