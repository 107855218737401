import React, { useEffect } from 'react';
import { useHoory } from '@hoory/react';
import { Locales } from '@hoory/react/src/types';

import { useGetUser, useIsAuthenticated } from '@monorepo/auth';
import { usePlayerProfile } from '@monorepo/cms';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

const useHooryChat = () => {
  const { hooryKey } = useSiteSettings();
  const { isAuthenticated } = useIsAuthenticated();
  const player = usePlayerProfile();
  const nicknameFromApi = player?.data?.nickname;
  const { language } = useI18n();
  const { data: profile } = useGetUser();
  const { email: emailUser, id: profileId } = profile || {};

  const API = useHoory(hooryKey, {
    avatar: '/images/support.svg',
    manualLoad: true
  });

  useEffect(() => {
    API.runScript();
  }, []);

  useEffect(() => {
    if (API.hasLoaded) {
      API.setLocale((language as Locales) || 'en');
    }
  }, [language]);

  useEffect(() => {
    if (!isAuthenticated && API.hasLoaded) {
      API.reset();
    }
    if (isAuthenticated && API.hasLoaded && nicknameFromApi && emailUser) {
      API.setUser(profileId.toString(), {
        name: nicknameFromApi,
        email: emailUser
      });
    }
  }, [isAuthenticated, nicknameFromApi, emailUser]);

  return (
    <div>
      <button type="button" onClick={() => API.toggle()}>
        Toggle
      </button>
    </div>
  );
};
export default useHooryChat;
