import uniqueId from 'lodash/uniqueId';

import { getBrowserFingerprint } from '@monorepo/helpers';

const createStanzaId = (username?: string) => {
  if (username && username !== 'undefined') {
    return uniqueId(`xmpp/${username}/${Date.now()}-`);
  }

  return uniqueId(`xmpp/${getBrowserFingerprint()}/${Date.now()}-`);
};

export default createStanzaId;
