import { createElement, useEffect, useRef } from 'react';
import Odometer from 'tm-odometer';

import { CustomFC } from '@monorepo/type';

import { ReactOdometerProps } from './type';

import 'tm-odometer/themes/odometer-theme-minimal.css';

const OdometerJs: CustomFC<ReactOdometerProps> = ({
  animation,
  duration,
  format,
  theme,
  value,
  ...rest
}) => {
  const node = useRef<HTMLDivElement>(null);
  const odometer = useRef<Odometer>();

  useEffect(() => {
    odometer.current = new Odometer({
      el: node.current,
      auto: false,
      animation,
      duration,
      format,
      theme,
      value
    });
  }, []);

  useEffect(() => {
    odometer.current?.update(value);
  }, [value]);

  return createElement('div', {
    ...rest,
    ref: node
  });
};

export default OdometerJs;
