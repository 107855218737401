import { TGame } from '../../../../types';
import normalizeGame from '../../../../utils/normalizeGame';

const topGameAdapter = (initialResponse: { games: TGame[] }) => {
  const { games } = initialResponse;
  if (games.length) {
    const [topGame] = games;
    return normalizeGame(topGame);
  }
  return null;
};

export default topGameAdapter;
