import React, { useState } from 'react';

import { formatError, useGetUser } from '@monorepo/auth';
import { useEditProfileMutation } from '@monorepo/auth/src/store/user';
import { useChangeSeparator } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { Component, UseCountry } from '@monorepo/type';

import { REQUIRED_PAYMENT_DATA, SERVICE_TYPES } from '../../../constants';
import useDeposit from '../../../hooks/useDeposit';
import { TFormFieldValues, TPaymentAction } from '../../../types';
import DepositAction from '../DepositAction';
import DepositProfileFields from '../DepositProfileFields';
import PaymentsForm from '../PaymentsForm';

type Props = {
  serviceId: string;
  isWithProfileCheck?: boolean;
  countriesFromJSON: UseCountry[];
  defaultCountry: string;
};
const DepositForm: Component<Props> = ({
  serviceId,
  isWithProfileCheck = false,
  countriesFromJSON,
  defaultCountry
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { data: profile } = useGetUser();
  const { errorNotification } = useNotifications();
  const { onDeposit, isLoading } = useDeposit();
  const { amountMaskToNumber } = useChangeSeparator();
  const [onEdit, { isLoading: isLoadingEdit }] = useEditProfileMutation();
  const [actionData, setActionData] = useState<TPaymentAction | null>(null);
  const isShowDepositFields =
    isWithProfileCheck && countriesFromJSON && countriesFromJSON.length !== 0;
  const submitHandler = async (userData: TFormFieldValues) => {
    const {
      amount,
      [REQUIRED_PAYMENT_DATA.FIRST_NAME]: firstName,
      [REQUIRED_PAYMENT_DATA.LAST_NAME]: lastName,
      [REQUIRED_PAYMENT_DATA.PHONE]: rawPhone,
      [REQUIRED_PAYMENT_DATA.PASSWORD]: password,
      ...fields
    } = userData;

    if (!profile.firstName || !profile.lastName || !profile.phone) {
      const result = await onEdit({
        ...profile,
        rawPhone,
        firstName,
        lastName,
        password
      });

      // @ts-ignore
      if (result?.error) {
        // @ts-ignore
        const formattedError = formatError(result?.error.code);
        errorNotification(t(formattedError?.message || ''));
        return;
      }
    }

    const data = await onDeposit({
      amount: amountMaskToNumber(amount),
      fields,
      serviceId
    });
    setActionData(data);
  };

  if (actionData) {
    return (
      <DepositAction
        actionData={actionData}
        setResult={() => setActionData(null)}
      />
    );
  }

  return (
    <PaymentsForm
      onSubmit={submitHandler}
      serviceId={serviceId}
      serviceType={SERVICE_TYPES.DEPOSIT}
      isLoading={isLoading || isLoadingEdit}
      additionalValues={{
        [REQUIRED_PAYMENT_DATA.FIRST_NAME]: '',
        [REQUIRED_PAYMENT_DATA.LAST_NAME]: '',
        [REQUIRED_PAYMENT_DATA.PHONE]: '',
        [REQUIRED_PAYMENT_DATA.PASSWORD]: ''
      }}
    >
      {isShowDepositFields && (
        <DepositProfileFields
          profile={profile}
          countriesFromJSON={countriesFromJSON}
          defaultCountry={defaultCountry}
        />
      )}
    </PaymentsForm>
  );
};

export default DepositForm;
