import React, { ElementRef, useRef, useState } from 'react';

import { Form, InputField, requiredValidator } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Box,
  Button,
  BUTTON_SIZE,
  FIELD_TONE,
  useShutterRef
} from '@monorepo/ui-kit';

import SubmitButton from '../../common/SubmitButton';
import useTwoFactorDisable from '../../hooks/useTwoFactorDisable';
import TwoFactorWrapper from '../TwoFactorWrapper';

import styles from './index.module.css';

const TwoFactorDisable = ({
  toneType = FIELD_TONE.dark
}: {
  toneType?: FIELD_TONE;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const formContextRef = useRef<ElementRef<typeof Form>>(null);
  const { onDisableTwoFactor, isLoading } = useTwoFactorDisable();

  const defaultValues = {
    password: ''
  };
  const [showMore, setIsShowMore] = useState(false);
  const { setShow, setHide, shutterRef } = useShutterRef();
  const handleChange = () => {
    setHide();
    setIsShowMore((prevState) => !prevState);
  };

  const save = async () => {
    const mainData = formContextRef.current?.getValues();
    try {
      return onDisableTwoFactor(mainData?.password, formContextRef);
    } catch (e) {
      return false;
    }
  };

  return (
    <Box className={`${styles.wrap} ${styles[toneType]}`}>
      <TwoFactorWrapper
        handleChange={handleChange}
        toneType={FIELD_TONE.light}
        handleClose={setHide}
        shutterRef={shutterRef}
      />
      <Box className={styles.head}>{t('two.factor.deactivate.header')}</Box>
      {showMore ? (
        <Form
          ref={formContextRef}
          formSettings={{
            mode: 'onChange',
            defaultValues
          }}
        >
          <Box className={styles.description}>
            {t('two.factor.enter.password.disable')}
          </Box>
          <InputField
            className={styles.input}
            id="password"
            name="password"
            type="password"
            label={t('two.factor.enter.password.label')}
            placeholder={t('two.factor.enter.password.please')}
            rules={{ ...requiredValidator() }}
            toneType={toneType}
          />
          <SubmitButton
            size={BUTTON_SIZE.h30}
            processing={isLoading}
            onClick={save}
          >
            {t('two.factor.submit')}
          </SubmitButton>
        </Form>
      ) : (
        <Button
          onClick={setShow}
          size={BUTTON_SIZE.h30}
          className={styles.button}
        >
          {t('two.factor.disable.button')}
        </Button>
      )}
    </Box>
  );
};

export default TwoFactorDisable;
