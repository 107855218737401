import Element from 'ltx/src/Element';

import { instance } from '../../hooks/useXMPPConnect';

const xmppBaseQuery = () => async (stanza: Element) => {
  try {
    const data = await instance.send(stanza);
    return { data };
  } catch (error) {
    return { error };
  }
};

export default xmppBaseQuery;
