import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import LineCompetitionsListSkeleton from '../LineCompetitionsList/LineCompetitionsListSkeleton';
import LineSportHeaderSkeleton from '../LineSportHeader/LineSportHeaderSkeleton';

import styles from './index.module.css';

type Props = {
  length?: number;
  withSportHeader?: boolean;
  isGroupedByDate?: boolean;
  sportAlias: string;
};

const LineSportsListSkeleton: Component<Props> = ({
  length = 1,
  withSportHeader = false,
  isGroupedByDate,
  sportAlias,
  className = ''
}) => (
  <Box className={className}>
    {createSkeletonArray(length).map((item: number) => (
      <Box key={item} className={styles.item}>
        {withSportHeader && <LineSportHeaderSkeleton />}
        <LineCompetitionsListSkeleton
          isGroupedByDate={isGroupedByDate}
          length={withSportHeader ? 2 : 1}
          sportAlias={sportAlias}
        />
      </Box>
    ))}
  </Box>
);

export default LineSportsListSkeleton;
