import React, { ReactElement } from 'react';

import { ComponentWithConfig } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox, Iframe } from '@monorepo/ui-kit';

import useGetEventFullStatistics from '../../hooks/useGetEventFullStatistics';

import styles from './index.module.css';

type TEventStatsProps = {
  gameId: number;
};

const EventStatistics: ComponentWithConfig<{}, TEventStatsProps> = ({
  gameId
}): ReactElement => {
  const statisticsUrl = useGetEventFullStatistics({ gameId });

  return (
    <FlexBox justify={FLEX_JUSTIFY.center} align={FLEX_ALIGN.center}>
      <Iframe src={statisticsUrl} className={styles.iframe} />
    </FlexBox>
  );
};

export default EventStatistics;
