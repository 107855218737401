import React from 'react';

import { usePageContext } from '@monorepo/page';
import { classNameProp, CustomFC } from '@monorepo/type';
import { FlexBox } from '@monorepo/ui-kit';

import Column from '../Column';

import styles from './index.module.css';

const MultiColumn: CustomFC<classNameProp> = ({ className = '' }) => {
  const { page } = usePageContext();
  if (page) {
    const { layout } = page;
    const { columns, type } = layout;

    return (
      <FlexBox className={`${styles.wrap} ${styles[type] || ''} ${className}`}>
        {columns.map((column: string[]) => (
          <Column
            key={column.join('/')}
            column={column}
            className={styles.column}
          />
        ))}
      </FlexBox>
    );
  }

  return null;
};

export default MultiColumn;
