import get from 'lodash/get';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { createIdsQuery } from '../../utils';
import gameUpdater from '../updaters/gameUdapter';

const getGameDetailsForHistory = (api) => ({
  query: ({ ids }) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          game: ['_parent_id', 'region_alias', 'info', 'text_info']
        },
        where: {
          game: {
            id: createIdsQuery(ids)
          }
        },
        subscribe: true
      }
    }
  }),
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => gameUpdater(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.GAME]))
      );
      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse: (rawResponse: any) => {
    const game = get(rawResponse, 'data.game', null);
    const subid = get(rawResponse, 'subid', '');

    return {
      game,
      subid
    };
  }
});
export default getGameDetailsForHistory;
