import { TOURNAMENT_TYPE_ID, TOURNAMENTS_QUERY_KEYS } from '../../../constants';
import {
  TCasinoTournament,
  TGetTournamentsListRequest,
  TTournamentsResponse
} from '../../../types';
import getTournamentsAdapter from '../adapters/getTournamentsAdapter';

const getTournaments = () => ({
  query: ({ stageList }: TGetTournamentsListRequest) => ({
    body: {
      command: 'get_tournament_list',
      params: {
        stage_list: stageList,
        product_type_id: TOURNAMENT_TYPE_ID.CLASSIK
      }
    }
  }),
  keepUnusedDataFor: 0,
  providesTags: [TOURNAMENTS_QUERY_KEYS.TOURNAMENT_LIST],
  transformResponse(response: TTournamentsResponse): TCasinoTournament[] {
    return !response ? [] : getTournamentsAdapter(response);
  }
});
export default getTournaments;
