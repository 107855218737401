import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';

import Item from '../Item';

type Props = {
  className: string;
  items: { title: string; link: string }[];
  replace?: boolean;
};

const HeaderNavMenuItems = ({ items, replace, className }: Props) => {
  const { t } = useI18n(NAMESPACES.DYNAMIC);

  return (
    <>
      {items.map(({ title, link }) => (
        <Item
          key={title}
          replace={replace}
          title={t(title)}
          link={link}
          className={className}
        />
      ))}
    </>
  );
};

export default HeaderNavMenuItems;
