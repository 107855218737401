import Element from 'ltx/src/Element';

import { IQ_TYPE, XML_NAMESPACES } from '@monorepo/xmpp';

import { TSocialsActions } from '../../../../types';
import IqErrorHandler from '../IqErrorHandler';
import IqMamHandler from '../IqMamHandler';
import IqMamSentHandler from '../IqMamSentHandler';
import RosterHandler from '../RosterHandler';

class IqHandler {
  reduxActions: TSocialsActions;

  constructor(reduxActions: TSocialsActions) {
    this.reduxActions = reduxActions;
  }

  public processStanza = (stanza: Element) => {
    const { type } = stanza.attrs;
    const [firstChild] = stanza.getChildElements();
    let handler = null;

    if (type === IQ_TYPE.ERROR) {
      handler = new IqErrorHandler();
    } else if (firstChild?.is('query', XML_NAMESPACES.ROSTER)) {
      handler = new RosterHandler(this);
    } else if (firstChild?.is('fin', XML_NAMESPACES.MAM)) {
      handler = new IqMamHandler(this);
    } else {
      // console.log(`Unprocessed iq ${stanza}`);
    }

    return handler?.processStanza(stanza);
  };

  public processSentStanza = (stanza: Element) => {
    const [firstChild] = stanza.getChildElements();
    let handler = null;

    if (firstChild?.is('query', XML_NAMESPACES.MAM)) {
      handler = new IqMamSentHandler(this);
    } else {
      // console.log(`Unprocessed sent iq ${stanza}`);
    }

    return handler?.processStanza(stanza);
  };
}

export default IqHandler;
