import React from 'react';

import { usePageContext } from '@monorepo/page';
import { classNameProp, CustomFC } from '@monorepo/type';

import Column from '../Column';

const OneColumn: CustomFC<classNameProp> = ({ className = '' }) => {
  const { page } = usePageContext();

  if (page) {
    const { layout } = page;
    const { columns } = layout;

    return <Column column={columns[0]} className={className} />;
  }

  return null;
};

export default OneColumn;
