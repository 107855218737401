import React, { useEffect, useRef } from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { googleAnalytics } from '@monorepo/helpers';
import { usePageContext } from '@monorepo/page';
import { CustomFC } from '@monorepo/type';
import { SiteLoader } from '@monorepo/ui-kit';

import Layout from '../Layout';

const Page: CustomFC = () => {
  const { page } = usePageContext();
  const { isAuthFetched } = useIsAuthenticated();
  /// ga js-load-time
  const loadTimeSended = useRef<boolean>(false);

  useEffect(() => {
    if (isAuthFetched && page && !loadTimeSended.current) {
      const ga = googleAnalytics();
      // @ts-ignore
      const time = new Date().getTime() - window.pageStart || 0;
      ga.dispatch({
        event: ga.event.jsLoadTime,
        eventParam: {
          event_category: 'js'
        },
        event_options: {
          url: window.location.href,
          time
        }
      });
      loadTimeSended.current = true;
    }
  }, [isAuthFetched, page]);

  return !isAuthFetched || !page ? <SiteLoader /> : <Layout page={page} />;
};

export default Page;
