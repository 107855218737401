import { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useIsAuthenticated } from '@monorepo/auth';
import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';

import { LAUNCH_TYPE } from '../../constants';
import getLaunchUrl from '../../utils/getLaunchUrl';

const useGameControls = (id: string) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useIsAuthenticated();
  const [searchParams] = useSearchParams();
  const { generalRoutes } = useSiteSettings();
  const { login, casinoLauncher, casinoCategory } = generalRoutes;
  const location = useLocation();
  const { pathname, search: searchPath } = location;
  const pathUrl = encodeURIComponent(`${pathname}${searchPath}`);
  const backPathEncoded = `${SEARCH_STRING_PARAMS.backPath}=${pathUrl}`;
  const innerBackPath =
    searchParams.get(SEARCH_STRING_PARAMS.backPath) || casinoCategory;

  const onRealPlay = useCallback(() => {
    const launchUrl = getLaunchUrl(id, LAUNCH_TYPE.REAL, casinoLauncher);
    const launchExtra = `${launchUrl}&${SEARCH_STRING_PARAMS.backPath}=${pathUrl}`;
    const innerPath =
      pathname === casinoLauncher
        ? `${launchUrl}&${SEARCH_STRING_PARAMS.backPath}=${innerBackPath}`
        : launchExtra;
    const routePath = pathname === casinoLauncher ? innerBackPath : pathUrl;

    if (!isAuthenticated) {
      const search = new URLSearchParams({
        [SEARCH_STRING_PARAMS.redirectUrl]: launchUrl
      });

      return navigate(
        `${login}?${search.toString()}&${
          SEARCH_STRING_PARAMS.backPath
        }=${routePath}`
      );
    }

    return navigate(innerPath, { replace: true });
  }, [isAuthenticated, id, backPathEncoded]);

  const onFreePlay = useCallback(() => {
    const launchUrl = getLaunchUrl(id, LAUNCH_TYPE.DEMO, casinoLauncher);
    const launchExtra = `${launchUrl}&${SEARCH_STRING_PARAMS.backPath}=${pathUrl}`;
    const innerPath =
      pathname === casinoLauncher
        ? `${launchUrl}&${SEARCH_STRING_PARAMS.backPath}=${innerBackPath}`
        : launchExtra;

    return navigate(innerPath, { replace: true });
  }, [id, backPathEncoded]);

  const onClose = useCallback(() => {
    if (location.key === 'default') {
      return navigate(casinoCategory);
    }
    return navigate(innerBackPath, { replace: true });
  }, [id, backPathEncoded]);

  return { onFreePlay, onRealPlay, onClose };
};

export default useGameControls;
