import React from 'react';
import { NavLink } from 'react-router-dom';

import { classNameProp, CustomFC } from '@monorepo/type';

type Props = {
  link: string;
  activeClassName?: string;
  disabled?: boolean;
  target?: string;
} & classNameProp;

const Item: CustomFC<Props> = ({
  children,
  link = '',
  className = '',
  activeClassName = '',
  target = '',
  disabled = false,
  ...props
}) => {
  if (disabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <NavLink
      to={link}
      target={target}
      className={({ isActive }) =>
        `${className} ${isActive ? activeClassName : ''}`
      }
      {...props}
    >
      {children}
    </NavLink>
  );
};

export default Item;
