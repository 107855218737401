import { WALLET_QUERY_KEYS } from '../../../constants';
import {
  TWithdrawableBalance,
  TWithdrawableBalanceResponce
} from '../../../types';
import withdrawableBalanceAdapter from '../adapters/withdrawableBalanceAdapter';

const getWithdrawableBalance = () => ({
  query: () => ({
    body: {
      command: 'get_client_withdrawable_balance',
      params: {
        product: 'Sport'
      }
    }
  }),
  providesTags: [WALLET_QUERY_KEYS.GET_BALANCE],
  transformResponse(
    response: TWithdrawableBalanceResponce
  ): TWithdrawableBalance {
    return !response
      ? {
          limit: null,
          unplayedAmount: null,
          unplayedFee: null,
          availableBalance: null
        }
      : withdrawableBalanceAdapter(response);
  }
});
export default getWithdrawableBalance;
