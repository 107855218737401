import regExpValidator from './regExpValidator';

const passwordRegExpValidator = (
  message: string = 'validators.password.format'
) => {
  const regexp = new RegExp(
    '^(?=(?:.*[A-Z])+)(?=(?:.*[a-z])+)(?=(?:.*\\d)+)(?=(?:.*[!@#$%?_,.])+)([A-Za-z0-9!@#$%?_,.]{8,64})$'
  );

  return regExpValidator(regexp, message);
};

export default passwordRegExpValidator;
