import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useActionCreators = (actions) => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      Object.keys(actions).reduce(
        (acc, action) => ({
          ...acc,
          [action]: (payload) => dispatch(actions[action](payload))
        }),
        {} as typeof actions
      ),
    [actions, dispatch]
  );
};

export default useActionCreators;
