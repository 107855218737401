import { TOptionItem } from '@monorepo/type';

const search = (
  query: string,
  initialData?: Array<TOptionItem>
): Array<TOptionItem> => {
  const findIntersection = (test: string) =>
    test.toLowerCase().includes(query.toLowerCase());
  return (initialData || []).filter(({ label }) =>
    findIntersection(label as string)
  );
};

export default search;
