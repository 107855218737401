import { camelizeKeys } from 'humps';

import { TMessageResponse, TMessages } from '../../../../types';

const getMessagesAdapter = ({ messages }: TMessageResponse): TMessages => {
  const result = camelizeKeys(messages).map(
    ({ body, checked, date, id, isDeletable, subject, threadId }) => ({
      content: body,
      checked,
      id,
      date,
      isDeletable,
      threadId,
      title: subject
    })
  );
  return result;
};
export default getMessagesAdapter;
