import React, { forwardRef, memo } from 'react';

import { DivProps } from '@monorepo/ui-kit';

import styles from './index.module.css';

const GameListWrapper = memo(
  forwardRef<HTMLDivElement, DivProps>(
    ({ className = '', children, ...rest }, ref) => (
      <div ref={ref} className={`${className} ${styles.wrapper}`} {...rest}>
        {children}
      </div>
    )
  )
);

export default GameListWrapper;
