import Element from 'ltx/src/Element';

import IqHandler from '../../handlers/IqHandler';

const iqStanzaCallback = (iqHandler: IqHandler) => (stanza: Element) => {
  if (stanza.is('iq')) {
    return iqHandler.processStanza(stanza);
  }
  return stanza;
};

export default iqStanzaCallback;
