import React from 'react';

import { Component } from '@monorepo/type';
import {
  createBareJidFromId,
  createRoomJid,
  MESSAGES_TYPES
} from '@monorepo/xmpp';

import useSelectRoomInbox from '../../hooks/selectors/useSelectRoomInbox';
import CountUI from '../CountUI';

type Props = {
  id: string;
  type: MESSAGES_TYPES;
};

const UnreadMessageCount: Component<Props> = ({ id, type, className = '' }) => {
  const jid =
    type === MESSAGES_TYPES.SINGLE
      ? createBareJidFromId(id)
      : createRoomJid(id);
  const item = useSelectRoomInbox(jid);

  const { unread = '' } = item || {};

  if (Number(unread)) {
    return <CountUI className={className}>{unread}</CountUI>;
  }

  return null;
};

export default UnreadMessageCount;
