import { googleAnalytics } from '@monorepo/helpers';

import { actions } from '../../index';

const requestLogout = () => ({
  query: () => ({
    method: 'DELETE',
    url: '/player',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  async onQueryStarted(arg: undefined, { dispatch, queryFulfilled }) {
    const { setIsCookiesFetched } = actions;
    try {
      await queryFulfilled;
      dispatch(
        setIsCookiesFetched({
          isCookiesFetched: false
        })
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // ga  js-error
      const ga = googleAnalytics();
      ga.dispatch({
        event: ga.event.jsError,
        eventParam: {
          event_category: 'js'
        },
        event_options: {
          message: (e as any)?.message,
          data: e
        }
      });
    }
  }
});

export default requestLogout;
