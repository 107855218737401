import { TGetLimit } from '../../../types';
import getUserLimitsAdapter from '../adapters/getUserLimits';

const getUserLossLimits = () => ({
  query: () => ({
    body: {
      command: 'get_sport_loss_limit',
      params: {}
    }
  }),
  transformResponse(response): Promise<TGetLimit> | TGetLimit {
    return getUserLimitsAdapter(response);
  }
});
export default getUserLossLimits;
