import React, { useMemo } from 'react';

import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import { MARKET_TYPES, MARKETS_ORDER_MAP } from '../../constants';
import useLineParameters from '../../hooks/useLineParameters';
import useMiddleScreen from '../../hooks/useMiddleScreen';
import LineEmptyMarket from '../LineEmptyMarket';

import styles from './index.module.css';

type Props = {
  isSuspended?: boolean;
  isFetching?: boolean;
  sportAlias: string;
  listClassName?: string;
};

const LineEmptyMarketsList: Component<Props> = ({
  isFetching,
  isSuspended,
  sportAlias,
  className = '',
  listClassName = ''
}) => {
  const { marketType = MARKET_TYPES.SINGLE } = useLineParameters();
  const displayKeys = useMemo(
    () => MARKETS_ORDER_MAP[marketType],
    [marketType]
  );

  const middleScreen = useMiddleScreen();
  const columnShow = middleScreen ? styles.showTwo : '';

  return (
    <Box className={`${styles.list} ${className} ${columnShow}`}>
      {displayKeys.map((displayKey) => (
        <Box key={displayKey} className={`${styles.list} ${listClassName}`}>
          <LineEmptyMarket
            isFetching={isFetching}
            isSuspended={isSuspended}
            displayKey={displayKey}
            sportAlias={sportAlias}
            className={listClassName}
          />
        </Box>
      ))}
    </Box>
  );
};

export default LineEmptyMarketsList;
