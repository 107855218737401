import { TUploadTypeRequest } from '../../../types';

const uploadFile = () => ({
  query: ({ sizes, source, async = false, file }: TUploadTypeRequest) => {
    const data = new FormData();
    data.append('file', file);

    return {
      url: `/api/v1/file?sizes=${sizes.join(
        ','
      )}&source=${source}&async=${async}`,
      method: 'POST',
      body: data
    };
  }
});

export default uploadFile;
