import { useNavigate } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

import { FIELD_TO_ERROR_MAP } from '../../constants';
import { useSignUpMutation } from '../../store/user';
import { TRegisterError, TRegisterRequest } from '../../types';
import useSignUpActions from '../useSignUpActions';

const useRegister = (
  onError: (data: any) => void,
  fieldsToErrorMap = FIELD_TO_ERROR_MAP,
  onSuccessCustom?: () => void
) => {
  const { generalRoutes } = useSiteSettings();
  const { needToVerifyEmail } = generalRoutes || {};
  const navigate = useNavigate();
  const [signUp, { isLoading, isError }] = useSignUpMutation();
  const { t } = useI18n(NAMESPACES.FORMS);
  const { errorNotification } = useNotifications();
  const { resetSignUpData } = useSignUpActions();

  const formatError = (code: string) => {
    const field = fieldsToErrorMap[code];
    return field
      ? { field, message: `error.${`${code}`.replace('-', '')}` }
      : null;
  };
  const onErrorDefault = ({ code, message = '' }: TRegisterError) => {
    const formattedError = formatError(code);
    errorNotification(
      t(formattedError?.message || `registration.form.${message.toLowerCase()}`)
    );
    onError?.(formattedError);
  };

  const onSuccess = (userInfo: TRegisterRequest) => {
    navigate(needToVerifyEmail, {
      state: {
        email: userInfo.email
      }
    });
  };

  const onSuccessHandler = onSuccessCustom || onSuccess;

  const onRegister = async (userInfo: TRegisterRequest) => {
    const { error } = await signUp(userInfo);
    if (error) {
      onErrorDefault?.(error);
    } else {
      resetSignUpData(undefined);
      onSuccessHandler?.(userInfo);
    }
  };

  return { onRegister, isLoading, isError, formatError };
};

export default useRegister;
