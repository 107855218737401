import { presenceCreator, TPresence, useXMPPInstance } from '@monorepo/xmpp';

// it can be used also for setting text status of user
const useSendStatus = () => {
  const xmpp = useXMPPInstance();

  return ({ status, show, to }: TPresence) =>
    xmpp.send(presenceCreator({ status, show, to }));
};

export default useSendStatus;
