import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetRegionsRequest } from '../../../types';
import {
  calcTimeFilter,
  createCompetitionWhereIdInQuery,
  createIdsQuery,
  createSearchCompetitionsNameQuery
} from '../../utils';
import { normalizeRegionsResponse } from '../adapters/regionsAdapter';
import regionUpdater from '../updaters/regionUpdater';

const getRegions = (api) => ({
  query: ({
    gameType,
    sportAlias,
    time,
    search,
    competitions,
    languages,
    gameIds = []
  }: TGetRegionsRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          region: ['id', 'alias', 'name', 'order'],
          competition: ['id', 'name', 'order'],
          game: '@count'
        },
        where: {
          game: {
            type: gameType, // Prematch or live
            ...calcTimeFilter(time),
            ...(gameIds?.length && { id: createIdsQuery(gameIds) })
          },
          ...createSearchCompetitionsNameQuery(search, languages),
          ...createCompetitionWhereIdInQuery(competitions),
          sport: {
            alias: sportAlias
          }
        },
        subscribe: true
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.REGIONS],
  transformResponse: (response: any) => normalizeRegionsResponse(response),
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => regionUpdater(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.REGIONS]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
});
export default getRegions;
