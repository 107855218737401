export enum LAYOUT_TYPE {
  ONE_COLUMN = 'oneColumn',
  ONE_COLUMN_SIDE = 'oneColumnSide',
  TWO_COLUMN_50X50 = 'twoColumns_50X50',
  TWO_COLUMN_FLUID = 'twoColumns_fluid',
  PERSONAL_OFFICE = 'personal_office',
  BACK_OFFICE = 'back_office',
  AUTH_PAGE = 'auth_page',
  SPORT_PAGE = 'sport_page',
  WITH_SIDEBAR = 'with_sidebar',
  CHATS = 'chats'
}

export enum USER_TYPES {
  USER = 'user',
  GUEST = 'guest',
  ALL = 'all'
}
