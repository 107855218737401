import { camelizeKeys } from 'humps';

import { PROVIDER_TYPE } from '../../../../constants';
import {
  TExternalJackpots,
  TExternalJackpotsItem,
  TGetExternalJackpotsResponse
} from '../../../../types';

const getExternalJackpotsAdapter = ({
  Data
}: TGetExternalJackpotsResponse): TExternalJackpots =>
  Data.map((item) => {
    const camelizedData = camelizeKeys(item) as Omit<
      TExternalJackpotsItem,
      'type'
    >;
    return { ...camelizedData, type: PROVIDER_TYPE.EXTERNAL };
  });

export default getExternalJackpotsAdapter;
