import { TSearchFavoriteTeamRequest } from '../../../types';
import { selectFavoriteTeam } from '../adapters/selectFavoriteTeam';

const statsApiId = process.env.REACT_APP_STATS_API_ID;
const key = process.env.REACT_APP_STATS_API_KEY;

const searchFavoriteTeam = () => ({
  query: ({ search, language }: TSearchFavoriteTeamRequest) =>
    `${language}/${key}/${statsApiId}/Utility/GetTeamSearchResult?searchString=${search}&take=20`,
  transformResponse: selectFavoriteTeam
});
export default searchFavoriteTeam;
