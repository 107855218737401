import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useConvertAmount } from '@monorepo/common';
import {
  AmountField,
  amountFieldValidator,
  Form,
  requiredValidator,
  useChangeSeparator
} from '@monorepo/forms';
import { NAMESPACES, useCurrencyTransform, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import {
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Radio,
  UI_SIZES
} from '@monorepo/ui-kit';

import BetslipSettings from '../../../../common/Betslip/components/BetslipSettings';
import { cashOutItems, settingItems } from '../../../../constants';
import useActions from '../../../../hooks/useActions';
import useMakeCashOut from '../../../../hooks/useMakeCashOut';
import { useSelectOddMode } from '../../../../store/api/selectors/betslipSelector';
import { TBets } from '../../../../types';
import SubmitButton from '../../../SubmitButton';

import styles from './index.module.css';

type TCashOutForm = {
  betItem: TBets;
  showCashOutChange: boolean;
  isPartialCashOut?: boolean;
  closeModal: () => void;
};

type FieldValues = {
  amount: string;
};

const CashOutForm: CustomFC<TCashOutForm> = ({
  betItem,
  isPartialCashOut = true,
  showCashOutChange,
  closeModal
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const [fullCashOut, partialCashOut] = cashOutItems;
  const [activeSetting, setActiveSetting] = useState(settingItems[0]);
  const { label } = activeSetting;
  const { currency, cashOut, id } = betItem;
  const currentCurrency = useCurrencyTransform(currency);
  const [activeCashOutType, setActiveCashOutType] = useState(fullCashOut.value);
  const { setOddMode } = useActions();
  const oddMode = useSelectOddMode();
  const { isDesktop } = useDeviceDetector();
  const { onCashOut } = useMakeCashOut();
  const betslipStyles = isDesktop ? styles.classModal : '';
  const defaultValues = {
    amount: ''
  };
  const { amountMaskToNumber } = useChangeSeparator();
  const handleChangeCashOutType = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setActiveCashOutType(
      Number(value) === partialCashOut.value
        ? partialCashOut.value
        : fullCashOut.value
    );
  };

  const submitHandler = (userData: FieldValues) => {
    const { amount } = userData;
    const outAmount =
      activeCashOutType === fullCashOut.value ? '' : amountMaskToNumber(amount);
    onCashOut({
      id,
      price: cashOut,
      mode: oddMode,
      amountOut: outAmount
    });
    closeModal();
  };
  const cashOutAmount = useConvertAmount(cashOut);

  useEffect(() => {
    setOddMode(0);
  }, []);

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrapper}>
      {showCashOutChange && (
        <FlexBox
          justify={FLEX_JUSTIFY.between}
          align={FLEX_ALIGN.center}
          className={styles.head}
          mb={UI_SIZES.s}
        >
          <span className={styles.amountChange}>
            {t('sportsbook.modal.cash_out.amount.change')}
          </span>
          <FlexBox align={FLEX_ALIGN.center}>
            <span className={styles.noChange}>{t(`${label}`)}</span>
            <BetslipSettings
              isLight={isDesktop}
              classModal={betslipStyles}
              setActiveSetting={setActiveSetting}
            />
          </FlexBox>
        </FlexBox>
      )}
      <Form
        className={styles.form}
        onSubmit={submitHandler}
        formSettings={{ defaultValues, mode: 'onChange' }}
      >
        <FlexBox direction={FLEX_DIRECTION.column}>
          <FlexBox
            className={`${styles.fullWrapper} ${styles[FIELD_TONE.light]}`}
            justify={FLEX_JUSTIFY.between}
          >
            <Radio
              className={styles.input}
              name="full"
              label={t(`${fullCashOut.label}`)}
              value={fullCashOut.value}
              onChange={handleChangeCashOutType}
              checked={activeCashOutType === fullCashOut.value}
              toneType={FIELD_TONE.light}
            />
            {activeCashOutType === fullCashOut.value ? (
              <span className={styles.fullAmount}>
                {cashOutAmount} {currentCurrency}
              </span>
            ) : null}
          </FlexBox>
          {isPartialCashOut && (
            <FlexBox
              className={styles.partialWrapper}
              direction={FLEX_DIRECTION.column}
            >
              <Radio
                className={styles.input}
                name="partial"
                label={t(`${partialCashOut.label}`)}
                value={partialCashOut.value}
                onChange={handleChangeCashOutType}
                checked={activeCashOutType === partialCashOut.value}
                toneType={FIELD_TONE.light}
              />
              {activeCashOutType === partialCashOut.value ? (
                <FlexBox
                  className={styles.amountWrapper}
                  direction={FLEX_DIRECTION.column}
                >
                  <span className={styles.amountText}>
                    {t('sportsbook.modal.cash_out.amount')}
                  </span>
                  <AmountField<FieldValues>
                    className={styles.inputAmount}
                    inputClassName={styles.inputClassName}
                    placeholder={t('sportsbook.modal.enter.cash_out.amount')}
                    id="amount"
                    name="amount"
                    toneType={FIELD_TONE.light}
                    type="text"
                    rules={{
                      ...requiredValidator(),
                      ...amountFieldValidator(
                        {
                          min: {
                            value: 0.01,
                            currency,
                            message: 'sportsbook.betslip.bet.minbeterror'
                          },
                          max: {
                            value: cashOut,
                            currency,
                            message: 'sportsbook.betslip.bet.maxbeterror'
                          }
                        },
                        amountMaskToNumber
                      )
                    }}
                  />
                  <span className={styles.limit}>
                    0,01 {currentCurrency} - {cashOutAmount}
                    {currentCurrency}
                  </span>
                </FlexBox>
              ) : null}
            </FlexBox>
          )}
        </FlexBox>

        <SubmitButton isMain={activeCashOutType === fullCashOut.value}>
          {t('sportsbook.modal.cash_out')}
        </SubmitButton>
      </Form>
    </FlexBox>
  );
};

export default CashOutForm;
