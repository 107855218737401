import merge from 'lodash/merge';

import { getSocketJackpot } from '@monorepo/websocket';

import {
  TSubscribeToExternalJackpot,
  TSubscribeToExternalJackpotRequest,
  TSubscribeToExternalJackpotResponse
} from '../../../types';
import subscribeToExternalJackpotAdapter from '../adapters/subscribeToExternalJackpotAdapter';

const subscribeToExternalJackpot = (casinoJackpots: any) => ({
  query: ({
    jackpotId,
    currency,
    partnerId,
    intensity
  }: TSubscribeToExternalJackpotRequest) => ({
    body: {
      Command: 'subscribetoexternaljackpot',
      Data: {
        JackPotId: [jackpotId],
        PartnerId: partnerId, // TODO change to our partnerId
        Currency: currency,
        Intensity: intensity
      }
    }
  }),
  keepUnusedDataFor: 0,
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const casinoJackpotInstance = getSocketJackpot();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      casinoJackpotInstance.subscribe(data?.RequestId, (newData) => {
        updateCachedData((draft) => merge(draft, newData));
      });

      await cacheEntryRemoved;
      if (data?.RequestId) {
        await dispatch(
          casinoJackpots.endpoints.unsubscribeFromJackpot.initiate(
            data?.RequestId
          )
        ).reset();
        casinoJackpotInstance.unsubscribe(data?.RequestId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse(
    response: TSubscribeToExternalJackpotResponse
  ): TSubscribeToExternalJackpot | {} {
    return !response ? {} : subscribeToExternalJackpotAdapter(response);
  }
});
export default subscribeToExternalJackpot;
