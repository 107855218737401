import React from 'react';
import { toast, ToastContentProps, ToastOptions } from 'react-toastify';

import { Component as ComponentType } from '@monorepo/type';

import ToastCloseButton from '../../common/ToastCloseButton';

type TOptions = Omit<ToastOptions, 'onClose' | 'onOpen'> & {
  onClose?: () => void;
  onOpen?: () => void;
};

const useToast = () => {
  const showToast = (
    Component: ComponentType<ToastContentProps<unknown>>,
    id: string,
    options?: TOptions
  ) => {
    const { onOpen, onClose, ...restOptions } = options || {};

    toast.onChange(({ status, id: toastId }) => {
      if (id === toastId) {
        if (status === 'added') {
          onOpen?.();
        } else if (status === 'removed') {
          onClose?.();
        }
      }
    });

    if (toast.isActive(id)) {
      toast.update(id, {
        ...restOptions,
        render: (props) => <Component {...props} />
      });
    } else {
      toast((props) => <Component {...props} />, {
        toastId: id,
        type: 'default',
        position: 'top-right',
        closeButton: ({ closeToast }) => (
          <ToastCloseButton onClick={closeToast} />
        ),
        ...restOptions
      });
    }
  };

  const hideToast = (id: string) => toast.dismiss(id);

  const hideAllToasts = () => toast.dismiss();

  return {
    showToast,
    hideToast,
    hideAllToasts
  };
};

export default useToast;
