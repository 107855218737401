import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TGameStatsRequest,
  TNormalizedGameStats,
  TSearchFavoriteTeamRequest
} from '../types';

import getGameStats from './api/getGameStats';
import searchFavoriteTeam from './api/searchFavoriteTeam';

const statsHost = process.env.REACT_APP_SPORTS_STATS_HOTS;

export const sportsStatsApi = createApi({
  reducerPath: 'sportsStatsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${statsHost}/api/` }),
  endpoints: (builder) => ({
    getGameStats: builder.query<TNormalizedGameStats, TGameStatsRequest>(
      getGameStats() as never
    ),
    searchFavoriteTeam: builder.query<string, TSearchFavoriteTeamRequest>(
      searchFavoriteTeam() as never
    )
  })
});

export const { useGetGameStatsQuery, useSearchFavoriteTeamQuery } =
  sportsStatsApi;
