import { camelizeKeys } from 'humps';
import get from 'lodash/get';
import pick from 'lodash/pick';
import values from 'lodash/values';

import { TPartnerConfig } from '../../../../types';

const partnerConfigAdapter = (initialData: {}): TPartnerConfig => {
  const [data] = values(get(initialData, 'data.partner'));
  // @ts-ignore
  return {
    ...camelizeKeys(
      pick(data, [
        'cashout_percetage',
        'currency',
        'id',
        'integration_type',
        'is_cashout_live',
        'is_cashout_prematch',
        'is_counter_offer_available',
        'is_superbet_on_live',
        'is_superbet_on_prematch',
        'is_trial',
        'is_two_factor_confirmation',
        'is_using_loyalty_program',
        'loyalty_bonus_max_exchange_points',
        'loyalty_bonus_min_exchange_points',
        'max_odd_for_multiple_bet',
        'max_selections_in_multiple_bet',
        'maximum_odd_for_cashout: ',
        'multiple_price_decimals',
        'partner_id',
        'prematch_multiple_disabled',
        'price_decimals',
        'price_round_method',
        'reg_curns',
        'single_login',
        'sms_restrictions',
        'sms_code_active_period',
        'sports_book_profile_ids:',
        'supported_currencies',
        'tax_integration_type',
        'two_factor_enabled',
        'two_factor_type',
        'tax_type',
        'tax_percent',
        'tax_amount_ranges'
      ])
    ),
    prematchMaxStake: data.prematch_max_stake,
    minBetStakes: data.min_bet_stakes,
    minCashoutAmount: data.min_cashout_amount,
    minSubBetStakes: data.min_sub_bet_stakes,
    liveMaxStake: data.live_max_stake,
    maxOddForMultipleBet: data.max_odd_for_multiple_bet,
    multWalletCurrencies: data.mult_wallet_currencies
  } as TPartnerConfig;
};

export default partnerConfigAdapter;
