import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';

const useNavigateUnauthenticated = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { generalRoutes } = useSiteSettings();
  const { login } = generalRoutes;
  const navigateToLogin = useCallback(
    (url?: string) =>
      navigate(
        `${login}?${SEARCH_STRING_PARAMS.redirectUrl}=${encodeURIComponent(
          url || pathname
        )}`
      ),
    [pathname]
  );

  return { navigateToLogin };
};

export default useNavigateUnauthenticated;
