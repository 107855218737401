import each from 'lodash/each';
import every from 'lodash/every';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { TNormalizedGames } from '../../../types';

const checkAndRemoveEmptyGames = (sports: TNormalizedGames['sport']) => {
  const filtered: TNormalizedGames['sport'] = { ...sports };

  each(sports, (sport) => {
    if (
      every(sport.region, (region) =>
        every(region.competition, (competition) => isEmpty(competition.game))
      )
    )
      delete filtered[sport.id];
    else
      each(sport.region, (region) => {
        each(
          filter(region.competition, (competition) =>
            isEmpty(competition.game)
          ),
          (competition) => {
            delete filtered[sport.id].region[region.id].competition[
              competition.id
            ];
          }
        );
      });
  });

  return filtered;
};

export const normalizeGamesResponse = (
  rawResponse: Record<string, any>
): TNormalizedGames => {
  const sport = get(rawResponse, 'data.sport', {});
  const subid = get(rawResponse, 'subid', '');

  return {
    sport,
    subid
  };
};

export const normalizeGamesResponseForSearch = (
  rawResponse: Record<string, any>
): TNormalizedGames => {
  const data = normalizeGamesResponse(rawResponse);

  return {
    ...data,
    sport: checkAndRemoveEmptyGames(data.sport)
  };
};
