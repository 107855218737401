import { TPartnerConfig } from '../../../types';
import partnerConfigAdapter from '../adapters/partnerConfigAdapter';

const getPartnerConfig = () => ({
  query: () => ({
    body: {
      command: 'get',
      params: {
        source: 'partner.config',
        what: { partner: [] }
      }
    }
  }),
  transformResponse(response): TPartnerConfig {
    return partnerConfigAdapter(response);
  }
});
export default getPartnerConfig;
