import React from 'react';
import { Link } from 'react-router-dom';

import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { FIELD_TONE, NotificationTitle, TEXT_SIZE } from '@monorepo/ui-kit';

import useChatUser from '../../../../hooks/useChatUser';

type Props = {
  userId: string;
  toneType?: FIELD_TONE;
  textSize?: TEXT_SIZE;
};

const ContactTitle: Component<Props> = ({
  userId,
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small
}) => {
  const { generalRoutes } = useSiteSettings();
  const { profile } = generalRoutes;
  const { displayName } = useChatUser(userId);

  return (
    <Link to={`${profile}/${userId}`}>
      <NotificationTitle textSize={textSize} toneType={toneType}>
        {displayName}
      </NotificationTitle>
    </Link>
  );
};

export default ContactTitle;
