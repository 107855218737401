import React from 'react';

import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import PromotionCard from '../PromotionCard';

import styles from './index.module.css';

type Props = {
  list: any;
  withLike?: boolean;
  withComment?: boolean;
  withFavorite?: boolean;
  withStream?: boolean;
  withShare?: boolean;
  isPromo?: boolean;
};

const PromotionsBlock: Component<Props> = ({
  list = [],
  withLike = false,
  withComment = false,
  withFavorite = false,
  withStream = false,
  withShare = false,
  isPromo
}) => (
  <Box className={styles.grid}>
    {(list || [])?.map(({ id, title, src, eventDate }) => (
      <PromotionCard
        key={`promotion-${id}-${title}`}
        id={id}
        title={title}
        src={src}
        eventDate={eventDate}
        withStream={withStream}
        withLike={withLike}
        withComment={withComment}
        withFavorite={withFavorite}
        withShare={withShare}
        className={styles.list}
        isPromo={isPromo}
      />
    ))}
  </Box>
);

export default PromotionsBlock;
