import React, { useEffect, useRef } from 'react';

import { useGetChildSession } from '@monorepo/auth';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ModalWrapper } from '@monorepo/notification';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_DIRECTION,
  FlexBox,
  TModalHandler
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const SwitchProposal = ({ currencyId = '', onClose = () => {} }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const ref = useRef<TModalHandler>(null);
  const { onGetChildSession } = useGetChildSession();

  const handleClose = () => {
    if (ref?.current) {
      ref.current.setIsShow(false);
    }
    onClose();
  };
  const handleAcceptTemp = async () => {
    await onGetChildSession(currencyId);
    handleClose();
  };

  useEffect(() => {
    ref?.current?.setIsShow(true);
  }, []);

  return (
    <ModalWrapper
      ref={ref}
      handleClose={handleClose}
      iconClass={styles.iconPopup}
      header={t('wallet.switch.info')}
      alwaysCenter
    >
      <Box>
        <div className={styles.text}>
          {t('wallet.new.wallet', { wallet: currencyId })}
        </div>
        <FlexBox direction={FLEX_DIRECTION.column} className={styles.control}>
          <Button
            size={BUTTON_SIZE.h30}
            className={styles.button}
            onClick={() => handleAcceptTemp()}
          >
            {t('wallet.confirm.button')}
          </Button>
          <Button
            size={BUTTON_SIZE.h30}
            className={styles.button}
            intent={BUTTON_INTENT.secondary}
            onClick={handleClose}
          >
            {t('wallet.switch.cancel')}
          </Button>
        </FlexBox>
      </Box>
    </ModalWrapper>
  );
};

export default SwitchProposal;
