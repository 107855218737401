const COMPONENT_TYPES = {
  BANNER_JACKPOTS: 'banner-jackpots',
  CASINO_CATEGORIES_MENU: 'casino_categories_menu',
  CASINO_GAMES_LOBBY: 'casino_games_lobby',
  CASINO_GAMES_BY_CATEGORY: 'casino_games_by_category',
  CASINO_GAMES_RECOMMENDED: 'casino_games_recommended',
  CASINO_GAMES_LAST_PLAYED: 'casino_last_played_games',
  CASINO_LAUNCHER: 'casino-launcher',
  CASINO_GAMES_SELECTED: 'casino-games-selected',
  CASINO_GAMES_SUGGESTED: 'casino-games-suggested',
  CASINO_TOURNAMENTS: 'casino-tournaments',
  CASINO_LAST_PLAYED_GAMES: 'last-played-casino-games',
  CASINO_JACKPOTS: 'casino-jackpots',
  CASINO_TOURNAMENT: 'casino-tournament',
  CASINO_TOURNAMENTS_FILTERS: 'casino-tournaments-filters',
  FAVORITE_GAMES: 'casino-favorite-games',
  CASINO_SELECTED_FILTERS: 'casino-selected-filters',
  SEARCH_CASINO_GAMES: 'search-casino',
  BANNERS_SLIDER: 'banners-slider',
  CASINO_GOODIES: 'casino-goodies',
  CASINO_SECTION: 'casino-section'
};

export default COMPONENT_TYPES;
