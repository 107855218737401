import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';

import useNotifications from '../../hooks/useNotifications';
import useNotificationState from '../../hooks/useNotificationState';
import { TNotificationProps } from '../../types';

import styles from './index.module.css';

const DefaultNotification: CustomFC<TNotificationProps> = ({
  handleMouseEnter,
  handleMouseLeave
}) => {
  const { notificationType, message } = useNotificationState();
  const { t } = useI18n(NAMESPACES.COMMON);
  const { closeNotification } = useNotifications();

  return (
    <div
      className={styles.wrap}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Boolean(notificationType) && (
        <div
          className={`${styles.tag} ${
            notificationType && styles[notificationType.toLowerCase()]
          }`}
        >
          {t(`notifications.type.${notificationType?.toLowerCase()}`)}
        </div>
      )}
      <div className={styles.message}>{message}</div>
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={closeNotification}
        className={styles.button}
      >
        <Icon name="close_0" title="" className={styles.icon} />
      </Button>
    </div>
  );
};

export default DefaultNotification;
