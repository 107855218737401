import React, { useEffect, useState } from 'react';

import { CustomFC } from '@monorepo/type';

import LineParametersContext from '../../store/LineParametersContext';
import { TLineParameters } from '../../types';

type Props = {
  params?: Partial<TLineParameters>;
};

const BaseParametersProvider: CustomFC<Props> = ({ children, params = {} }) => {
  const [state, setState] = useState<TLineParameters>(params);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...params }));
  }, [params]);

  return (
    <LineParametersContext.Provider value={[state, setState]}>
      {children}
    </LineParametersContext.Provider>
  );
};

export default BaseParametersProvider;
