import { xml } from '@xmpp/client';

import { X_DATA_TYPE } from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';

export const xDataCreator = (type?: X_DATA_TYPE) => {
  const x = xml('x', { xmlns: XML_NAMESPACES.X_DATA });

  if (type) {
    x.attrs.type = type;
  }
  return x;
};

const createValue = (value: string) => xml('value', {}, value);

export const fieldCreator = (
  variable: string,
  value: string | string[],
  type?: string
) => {
  const field = xml('field', { var: variable });
  field.append(
    ...(Array.isArray(value) ? value.map(createValue) : [createValue(value)])
  );
  if (type) {
    field.attrs.type = type;
  }

  return field;
};
