import React, { forwardRef, memo } from 'react';

import { DivProps } from '@monorepo/ui-kit';

import styles from './index.module.css';

// @ts-ignore
const GameListItem = memo(
  forwardRef<HTMLDivElement, DivProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ className = '', children, context, ...rest }, ref) => (
      <div ref={ref} className={`${className} ${styles.singleGame}`} {...rest}>
        {children}
      </div>
    )
  )
);

export default GameListItem;
