import {
  TAvailableTournamentsIds,
  TAvailableTournamentsResponse
} from '../../../../types';

const availableTournamentsAdapter = ({
  tournaments
}: TAvailableTournamentsResponse): TAvailableTournamentsIds => tournaments;

export default availableTournamentsAdapter;
