const unsubscribeFromJackpot = () => ({
  query: (jackPotId: string | number) => ({
    body: {
      Command: 'unsubscribe',
      Data: { rid: jackPotId },
      RequestId: jackPotId
    }
  })
});
export default unsubscribeFromJackpot;
