import parseISO from 'date-fns/parseISO';
import { camelizeKeys } from 'humps';
import get from 'lodash/get';
import pick from 'lodash/pick';
import values from 'lodash/values';

import { formatDate, formatUnixDate } from '@monorepo/helpers';

import { TProfileData, TUserProfile } from '../../../../types/user';

const formatPhone = (rawPhone = '') => {
  if (!rawPhone) {
    return { phoneCode: '', phone: '' };
  }
  const phone = rawPhone.startsWith('+') ? rawPhone : `+${rawPhone}`;

  return {
    phone: phone.slice(4),
    phoneCode: phone.slice(0, 4),
    rawPhone
  };
};

export const profileFormat = (profile: any): TProfileData => {
  const profileData = camelizeKeys(
    pick(profile, [
      'id',
      'nick_name',
      'first_name',
      'last_name',
      'middle_name',
      'language',
      'currency_name',
      'currency_id',
      'currencyid',
      'balance',
      'name',
      'additional_address',
      'address',
      'birth_date',
      'bonus_balance',
      'bonus_money',
      'bonus_win_balance',
      'casino_balance',
      'casino_bonus',
      'casino_bonus_win',
      'casino_unplayed_balance',
      'deposit_count',
      'email',
      'gender',
      'is_bonus_allowed',
      'is_cash_out_available',
      'is_gdpr_passed',
      'is_super_bet_available',
      'is_tax_applicable',
      'is_verified',
      'last_login_date',
      'session_duration',
      'phone',
      'phone_code',
      'reg_date',
      'username',
      'zip_code',
      'unread_count',
      'country_code',
      'doc_number',
      'mobile_phone',
      'birth_region',
      'birth_city',
      'city',
      'frozen_balance',
      'logout',
      'exclude_type',
      'active_step_state',
      'iban',
      'sport_bonus',
      'iban',
      'personal_id',
      'sport_bonus',
      'has_free_bets',
      'owner_id',
      'parent_currency',
      'is_two_factor_authentication_enabled',
      'authentication_status'
    ])
  ) as TUserProfile;

  const [
    mailingAddress = '',
    mailingCity = '',
    mailingCountryCode = '',
    mailingZipCode = ''
  ] = profileData.additionalAddress?.split('#') || [];

  return {
    ...profileData,
    ...formatPhone(profileData.phone),
    countryCode: profileData.countryCode?.toLowerCase() || '',
    birthRegion: profileData.birthRegion?.toLowerCase() || '',
    currencyName: profileData.currencyId || profileData.currencyid,
    lastLoginDate: profileData.lastLoginDate
      ? formatUnixDate(profileData.lastLoginDate)
      : formatDate(new Date(), 'dd.MM.yyyy'),
    birthDate: profileData.birthDate
      ? formatDate(parseISO(profileData.birthDate), 'dd-MM-yyyy')
      : '',
    mailingAddress,
    mailingCity,
    mailingCountryCode,
    mailingZipCode
  };
};

const userProfileAdapter = (initialData: {}): TUserProfile => {
  const subId = get(initialData, 'subid', '');
  const [profileData] = values(get(initialData, 'data.profile', {}));
  const profile = profileFormat(profileData);

  return {
    profile,
    subId
  };
};

export default userProfileAdapter;
