import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  CarouselNavigation,
  NavigationItem,
  TMenuItem
} from '@monorepo/ui-kit';

import useScrollToTop from '../../hooks/useScrollToTop';
import { TStickHeader } from '../../types';

import styles from './index.module.css';

const TabMenuCarousel: CustomFC<TStickHeader> = ({ config }) => {
  const { replace, items = [], theme = '', className = '' } = config || {};
  const { t } = useI18n(NAMESPACES.DYNAMIC);

  useScrollToTop();

  const translatedItems = useMemo<TMenuItem[]>(
    () =>
      items.map((item) => ({
        id: uniqueId(),
        ...item,
        title: t(item.title)
      })),
    [t, items]
  );
  const { pathname } = useLocation();
  const activeIndex = translatedItems.findIndex(
    (item) => item.link === pathname
  );

  const slides = translatedItems.map((item) => ({
    id: item.id,
    component: () => (
      <NavigationItem
        item={item}
        linkClassName={styles.link}
        itemClassName={styles.item}
        activeClassName={styles.active}
        isExact={false}
        replace={replace}
        key={item.id}
      />
    )
  }));

  return (
    <Box className={`${styles.wrapper} ${styles[theme]} ${className}`}>
      {translatedItems.length > 0 && (
        <CarouselNavigation
          slides={slides}
          slideClassName={styles.slide}
          isWithButtons={false}
          activeIndex={activeIndex}
          params={{
            slidesPerView: 'auto',
            spaceBetween: 0,
            centerInsufficientSlides: true,
            navigation: false,
            autoplay: false,
            speed: 1000,
            pagination: false,
            freeMode: {
              enabled: false,
              momentumBounce: false,
              momentumRatio: 0.2,
              minimumVelocity: 0.01,
              momentum: true
            },
            lazy: true
          }}
        />
      )}
    </Box>
  );
};

export default TabMenuCarousel;
