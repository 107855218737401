import { useTypedSelector } from '@monorepo/redux';

import { userSignUp } from '../../../store/userSignUp';
import { TSignUpData } from '../../../types/signUpData';

const getSignUpData = (state: any) => state[userSignUp.name].signUpData;

const useSignUpData = (): TSignUpData => useTypedSelector(getSignUpData);

export default useSignUpData;
