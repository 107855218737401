import React from 'react';

import { CustomFC } from '@monorepo/type';

import styles from './index.module.css';

const HighlightedSearchText: CustomFC<{
  searchText?: string;
  replacingText: string;
  className?: string;
}> = ({ searchText, replacingText, className }) => (
  <span
    className={`${className} ${styles.highlightedText}`}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: !searchText
        ? replacingText
        : replacingText?.replace(
            new RegExp(
              searchText.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'),
              'gi'
            ),
            (match: string) => `<span>${match}</span>`
          )
    }}
  />
);

export default HighlightedSearchText;
