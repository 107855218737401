import { MUC_PRESENCE_STATUS } from '@monorepo/xmpp';

export const FRIENDS_MODAL_STORAGE_KEY = 'friends_requests_last';

export const FRIENDS_MODAL_TIMEOUT = 20_000;

export enum SOCIAL_NOTIFICATION_TYPE {
  FRIEND_REQUEST = 'FRIEND_REQUEST',
  UNREAD_MESSAGE = 'UNREAD_MESSAGE',
  COMMUNITY_INVITE = 'COMMUNITY_INVITE',
  COMMUNITY_DECLINE_INVITE = 'COMMUNITY_DECLINE_INVITE',
  COMMUNITY_UNREAD_MESSAGE = 'COMMUNITY_UNREAD_MESSAGE'
}

export enum MAM_QUERY_STATUS {
  UNINITIALISED,
  LOADING,
  LOADED,
  COMPLETE
}

export enum SOCIALS_QUERY_TAG {
  MUC_ROOM_DISCO_INFO = 'MUC_ROOM_DISCO_INFO',
  MUC_ROOM_AFFILIATIONS = 'MUC_ROOM_AFFILIATIONS'
}

export const MUC_ROOM_AFFILIATIONS_LIST = 'LIST';

export const MUC_KICK_STATUS = [
  MUC_PRESENCE_STATUS.EXIT,
  MUC_PRESENCE_STATUS.KICKED,
  MUC_PRESENCE_STATUS.BANNED,
  MUC_PRESENCE_STATUS.REMOVED_AFFILIATION_CHANGED,
  MUC_PRESENCE_STATUS.REMOVED_CONFIGURATION_CHANGED,
  MUC_PRESENCE_STATUS.REMOVED_SERVICE_SHUT_DOWN,
  MUC_PRESENCE_STATUS.REMOVED_TECHNICAL_ISSUE
];
