import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetGameRequest } from '../../../types';
import { normalizeGameResponse } from '../adapters/gameAdapter';
import gameUpdater from '../updaters/gameUdapter';

const getGame = (api: any) => ({
  query: ({ id, competitionId }: TGetGameRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          game: [
            'id',
            'is_live',
            'is_blocked',
            'is_stat_available',
            'info',
            'team1_name',
            'team2_name',
            'start_ts',
            'text_info',
            'team1_id',
            'team2_id',
            'last_event',
            'sport_alias',
            'region_alias',
            'video_id'
          ],
          competition: ['id', 'name', 'favorite'],
          market: [
            'id',
            'group_id',
            'name',
            'group_name',
            'cashout',
            'col_count',
            'type',
            'order',
            'group_order',
            'name_template',
            'sequence',
            'point_sequence'
          ]
        },
        where: {
          game: {
            id
          },
          ...(competitionId && {
            competition: {
              id: competitionId
            }
          })
        },
        subscribe: true
      }
    }
  }),
  providesTags: [SPORTS_QUERY_KEYS.GAME],
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => gameUpdater(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.GAME]))
      );
      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse: normalizeGameResponse
});
export default getGame;
