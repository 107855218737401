import regExpValidator from './regExpValidator';

const numbersAndLettersRegExpValidator = (
  message: string = 'validators.numbersandletters.format'
) => {
  const regexp = new RegExp('^.*[a-zA-ZáčďéěňóřšťúůýžÁČĎĚÉÍŇÓŘŠŤÚŮÝŽ]+.*$');

  return regExpValidator(regexp, message);
};

export default numbersAndLettersRegExpValidator;
