import { ReactNode } from 'react';

const getCountry = (
  countryCode: string,
  countries: { value: string; label?: string | ReactNode }[]
) => {
  if (!countryCode || !countries.length) {
    return;
  }
  return countries.find((c) => c.value === countryCode.toLowerCase())?.label;
};

export default getCountry;
