import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

import {
  TNotification,
  TNotificationsLocalState
} from '../types/notificationsLocal';

const initialState = {};

export const notificationsLocalSlice = createSlice<
  TNotificationsLocalState,
  SliceCaseReducers<TNotificationsLocalState>
>({
  name: 'notificationsLocal',
  initialState,
  reducers: {
    setNotification: (state, { payload }: TPayload<TNotification>) => {
      const { categoryId, notificationId } = payload;
      if (!state[categoryId]) {
        state[categoryId] = [];
      }
      if (state[categoryId].indexOf(notificationId) === -1) {
        state[categoryId] = [...state[categoryId], notificationId];
      }
      return state;
    }
  }
});

export const { actions } = notificationsLocalSlice;
