import { TChangePasswordRequest } from '../../../types';

const updatePassword = () => ({
  query: ({
    password,
    newPassword,
    confirmationCode,
    encryptedToken
  }: TChangePasswordRequest) => ({
    body: {
      command: 'update_user_password',
      params: {
        password,
        new_password: newPassword,
        confirmation_code: confirmationCode,
        encrypted_token: encryptedToken
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result, result_text: msg } = data;
      return !(code === 0 && result === 0)
        ? { code: result, message: msg }
        : false;
    }
  })
});

export default updatePassword;
