import { useIsAuthenticated } from '@monorepo/auth';

import { useStreamingQuery } from '../../store/socialsApi';

const useSocialStreaming = ({ skip = false, ...options } = {}) => {
  const { isAuthenticated } = useIsAuthenticated();

  return useStreamingQuery(undefined, {
    skip: !isAuthenticated || skip,
    ...options
  });
};

export default useSocialStreaming;
