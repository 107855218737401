import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { transformCmsIcons } from '@monorepo/cms';
import { useSearchParamsWithParsing } from '@monorepo/helpers/src';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FLEX_WRAP,
  FlexBox,
  getTooltipDataAttributes
} from '@monorepo/ui-kit';

import { PAYMENT_MAP, SERVICE_TYPES } from '../../constants';
import usePaymentServiceData from '../../hooks/usePaymentServiceData';

import styles from './index.module.css';

type Props = {
  serviceType: SERVICE_TYPES;
  withdrawInfoPage: string;
};

const PaymentMenu: CustomFC<Props> = ({ serviceType, withdrawInfoPage }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { setSearchParam } = useSearchParamsWithParsing();
  const { isFetching, groupKeys, activeGroup } =
    usePaymentServiceData(serviceType);
  const handleClick = (value: any) => () => {
    setSearchParam('group', value);
  };

  const tooltipData = useMemo(
    () =>
      getTooltipDataAttributes({
        content: t('wallet.rules.help'),
        delayShow: 10
      }),
    []
  );

  if (isFetching) {
    return null;
  }

  return (
    <FlexBox className={styles.pillsList}>
      <FlexBox
        className={styles.pillsListWrapper}
        wrap={FLEX_WRAP.wrap}
        justify={FLEX_JUSTIFY.start}
        align={FLEX_ALIGN.start}
      >
        {groupKeys.map((id: any) => {
          const title = PAYMENT_MAP[id] || `${id}`;
          const activeClass = activeGroup === id ? styles.active : '';
          return (
            <Button
              intent={BUTTON_INTENT.transparent}
              key={id}
              className={`${styles.item} ${activeClass}`}
              value={id}
              onClick={handleClick(id)}
            >
              <Icon
                name={transformCmsIcons(`payment${id}`)}
                className={styles.icon}
              />
              {t(`wallet.payment.${title.toLowerCase()}`)}
            </Button>
          );
        })}
      </FlexBox>
      {withdrawInfoPage && (
        <Link to={withdrawInfoPage} className={styles.link}>
          <FlexBox {...tooltipData}>
            <Icon name="question-3" />
          </FlexBox>
        </Link>
      )}
    </FlexBox>
  );
};

export default PaymentMenu;
