import { destroyRoom, TDestroyRoom } from '@monorepo/xmpp';

import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const destroy = () => ({
  query: (args: TDestroyRoom) => destroyRoom(args),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError
});

export default destroy;
