import { AUTH_QUERY_KEYS } from '../../../constants';
import { TBonusesData } from '../../../types';
import freeSpinsAdapter from '../adapters/freeSpins';

const getFreeSpins = () => ({
  query: ({ acceptanceType }: { acceptanceType: number }) => ({
    body: {
      command: 'get_free_spin_bonuses',
      params: { acceptance_type: acceptanceType }
    }
  }),
  providesTags: [AUTH_QUERY_KEYS.FREESPINS],
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },

  transformResponse: (
    response: TBonusesData
  ): Promise<TBonusesData> | TBonusesData => freeSpinsAdapter(response)
});
export default getFreeSpins;
