import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';

import { formatDate, parseDateFromFormat } from '@monorepo/helpers';

const useGetTimelineRangeList = (fromDate: string, toDate: string) => {
  const monthDifference = differenceInCalendarMonths(
    parseDateFromFormat(toDate as string),
    parseDateFromFormat(fromDate as string)
  );
  const arrLength = monthDifference + 1;
  const dataArray = new Array(arrLength);
  const startDayOfEndPeriod = formatDate(
    startOfMonth(parseDateFromFormat(toDate as string))
  );
  const firstTimePeriod = {
    currentFromDate: startDayOfEndPeriod,
    currentToDate: toDate,
    page: 0
  };

  const lastDayOfFirstDateRange = formatDate(
    lastDayOfMonth(parseDateFromFormat(fromDate as string))
  );
  const lastTimePeriod = {
    currentFromDate: fromDate,
    currentToDate: lastDayOfFirstDateRange,
    page: monthDifference
  };
  dataArray[0] = firstTimePeriod;
  dataArray[monthDifference] = lastTimePeriod;

  for (let i = 0; i < arrLength; i++) {
    if (i === 0 || i === monthDifference) continue;
    const startDayInMonth = subMonths(
      parseDateFromFormat(startDayOfEndPeriod),
      i
    );
    const lastDayInMonth = lastDayOfMonth(startDayInMonth);
    dataArray[i] = {
      currentFromDate: formatDate(startDayInMonth),
      currentToDate: formatDate(lastDayInMonth),
      page: i
    };
  }

  return dataArray;
};

export default useGetTimelineRangeList;
