import { useMemo } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { UseCountry } from '@monorepo/type';

import { useGetCountriesQuery } from '../../store/api/getCountries';

const useCountries = (): UseCountry[] => {
  const { t } = useI18n(NAMESPACES.COUNTRIES);
  const { data: countries = [], error, isLoading } = useGetCountriesQuery();

  const countriesTranslate = useMemo(
    () =>
      !isLoading
        ? countries.map((country) => ({
            ...country,
            label: t(`country.${country.value}`)
          }))
        : [],
    [countries, t]
  );

  if (error) {
    console.error(error);
  }

  return countriesTranslate;
};

export default useCountries;
