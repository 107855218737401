import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { classNameProp, CustomFC, TId, TOptionItem } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  FLEX_DIRECTION,
  FlexBox
} from '@monorepo/ui-kit';

import toggleCheck from './utils/toggleCheck';

import styles from './index.module.css';

type Props = {
  id: string;
  items: TOptionItem[];
  defaultChecked?: TId | Array<TId>;
  onChange: (value: any) => void;
  isWithSearch?: boolean;
  listClassName?: string;
  isMultiselect?: boolean;
  itemClass?: string;
} & classNameProp;

const FilterList: CustomFC<Props> = ({
  id,
  items,
  defaultChecked = [],
  isMultiselect = true,
  isWithSearch = true, // fixme: is used somewhere?
  onChange,
  className = '',
  listClassName = '',
  itemClass = ''
}) => {
  const [list] = useState(items || []);
  const [checked, setChecked] = useState(
    Array.isArray(defaultChecked) ? defaultChecked : [defaultChecked]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newChecked = isMultiselect
        ? toggleCheck(checked.filter(Boolean), e.target.value)
        : [e.target.value];
      onChange(isEmpty(newChecked) ? null : newChecked);
    },
    [isMultiselect, onChange, checked]
  );

  useEffect(() => {
    setChecked(
      Array.isArray(defaultChecked) ? defaultChecked : [defaultChecked]
    );
  }, [defaultChecked]);

  return (
    <FlexBox
      direction={FLEX_DIRECTION.row}
      className={`${styles.wrap} ${className}`}
    >
      <div className={`${styles.list} ${listClassName}`}>
        {list.map(({ value, key, label, ...props }) => (
          <Button
            intent={BUTTON_INTENT.transparent}
            key={key || `${id}-${value}`}
            id={key || `${id}-${value}`}
            className={`${styles.item} ${itemClass} ${
              checked.includes(value) && styles.active
            }`}
            value={value}
            onClick={(e) => handleChange(e)}
            {...props}
          >
            {label}
          </Button>
        ))}
      </div>
    </FlexBox>
  );
};

export default FilterList;
