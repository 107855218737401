import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import { FLEX_DIRECTION, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { TBets } from '../../types';

import CashOutForm from './components/CashOutForm';

import styles from './index.module.css';

type TCashOut = {
  bet: TBets;
  isPartialCashOut?: boolean;
  closeModal?: () => void;
  showCashOutChange: boolean;
};

const CashOut: CustomFC<TCashOut> = ({
  bet,
  closeModal,
  showCashOutChange,
  isPartialCashOut
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  return (
    <FlexBox className={styles.wrapper} direction={FLEX_DIRECTION.column}>
      <FlexBox justify={FLEX_JUSTIFY.between} className={styles.heaaderWrapper}>
        <span className={styles.header}>{t('sportsbook.modal.cash_out')}</span>
        <Icon
          name="close_0"
          className={styles.iconClose}
          onClick={closeModal}
        />
      </FlexBox>
      <CashOutForm
        betItem={bet}
        isPartialCashOut={isPartialCashOut}
        showCashOutChange={showCashOutChange}
        closeModal={closeModal}
      />
    </FlexBox>
  );
};

export default CashOut;
