import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

import { LOGOUT_FREEZE_SOURCE } from '../../constants';
import useLogout from '../useLogout';

const useLogoutFreeze = () => {
  const navigate = useNavigate();
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { infoNotification } = useNotifications();
  const { generalRoutes } = useSiteSettings();
  const { home } = generalRoutes;

  const onSuccess = useCallback(() => {
    infoNotification(t('logout.freeze.notification'));
    navigate(home);
  }, [infoNotification, t, navigate, home]);

  const { onLogout, isLoading } = useLogout({ onSuccess });

  const onLogoutFreeze = useCallback(
    () => onLogout({ source: LOGOUT_FREEZE_SOURCE }),
    [onLogout]
  );

  return { onLogoutFreeze, isLoading };
};

export default useLogoutFreeze;
