import { TVerifyEmail } from '../../../types';

const verifyEmail = () => ({
  query: ({ code }: TVerifyEmail) => ({
    body: {
      command: 'verify_user',
      params: {
        verification_code: code
      }
    }
  })
});

export default verifyEmail;
