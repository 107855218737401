import React from 'react';
import { useFormState } from 'react-hook-form';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { CustomFC } from '@monorepo/type';
import { Button, BUTTON_SIZE, ButtonProps } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  isMain?: boolean;
} & Omit<ButtonProps, 'type'>;

const SubmitButton: CustomFC<Props> = ({
  children,
  processing,
  isMain = true,
  className = '',
  ...props
}) => {
  const { isSubmitting, isValid, isValidating } = useFormState();
  const { isDesktop } = useDeviceDetector();
  const buttonStyles = isDesktop ? styles.button : styles.buttonMobile;

  return (
    <Button
      type="submit"
      size={BUTTON_SIZE.h40}
      className={`${buttonStyles} ${className}`}
      disabled={!isMain && !isValid}
      processing={isSubmitting || isValidating || processing}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
