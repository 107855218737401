import React from 'react';

import { Component } from '@monorepo/type';

import Box from '../../Box';

import styles from './index.module.css';

const NotificationDate: Component = ({ className = '', children }) => (
  <Box className={`${styles.date} ${className}`}>{children}</Box>
);

export default NotificationDate;
