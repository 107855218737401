import React from 'react';

import { SvgIcon } from '@monorepo/icons';
import { Component } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../../constants';
import FlexBox from '../../FlexBox';
import PreloadImage from '../../PreloadImage';

import styles from './index.module.css';

type Props = {
  src: string;
  alt: string;
};

const NotificationImage: Component<Props> = ({ src, alt, className = '' }) => (
  <PreloadImage
    className={`${styles.wrap} ${className}`}
    imgClassName={styles.img}
    src={src}
    alt={alt}
  >
    <FlexBox
      className={styles.placeholder}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
    >
      <SvgIcon name="logo" className={styles.logo} />
    </FlexBox>
  </PreloadImage>
);

export default NotificationImage;
