import React from 'react';

import { FIELD_TONE, FLEX_ALIGN, THEME_TONE } from '../../../constants';
import { CheckboxProps } from '../../../types';
import Box from '../../Box';
import FlexBox from '../../FlexBox';

import styles from './index.module.css';

const Toggle = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      className = '',
      toneType = FIELD_TONE.dark,
      theme = THEME_TONE.default,
      disabled,
      titleText = '',
      dataCustom = '',
      controlText = '',
      ...props
    },
    ref
  ) => {
    const classes = `${styles.wrap}
      ${styles[toneType]}
      ${styles[theme]}
      ${disabled ? styles.disabled : ''}
      ${className}`;

    return (
      <FlexBox title={titleText} className={classes} align={FLEX_ALIGN.center}>
        <input
          data-custom={dataCustom}
          data-status={props.checked}
          id={id}
          type="checkbox"
          className={styles.input}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        <Box className={styles.box} />
        <Box className={styles.control}>{controlText}</Box>
      </FlexBox>
    );
  }
);

export default Toggle;
