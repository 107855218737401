import { useContext } from 'react';

import NotificationContext from '../../store/context';
import { TNotificationState } from '../../types';

const useNotificationState = <TMessage>(): TNotificationState<TMessage> => {
  const [state] = useContext(NotificationContext);
  return state;
};

export default useNotificationState;
