import { camelizeKeys } from 'humps';

import { TCasinoTournament, TTournamentResponse } from '../../../../types';

const getTournamentAdapter = (
  response: TTournamentResponse
): TCasinoTournament => {
  const { result } = response;
  return camelizeKeys(result) as TCasinoTournament;
};

export default getTournamentAdapter;
