import {
  TUpdatePlayerInformation,
  useUpdatePlayerInfoMutation
} from '@monorepo/cms';
import { useNotifications } from '@monorepo/notification';

const useUpdateStatus = () => {
  const [onSave, { isLoading, isSuccess }] = useUpdatePlayerInfoMutation();
  const { errorNotification } = useNotifications();

  const onUpdateStatus = async (
    args: Pick<TUpdatePlayerInformation, 'status' | 'visibility_status'>
  ) => {
    try {
      await onSave(args).unwrap();
    } catch (error) {
      const { data } = error;
      const { message } = data || {};
      const { errors } = JSON.parse(message);
      const { status: errorStatus = '' } = errors || {};
      errorNotification(errorStatus);
    }
  };
  return { onUpdateStatus, isLoading, isSuccess };
};
export default useUpdateStatus;
