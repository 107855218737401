import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

import { useIsAuthenticated } from '@monorepo/auth';
import { ComponentWithConfig } from '@monorepo/type';
import { Box, FlexBox, Sticky } from '@monorepo/ui-kit';
import { MESSAGES_TYPES } from '@monorepo/xmpp';

import FriendsList from './components/FriendsList';
import SocialButton from './components/SocialButton';

import styles from './index.module.css';

type TConfig = {
  isHorizontal: boolean;
  isInFrame?: boolean;
};

const FriendsMenu: ComponentWithConfig<TConfig> = ({ config }) => {
  const { isHorizontal, isInFrame = false } = config;
  const classes = `${isHorizontal ? styles.horizontal : styles.vertical}`;
  const { isAuthenticated } = useIsAuthenticated();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Sticky className={`${classes} ${styles.wrap}`}>
      <Box>
        <SocialButton
          type={MESSAGES_TYPES.GROUP}
          className={styles.community}
          isHorizontal={isHorizontal}
          isInFrame={isInFrame}
        />
        <SocialButton
          type={MESSAGES_TYPES.SINGLE}
          className={styles.friends}
          isHorizontal={isHorizontal}
          isInFrame={isInFrame}
        />
        {isHorizontal ? (
          <Box className={styles.friendsListHorizontal}>
            <FlexBox className={styles.friendsList}>
              <FriendsList isHorizontal={isHorizontal} isInFrame={isInFrame} />
            </FlexBox>
          </Box>
        ) : (
          <Box className={styles.friendsListVertical}>
            <Scrollbar className={styles.friendsList}>
              <FriendsList isHorizontal={isHorizontal} isInFrame={isInFrame} />
            </Scrollbar>
          </Box>
        )}
      </Box>
    </Sticky>
  );
};

export default FriendsMenu;
