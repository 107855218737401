import { googleAnalytics } from '@monorepo/helpers';

import { TAuthLoginRequest } from '../../../types';
import { actions } from '../../index';

const requestAuthLogin = () => ({
  query: ({ userId, authToken, partnerId }: TAuthLoginRequest) => ({
    method: 'POST',
    url: '/player',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      user_id: userId,
      auth_token: authToken,
      partner_id: partnerId
    }
  }),
  extraOptions: { maxRetries: 3 },
  async onQueryStarted(args: TAuthLoginRequest, { dispatch, queryFulfilled }) {
    const {
      setIsForceReFetchAuthCookie,
      setJwtAuthToken,
      setMessengerAuthToken
    } = actions;
    try {
      const { data } = await queryFulfilled;
      dispatch(setJwtAuthToken(data.jwt_access));
      if (data.messenger_jwt) {
        dispatch(setMessengerAuthToken(data.messenger_jwt));
      }
      dispatch(setIsForceReFetchAuthCookie(false));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // ga  js-error
      const ga = googleAnalytics();
      ga.dispatch({
        event: ga.event.jsError,
        eventParam: {
          event_category: 'js'
        },
        event_options: {
          message: (e as any)?.message,
          data: e
        }
      });
    }
  }
});

export default requestAuthLogin;
