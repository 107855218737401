import { actions } from '@monorepo/auth';

import {
  TPlayerInformation,
  TPlayerInformationGetArgs
} from '../../../../types';
import { CUSTOM_API_QUERY_KEYS } from '../../constants';

const getPlayerInformation = () => ({
  query: () => ({
    url: '/player',
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  providesTags: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION],
  async onQueryStarted(
    args: TPlayerInformationGetArgs,
    { queryFulfilled, dispatch }
  ) {
    const { setIsForceReFetchAuthCookie } = actions;
    try {
      await queryFulfilled;
    } catch (e) {
      if (e?.error?.status === 401) {
        dispatch(setIsForceReFetchAuthCookie(true));
      }
    }
  }
});

export default getPlayerInformation;
