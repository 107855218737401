import get from 'lodash/get';
import merge from 'lodash/merge';
import values from 'lodash/values';

import { profileFormat } from '../adapters/userProfile';

const updateCallback = (draft, updateData) => {
  const [profile] = values(get(updateData, 'profile', {}));

  return merge(draft, { profile: profileFormat(profile) });
};

export default updateCallback;
