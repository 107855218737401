import React from 'react';

import { Component } from '@monorepo/type';
import { FIELD_TONE, Portal, Shutter, useShutterRef } from '@monorepo/ui-kit';

import AuthLink from '../AuthLink';
import SnippetComponent from '../SnippetComponent';

type Props = {
  snippetId: string | number;
};

const LinkShutter: Component<Props> = ({
  children,
  className = '',
  snippetId
}) => {
  const { shutterRef, setShow } = useShutterRef();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShow();
  };

  return (
    <>
      <AuthLink to="#" className={className} onClick={handleClick}>
        {children}
      </AuthLink>
      <Portal>
        {/* @ts-ignore */}
        <Shutter
          side="wide"
          // isWithHeader={isDesktop}
          toneType={FIELD_TONE.dark}
          ref={shutterRef}
        >
          <SnippetComponent snippetId={snippetId} />
        </Shutter>
      </Portal>
    </>
  );
};

export default LinkShutter;
