import notificationsAdapter from '../adapters/notificationsAdapter';

const getBanners = () => ({
  query: ({ lang, partner, slug }: any) =>
    `${lang}/partners/${partner}/components/${slug}/contents`,
  transformResponse(response): any[] {
    return notificationsAdapter(response);
  }
});
export default getBanners;
