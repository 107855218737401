import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { FIELD_TO_ERROR_MAP } from '../../constants';
import { useEditProfileMutation } from '../../store/user';
import { TProfileRequest } from '../../types';

const useEditProfile = (onError, fieldsToErrorMap = FIELD_TO_ERROR_MAP) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { successNotification, errorNotification } = useNotifications();
  const [onEdit, { isLoading }] = useEditProfileMutation();

  const formatError = (code: string) => {
    const field = fieldsToErrorMap[code];
    return field
      ? { field, message: `updateprofile.field.validate.${code}` }
      : { message: `updateprofile.field.validate.${code}` };
  };

  const onErrorDefault = ({ code }) => {
    const formattedError = formatError(code);
    errorNotification(t(formattedError?.message || ''));
    onError?.(formattedError);
  };

  const onSuccess = (ref) => {
    successNotification(t('updateprofile.success'));
    if (ref?.current) {
      ref.current.resetField('password');
      ref.current.reset({}, { keepValues: true });
    }
  };

  const onEditProfile = async (profile: TProfileRequest, ref) => {
    const { error } = await onEdit(profile);
    if (error) {
      onErrorDefault(error);
    } else {
      onSuccess(ref);
    }
  };

  return { onEditProfile, isLoading, formatError };
};

export default useEditProfile;
