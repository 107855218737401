import { xml } from '@xmpp/client';

import { INBOX_ORDER, IQ_TYPE, X_DATA_TYPE } from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';
import { IConversationInbox, TInboxFormFields, TInboxQuery } from '../../types';
import createStanzaId from '../createStanzaId';

import { getIqQuery } from './getIqQuery';
import { rsmCreator } from './rsm';
import { fieldCreator, xDataCreator } from './xData';

export const getInboxQuery = () => getIqQuery(XML_NAMESPACES.INBOX);

export const getConversationInboxQuery = (
  jid?: string,
  isComplete: boolean = false
) => {
  const query = xml('query', XML_NAMESPACES.INBOX_CONVERSATION);

  if (jid) {
    query.attrs.jid = jid;
  }
  if (isComplete) {
    query.attrs.complete = 'true';
  }

  return xml('iq', { type: IQ_TYPE.GET }, query);
};

const inboxXDataCreator = ({
  start,
  end,
  order = INBOX_ORDER.DESC
}: TInboxFormFields) => {
  const x = xDataCreator(X_DATA_TYPE.FORM);
  x.append(fieldCreator('FORM_TYPE', XML_NAMESPACES.INBOX, 'hidden'));

  if (order) {
    x.append(fieldCreator('order', order));
  }
  if (start) {
    x.append(fieldCreator('start', start));
  }
  if (end) {
    x.append(fieldCreator('end', end));
  }

  return x;
};

export const setInbox = (args: TInboxQuery = {}) => {
  const { queryId, id, order, end, start, max, before, after } = args;
  const iq = xml('iq', {
    type: IQ_TYPE.SET
  });
  const inbox = xml(
    'inbox',
    {
      xmlns: XML_NAMESPACES.INBOX,
      queryid: queryId || createStanzaId()
    },
    inboxXDataCreator({ start, end, order })
  );
  const rsm = rsmCreator({ max, after, before });

  if (rsm) {
    inbox.append(rsm);
  }

  if (id) {
    iq.attrs.id = id;
  }

  iq.append(inbox);

  return iq;
};

export const setConversationInbox = ({
  jid,
  box,
  read,
  mute
}: IConversationInbox) => {
  const query = xml('query', { xmlns: XML_NAMESPACES.INBOX_CONVERSATION, jid });

  if (box) {
    query.append(xml('box', {}, box));
  }
  if (mute) {
    query.append(xml('mute', {}, mute));
  }
  if (typeof read === 'boolean') {
    query.append(xml('read', {}, read ? 'true' : 'false'));
  }

  return xml('iq', { type: IQ_TYPE.SET }, query);
};

export const setConversationRead = (jid: string) =>
  setConversationInbox({ jid, read: true });

export const setEmptyBin = () =>
  xml('iq', { type: IQ_TYPE.SET }, xml('empty-bin', XML_NAMESPACES.INBOX));
