const COMPONENT_TYPES = {
  HEADER_USER_MENU: 'header-user-menu',
  HEADER_USER_MENU_UNAUTHORIZED: 'header-user-menu-unauthorized',
  LOGIN: 'login',
  RESTORE_PASSWORD_LOGIN: 'restore-password-login',
  // RESTORE_PASSWORD_CHECK_EMAIL: 'restore-password-check-email',
  RESTORE_PASSWORD_SET_NEW: 'restore-password-set-new',
  RESTORE_PASSWORD_SUCCESS: 'restore-password-success',
  REGISTRATION: 'registration',
  REGISTRATION_SUCCESS: 'registration-success',
  PROFILE: 'profile',
  PROFILE_DESKTOP: 'profile-desktop',
  EDIT_PROFILE_MENU: 'edit-profile-menu',
  EDIT_PROFILE_MAIN: 'edit-profile-main',
  EDIT_PROFILE_CONTACTS: 'edit-profile-contacts',
  EDIT_PROFILE_PERSONAL_DATA: 'edit-profile-personal-data',
  EDIT_PROFILE_CHANGE_PASSWORD: 'edit-profile-change-password',
  EDIT_PROFILE_NICKNAME: 'edit-profile-nickname',
  EDIT_PROFILE_VERIFY_ACCOUNT: 'edit-profile-verify-account',
  VERIFY_EMAIL_SUCCESS: 'verify-email-success',
  VERIFYING_EMAIL: 'verifying-email',
  EDIT_PROFILE_BANK_ID_VERIFICATION: 'bank-id-verification',
  // PROFILE_PREFERABLES_INFORMATION: 'preferables-custom-information',
  PROFILE_PREFERABLES_LIST: 'preferables-custom-choose-list',
  PROFILE_VISIBILITY_LIST: 'profile-visibility-list',
  LIMITS: 'limits',
  AUTH_BANNER: 'auth_banner',
  USER_NOTIFICATIONS_MESSAGE: 'user-notifications-message',
  BELL_NOTIFICATION: 'bell-notification',
  TWO_FACTOR: 'two-factor',
  BONUSES: 'bonuses',
  BONUSES_EXPENDED: 'bonuses-expended'
};

export default COMPONENT_TYPES;
