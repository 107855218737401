import { useCallback, useState } from 'react';

import useAnimationFrame from '../useAnimationFrame';

const useAnimationFrameTimer = (step = 1) => {
  const [time, setTime] = useState<number>(0);

  const handleCount = useCallback(
    () => setTime((prev) => prev + step),
    [step, setTime]
  );

  useAnimationFrame({ callback: handleCount, delay: step * 1000 });

  return time;
};

export default useAnimationFrameTimer;
