import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { CustomFC } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import LineCompetitionHeader from '../../../../common/LineCompetitionHeader';
import LineGameCard from '../../../../common/LineGameCard';
import LineMarketsList from '../../../../common/LineMarketsList';
import useCreateGameLink from '../../../../hooks/useCreateGameLink';

import styles from './index.module.css';

type Props = {
  gameId: number;
  sportId: number;
  regionId: number;
  competitionId: number;
  showMoreButton?: boolean;
  regionAlias: string;
  sportAlias: string;
  isLive: number;
};
const SportsSuggestedItem: CustomFC<Props> = ({
  gameId,
  sportId,
  competitionId,
  regionId,
  showMoreButton,
  regionAlias,
  sportAlias,
  isLive
}) => {
  const game = { isLive, sportAlias, regionAlias, id: gameId };
  const gameLink = useCreateGameLink(competitionId, game);

  return (
    <FlexBox className={styles.slide} direction={FLEX_DIRECTION.column}>
      <LineCompetitionHeader
        sportId={sportId}
        regionId={regionId}
        competitionId={competitionId}
      />
      <Link to={gameLink} className={styles.link} />
      <LineGameCard
        key={gameId}
        gameId={gameId}
        sportId={sportId}
        regionId={regionId}
        competitionId={competitionId}
        className={styles.card}
        showMoreButton={showMoreButton}
      >
        <LineMarketsList
          competitionId={competitionId}
          sportId={sportId}
          regionId={regionId}
          gameId={gameId}
        />
      </LineGameCard>
    </FlexBox>
  );
};

export default memo(SportsSuggestedItem);
