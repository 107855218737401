import React from 'react';

import { InnerHTMLSnippet } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../../constants';
import usePaymentService from '../../../../hooks/usePaymentService';
import { useGetWithdrawableBalanceQuery } from '../../../../store/wallet';
import NoPaymentSystem from '../../../common/NoPaymentSystem';
import PaymentImage from '../../../common/PaymentImage';
import PaymentInfo from '../../../common/PaymentInfo';
import SubmitWithdraw from '../../../common/SubmitWithdraw';
import WithdrawBalance from '../../../common/WithdrawBalance';

import styles from './index.module.css';

type Props = {
  id: string;
  imageFromCms?: boolean;
};

const MobileWithdrawMenu: Component<Props> = ({ id, imageFromCms = false }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { system } = usePaymentService(SERVICE_TYPES.WITHDRAW, id);
  const { data } = useGetWithdrawableBalanceQuery(undefined);
  const { availableBalance = null } = data || {};
  const { currency } = system || {};
  const { min } = currency || {};

  if (!system) {
    return <NoPaymentSystem />;
  }

  const { displayName, PaymentSystemIconUrl: imgSrc } = system;

  return (
    <PaddingWrapper>
      <Box>
        <FlexBox
          className={styles.paymentWrapper}
          direction={FLEX_DIRECTION.column}
        >
          <FlexBox
            direction={FLEX_DIRECTION.column}
            align={FLEX_ALIGN.center}
            justify={FLEX_JUSTIFY.around}
            className={styles.itemWrapper}
          >
            <PaymentImage
              imgSrc={imgSrc}
              displayName={displayName}
              imageFromCms={imageFromCms}
              serviceId={id}
            />
            <p className={styles.itemWrapperText}>{displayName}</p>
          </FlexBox>
          <PaymentInfo
            serviceId={id}
            serviceType={SERVICE_TYPES.WITHDRAW}
            className={styles.limitsWrapper}
          />
        </FlexBox>
        <WithdrawBalance />
        {availableBalance < min && (
          <FlexBox className={styles.message} justify={FLEX_JUSTIFY.center}>
            {t('wallet.withdraw.wrong.amount')}
          </FlexBox>
        )}
        {availableBalance >= min && (
          <FlexBox direction={FLEX_DIRECTION.column}>
            <Box className={styles.description}>
              <InnerHTMLSnippet html={t(`wallet.withdraw.description.${id}`)} />
            </Box>
            <SubmitWithdraw serviceId={id} />
          </FlexBox>
        )}
      </Box>
    </PaddingWrapper>
  );
};

export default MobileWithdrawMenu;
