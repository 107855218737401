import { useSearchParamsWithParsing } from '@monorepo/helpers';

import { DEFAULT_EXTERNAL_DATA_TELEGRAM } from '../../constants';

type TExternalData = {
  telegramId: string;
  phone: string;
  firstName: string;
  lastName: string;
  username: string;
};

const useExternalData = (): TExternalData => {
  const { searchParams } = useSearchParamsWithParsing();
  const { data = '' } = searchParams;
  const exportData = atob(data as string);
  const exportedJson =
    exportData === '' ? DEFAULT_EXTERNAL_DATA_TELEGRAM : JSON.parse(exportData);
  const { telegram_id, phone, first_name, last_name, username } = exportedJson;

  return {
    telegramId: telegram_id,
    phone,
    firstName: first_name,
    lastName: last_name,
    username
  };
};

export default useExternalData;
