import React, { ElementRef, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import {
  ConfirmSwitch,
  TPartnerConfig,
  TwoFactorWrapper,
  useGetPartnerConfig,
  useGetUser
} from '@monorepo/auth';
import { Recaptcha, RECAPTCHA_ACTIONS } from '@monorepo/common';
import { Form } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { FIELD_TONE, useShutterRef } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentService from '../../../hooks/usePaymentService';
import { useGetWithdrawableBalanceQuery } from '../../../store/wallet';
import { TFormFieldValues } from '../../../types';
import SubmitButton from '../SubmitButton';

import PaymentsAmountField from './components/AmountField';
import PaymentInputField from './components/InputField';
import PaymentSelectField from './components/SelectField';

import styles from './index.module.css';

type Props = {
  serviceId: string;
  onSubmit: (data: TFormFieldValues) => void;
  serviceType: SERVICE_TYPES;
  isLoading?: boolean;
  additionalValues?: Record<string, string | number>;
};

const PaymentsForm: Component<Props> = ({
  serviceId,
  onSubmit,
  serviceType,
  isLoading,
  children,
  additionalValues = {}
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { data: partnerConfig } = useGetPartnerConfig();
  const { isTwoFactorConfirmation } = (partnerConfig as TPartnerConfig) || {};
  const { generalRoutes } = useSiteSettings();
  const { twoFactor } = generalRoutes;
  const { data: profile } = useGetUser();
  const { isTwoFactorAuthenticationEnabled = false } = profile || {};
  const navigate = useNavigate();
  const goToLink = () => navigate(twoFactor);
  const { data } = useGetWithdrawableBalanceQuery(undefined, {
    skip: serviceType !== SERVICE_TYPES.WITHDRAW
  });
  const { availableBalance = null } = data || {};
  const { system } = usePaymentService(serviceType, serviceId);
  const { fields = [], currency } = system || {};
  const { min, max } = currency || {};
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const recaptchaHandlers = useRef<ElementRef<typeof Recaptcha>>(null);
  const { isDesktop } = useDeviceDetector();
  const defaultValues = fields.reduce(
    (acc, field) => ({ ...acc, [field.fieldKey]: field.value || '' }),
    additionalValues
  );
  const maxValue =
    serviceType === SERVICE_TYPES.WITHDRAW &&
    Number(availableBalance) < Number(max)
      ? Number(availableBalance)
      : max;
  // @TODO make simple
  const buttonClasses = isDesktop ? styles.button : styles.mobileButton;

  useEffect(() => {
    if (formRef.current) {
      formRef.current.reset();
    }
  }, [serviceId]);

  const { setShow, setHide, shutterRef } = useShutterRef();
  const {
    setShow: setConfirmShow,
    setHide: setConfirmHide,
    shutterRef: confirmRef
  } = useShutterRef();

  const handleOnValidated = () => {
    const formData = formRef.current?.getValues();
    return onSubmit(formData as TFormFieldValues);
  };

  const handleSubmit = (formData: TFormFieldValues) => {
    if (serviceType === SERVICE_TYPES.WITHDRAW) {
      setHide();
      return recaptchaHandlers.current?.validateRecaptcha();
    }
    return onSubmit(formData);
  };

  const handleDivideSubmit = (formData: TFormFieldValues) => {
    if (serviceType === SERVICE_TYPES.WITHDRAW && isTwoFactorConfirmation) {
      if (isTwoFactorAuthenticationEnabled) {
        setShow();
      } else {
        setConfirmShow();
      }
      return;
    }
    handleSubmit(formData);
  };

  return (
    <>
      <TwoFactorWrapper
        handleChange={handleSubmit}
        toneType={FIELD_TONE.light}
        handleClose={setHide}
        shutterRef={shutterRef}
      />
      <ConfirmSwitch
        header={t(`wallet.need.activate.two.factor`)}
        confirmText={t(`wallet.need.activate.two.factor.text`)}
        confirmButton={t(`wallet.need.activate.two.factor.button`)}
        closeText={t(`wallet.need.activate.two.factor.close`)}
        goToLink={goToLink}
        handleHide={setConfirmHide}
        confirmRef={confirmRef}
      />
      <Form
        ref={formRef}
        className={styles.form}
        onSubmit={handleDivideSubmit}
        formSettings={{ defaultValues, mode: 'onSubmit' }}
      >
        {children}
        {fields.map((field) => {
          const { fieldKey, type } = field;
          if (type === 'hidden') {
            return <input key={fieldKey} name={fieldKey} type="hidden" />;
          }
          if (fieldKey === 'amount') {
            return (
              <PaymentsAmountField
                key={fieldKey}
                field={field}
                min={min}
                max={maxValue}
                serviceType={serviceType}
              />
            );
          }
          if (type === 'select') {
            return <PaymentSelectField key={fieldKey} field={field} />;
          }
          if (type === 'text' || type === 'number') {
            return <PaymentInputField key={fieldKey} field={field} />;
          }
          return <div key={fieldKey}>{JSON.stringify(field)}</div>;
        })}
        {serviceType === SERVICE_TYPES.WITHDRAW && (
          <Recaptcha
            action={RECAPTCHA_ACTIONS.WITHDRAW}
            isManual
            ref={recaptchaHandlers}
            onValidated={handleOnValidated}
            theme="dark"
          />
        )}
        <SubmitButton
          mode="onSubmit"
          className={buttonClasses}
          processing={isLoading || recaptchaHandlers.current?.isValidating}
          disabled={serviceType === SERVICE_TYPES.WITHDRAW && !availableBalance}
        >
          {t(`wallet.do_${serviceType}`)}
        </SubmitButton>
      </Form>
    </>
  );
};

export default PaymentsForm;
