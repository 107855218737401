import React from 'react';

import { TListRange } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import { Button, BUTTON_SIZE, FlexBox } from '@monorepo/ui-kit';

import getNextRangePeriod from '../../utils/getNextRangePeriod';
import getPrevRangePeriod from '../../utils/getPrevRangePeriod';

import styles from './index.module.css';

type TRangeButtons = {
  pagesList: TListRange[];
  page: number | null;
  setPage: React.Dispatch<React.SetStateAction<number | null>>;
};

const RangeButtons: CustomFC<TRangeButtons> = ({
  pagesList,
  page,
  setPage
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { nextFromDate, nextToDate } = getNextRangePeriod(page, pagesList);
  const { prevFromDate, prevToDate } = getPrevRangePeriod(page, pagesList);

  const handleNextPage = () => setPage((prev) => prev + 1);
  const handlePrevPage = () => setPage((prev) => prev - 1);

  return (
    <>
      {pagesList.length ? (
        <FlexBox className={styles.buttonsWrap}>
          <Button
            className={styles.button}
            size={BUTTON_SIZE.h40}
            onClick={handlePrevPage}
            disabled={page === 0}
          >
            {page === 0
              ? t('bets.history.no_data_prev')
              : `${t('bets.history.show_range_from')} ${prevFromDate} ${t(
                  'bets.history.show_range_to'
                )} ${prevToDate} `}
          </Button>
          <Button
            className={styles.button}
            size={BUTTON_SIZE.h40}
            onClick={handleNextPage}
            disabled={page === pagesList.length - 1}
          >
            {page === pagesList.length - 1
              ? t('bets.history.no_data_next')
              : `${t('bets.history.show_range_from')} ${nextFromDate} ${t(
                  'bets.history.show_range_to'
                )} ${nextToDate}`}
          </Button>
        </FlexBox>
      ) : null}
    </>
  );
};

export default RangeButtons;
