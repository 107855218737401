import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { compareValidator, InputField, TFormField } from '@monorepo/forms';

type Props<TFormValues> = TFormField<TFormValues>;

const ConfirmPasswordField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  rules,
  watchFieldName = 'newPassword',
  directionRule = false,
  errorMessage = 'confirmpassword.notequalnew.error',
  styledClassName = '',
  ...props
}: Props<TFormValues> & {
  watchFieldName?: string;
  directionRule?: boolean;
  errorMessage?: string;
  styledClassName?: string;
}) => {
  const fieldPassword = useWatch({ name: watchFieldName });
  const { trigger, getValues } = useFormContext();

  useEffect(() => {
    if (getValues(props.name)) {
      trigger(props.name);
    }
  }, [fieldPassword]);

  return (
    <InputField
      styledClassName={styledClassName}
      {...props}
      rules={{
        ...rules,
        ...compareValidator(fieldPassword, errorMessage, directionRule)
      }}
    />
  );
};

export default ConfirmPasswordField;
