import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FORM_FACTORS, useDeviceDetector } from 'betkit-ui-device-detector';

import { useIsAuthenticated } from '@monorepo/auth';
import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';

import { USER_TYPES } from '../../constants';
import { TComponentsMap, TPage, TPages } from '../../types';
import getPageConfig from '../../utils/getPageConfig';
import PageContext from '../context';

type TPageProvider = {
  componentsMap: TComponentsMap;
  pages: TPages;
};

const PageProvider: CustomFC<TPageProvider> = ({
  componentsMap = {},
  pages = {},
  children
}) => {
  const [resultPage, setResultPage] = useState<
    [string | undefined, TPage] | []
  >([]);
  const { unAuthorizedRoute, generalRoutes } = useSiteSettings();
  const { home = '/', page404 } = generalRoutes || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { pageType } = useDeviceDetector();
  const { isAuthenticated, isAuthFetched } = useIsAuthenticated();

  const handleRouting = useCallback(
    (location) => {
      const determinedPageConfig =
        getPageConfig(location, pageType as FORM_FACTORS, pages) || [];
      const [, page = null] = determinedPageConfig;
      if (location.length === 1) {
        return navigate(home, { replace: true });
      }
      if (page) {
        const { pageConfig } = page;
        const { userGroup, backLink } = pageConfig;

        if (
          userGroup === USER_TYPES.USER &&
          isAuthFetched &&
          !isAuthenticated
        ) {
          return navigate(
            `${unAuthorizedRoute}?${SEARCH_STRING_PARAMS.redirectUrl}=${
              backLink || location
            }`
          );
        }
        if (
          userGroup === USER_TYPES.GUEST &&
          isAuthFetched &&
          isAuthenticated
        ) {
          return navigate(home);
        }
      } else {
        navigate(page404, { replace: true });
      }
      return setResultPage(determinedPageConfig);
    },
    [pageType, generalRoutes, pages, isAuthenticated, isAuthFetched]
  );

  useLayoutEffect(() => {
    handleRouting(pathname);
  }, [isAuthFetched, handleRouting, pathname]);

  const value = useMemo(() => {
    const [, page = null] = resultPage;
    return {
      componentsMap,
      page
    };
  }, [componentsMap, resultPage]);

  return (
    <PageContext.Provider value={value}>
      <Routes>
        <Route
          path={resultPage ? resultPage[0] : undefined}
          element={children}
        />
      </Routes>
    </PageContext.Provider>
  );
};

export default PageProvider;
