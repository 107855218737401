import React from 'react';

import { useSessionSettings } from '@monorepo/auth';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import { Box, FIELD_TONE, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  toneType?: FIELD_TONE;
};

const GooglePrivacyBadge: CustomFC<Props> = ({
  toneType = FIELD_TONE.light
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const sessionData = useSessionSettings();
  const { recaptchaEnabled } = sessionData;
  if (!recaptchaEnabled) {
    return null;
  }
  return (
    <FlexBox className={styles.googleWrap} align={FLEX_ALIGN.center}>
      <img
        src="/images/recaptchaLogo.svg"
        alt="recaptcha"
        className={styles.image}
      />
      <Box className={`${styles.text} ${styles[toneType]}`}>
        {t('recaptcha.protection')}
        &nbsp;
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('recaptcha.privacypolicy')}
        </a>
        &nbsp;
        {t('recaptcha.and')}
        &nbsp;
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('recaptcha.termsofservice')}
        </a>
      </Box>
    </FlexBox>
  );
};

export default GooglePrivacyBadge;
