/* eslint-disable prefer-object-spread,no-param-reassign,camelcase */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

import { MessengerSliceSate } from '../types/store';

const initialState = {
  userId: null,
  activeChat: null
};

const messengerSlice = createSlice<
  MessengerSliceSate,
  SliceCaseReducers<MessengerSliceSate>
>({
  name: 'messengerSlice',
  initialState,
  reducers: {
    resetSliceState: (state) => {
      state = initialState;
      return state;
    },
    setUser: (state, { payload }: TPayload<string>) => {
      state.userId = payload;
      return state;
    },
    enterChat: (state, { payload }: TPayload<string>) => {
      state.activeChat = payload;
      return state;
    },
    exitChat: (state, { payload }: TPayload<string>) => {
      const { activeChat } = state;
      if (activeChat === payload) {
        state.activeChat = null;
      }
      return state;
    }
  }
});

export const { actions } = messengerSlice;

export default messengerSlice;
