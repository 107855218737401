import intersection from 'lodash/intersection';
import isNumber from 'lodash/isNumber';

import {
  addToDate,
  getDifferenceInSeconds,
  getEndOfDate,
  getEndOfWeek,
  getStartOfDate
} from '@monorepo/helpers';
import { LANGUAGE_CODES_MAP, LANGUAGE_LONG_CODES_MAP } from '@monorepo/i18n';

export const calcTimeFilter = (time?: number | string) => {
  if (!time || (time && !isNumber(Number(time)))) {
    return {};
  }

  let startSeconds = 0;
  let seconds = Number(time) * 60 * 60;
  const now = new Date();

  if (time === 'today') {
    seconds = getDifferenceInSeconds(getEndOfDate(now), now);
  }
  if (time === 'tomorrow' || time === 'aftertomorrow') {
    const startOfDay = getStartOfDate(
      addToDate(now, {
        days: time === 'tomorrow' ? 1 : 2
      })
    );

    startSeconds = getDifferenceInSeconds(startOfDay, now);
    seconds = getDifferenceInSeconds(getEndOfDate(startOfDay), now);
  }

  if (time === 'week') {
    seconds = getDifferenceInSeconds(getEndOfWeek(now), now);
  }

  return { start_ts: { '@now': { '@gte': startSeconds, '@lt': seconds } } };
};

const getValueWithAllLanguageKeys = (
  value: string,
  languages: string[] = []
) => {
  const searchInternational: typeof LANGUAGE_LONG_CODES_MAP = {};
  const upperLanguages = languages.map((element) => element.toUpperCase());
  intersection(Object.keys(LANGUAGE_CODES_MAP), upperLanguages).forEach(
    (langCode) => {
      searchInternational[
        LANGUAGE_LONG_CODES_MAP[
          LANGUAGE_CODES_MAP[langCode as keyof typeof LANGUAGE_CODES_MAP]
        ]
      ] = value;
    }
  );
  return searchInternational;
};

export const createSearchQuery = (search?: string, languages?: string[]) => {
  if (!search) return {};

  return {
    '@or': [
      {
        team1_name: {
          '@like': getValueWithAllLanguageKeys(search, languages)
        }
      },
      {
        team2_name: {
          '@like': getValueWithAllLanguageKeys(search, languages)
        }
      }
    ]
  };
};

export const createIdsQuery = (ids: number[] | string[]) => {
  if (!ids?.length) return {};

  return {
    '@in': ids
  };
};

export const createSearchCompetitionsNameQuery = (
  search?: string,
  languages: string[]
) => {
  if (!search) return {};
  return {
    competition: {
      name: {
        '@like': getValueWithAllLanguageKeys(search, languages)
      }
    }
  };
};

export const createCompetitionWhereIdInQuery = (competitions?: number[]) => {
  if (!competitions?.length) return {};

  return {
    competition: {
      id: {
        '@in': competitions
      }
    }
  };
};
