import { mediatedInvite, TMediateInvite } from '@monorepo/xmpp';

import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const inviteToRoom = () => ({
  query: (args: TMediateInvite) => mediatedInvite(args),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError
});

export default inviteToRoom;
