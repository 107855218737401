import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TBackButtonProps = {
  sportName: string;
  onClick?: () => void;
};

const BackButton: Component<TBackButtonProps> = ({
  sportName,
  onClick,
  className
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const classes = `${styles.button} ${className}`;
  return (
    <FlexBox className={styles.root} align={FLEX_ALIGN.center}>
      <Button
        intent={BUTTON_INTENT.tertiary}
        size={BUTTON_SIZE.h48}
        onClick={onClick}
        className={classes}
      >
        <Icon name="close_1" className={styles.icon} title={sportName} />
        <span className={styles.label}>
          {`${t('sportsbook.menu.back.button')} ${
            sportName?.length ? `/ ${sportName}` : ''
          } `}
        </span>
      </Button>
    </FlexBox>
  );
};

export default BackButton;
