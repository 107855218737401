import React from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { getImageCmsPath, InnerHTMLSnippet } from '@monorepo/cms';
import { formatUnixTime } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  NotificationDate,
  NotificationImage,
  NotificationText,
  NotificationTitle,
  TEXT_SIZE
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TCMSNotification = {
  hrefType: string;
  title: string;
  content: string;
  src: string;
  srcAlt: null | string;
  srcType: string;
  href?: string;
  target?: string;
  showTo: number;
};

type Props = {
  data: TCMSNotification;
  onClick: void;
  toneType?: FIELD_TONE;
  textSize?: TEXT_SIZE;
  isRead?: boolean;
};

const CMSNotificationItem: Component<Props> = ({
  data,
  onClick,
  toneType,
  textSize,
  isRead
}) => {
  const { src, srcAlt, title, href, target, hrefType, content, date } = data;
  const formatedDate = date ? formatUnixTime(date, 'dd.MM.yyyy, HH:mm') : null;
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();

  return (
    <FlexBox
      className={isRead ? `${styles.item} ${styles.read}` : styles.item}
      onClick={!isDesktop ? null : onClick}
    >
      {src && (
        <NotificationImage
          className={styles.image}
          src={getImageCmsPath(src)}
          alt={srcAlt || title}
        />
      )}
      <FlexBox
        className={src ? styles.contentWrapper : styles.contentWrapperFull}
        direction={FLEX_DIRECTION.column}
      >
        {formatedDate && (
          <NotificationDate className={styles.date}>
            {formatedDate}
          </NotificationDate>
        )}
        <NotificationTitle
          textSize={textSize}
          toneType={toneType}
          className={styles.title}
          isRead={isRead}
        >
          {title}
        </NotificationTitle>
        <NotificationText
          textSize={textSize}
          toneType={toneType}
          className={styles.text}
        >
          <InnerHTMLSnippet html={content} />
        </NotificationText>
      </FlexBox>
      {isDesktop ? (
        <FlexBox
          justify={FLEX_JUSTIFY.center}
          align={FLEX_ALIGN.center}
          className={styles.iconWrapper}
        >
          <Icon name="arrow_right-1" title="" className={styles.icon} />
        </FlexBox>
      ) : (
        <Button
          intent={BUTTON_INTENT.primary}
          className={styles.linkButton}
          size={BUTTON_SIZE.h40}
          onClick={onClick}
        >
          {t('read.more')}
        </Button>
      )}
    </FlexBox>
  );
};

export default CMSNotificationItem;
