import { getSearchString } from '@monorepo/helpers';

import { TGameInformationGetArgs } from '../../../../types';

const getGameInformation = () => ({
  query: ({ gameId, source, partnerId, name }: TGameInformationGetArgs) => {
    const url = `/tutorials${getSearchString({
      partner_id: partnerId,
      game_id: gameId,
      source,
      name
    })}`;

    return {
      url,
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  },
  keepUnusedDataFor: 60
});

export default getGameInformation;
