import React from 'react';

import { Component } from '@monorepo/type';

import { toneTypeProp } from '../../types';

import styles from './index.module.css';

const Loader: Component<toneTypeProp> = ({
  className = '',
  toneType = 'FIELD_TONE.dark'
}) => <div className={`${styles.loader} ${styles[toneType]} ${className}`} />;

export default Loader;
