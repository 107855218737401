import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import { Component, TQueryResult } from '@monorepo/type';
import { EllipsisText, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import useGetLineGames from '../../hooks/useGetLineGames';
import { selectSportsBySportId } from '../../store/api/selectors';
import { TNormalizedSports } from '../../types';
import SportColor from '../SportColor';
import SportIcon from '../SportIcon';

import CounterLink from './components/CounterLink';

import styles from './index.module.css';

type Props = {
  sportId: number;
  isShowCounter?: boolean;
};

const LineSportHeader: Component<Props> = ({
  sportId,
  isShowCounter = true,
  className = ''
}) => {
  const { data: sport } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectSportsBySportId(result, sportId)
    }))
  });

  if (isEmpty(sport)) {
    return null;
  }

  const { name, alias } = sport;

  return (
    <SportColor alias={alias}>
      <FlexBox
        align={FLEX_ALIGN.center}
        className={`${styles.wrap} ${className}`}
      >
        <SportIcon name={alias?.toLowerCase()} className={styles.icon} />
        <EllipsisText className={styles.title}>{name}</EllipsisText>
        {isShowCounter && <CounterLink sportId={sportId} />}
      </FlexBox>
    </SportColor>
  );
};

export default LineSportHeader;
