import get from 'lodash/get';

import { SPORTS_QUERY_KEYS } from '../../../constants';

const getBetsCount = () => ({
  query: () => ({
    body: {
      command: 'bet_history',
      params: {
        where: {
          only_counts: true,
          outcome: 0
        }
      }
    }
  }),
  providesTags: [SPORTS_QUERY_KEYS.BETS_COUNT],
  transformResponse(response: any): null | number {
    return get(response, 'bets.count', null);
  }
});

export default getBetsCount;
