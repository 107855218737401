import React from 'react';

import { formatUnixTime } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import { GAME_STATE, SCORE_TYPES } from '../../constants';
import { TGameInfo } from '../../types';
import {
  formatGameScoreSets,
  formatGameTime,
  formatLiveTeamScore,
  formatScore
} from '../../utils';
import getPeriodTranslationKey from '../../utils/getTranslatePeriod';

import styles from './index.module.css';

type Props = {
  team2Name?: string;
  date: number;
  withFullScoreFormat?: boolean;
  gameInfo?: TGameInfo;
  stats?: Record<string, any>;
  sportAlias?: string;
  showType?: string;
};

const LiveInfo: Component = ({ className = '', children }) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      className={`${styles.info} ${className}`}
    >
      <Icon
        name="live-0"
        className={styles.icon}
        title={t('sportsbook.type.menu.live')}
      />
      {children}
    </FlexBox>
  );
};

const GameLiveInfo: Component<Props> = ({
  className = '',
  team2Name = '',
  date,
  withFullScoreFormat = false,
  gameInfo,
  stats,
  sportAlias,
  showType
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const isOutright = showType === SCORE_TYPES.OUTRIGHT;
  const { passes = {} } = stats || {};
  const { team1Value = null, team2Value = null } = passes;
  const { currentGameState, score1 = '', score2 = '' } = gameInfo || {};

  if (currentGameState === GAME_STATE.NOT_STARTED) {
    return (
      <LiveInfo className={className}>{`${t(
        'sportsbook.game.starting'
      )} ${formatUnixTime(date, 'HH:mm')}`}</LiveInfo>
    );
  }

  const period = getPeriodTranslationKey(
    gameInfo?.currentGameState,
    sportAlias
  );

  if (isOutright) {
    return <LiveInfo className={className}>{`${t(period)}`}</LiveInfo>;
  }

  const liveScore = formatLiveTeamScore(team1Value, team2Value);
  const isSingleTeam = team2Name === '' && score2 === '';
  const scoreSets = formatGameScoreSets(stats, isSingleTeam);

  const time = formatGameTime(gameInfo);

  const score = formatScore(score1, score2);

  const extendedFullScore = `${t(period)}${
    scoreSets
      ? ` ${score} ${scoreSets}${isSingleTeam ? '' : ` ${liveScore}`}`
      : ''
  } ${time}`;

  const fullScore = `${t(period)}${time}${
    scoreSets ? ` ${scoreSets}${isSingleTeam ? '' : ` ${liveScore}`}` : ''
  }`;

  const scoreData = withFullScoreFormat ? extendedFullScore : fullScore;

  return <LiveInfo className={className}>{scoreData}</LiveInfo>;
};

export default GameLiveInfo;
