import { MESSAGES_QUERY_KEYS } from '../../../constants';
import { TChangeMessageStatus } from '../../../types';

const makeReadMessage = () => ({
  query: ({ id }: TChangeMessageStatus) => ({
    body: {
      command: 'read_user_message',
      params: {
        message_id: id
      }
    }
  }),
  checkError: (response: any) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },
  invalidatesTags: [MESSAGES_QUERY_KEYS.INCOMING]
});
export default makeReadMessage;
