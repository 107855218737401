import { TPauseLimitsRequest } from '../../../types';

const setTimeOutLimits = () => ({
  query: ({ pause, pauseRadio }: TPauseLimitsRequest) => ({
    body: {
      command: 'set_client_self_exclusion',
      params: {
        exc_type: 1,
        minutes: pauseRadio === '1' ? pause : 0
      }
    }
  })
});
export default setTimeOutLimits;
