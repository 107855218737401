import Element from 'ltx/src/Element';

import {
  createCommunityInviteNotification,
  createDeclineCommunityNotification
} from '../../../../utils/notifications';
import { mucUserXDataParse } from '../../../../utils/stanzaParsers';
import MessageHandler from '../MessageHandler';

class MUCUserXDataMessageHandler {
  messageHandler: MessageHandler;

  constructor(handler: MessageHandler) {
    this.messageHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { updateInbox } = this.messageHandler.reduxActions;
    const { displayToast } = this.messageHandler.callbacks;
    const message = mucUserXDataParse(stanza);
    if (message?.invite) {
      const notification = createCommunityInviteNotification(
        message.roomJid,
        message.from
      );
      updateInbox(message);
      displayToast(notification);
    } else if (message?.from) {
      displayToast(
        createDeclineCommunityNotification(message.roomJid, message.from)
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(`Invalid xData message ${stanza}`);
    }
  };
}

export default MUCUserXDataMessageHandler;
