import React from 'react';
import { memoize } from 'proxy-memoize';

import { ICON_TYPE } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';

import useGetCompetitionsWithSports from '../../../../hooks/useGetCompetitionsWithSports';
import { selectCompetitionsBySportIdRegionIdCompetitionId } from '../../../../store/api/selectors';
import CompetitionMenuItem from '../../../CompetitionMenuItem';

type TCompetitionProps = {
  competitionId: number;
  regionId: number;
  sportId: number;
};

const Competition: Component<TCompetitionProps> = ({
  competitionId,
  regionId,
  sportId
}) => {
  const { generalRoutes } = useSiteSettings();
  const { prematch } = generalRoutes;
  const { data: competition } = useGetCompetitionsWithSports(
    { favorite: true },
    {
      refetchOnMountOrArgChange: false,
      selectFromResult: memoize((result) => ({
        data: selectCompetitionsBySportIdRegionIdCompetitionId(
          result,
          sportId,
          regionId,
          competitionId
        )
      }))
    }
  );
  const { id, name, game, sportAlias, regionAlias } = competition;

  return (
    <CompetitionMenuItem
      link={`${prematch}/${sportAlias}/${regionAlias}/${id}`}
      title={name}
      count={game}
      iconType={ICON_TYPE.FONT}
      iconName={sportAlias}
    />
  );
};

export default Competition;
