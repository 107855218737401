import React from 'react';

import { Icon } from '@monorepo/icons';

import { FIELD_TONE } from '../../../constants';
import { CheckboxProps } from '../../../types';
import Label from '../Label';

import styles from './index.module.css';

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      label = '',
      className = '',
      labelClass = '',
      toneType = FIELD_TONE.dark,
      disabled,
      ...props
    },
    ref
  ) => {
    const classes = `${styles.wrap}
      ${styles[toneType]}
      ${disabled ? styles.disabled : ''}
      ${className}`;

    return (
      <Label htmlFor={id} className={classes} toneType={toneType}>
        <input
          id={id}
          type="checkbox"
          className={styles.input}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        <Icon name="check_filter" className={styles.checkmark} title="" />
        {Boolean(label) && (
          <div className={`${styles.label} ${labelClass}`}>{label}</div>
        )}
      </Label>
    );
  }
);

export default Checkbox;
