import React from 'react';
import { Link } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { FIELD_TONE, NotificationTitle, TEXT_SIZE } from '@monorepo/ui-kit';

import useChatUser from '../../../../hooks/useChatUser';
import useGetRoomDiscoInfo from '../../../../hooks/useGetRoomDiscoInfo';

import styles from './index.module.css';

type Props = {
  roomId: string;
  userId: string;
  toneType?: FIELD_TONE;
  textSize?: TEXT_SIZE;
};

const CommunityTitle: Component<Props> = ({
  roomId,
  userId,
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small
}) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { profile, community } = generalRoutes;
  const { displayName } = useChatUser(userId);
  const { data } = useGetRoomDiscoInfo(roomId);
  const { name = roomId } = data || {};

  return (
    <NotificationTitle textSize={textSize} toneType={toneType}>
      <Link to={`${profile}/${userId}`} className={styles.link}>
        {displayName}
      </Link>
      &nbsp;
      {t('notification.unread_message.from_user_to_community')}
      &nbsp;
      <Link to={`${community}/${roomId}`} className={styles.link}>
        {name}
      </Link>
    </NotificationTitle>
  );
};

export default CommunityTitle;
