import React, { ComponentProps } from 'react';

import { SubmitButton } from '@monorepo/forms';
import { CustomFC } from '@monorepo/type';
import { BUTTON_SIZE } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = Omit<ComponentProps<typeof SubmitButton>, 'type'>;

const WalletSubmitButton: CustomFC<Props> = ({
  children,
  className = '',
  ...props
}) => (
  <SubmitButton
    size={BUTTON_SIZE.h40}
    className={`${styles.button} ${className}`}
    {...props}
  >
    {children}
  </SubmitButton>
);

export default WalletSubmitButton;
