import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const CasinoTournaments = lazy(() => import('./CasinoTournaments'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <CasinoTournaments config={config} />
  </Suspense>
);
