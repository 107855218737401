import React from 'react';
import { NavLink } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';

import useGetPartnerConfig from '../../hooks/useGetPartnerConfig';

import styles from './index.module.css';

const TwoFactorButton = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { data: partnerConfig } = useGetPartnerConfig();
  const { twoFactorEnabled } = partnerConfig || {};
  const { generalRoutes } = useSiteSettings();
  const { twoFactor } = generalRoutes;

  if (!twoFactorEnabled) {
    return null;
  }

  return (
    <li
      key="profile-two-factor"
      className={styles.item}
      data-custom="profile-two-factor"
    >
      <NavLink to={twoFactor} className={styles.link}>
        {t('profile.two.factor', { ns: NAMESPACES.ACCOUNT })}
        <Icon name="arrow_2" className={styles.icon} title="" />
      </NavLink>
    </li>
  );
};
export default TwoFactorButton;
