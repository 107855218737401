import React from 'react';

import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { TGame } from '../../../../types';
import Team from '../Team';

type Props = {
  game: TGame;
};

const Teams: Component<Props> = ({ className = '', game }) => {
  const { team1Name, team2Name = '', team1Id, team2Id } = game || {};

  return (
    <FlexBox
      justify={FLEX_JUSTIFY.center}
      align={FLEX_ALIGN.center}
      className={className}
    >
      <Team title={team1Name} itemId={team1Id} />
      {Boolean(team2Name) && <Team title={team2Name} itemId={team2Id} />}
    </FlexBox>
  );
};

export default Teams;
