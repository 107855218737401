import React from 'react';

import { classNameProp, CustomFC } from '@monorepo/type';

import styles from './index.module.css';

type Props = {
  count?: string | number | null;
} & classNameProp;

const Count: CustomFC<Props> = ({ count, className = '' }) => {
  if (count) {
    return <div className={`${styles.count} ${className}`}>{count}</div>;
  }

  return null;
};

export default Count;
