import React, { useState } from 'react';

import { Icon } from '@monorepo/icons';

import {
  BUTTON_INTENT,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_JUSTIFY
} from '../../../constants';
import { InputProps } from '../../../types';
import Button from '../../Button';
import FlexBox from '../../FlexBox';

import styles from './index.module.css';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      inputValue = '',
      inputClassName = '',
      type = 'text',
      disabled,
      readOnly,
      iconName = '',
      iconClassName = '',
      activeIcon = false,
      onClean,
      setIsCheckedPassword,
      toneType = FIELD_TONE.dark,
      autoComplete = '',
      maxLength,
      ...props
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const isShowControls =
      !disabled &&
      (type === 'password' || Boolean(iconName) || Boolean(onClean));
    const classes = `${styles.wrap}
      ${isShowControls ? styles.showControls : ''}
      ${styles[toneType]}
      ${className}`;

    const cleanButtonStyles =
      inputValue || ref?.current?.value ? '' : styles.buttonDisabled;

    const togglePasswordVisibility = () => {
      setIsPasswordVisible((prev) => !prev);
      setIsCheckedPassword((prev) => !prev);
    };

    return (
      <FlexBox className={classes} align={FLEX_ALIGN.center}>
        <input
          ref={ref}
          type={isPasswordVisible ? 'text' : type}
          className={`${styles.input} ${inputClassName}`}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autoComplete}
          maxLength={maxLength}
          {...props}
        />
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.controls}
        >
          {Boolean(onClean) && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={onClean}
              className={`${styles.button} ${cleanButtonStyles}`}
            >
              <Icon name="close_0" className={styles.icon} />
            </Button>
          )}
          {type === 'password' && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              isOnlyIcon
              onClick={togglePasswordVisibility}
              className={styles.button}
              toneType={toneType}
            >
              <Icon
                name={`eye_${isPasswordVisible ? '1' : '3'}`}
                className={styles.eye}
                title=""
              />
            </Button>
          )}
          {Boolean(iconName) && (
            <Icon
              className={`${styles.icon} ${iconClassName} ${
                activeIcon ? styles.activeIcon : ''
              }`}
              name={iconName}
              title=""
            />
          )}
        </FlexBox>
      </FlexBox>
    );
  }
);

export default Input;
