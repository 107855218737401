import { camelizeKeys } from 'humps';

import { TWinnersList, TWinnersResponse } from '../../../../types';

const getWinnersAdapter = (response: TWinnersResponse): TWinnersList => {
  const { details } = response;
  return camelizeKeys(details) as TWinnersList;
};

export default getWinnersAdapter;
