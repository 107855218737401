import { useGetUser } from '@monorepo/auth';
import { getHostName } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

import { useMakeWithdrawMutation } from '../../store/wallet';
import formatPaymentError from '../../utils/formatPaymentError';

type TUserData = {
  amount: string;
  serviceId: string;
  fields: Record<string, string | number>;
};

const useWithdraw = () => {
  const location = getHostName();
  const [makeWithdraw, { isLoading, isError }] = useMakeWithdrawMutation();
  const { successNotification, errorNotification } = useNotifications();
  const { t } = useI18n(NAMESPACES.FORMS);
  const { generalRoutes } = useSiteSettings();
  const { withdraw } = generalRoutes;
  const { data: user } = useGetUser();
  const currency = user?.currencyName;

  const onWithdraw = async (userData: TUserData) => {
    const { amount, serviceId, fields } = userData;
    const { data } = await makeWithdraw({
      amount,
      domain: location,
      balancePage: withdraw,
      currency,
      serviceId,
      fields
    });

    if (!data || data.error) {
      errorNotification(formatPaymentError(t, 'deposit.reject', data));
    } else {
      successNotification(t('withdraw.verify'));
    }
  };

  return { onWithdraw, isLoading, isError };
};

export default useWithdraw;
