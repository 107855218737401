import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const ChatCenterEmpty = lazy(() => import('./ChatCenterEmpty'));

export default ({ config }: ComponentProps<typeof ChatCenterEmpty>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ChatCenterEmpty config={config} />
  </Suspense>
);
