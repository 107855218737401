import React, { ReactElement, ReactNode } from 'react';

import { classNameProp, CustomFC } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

type TTabsListProps = {
  children: string | ReactNode;
  onChange: (index: number) => void;
  activeIndex: number;
  fullWidth?: boolean;
} & classNameProp;

const TabsList: CustomFC<TTabsListProps> = ({
  children,
  onChange,
  activeIndex,
  fullWidth,
  className = ''
}): ReactElement => {
  const processedChildren = React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    return React.cloneElement(child, {
      index,
      onClick: onChange,
      activeIndex
    });
  });
  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.start}
      className={`${styles.root} ${
        fullWidth ? styles.fullWidth : ''
      } ${className}`}
    >
      {processedChildren}
    </FlexBox>
  );
};

export default TabsList;
