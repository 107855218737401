import React from 'react';

import { FLEX_DIRECTION, FlexBox, PaddingWrapper } from '@monorepo/ui-kit';

import { TAdditional } from '../../types';

import CmsNavMenuItems from './components/CmsNavMenuItems';
import GametaButton from './components/GametaButton';
import HeaderNavMenuItems from './components/HeaderNavMenuItems';

import styles from './index.module.css';

type THeaderMobileNavMenu = {
  config: {
    menuId: number;
    className: string;
    items?: { title: string; link: string }[];
    replace?: boolean;
    additional?: TAdditional;
  };
};

const HeaderMobileNavMenu = ({ config }: THeaderMobileNavMenu) => {
  const { menuId, items, className, replace, additional } = config;

  return (
    <PaddingWrapper isOnlyMobile>
      <FlexBox direction={FLEX_DIRECTION.column}>
        <ul className={`${styles.list} ${items ? className : ''}`}>
          {items ? (
            <HeaderNavMenuItems
              items={items}
              replace={replace}
              className={className}
            />
          ) : (
            <CmsNavMenuItems menuId={menuId} className={className} />
          )}
          {additional && (
            <li className={styles.gametaLi}>
              <GametaButton additional={additional} />
            </li>
          )}
        </ul>
      </FlexBox>
    </PaddingWrapper>
  );
};

export default HeaderMobileNavMenu;
