import { TRestorePassword } from '../../../types';

const restorePassword = () => ({
  query: ({ login }: TRestorePassword) => ({
    body: {
      command: 'forgot_password',
      params: {
        email: login
      }
    },
    checkError: (response) => {
      const { code, data, msg } = response;
      const { result, result_text: resultText } = data;
      const message = msg || resultText || true;
      return !(code === 0 && result === 0) ? message : false;
    }
  })
});

export default restorePassword;
