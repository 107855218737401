import { convertParams } from '@monorepo/helpers';

import { TCategories, TCategoriesRequest } from '../../../types';
import categoryAdapter from '../adapters/categoryAdapter';

const getCategories = () => ({
  query: ({
    partnerId,
    isMobile,
    lang,
    country,
    category
  }: TCategoriesRequest) => {
    const commonParams = {
      partner_id: partnerId,
      is_mobile: isMobile,
      lang,
      country
    };
    const params = category
      ? { ...commonParams, category_id: category, with_extra_categories: 1 }
      : { ...commonParams, only_categories: 1 };

    return `${
      category ? 'getOptionsByGameOrder' : 'getOptions'
    }?${convertParams(params)}`;
  },
  transformResponse(response): TCategories[] {
    return categoryAdapter(response);
  }
});
export default getCategories;
