import React from 'react';

import { Page } from '@monorepo/layout';
import { CustomFC } from '@monorepo/type';

import PageProvider from '../../store/provider';
import { TComponentsMap, TPages } from '../../types';

type TPageProps = {
  componentsMap: TComponentsMap;
  pages?: TPages;
};

const PageWrapper: CustomFC<TPageProps> = ({
  componentsMap,
  pages = {},
  children
}) => (
  <PageProvider componentsMap={componentsMap} pages={pages}>
    <Page />
    {children}
  </PageProvider>
);

export default PageWrapper;
