import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import findIndex from 'lodash/findIndex';

import { useGetUser } from '@monorepo/auth';

import { useSelectBetsInfo } from '../../store/api/selectors/betslipSelector';
import { TBetInfo } from '../../types';
import useGetFreeBet from '../useGetFreeBet';

const useGetFreebetAmount = () => {
  const { data: profile } = useGetUser();
  const bets: TBetInfo[] = useSelectBetsInfo();

  const getFreeBetData = useMemo(() => {
    const isLive = findIndex(bets, ['isLive', 1]) >= 0;
    const betsInfo = bets?.map(({ price = '', eventId = '' }) => ({
      Coeficient: price,
      SelectionId: eventId
    }));
    return { bets: betsInfo, isLive };
  }, [bets.length]);

  const formFreebetId = useWatch({ name: 'freebet' });
  const { data } = useGetFreeBet(getFreeBetData, {
    skip: !profile?.hasFreeBets
  });
  const freebetAmount = data?.find(
    ({ id }) => id === Number(formFreebetId)
  )?.amount;

  return { freebetAmount, formFreebetId };
};

export default useGetFreebetAmount;
