import merge from 'lodash/merge';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetCompetitionRequest } from '../../../types';
import { normalizeCompetitionResponse } from '../adapters/competionAdapter';

const getCompetitions = (api) => ({
  query: ({
    sportAlias,
    regionAlias,
    competitionId
  }: TGetCompetitionRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        subscribe: true,
        what: {
          sport: ['id', 'alias', 'name', 'order'],
          region: ['id', 'alias', 'name', 'order'],
          competition: ['id', 'name', 'order']
        },
        where: {
          sport: {
            alias: sportAlias
          },
          region: {
            alias: regionAlias
          },
          competition: {
            id: competitionId
          }
        }
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.COMPETITION],
  transformResponse: normalizeCompetitionResponse,
  async onCacheEntryAdded(
    args: any,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => merge(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.COMPETITION]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
});

export default getCompetitions;
