import Element from 'ltx/src/Element';

import IqHandler from '../../handlers/IqHandler';

const iqSendStanzaCallback = (iqHandler: IqHandler) => (stanza: Element) => {
  if (stanza?.is('iq')) {
    return iqHandler.processSentStanza(stanza);
  }
  return stanza;
};

export default iqSendStanzaCallback;
