import trim from 'lodash/trim';

import { TPlayerProfileInfo } from '@monorepo/cms';

import { PROFILE_NICKNAME } from '../../constants';
import { TProfile } from '../../types';

const getNickName = (
  profile: TProfile,
  player?: TPlayerProfileInfo,
  userDefaultNick: PROFILE_NICKNAME
) => {
  const { firstName = '', lastName = '', username = '' } = profile || {};

  if (player?.nickname) {
    return player.nickname;
  }
  if (userDefaultNick === PROFILE_NICKNAME.fullname) {
    return trim(`${firstName} ${lastName}`) || username;
  }
  return profile[userDefaultNick] || username;
};
export default getNickName;
