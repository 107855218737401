import {
  TGameEvent,
  TGameEventDescriptionResponse,
  TGameEventRequest
} from '../../../types';
import getGameInfoAdapter from '../adapters/getGameInfoAdapter';

const getGameInfo = () => ({
  query: ({ gameId }: TGameEventRequest) => ({
    body: {
      command: 'get_results',
      params: {
        game_id: gameId
      }
    }
  }),
  transformResponse(response: TGameEventDescriptionResponse): TGameEvent[] {
    return !response ? [] : getGameInfoAdapter(response);
  }
});
export default getGameInfo;
