export enum SERVICE_TYPES {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw'
}

export const REAL_NUMBER_REGEXP =
  /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;

export const USER_CARD_REGEXP = /^\d{4}-\d{4}-\d{4}-\d{4}$/;

export enum PAY_METHOD {
  IFRAME = 'iframe',
  POST = 'post',
  GET = 'get',
  MESSAGE = 'message'
}

export const WALLET_QUERY_KEYS = {
  GET_PAYMENTS_LIST: 'GET_PAYMENTS_LIST',
  GET_PAYMENTS_DETAILS: 'GET_PAYMENTS_DETAILS',
  GET_BALANCE: 'GET_BALANCE'
};

export const PAYMENT_CODES = {
  CARDS: 1,
  WALLET: 2,
  VOUCHER: 3,
  CRYPTO: 4,
  BANK_TRANSFER: 5,
  OPEN_BANKING: 6,
  QR: 7,
  MOBILE: 8,
  CASH: 9,
  AGENT: 10
};

export const PAYMENT_GROUP_ALL = 'all';
export const PAYMENT_GROUP_OTHER = 'others';

export const PAYMENT_MAP = {
  [PAYMENT_CODES.CARDS]: 'Cards',
  [PAYMENT_CODES.WALLET]: 'Wallet',
  [PAYMENT_CODES.VOUCHER]: 'Voucher',
  [PAYMENT_CODES.CRYPTO]: 'Crypto',
  [PAYMENT_CODES.BANK_TRANSFER]: 'Bank_transfer',
  [PAYMENT_CODES.OPEN_BANKING]: 'Open_banking',
  [PAYMENT_CODES.QR]: 'QR',
  [PAYMENT_CODES.MOBILE]: 'Mobile',
  [PAYMENT_CODES.CASH]: 'Cash',
  [PAYMENT_CODES.AGENT]: 'Agent',
  [PAYMENT_GROUP_ALL]: 'All',
  [PAYMENT_GROUP_OTHER]: 'Others'
};

export const WITHDRAWAL_CODES = {
  REJECTED: -2,
  CANCELED: -1,
  NEW: 0,
  ALLOWED: 1,
  PENDING: 2,
  PAID: 3,
  WITHDRAWAL_ROLLBACK: 4
};

export const WITHDRAWAL_MAP = {
  [WITHDRAWAL_CODES.CANCELED]: 'Rejected',
  [WITHDRAWAL_CODES.REJECTED]: 'Cancelled',
  [WITHDRAWAL_CODES.NEW]: 'New',
  [WITHDRAWAL_CODES.ALLOWED]: 'Allowed',
  [WITHDRAWAL_CODES.PENDING]: 'Pending',
  [WITHDRAWAL_CODES.PAID]: 'Paid',
  [WITHDRAWAL_CODES.WITHDRAWAL_ROLLBACK]: 'WithdrawalRollback'
};

export const REQUIRED_PAYMENT_DATA = {
  PHONE: 'depositPhone',
  FIRST_NAME: 'depositFirstName',
  LAST_NAME: 'depositLastName',
  PASSWORD: 'depositPassword'
};
