import React, { lazy, Suspense } from 'react';

import { ProfileSkeleton } from '@monorepo/common';

const EditProfileMenu = lazy(() => import('./EditProfileMenu'));

export default ({ config }: any) => (
  <Suspense fallback={<ProfileSkeleton />}>
    <EditProfileMenu config={config} />
  </Suspense>
);
