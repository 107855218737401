import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const PromotionsSlots = lazy(() => import('./PromotionsSlots'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <PromotionsSlots config={config} />
  </Suspense>
);
