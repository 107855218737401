import React from 'react';
import { NavLink } from 'react-router-dom';

import { useIsAuthenticated, UserInfo } from '@monorepo/auth';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';

import styles from './index.module.css';

const User = () => {
  const { generalRoutes } = useSiteSettings();
  const { login } = generalRoutes;
  const { isAuthenticated } = useIsAuthenticated();
  return isAuthenticated ? (
    <UserInfo />
  ) : (
    <NavLink to={login} className={styles.avatar}>
      <Icon name="user" className={styles.icon} />
    </NavLink>
  );
};
export default User;
