import notificationsAdapter from '../adapters/notificationsAdapter';

const getNotifications = () => ({
  query: ({ lang, partner, slug }: any) =>
    `${lang}/partners/${partner}/notifications/${slug}`,
  transformResponse(response): any[] {
    return notificationsAdapter(response);
  }
});
export default getNotifications;
