import { useMemo } from 'react';

import { MESSAGES_TYPES } from '@monorepo/xmpp';

import { TStoreInboxMessage } from '../../../types';
import useSelectInboxList from '../useSelectInboxList';

type UseSelectInbox = {
  list: TStoreInboxMessage[];
  total: number;
};

const useSelectInbox = (type: MESSAGES_TYPES) => {
  const list = useSelectInboxList();

  return useMemo(
    () =>
      list.reduce(
        (acc: UseSelectInbox, item: TStoreInboxMessage) => {
          if (item.type === type) {
            return {
              list: [...acc.list, item],
              total: acc.total + Number(item.unread)
            };
          }
          return acc;
        },
        { list: [], total: 0 }
      ),
    [list]
  );
};

export default useSelectInbox;
