import { LAUNCH_TYPE } from '../../constants';

const getLaunchUrl = (
  gameId: string,
  openType: string = LAUNCH_TYPE.DEMO,
  baseUrl: string = '/'
) => {
  const search = new URLSearchParams({ gameId, openType });

  return `${baseUrl}?${search.toString()}`;
};

export default getLaunchUrl;
