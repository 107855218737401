import React from 'react';

import { Component } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_DIRECTION, FLEX_JUSTIFY } from '../../../constants';
import FlexBox from '../../FlexBox';

import styles from './index.module.css';

type Props = {
  image: React.ReactNode;
};

const NotificationLayout: Component<Props> = ({
  className = '',
  children,
  image
}) => (
  <FlexBox align={FLEX_ALIGN.stretch} className={`${styles.wrap} ${className}`}>
    <FlexBox
      align={FLEX_ALIGN.center}
      direction={FLEX_DIRECTION.column}
      justify={FLEX_JUSTIFY.center}
      className={styles.banner}
    >
      {image}
    </FlexBox>
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.content}>
      {children}
    </FlexBox>
  </FlexBox>
);

export default NotificationLayout;
