import { NAMESPACES } from '../../constants';
import useI18n from '../useI18n';

const useCurrencyTranslate = (currency?: string) => {
  const { t } = useI18n(NAMESPACES.CURRENCIES);
  if (currency) {
    return t(`${currency.toLowerCase()}`);
  }
  return '';
};

export default useCurrencyTranslate;
