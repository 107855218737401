import { TGetMarketTypesRequest } from '../../../types';
import { normalizeMarketTypesResponse } from '../adapters/marketTypesAdapter';

const getMarketTypes = () => ({
  query: ({ sportAlias }: TGetMarketTypesRequest) => ({
    body: {
      command: 'get_market_type',
      params: {
        sport_alias: sportAlias
      }
    }
  }),
  keepUnusedDataFor: 30,
  transformResponse: normalizeMarketTypesResponse
});
export default getMarketTypes;
