import { googleAnalytics } from '@monorepo/helpers';
import { getSocketSWARM } from '@monorepo/websocket';

import { AUTH_QUERY_KEYS, LOGOUT_EXCLUDE_TYPES } from '../../../constants';
import { TUserProfile } from '../../../types/user';
import userProfileAdapter from '../adapters/userProfile';
import userUpdater from '../updaters/userUpdater';

const getUser = (api) => ({
  query: () => ({
    body: {
      command: 'get',
      params: {
        source: 'user',
        what: {
          profile: []
        },
        subscribe: true
      }
    }
  }),
  keepUnusedDataFor: 0,
  providesTags: [AUTH_QUERY_KEYS.USER],
  async onCacheEntryAdded(
    args: undefined,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData, getState }
  ) {
    const instance = getSocketSWARM();
    try {
      const handleLogout = (userProfile: TUserProfile) => {
        const { logout, excludeType, id } = userProfile.profile;
        const { app = {} } = getState() || {};
        const { sessionSwarmId = '' } = app;
        const logoutExclude =
          excludeType && LOGOUT_EXCLUDE_TYPES.includes(excludeType);
        if (logout || logoutExclude) {
          dispatch(api.endpoints.logout.initiate());
          // ga js-swarm-logout
          const ga = googleAnalytics();
          ga.dispatch({
            event: ga.event.jsSwarmLogout,
            eventParam: {
              event_category: 'js'
            },
            event_options: {
              swarm_session_id: sessionSwarmId,
              userId: id
            }
          });
        }
      };
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      handleLogout(data);
      instance.subscribe(
        data?.subId,
        (newData: Partial<TUserProfile>) => {
          updateCachedData((draft: TUserProfile) => {
            const updatedData = userUpdater(draft, newData);
            handleLogout(updatedData);
            return updatedData;
          });
        },
        () => dispatch(api.util.invalidateTags([AUTH_QUERY_KEYS.USER]))
      );

      await cacheEntryRemoved;

      if (data?.subId) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subId)).reset();
        instance.unsubscribe(data?.subId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse(response): TUserProfile {
    return userProfileAdapter(response);
  }
});
export default getUser;
