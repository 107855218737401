import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { classNameProp, CustomFC } from '@monorepo/type';

import { UI_SIZES } from '../../../constants';
import Box from '../../Box';
import FlexBox from '../../FlexBox';
import Skeleton from '../../skeletons/Skeleton';

import styles from './index.module.css';

type Props = {
  limit?: number;
} & classNameProp;

const NotificationsSkeleton: CustomFC<Props> = ({ limit = 10 }) => {
  const list = createSkeletonArray(limit);
  return (
    <Box mt={UI_SIZES.xxl} className={styles.wrap}>
      {list.map((notify) => (
        <FlexBox key={notify} className={styles.item}>
          <Skeleton className={styles.image} />
          <Skeleton className={styles.text} />
        </FlexBox>
      ))}
    </Box>
  );
};

export default NotificationsSkeleton;
