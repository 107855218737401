import {
  TCompetitionsListRequest,
  TResultCompetitions,
  TResultCompetitionsListResponse
} from '../../../types';
import resultCompetitionsAdapter from '../adapters/resultCompetitionsAdapter';

const getActiveCompetitions = () => ({
  query: ({ fromDate, toDate, isLive }: TCompetitionsListRequest) => ({
    body: {
      command: 'get_active_competitions',
      params: {
        from_date: fromDate,
        to_date: toDate,
        ...(isLive && { is_live: true })
      }
    }
  }),
  keepUnusedDataFor: 0,
  transformResponse(
    response: TResultCompetitionsListResponse
  ): TResultCompetitions[] {
    return !response ? [] : resultCompetitionsAdapter(response);
  }
});
export default getActiveCompetitions;
