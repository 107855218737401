import { useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import { useAppActions, useSendLog } from '@monorepo/common';
import {
  getBrowserFingerprint,
  googleAnalytics,
  usePrevious
} from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { getSocketSWARM, WS_QUERY_MESSAGE_TYPE } from '@monorepo/websocket';

import { useRequestSessionMutation } from '../../store/session';
import { useRestoreLoginMutation } from '../../store/user';
import { TAuthData } from '../../types';
import useActions from '../useActions';

const useRequestSession = (authData: TAuthData, source?: number) => {
  const prevAuthData = usePrevious(authData);
  const instance = getSocketSWARM();
  const { siteId } = useSiteSettings();
  const { languageSwarmCode } = useI18n();
  const ga = googleAnalytics();
  const [requestSession] = useRequestSessionMutation({
    fixedCacheKey: 'RequestSession'
  });
  const [restoreLogin] = useRestoreLoginMutation();
  const { setIsAuthenticated, setIsCookiesFetched } = useActions();
  const { setSessionSwarmId } = useAppActions();
  const { handleSendLog } = useSendLog();
  const request = useCallback(async () => {
    const { data, error } = await requestSession({
      siteId,
      language: languageSwarmCode,
      source,
      afec: btoa(getBrowserFingerprint().toString())
    });
    if (error) {
      if (error?.message === WS_QUERY_MESSAGE_TYPE.TIMEOUT_ERROR) {
        ga.dispatch({
          event: ga.event.jsSwarmTimeout,
          eventParam: {
            event_category: 'js'
          },
          event_options: {
            url: 'RequestSession'
          }
        });
        instance.reconnect();
      } else {
        // ga  js-error
        ga.dispatch({
          event: ga.event.jsSwarmServerError,
          eventParam: {
            event_category: 'js'
          },
          event_options: {
            message: error?.message,
            data: JSON.stringify(error)
          }
        });
      }
      throw Error(JSON.stringify(error));
    }
    const { isSessionReady } = data || {};
    if (isSessionReady) {
      setSessionSwarmId(data.swarmSessionId);
      if (authData?.authToken && authData?.userId) {
        const result = await restoreLogin(authData);
        const userId = result?.data?.userId;
        if (authData?.isNeedLog) {
          handleSendLog({
            name: `login_${result?.error ? 'error' : 'success'}`,
            userId
          });
        }
      } else {
        setIsAuthenticated({
          isAuthenticated: false,
          isAuthFetched: true
        });
        setIsCookiesFetched({
          isCookiesFetched: false
        });
      }
    }
  }, [authData, languageSwarmCode, source, siteId]);

  useEffect(() => {
    instance.setOnInit = request;
  }, [request]);

  useEffect(() => {
    if (authData && prevAuthData && !isEqual(authData, prevAuthData)) {
      instance.reconnect();
    }
  }, [JSON.stringify(authData)]);
};

export default useRequestSession;
