import React from 'react';

import { FIELD_TONE } from '../../../constants';

import styles from './index.module.css';

const Skeleton = ({ className = '', toneType = FIELD_TONE.dark }) => (
  <div className={`${styles.skeleton} ${className} ${styles[toneType]}`} />
);

export default Skeleton;
