import React from 'react';
import {
  buildCountryData,
  defaultCountries,
  parseCountry
} from 'react-international-phone';
import {
  CountryData,
  ParsedCountry
} from 'react-international-phone/dist/types';

import {
  InputField,
  PhoneField,
  phoneGoogleValidator,
  requiredValidator
} from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { UseCountry } from '@monorepo/type';
import { FIELD_TONE } from '@monorepo/ui-kit';

import { REQUIRED_PAYMENT_DATA } from '../../../constants';

import styles from './index.module.css';

const DepositProfileFields = ({
  profile,
  countriesFromJSON,
  defaultCountry = 'al'
}: {
  profile: any;
  countriesFromJSON: UseCountry[];
  defaultCountry?: string;
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { firstName, lastName, phone, countryCode } = profile;

  const countries = countriesFromJSON?.reduce(
    (acc: CountryData[], { value, label, phoneCode }) => {
      const countryData = defaultCountries.find((item) => {
        const { iso2 } = parseCountry(item);
        return value === iso2;
      });
      if (!countryData) {
        const newCountryData = buildCountryData({
          name: label,
          iso2: value,
          dialCode: phoneCode
        } as ParsedCountry);
        return [...acc, newCountryData];
      }

      return [...acc, countryData];
    },
    []
  );
  const country = countries.reduce((acc, item) => {
    const { iso2 } = parseCountry(item);
    if (iso2 === countryCode) {
      return countryCode;
    }
    return acc;
  }, defaultCountry);

  return !firstName || !lastName || !phone ? (
    <>
      {!firstName && (
        <InputField
          id="firstName"
          name={REQUIRED_PAYMENT_DATA.FIRST_NAME}
          label={`${t('updateprofile.form.firstname.label')}`}
          toneType={FIELD_TONE.dark}
          rules={{
            ...requiredValidator()
          }}
        />
      )}
      {!lastName && (
        <InputField
          id="lastName"
          name={REQUIRED_PAYMENT_DATA.LAST_NAME}
          label={`${t('updateprofile.form.lastname.label')}`}
          toneType={FIELD_TONE.dark}
          rules={{
            ...requiredValidator()
          }}
        />
      )}
      {!phone && (
        <PhoneField
          id="phone"
          name={REQUIRED_PAYMENT_DATA.PHONE}
          className={styles.phone}
          defaultCountry={country}
          countries={countries}
          label={t('updateprofile.form.phonecode.label')}
          rules={{
            ...requiredValidator(),
            ...phoneGoogleValidator()
          }}
          toneType={FIELD_TONE.dark}
        />
      )}
      <InputField
        id="password"
        name={REQUIRED_PAYMENT_DATA.PASSWORD}
        type="password"
        autoComplete="new-password"
        label={t('updateprofile.form.password.label')}
        placeholder={t('updateprofile.form.password.placeholder')}
        toneType={FIELD_TONE.dark}
        rules={{ ...requiredValidator() }}
      />
    </>
  ) : null;
};
export default DepositProfileFields;
