import validateCreator from './validateCreator';

const isPhoneValid = async (phone: string) => {
  const { PhoneNumberUtil } = await import('google-libphonenumber');
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const phoneGoogleValidator = <TFieldValue>(
  message = 'validators.phone.format'
) => {
  const validate = async (fieldValue: TFieldValue) => {
    // @ts-ignore
    const isValid = await isPhoneValid(fieldValue);
    return isValid || message;
  };
  return validateCreator<TFieldValue>(validate);
};

export default phoneGoogleValidator;
