import { requestAffiliationList } from '@monorepo/xmpp';

import {
  MUC_ROOM_AFFILIATIONS_LIST,
  SOCIALS_QUERY_TAG
} from '../../../constants';
import { TPermissionItem, TRoomAffiliationListRequest } from '../../../types';
import {
  parseRoomPermissionList,
  parseStanzaTypeError
} from '../../../utils/stanzaParsers';

const getAffiliationList = () => ({
  query: ({ affiliation, roomName, server }: TRoomAffiliationListRequest) =>
    requestAffiliationList(affiliation, roomName, server),
  transformResponse: parseRoomPermissionList,
  transformErrorResponse: parseStanzaTypeError,
  providesTags: (
    result: TPermissionItem[] | undefined,
    error: unknown,
    { affiliation, roomName }: TRoomAffiliationListRequest
  ) =>
    result
      ? [
          {
            type: SOCIALS_QUERY_TAG.MUC_ROOM_AFFILIATIONS,
            id: `${affiliation}-${roomName}`
          },
          {
            type: SOCIALS_QUERY_TAG.MUC_ROOM_AFFILIATIONS,
            id: `${MUC_ROOM_AFFILIATIONS_LIST}-${roomName}`
          }
        ]
      : [SOCIALS_QUERY_TAG.MUC_ROOM_AFFILIATIONS]
});

export default getAffiliationList;
