import { TLogoutRequest } from '../../../types';

const logout = () => ({
  query: (params: TLogoutRequest) => ({
    body: {
      command: 'logout',
      params: { ...params }
    },
    checkError: (response: any) => response.code !== 0
  })
});

export default logout;
