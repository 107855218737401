import React from 'react';
import { Helmet } from 'react-helmet';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';

type Props = {
  titlePage: string;
};
const MetaTitle: CustomFC<Props> = ({ titlePage }) => {
  const { t } = useI18n(NAMESPACES.PAGES);
  const title = titlePage
    ? `${titlePage} | ${t('title.defaulttitle')}`
    : t('title.defaulttitle');
  return <Helmet title={title} />;
};

export default MetaTitle;
