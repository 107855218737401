import { TRawTransactions, TTransaction } from 'types';

const transactionHistoryAdapter = (
  transactions: TRawTransactions
): TTransaction[] =>
  transactions.history.map(
    ({ amount, balance, transaction_id, date_time, game, operation_name }) => ({
      date: date_time,
      id: transaction_id,
      game,
      operationName: operation_name,
      amount,
      balance
    })
  );

export default transactionHistoryAdapter;
