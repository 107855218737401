import { requestRoomName } from '@monorepo/xmpp';

import {
  parseStanzaTypeError,
  parseUniqueRoomName
} from '../../../utils/stanzaParsers';

const requestName = () => ({
  query: () => requestRoomName(),
  transformResponse: parseUniqueRoomName,
  transformErrorResponse: parseStanzaTypeError
});

export default requestName;
