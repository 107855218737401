import { TGetMaxBetRequest } from '../../../types';

const getMaxBet = () => ({
  query: ({ events, type }: TGetMaxBetRequest) => ({
    body: {
      command: 'get_max_bet',
      params: {
        events,
        type,
        each_way: false
      }
    }
  }),
  transformResponse(rawResponse) {
    return rawResponse.details;
  }
});
export default getMaxBet;
