import isObject from 'lodash/isObject';

import { TOptionItem } from '@monorepo/type';

export const toSelectOptions = (
  data: any[] | undefined,
  keyParam: string,
  valueParam: string
): Array<TOptionItem> =>
  data?.map((item) => {
    if (isObject(item)) {
      return {
        label: item[valueParam],
        value: item[keyParam].toString(),
        key: item.key || undefined
      };
    }
    return {
      label: item,
      value: item,
      key: undefined
    };
  }) || [];

export const enumToSelectOptions = (
  data: object,
  reverse?: boolean,
  formatValue?: (data: string) => string
) =>
  Object.entries(data).map(([key, item]) =>
    reverse
      ? {
          value: item.toString(),
          label: formatValue ? formatValue(key) : key
        }
      : {
          label: formatValue ? formatValue(item) : item,
          value: key.toString()
        }
  );

export const getEnumKey = (data: object, val: any) =>
  Object.entries(data).reduce((acc, [key, value]) => {
    if (val === value) {
      return key;
    }
    return acc;
  }, '');

export const createArrayOfNumbers = (
  length: number,
  offset: number = 0
): number[] => new Array(length).fill(1).map((e, i) => i + offset);

export const createSkeletonArray = (length: number): number[] =>
  createArrayOfNumbers(length);
