import entries from 'lodash/entries';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';

import { useGetUser } from '@monorepo/auth';
import { TQueryResult } from '@monorepo/type';

import { SERVICE_TYPES } from '../../constants';
import { TPaymentServices, TPaymentSystem } from '../../types';
import useGetPaymentServices from '../useGetPaymentServices';

const getPaymentService = (
  data: TPaymentServices | undefined,
  serviceType: SERVICE_TYPES,
  id: string,
  currencyName: string
): TPaymentSystem | null => {
  const services = data?.[serviceType];
  if (services) {
    const system = find(services, (service) => service.PaymentSystem_id === id);
    if (system) {
      const currency = get(get(system, 'info_notes'), currencyName);
      const fields = map(
        entries(get(get(system, 'method'), 'fields')),
        ([fieldKey, value]) => ({ ...value, fieldKey })
      );
      return {
        ...system,
        currency,
        fields
      };
    }
  }
  return null;
};

const usePaymentService = (serviceType: SERVICE_TYPES, id: string) => {
  const { data } = useGetUser();
  const { currencyName } = data || {};

  // @ts-ignore
  const { isFetching, system } = useGetPaymentServices({
    skip: !data,
    selectFromResult: (result: TQueryResult<TPaymentServices>) => ({
      isFetching: result?.isFetching,
      system: getPaymentService(result?.data, serviceType, id, currencyName)
    })
  });

  return { isFetching, system } as {
    isFetching: boolean;
    system: TPaymentSystem | null;
  };
};

export default usePaymentService;
