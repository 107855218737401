import { createArrayOfNumbers } from '@monorepo/helpers';

const createPagesArray = (currentPage = 1, totalPages = 1) => {
  if (totalPages <= 5) {
    return createArrayOfNumbers(totalPages, 1);
  }
  if (currentPage < 3) {
    return createArrayOfNumbers(4, 1);
  }
  if (currentPage > totalPages - 3) {
    return createArrayOfNumbers(4, totalPages - 3);
  }
  return createArrayOfNumbers(3, currentPage - 1);
};

export default createPagesArray;
