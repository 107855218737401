import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TUploadTypeRequest } from '../../types';
import uploadFile from '../api/uploadFile';

export const mediaStorageApi = createApi({
  reducerPath: 'mediaStorageApi',
  baseQuery: fetchBaseQuery({ baseUrl: `` }),
  tagTypes: [],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, TUploadTypeRequest>(uploadFile())
  })
});

export const { useUploadFileMutation } = mediaStorageApi;
