import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useIsAuthenticated } from '@monorepo/auth';
import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import { usePageContext } from '@monorepo/page/src';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  Button,
  BUTTON_INTENT,
  FLEX_ALIGN,
  FlexBox,
  Logo
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type THeaderAuthConfig = {
  wrapClassName?: string;
  children?: React.ReactNode;
};
const HeaderAuth: CustomFC<THeaderAuthConfig> = ({
  wrapClassName = '',
  children
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get(SEARCH_STRING_PARAMS.redirectUrl);
  const { siteLogo } = useSiteSettings();
  const navigate = useNavigate();
  const { page } = usePageContext();
  const { pageConfig } = page;
  const { backLink } = pageConfig;
  const handleClick = () => {
    if (redirectUrl && isAuthenticated) {
      return navigate(redirectUrl);
    }
    if (backLink) {
      return navigate(backLink);
    }
    return navigate(-1);
  };

  return (
    <Box className={styles.header}>
      <FlexBox
        className={`${styles.headerWrap} ${wrapClassName}`}
        align={FLEX_ALIGN.center}
      >
        {children ||
          (Boolean(siteLogo) && (
            <Logo src={siteLogo} className={`${styles.logo}`} />
          ))}
        <Button
          isOnlyIcon
          intent={BUTTON_INTENT.tertiary}
          onClick={handleClick}
          className={styles.button}
        >
          <Icon name="close_0" className={styles.icon} />
        </Button>
      </FlexBox>
    </Box>
  );
};
export default HeaderAuth;
