import React from 'react';

import { useGetUser } from '@monorepo/auth';
import { useConvertAmount } from '@monorepo/common';
import { NAMESPACES, useCurrencyTransform, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { Box, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentService from '../../../hooks/usePaymentService';

import styles from './index.module.css';

type Props = {
  serviceId: string;
  serviceType: SERVICE_TYPES;
};

const PaymentInfo: Component<Props> = ({
  serviceId,
  serviceType,
  className
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { data } = useGetUser();
  const currencyTransformed = useCurrencyTransform(data?.currencyName);
  const { system } = usePaymentService(serviceType, serviceId);
  const { currency } = system;
  const { min, max } = currency || {};
  const minAmount = useConvertAmount(min);
  const maxAmount = useConvertAmount(max);

  return (
    <Box className={className}>
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.start}
        className={styles.instant}
      >
        <Box className={styles.limitsWrapperText}>
          {t('wallet.deposit_fee')}
        </Box>
        <Box className={styles.wrapperLimits}>{t('wallet.deposit_free')}</Box>
      </FlexBox>
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.start}
        className={styles.instant}
      >
        <Box className={styles.limitsWrapperText}>
          {t('wallet.process_time')}
        </Box>
        <Box className={styles.wrapperLimits}>
          {t('wallet.deposit_instant')}
        </Box>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.start}
        align={FLEX_ALIGN.center}
        className={styles.limitDescription}
      >
        <Box className={styles.limitsWrapperText}>
          {t(`wallet.minimum_${serviceType}`)}
        </Box>
        <Box className={styles.wrapperLimits}>
          {minAmount} &nbsp;
          {currencyTransformed}
        </Box>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.start}
        align={FLEX_ALIGN.center}
        className={styles.limitDescription}
      >
        <Box className={styles.limitsWrapperText}>
          {t(`wallet.maximum_${serviceType}`)}
        </Box>
        <Box className={styles.wrapperLimits}>
          {maxAmount} &nbsp;
          {currencyTransformed}
        </Box>
      </FlexBox>
    </Box>
  );
};

export default PaymentInfo;
