import { useTypedSelector } from '@monorepo/redux';

const getIsAuthFetched = (state) => state.user.isAuthFetched;
const getIsAuthenticated = (state) => state.user.isAuthenticated;

const useIsAuthenticated = () => {
  const isAuthFetched = useTypedSelector(getIsAuthFetched);
  const isAuthenticated = useTypedSelector(getIsAuthenticated);
  return {
    isAuthFetched,
    isAuthenticated
  };
};
export default useIsAuthenticated;
