const isValidJSONString = (str: string | null) => {
  try {
    JSON.parse(str as string);
  } catch {
    return false;
  }
  return true;
};

export const getStorageValue = (
  storage: 'localStorage' | 'sessionStorage',
  key: string,
  defaultValue?: any
) => {
  try {
    const json = window[storage].getItem(key);
    let data = json;

    if (isValidJSONString(json)) {
      data = JSON.parse(json as string);
    }

    return data || Number.isInteger(data) ? data : defaultValue;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e, `${storage} error`);
    return null;
  }
};

export const setStorageValue = (
  storage: 'localStorage' | 'sessionStorage',
  key: string,
  newValue: string | any
) => {
  if (key) {
    try {
      window[storage].setItem(
        key,
        typeof newValue === 'string' ? newValue : JSON.stringify(newValue)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e, `${storage} error`);
    }
  }
};

export const removeStorageValue = (
  storage: 'localStorage' | 'sessionStorage',
  key: string
) => {
  try {
    window[storage].removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e, `${storage} error`);
  }
};

export const getLocalStorageValue = (key: string, defaultValue?: any) =>
  getStorageValue('localStorage', key, defaultValue);
export const setLocalStorageValue = (key: string, newValue: string | any) =>
  setStorageValue('localStorage', key, newValue);
export const removeLocalStorageItem = (key: string) =>
  removeStorageValue('localStorage', key);
export const getSessionStorageValue = (key: string, defaultValue?: any) =>
  getStorageValue('sessionStorage', key, defaultValue);
export const setSessionStorageValue = (key: string, newValue: string | any) =>
  setStorageValue('sessionStorage', key, newValue);
export const removeSessionStorageItem = (key: string) =>
  removeStorageValue('sessionStorage', key);
