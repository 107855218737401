import { camelizeKeys } from 'humps';

import normalizeGame from '../../../../utils/normalizeGame';

const gamesAdapter = (initialResponse: any) => ({
  games: camelizeKeys(initialResponse?.games?.map(normalizeGame)) || [],
  totalCount: initialResponse?.total_count || 0
});

export default gamesAdapter;
