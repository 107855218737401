import React from 'react';
import { NavLink } from 'react-router-dom';

import { AVATAR_SIZE } from '@monorepo/files';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { useChatUser } from '@monorepo/socials';
import { Component } from '@monorepo/type';
import { Avatar } from '@monorepo/ui-kit';

import styles from './index.module.css';

const EditorAvatar: Component = ({ className = '' }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes, editorId } = useSiteSettings();
  const { profile } = generalRoutes;
  const editorLink = `${profile}/${editorId}`;
  const { avatar = '' } = useChatUser(`${editorId}`);
  const avatarSrc = `${avatar}/${AVATAR_SIZE.L}`;

  return (
    <NavLink to={editorLink} className={styles.navWrapper}>
      <Avatar
        className={`${styles.wrap} ${className}`}
        imgSrc={avatarSrc}
        alt={t('editor')}
        nickname="All Inn editor"
      />
    </NavLink>
  );
};

export default EditorAvatar;
