import React from 'react';
import { useWatch } from 'react-hook-form';

import { SmsVerificationModal } from '@monorepo/common';

import { formatPhone } from '../../../../utils';

const VerificationModal = ({ onSubmit, modalRef }) => {
  const watchData = useWatch({ name: ['phoneCode', 'phone'] });
  return (
    <SmsVerificationModal
      phoneNumber={formatPhone(...watchData)}
      actionType={1}
      onSubmit={onSubmit}
      modalRef={modalRef}
    />
  );
};

export default VerificationModal;
