import React, { memo } from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  EllipsisText
} from '@monorepo/ui-kit';

import SportColor from '../../../SportColor';
import SportIcon from '../../../SportIcon';

import styles from './index.module.css';

type Props = {
  isActive?: boolean;
  onClick?: () => void;
  iconName: string;
  name: string;
  count?: string | number;
  alias: string;
};

const SportMenuItem: Component<Props> = ({
  isActive = false,
  onClick,
  iconName,
  name,
  count,
  className = '',
  alias
}) => {
  const classes = `${styles.root}
    ${isActive ? styles.active : ''}
    ${className}`;

  return (
    <Button
      size={BUTTON_SIZE.h42}
      intent={BUTTON_INTENT.tertiary}
      className={classes}
      onClick={onClick}
    >
      <SportColor alias={alias}>
        <Box className={styles.iconWrap}>
          <SportIcon name={iconName} className={styles.icon} />
        </Box>
      </SportColor>
      <EllipsisText className={styles.name}>{name}</EllipsisText>
      {Boolean(count) && <span className={styles.count}>{count}</span>}
      <Icon name="arrow_2" className={styles.arrow} />
    </Button>
  );
};

export default memo(SportMenuItem);
