import { useContext } from 'react';

import ComponentsConfigContext, {
  TComponentsConfigValues
} from '../../store/context/ComponentsConfigContext';

const useComponentsConfig = <T extends TComponentsConfigValues>(): T =>
  useContext(ComponentsConfigContext) as T;

export default useComponentsConfig;
