import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';

import MenuItemSkeleton from './components/MenuItemSkeleton';

type Props = {
  length: number;
};

const MenuSkeleton: Component<Props> = ({ length, className = '' }) => (
  <>
    {createSkeletonArray(length).map((i) => (
      <MenuItemSkeleton key={i} className={className} />
    ))}
  </>
);

export default MenuSkeleton;
