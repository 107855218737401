import { Api } from '@reduxjs/toolkit/query';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetEventMarketsRequest } from '../../../types';
import { createIdsQuery } from '../../utils';
import { normalizeMarketsResponse } from '../adapters/gameMarketsAdapter';
import marketUpdater from '../updaters/marketUpdater';

const getGameMarkets = (api: Api<any, any, any, any>) => ({
  query: ({
    gameId,
    competitionId,
    sportAlias,
    regionAlias,
    marketGroupId,
    marketIds
  }: TGetEventMarketsRequest) => {
    const marketParams =
      marketGroupId !== null ? { group_id: marketGroupId } : {};

    return {
      body: {
        command: 'get',
        params: {
          source: 'betting',
          what: {
            game: [
              'team1_name',
              'team2_name',
              'is_blocked',
              'video_id',
              'start_ts'
            ],
            market: [
              'id',
              'group_id',
              'name',
              'group_name',
              'cashout',
              'col_count',
              'type',
              'order',
              'group_order',
              'name_template',
              'sequence',
              'point_sequence'
            ],
            event: []
          },
          where: {
            game: {
              id: gameId
            },
            competition: {
              id: competitionId
            },
            ...(sportAlias && {
              competition: {
                alias: sportAlias
              }
            }),
            ...(regionAlias && {
              region: {
                alias: regionAlias
              }
            }),
            market: {
              ...marketParams,
              ...(marketIds?.length && { id: createIdsQuery(marketIds) })
            }
          },
          subscribe: true
        }
      }
    };
  },
  providesTags: [SPORTS_QUERY_KEYS.EVENT_MARKETS],
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => marketUpdater(draft, newData));
        },
        () =>
          dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.EVENT_MARKETS]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse: normalizeMarketsResponse
});

export default getGameMarkets;
