import { useCallback } from 'react';

import { useAddChatInfoMutation } from '../../store/userGeneratedContentApi';
import { TAddChatInfoRequest } from '../../types';

type TUseAddChatInfo = {
  onSuccess?: () => void;
  onError?: (e: any) => void;
};

const useAddChatInfo = (options: TUseAddChatInfo = {}) => {
  const { onSuccess, onError } = options;
  const [add, { isLoading }] = useAddChatInfoMutation();

  const addChatInfo = useCallback(
    async (params: TAddChatInfoRequest) => {
      try {
        await add({ ...params }).unwrap();
        onSuccess?.();
      } catch (e) {
        onError?.(e);
      }
    },
    [add]
  );

  return { addChatInfo, isLoading };
};

export default useAddChatInfo;
