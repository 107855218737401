import validateCreator from './validateCreator';

const dataBoxValidator = <TFieldValue>(message = 'validators.code.wrong') => {
  const dataBoxIdValidator = (value: any) => {
    let summSymbols = 0;
    const controlValue = value.slice(0, 6);
    const alphaBet = 'abcdefghijkmnpqrstuvwxyz23456789';
    for (let n = controlValue.length - 1; n >= 0; n -= 1) {
      const stringChar = controlValue.charAt(n);
      let alphaBetDigit = Number(alphaBet.indexOf(stringChar));

      if (n % 2 !== 0) {
        alphaBetDigit *= 2;
      }

      summSymbols += Math.floor(alphaBetDigit / 32) + (alphaBetDigit % 32);
    }
    const checkLetter = summSymbols % 32;

    if (checkLetter > 0) {
      summSymbols = 32 - checkLetter;
    }

    const calculatedValue = controlValue + alphaBet[summSymbols];

    if (value === calculatedValue || !value) {
      return true;
    }

    return message;
  };

  return validateCreator<TFieldValue>(dataBoxIdValidator);
};

export default dataBoxValidator;
