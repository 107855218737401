import {
  getLocalStorageValue,
  setLocalStorageValue
} from '@monorepo/helpers/src';

import { FRIENDS_MODAL_STORAGE_KEY } from '../../constants';

const formatTo = (list: string[]) => list.join('|');
const formatFrom = (list?: string) => list?.toString().split('|');

const getRequestsListFromStorage = (): string[] | undefined =>
  formatFrom(getLocalStorageValue(FRIENDS_MODAL_STORAGE_KEY));
// todo refactor to persistent storage
const setRequestsListInStorage = (list: string[]) => {
  setLocalStorageValue(FRIENDS_MODAL_STORAGE_KEY, formatTo(list));
};

const addRequestToStorage = (jid: string) => {
  const currentList = getRequestsListFromStorage() || [];
  if (!currentList.includes(jid)) {
    setRequestsListInStorage([...currentList, jid]);
  }
};

const filterLocalStorageRequestsList = (jid: string) => {
  const StorageRequestsList = getRequestsListFromStorage();

  if (StorageRequestsList) {
    const filteredList = StorageRequestsList.filter(
      (item: string) => item !== jid
    );
    setRequestsListInStorage(filteredList);
  }
};

export {
  addRequestToStorage,
  setRequestsListInStorage,
  filterLocalStorageRequestsList,
  getRequestsListFromStorage
};
