import React from 'react';

import { Icon } from '@monorepo/icons';

import { TMenuItem } from '../../../types';
import EllipsisText from '../../EllipsisText';
import Menu from '../HeaderNavMenu/components/Menu';

import Item from './components/Item';

import styles from './index.module.css';

type TNavItem = {
  title: string;
  link: string;
  subItems?: TMenuItem[];
  iconName?: string;
  disabled?: boolean;
  isSubItem?: boolean;
  target?: string;
  className?: string;
  linkClassName?: string;
  children?: React.ReactNode;
};

const NavItem = ({
  title,
  link,
  subItems = [],
  iconName = '',
  isSubItem = false,
  target = '',
  disabled = false,
  className = '',
  linkClassName = '',
  children,
  ...props
}: TNavItem) => {
  const classes = `${styles.item}
    ${className}
    ${subItems.length > 0 ? styles.withSubmenu : ''}
    ${disabled ? styles.disabled : ''}
    ${isSubItem ? styles.subItem : ''}`;

  return (
    <li key={title} className={classes}>
      <Item
        link={link}
        target={target}
        disabled={disabled}
        className={`${linkClassName} ${styles.link} ${
          disabled ? styles.disabled : ''
        }`}
        activeClassName={styles.active}
        {...props}
      >
        {Boolean(iconName) && (
          <Icon name={iconName} className={styles.icon} title="" />
        )}
        <EllipsisText>{title}</EllipsisText>
      </Item>
      {children}
      {subItems.length > 0 && (
        <Menu items={subItems} className={styles.secondLevel} isSubmenu />
      )}
    </li>
  );
};
export default NavItem;
