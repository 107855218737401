import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetBannersQuery } from '../../store';

const useGetBanners = (slug: string) => {
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();
  return useGetBannersQuery({
    lang: languageLongCode,
    partner: siteId,
    slug
  });
};

export default useGetBanners;
