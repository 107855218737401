import React from 'react';

import { SvgIcon } from '@monorepo/icons';
import { classNameProp, Component } from '@monorepo/type';

import styles from './index.module.css';

type TNameProps = classNameProp & {
  name?: string;
};

const SvgSpinner: Component<TNameProps> = ({
  className = '',
  name = 'spinner'
}) => <SvgIcon name={name} className={`${styles.spinner} ${className}`} />;

export default SvgSpinner;
