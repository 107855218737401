import SocketClient from '../BaseSocketClient';
import { openSocketJackpot } from '../casinoJackpotInstance';
import openMessengerSocket from '../openMessengerSocket';
import { openSocketSWARM } from '../websocketInstance';

enum SOCKET_TYPES {
  SWARM = 'SWARM',
  JACKPOT = 'JACKPOT',
  MESSENGER = 'MESSENGER'
}

const OPEN_SOCKET_MAP = {
  [SOCKET_TYPES.SWARM]: openSocketSWARM,
  [SOCKET_TYPES.JACKPOT]: openSocketJackpot,
  [SOCKET_TYPES.MESSENGER]: openMessengerSocket
};

const sockets: Map<SOCKET_TYPES, SocketClient> = new Map();

const createSocket = (type: SOCKET_TYPES): SocketClient => {
  if (!sockets.has(type)) {
    const socket = OPEN_SOCKET_MAP[type]();
    sockets.set(type, socket);
    return socket;
  }
  return sockets.get(type) as SocketClient;
};

export const getSocketJackpot = () => createSocket(SOCKET_TYPES.JACKPOT);

export const getSocketSWARM = () => createSocket(SOCKET_TYPES.SWARM);

export const getSocketMessenger = () => createSocket(SOCKET_TYPES.MESSENGER);
