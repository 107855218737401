import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useSetTimeOutLimitsMutation } from '../../store/user';
import { TPauseLimitsRequest } from '../../types';

type TOptions = {
  onSuccess?: (data) => void;
  onError?: (error: boolean) => void;
  redirectTo?: string;
};

const useSetTimeOutLimits = (options: TOptions = {}) => {
  const { onSuccess, onError } = options;
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const [userTimeOutLimitsMutation, { isLoading, isError }] =
    useSetTimeOutLimitsMutation();
  const { errorNotification, successNotification } = useNotifications();
  const onSetTimeOutLimits = async ({
    pauseLimitRadio,
    pauseRadio
  }: TPauseLimitsRequest) => {
    const { data } = await userTimeOutLimitsMutation({
      pause: Number(pauseLimitRadio),
      pauseRadio
    });
    if (data?.result !== 0) {
      onError?.(data?.result);
      errorNotification(t(`profile.loss.save.error.${data.result}`));
    } else {
      onSuccess?.(data);
      successNotification(t(`profile.limits.save.success`));
    }
  };

  return { onSetTimeOutLimits, isLoading, isError };
};

export default useSetTimeOutLimits;
