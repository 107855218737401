import React, {
  Dispatch,
  forwardRef,
  ForwardRefRenderFunction,
  SetStateAction,
  useEffect,
  useState
} from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { COOKIE_KEYS, getISOStringDate, setCookie } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_DIRECTION,
  FlexBox,
  Modal,
  Portal,
  TShutterHandler
} from '@monorepo/ui-kit';

import { COOKIES_SETTINGS, DEFAULT_COOKIES_SETTINGS } from '../../constants';
import useSetCookiesNotification from '../../hooks/useCookiesNotification';
import { useSelectCookiesSettings } from '../../store/selectors';
import { TCookiesSettings } from '../../types';

import DetailsAccordion from './components/DetailsAccordion';

import styles from './index.module.css';

type TCookieDetails = {
  handleClose: () => void;
  setCookieAgreeUnregistered?: Dispatch<SetStateAction<any>>;
  classOverlayModal?: string;
  classContentModal?: string;
};

const CookieDetailsNotification: ForwardRefRenderFunction<
  TShutterHandler,
  TCookieDetails
> = (
  {
    setCookieAgreeUnregistered = () => {},
    handleClose,
    classOverlayModal = '',
    classContentModal = ''
  },
  ref
) => {
  const defaultCookiesSettings = DEFAULT_COOKIES_SETTINGS;
  const defaultSettings = useSelectCookiesSettings() || {};
  const { isAuthenticated } = useIsAuthenticated();

  const [cookiesSettings, setCookiesSettings] =
    useState<TCookiesSettings>(defaultSettings);
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes } = useSiteSettings();
  const { cookieAgreeHere } = generalRoutes || {};
  const { onSaveCookies } = useSetCookiesNotification();
  const cookiesValue = getISOStringDate();

  const handleReject = () => {
    const cookies = { ...defaultCookiesSettings, value: cookiesValue };
    if (!isAuthenticated) {
      setCookieAgreeUnregistered(cookies);
      setCookie(COOKIE_KEYS.COOKIE_AGREE, JSON.stringify(cookies), {
        expires: 365
      });
      handleClose();
      return;
    }
    onSaveCookies(cookies);
    handleClose();
  };

  const handleApprove = () => {
    const cookies = {
      alwaysActive: true,
      perfomanceData: true,
      targetingData: true,
      value: cookiesValue
    };
    if (!isAuthenticated) {
      setCookieAgreeUnregistered(cookies);
      setCookie(COOKIE_KEYS.COOKIE_AGREE, JSON.stringify(cookies), {
        expires: 365
      });
      handleClose();
      return;
    }
    onSaveCookies(cookies);
    handleClose();
  };

  const submitSettings = () => {
    const cookies = {
      ...cookiesSettings,
      value: cookiesValue
    };
    if (!isAuthenticated) {
      setCookieAgreeUnregistered(cookies);
      setCookie(COOKIE_KEYS.COOKIE_AGREE, JSON.stringify(cookies), {
        expires: 365
      });
      handleClose();
      return;
    }
    onSaveCookies(cookies);
    handleClose();
  };

  useEffect(() => {
    setCookiesSettings(defaultSettings);
  }, [defaultSettings]);

  return (
    <Portal>
      {/* @ts-ignore */}
      <Modal
        closeOutside={handleClose}
        classOverlay={classOverlayModal}
        classContent={classContentModal}
        ref={ref}
      >
        <FlexBox className={styles.wrapper} direction={FLEX_DIRECTION.column}>
          <Icon
            name="close_0"
            className={styles.iconClose}
            title=""
            onClick={handleClose}
          />
          <FlexBox className={styles.header}>
            {t('cookie.details.header')}
          </FlexBox>
          <FlexBox className={styles.main} direction={FLEX_DIRECTION.column}>
            <span className={styles.description}>
              {t('cookie.details.data')}
            </span>
            <span className={styles.description}>
              {t('cookie.details.categories')}
            </span>
            <FlexBox
              className={styles.description}
              direction={FLEX_DIRECTION.column}
            >
              <span>{t('cookie.details.privacy')}</span>
              <a href={cookieAgreeHere} className={styles.moreInfo}>
                {t('cookie.details.more_information')}
              </a>
            </FlexBox>
            <FlexBox className={styles.buttonsWrap}>
              <Button
                size={BUTTON_SIZE.h40}
                intent={BUTTON_INTENT.secondary}
                onClick={handleReject}
                className={styles.button}
              >
                {t('cookie.details.reject')}
              </Button>
              <Button
                size={BUTTON_SIZE.h40}
                intent={BUTTON_INTENT.secondary}
                onClick={handleApprove}
                className={styles.button}
              >
                {t('cookie.details.approve')}
              </Button>
            </FlexBox>
            <DetailsAccordion
              setCookiesSettings={setCookiesSettings}
              cookiesSettings={cookiesSettings}
              name={COOKIES_SETTINGS.ALWAYSACTIVE}
              title={t('cookie.details.necessary_data')}
              description={t('cookie.details.necessary_description')}
            />
            <DetailsAccordion
              setCookiesSettings={setCookiesSettings}
              cookiesSettings={cookiesSettings}
              name={COOKIES_SETTINGS.PERFOMANCEDATA}
              isWithSwitcher
              title={t('cookie.details.perfomance_data')}
              description={t('cookie.details.perfomance_description')}
            />
            <DetailsAccordion
              setCookiesSettings={setCookiesSettings}
              cookiesSettings={cookiesSettings}
              name={COOKIES_SETTINGS.TARGETINGDATA}
              isWithSwitcher
              title={t('cookie.details.targeting_data')}
              description={t('cookie.details.targeting_description')}
            />
            <DetailsAccordion
              setCookiesSettings={setCookiesSettings}
              cookiesSettings={cookiesSettings}
              name={COOKIES_SETTINGS.ALWAYSACTIVE} // @TODO: change to third_party_content
              isWithSwitcher
              title={t('cookie.details.third_party_content_data')}
              description={t('cookie.details.third_party_content_description')}
            />
            <Button
              size={BUTTON_SIZE.h40}
              onClick={submitSettings}
              className={styles.buttonSubmit}
            >
              {t('cookie.details.save_settings')}
            </Button>
          </FlexBox>
        </FlexBox>
      </Modal>
    </Portal>
  );
};
export default forwardRef(CookieDetailsNotification);
