import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import {
  Component,
  TActionsConfig,
  TEntry,
  TQueryResult
} from '@monorepo/type';
import { Accordion, Box, BUTTON_SIZE } from '@monorepo/ui-kit';

import useGetLineGames from '../../hooks/useGetLineGames';
import { selectSportCompetitionOrderedGamesGroupedByDate } from '../../store/api/selectors';
import { TNormalizedSports } from '../../types';
import LineGameCard from '../LineGameCard';
import LineMarketsList from '../LineMarketsList';

import styles from './index.module.css';

type Props = {
  sportId: number;
  regionId: number;
  competitionId: number;
} & TActionsConfig;

const LineGamesListGroupedByDate: Component<Props> = ({
  sportId,
  regionId,
  competitionId,
  className = ''
}) => {
  const { groups } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      groups: selectSportCompetitionOrderedGamesGroupedByDate(
        result,
        sportId,
        regionId,
        competitionId
      )
    }))
  });

  if (isEmpty(groups)) {
    return null;
  }

  return (
    <Box className={className}>
      {groups.map(([date, games]: TEntry<Record<string, Array<number>>>) => (
        <Accordion
          key={date}
          label={date}
          isDefaultOpen
          size={BUTTON_SIZE.h30}
          className={styles.accordion}
          headerClassName={styles.accordionHeader}
          bodyClassName={styles.accordionBody}
        >
          {games.map((gameId: number) => (
            <LineGameCard
              key={gameId}
              gameId={gameId}
              sportId={sportId}
              regionId={regionId}
              competitionId={competitionId}
              className={styles.card}
            >
              <LineMarketsList
                competitionId={competitionId}
                sportId={sportId}
                regionId={regionId}
                gameId={gameId}
              />
            </LineGameCard>
          ))}
        </Accordion>
      ))}
    </Box>
  );
};

export default LineGamesListGroupedByDate;
