import get from 'lodash/get';

import { TMarketGroupsResponse, TNormalizedMarketGroups } from '../../../types';

export const normalizeMarketGroupsResponse = (
  rawResponse: TMarketGroupsResponse
): TNormalizedMarketGroups => {
  const market = get(rawResponse, 'data.market', null);
  const subid = get(rawResponse, 'subid', '');

  return {
    market,
    subid
  };
};
