import React, { useState } from 'react';

import { Component } from '@monorepo/type';

import ThemeContext from '../../store/context';

type Props = {
  defaultTheme?: string;
};

const ThemeProvider: Component<Props> = ({ children, defaultTheme = '' }) => {
  const [theme, setTheme] = useState<string>(defaultTheme);

  return (
    <ThemeContext.Provider value={[theme, setTheme]}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
