export const DESKTOP_SIZE = 1440 - 1;

export enum FAVORITE_TYPE {
  GAME = 'game',
  CASINO = 'casino',
  COMPETITION = 'competition',
  MARKET = 'market',
  WALL = 'wall',
  VIDEO = 'video'
}

export enum FAVORITE_LINK_TYPES {
  CASINO = 'casino',
  SPORT = 'sport',
  WALL = 'wall'
}
export const TABS_NAVIGATION_TITLES = {
  FAVORITE: 'sportsbook.favorite',
  TOP: 'sportsbook.type.menu.top',
  PREMATCH: 'sportsbook.type.menu.prematch',
  LIVE: 'sportsbook.type.menu.live'
};

export const MAX_AVAILABLE_DAYS_DATA = 30;

export const RANGE_BY_DEFAULT = 7;

export const RECAPTCHA_ACTIONS = {
  LOGIN: 'login',
  REGISTRATION: 'register',
  APPLY_PROMO_CODES: 'apply_promo_codes',
  DO_BET: 'do_bet',
  GET_MAX_BET: 'get_max_bet',
  APPLY_SCRATCH_CARD: 'apply_scratch_card',
  RESET_PASSWORD: 'reset_password',
  WITHDRAW: 'withdraw',
  WITHDRAW_V2: 'withdraw_v2'
} as const;

export enum VIDEO_PROVIDERS {
  PROVIDER_25 = 25,
  PROVIDER_21 = 21
}

export const VIDEO_TYPE_HLS = 'hls';

export const TYPE_BY_PROVIDER = {
  [VIDEO_PROVIDERS.PROVIDER_25]: VIDEO_TYPE_HLS
};

export const LIKE_BODY_OBJECT = {
  LIST_OBJECT: 'likes',
  TOGGLE_OBJECT: 'like'
};

export const LIKE_ACTIONS_TYPE = {
  TOGGLE: 'toggle',
  LIST: 'list'
};

export enum LIKE_TYPE {
  WALL = 'wall',
  VIDEO = 'video',
  SPORTSBOOK = 'sportsbook',
  CASINO = 'casino',
  POST = 'post'
}

export const CHAT_OBJECT = {
  CHAT_INFO: 'chat_info'
};

export const CHAT_TYPE = {
  UPDATE: 'update',
  ADD: 'add',
  GET: 'get'
};

export const UGC_QUERY_TAG = {
  CHAT_INFO: 'CHAT_INFO',
  BLOG_INFO: 'BLOG_INFO'
};

export enum PROFILE_NICKNAME {
  username = 'username',
  fullname = 'fullname'
}

export const CMS_HREF_TYPE = {
  LINK: 'link',
  GAME: 'game',
  DEFAULT: 'default'
};

export const AUTOMATIC_SEARCH_INTERVAL = 1000;

export const DATE_FORMAT = {
  SHORT: 'dd-MM-yyyy',
  LONG: 'dd.MM.yyyy HH:mm'
};

export const EXCHANGE_QUERY_TAG = {
  EXCHANGE_INFO: 'EXCHANGE_INFO'
};
