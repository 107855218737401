import React, { ReactNode } from 'react';
import {
  useFormContext,
  UseFormReturn,
  useFormState,
  UseFormStateReturn
} from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { FIELD_TONE, FLEX_DIRECTION, FlexBox, Label } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import FormErrorMessage from '../FormErrorMessage';
import Hint from '../Hint';

import styles from './index.module.css';

type ControlProps<TFormValues> = {
  labelClassName?: string;
  children: ReactNode;
  requiredLabel?: boolean;
} & Pick<
  TFormField<TFormValues>,
  | 'className'
  | 'id'
  | 'name'
  | 'label'
  | 'hint'
  | 'toneType'
  | 'errorClassName'
  | 'errorTranslationNS'
>;

const Control = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  children,
  className,
  errorClassName = '',
  id,
  name,
  label,
  hint,
  toneType = FIELD_TONE.dark,
  labelClassName = '',
  errorTranslationNS,
  requiredLabel = false
}: ControlProps<TFormValues>) => {
  const { control }: UseFormReturn<TFormValues> = useFormContext<TFormValues>();
  const { errors }: UseFormStateReturn<TFormValues> = useFormState<TFormValues>(
    { control }
  );
  // @ts-ignore
  const fieldError = errors[name];

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.wrap} ${className}`}
    >
      {Boolean(label) && (
        <Label
          className={`${styles.label} ${labelClassName}`}
          htmlFor={id}
          toneType={toneType}
        >
          {`${label} ${requiredLabel ? '*' : ''}`}
        </Label>
      )}
      {children}
      <ErrorMessage
        name={name as any}
        errors={errors}
        render={({ message }) => (
          <FormErrorMessage
            message={message}
            errorTranslationNS={errorTranslationNS}
            errorClassName={errorClassName}
          />
        )}
      />
      {Boolean(hint) && !fieldError && (
        <Hint className={styles.hint} toneType={toneType}>
          {hint}
        </Hint>
      )}
    </FlexBox>
  );
};

export default Control;
