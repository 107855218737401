import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TAuthData } from '../../../types';

const getSignData = (state: any) => state[userLocalSlice.name].signData;

const useSelectSignData = (): TAuthData | null => useTypedSelector(getSignData);

export default useSelectSignData;
