import { useNavigate } from 'react-router-dom';

import useSearchString from '../useSearchString';

const useSearchParamsWithParsing = () => {
  const navigate = useNavigate();

  const { setParamByName, getAsObject, getAsString } = useSearchString();

  const setParam = (key: string, value: string | string[] | null | boolean) =>
    setParamByName(key, value);

  const setSearchParam = (
    key: string,
    value: string | string[] | null,
    replacePath?: boolean
  ) => {
    setParam(key, value);
    navigate(`?${getAsString()}`, {
      replace: replacePath
    });
  };

  const setSearchParamsObject = (
    data: Record<string, string | string[] | null | boolean>,
    replacePath?: boolean
  ) => {
    Object.entries(data).forEach(([key, value]) => setParam(key, value));
    navigate(`?${getAsString()}`, {
      replace: replacePath
    });
  };

  const clearSearchParams = (exclude: string[] = []) => {
    const searchParamsObject = getAsObject();
    Object.keys(searchParamsObject).forEach((item) => {
      if (!exclude.includes(item)) {
        setParamByName(item, null);
      }
    });
    navigate(`?${getAsString()}`);
  };

  return {
    searchParams: getAsObject(),
    setSearchParam,
    setSearchParamsObject,
    clearSearchParams
  };
};

export default useSearchParamsWithParsing;
