import React from 'react';

import { HTMLSnippetPage } from '@monorepo/cms';
import { Component } from '@monorepo/type';

type Props = {
  snippetId: string | number;
  setIsShow?: (val: boolean) => void;
};

const SnippetComponent: Component<Props> = ({
  snippetId,
  setIsShow = () => {}
}) => {
  const handleGoBack = () => setIsShow(false);

  return <HTMLSnippetPage snippetId={snippetId} onGoBack={handleGoBack} />;
};

export default SnippetComponent;
