import get from 'lodash/get';

import { TBoostedOddsResponse } from '../../../types';

const getBoostedOdds = () => ({
  query: () => ({
    body: {
      command: 'get_boosted_selections',
      params: {}
    }
  }),
  transformResponse: (response: any): TBoostedOddsResponse =>
    get(response, 'data.details', {})
});
export default getBoostedOdds;
