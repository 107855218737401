import React, { lazy, Suspense } from 'react';

import { ProfileSkeleton } from '@monorepo/common';

const EditProfileMain = lazy(() => import('./EditProfileMain'));

export default ({ config }: any) => (
  <Suspense fallback={<ProfileSkeleton />}>
    <EditProfileMain config={config} />
  </Suspense>
);
