import { xml } from '@xmpp/client';

import { XML_NAMESPACES } from '../../constants/namespaces';
import { TRsm } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const rsmCreator = ({ max, after, before }: TRsm) => {
  if (!(max || after || before)) {
    return undefined;
  }
  const set = xml('set', XML_NAMESPACES.RSM);

  if (max) {
    set.append(xml('max', {}, max.toString(10)));
  }
  if (after) {
    if (typeof after === 'string') {
      set.append(xml('after', {}, after));
    } else {
      set.append(xml('after'));
    }
  }
  if (before) {
    if (typeof before === 'string') {
      set.append(xml('before', {}, before));
    } else {
      set.append(xml('before'));
    }
  }

  return set;
};
