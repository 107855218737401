import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformMenuItems, useGetMenu } from '@monorepo/cms';

import Item from '../Item';

type Props = {
  menuId: number;
  className: string;
};

const CmsNavMenuItems = ({ menuId, className }: Props) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { isLoading, data: cmsMenuItems } = useGetMenu(menuId);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {transformMenuItems(cmsMenuItems, isAuthenticated).map(
        ({ title, link }) => (
          <Item key={title} title={title} link={link} className={className} />
        )
      )}
    </>
  );
};

export default CmsNavMenuItems;
