import React, { ElementRef, useRef, useState } from 'react';

import { Recaptcha, RECAPTCHA_ACTIONS } from '@monorepo/common';
import {
  CheckboxField,
  emailRegExpValidator,
  Form,
  InputField,
  requiredValidator
} from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import AuthLink from '../../../../common/AuthLink';
import SubmitButton from '../../../../common/SubmitButton';
import useLogin from '../../../../hooks/useLogin';
import { TLoginRequest } from '../../../../types';

import styles from './index.module.css';

const defaultValues: TLoginRequest = {
  login: '',
  password: ''
};

const LoginForm = () => {
  const recaptchaHandlers = useRef<ElementRef<typeof Recaptcha>>(null);
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const [isValidCaptcha, setIsValidCaptcha] = useState<boolean>(true);
  const { t } = useI18n(NAMESPACES.FORMS);
  const { generalRoutes } = useSiteSettings();
  const { restorePassword } = generalRoutes || {};
  const { onLogin, isLoading } = useLogin({
    onError: (error) => {
      if (error.status === 27) {
        recaptchaHandlers.current?.setV2Key(error.captchaData);
        setIsValidCaptcha(false);
      }
    }
  });

  const setIsValidForm = async (token: string | boolean) => {
    const data = formRef.current?.getValues();
    setIsValidCaptcha(!!token);
    if (data) {
      const payload =
        typeof token === 'string'
          ? { ...data, gRecaptchaResponse: token }
          : data;
      await onLogin(payload as TLoginRequest);
    }
  };

  const handleSubmit = () => recaptchaHandlers.current?.validateRecaptcha();

  return (
    <Form<TLoginRequest>
      ref={formRef}
      onSubmit={handleSubmit}
      formSettings={{ defaultValues, mode: 'onChange' }}
    >
      <InputField<TLoginRequest>
        id="userName"
        name="login"
        label={t('login.form.email.label')}
        placeholder={t('login.form.email.placeholder')}
        className={styles.loginInput}
        isClearable
        rules={{
          ...requiredValidator(),
          ...emailRegExpValidator()
        }}
        toneType={FIELD_TONE.light}
      />
      <InputField<TLoginRequest>
        id="password"
        name="password"
        type="password"
        label={t('login.form.password.label')}
        placeholder={t('login.form.password.placeholder')}
        className={styles.loginInput}
        rules={{ ...requiredValidator() }}
        toneType={FIELD_TONE.light}
      />

      <FlexBox
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
        className={styles.wrapper}
      >
        <CheckboxField
          label={t('login.form.remember')}
          id="remember"
          name="remember"
          className={styles.checkbox}
          toneType={FIELD_TONE.light}
        />

        <AuthLink to={restorePassword}>
          {t('login.link.forgotpassword')}
        </AuthLink>
      </FlexBox>

      <Recaptcha
        isV2Only
        isManual
        action={RECAPTCHA_ACTIONS.LOGIN}
        onValidated={setIsValidForm}
        ref={recaptchaHandlers}
      />

      <SubmitButton
        disabled={!isValidCaptcha}
        processing={isLoading}
        data-custom="login-submit-button"
      >
        {t('login.form.submit')}
      </SubmitButton>
    </Form>
  );
};

export default LoginForm;
