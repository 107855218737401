import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { actions as notificationActions } from '@monorepo/notification';

import { actions } from '../../../store';
import { TAuthData } from '../../../types';
import { actions as localActions } from '../../userLocalSlice';
import restoreLoginAdapter from '../adapters/restoreLogin';

const restoreLogin = () => ({
  query: ({ userId, authToken, jweToken }: TAuthData) => ({
    body: {
      command: jweToken ? 'login_encrypted' : 'restore_login',
      params: jweToken
        ? {
            jwe_token: jweToken,
            auth_token: authToken
          }
        : {
            user_id: userId,
            auth_token: authToken
          }
    },
    checkError: (response) => {
      const { code } = response;
      return code !== 0;
    }
  }),
  async onQueryStarted(
    { userId, authToken, jweToken }: TAuthData,
    { dispatch, queryFulfilled }
  ) {
    const { setIsAuthenticated } = actions;
    const { resetUserLocal, setAuthData } = localActions;
    const { setClearLogout } = notificationActions;
    try {
      const { data = {} } = await queryFulfilled;
      const { userId: id = userId, authToken: token = authToken } = data;
      dispatch(setAuthData({ userId: id, authToken: token, jweToken }));
      dispatch(
        setIsAuthenticated({
          isAuthenticated: true,
          isAuthFetched: true
        })
      );
    } catch {
      dispatch(resetUserLocal(undefined));
      dispatch(setClearLogout());
      dispatch(
        setIsAuthenticated({
          isAuthenticated: false,
          isAuthFetched: true
        })
      );
    }
  },
  transformResponse(
    response: BaseQueryResult<any>
  ): Promise<{ isAuthenticated: boolean }> | { isAuthenticated: boolean } {
    return restoreLoginAdapter(response);
  }
});

export default restoreLogin;
