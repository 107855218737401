import React from 'react';
import { Link } from 'react-router-dom';

import { BUTTON_INTENT, BUTTON_SIZE, FIELD_TONE } from '../../constants';
import { ButtonProps } from '../../types';

import styles from '../Button/index.module.css';

const LinkButton = ({
  className = '',
  intent = BUTTON_INTENT.primary,
  isOnlyIcon = false,
  children,
  toneType = FIELD_TONE.dark,
  size = BUTTON_SIZE.h48,
  to = '/',
  onClick,
  target
}: ButtonProps) => {
  const classes = `${styles.button}
    ${styles[toneType]}
    ${styles[size]}
    ${styles[intent]}
    ${isOnlyIcon ? styles.iconButton : ''}
    ${className}`;

  return (
    <Link className={classes} to={to} target={target} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkButton;
