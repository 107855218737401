import { XML_NAMESPACES } from '../../constants/namespaces';

import { getIqQuery } from './getIqQuery';

export const getDiscoInfo = (to: string) => {
  const iq = getIqQuery(XML_NAMESPACES.DISCO_INFO);
  iq.attrs.to = to;
  return iq;
};

export const getDiscoItems = (to: string) => {
  const iq = getIqQuery(XML_NAMESPACES.DISCO_ITEMS);
  iq.attrs.to = to;
  return iq;
};
