import { createApi } from '@reduxjs/toolkit/query/react';

import { TDestroyRoom, TMediateInvite, xmppBaseQuery } from '@monorepo/xmpp';

import { SOCIALS_QUERY_TAG } from '../constants';
import {
  TCommunityDiscoInfo,
  TCommunityItem,
  TCommunityPermission,
  TCreateRoomRequest,
  TDeclineRoomInvite,
  TEnterRoomRequest,
  TPermissionItem,
  TRoomAffiliationListRequest,
  TRoomRegisterResult,
  TRoomRequest,
  TRoomSetAffiliationListRequest,
  TSubmitRoomConfigurationRequest
} from '../types';

import createRoom from './api/createRoom';
import declineRoomInvite from './api/declineRoomInvite';
import destroyRoom from './api/destroyRoom';
import enterRoom from './api/enterRoom';
import exitRoom from './api/exitRoom';
import getCommunityList from './api/getCommunityList';
import getInbox from './api/getInbox';
import getRoomDiscoInfo from './api/getRoomDiscoInfo';
import inviteToRoom from './api/inviteToRoom';
import getAffiliationList from './api/requestAffiliationList';
import requestRoomConfiguration from './api/requestRoomConfiguration';
import requestRoomName from './api/requestRoomName';
import requestRoomRegistration from './api/requestRoomRegistration';
import roomPresence from './api/roomPresence';
import setAffiliationList from './api/setAffiliationsList';
import setInboxRead from './api/setInboxRead';
import streaming from './api/streaming';
import submitRoomConfiguration from './api/submitRoomConfiguration';

export const socialsApi = createApi({
  reducerPath: 'socialsApi',
  baseQuery: xmppBaseQuery(),
  tagTypes: [
    SOCIALS_QUERY_TAG.MUC_ROOM_DISCO_INFO,
    SOCIALS_QUERY_TAG.MUC_ROOM_AFFILIATIONS
  ],
  endpoints: (builder) => ({
    streaming: builder.query<unknown, undefined>(streaming()),
    getCommunityList: builder.query<TCommunityItem[], undefined>(
      getCommunityList()
    ),
    getRoomDiscoInfo: builder.query<TCommunityDiscoInfo, string>(
      getRoomDiscoInfo()
    ),
    getAffiliationList: builder.query<
      TPermissionItem[],
      TRoomAffiliationListRequest
    >(getAffiliationList()),
    enterRoom: builder.mutation<TCommunityPermission, TEnterRoomRequest>(
      enterRoom()
    ),
    exitRoom: builder.mutation<TCommunityPermission, TRoomRequest>(exitRoom()),
    requestRoomConfiguration: builder.mutation<null, string>(
      requestRoomConfiguration()
    ),
    submitRoomConfiguration: builder.mutation<
      null,
      TSubmitRoomConfigurationRequest
    >(submitRoomConfiguration()),
    requestRoomName: builder.mutation<string, undefined>(requestRoomName()),
    createRoom: builder.mutation<null, TCreateRoomRequest>(createRoom()),
    requestRoomRegistration: builder.mutation<TRoomRegisterResult, string>(
      requestRoomRegistration()
    ),
    destroyRoom: builder.mutation<null, TDestroyRoom>(destroyRoom()),
    inviteToRoom: builder.mutation<null, TMediateInvite>(inviteToRoom()),
    declineRoomInvite: builder.mutation<null, TDeclineRoomInvite>(
      declineRoomInvite()
    ),
    setAffiliationList: builder.mutation<null, TRoomSetAffiliationListRequest>(
      setAffiliationList()
    ),
    roomPresence: builder.query<TCommunityPermission, TEnterRoomRequest>(
      roomPresence()
    ),
    getInbox: builder.query<unknown, undefined>(getInbox()),
    setInboxRead: builder.mutation<null, string>(setInboxRead())
  })
});

export const {
  useGetCommunityListQuery,
  useGetRoomDiscoInfoQuery,
  useGetAffiliationListQuery,
  useRequestRoomConfigurationMutation,
  useSubmitRoomConfigurationMutation,
  useRequestRoomNameMutation,
  useCreateRoomMutation,
  useRequestRoomRegistrationMutation,
  useDestroyRoomMutation,
  useInviteToRoomMutation,
  useDeclineRoomInviteMutation,
  useSetAffiliationListMutation,
  useExitRoomMutation, // this endpoint used in `roomPresence`
  useEnterRoomMutation,
  useRoomPresenceQuery,
  useStreamingQuery,
  useGetInboxQuery,
  useSetInboxReadMutation
} = socialsApi;
