import { camelizeKeys } from 'humps';
import get from 'lodash/get';

// eslint-disable-next-line import/prefer-default-export
export const favoriteTeamAdapter = (rawResponse: Record<string, any>): any => {
  const teams = get(rawResponse, 'details', null);
  if (Array.isArray(teams) && teams[0]) {
    return camelizeKeys(teams[0]);
  }
  return null;
};
