import { WALLETS_KEYS } from '../../../constants';

const getChildBalances = () => ({
  query: () => ({
    body: {
      command: 'get_children_balances'
    }
  }),
  providesTags: [WALLETS_KEYS.BALANCES],
  transformResponse(rawResponse) {
    return rawResponse.details;
  }
});

export default getChildBalances;
