import { TGetBlogsCategoryResponse } from '../../../types';

const getBlogsCategory = () => ({
  query: () => ({
    url: `/tags/public`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  keepUnusedDataFor: 0
});

export default getBlogsCategory;
