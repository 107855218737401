import { memoize } from 'proxy-memoize';

import { useSiteSettings } from '@monorepo/site-settings';

import { useGetRecommendedGamesIdsQuery } from '../../store/sportsRecommender';

const useGetRecommendedSportsGameIds = () => {
  const { siteId } = useSiteSettings();
  return useGetRecommendedGamesIdsQuery(
    { siteId },
    {
      selectFromResult: memoize(({ data, isFetching, isLoading }) => ({
        isLoading,
        isFetching,
        data: (data || []).map((item: any) => item.game)
      }))
    }
  );
};

export default useGetRecommendedSportsGameIds;
