// eslint-disable-next-line import/prefer-default-export
export enum FILTER_PARAMS {
  all = 'all',
  page = 'page',
  provider = 'provider',
  tag = 'tag',
  search = 'search',
  status = 'status',
  registrationStatus = 'registrationStatus',
  entryType = 'entryType'
}

export enum SEARCH_STRING_PARAMS {
  code = 'code',
  redirectUrl = 'redirectUrl',
  backPath = 'backPath'
}
