import { TSetNewPassword } from '../../../types';

const resetPassword = () => ({
  query: ({ password, resetCode }: TSetNewPassword) => ({
    body: {
      command: 'reset_password',
      params: {
        new_password: password,
        reset_code: resetCode
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result, result_text: msg } = data;
      return !(code === 0 && result === 0) ? msg || result : false;
    }
  })
});

export default resetPassword;
