import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const BannersCmsWide = lazy(() => import('./BannersCmsWide'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BannersCmsWide config={config} />
  </Suspense>
);
