const getStream = () => ({
  query: (videoInfo: {}) => ({
    body: {
      command: 'video_url',
      params: videoInfo
    }
  })
});

export default getStream;
