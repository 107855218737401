import { useEffect, useRef } from 'react';

const usePrevious = <T>(value: T, defaultValue = null): T | null => {
  const ref = useRef<T | null>(value || defaultValue);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
