import React from 'react';

import { getImageCmsPath } from '@monorepo/cms';
import { TSlideProps } from '@monorepo/common';
import { CustomFC } from '@monorepo/type';

import TopGameItem from '../../common/TopGameItem';
import useGetGamesByIds from '../../hooks/useGetGamesByIds';

type TBannerGameItem = {
  slide: object;
  withTutorials: boolean;
  className?: string;
  visual?: string;
};

const BannerGame: CustomFC<TBannerGameItem> = ({
  slide,
  withTutorials,
  className = '',
  visual = 'sport'
}) => {
  const { src = '', href = '' }: TSlideProps = slide;
  const imageFullPath = getImageCmsPath(src);
  const { data: games, isLoading } = useGetGamesByIds({
    externalId: href
  });

  const [game] = games || [];
  const topGame = game
    ? { ...game, img: src ? imageFullPath : game.img }
    : {
        name: '',
        gameId: '',
        img: '',
        isRealMode: false,
        isFunMode: false,
        badge: {}
      };

  if (isLoading) {
    return null;
  }

  return (
    <TopGameItem
      game={topGame}
      withTutorials={withTutorials}
      className={className}
      visual={visual}
    />
  );
};

export default BannerGame;
