import {
  TGetRecommendedGamesRequest,
  TGetRecommendedGamesResponse
} from '../../../types';
import getRecommendedGamesIdsAdapter from '../adapters/getRecommendedGamesIdsAdapter';

const getGamesIds = () => ({
  query: ({ siteId, id }: TGetRecommendedGamesRequest) => `${siteId}/${id}`,
  transformResponse(response: TGetRecommendedGamesResponse): number[] | [] {
    return !response ? [] : getRecommendedGamesIdsAdapter(response);
  }
});
export default getGamesIds;
