import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { CustomFC } from '@monorepo/type';

import styles from './index.module.css';

const AuthLink: CustomFC<LinkProps> = ({
  children,
  className = '',
  ...props
}) => (
  <Link className={`${styles.link} ${className}`} {...props}>
    {children}
  </Link>
);

export default AuthLink;
