import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { get } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { getPageSnippet } from '../../requests';
import { TGetSnippetRequest, TGetSnippetResponse } from '../../types';

const useGetSnippet = (
  params: Omit<TGetSnippetRequest, 'partnerId' | 'lang'>
) => {
  const navigate = useNavigate();
  const { siteId, generalRoutes } = useSiteSettings();
  const { page404 } = generalRoutes;
  const { languageLongCode } = useI18n();
  const [isFetching, setIsFetching] = useState(false);
  const [snippet, setSnippet] = useState<TGetSnippetResponse | null>(null);

  useLayoutEffect(() => {
    setIsFetching(true); // todo refactor with RTKQuery
    get(
      getPageSnippet({
        partnerId: siteId,
        lang: languageLongCode,
        ...params
      })
    )
      .then((response) => {
        setSnippet(response.data || null);
        setIsFetching(false);
        if (response.code !== 200) {
          navigate(page404);
        }
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, [params.id, languageLongCode]);

  return { isFetching, snippet };
};

export default useGetSnippet;
