/* eslint-disable prefer-object-spread,no-param-reassign */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

type UserState = {
  isAuthenticated: boolean;
  isAuthFetched: boolean;
  isAccountFrozen: boolean;
  isCookiesFetched: boolean;
  isForceReFetchAuthCookie: boolean;
  jwtAuthToken: string | null;
  messengerAuthToken: string | null;
};

const initialState = {
  isAuthenticated: false,
  isAuthFetched: false,
  isAccountFrozen: false,
  isCookiesFetched: false,
  isForceReFetchAuthCookie: false,
  jwtAuthToken: null,
  messengerAuthToken: null
};

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>>({
  name: 'user',
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      const { isAuthenticated, isAuthFetched } = payload;
      state.isAuthenticated = Boolean(isAuthenticated);
      state.isAuthFetched = Boolean(isAuthFetched);
      return state;
    },
    setIsCookiesFetched: (state, { payload }) => {
      const { isCookiesFetched } = payload;
      state.isCookiesFetched = Boolean(isCookiesFetched);
      return state;
    },
    setIsForceReFetchAuthCookie: (state, { payload }) => {
      state.isForceReFetchAuthCookie = payload;
      state.isCookiesFetched = !payload;
      return state;
    },
    setJwtAuthToken: (state, { payload }: TPayload<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.jwtAuthToken = payload;
      return state;
    },
    setMessengerAuthToken: (state, { payload }: TPayload<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.messengerAuthToken = payload;
      return state;
    }
  }
});

export const { actions } = userSlice;
