import React, { useMemo } from 'react';

import { DESKTOP_SIZE } from '@monorepo/common';
import { CustomFC } from '@monorepo/type';

import useGetMarketType from '../../hooks/useGetMarketType';
import useSportsURLParams from '../../hooks/useSportsURLParams';
import { TLineParameters } from '../../types';
import BaseParametersProvider from '../BaseParametersProvider';

type Props = {
  params?: Partial<TLineParameters>;
};

const LineParametersProvider: CustomFC<Props> = ({ children, params = {} }) => {
  const urlParams = useSportsURLParams();
  const marketType = useGetMarketType(DESKTOP_SIZE);
  const defaultState = useMemo(
    () => ({
      marketType,
      ...urlParams,
      ...params
    }),
    [urlParams, params, marketType]
  );

  return (
    <BaseParametersProvider params={defaultState}>
      {children}
    </BaseParametersProvider>
  );
};

export default LineParametersProvider;
