export enum POST_MESSAGE_TYPE {
  EXTERNAL_CHAT_INIT = 'social-iframe-externalChatInit',
  COMMON_CHAT_INIT = 'social-iframe-commonChatInit',
  FRIENDSHIP_INIT = 'social-iframe-friendshipInit',
  FRIENDS_LIST_INIT = 'social-iframe-friendsListInit',
  MESSENGER_INIT = 'social-iframe-messengerInit',
  CHANGE_LANGUAGE = 'social-iframe-changeLanguage'
}

export enum SENT_POST_MESSAGE_TYPE {
  FRAME_INIT = 'social-iframe-initiated',
  REDIRECT = 'social-iframe-redirect',
  DIALOG_ACTION = 'social-iframe-dialog-action'
}

export enum MESSENGER_VIEW {
  PERSONAL = 'personal',
  COMMUNITY = 'community'
}

export enum FRIENDSHIP_VIEW {
  // USER_PROFILE = 'userProfile',
  LOBBY = 'lobby',
  SEARCH = 'search',
  CONTACT_PREVIEW = 'contactPreview',
  MATCH_PREVIEW = 'matchPreview',
  // CONTACT_PROFILE = 'contactProfile',
  DIALOG = 'dialog'
}

export enum SOCIAL_FRAME_NAME {
  FRIENDS_LIST = 'friendsList',
  MESSENGER = 'messenger',
  FRIENDSHIP = 'friendship',
  EXTERNAL_CHAT = 'externalChat',
  COMMON_CHAT = 'commonChat'
}

export const SOCIAL_FRAME_LIST = [
  SOCIAL_FRAME_NAME.COMMON_CHAT,
  SOCIAL_FRAME_NAME.EXTERNAL_CHAT,
  SOCIAL_FRAME_NAME.FRIENDSHIP,
  SOCIAL_FRAME_NAME.FRIENDS_LIST,
  SOCIAL_FRAME_NAME.MESSENGER
];

export enum EXTERNAL_CHAT_SLUG {
  SPORT_EVENT = 'event',
  CASINO_GAME = 'casino'
}

export enum DIALOG_ACTION {
  OPEN = 'open',
  CLOSED = 'closed'
}

export const SOCIAL_FRAME_NAME_MAP = {
  [SOCIAL_FRAME_NAME.FRIENDS_LIST]: POST_MESSAGE_TYPE.FRIENDS_LIST_INIT,
  [SOCIAL_FRAME_NAME.MESSENGER]: POST_MESSAGE_TYPE.MESSENGER_INIT,
  [SOCIAL_FRAME_NAME.EXTERNAL_CHAT]: POST_MESSAGE_TYPE.EXTERNAL_CHAT_INIT,
  [SOCIAL_FRAME_NAME.COMMON_CHAT]: POST_MESSAGE_TYPE.COMMON_CHAT_INIT,
  [SOCIAL_FRAME_NAME.FRIENDSHIP]: POST_MESSAGE_TYPE.FRIENDSHIP_INIT
};
