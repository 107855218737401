import React from 'react';

import { CardsSkeleton, useCountries } from '@monorepo/common';
import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import { Box, FlexBox, PaddingWrapper, UI_SIZES } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../constants';
import usePaymentServiceData from '../../hooks/usePaymentServiceData';
import AllCardsSystem from '../common/AllCardsSystem';
import CardBackHead from '../common/CardBackHead';
import CardSystem from '../common/CardSystem';
import EmptyMethod from '../common/EmptyMethod';
import MobileDepositMenu from '../common/MobileDepositMenu';
import PaymentMenu from '../PaymentMenu';

import styles from './index.module.css';

type TDeposit = {
  imageFromCms?: boolean;
  isWithProfileCheck?: boolean;
  defaultCountry: string;
  withdrawInfoPage?: string;
};

const MobileDeposit: ComponentWithConfig<TDeposit> = ({ config }) => {
  const {
    imageFromCms = false,
    isWithProfileCheck = false,
    defaultCountry,
    withdrawInfoPage = ''
  } = config;
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const countriesFromJSON = useCountries();
  const {
    isFetching,
    listData: list,
    systemId,
    setSystemId
  } = usePaymentServiceData(SERVICE_TYPES.DEPOSIT);

  const { searchParams } = useSearchParamsWithParsing();
  const { group = '' } = searchParams;
  const handleClick = () => setSystemId(undefined);

  if (isFetching) {
    return (
      <PaddingWrapper>
        <Box mt={UI_SIZES.l}>
          <CardsSkeleton size={5} />
        </Box>
      </PaddingWrapper>
    );
  }

  if (list?.length) {
    return (
      <>
        {!systemId ? (
          <>
            <PaymentMenu
              serviceType={SERVICE_TYPES.DEPOSIT}
              withdrawInfoPage={withdrawInfoPage}
            />
            {group === 'all' || !group ? (
              <AllCardsSystem
                setDepositSystem={setSystemId}
                serviceType={SERVICE_TYPES.DEPOSIT}
                activeSystem={systemId}
                imageFromCms={imageFromCms}
                isMobile
              />
            ) : (
              <CardSystem
                setDepositSystem={setSystemId}
                serviceType={SERVICE_TYPES.DEPOSIT}
                activeSystem={systemId}
                imageFromCms={imageFromCms}
                isMobile
              />
            )}
          </>
        ) : (
          <>
            <CardBackHead
              id={systemId}
              serviceType={SERVICE_TYPES.DEPOSIT}
              handleClick={handleClick}
            />
            <MobileDepositMenu
              id={systemId}
              imageFromCms={imageFromCms}
              isWithProfileCheck={isWithProfileCheck}
              countriesFromJSON={countriesFromJSON}
              defaultCountry={defaultCountry}
            />
          </>
        )}
      </>
    );
  }
  return (
    <PaddingWrapper className={`${styles.wrap} ${styles.empty}`}>
      <FlexBox>
        <EmptyMethod
          head={t('wallet.payment.nodeposit.head')}
          text={t('wallet.payment.nodeposit.text')}
          supportButtonText={t('navigation.support')}
        />
      </FlexBox>
    </PaddingWrapper>
  );
};

export default MobileDeposit;
