import pick from 'lodash/pick';
import { memoize } from 'proxy-memoize';

import useGetPlayerInformation from '../useGetPlayerInformation';

const getData = (
  { data }: { data: Record<string, any> },
  properties: string[] = []
) => (properties.length ? pick(data, properties) : data || null);

const usePlayerProfile = (properties: string[] = []) =>
  useGetPlayerInformation({
    selectFromResult: memoize((result: any) => ({
      data: getData(result, properties),
      isSuccess: result?.isSuccess,
      isFetching: result?.isFetching,
      isUninitialized: result?.isUninitialized,
      isLoading: result?.isLoading
    }))
  });

export default usePlayerProfile;
