import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { CustomFC } from '@monorepo/type';
import { FlexBox } from '@monorepo/ui-kit';

import SportsBookButtonSkeleton from '../../../SportsBookButton/SportsBookButtonSkeleton';

type TMarketTitlesSkeleton = {
  amount?: number;
  skeletonWrappClassName?: string;
  skeletonItemClassName?: string;
};

const MarketTitlesSkeleton: CustomFC<TMarketTitlesSkeleton> = ({
  amount = 3,
  skeletonItemClassName = '',
  skeletonWrappClassName = ''
}) => {
  const array = createSkeletonArray(amount);

  return (
    <FlexBox className={skeletonWrappClassName}>
      {array.map((key) => (
        <SportsBookButtonSkeleton key={key} className={skeletonItemClassName} />
      ))}
    </FlexBox>
  );
};

export default MarketTitlesSkeleton;
