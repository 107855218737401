import { TGetMatchInfo, TGetMatchScoresResponse } from '../../../types';

const getMatchScoreAdapter = (
  response: TGetMatchScoresResponse
): TGetMatchInfo => {
  const {
    details: [scoreInfo]
  } = response;
  const { score, match_id, is_match_finished } = scoreInfo || {};

  return { score, matchId: match_id, isFinished: is_match_finished };
};

export default getMatchScoreAdapter;
