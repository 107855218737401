import {
  TRawTransactions,
  TTransaction,
  TTransactionsHistoryRequest
} from '../../../types';
import transactionHistoryAdapter from '../adapters/transactionHistoryAdapter';

const getTransactionsHistory = () => ({
  query: ({
    fromDate,
    toDate,
    operationType,
    category
  }: TTransactionsHistoryRequest) => ({
    body: {
      command: 'balance_history',
      params: {
        where: {
          from_date: fromDate,
          to_date: toDate,
          type: operationType
        },
        product: category
      }
    }
  }),
  keepUnusedDataFor: 0,
  transformResponse(response: TRawTransactions): TTransaction[] {
    return !(response.history && Array.isArray(response.history))
      ? []
      : transactionHistoryAdapter(response);
  }
});
export default getTransactionsHistory;
