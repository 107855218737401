const sumToString = (num: number) => {
  if (!num) {
    return '0.00';
  }
  const total = Math.trunc(num);
  const formattedTotal = Array.from(`${total}`)
    .reverse()
    .reduce((acc: string[], item, index) => {
      if ((index + 1) % 3 === 0 && index + 1 !== `${total}`.length) {
        return [...acc, item, ' '];
      }
      return [...acc, item];
    }, [])
    .reverse()
    .join('');
  const cents = Math.round((num - total) * 100);
  const formattedCents = cents ? `${cents < 10 ? `0${cents}` : cents}` : '00';

  return `${formattedTotal}.${formattedCents}`;
};

export default sumToString;
