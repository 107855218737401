import { TGameEvent, TGameEventDescriptionResponse } from '../../../types';

const getGameInfoAdapter = (
  response: TGameEventDescriptionResponse
): TGameEvent[] => {
  const {
    lines: { line }
  } = response;

  const transformedLine = line.reduce((acc, item) => {
    const { events, line_name } = item;
    const { event_name } = events;
    const [defaultEvent] = event_name;

    if (event_name.length === 0) {
      return [...acc];
    }
    return [...acc, { eventName: line_name, eventValue: defaultEvent }];
  }, []);

  return transformedLine;
};

export default getGameInfoAdapter;
