import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TMakeCashOutRequest } from '../../../types';

const makeCashOut = () => ({
  query: ({ id, price, mode, amountOut }: TMakeCashOutRequest) => ({
    body: {
      command: 'cashout',
      params: {
        bet_id: id,
        price,
        mode,
        partial_price: amountOut
      }
    },
    checkError: (response) => {
      const { data } = response;
      const { result, result_text: message } = data;
      if (result.toLowerCase() === 'ok') {
        return false;
      }
      return { code: result, message };
    }
  }),
  invalidatesTags: [SPORTS_QUERY_KEYS.BETS, SPORTS_QUERY_KEYS.BETS_COUNT]
});
export default makeCashOut;
