import mapValues from 'lodash/mapValues';

import { useGetPlayerProfileSettings } from '@monorepo/auth';

import { TPlayerProfileResponse } from '../../../types';

const getProfileData = ({ data }: { data: TPlayerProfileResponse['result'] }) =>
  data?.profile
    ? {
        data: mapValues(data?.profile, (value) =>
          value === 'not set' ? undefined : value
        )
      }
    : { data: null };

const usePlayerProfileData = (target: number) =>
  useGetPlayerProfileSettings(target, {
    selectFromResult: getProfileData
  });

export default usePlayerProfileData;
