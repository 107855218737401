/* eslint-disable prefer-object-spread,no-param-reassign */
import merge from 'lodash/merge';

import { googleAnalytics } from '@monorepo/helpers';
import { getSocketMessenger } from '@monorepo/websocket';

import {
  FRIENDSHIP_REQUEST_TYPE,
  SERVICE_MSG_ACTION,
  SYSTEM_UID
} from '../constants';
import { getActiveChat } from '../hooks/selectors/useSelectActiveChat';
import { getUserId } from '../hooks/selectors/useSelectUserId';
import { Message } from '../types/messages';
import { TMessengerAuth } from '../types/requests';
import {
  TGetFriendshipRequestsResponse,
  TGetMessagesResult,
  TPostFriendshipRequestResponse
} from '../types/responses';

import messengerApi, { MESSENGER_TAG_TYPES } from './index';
import { actions } from './messengerSlice';

const initialStreamData = {
  isConnected: false
};

type TStreamData = {
  isConnected: boolean;
};

const onNewMessage =
  ({ dispatch, getState }) =>
  (data: Message) => {
    const state = getState();
    const activeChat = getActiveChat(state);
    const userID = getUserId(state);
    const { action, extra } = data.meta?.service_msg_details || {};

    if (data.chat_id === activeChat) {
      if (data.author !== SYSTEM_UID) {
        const entries = messengerApi.util.selectInvalidatedBy(state, [
          { type: MESSENGER_TAG_TYPES.CHAT_MESSAGES, id: data.chat_id }
        ]);

        entries.forEach((entry) => {
          dispatch(
            messengerApi.util.updateQueryData(
              // @ts-ignore
              entry.endpointName,
              entry.originalArgs,
              (draft: TGetMessagesResult) => {
                if (draft) {
                  draft.messages = Object.assign(draft.messages, {
                    [data.id]: data
                  });
                  draft.list.push(data.id);
                }
                return draft;
              }
            )
          );
        });
      } else {
        if (action === SERVICE_MSG_ACTION.UPDATE_MEMBER && extra === userID) {
          dispatch(
            messengerApi.util.invalidateTags([
              { type: MESSENGER_TAG_TYPES.CHAT, id: data.chat_id },
              { type: MESSENGER_TAG_TYPES.CHATS, id: data.chat_id },
              {
                type: MESSENGER_TAG_TYPES.CHAT_MEMBERS,
                id: data.chat_id
              }
            ])
          );
        }
        if (action === SERVICE_MSG_ACTION.ADD_MEMBER && extra === userID) {
          dispatch(
            messengerApi.util.invalidateTags([
              { type: MESSENGER_TAG_TYPES.CHAT, id: data.chat_id },
              { type: MESSENGER_TAG_TYPES.CHATS, id: data.chat_id },
              {
                type: MESSENGER_TAG_TYPES.CHAT_MEMBERS,
                id: data.chat_id
              }
            ])
          );
        }
        if (action === SERVICE_MSG_ACTION.PIN_MESSAGE) {
          dispatch(
            messengerApi.util.invalidateTags([
              MESSENGER_TAG_TYPES.CHAT_PINNED_MESSAGES
            ])
          );
        }
      }
    }
    if (action === SERVICE_MSG_ACTION.BLOCK_MEMBER) {
      dispatch(
        messengerApi.util.invalidateTags([
          {
            type: MESSENGER_TAG_TYPES.CHAT_MEMBER_BLOCKED,
            id: `${data.chat_id}_${extra}`
          }
        ])
      );
    }
  };

const onEditMessage =
  ({ dispatch, getState }) =>
  (data: Message) => {
    const state = getState();
    const activeChat = getActiveChat(state);

    if (data.chat_id === activeChat) {
      const entries = messengerApi.util.selectInvalidatedBy(state, [
        { type: MESSENGER_TAG_TYPES.CHAT_MESSAGES, id: data.chat_id },
        { type: MESSENGER_TAG_TYPES.CHAT_PINNED_MESSAGES, id: data.id }
      ]);

      entries.forEach((entry) => {
        dispatch(
          messengerApi.util.updateQueryData(
            // @ts-ignore
            entry.endpointName,
            entry.originalArgs,
            (draft: TGetMessagesResult) => {
              if (draft) {
                const copyMessage = { ...draft.messages[data.id] };
                draft.messages = Object.assign(draft.messages, {
                  [data.id]: merge(copyMessage, data)
                });
              }
              return draft;
            }
          )
        );
      });
    }
  };

const onMessageDelete =
  ({ dispatch, getState }) =>
  (data) => {
    const state = getState();
    const activeChat = getActiveChat(state);

    if (data.chat_id === activeChat) {
      dispatch(
        messengerApi.util.invalidateTags([
          {
            type: MESSENGER_TAG_TYPES.CHAT_PINNED_MESSAGES,
            id: data.id
          }
        ])
      );
      const entries = messengerApi.util.selectInvalidatedBy(state, [
        { type: MESSENGER_TAG_TYPES.CHAT_MESSAGES, id: data.chat_id }
      ]);
      entries.forEach((entry) => {
        dispatch(
          messengerApi.util.updateQueryData(
            // @ts-ignore
            entry.endpointName,
            entry.originalArgs,
            (draft: TGetMessagesResult) => {
              if (draft) {
                draft.messages = Object.assign(draft.messages, {
                  [data.id]: Object.assign(draft.messages[data.id], {
                    deleted: true
                  })
                });
              }
              return draft;
            }
          )
        );
      });
    } else {
      // todo notifications if needed
    }
  };

const onFriendshipRequestCreated =
  ({ dispatch, getState }) =>
  (data: TPostFriendshipRequestResponse) => {
    const state = getState();

    const entries = messengerApi.util.selectInvalidatedBy(state, [
      {
        type: MESSENGER_TAG_TYPES.GET_FRIENDSHIP_REQUESTS,
        id: FRIENDSHIP_REQUEST_TYPE.RECEIVED
      }
    ]);
    entries.forEach((entry) => {
      dispatch(
        messengerApi.util.updateQueryData(
          // @ts-ignore
          entry.endpointName,
          entry.originalArgs,
          (draft: TGetFriendshipRequestsResponse) => {
            const item = {
              request_id: data.id,
              user_id: data.user_id,
              avatar: '',
              name: '',
              surname: '',
              username: ''
            };
            if (draft) {
              if (draft.records) {
                draft.records.push(item);
              } else {
                draft.records = [item];
              }
            }
            return draft;
          }
        )
      );
    });
  };

const onFriendshipRequestApproved =
  ({ dispatch, getState }) =>
  (data: TPostFriendshipRequestResponse) => {
    const state = getState();

    const entries = messengerApi.util.selectInvalidatedBy(state, [
      {
        type: MESSENGER_TAG_TYPES.GET_FRIENDSHIP_REQUESTS,
        id: FRIENDSHIP_REQUEST_TYPE.SENT
      }
    ]);
    entries.forEach((entry) => {
      dispatch(
        messengerApi.util.updateQueryData(
          // @ts-ignore
          entry.endpointName,
          entry.originalArgs,
          (draft: TGetFriendshipRequestsResponse) => {
            if (draft?.records) {
              draft.records = draft.records.filter(
                ({ request_id: id }) => id !== data.id
              );
            }
            return draft;
          }
        )
      );
    });
    dispatch(
      messengerApi.util.invalidateTags([MESSENGER_TAG_TYPES.GET_FRIENDS])
    );
  };

const onFriendshipRequestDeclined =
  ({ dispatch, getState }) =>
  (data: TPostFriendshipRequestResponse) => {
    const state = getState();

    const entries = messengerApi.util.selectInvalidatedBy(state, [
      {
        type: MESSENGER_TAG_TYPES.GET_FRIENDSHIP_REQUESTS,
        id: FRIENDSHIP_REQUEST_TYPE.SENT
      }
    ]);
    entries.forEach((entry) => {
      dispatch(
        messengerApi.util.updateQueryData(
          // @ts-ignore
          entry.endpointName,
          entry.originalArgs,
          (draft: TGetFriendshipRequestsResponse) => {
            if (draft?.records) {
              draft.records = draft.records.filter(
                ({ request_id: id }) => id !== data.id
              );
            }
            return draft;
          }
        )
      );
    });
  };

const onFriendshipRequestDeleted =
  ({ dispatch, getState }) =>
  (data: TPostFriendshipRequestResponse) => {
    const state = getState();

    const entries = messengerApi.util.selectInvalidatedBy(state, [
      {
        type: MESSENGER_TAG_TYPES.GET_FRIENDSHIP_REQUESTS,
        id: FRIENDSHIP_REQUEST_TYPE.RECEIVED
      }
    ]);
    entries.forEach((entry) => {
      dispatch(
        messengerApi.util.updateQueryData(
          // @ts-ignore
          entry.endpointName,
          entry.originalArgs,
          (draft: TGetFriendshipRequestsResponse) => {
            if (draft?.records) {
              draft.records = draft.records.filter(
                ({ request_id: id }) => id !== data.id
              );
            }
            return draft;
          }
        )
      );
    });
  };

const messengerSocketApi = messengerApi.injectEndpoints({
  endpoints: (build) => ({
    messengerStream: build.query<TStreamData, TMessengerAuth>({
      queryFn: () => ({
        data: initialStreamData
      }),
      keepUnusedDataFor: 0,
      onCacheEntryAdded: async (
        { token },
        {
          cacheDataLoaded,
          cacheEntryRemoved,
          updateCachedData,
          dispatch,
          getState
        }
      ) => {
        const { setUser } = actions;
        const socket = getSocketMessenger();

        try {
          await cacheDataLoaded;
          socket.setOnInit = async () => {
            const data = await socket.send({
              event: 'auth',
              data: { access_token: token }
            });
            // @ts-ignore
            dispatch(setUser(data?.data?.user_id));
            updateCachedData((draft) =>
              merge(draft || {}, {
                isConnected: true
              })
            );
            socket.subscribe('new_msg', onNewMessage({ dispatch, getState }));
            socket.subscribe('msg_edit', onEditMessage({ dispatch, getState }));
            socket.subscribe(
              'msg_delete',
              onMessageDelete({ dispatch, getState })
            );
            socket.subscribe(
              'friendship_request_created',
              onFriendshipRequestCreated({ dispatch, getState })
            );
            socket.subscribe(
              'friendship_request_approved',
              onFriendshipRequestApproved({ dispatch, getState })
            );
            socket.subscribe(
              'friendship_request_declined',
              onFriendshipRequestDeclined({ dispatch, getState })
            );
            socket.subscribe(
              'friendship_request_deleted',
              onFriendshipRequestDeleted({ dispatch, getState })
            );
          };
          await cacheEntryRemoved;
          socket.unsubscribe('status');
          socket.unsubscribe('new_msg');
          socket.unsubscribe('msg_edit');
          socket.unsubscribe('msg_delete');
          socket.unsubscribe('friendship_request_created');
          socket.unsubscribe('friendship_request_approved');
          socket.unsubscribe('friendship_request_declined');
          socket.unsubscribe('friendship_request_deleted');
          socket.reconnect();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e);
          // ga  js-error
          const ga = googleAnalytics();
          ga.dispatch({
            event: ga.event.jsError,
            eventParam: {
              event_category: 'js'
            },
            event_options: {
              message: (e as any)?.message,
              data: e
            }
          });
        }
      }
    })
  })
});

export const { useMessengerStreamQuery } = messengerSocketApi;

export default messengerSocketApi;
