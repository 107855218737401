import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { TValidateRecaptcha } from '../../../types';
import validateRecaptchaAdapter from '../adapters/validateRecaptcha';

const validateRecaptcha = () => ({
  query: ({ token, action }) => ({
    body: {
      command: 'validate_recaptcha',
      params: {
        g_recaptcha_response: token,
        version: 'v2',
        action
      }
    }
  }),
  transformResponse(response: BaseQueryResult<any>): TValidateRecaptcha {
    return validateRecaptchaAdapter(response);
  }
});
export default validateRecaptcha;
