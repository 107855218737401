import React, { useCallback } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Box, UI_SIZES } from '@monorepo/ui-kit';

import BackButton from '../../../../common/BackButton';
import PopularCompetitionsList from '../../../../common/PopularCompetitionsList';
import { POPULAR_ALIAS } from '../../../../constants';

import styles from '../index.module.css';

const ExtraItems = ({ sport, handleChangeSport }) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  const goBack = useCallback(() => {
    handleChangeSport('');
  }, [handleChangeSport]);

  return (
    <>
      <BackButton
        sportName={t(`sportsbook.${sport}`)}
        onClick={goBack}
        className={styles.back}
      />
      <Box pl={UI_SIZES.m} pr={UI_SIZES.m}>
        {sport === POPULAR_ALIAS && <PopularCompetitionsList />}
      </Box>
    </>
  );
};

export default ExtraItems;
