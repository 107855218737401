import { camelizeKeys } from 'humps';

import { TGetPromotionRequest, TPromotion } from '../../../types';

const getPromotion = () => ({
  query: ({ lang, partnerId, id }: TGetPromotionRequest) =>
    `${lang}/partners/${partnerId}/promotions/${id}`,
  transformResponse: (response: any): TPromotion => camelizeKeys(response.data)
});

export default getPromotion;
