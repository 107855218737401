import add from 'date-fns/add';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { Duration } from 'date-fns/Duration';
import endOfDay from 'date-fns/endOfDay';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subYears from 'date-fns/subYears';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

export const formatUnixTime = (
  date: number,
  dateFormat: string = 'dd-MM-yyyy'
): string | null => (date ? format(date * 1000, dateFormat) : null);

export const fromUnixTime = (date: number): Date => new Date(date * 1000);

export const toSeconds = () => {
  const date = new Date();
  return Math.floor(date.getTime() / 1000);
};

export const toSecondsDate = (date: Date) => Math.floor(date.getTime() / 1000);

export const fromString = (date: string, dateFormat: string = 'dd-MM-yyyy') =>
  parse(date, dateFormat, new Date());

export const parseDateFromFormat = (
  date: string,
  dateFormat: string = 'dd-MM-yyyy'
) => parse(date, dateFormat, new Date());

export const toUnixTime = (
  date: string,
  dateFormat: string = 'dd-MM-yyyy',
  isEndDay: boolean = true
) => {
  const parsed = parse(date, dateFormat, new Date());
  const isoDate = isEndDay ? endOfDay(parsed) : startOfDay(parsed);
  const utcDate = zonedTimeToUtc(isoDate, 'UTC');
  return parsed ? getUnixTime(utcDate) : null;
};

export const toUnix = (
  date: string,
  dateFormat: string = "yyyy-MM-dd'T'HH:mm:ss"
) => {
  const parsed = parse(date, dateFormat, new Date());
  return parsed ? getUnixTime(parsed) : null;
};

export const subDaysFrom = (amount: number, startDate = new Date()): Date =>
  subDays(startDate, amount);

export const subYearsFrom = (amount: number, startDate = new Date()): Date =>
  subYears(startDate, amount);

export const formatDate = (
  date: Date,
  formatString: string = 'dd-MM-yyyy'
): string => format(date, formatString);

export const differenceDays = (dateLeft: Date, dateRight: Date): number =>
  differenceInDays(dateLeft, dateRight);

export const formatUnixDate = (
  date: number,
  formatString: string = 'dd.MM.yyyy'
): string => format(date * 1000, formatString);

export const checkDateIsFuture = (endDate: number) =>
  endDate * 1000 > Date.now();

export const formatDateRange = (amount: number) => {
  const start = new Date();
  const end = subDaysFrom(amount, start);
  return [formatDate(end), formatDate(start)];
};

export const isValidDate = (date: Date) => isValid(date);

export const getEndOfDate = (date: Date) => endOfDay(date);

export const getDifferenceInSeconds = (dateStart: Date, dateEnd: Date) =>
  differenceInSeconds(dateStart, dateEnd, {});

export const getStartOfDate = (date: Date) => startOfDay(date);

export const getISOStringDate = () => new Date().toISOString();

export const getDifferenceInHours = (date: Date, period: string) =>
  subHours(date, Number(period));

export const getEndOfWeek = (date: Date) => endOfWeek(date);

export const addToDate = (date: Date, duration: Duration) =>
  add(date, duration);
