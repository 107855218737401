import notificationsAdapter from '../adapters/notificationsAdapter';

const getPopups = () => ({
  query: ({ lang, partner, type, device }: any) =>
    `${lang}/partners/${partner}/popups?type=${type}&use_webp=${device}`,
  transformResponse(response): any[] {
    return notificationsAdapter(response);
  }
});
export default getPopups;
