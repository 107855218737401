import isEqual from 'lodash/isEqual';

import validateCreator from './validateCreator';

const compareValidator = <TFieldValue>(
  getCompare: TFieldValue,
  message = 'validators.notequal',
  inverse = false
) => {
  const validate = (fieldValue: TFieldValue) => {
    const equal = isEqual(fieldValue, getCompare);
    return (inverse ? !equal : equal) || message;
  };
  return validateCreator<TFieldValue>(validate);
};

export default compareValidator;
