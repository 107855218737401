import React from 'react';
import isNumber from 'lodash/isNumber';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { BETSLIP_STATUS, OUTCOME_VIEW_MODE } from '../../constants';
import {
  useSelectActiveBet,
  useSelectBetslipStatus
} from '../../store/api/selectors/betslipSelector';
import Coefficient from '../Coefficient';
import SportsBookButton from '../SportsBookButton';

import styles from './index.module.css';

type Props = {
  id?: string | number;
  label?: string;
  coefficient?: string | number;
  onClick?: () => void;
  isActive?: boolean;
  isSuspended?: boolean;
  isEmpty?: boolean;
  direction?: FLEX_DIRECTION;
  viewMode?: OUTCOME_VIEW_MODE;
  isBoosted?: boolean;
};

const Outcome: Component<Props> = ({
  id,
  className = '',
  onClick,
  label,
  coefficient = 0.0,
  isActive = false,
  isSuspended = false,
  isEmpty = false,
  direction,
  viewMode = OUTCOME_VIEW_MODE.LINE,
  isBoosted = false
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const isSelected = useSelectActiveBet(id as number);
  const formattedCoefficient = coefficient.toFixed(2).toString();
  const betslipStatus = useSelectBetslipStatus();

  const classes = `${styles.wrap}
    ${isActive ? styles.active : ''}
    ${isBoosted ? styles.isBoosted : ''}
    ${
      coefficient === '-' || formattedCoefficient === '1.00'
        ? styles.disabled
        : ''
    }
    ${className}`;

  return (
    <SportsBookButton
      direction={direction}
      disabled={
        isSuspended ||
        isEmpty ||
        formattedCoefficient === '1.00' ||
        betslipStatus === BETSLIP_STATUS.PROCESSING
      }
      className={classes}
      isActive={isActive || isSelected}
      onClick={onClick}
    >
      {isBoosted && !isSuspended ? (
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.boosted}
        >
          <Icon name="boosted_odds-3" className={styles.boostedIcon} />
        </FlexBox>
      ) : null}
      {isSuspended || coefficient.toString() === '1.00' ? (
        <Icon
          name="lock-2"
          className={styles.suspendedIcon}
          title={t('sportsbook.outcome.suspended')}
        />
      ) : (
        <>
          {isEmpty ? (
            <Icon
              name="lock-1"
              className={styles.emptyOutcome}
              title={t('sportsbook.outcome.empty')}
            />
          ) : (
            <>
              {(Boolean(label) || isNumber(label)) && (
                <span className={`${styles.label} ${styles[viewMode]}`}>
                  {label}
                </span>
              )}
              <Coefficient
                className={styles.coefficient}
                coefficient={coefficient}
              />
            </>
          )}
        </>
      )}
    </SportsBookButton>
  );
};

export default Outcome;
