import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const BannersCmsPlain = lazy(() => import('./BannersCmsPlain'));

export default ({ categoryId }) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BannersCmsPlain categoryId={categoryId} />
  </Suspense>
);
