import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const HeaderDeposit = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { isDesktop } = useDeviceDetector();
  const { generalRoutes } = useSiteSettings();
  const { deposit } = generalRoutes || {};

  return (
    <NavLink className={styles.link} to={deposit}>
      <FlexBox direction={FLEX_DIRECTION.column} justify={FLEX_JUSTIFY.center}>
        {isDesktop && (
          <EllipsisText className={styles.titleDesc}>
            {t('profile.deposit')}
          </EllipsisText>
        )}
        {!isDesktop && (
          <FlexBox
            direction={FLEX_DIRECTION.column}
            align={FLEX_ALIGN.center}
            className={styles.titleMob}
          >
            <Icon name="wallet_1" className={styles.icon} title="" />
            <EllipsisText className={styles.title}>
              {t('profile.deposit')}
            </EllipsisText>
          </FlexBox>
        )}
      </FlexBox>
    </NavLink>
  );
};
export default HeaderDeposit;
