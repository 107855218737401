const setSessionDuration = () => ({
  query: (duration) => ({
    body: {
      command: 'set_session_duration',
      params: {
        session_duration: duration
      }
    }
  }),
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  }
});
export default setSessionDuration;
