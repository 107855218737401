import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const RegistrationSuccess = lazy(() => import('./RegistrationSuccess'));

export default (props: ComponentProps<typeof RegistrationSuccess>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <RegistrationSuccess {...props} />
  </Suspense>
);
