import React, { useState } from 'react';

import { CustomFC } from '@monorepo/type';

import SiteSettingsContext from '../../store/context';

const SiteSettingsProvider: CustomFC<{ siteSettings: any }> = ({
  children,
  siteSettings
}) => {
  const [state, setState] = useState<any>(siteSettings);
  return (
    <SiteSettingsContext.Provider value={[state, setState]}>
      {children}
    </SiteSettingsContext.Provider>
  );
};

export default SiteSettingsProvider;
