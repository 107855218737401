const partnerId = process.env.REACT_APP_FORCE_PARTNER_ID;

const backendOptions = {
  loadPath: `{{lng}}/{{ns}}`,
  stringify: JSON.stringify,
  allowMultiLoading: false,
  requestOptions: {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'default'
  },
  parse: (data: string) => {
    const parsedData = JSON.parse(data);
    return { ...parsedData.response };
  },
  fetch: (url: string, payload: object) => {
    const [language, namespace] = url.split('/');
    return fetch(`/api/json`, {
      ...payload,
      body: JSON.stringify({
        type: 'get',
        obj: 'translations',
        language,
        namespace,
        partner_id: partnerId
      })
    });
  }
};

export default backendOptions;
