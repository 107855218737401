import { FORM_FACTORS } from 'betkit-ui-device-detector';
import merge from 'lodash/merge';

import { TPage, TPages } from '../types';

import getMatchedPathFromBatch from './getMathedPathFromBatch';

const getPageConfig = (
  pathname: string,
  formFactor: FORM_FACTORS,
  pages: TPages
): [string, TPage] | null => {
  const matchedPath = getMatchedPathFromBatch(pages, pathname);
  if (matchedPath && pages[matchedPath]) {
    const page = pages[matchedPath];
    const { general = {} } = page || {};

    // merge is mutating all page object and crash page rebuild by formFactor.
    // need to make clone of resulting object without mutation
    if (formFactor && page[formFactor]) {
      return [matchedPath, merge({}, general, page[formFactor])];
    }
    return [matchedPath, merge({}, general, page[FORM_FACTORS.MOBILE])];
  }
  return null;
};

export default getPageConfig;
