import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import { Box, FIELD_TONE, PaddingWrapper } from '@monorepo/ui-kit';

import BackHeaderCommon from '../../common/BackHeaderCommon';
import { TStickHeader } from '../../types';

import styles from './index.module.css';

const BackHeaderTitle: CustomFC<TStickHeader> = ({ config }) => {
  const {
    backLink,
    closeLink,
    titleKey = '',
    className = '',
    tone = FIELD_TONE.light
  } = config || {};
  const { t } = useI18n(NAMESPACES.DYNAMIC);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get(SEARCH_STRING_PARAMS.redirectUrl);

  return (
    <Box className={`${styles.wrapper} ${className}`}>
      <PaddingWrapper>
        <Box>
          <BackHeaderCommon
            backLink={redirectUrl || backLink}
            closeLink={redirectUrl || closeLink}
            title={t(titleKey)}
            className={styles.root}
            tone={tone}
          />
        </Box>
      </PaddingWrapper>
    </Box>
  );
};

export default BackHeaderTitle;
