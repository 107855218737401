const COMPONENT_TYPES = {
  FRIENDS_MENU: 'friends_menu',
  CHATS_MESSENGER: 'chats_messenger',
  CHATS_USER_LIST: 'chats_user_list',
  CHATS_COMMUNITY_LIST: 'chats_community_list',
  CHATS_USER_INFO: 'chats_user_info',
  CHATS_NAV_MENU: 'chats_nav_menu',
  CHATS_RIGHT_EMPTY: 'chats_right_empty',
  CHATS_CENTER_EMPTY: 'chats_center_empty',
  CHATS_TOPIC: 'chats_topic',
  CHATS_PROFILE: 'chats_profile',
  CHATS_COMMUNITY: 'chats_community',
  CHATS_TOPIC_INFO: 'chats_topic_info'
};

export default COMPONENT_TYPES;
