import { useMemo } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';

const useDocuments = (documentTypes: []) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  return useMemo(
    () =>
      documentTypes.map(({ value }) => ({
        value,
        label: t(`document.${value}`)
      })),
    [t]
  );
};

export default useDocuments;
