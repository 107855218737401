import { TEAMS_ID_NAME } from '../../constants';

const getTeamId = (team1Name: string, team2Name: string, title: string) => {
  if (title === team1Name) {
    return TEAMS_ID_NAME.TEAM1ID;
  }
  if (title === team2Name) {
    return TEAMS_ID_NAME.TEAM2ID;
  }
  return '';
};

export default getTeamId;
