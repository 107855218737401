import { mediatedDecline } from '@monorepo/xmpp';

import { TDeclineRoomInvite } from '../../../types';
import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const declineRoomInvite = () => ({
  query: ({ roomJid, userJid }: TDeclineRoomInvite) =>
    mediatedDecline(roomJid, userJid),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError
});

export default declineRoomInvite;
