import React, {
  Children,
  cloneElement,
  forwardRef,
  ForwardRefRenderFunction,
  isValidElement,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';

import { useDisableBodyScroll } from '@monorepo/common';

import { FIELD_TONE, SIDE_POSITION, VERTICAL_POSITION } from '../../constants';
import {
  sidePositionProp,
  toneTypeProp,
  TShutterHandler,
  verticalPositionProp
} from '../../types';

import styles from './index.module.css';

type Props = {
  side?: string;
  vertical?: string;
  isFullWidth?: boolean;
  isWithHeader?: boolean;
  unmountOnExit?: boolean;
  wrapperClassName?: string;
  shutterClassName?: string;
  children: React.ReactNode;
} & toneTypeProp &
  sidePositionProp &
  verticalPositionProp;

const Shutter: ForwardRefRenderFunction<TShutterHandler, Props> = (
  {
    side = SIDE_POSITION.left,
    vertical = VERTICAL_POSITION.stretch,
    isFullWidth = false,
    isWithHeader = false,
    toneType = FIELD_TONE.light,
    unmountOnExit = true,
    wrapperClassName = '',
    shutterClassName = '',
    children
  },
  ref
) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isShow, setIsShow] = useState(false);
  const { enter, enterActive, enterDone, exit, exitActive, exitDone } = styles;
  const shutterClasses = `${styles.shutter}
  ${shutterClassName}
    ${isFullWidth ? styles.fullWidth : ''}
    ${styles[side]}
    ${styles[vertical]}
    ${styles[toneType]}`;

  const wrapperClasses = `${styles.wrap}
    ${isWithHeader ? styles.withHeader : ''}
    ${wrapperClassName}
    ${styles[isShow ? 'visible' : 'hidden']}`;

  useImperativeHandle(ref, () => ({ setIsShow }));
  useDisableBodyScroll(isShow);

  return (
    <div
      className={wrapperClasses}
      onClick={() => {
        setIsShow(false);
      }}
    >
      <CSSTransition
        timeout={300}
        in={isShow}
        nodeRef={nodeRef}
        unmountOnExit={unmountOnExit}
        classNames={{
          enter,
          enterActive,
          enterDone,
          exit,
          exitActive,
          exitDone
        }}
      >
        <div
          ref={nodeRef}
          className={shutterClasses}
          onClick={(e) => e.stopPropagation()}
        >
          {Children.map(children, (child) =>
            isValidElement(child) ? cloneElement(child, { setIsShow }) : null
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default forwardRef(Shutter);
