import { useRef, useState } from 'react';

import { useSessionSettings } from '@monorepo/auth';
import { getSocketSWARM } from '@monorepo/websocket';

import BaseRecaptcha from '../../common/BaseRecaptcha';
import { TRecaptchaAction } from '../../types';

type TValidateCallback = {
  action: TRecaptchaAction;
  token: string;
};

const useRecaptcha = (
  isManual = false,
  isV2Only = false,
  onValidated?: (token: string | boolean) => Promise<void> | void
) => {
  const sessionData = useSessionSettings();
  const { recaptchaEnabled, siteKey, recaptchaActions } = sessionData;
  const recaptchaRef = useRef<BaseRecaptcha>(null);
  const [v2Key, setV2Key] = useState<string>();
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const handleValidated = async (token: string | boolean) => {
    if (token) {
      setIsValidating(false);
      await onValidated?.(token);
    }
  };

  const validateCaptcha = async ({ action, token }: TValidateCallback) => {
    const instance = getSocketSWARM();
    return instance.send({
      command: 'validate_recaptcha',
      params: {
        action,
        g_recaptcha_response: token,
        version: v2Key ? 'v2' : 'v3'
      }
    });
  };

  const handleValidate = async (action: TRecaptchaAction, token: string) => {
    const recaptcha = await validateCaptcha({ action, token });
    if (recaptcha.code === 27) {
      setV2Key(recaptcha.data);
    } else {
      await handleValidated(token);
    }
  };

  const isEnabled = (action: TRecaptchaAction) =>
    recaptchaEnabled && recaptchaActions.includes(action);

  const onVerify = (action: TRecaptchaAction) => async (token: string) => {
    if (isEnabled(action)) {
      if (token) {
        if (!((isManual || isV2Only) && !v2Key)) {
          setIsValidating(true);
          await handleValidate(action, token);
        }
      }
    } else {
      await handleValidated(true);
    }
  };

  const executeCaptcha = () => {
    if (!v2Key) {
      recaptchaRef.current?.execute();
    }
  };

  const getToken = async () => {
    const token = await recaptchaRef.current?.getResponse();
    await recaptchaRef.current?.reset();
    await recaptchaRef.current?.execute();
    return token;
  };

  const triggerManualValidation = async (action: TRecaptchaAction) => {
    setIsValidating(true);
    if (isEnabled(action)) {
      if (isV2Only && !v2Key) {
        await handleValidated(true);
      } else {
        const token = await getToken();
        if (token) {
          await handleValidate(action, token);
        }
      }
    } else {
      await handleValidated(true);
    }
  };

  return {
    recaptchaRef,
    siteKey,
    v2Key,
    isValidating,
    setV2Key,
    executeCaptcha,
    onVerify,
    isEnabled,
    triggerManualValidation
  };
};

export default useRecaptcha;
