const disableTwoFactor = () => ({
  query: (password: string) => ({
    body: {
      command: 'update_user',
      params: {
        user_info: {
          is_two_factor_authentication_enabled: false,
          password
        }
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result } = data;
      return !(code === 0 && result === 0) ? result : false;
    }
  })
});

export default disableTwoFactor;
