import { useSiteSettings } from '@monorepo/site-settings';

import { TGetRegionsRequest } from '../../types';
import useGetRegions from '../useGetRegions';
import useSportsParams from '../useSportsParams';

const useGetMenuRegions = (
  args: Partial<TGetRegionsRequest> = {},
  options = {}
) => {
  const { gameType, sportAlias, time, search, competitionIds, gameIds } =
    useSportsParams();

  const { languages } = useSiteSettings();

  return useGetRegions(
    {
      gameType,
      sportAlias,
      time,
      search,
      competitions: competitionIds,
      gameIds,
      languages,
      ...args
    },
    options
  );
};

export default useGetMenuRegions;
