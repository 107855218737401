import React, { MouseEventHandler } from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { Component } from '@monorepo/type';
import { ActionButton } from '@monorepo/ui-kit';

import { LIKE_TYPE } from '../../constants';
import useGetLike from '../../hooks/useGetLike';
import useNavigateUnauthenticated from '../../hooks/useNavigateUnauthenticated';
import { useToggleLikeMutation } from '../../store/userGeneratedContentApi';

type TLikeButtonProps = {
  id: string | number;
  type: LIKE_TYPE;
  size?: 'small' | 'medium';
  dataCustom?: string;
};

const LikeButton: Component<TLikeButtonProps> = ({
  id,
  type,
  size,
  className = '',
  dataCustom
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { navigateToLogin } = useNavigateUnauthenticated();
  const [toggleLike] = useToggleLikeMutation();

  const linkId = typeof id !== 'string' ? JSON.stringify(id) : id;
  const likeData = useGetLike(linkId, type);

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (!isAuthenticated) {
      navigateToLogin();
    } else {
      toggleLike({ id: linkId, likeType: type });
    }
  };

  return (
    <ActionButton
      iconName="like_3"
      isActive={likeData?.is_liked}
      count={likeData?.counter}
      onClick={handleClick}
      size={size}
      className={className}
      data-custom={dataCustom}
    />
  );
};

export default LikeButton;
