import React from 'react';
import { Link } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import {
  FAVORITE_TYPE,
  FavoriteButton,
  LIKE_TYPE,
  LikeButton
} from '@monorepo/common';
import { formatUnixTime } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import { useComponentsConfig } from '@monorepo/page';
import { Component, TActionsConfig, TQueryResult } from '@monorepo/type';
import { Box, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import useCreateGameLink from '../../hooks/useCreateGameLink';
import useGetLineGames from '../../hooks/useGetLineGames';
import useGetLineMarkets from '../../hooks/useGetLineMarkets';
import useGetStreams from '../../hooks/useGetStreams';
import useSportsURLParams from '../../hooks/useSportsURLParams';
import {
  selectGameBySportIdRegionIdCompetitionIdGameId,
  selectIsHeadMarketsPresent
} from '../../store/api/selectors';
import { getStreamById } from '../../store/api/selectors/streamsByIdSelector';
import { TNormalizedLineMarkets, TNormalizedSports } from '../../types';
import CommentButton from '../CommentButton';
import LineGameLiveInfo from '../LineGameLiveInfo';
import LineEmptyMarketsList from '../LineMarketsList/LineEmptyMarketsList';
import ShowMoreButton from '../ShowMoreButton';

import Teams from './components/Teams';

import styles from './index.module.css';

type Props = {
  gameId: number;
  competitionId: number;
  sportId: number;
  regionId: number;
  showMoreButton?: boolean;
} & TActionsConfig;

const LineGameCard: Component<Props> = ({
  gameId,
  sportId,
  regionId,
  competitionId,
  className = '',
  showMoreButton = true,
  children
}) => {
  const { isDesktop } = useDeviceDetector();
  const { data: game } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectGameBySportIdRegionIdCompetitionIdGameId(
        result,
        sportId,
        regionId,
        competitionId,
        gameId,
        [
          'id',
          'markets_count',
          'is_blocked',
          'is_stat_available',
          'show_type',
          'sport_alias',
          'region_alias',
          'team1_name',
          'team2_name',
          'start_ts',
          'is_live'
        ]
      )
    }))
  });
  const { isLive: isLivePage } = useSportsURLParams();
  const gameLink = useCreateGameLink(competitionId, game, isLivePage);
  const { data: headMarkets } = useGetLineMarkets(undefined, {
    selectFromResult: memoize(
      (result: TQueryResult<TNormalizedLineMarkets>) => ({
        data: selectIsHeadMarketsPresent(result, gameId)
      })
    )
  });
  const { withLike, withStream, withFavorite, withComment } =
    useComponentsConfig<{
      withFavorite?: boolean;
      withLike?: boolean;
      withStream?: boolean;
      withComment?: boolean;
    }>();

  if (isEmpty(game)) {
    return null;
  }

  const {
    marketsCount,
    id,
    startTs,
    isLive,
    isBlocked,
    isStatAvailable,
    sportAlias,
    showType = '',
    team2Name = ''
  } = game;

  const { data: streams } = useGetStreams({
    selectFromResult: (result) => ({
      ...result,
      data: getStreamById(result, id)
    })
  });

  return (
    <FlexBox
      align={FLEX_ALIGN.stretch}
      justify={FLEX_JUSTIFY.between}
      className={`${styles.wrap} ${className}`}
    >
      <Link to={gameLink} className={styles.info}>
        <FlexBox align={FLEX_ALIGN.center} className={styles.timeWrap}>
          <span className={styles.gameTime}>
            {formatUnixTime(startTs, 'HH:mm')}
          </span>
          {withStream && !!streams && (
            <Icon name="videocam" className={styles.streamIcon} />
          )}
          {!isDesktop && Boolean(marketsCount) && (
            <span className={styles.count}>{`+${marketsCount}`}</span>
          )}
          {withLike && (
            <LikeButton
              id={id}
              size="medium"
              className={styles.action}
              type={LIKE_TYPE.SPORTSBOOK}
            />
          )}
          {withComment && (
            <CommentButton
              id={id}
              size="medium"
              className={styles.action}
              link={gameLink}
              hasStatistics={isStatAvailable}
            />
          )}
          {withFavorite && (
            <FavoriteButton
              id={id}
              type={FAVORITE_TYPE.GAME}
              size="medium"
              className={styles.action}
            />
          )}
        </FlexBox>
        <Teams
          game={game}
          sportId={sportId}
          regionId={regionId}
          competitionId={competitionId}
          gameId={gameId}
          className={styles.teams}
        />
        {(Boolean(isLive) || isLivePage) && (
          <LineGameLiveInfo
            team2Name={team2Name}
            sportId={sportId}
            regionId={regionId}
            competitionId={competitionId}
            gameId={gameId}
            date={startTs}
            showType={showType}
          />
        )}
      </Link>
      {Boolean(marketsCount) && (
        <Box className={styles.markets}>
          {isBlocked ? (
            <LineEmptyMarketsList
              sportAlias={sportAlias}
              isSuspended={Boolean(isBlocked)}
            />
          ) : (
            children
          )}
        </Box>
      )}
      {(showMoreButton || !headMarkets) && (
        <ShowMoreButton
          link={gameLink}
          marketsCount={marketsCount}
          className={`${styles.showMore} ${
            isDesktop || !marketsCount ? styles.visible : ''
          }`}
        />
      )}
    </FlexBox>
  );
};

export default LineGameCard;
