import {
  useHasLoginCookies,
  useSelectMessengerAuthToken
} from '@monorepo/auth';

const useMessengerToken = () => {
  const isHasCookies = useHasLoginCookies();
  const messengerAuthToken = useSelectMessengerAuthToken();

  return {
    token: messengerAuthToken || '',
    isValid: isHasCookies && Boolean(messengerAuthToken)
  };
};

export default useMessengerToken;
