import { TCheckInLimitsRequest } from '../../../types';

const setCheckInLimits = () => ({
  query: ({ checkIn, checkInRadio }: TCheckInLimitsRequest) => ({
    body: {
      command: 'set_session_duration',
      params: {
        session_duration: checkInRadio === '1' ? checkIn : 0
      }
    }
  })
});
export default setCheckInLimits;
