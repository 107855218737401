import React, { useMemo } from 'react';
import { memoize } from 'proxy-memoize';

import { LikesProvider } from '@monorepo/common';
import { Component, TActionsConfig, TQueryResult } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import useGetLineGames from '../../hooks/useGetLineGames';
import {
  selectGameIds,
  selectSportRegionsWithOrder
} from '../../store/api/selectors';
import { TNormalizedSports } from '../../types';
import LineCompetitionsList from '../LineCompetitionsList';

import styles from './index.module.css';

type Props = {
  isGroupedByDate?: boolean;
  sportId: number;
  showTitle?: boolean;
} & TActionsConfig;

const LineRegionsList: Component<Props> = ({
  sportId,
  showTitle,
  isGroupedByDate,
  className = ''
}) => {
  const { data: regions, ids } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectSportRegionsWithOrder(result, sportId),
      ids: selectGameIds(result, sportId)
    }))
  });

  const likeIds = useMemo(
    () => ids?.map((id: string | number) => `${id}`) || [],
    [ids]
  );

  return (
    <LikesProvider list={likeIds}>
      <Box className={className}>
        {(regions || []).map((regionId: number) => (
          <LineCompetitionsList
            className={styles.item}
            key={regionId}
            sportId={sportId}
            regionId={regionId}
            showTitle={showTitle}
            isGroupedByDate={isGroupedByDate}
          />
        ))}
      </Box>
    </LikesProvider>
  );
};

export default LineRegionsList;
