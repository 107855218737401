import React, { ReactElement } from 'react';

import { CustomFC } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit/src';

import styles from './index.module.css';

type TPreviewItemProps = {
  value: string;
  label: string;
};

const PreviewItem: CustomFC<TPreviewItemProps> = ({
  label,
  value
}): ReactElement => (
  <FlexBox className={styles.root} align={FLEX_ALIGN.center}>
    <span className={styles.label}>{label}:</span>
    <span className={styles.value}>{value}</span>
  </FlexBox>
);

export default PreviewItem;
