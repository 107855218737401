import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useGeoIP } from '@monorepo/cms';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetCategoriesQuery } from '../../store/casino';

const useCategories = (category?: number) => {
  const { isDesktopDevice } = useDeviceDetector();
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();
  const { data, isLoading } = useGeoIP();
  return useGetCategoriesQuery(
    {
      partnerId: siteId,
      isMobile: isDesktopDevice ? 0 : 1,
      lang: languageLongCode,
      country: data?.countryCode,
      category
    },
    { skip: isLoading }
  );
};

export default useCategories;
