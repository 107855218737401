enum EventNames {
  jsError = 'js_error',
  jsWarning = 'js_warning',
  jsConectTime = 'js_conect-time',
  jsSwarmReconnect = 'js_swarm_reconnect',
  jsLoadTime = 'js_load-time',
  jsSwarmTimeout = 'js_swarm-timeout',
  jsSwarmServerError = 'js_swarm-server-error',
  jsSwarmLogout = 'js_swarm-logout',
  serverError = 'server-error',
  webButton = 'web_button'
}

const googleAnalytics = () => {
  const dispatch = ({
    event,
    eventParam,
    event_options
  }: {
    event: EventNames;
    eventParam?: any;
    event_options?: any;
  }) => {
    if (Object.hasOwn(window, 'dataLayer')) {
      // @ts-ignore
      window.dataLayer.push({
        event,
        event_options,
        ...eventParam
      });
    }
  };
  return { dispatch, event: EventNames };
};

export default googleAnalytics;
