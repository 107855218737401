import React from 'react';

import {
  TAuthData,
  useAuthLoginSWARM,
  useCheckNickOnLogin,
  useIsAuthenticated,
  useIsRestricted,
  usePlayingFreeze,
  useRequestSession,
  useSelectAuthData
} from '@monorepo/auth';
import {
  App as CommonApp,
  ErrorBoundary,
  useDisableZoom,
  useHooryChat
} from '@monorepo/common';
import { SwitchProposal } from '@monorepo/custom';
import { config, i18n, useLanguage } from '@monorepo/i18n';
import { CookieAgreeNotification } from '@monorepo/notification';
import { useAddSocialsHandlers } from '@monorepo/socials';

import Page from '../Page';
import RegistrationModal from '../RegistrationModal';
import UserModals from '../UserModals';

import i18nSettings from './i18n-settings.json';
import siteSettings from './settings.json';

import './App.css';

const { config: i18nConfig } = i18nSettings;
const i18nConfigSite = { ...config, ...i18nConfig };
// @ts-ignore
i18n.init(i18nConfigSite);

const Popups = () => {
  const { isAuthenticated } = useIsAuthenticated();
  if (isAuthenticated) {
    return <UserModals />;
  }
  return null;
};

const Restricted = () => {
  const isRestricted = useIsRestricted();
  if (isRestricted) {
    return <SwitchProposal />;
  }
  return null;
};

const PlayerFreeze = () => {
  usePlayingFreeze();
  return null;
};

const Session = () => {
  const authData = useSelectAuthData();
  useRequestSession(authData as TAuthData, undefined);
  useLanguage();
  useAddSocialsHandlers();
  useHooryChat();
  useDisableZoom();
  useAuthLoginSWARM();
  useCheckNickOnLogin();

  return null;
};

const App = () => (
  <ErrorBoundary>
    <CommonApp siteSettings={siteSettings}>
      <Session />
      <RegistrationModal />
      <PlayerFreeze />
      <Popups />
      <Page>
        <Restricted />
        <CookieAgreeNotification logoComponentId="logo-1" />
      </Page>
    </CommonApp>
  </ErrorBoundary>
);

export default App;
