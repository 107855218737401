import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useGetPartnerConfig } from '@monorepo/auth';
import { useConvertAmount } from '@monorepo/common';
import { useChangeSeparator } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Box, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import useGetFreebetAmount from '../../hooks/useGetFreebetAmount';
import { toFixed } from '../../utils';
import PossibleWinAndTaxes from '../PossibleWinAndTaxes';

import styles from './index.module.css';

type Props = {
  price: number;
  currency: string;
  fieldName: string;
  defaultBetValue?: number;
  className?: string;
  totalClassName?: string;
  totalValueClassName?: string;
};

const PossibleWinAmount = ({
  price,
  currency,
  fieldName,
  defaultBetValue,
  className = '',
  totalClassName,
  totalValueClassName
}: Props) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const { amountMaskToNumber } = useChangeSeparator();
  const { watch } = useFormContext();

  const { data: partnerConfig } = useGetPartnerConfig();

  const { freebetAmount } = useGetFreebetAmount();
  const betValue = watch(fieldName) ?? defaultBetValue;
  const possibleWin = freebetAmount
    ? freebetAmount * price - freebetAmount
    : amountMaskToNumber(betValue) * price;
  const possibleWinAmount = useConvertAmount(toFixed(possibleWin, 2) || 0);

  const { taxType, taxPercent, taxAmountRanges } = partnerConfig || {};

  const taxOnStake = taxPercent ? (betValue * taxPercent) / 100 : 0;
  const netStake = betValue - taxOnStake;
  const possibleWinAfterTax = freebetAmount
    ? freebetAmount * price - freebetAmount
    : amountMaskToNumber(netStake) * price;

  return Number.isInteger(taxType) && Number.isInteger(taxPercent) ? (
    <PossibleWinAndTaxes
      taxOnStake={taxOnStake}
      netStake={netStake}
      possibleWinAfterTax={possibleWinAfterTax}
      taxAmountRanges={taxAmountRanges}
      currency={currency}
      className={className}
    />
  ) : (
    <FlexBox
      justify={FLEX_JUSTIFY.between}
      align={FLEX_ALIGN.center}
      className={`${styles.result} ${totalClassName} `}
    >
      <Box>{t('sportsbook.betslip.bet.possiblewinnings')}:</Box>
      <Box className={totalValueClassName}>
        {possibleWinAmount} {currency}
      </Box>
    </FlexBox>
  );
};

export default PossibleWinAmount;
