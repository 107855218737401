import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import {
  LogoutButton,
  SignInButton,
  useIsAuthenticated,
  useLogout
} from '@monorepo/auth';
import { transformCmsIcons } from '@monorepo/cms';
import { FlagsList, LanguageSwitcher, supportChatOpen } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import { EllipsisText, FLEX_ALIGN, FlexBox, TMenuItem } from '@monorepo/ui-kit';

import User from '../User';

import styles from './index.module.css';

type Props = {
  items: TMenuItem[];
  isWithLogout?: boolean;
  isWithLanguage?: boolean;
  setIsShow?: (state: boolean) => void;
  flagsList?: FlagsList;
};

type ItemProps = TMenuItem & Pick<Props, 'setIsShow'>;

const MenuItem: CustomFC<ItemProps> = ({
  title,
  link,
  count,
  iconName,
  type,
  setIsShow
}) => {
  const isSupportChat = link?.includes('openSupportChat');
  const onMenuItemClick = useCallback(
    (event) => {
      if (isSupportChat) {
        event.stopPropagation();
        event.preventDefault();
        supportChatOpen();
      }
      setIsShow?.(false);
    },
    [isSupportChat, setIsShow]
  );

  return type !== 'group' ? (
    <li className={styles.item}>
      <NavLink
        to={link}
        className={({ isActive }) =>
          `${styles.link} ${isActive ? styles.active : ''}`
        }
        onClick={onMenuItemClick}
      >
        <FlexBox className={styles.title} align={FLEX_ALIGN.center}>
          {iconName !== undefined && (
            <Icon
              name={transformCmsIcons(iconName)}
              className={styles.icon}
              title=""
            />
          )}
          <EllipsisText>{title}</EllipsisText>
          {Boolean(count) && <div className={styles.count}>{count}</div>}
        </FlexBox>
      </NavLink>
    </li>
  ) : (
    <li className={styles.group} />
  );
};

const Menu: CustomFC<Props> = ({
  items,
  setIsShow,
  isWithLogout = false,
  isWithLanguage = true,
  flagsList
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isAuthenticated } = useIsAuthenticated();
  const { onLogout } = useLogout();

  const handleLogOut = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    onLogout();
  };

  return (
    <ul className={styles.list}>
      <li>
        <User />
      </li>
      {items.map(({ title, link, count, iconName, type, id }) => (
        <MenuItem
          title={title}
          link={link}
          count={count}
          iconName={iconName}
          type={type}
          id={id}
          key={id}
          setIsShow={setIsShow}
        />
      ))}
      {isWithLogout && isAuthenticated ? (
        <li className={styles.logout}>
          <LogoutButton>
            <NavLink
              to="#"
              className={({ isActive }) =>
                `${styles.link} ${isActive ? styles.active : ''}`
              }
              onClick={handleLogOut}
            >
              {t('burgermenu.logout')}
            </NavLink>
          </LogoutButton>
        </li>
      ) : (
        <li className={styles.signin}>
          <SignInButton />
        </li>
      )}

      {isWithLanguage && (
        <li className={styles.lang}>
          <LanguageSwitcher
            className={styles.langDrop}
            config={{
              flagsList
            }}
          />
        </li>
      )}
    </ul>
  );
};

export default Menu;
