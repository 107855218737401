import React from 'react';

import { useSelectAuthData } from '@monorepo/auth';
import { googleAnalytics } from '@monorepo/helpers';
import { ComponentWithConfig } from '@monorepo/type';
import { Logo as UILogo } from '@monorepo/ui-kit';

type Config = {
  link: string;
  src: string;
  title: string;
  logoIcon: string;
};

const Logo: ComponentWithConfig<Config> = ({ config, className = '' }) => {
  const { link, title, src, logoIcon } = config;
  const authData = useSelectAuthData();

  const handleClick = () => {
    const ga = googleAnalytics();
    ga.dispatch({
      event: ga.event.webButton,
      eventParam: {
        event_name: 'logo_click',
        userId: authData?.userId
      },
      event_options: {
        element: 'logo',
        event_category: 'web_click'
      }
    });
  };
  return (
    <UILogo
      title={title}
      src={src}
      link={link}
      iconName={logoIcon}
      className={className}
      onClick={handleClick}
    />
  );
};

export default Logo;
