import React from 'react';

import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FLEX_WRAP, FlexBox } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentServiceData from '../../../hooks/usePaymentServiceData';

import Item from './components/Item';

import styles from './index.module.css';

type Props = {
  setDepositSystem: React.Dispatch<React.SetStateAction<string | any>>;
  serviceType: SERVICE_TYPES;
  activeSystem?: string;
  isMobile?: boolean;
  imageFromCms?: boolean;
};

const CardSystem: Component<Props> = ({
  setDepositSystem,
  serviceType,
  activeSystem,
  isMobile = false,
  imageFromCms = false
}) => {
  const { isFetching, listData } = usePaymentServiceData(serviceType);

  if (isFetching) {
    return null;
  }

  return (
    <FlexBox
      wrap={FLEX_WRAP.wrap}
      align={FLEX_ALIGN.start}
      justify={FLEX_JUSTIFY.start}
      className={isMobile ? styles.mobileWrapper : styles.wrapper}
    >
      {listData.map((id) => (
        <Item
          serviceType={serviceType}
          imageFromCms={imageFromCms}
          id={id}
          className={`${styles.item} ${
            id === activeSystem ? styles.active : ''
          }`}
          key={id}
          onClick={setDepositSystem}
        />
      ))}
    </FlexBox>
  );
};

export default CardSystem;
