import { TGamesResponse, TNormalizedGame } from '../../../types';
import normalizeGame from '../../../utils/normalizeGame';
import getGamesRequest from '../query';

const getGamesByIds = () => ({
  query: getGamesRequest,
  transformResponse: (response: TGamesResponse): TNormalizedGame[] =>
    (response?.games || []).map(normalizeGame)
});

export default getGamesByIds;
