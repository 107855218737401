import React from 'react';

import { Component } from '@monorepo/type';

import styles from './index.module.css';

type Props = {
  count?: string | number;
};

const MenuCount: Component<Props> = ({ count, className = '' }) =>
  count ? <div className={`${styles.count} ${className}`}>{count}</div> : null;

export default MenuCount;
