import React from 'react';

import { CustomFC } from '@monorepo/type';

import useNotificationState from '../../hooks/useNotificationState';
import { TNotificationProps } from '../../types';

import styles from './index.module.css';

const CustomNotification: CustomFC<TNotificationProps> = ({
  handleMouseEnter,
  handleMouseLeave
}) => {
  const { message } = useNotificationState<React.ReactFragment>();

  return (
    <div
      className={styles.wrap}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {
        // message can get a react fragment here
        message
      }
    </div>
  );
};

export default CustomNotification;
