import {
  TGetSportsRecommendedIdsResponse,
  TSportsRecommendedItem
} from '../../../types';

const getSportsRecommendedGamesIdsAdapter = ({
  data
}: TGetSportsRecommendedIdsResponse): TSportsRecommendedItem[] => data;

export default getSportsRecommendedGamesIdsAdapter;
