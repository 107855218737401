import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const EditProfileMain = lazy(() => import('./EditProfileMain'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <EditProfileMain config={config} />
  </Suspense>
);
