import React from 'react';
import { Link } from 'react-router-dom';

import { SvgIcon } from '@monorepo/icons';
import { Component } from '@monorepo/type';

import styles from './index.module.css';

type TProp = {
  link?: string;
  title?: string;
  src?: string;
  iconName?: string;
  onClick?: () => void;
};
const Logo: Component<TProp> = ({
  link = '/',
  title = '',
  className = '',
  src = '',
  iconName = '',
  onClick
}) => {
  const img = src ? (
    <img src={src} alt={title} className={styles.logo} />
  ) : (
    <SvgIcon name={iconName} className={styles.logo} />
  );
  return link ? (
    <Link
      to={link}
      title={title}
      className={`${styles.link} ${className}`}
      onClick={onClick}
    >
      {img}
    </Link>
  ) : (
    img
  );
};

export default Logo;
