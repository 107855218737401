import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const CasinoTournamentsFilters = lazy(
  () => import('./CasinoTournamentsFilters')
);

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <CasinoTournamentsFilters config={config} />
  </Suspense>
);
