import React from 'react';

import { useGametaRedirect } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';

import { TAdditional } from '../../../../types';

import styles from './index.module.css';

type Props = {
  additional: TAdditional;
};

const GametaButton = ({ additional }: Props) => {
  const { t } = useI18n(NAMESPACES.DYNAMIC);
  const { checkMeta } = useGametaRedirect();

  return (
    <Button
      intent={BUTTON_INTENT.transparent}
      className={styles.gametaButton}
      onClick={() => checkMeta(additional?.link)}
    >
      {t(`navigation.${additional.title.toLowerCase()}`)}
    </Button>
  );
};
export default GametaButton;
