import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Carousel, SliderShadows } from '@monorepo/ui-kit';

import GameSkeleton from '../GameSkeleton';
import HeaderSkeleton from '../SliderHeader/HeaderSkeleton';

import styles from './index.module.css';

const GamesSliderSkeleton = ({
  isWithButtons = false,
  isViewAll = true,
  limit = 10,
  rows = 1
}) => {
  const slides = createSkeletonArray(limit).map((i) => ({
    id: i,
    component: () => <GameSkeleton />
  }));

  return (
    <div className={styles.wrapper}>
      <HeaderSkeleton isViewAll={isViewAll} className={styles.header} />
      <SliderShadows className={styles.container} isWithNext={isWithButtons}>
        <Carousel
          slides={slides}
          className={`${styles.slider} ${styles[`rows_${rows}`]}`}
          slideClassName={styles.slide}
          params={{
            grid: {
              rows
            },
            slidesPerView: 'auto',
            spaceBetween: 12,
            freeMode: true,
            lazy: true
          }}
        />
      </SliderShadows>
    </div>
  );
};

export default GamesSliderSkeleton;
