import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useMakeCashOutMutation } from '../../store/sportsbook';

type TUserData = {
  price: number;
  id: number;
  mode: number;
  amountOut: number | string;
};

const useMakeCashOut = () => {
  const [makeCashOut, { isLoading, isError }] = useMakeCashOutMutation();
  const { successNotification, errorNotification } = useNotifications();
  const { t } = useI18n(NAMESPACES.FORMS);

  const onCashOut = async (userData: TUserData) => {
    const { id, price, mode, amountOut } = userData;
    const { error } = await makeCashOut({
      id,
      price,
      mode,
      amountOut
    });

    if (error) {
      const code = Number(error?.code);
      if (code) {
        errorNotification(
          t(
            `error.${code < 0 ? code * -1 : code}`,
            t('sportsbook.cash_out.reject'),
            { ns: NAMESPACES.FORMS }
          )
        );
      } else {
        errorNotification(t('sportsbook.cash_out.reject'));
      }
    } else {
      successNotification(t('sportsbook.cash_out.success'));
    }
  };

  return { onCashOut, isLoading, isError };
};

export default useMakeCashOut;
