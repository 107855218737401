import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SEARCH_STRING_PARAMS, useSearchString } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

import { AUTHENTICATION_STATUS } from '../../constants';
import { useLoginMutation } from '../../store/user';
import { TAuthData, TLoginRequest } from '../../types';
import useLocalActions from '../useLocalActions';

type TOptions = {
  onSuccess?: (data: TAuthData) => void;
  onError?: (error: boolean) => void;
  redirectTo?: string;
};

const useLogin = (options: TOptions = {}) => {
  const { onSuccess, onError, redirectTo } = options;
  const { t } = useI18n(NAMESPACES.FORMS);
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { home = '/' } = generalRoutes || {};
  const { getParamByName } = useSearchString();
  const [loginMutation, { isLoading, isError }] = useLoginMutation();
  const { errorNotification } = useNotifications();
  const { setLoginTime } = useLocalActions();

  const handleRedirect = useCallback(() => {
    const backPathParam = SEARCH_STRING_PARAMS.backPath;
    const redirectUrl = getParamByName(SEARCH_STRING_PARAMS.redirectUrl, false);
    const backPath = getParamByName(backPathParam, false);
    const redirectUrlFull = backPath
      ? `${redirectUrl}&${backPathParam}=${backPath}`
      : redirectUrl;
    return navigate(redirectUrlFull || redirectTo || home);
  }, [getParamByName, navigate, redirectTo, home]);

  const handleSuccess = useCallback(
    (data) => {
      onSuccess?.(data);
      setLoginTime(new Date().toString());
      if (
        data?.authenticationStatus !== AUTHENTICATION_STATUS.NEED_AUTHENTICATION
      ) {
        handleRedirect();
      }
    },
    [onSuccess, handleRedirect, setLoginTime]
  );

  const handleError = useCallback(
    (error) => {
      onError?.(error);
      errorNotification(t(`error.${error.status}`));
    },
    [onError, errorNotification, t]
  );

  const onLogin = useCallback(
    async ({
      login,
      password,
      remember,
      gRecaptchaResponse
    }: TLoginRequest) => {
      const { data, error } = await loginMutation({
        login,
        password,
        remember,
        gRecaptchaResponse
      });
      if (error) {
        handleError(error);
      } else {
        handleSuccess(data);
        return data;
      }
    },
    [loginMutation, handleSuccess, handleError]
  );

  return { onLogin, isLoading, isError, handleRedirect };
};

export default useLogin;
