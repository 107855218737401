import React from 'react';

import { classNameProp, CustomFC } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox, Skeleton, UI_SIZES } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  isViewAll?: boolean;
} & classNameProp;

const Header: CustomFC<Props> = ({ isViewAll = false, className = '' }) => (
  <FlexBox
    className={`${styles.header} ${className}`}
    align={FLEX_ALIGN.center}
    mb={UI_SIZES.xl}
  >
    <Skeleton className={`${styles.title} ${styles.skeleton}`} />
    {isViewAll && (
      <Skeleton className={`${styles.viewAll} ${styles.skeleton}`} />
    )}
  </FlexBox>
);

export default Header;
