import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const RestorePasswordSuccess = lazy(() => import('./RestorePasswordSuccess'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <RestorePasswordSuccess config={config} />
  </Suspense>
);
