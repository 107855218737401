import { TFunction } from 'i18next';

const formatPaymentError = (
  t: TFunction,
  defaultKey: string,
  data?: Record<string, any>
) => {
  if (!data) {
    return t(defaultKey);
  }
  const { error, error_code: errorCode } = data;
  const code = Number(errorCode);
  if (code === 99 && error && typeof error === 'string') {
    return `${t('error.99')}. ${error}`;
  }
  if (code) {
    return t(`error.${code < 0 ? code * -1 : code}`, defaultKey);
  }
  return t(defaultKey);
};

export default formatPaymentError;
