import React from 'react';

import { copyClipBoard } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { CustomFC } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type ClipBoardCopyProps = {
  toCopy: any;
  className?: string;
  onClick?: () => void;
};

const ClipBoardCopy: CustomFC<ClipBoardCopyProps> = ({
  toCopy,
  className = '',
  onClick,
  children
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { successNotification } = useNotifications();

  const clickToCopy = () => {
    copyClipBoard(toCopy);
    successNotification(t('message.copy.successfull'));
    if (onClick) {
      onClick();
    }
  };

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      onClick={clickToCopy}
      className={`${styles.copy} ${className}`}
    >
      {children}
    </FlexBox>
  );
};

export default ClipBoardCopy;
