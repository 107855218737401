import get from 'lodash/get';

export const normalizeBetResponse = (rawResponse: any) => {
  const game = get(rawResponse, 'data.game', null);
  const subid = get(rawResponse, 'subid', '');

  return {
    game,
    subid
  };
};
