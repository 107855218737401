import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { classNameProp, CustomFC, TId, TOptionItem } from '@monorepo/type';
import { Checkbox, FLEX_DIRECTION, FlexBox, Radio } from '@monorepo/ui-kit';

import SearchInput from '../SearchInput';

import searchIntersections from './utils/search';
import toggleCheck from './utils/toggleCheck';

import styles from './index.module.css';

type Props = {
  id: string;
  items: TOptionItem[];
  defaultChecked?: TId | Array<TId>;
  onChange: (value: any) => void;
  isWithSearch?: boolean;
  listClassName?: string;
  onSearch?: (
    query: string,
    initialList?: Array<TOptionItem>
  ) => Array<TOptionItem> | Promise<Array<TOptionItem>>;
  isMultiselect?: boolean;
  itemClass?: string;
} & classNameProp;

const FilterList: CustomFC<Props> = ({
  id,
  items,
  defaultChecked = [],
  isMultiselect = true,
  onChange,
  isWithSearch = true,
  onSearch = searchIntersections,
  className = '',
  listClassName = '',
  itemClass = ''
}) => {
  const { t } = useI18n(NAMESPACES.CASINO);
  const Component = isMultiselect ? Checkbox : Radio;
  const [list, setList] = useState(items || []);
  const [query, setQuery] = useState('');
  const [checked, setChecked] = useState(
    Array.isArray(defaultChecked) ? defaultChecked : [defaultChecked]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newChecked = isMultiselect
        ? toggleCheck(checked.filter(Boolean), e.target.value)
        : [e.target.value];
      onChange(isEmpty(newChecked) ? null : newChecked);
    },
    [isMultiselect, onChange, checked]
  );

  const search = useCallback(
    async (itemsList, queryString) => {
      const newList = await onSearch(queryString, itemsList);
      setList(newList);
    },
    [setList]
  );

  const handleSearch = useCallback(
    (e) => {
      setQuery(e.target.value);
    },
    [setQuery]
  );

  useEffect(() => {
    search(items, query);
  }, [items, query]);

  useEffect(() => {
    setChecked(
      Array.isArray(defaultChecked) ? defaultChecked : [defaultChecked]
    );
  }, [defaultChecked]);

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.wrap} ${className}`}
    >
      {isWithSearch && (
        <SearchInput
          placeholder={t('filter.search.placeholder')}
          className={styles.search}
          onChange={handleSearch}
        />
      )}
      <div className={`${styles.list} ${listClassName}`}>
        {list.map(({ value, key, ...props }) => (
          <Component
            key={key || `${id}-${value}`}
            id={key || `${id}-${value}`}
            className={`${styles.item} ${itemClass}`}
            value={value}
            checked={checked.includes(value)}
            onChange={handleChange}
            {...props}
          />
        ))}
      </div>
    </FlexBox>
  );
};

export default FilterList;
