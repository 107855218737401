import { useTypedSelector } from '@monorepo/redux';

const getIsLimitVisited = (state: any) =>
  state.userNotifications.isLimitVisited;

export const useSelectIsLimitVisited = () =>
  useTypedSelector(getIsLimitVisited);

const getTimeForEndSMSTimer = (state: any) =>
  state.userNotifications.timeForEndSMSTimer;

export const useSelectTimeForEndSMSTimer = () =>
  useTypedSelector(getTimeForEndSMSTimer);

const getTimeLeftToVisitLimit = (state: any) =>
  state.userNotifications.timeForLimitNotification;

export const useSelectTimeLeftToVisitLimit = () =>
  useTypedSelector(getTimeLeftToVisitLimit);

const getTimeToRealityCheckNotification = (state: any) =>
  state.userNotifications.timeToRealityCheckNotification;

export const useSelectTimeToRealityCheckNotification = () =>
  useTypedSelector(getTimeToRealityCheckNotification);

const getTimeLeftToLogoutFreeze = (state: any) =>
  state.userNotifications.timeForLogoutFreeze;

export const useSelectTimeLeftToLogoutFreeze = () =>
  useTypedSelector(getTimeLeftToLogoutFreeze);

const getTimeLeftToVisitVerify = (state: any) =>
  state.userNotifications.timeForVerifyNotification;

export const useSelectTimeLeftToVisitVerify = () =>
  useTypedSelector(getTimeLeftToVisitVerify);

const getRemainTimeForVerify = (state: any) =>
  state.userNotifications.remainTimeForVerify;

export const useSelectRemainTimeForVerify = () =>
  useTypedSelector(getRemainTimeForVerify);

const getCookiesSettings = (state: any) => state.userNotifications.cookies;

export const useSelectCookiesSettings = () =>
  useTypedSelector(getCookiesSettings);

const getIsShowCookiesSettings = (state: any) =>
  state.userNotifications.isShowCookiesSettings;

export const useIsShowCookiesSettings = () =>
  useTypedSelector(getIsShowCookiesSettings);
