import React from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

const PromotionPlaceholder: Component = ({ className = '' }) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    justify={FLEX_JUSTIFY.center}
    className={`${styles.wrap} ${className}`}
  >
    <Icon name="wall_0" className={styles.icon} />
  </FlexBox>
);

export default PromotionPlaceholder;
