import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle
} from 'react';

import { classNameProp } from '@monorepo/type';

import BaseRecaptcha, {
  Props as BaseRecaptchaProps
} from '../../common/BaseRecaptcha';
import useRecaptcha from '../../hooks/useRecaptcha';
import { TRecaptchaAction, TRecaptchaHandlers } from '../../types';

type Props = {
  action: TRecaptchaAction;
  onValidated?: (token: string | boolean) => Promise<void> | void;
  isManual?: boolean;
  isV2Only?: boolean;
} & classNameProp &
  Pick<BaseRecaptchaProps, 'theme'>;

const Recaptcha: ForwardRefRenderFunction<TRecaptchaHandlers, Props> = (
  { action, onValidated, isManual, isV2Only, theme, className },
  handlersRef
) => {
  const {
    siteKey,
    recaptchaRef,
    v2Key,
    isValidating,
    onVerify,
    isEnabled,
    executeCaptcha,
    setV2Key,
    triggerManualValidation
  } = useRecaptcha(isManual, isV2Only, onValidated);

  useImperativeHandle(
    handlersRef,
    () => ({
      validateRecaptcha: () => triggerManualValidation(action),
      setV2Key,
      v2Key,
      isValidating
    }),
    [isValidating, v2Key, triggerManualValidation, setV2Key]
  );

  return (
    <>
      {isEnabled(action) && (
        <BaseRecaptcha
          sitekey={v2Key || siteKey}
          ref={recaptchaRef}
          onVerify={onVerify(action)}
          onRender={executeCaptcha}
          size={v2Key ? 'normal' : 'invisible'}
          executeData={v2Key ? {} : { action }}
          theme={theme}
          className={className}
        />
      )}
    </>
  );
};

export default forwardRef(Recaptcha);
