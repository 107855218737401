import React from 'react';

import { Component } from '@monorepo/type';

import { FIELD_TONE, TEXT_SIZE } from '../../../constants';
import Box from '../../Box';
import { StatusMark } from '../../../index';

import styles from './index.module.css';

const NotificationTitle: Component = ({
  className = '',
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small,
  children,
  isRead = false
}) => (
  <Box
    className={`${styles[toneType]} ${styles[textSize]} ${styles.title} ${className}`}
  >
    {!isRead && (
      <StatusMark
        className={styles.mark}
        toneType={FIELD_TONE.custom}
        isHaveStatus
        isActive={!!isRead}
      />
    )}
    <Box className={`${!isRead ? styles.markTitle : ''}`}>{children}</Box>
  </Box>
);

export default NotificationTitle;
