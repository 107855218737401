import { useMemo } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';

import { GAME_TYPES_MAP, POPULAR_ALIAS } from '../../constants';
import { TLineUrlParameters } from '../../types';

const useSportsURLParams = (): TLineUrlParameters => {
  const { generalRoutes } = useSiteSettings();
  const { prematch, live, preMatchTop } = generalRoutes;
  const { sportAlias, competitionId, regionAlias, gameId } =
    useParams<Record<string, string | undefined>>();
  const { searchParams } = useSearchParamsWithParsing();
  const { time = '', search } = searchParams;

  const matchLive = useMatch({ path: live, end: false });
  const isLive = Boolean(matchLive);
  const matchPrematch = useMatch({ path: prematch, end: false });
  const isPrematch = Boolean(matchPrematch);
  const matchPreMatchTop = useMatch({ path: preMatchTop, end: false });
  const isPrematchTop = Boolean(matchPreMatchTop);
  const rootPathData = isLive ? live : prematch;
  const path = isPrematchTop ? preMatchTop : rootPathData;

  const matchPopular = useMatch({
    path: `${isLive ? live : prematch}/${POPULAR_ALIAS}`,
    end: true
  });
  const isPopular = Boolean(matchPopular);

  const gameType = useMemo(() => {
    if (isLive) {
      return GAME_TYPES_MAP.LIVE;
    }
    if (isPrematch) {
      return GAME_TYPES_MAP.PREMATCH;
    }
    return undefined;
  }, [isLive, isPrematch]);

  return useMemo(
    () => ({
      gameType,
      sportAlias,
      regionAlias,
      competitionId: competitionId ? Number(competitionId) : undefined,
      time: time ? time.toString() : time,
      isPopular,
      isPrematch,
      isLive,
      rootPath: path,
      search: search ? `${search}` : '',
      gameId
    }),
    [
      gameType,
      sportAlias,
      regionAlias,
      competitionId,
      time,
      isPopular,
      isPrematch,
      isLive,
      search,
      gameId
    ]
  );
};

export default useSportsURLParams;
