import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

import getRecentPlayedCasinoGames from './api/getRecentPlayedCasinoGames';

const casinoLastPlayedHost = process.env.REACT_APP_LAST_PLAYED_HOST;

export const casinoLastGamesApi = createApi({
  reducerPath: 'casinoLastGamesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${casinoLastPlayedHost}/api/v1/recent-games/`
  }),
  endpoints: (builder) => ({
    getRecentPlayedCasinoGames: builder.query(getRecentPlayedCasinoGames())
  })
});

export const { useGetRecentPlayedCasinoGamesQuery } = casinoLastGamesApi;
