import React, { useEffect, useMemo, useState } from 'react';

import { CustomFC } from '@monorepo/type';

import useXMPPConnect from '../../hooks/useXMPPConnect';
import XMPPContext from '../../store/context';

const XMPPProvider: CustomFC = ({ children }) => {
  const xmppInstance = useXMPPConnect();
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [isReconnected, setIsReconnected] = useState<boolean>(false);

  const isServerOnline = useMemo(
    () => status === 'online' || (isReconnected && status === 'open'),
    [status, isReconnected]
  );

  useEffect(() => {
    const callback = (callbackStatus?: string) => setStatus(callbackStatus);
    const reconnectCallback = () => setIsReconnected(true);
    const reconnectingCallback = () => setIsReconnected(false);

    xmppInstance.createListener('status', callback);
    xmppInstance.createReconnectListener('reconnected', reconnectCallback);
    xmppInstance.createReconnectListener('reconnecting', reconnectingCallback);

    return () => {
      xmppInstance.removeListener('status', callback);
      xmppInstance.removeReconnectListener('reconnected', reconnectCallback);
      xmppInstance.removeReconnectListener(
        'reconnecting',
        reconnectingCallback
      );
    };
  }, []);

  return (
    <XMPPContext.Provider value={{ xmppInstance, isServerOnline }}>
      {children}
    </XMPPContext.Provider>
  );
};

export default XMPPProvider;
