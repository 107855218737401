import React from 'react';

import { classNameProp, CustomFC } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import ComponentsArray from '../../ComponentsArray';

type TColumnProps = {
  column: string[];
} & classNameProp;

const Column: CustomFC<TColumnProps> = ({ column, className = '' }) => (
  <FlexBox direction={FLEX_DIRECTION.column} className={className}>
    <ComponentsArray componentIds={column} />
  </FlexBox>
);

export default Column;
