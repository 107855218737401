import { BaseQueryResult } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { SESSION_SOURCE } from '../../../constants';
import { TSessionSettings } from '../../../types';
import getSessionAdapter from '../adapters/getSession';

const requestSession = () => ({
  query: ({
    siteId,
    language,
    source = SESSION_SOURCE.SWARM_SOURCE,
    afec
  }: {
    siteId: any;
    language: string;
    source?: number;
    afec?: string;
  }) => ({
    body: {
      command: 'request_session',
      params: {
        site_id: siteId,
        language,
        source,
        afec
      }
    },
    timeout: 1000
  }),
  transformResponse(response: BaseQueryResult<any>): TSessionSettings {
    return getSessionAdapter(response);
  }
});
export default requestSession;
