import React, { useMemo } from 'react';

import { Component } from '@monorepo/type';

import { MARKET_DISPLAY_KEYS } from '../../constants';
import { isSportWithDraw } from '../../utils';
import LineEmptyOutcomeList from '../LineEmptyOutcomeList';
import LineMarketBase from '../LineMarketBase';

type Props = {
  isSuspended?: boolean;
  isFetching?: boolean;
  displayKey: string;
  sportAlias: string;
};

const LineEmptyMarketsList: Component<Props> = ({
  isFetching,
  isSuspended,
  displayKey,
  sportAlias,
  className = ''
}) => {
  const isWinner = useMemo(
    () => displayKey === MARKET_DISPLAY_KEYS.WINNER,
    [displayKey]
  );
  const selectionCount = useMemo(() => {
    if (isWinner && isSportWithDraw(sportAlias)) {
      return 3;
    }
    return 2;
  }, [isWinner, sportAlias]);

  return (
    <>
      {!isWinner && <LineMarketBase base="-" displayKey={displayKey} />}
      <LineEmptyOutcomeList
        isFetching={isFetching}
        isSuspended={isSuspended}
        selectionCount={selectionCount}
        className={className}
      />
    </>
  );
};

export default LineEmptyMarketsList;
