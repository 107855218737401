import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { classNameProp, CustomFC, TOptionItem } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_WRAP, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  items: TOptionItem[];
  onItemClick: (value: any) => void;
  onClearAll: () => void;
} & classNameProp;

const SelectedFilters: CustomFC<Props> = ({
  items,
  onItemClick,
  onClearAll,
  className = ''
}) => {
  const { t } = useI18n(NAMESPACES.CASINO);

  if (!items.length) {
    return null;
  }

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      wrap={FLEX_WRAP.wrap}
      className={`${styles.wrap} ${className}`}
    >
      {items.map(({ value, key, label }) => (
        <FlexBox
          align={FLEX_ALIGN.center}
          key={key || value}
          onClick={() => onItemClick(value)}
          className={styles.item}
        >
          {label}
          <Icon name="close_0" className={styles.icon} title="" />
        </FlexBox>
      ))}
      <FlexBox
        align={FLEX_ALIGN.center}
        onClick={onClearAll}
        className={`${styles.item} ${styles.clear}`}
      >
        {t('filter.clear')}
        <Icon name="bin_0" title={t('filter.clear')} className={styles.icon} />
      </FlexBox>
    </FlexBox>
  );
};

export default SelectedFilters;
