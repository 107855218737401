import { TSessionSettings } from '../../../../types';

const getSession = (initialData): TSessionSettings => ({
  isSessionReady: initialData === null || Boolean(initialData.sid),
  recaptchaEnabled: initialData.recaptcha_enabled,
  recaptchaVersion: initialData.recaptcha_version,
  recaptchaActions: initialData.recaptcha_actions || [],
  siteKey: initialData.site_key,
  swarmSessionId: initialData.sid
});

export default getSession;
