import { useMemo, useState } from 'react';

const useHover = () => {
  const [showInfoButton, setShowInfoButton] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      onMouseOver() {
        setShowInfoButton(true);
      },
      onTouchEnd() {
        setShowInfoButton(true);
      },
      onFocus() {
        setShowInfoButton(true);
      },
      onClick() {
        setShowInfoButton(true);
      }
    }),
    []
  );

  return { showInfoButton, eventHandlers };
};

export default useHover;
