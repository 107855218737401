import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import ClipBoardCopy from '../../../ClipBoardCopy';

import styles from './index.module.css';

type Props = {
  title: string;
  url: string;
  onClosePopper: () => void;
};
const PopperBlock: Component<Props> = ({
  title = '',
  url = '',
  onClosePopper
}) => {
  const { generalRoutes } = useSiteSettings();
  const { personalChats } = generalRoutes;
  const navigate = useNavigate();
  const toNavigate = () => navigate(personalChats);

  return (
    <FlexBox className={styles.root} align={FLEX_ALIGN.center}>
      <FlexBox
        className={styles.icon}
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        onClick={onClosePopper}
      >
        <FacebookShareButton
          url={url}
          quote={title}
          hashtag="#allinn"
          className={styles.button}
        >
          <Icon name="facebook" />
        </FacebookShareButton>
      </FlexBox>
      <FlexBox
        className={styles.icon}
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        onClick={onClosePopper}
      >
        <TwitterShareButton url={url} title={title} className={styles.button}>
          <Icon name="twitter" />
        </TwitterShareButton>
      </FlexBox>
      <FlexBox
        className={styles.icon}
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        onClick={onClosePopper}
      >
        <ClipBoardCopy
          toCopy={url}
          className={styles.button}
          onClick={toNavigate}
        >
          <Icon name="copy_outline" />
        </ClipBoardCopy>
      </FlexBox>
    </FlexBox>
  );
};

export default PopperBlock;
