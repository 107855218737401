import { TSendSMSRequest } from '../../../types';

const sendSms = () => ({
  query: ({ actionType, phoneNumber }: TSendSMSRequest) => ({
    body: {
      command: 'send_sms_to_phone_number',
      params: {
        action_type: actionType,
        phone_number: phoneNumber
      }
    }
  })
});
export default sendSms;
