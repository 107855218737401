import { TGetMarketsRequest } from '../../types';
import useGetMarkets from '../useGetMarkets';
import useLineParameters from '../useLineParameters';

const useGetLineMarkets = (
  args: Partial<TGetMarketsRequest> = {},
  options = {}
) => {
  const { gameType, competitionId, time, isPopular, marketType, gameIds } =
    useLineParameters();

  return useGetMarkets(
    { gameType, competitionId, time, isPopular, marketType, gameIds, ...args },
    options
  );
};

export default useGetLineMarkets;
