export const VALIDATION_ERROR_CODES = {
  INCORRECT_PASSWORD: '-1013',
  INCORRECT_PHONE: '-1012',
  DUPLICATE_PHONE: '-1127',
  ERROR_SEND_SMS: '-1014',
  DUPLICATE_LOGIN: '-1118',
  DUPLICATE_EMAIL: '-1119',
  PASSWORD_SAME_AS_LOGIN: '-1010',
  DUPLICATE_DOCUMENT_NUMBER: '-1123',
  NICKNAME_EXIST: '-2049',
  INCORRECT_OLD_PASSWORD: '-1005',
  INCORRECT_OLD_PASSWORD_2: '1005'
};

export const FIELD_TO_ERROR_MAP = {
  [VALIDATION_ERROR_CODES.DUPLICATE_EMAIL]: 'email',
  [VALIDATION_ERROR_CODES.DUPLICATE_PHONE]: 'phone',
  [VALIDATION_ERROR_CODES.DUPLICATE_LOGIN]: 'email',
  [VALIDATION_ERROR_CODES.PASSWORD_SAME_AS_LOGIN]: 'password',
  [VALIDATION_ERROR_CODES.NICKNAME_EXIST]: 'nickName',
  [VALIDATION_ERROR_CODES.INCORRECT_OLD_PASSWORD]: 'password',
  [VALIDATION_ERROR_CODES.INCORRECT_OLD_PASSWORD_2]: 'password',
  [VALIDATION_ERROR_CODES.INCORRECT_PASSWORD]: 'password'
};

export const AUTH_QUERY_KEYS = {
  USER: 'user',
  BONUSES: 'bonuses',
  DOCUMENTS: 'documents',
  FREESPINS: 'freespins',
  FREESPINS_GAMES: 'freespins_games',
  JWT: 'jwt'
};
export const WALLETS_KEYS = {
  BALANCES: 'balances'
};

export const MESSAGES_QUERY_KEYS = {
  INCOMING: 'incoming',
  OUTCOMING: 'outcoming'
};

export const MESSAGES_TYPES = {
  INCOMING: 0,
  OUTGOING: 1
};

export const MESSAGES_TYPES_MAP = {
  [MESSAGES_TYPES.INCOMING]: 'incoming',
  [MESSAGES_TYPES.OUTGOING]: 'outgoing'
};

export const LOGOUT_FREEZE_SOURCE = 4;

export const PROFILE_EXCLUDE_TYPE = {
  TIMEOUT: 1,
  SELF_EXCLUSION: 2,
  SPORTSBOOK_TEMPORARY_BLOCK: 3,
  SPORTSBOOK_BLOCK_ACCOUNT_CLOSURE: 4,
  ACCOUNT_CLOSURE: 5,
  BETTING_BLOCK: 6,
  BETTING_BLOCK_UK: 7
};

export const LOGOUT_EXCLUDE_TYPES = [
  PROFILE_EXCLUDE_TYPE.TIMEOUT,
  PROFILE_EXCLUDE_TYPE.SELF_EXCLUSION,
  PROFILE_EXCLUDE_TYPE.SPORTSBOOK_BLOCK_ACCOUNT_CLOSURE,
  PROFILE_EXCLUDE_TYPE.BETTING_BLOCK_UK
];

export const PICTURE_URL_PARAMS =
  'sizes=32,64,132,168&source=avatars&async=false';

export const ALL_PHONE_CODE = [
  '+1',
  '+20',
  '+210',
  '+211',
  '+212',
  '+213',
  '+216',
  '+218',
  '+220',
  '+221',
  '+222',
  '+223',
  '+224',
  '+225',
  '+226',
  '+227',
  '+228',
  '+229',
  '+230',
  '+231',
  '+232',
  '+233',
  '+234',
  '+235',
  '+236',
  '+237',
  '+238',
  '+239',
  '+240',
  '+241',
  '+242',
  '+243',
  '+244',
  '+245',
  '+246',
  '+247',
  '+248',
  '+249',
  '+250',
  '+251',
  '+252',
  '+253',
  '+254',
  '+255',
  '+256',
  '+257',
  '+258',
  '+260',
  '+261',
  '+262',
  '+263',
  '+264',
  '+265',
  '+266',
  '+267',
  '+268',
  '+269',
  '+27',
  '+290',
  '+291',
  '+297',
  '+298',
  '+299',
  '+30',
  '+31',
  '+32',
  '+33',
  '+34',
  '+350',
  '+351',
  '+352',
  '+353',
  '+354',
  '+355',
  '+356',
  '+357',
  '+358',
  '+359',
  '+36',
  '+370',
  '+371',
  '+372',
  '+373',
  '+374',
  '+375',
  '+376',
  '+377',
  '+378',
  '+379',
  '+380',
  '+381',
  '+382',
  '+383',
  '+385',
  '+386',
  '+387',
  '+389',
  '+39',
  '+40',
  '+41',
  '+420',
  '+421',
  '+423',
  '+43',
  '+44',
  '+45',
  '+46',
  '+47',
  '+48',
  '+49',
  '+500',
  '+501',
  '+502',
  '+503',
  '+504',
  '+505',
  '+506',
  '+507',
  '+508',
  '+509',
  '+51',
  '+52',
  '+53',
  '+54',
  '+55',
  '+56',
  '+57',
  '+58',
  '+590',
  '+591',
  '+592',
  '+593',
  '+594',
  '+595',
  '+596',
  '+597',
  '+598',
  '+599',
  '+60',
  '+61',
  '+62',
  '+63',
  '+64',
  '+65',
  '+66',
  '+670',
  '+672',
  '+673',
  '+674',
  '+675',
  '+676',
  '+677',
  '+678',
  '+679',
  '+680',
  '+681',
  '+682',
  '+683',
  '+685',
  '+686',
  '+687',
  '+688',
  '+689',
  '+690',
  '+691',
  '+692',
  '+7',
  '+81',
  '+82',
  '+84',
  '+850',
  '+852',
  '+853',
  '+855',
  '+856',
  '+86',
  '+870',
  '+880',
  '+881',
  '+886',
  '+90',
  '+91',
  '+92',
  '+93',
  '+94',
  '+95',
  '+960',
  '+961',
  '+962',
  '+963',
  '+964',
  '+965',
  '+966',
  '+967',
  '+968',
  '+970',
  '+971',
  '+972',
  '+973',
  '+974',
  '+975',
  '+976',
  '+977',
  '+98',
  '+992',
  '+993',
  '+994',
  '+995',
  '+996',
  '+997',
  '+998'
];

export const ICON_STATUS_DOCUMENT = {
  // 0 New
  0: 'won-0',
  // 1 Verified
  1: 'won-0',
  // 2 Rejected
  2: 'canceled-0',
  // 3 In Progress
  3: 'canceled-0'
};

export const DEFAULT_EXTERNAL_DATA_TELEGRAM = {
  telegram_id: '',
  phone: '',
  first_name: '',
  last_name: '',
  username: ''
};

export const SESSION_SOURCE = {
  SWARM_SOURCE: 42,
  TELEGRAM: 104
};

export const BONUS_DATA = {
  BONUS_REQUEST: 'Bonus request'
};

export const BONUS_TYPE = {
  SPORT_BONUS: 1,
  WAGERING_BONUS: 2,
  MANUAL_BONUS: 3,
  CASH_BONUS: 4,
  FREE_SPIN: 5,
  FREE_BET: 6,
  BONUS_MONEY: 7,
  CLIENT_WAGERING_BONUS_RESPONSE: 8
};

export const BONUS_SOURCE = {
  SPORT_BONUS: 1,
  CASINO_BONUS: 2,
  MANUAL_BONUS: 0
};

export const BONUS_ACCEPTANCE_TYPE = {
  NOT_CLAIMED: 0,
  CLAIMED: 1,
  DEPOSIT: 2
};

export const AUTHENTICATION_STATUS = {
  AUTHENTICATED: 0,
  NEED_AUTHENTICATION: 4
};

export const LOGGER_EVENT = {
  LOG: 'log',
  SYNC: 'sync'
};

export const BONUS_CONFIRMATION_TITLE_TYPE = {
  CLAIM: 'claim',
  CANCEL: 'cancel'
};
