import React, { useCallback } from 'react';
import { memoize } from 'proxy-memoize';

import { Component, TQueryResult } from '@monorepo/type';

import useGetMenuSports from '../../../../hooks/useGetMenuSports';
import { selectSportsBySportId } from '../../../../store/api/selectors';
import { TNormalizedSports } from '../../../../types';
import SportMenuItem from '../SportMenuItem';

type TSportItemProps = {
  sportId: number;
  activeSport: string;
  setSport: (sportAlias: string) => void;
};

const SportItem: Component<TSportItemProps> = ({
  sportId,
  setSport,
  activeSport,
  className = ''
}) => {
  const { data: sport } = useGetMenuSports(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectSportsBySportId(result, sportId)
    }))
  });

  const { alias, name, game: count } = sport;

  const onClick = useCallback(() => {
    setSport(alias);
  }, [alias, setSport]);

  return (
    <SportMenuItem
      isActive={activeSport === alias}
      onClick={onClick}
      iconName={alias?.toLowerCase()}
      name={name}
      count={count}
      className={className}
      alias={alias}
    />
  );
};

export default SportItem;
