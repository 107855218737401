import React, { ComponentProps } from 'react';

import { Logo as CoreLogo } from '@monorepo/common';

import styles from './index.module.css';

const Logo = ({ config }: ComponentProps<typeof CoreLogo>) => (
  <CoreLogo config={config} className={styles.logo} />
);

export default Logo;
