import React, { ElementRef, useRef } from 'react';

import {
  Form,
  InputField,
  maxLengthValidator,
  minLengthValidator,
  SubmitButton
} from '@monorepo/forms';
import { getBrowserFingerprint } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { Box, BUTTON_SIZE, FIELD_TONE } from '@monorepo/ui-kit';

import useLocalActions from '../../../../hooks/useLocalActions';
import { useGetTwoFactorApplyMutation } from '../../../../store/user';

import styles from './index.module.css';

const defaultValues = {
  code: ''
};

const TwoFactorAuthAddForm = ({
  toneType = FIELD_TONE.dark,
  onCustomHandler = () => null
}: {
  toneType?: FIELD_TONE;
  onCustomHandler?: () => void;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const { errorNotification, successNotification } = useNotifications();

  const [fetchCode] = useGetTwoFactorApplyMutation();
  const { setSignData } = useLocalActions();
  const handleSubmit = async () => {
    const formData = formRef.current?.getValues();
    const result = await fetchCode({
      code: formData?.code,
      deviceFingerprint: btoa(getBrowserFingerprint().toString())
    });

    const { error } = result;

    if (error) {
      const code = Number(error);
      errorNotification(
        t(`error.${code < 0 ? code * -1 : code}`, { ns: NAMESPACES.FORMS })
      );
    } else {
      successNotification(t('two.factor.activate.applyed'));
      setSignData(null);
      if (onCustomHandler) {
        onCustomHandler();
      }
    }
  };

  return (
    <Form
      className={styles[toneType]}
      ref={formRef}
      onSubmit={handleSubmit}
      formSettings={{ defaultValues, mode: 'onChange' }}
    >
      <Box className={styles.codeText}>{t('two.factor.code.sended')}</Box>
      <Box className={styles.codeInput}>
        <InputField
          id="code"
          name="code"
          maxLength={6}
          labelClassName={styles.codeLabel}
          placeholder={t('two.factor.code.please.enter')}
          label={t('two.factor.code.enter')}
          toneType={toneType}
          rules={{ ...minLengthValidator(6), ...maxLengthValidator(6) }}
        />
      </Box>
      <Box className={styles.control}>
        <SubmitButton className={styles.button} size={BUTTON_SIZE.h36}>
          {t('two.factor.submit')}
        </SubmitButton>
      </Box>
    </Form>
  );
};
export default TwoFactorAuthAddForm;
