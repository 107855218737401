import debounce from 'lodash/debounce';

type TCallback = (data: any) => void;

const debounceEvents = (
  callback: TCallback,
  delay = 2000,
  isReplace = false
) => {
  let events = [];

  const batch = debounce(
    (eventsBatch) => {
      callback(eventsBatch);
      events = [];
    },
    delay,
    { leading: false }
  );

  return (event: any) => {
    if (isReplace) {
      events = event;
    } else {
      events.push(event);
    }
    batch(events);
  };
};

export default debounceEvents;
