export enum AVATAR_SIZE {
  S = 64,
  L = 168
}

export const AVATAR_UPLOAD_SIZES = [AVATAR_SIZE.S, AVATAR_SIZE.L];

export enum FILE_SOURCE {
  AVATAR = 'avatars',
  UGC = 'ugc'
}
export const BLOG_UPLOAD_SIZES = ['original'];
