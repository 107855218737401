import React from 'react';

import { ComponentsConfigProvider } from '@monorepo/page';

import RegionsMenu from '../../../../common/RegionsMenu';
import SportBackHeader from '../../../../common/SportsBackHeader';
import { POPULAR_ALIAS } from '../../../../constants';
import ExtraItems from '../ExtraItems';
import Wrapper from '../Wrapper';

import styles from '../index.module.css';

const SelectedSportMenu = ({ sport, handleChangeSport, region, rootPath }) => (
  <Wrapper>
    <ComponentsConfigProvider params={{ withIcons: true }}>
      {sport === POPULAR_ALIAS || sport === 'favorite' ? (
        <ExtraItems sport={sport} handleChangeSport={handleChangeSport} />
      ) : (
        <>
          <SportBackHeader
            activeSport={sport}
            className={styles.back}
            handleClick={handleChangeSport}
          />
          <RegionsMenu
            basePath={`${rootPath}/${sport}`}
            activeRegion={region || ''}
            activeSport={sport}
          />
        </>
      )}
    </ComponentsConfigProvider>
  </Wrapper>
);

export default SelectedSportMenu;
