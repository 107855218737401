import pickBy from 'lodash/pickBy';

import { TLineParameters } from '../../types';
import useLineParameters from '../useLineParameters';
import useSportsURLParams from '../useSportsURLParams';

const useSportsParams = (): TLineParameters => {
  const urlParams = useSportsURLParams();
  const lineParams = useLineParameters();

  // remove all undefed values from both, to prevent overriding with undefined
  const urlFilteredParams = pickBy<TLineParameters>(
    urlParams,
    (value) => typeof value !== 'undefined'
  );
  const lineFilteredParams = pickBy<TLineParameters>(
    lineParams,
    (value) => typeof value !== 'undefined'
  );

  return { ...lineFilteredParams, ...urlFilteredParams } as TLineParameters;
};

export default useSportsParams;
