import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper } from 'swiper';

import { useDebounce } from '@monorepo/helpers';
import { CustomFC } from '@monorepo/type';
import { Carousel, PaddingWrapper, SliderShadows } from '@monorepo/ui-kit';

import { TNormalizedGame } from '../../types';
import GameItem from '../GameItem';
import Header from '../SliderHeader';

import styles from './index.module.css';

type TSlider = {
  games: TNormalizedGame[];
  totalCount: number;
  categoryId: string;
  isWithButtons: boolean;
  isViewAll: boolean;
  rows: number;
  categoryName: string;
  isSimpleSlider?: boolean;
  sliderClassName?: string;
  headerClassName?: string;
  itemSliderClassName?: string;
  goToCustomLink?: () => void;
  withTutorials?: boolean;
  enableFavorite?: boolean;
};

const Slider: CustomFC<TSlider> = ({
  games = [],
  totalCount = 0,
  categoryId = '',
  isWithButtons = false,
  isViewAll = false,
  rows = 1,
  categoryName = '',
  isSimpleSlider = false,
  sliderClassName = '',
  headerClassName = '',
  itemSliderClassName = '',
  goToCustomLink,
  withTutorials = false,
  enableFavorite = true
}) => {
  const swiperRef = useRef<Swiper>(null);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const sliderShadowsStyles = isSimpleSlider ? '' : styles.container;
  const sliderIndentsStyles = isSimpleSlider
    ? `${styles.slider} ${sliderClassName}`
    : `${styles.slider} ${sliderClassName} ${styles[`rows_${rows}`]}`;

  const slides = games.map((game: TNormalizedGame) => ({
    id: game.gameId,
    component: () => (
      <GameItem
        game={game}
        withTutorials={withTutorials}
        enableFavorite={enableFavorite}
      />
    )
  }));

  const onNext = useCallback(() => {
    if (swiperRef) {
      swiperRef.current?.slideNext();
    }
  }, [swiperRef.current]);

  const onPrev = useCallback(() => {
    if (swiperRef) {
      swiperRef.current?.slidePrev();
    }
  }, [swiperRef.current]);

  const onSlideChange = useDebounce((swiper: Swiper) => {
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, 500);

  useEffect(() => {
    if (swiperRef.current) {
      const { isBeginning, isEnd: isLastSlide } = swiperRef.current;
      if (isLastSlide && isBeginning) {
        setIsStart(true);
        setIsEnd(true);
      }
    }
  }, [swiperRef.current]);

  return (
    <div className={`${styles.wrapper} ${sliderClassName}`}>
      <PaddingWrapper
        isOnlyMobile
        className={`${styles.header} ${headerClassName}`}
      >
        <Header
          title={categoryName}
          categoryId={categoryId}
          totalCount={totalCount}
          isViewAll={isViewAll}
          isWithButtons={isWithButtons}
          isStart={isStart}
          isEnd={isEnd}
          goToCustomLink={goToCustomLink}
          onPrev={onPrev}
          onNext={onNext}
        />
      </PaddingWrapper>
      <SliderShadows
        className={sliderShadowsStyles}
        isWithPrev={!isStart && isWithButtons}
        isWithNext={!isEnd && isWithButtons}
      >
        <PaddingWrapper isOnlyMobile className={sliderIndentsStyles}>
          <Carousel
            slides={slides}
            slideClassName={`${styles.slide} ${itemSliderClassName}`}
            params={{
              grid: {
                rows
              },
              slidesPerView: 'auto',
              spaceBetween: 12,
              freeMode: {
                enabled: true,
                momentumBounce: false,
                momentumRatio: 0.2,
                minimumVelocity: 0.01,
                momentum: true
              },
              lazy: true,
              onSwiper: (ref) => {
                swiperRef.current = ref;
              },
              onSlideChange,
              onReachEnd: () => {
                setIsStart(false);
                setIsEnd(true);
              },
              onReachBeginning: () => {
                setIsStart(true);
                setIsEnd(false);
              }
            }}
          />
        </PaddingWrapper>
      </SliderShadows>
    </div>
  );
};

export default Slider;
