import { TAmountMaskOptions } from '../../types';

const MASK_SIGN = '_';

const amountFieldToNumber =
  (options: TAmountMaskOptions) =>
  (value: string | number = 0): number => {
    const {
      thousandsSeparatorSymbol,
      includeThousandsSeparator,
      decimalSymbol,
      prefix,
      suffix
    } = options;
    const cleanString = [
      includeThousandsSeparator ? thousandsSeparatorSymbol : '',
      prefix,
      suffix,
      MASK_SIGN
    ].reduce((acc, symbol) => {
      if (symbol) {
        return acc.replaceAll(symbol, '');
      }
      return acc;
    }, value.toString());

    if (decimalSymbol !== '.') {
      return Number(cleanString.replace(decimalSymbol, '.'));
    }

    return Number(cleanString);
  };

export default amountFieldToNumber;
