import React, { useCallback, useEffect, useState } from 'react';

import {
  FILTER_PARAMS,
  usePrevious,
  useSearchParamsWithParsing
} from '@monorepo/helpers';
import { CustomFC } from '@monorepo/type';

import useSportsParams from '../../hooks/useSportsParams';
import SportsMenu from '../SportsMenu';

import SelectedSportMenu from './components/SelectedSportMenu';
import Wrapper from './components/Wrapper';

type TProps = {};

const SportNavigation: CustomFC<TProps> = () => {
  const { sportAlias, regionAlias, rootPath } = useSportsParams();
  const prevSport = usePrevious(sportAlias);
  const [sport, setSport] = useState(sportAlias);
  const [region, setRegion] = useState(regionAlias);
  const { searchParams } = useSearchParamsWithParsing();

  const handleChangeSport = useCallback(
    (newSport: string) => {
      setSport(newSport);
      setRegion(prevSport === newSport ? regionAlias : '');
    },
    [regionAlias]
  );

  useEffect(() => {
    setRegion(regionAlias);
  }, [regionAlias]);

  useEffect(() => {
    if (sportAlias) {
      setSport(sportAlias);
    }
  }, [sportAlias]);

  // for search purposes, clear choosed sport when search query changed
  useEffect(() => {
    if (sport) setSport(sportAlias);
  }, [searchParams[FILTER_PARAMS.search]]);

  if (sport) {
    return (
      <SelectedSportMenu
        sport={sport}
        handleChangeSport={handleChangeSport}
        region={region}
        rootPath={rootPath}
      />
    );
  }

  return (
    <Wrapper>
      <SportsMenu activeSport={sportAlias || ''} setSport={handleChangeSport} />
    </Wrapper>
  );
};

export default SportNavigation;
