import { AUTH_COMPONENT_TYPES, AUTH_COMPONENTS_MAP } from '@monorepo/auth';
import { CASINO_COMPONENTS_MAP } from '@monorepo/casino';
import { CMS_COMPONENTS_MAP } from '@monorepo/cms';
import {
  COMMON_COMPONENT_TYPES,
  COMMON_COMPONENTS_MAP
} from '@monorepo/common';
import { NOTIFICATIONS_COMPONENTS_MAP } from '@monorepo/notification';
import { SOCIAL_COMPONENTS_MAP } from '@monorepo/socials';
import { SPORTS_COMPONENTS_MAP } from '@monorepo/sports';
import { WALLET_COMPONENTS_MAP } from '@monorepo/wallet';

import EditProfileContacts from '../EditProfileContacts';
import EditProfileMain from '../EditProfileMain';
import EditProfilePersonalData from '../EditProfilePersonalData';
import Footer from '../Footer';
import Header from '../Header';
import HeaderAuth from '../HeaderAuth';
import HeaderNavMenu from '../HeaderNavMenu';
import Limits from '../Limits';
import Login from '../Login';
import Logo from '../Logo';
import Registration from '../Registration';

const CUSTOM_TYPES = {
  HEADER_AUTH: 'header_auth',
  HEADER_NAV_MENU: 'header-navigation',
  HEADER: 'header_custom'
};

const componentsMap = {
  ...AUTH_COMPONENTS_MAP,
  ...CMS_COMPONENTS_MAP,
  ...CASINO_COMPONENTS_MAP,
  ...SOCIAL_COMPONENTS_MAP,
  ...SPORTS_COMPONENTS_MAP,
  ...WALLET_COMPONENTS_MAP,
  ...COMMON_COMPONENTS_MAP,
  ...NOTIFICATIONS_COMPONENTS_MAP,
  [AUTH_COMPONENT_TYPES.REGISTRATION]: Registration,
  [AUTH_COMPONENT_TYPES.LIMITS]: Limits,
  [AUTH_COMPONENT_TYPES.EDIT_PROFILE_MAIN]: EditProfileMain,
  [AUTH_COMPONENT_TYPES.EDIT_PROFILE_CONTACTS]: EditProfileContacts,
  [AUTH_COMPONENT_TYPES.EDIT_PROFILE_PERSONAL_DATA]: EditProfilePersonalData,
  [AUTH_COMPONENT_TYPES.LOGIN]: Login,
  [COMMON_COMPONENT_TYPES.LOGO]: Logo,
  [COMMON_COMPONENT_TYPES.FOOTER]: Footer,
  [CUSTOM_TYPES.HEADER_AUTH]: HeaderAuth,
  [CUSTOM_TYPES.HEADER]: Header,
  [CUSTOM_TYPES.HEADER_NAV_MENU]: HeaderNavMenu
};

export default componentsMap;
