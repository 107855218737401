import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import convertParams from '../convertParams';

const withNullParam = (param: any): boolean => !isNull(param) && isNil(param);

export const getRequestParams = (args: Record<string, any>, withNull = false) =>
  omitBy(args, withNull ? withNullParam : isNil);

export const getSearchString = (
  args: Record<string, any>,
  withNull = false
) => {
  const params = convertParams(getRequestParams(args, withNull));
  return params ? `?${params}` : '';
};
