import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { classNameProp, CustomFC } from '@monorepo/type';
import { Button, BUTTON_INTENT, BUTTON_SIZE } from '@monorepo/ui-kit';

import useLogout from '../../hooks/useLogout';

const LogoutButton: CustomFC<classNameProp> = ({
  className = '',
  ...props
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { onLogout, isLoading } = useLogout();
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { home } = generalRoutes;

  const handleLogout = useCallback(() => {
    onLogout().then(() => navigate(home));
  }, [onLogout]);

  return (
    <Button
      processing={isLoading}
      className={className}
      size={BUTTON_SIZE.h40}
      intent={BUTTON_INTENT.quaternary}
      onClick={handleLogout}
      {...props}
    >
      {t('burgermenu.logout')}
    </Button>
  );
};

export default LogoutButton;
