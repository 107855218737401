import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const BackHeaderVideo = lazy(() => import('./BackHeaderVideo'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BackHeaderVideo config={config} />
  </Suspense>
);
