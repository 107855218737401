import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { memoize } from 'proxy-memoize';

import { Component, TQueryResult } from '@monorepo/type';
import { FLEX_DIRECTION } from '@monorepo/ui-kit';

import { MARKET_DISPLAY_KEYS, OUTCOME_VIEW_MODE } from '../../constants';
import useGetLineMarkets from '../../hooks/useGetLineMarkets';
import { selectGameMarket } from '../../store/api/selectors';
import { TNormalizedLineMarkets } from '../../types';
import LineMarketBase from '../LineMarketBase';
import LineOutcomeList from '../LineOutcomeList';

type Props = {
  marketId: number;
  gameId: number;
  displayKey: string;
  itemClassName?: string;
  direction?: FLEX_DIRECTION;
  viewMode?: OUTCOME_VIEW_MODE;
  competitionId: number;
  sportId: number;
  regionId: number;
};

const LineMarket: Component<Props> = ({
  marketId,
  gameId,
  displayKey,
  itemClassName = '',
  direction,
  viewMode,
  competitionId,
  sportId,
  regionId
}) => {
  const { data: market } = useGetLineMarkets(undefined, {
    selectFromResult: memoize(
      (result: TQueryResult<TNormalizedLineMarkets>) => ({
        data: selectGameMarket(result, gameId, marketId)
      })
    )
  });

  const baseCoef = useMemo(() => {
    if (!isEmpty(market)) {
      const { base } = market;
      if (base || isNumber(base)) {
        if (displayKey === MARKET_DISPLAY_KEYS.TOTALS || base === 0) {
          return base;
        }
        if (base > 0) {
          return `-${base}+`;
        }
        return `+${base * -1}-`;
      }
    }
    return '-';
  }, [displayKey, market]);

  return (
    <>
      {displayKey !== MARKET_DISPLAY_KEYS.WINNER && (
        <LineMarketBase displayKey={displayKey} base={baseCoef} />
      )}
      <LineOutcomeList
        marketId={marketId}
        gameId={gameId}
        itemClassName={itemClassName}
        direction={direction}
        viewMode={viewMode}
        competitionId={competitionId}
        regionId={regionId}
        sportId={sportId}
      />
    </>
  );
};

export default LineMarket;
