import SocketClient, { TSocketClientConstructor } from './BaseSocketClient';

type TConnectionProps = {
  host: string;
  ClientClass: typeof SocketClient;
} & Omit<TSocketClientConstructor, 'url'>;

const openSocketConnection = ({
  host = '',
  ClientClass,
  ...rest
}: TConnectionProps) =>
  new ClientClass({
    url: `${host}`,
    ...rest
  });

export default openSocketConnection;
