import React from 'react';

import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  title: string;
};

const PromotionTitle: Component<Props> = ({ className = '', title }) => (
  <Box className={`${styles.title} ${className}`}>{title}</Box>
);

export default PromotionTitle;
