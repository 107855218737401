import React, { memo } from 'react';

import { TComponentsConfig, usePageContext } from '@monorepo/page';
import { classNameProp, CustomFC } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type ColumnProps = {
  components: string[];
  headerComponentsMap: TComponentsConfig;
} & classNameProp;

const HeaderColumn = ({
  className = '',
  components = [],
  headerComponentsMap
}: ColumnProps) => {
  const { componentsMap } = usePageContext();
  return (
    <FlexBox
      className={`${styles.wrap} ${className}`}
      align={FLEX_ALIGN.center}
    >
      {components.map((id) => {
        const componentData = headerComponentsMap[id];
        const Component: CustomFC<{ config: {} }> =
          componentsMap[componentData?.type];

        return (
          <FlexBox key={id} align={FLEX_ALIGN.center} className={styles.item}>
            {Component ? (
              <Component config={componentData.config || {}} />
            ) : null}
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export default memo(HeaderColumn);
