import React, { ReactElement, ReactNode } from 'react';

import { Icon } from '@monorepo/icons';
import { classNameProp, CustomFC } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

type TTabProps = {
  label: string | ReactNode;
  isShowLabel?: boolean;
  isWithIcon?: boolean;
  iconNames?: string[];
  index?: number;
  activeIndex?: number;
  onClick?: (index: number) => void;
  disabled?: boolean;
  activeClass?: string;
} & classNameProp;

const Tab: CustomFC<TTabProps> = ({
  label,
  iconNames,
  isShowLabel = true,
  isWithIcon = false,
  index,
  activeIndex,
  disabled,
  onClick,
  className = '',
  activeClass = ''
}): ReactElement => {
  const handleClick = () => {
    if (disabled) {
      return null;
    }

    if (index !== undefined && onClick) {
      onClick(index);
    }
  };

  const classes = `
  ${styles.root}
  ${disabled ? styles.disabled : ''}
  ${index === activeIndex && !activeClass ? styles.active : ''}
  ${index === activeIndex && activeClass ? activeClass : ''}
  ${className}`;

  const dataCustom = `bet-slip-tab_${index}`;
  const iconName = iconNames?.[index] || '';

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={classes}
      data-custom={dataCustom}
      onClick={handleClick}
    >
      {isWithIcon && <Icon name={iconName} />}
      {isShowLabel ? label : null}
    </FlexBox>
  );
};

export default Tab;
