import { SPORTS_PERIODS } from '../../constants';

const getPeriodTranslationKey = (
  state: string = '',
  sportAlias: string = ''
) => {
  if (!state || !sportAlias) {
    return '';
  }

  const sport = sportAlias.toLowerCase();
  if (!state?.match(/^set\d/)) {
    return `sportsbook.${state.toLowerCase().replaceAll(' ', '')}`;
  }
  const period = SPORTS_PERIODS[sport]
    ? `${SPORTS_PERIODS[sport]}.${state}`
    : state;

  return `sportsbook.${period}`;
};

export default getPeriodTranslationKey;
