import { useUpdatePlayerInfoMutation } from '@monorepo/cms';
import { getNickName, PROFILE_NICKNAME } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

import useGetUser from '../useGetUser';

const useNickname = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { data: profile } = useGetUser();
  const [onSave, { isLoading, error }] = useUpdatePlayerInfoMutation();
  const { successNotification, errorNotification } = useNotifications();
  const { userDefaultNick = PROFILE_NICKNAME.username } = useSiteSettings();

  return {
    saveNickname: (nickname: string) =>
      onSave({ nickname })
        .unwrap()
        .then(() => {
          successNotification(t('nickname.success'));
        })
        .catch(() => errorNotification(t('nickname.error'))),
    isLoading,
    error,
    saveNicknameLogin: () => {
      onSave({
        nickname: getNickName(
          profile as {},
          userDefaultNick as PROFILE_NICKNAME
        )
      }).unwrap();
    }
  };
};

export default useNickname;
