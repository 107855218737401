import React from 'react';

import { useGametaRedirect } from '@monorepo/common';
import { Component } from '@monorepo/type';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';

type TMetaVerseItem = {
  link: string;
};

const MetaVerseItem: Component<TMetaVerseItem> = ({
  children,
  link = '',
  className = ''
}) => {
  const { checkMeta } = useGametaRedirect();

  return (
    <Button
      onClick={() => checkMeta(link)}
      intent={BUTTON_INTENT.transparent}
      className={className}
    >
      {children}
    </Button>
  );
};

export default MetaVerseItem;
