import React, { useCallback, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useOnClickOutside } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import { TMenuItem } from '@monorepo/ui-kit';

import { transformCmsIcons } from '../../../../helpers';

import styles from './index.module.css';

const ParentItem: CustomFC<TMenuItem & { menuClass?: string }> = ({
  title = '',
  link = '',
  iconName = '',
  disabled = false,
  items = [],
  menuClass = '',
  toggleShowSubMenu
}) => {
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { enter, enterActive, enterDone, exit, exitActive, exitDone } = styles;
  const menuRef = useRef(null);

  useOnClickOutside(ref, () => {
    setIsMenuOpen(false);
    toggleShowSubMenu?.(false);
  });
  if (disabled) {
    return (
      <li className={`${styles.parentItem} ${styles.disabled}`}>
        {Boolean(iconName) && (
          <Icon name={transformCmsIcons(iconName)} className={styles.icon} />
        )}
        {title}
      </li>
    );
  }

  if (!items.length) {
    return (
      <li>
        <NavLink
          to={link}
          className={({ isActive }) =>
            `${styles.item} ${isActive ? styles.active : ''}`
          }
        >
          {Boolean(iconName) && (
            <Icon
              name={transformCmsIcons(iconName)}
              className={styles.icon}
              title=""
            />
          )}
          {title}
        </NavLink>
      </li>
    );
  }
  const itemMenuClick = useCallback(() => {
    toggleShowSubMenu?.(!isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);
  const classesItem = `${styles.parentItem} ${styles[menuClass]}`;
  const classesParentItem = `${styles.parent} ${styles[menuClass]} ${
    isMenuOpen ? styles.isOpen : ''
  }`;
  const classesSubItem = `${styles.subMenuItem} ${styles[menuClass]}`;
  return (
    <li className={classesItem} ref={ref} onClick={itemMenuClick}>
      <div className={classesParentItem}>
        {Boolean(iconName) &&
          (isMenuOpen ? (
            <Icon name="close_0" className={styles.icon} title="" />
          ) : (
            <Icon
              name={transformCmsIcons(iconName)}
              className={styles.icon}
              title=""
            />
          ))}
      </div>
      <div className={styles.title}>{title}</div>

      <CSSTransition
        timeout={700}
        nodeRef={menuRef}
        in={isMenuOpen}
        unmountOnExit
        classNames={{
          enter,
          enterActive,
          enterDone,
          exit,
          exitActive,
          exitDone
        }}
      >
        <div className={styles.subMenu} ref={menuRef}>
          <div className={styles.subMenuBg} />
          {items.map((l) => (
            <NavLink
              key={l.link}
              to={l.link}
              className={({ isActive }) =>
                `${classesSubItem} ${isActive ? styles.active : ''}`
              }
            >
              {Boolean(l.iconName) && (
                <Icon
                  name={transformCmsIcons(l.iconName)}
                  className={styles.icon}
                  title=""
                />
              )}
            </NavLink>
          ))}
        </div>
      </CSSTransition>
    </li>
  );
};

export default ParentItem;
