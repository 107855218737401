export const parseQueryString = (queryString = '') =>
  new URLSearchParams(queryString);

export const setParam = (
  query: URLSearchParams,
  paramName: string,
  value: string | string[] | null | boolean
) => {
  if (!value) {
    query.delete(paramName);
  } else {
    query.set(paramName, value as string);
  }
  return query;
};
