import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import sendLog from './api/sendLog';

export const appLoggerApi = createApi({
  reducerPath: 'appLoggerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ''
  }),
  endpoints: (builder) => ({
    sendLog: builder.mutation<any, any>(sendLog())
  })
});

export const { useSendLogMutation } = appLoggerApi;
