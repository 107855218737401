import React from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { sumToString } from '@monorepo/helpers';
import { useCurrencyTransform } from '@monorepo/i18n';
import { SvgIcon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { TCasinoTournament } from '../../../../../../types';

import styles from './index.module.css';

type TTournament = {
  tournament: TCasinoTournament;
  className?: string;
};

const CasinoTournament: CustomFC<TTournament> = ({ tournament, className }) => {
  const { lobbyBannerImages, name, prizeFund, currencyId } = tournament;

  const [{ images = [] } = {}] = lobbyBannerImages;
  const [{ imageUrl = '' } = {}] = images;

  const transformedCurrency = useCurrencyTransform(currencyId);
  const { isDesktop } = useDeviceDetector();

  return (
    <FlexBox
      className={`${styles.wrapper} ${className}`}
      align={FLEX_ALIGN.center}
    >
      <Box className={styles.imgWrap}>
        <img src={imageUrl} alt="" title={name} className={styles.img} />
      </Box>
      <FlexBox
        className={styles.content}
        align={FLEX_ALIGN.center}
        direction={isDesktop ? FLEX_DIRECTION.row : FLEX_DIRECTION.column}
      >
        <Box className={styles.name}>{name}</Box>
        <FlexBox
          justify={FLEX_JUSTIFY.center}
          align={FLEX_ALIGN.center}
          className={styles.fireText}
        >
          <SvgIcon name="layer_1" className={styles.icon} />
          <span className={styles.prize}>
            {sumToString(prizeFund)} {transformedCurrency}
          </span>
          <SvgIcon name="layer_2" />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default CasinoTournament;
