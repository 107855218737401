// import { TRawTransactions, TTransaction } from 'types';

const freeSpinsAdapter = (initialResponse) =>
  initialResponse?.details?.map(
    ({
      Amount,
      AcceptanceType,
      AmountPercent,
      BonusType,
      CanAccept,
      ClientBonusExpirationDate,
      DepositWageringFactor,
      Description,
      EndDate,
      ExpirationDate,
      ExpirationDays,
      ExternalId,
      Id,
      IsConvertible,
      IsForVerifiedPlayersOnly,
      MoneyRequirenments,
      Name,
      PartnerBonusId,
      ResultType,
      Source,
      StartDate,
      WageringFactor
    }) => ({
      amount: Amount,
      acceptanceType: AcceptanceType,
      amountPercent: AmountPercent,
      bonusType: BonusType,
      canAccept: CanAccept,
      clientBonusExpirationDate: ClientBonusExpirationDate,
      depositWageringFactor: DepositWageringFactor,
      description: Description,
      endDate: EndDate,
      expirationDate: ExpirationDate,
      expirationDays: ExpirationDays,
      externalId: ExternalId,
      id: Id,
      is_convertible: IsConvertible,
      is_for_verified_players_only: IsForVerifiedPlayersOnly,
      moneyRequirenments: MoneyRequirenments,
      name: Name,
      partnerBonusId: PartnerBonusId,
      resultType: ResultType,
      source: Source,
      startDate: StartDate,
      wageringFactor: WageringFactor
    })
  );

export default freeSpinsAdapter;
