import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import { memoize } from 'proxy-memoize';

import { useTypedSelector } from '@monorepo/redux';
import { isFriendsSubscription, MESSAGES_TYPES } from '@monorepo/xmpp';

import { TStoreRoster } from '../../../types';
import useSelectInbox from '../useSelectInbox';
import { getRoster } from '../useSelectRoster';

const getFriends = (roster: TStoreRoster, list: string[]) =>
  map(
    sortBy(
      filter(values(roster), (item) =>
        isFriendsSubscription(item.subscription)
      ),
      ({ jid }) => {
        const index = list.indexOf(jid.toString());
        return index < 0 ? Infinity : index;
      }
    ),
    (item) => get(item, 'id')
  );

const useSelectFriends = (): string[] => {
  const { list } = useSelectInbox(MESSAGES_TYPES.SINGLE);

  return useTypedSelector(
    memoize((state) =>
      getFriends(
        getRoster(state),
        map(list, (item) => get(item, 'roomJid'))
      )
    )
  );
};
export default useSelectFriends;
