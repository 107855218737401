import React from 'react';

import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

const CountUI: Component = ({ children, className = '' }) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    justify={FLEX_JUSTIFY.center}
    className={`${styles.count} ${className}`}
  >
    {children}
  </FlexBox>
);

export default CountUI;
