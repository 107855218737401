import { useTypedSelector } from '@monorepo/redux';

import { TStoreRoster } from '../../../types';

export const getRoster = (state: any): TStoreRoster =>
  state.socialsSlice.roster;

const useSelectRoster = (): TStoreRoster => useTypedSelector(getRoster);

export default useSelectRoster;
