import React, { useCallback, useMemo } from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import createPagesArray from '../../utils/createPagesArray';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

type TPaginationProps = {
  currentPage?: number;
  totalPages?: number;
  onPageChange: (page: number) => void;
  isFetching: boolean;
};

const Pagination: Component<TPaginationProps> = ({
  onPageChange,
  totalPages = 1,
  currentPage = 1,
  isFetching
}) => {
  const pages = useMemo(
    () => createPagesArray(currentPage, totalPages),
    [currentPage, totalPages]
  );
  const isShowPrev = useMemo(
    () => totalPages > 5 && currentPage > 2,
    [totalPages, currentPage]
  );
  const isShowNext = useMemo(
    () => totalPages > 5 && currentPage < totalPages - 2,
    [totalPages, currentPage]
  );

  const handlePrev = useCallback(
    () => onPageChange(currentPage - 1),
    [onPageChange, currentPage]
  );
  const handleNext = useCallback(
    () => onPageChange(currentPage + 1),
    [onPageChange, currentPage]
  );

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.wrap}
    >
      {isShowPrev && (
        <button
          type="button"
          onClick={handlePrev}
          className={`${styles.button} ${styles.action}`}
        >
          <Icon name="arrow_right-1" className={styles.icon} />
        </button>
      )}
      {pages.map((page) => (
        <button
          key={page}
          type="button"
          onClick={() => onPageChange(page)}
          disabled={isFetching}
          className={`${styles.button} ${styles.page} ${
            page === currentPage ? styles.active : ''
          }`}
        >
          {page}
        </button>
      ))}
      {isShowNext && (
        <button
          type="button"
          onClick={handleNext}
          className={`${styles.button} ${styles.action}`}
        >
          <Icon name="arrow_right-1" className={styles.icon} />
        </button>
      )}
    </FlexBox>
  );
};

export default Pagination;
