import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  AMOUNT_REGEXP,
  AmountField,
  amountFieldValidator,
  numbersRegExpValidator,
  RadioGroupField,
  regExpValidator,
  requiredValidator,
  useChangeSeparator,
  valuesQuantityValidator
} from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import {
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { TLimit } from '../../types';
import LimitTooltip from '../LimitTooltip';

import styles from './index.module.css';

const Limit: CustomFC<TLimit> = ({
  title,
  radioName,
  measure,
  hint,
  tooltip,
  limitItems,
  limitName,
  minValue,
  maxValue,
  value,
  inverse = false
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const context = useFormContext();
  const { amountMaskToNumber } = useChangeSeparator();

  return (
    <div className={styles.wrap}>
      <FlexBox align={FLEX_ALIGN.center} className={styles.header}>
        {title}
        {tooltip && <LimitTooltip text={tooltip} />}
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
        className={styles.wrapper}
      >
        <RadioGroupField
          id={limitName}
          name={radioName}
          elements={limitItems}
          className={styles.limitControl}
          inputClassName={styles.limitControl}
          label=""
          toneType={FIELD_TONE.light}
        />
      </FlexBox>

      {context.watch(radioName) && (
        <FlexBox
          justify={FLEX_JUSTIFY.between}
          align={FLEX_ALIGN.center}
          className={styles.inputWrapper}
          direction={FLEX_DIRECTION.column}
        >
          <FlexBox
            direction={FLEX_DIRECTION.row}
            align={FLEX_ALIGN.start}
            className={styles.limitInputWrapper}
          >
            <AmountField
              id={limitName}
              name={limitName}
              inputClassName={styles.limitInput}
              rules={{
                ...requiredValidator(),
                ...amountFieldValidator(
                  {
                    min: {
                      value: minValue,
                      message:
                        measure === 'min'
                          ? 'validators.min.minutes'
                          : 'validators.value.min'
                    },
                    max: {
                      value: maxValue,
                      message:
                        measure === 'min'
                          ? 'validators.max.minutes'
                          : 'validators.value.max'
                    }
                  },
                  amountMaskToNumber,
                  measure !== 'min'
                ),
                ...numbersRegExpValidator(),
                ...regExpValidator(AMOUNT_REGEXP, t('validators.limit.error')),
                ...(value &&
                  valuesQuantityValidator(
                    () => context.watch(value),
                    'validators.values.error',
                    inverse,
                    amountMaskToNumber
                  ))
              }}
              toneType={FIELD_TONE.light}
            />
            {Boolean(measure) && (
              <FlexBox align={FLEX_ALIGN.center} className={styles.measure}>
                {measure}
              </FlexBox>
            )}
          </FlexBox>
          <span className={styles.hint}>{hint}</span>
        </FlexBox>
      )}
    </div>
  );
};

export default Limit;
