import React from 'react';

import { usePageContext } from '@monorepo/page';
import { classNameProp, CustomFC } from '@monorepo/type';
import {
  Box,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import Column from '../Column';

import styles from './index.module.css';

const Chats: CustomFC<classNameProp> = ({ className = '' }) => {
  const { page } = usePageContext();
  if (page) {
    const { layout } = page;
    const { columns, type } = layout;

    return (
      <PaddingWrapper isOnlyDesktop className={styles.glow}>
        <Box>
          <FlexBox
            className={`${styles.wrap} ${styles[type] || ''} ${className}`}
            justify={FLEX_JUSTIFY.center}
            direction={FLEX_DIRECTION.row}
          >
            <Column
              key={columns[0].join('/')}
              column={columns[0]}
              className={styles.columnMenu}
            />
            <Column
              key={columns[1].join('/')}
              column={columns[1]}
              className={styles.columnMain}
            />
            <Column
              key={columns[2].join('/')}
              column={columns[2]}
              className={styles.columnInfo}
            />
          </FlexBox>
        </Box>
      </PaddingWrapper>
    );
  }

  return null;
};

export default Chats;
