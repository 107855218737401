import netLossAdapter from '../adapters/netLossAdapter';

const getNetLoss = () => ({
  query: ({ game_id, from_date, to_date }) => ({
    body: {
      command: 'get_net_winning_report',
      params: {
        game_id,
        from_date,
        to_date
      }
    }
  }),
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },
  transformResponse(response) {
    return netLossAdapter(response);
  }
});
export default getNetLoss;
