import React from 'react';

import { Component } from '@monorepo/type';
import { Avatar, FIELD_TONE } from '@monorepo/ui-kit';

import UserStatus from '../UserStatus';

import styles from './index.module.css';

type Props = {
  imgSrc?: string;
  alt?: string;
  nickname?: string;
  avatarClass?: string;
  statusClassName?: string;
  toneType?: FIELD_TONE;
};

const HeaderAvatar: Component<Props> = ({
  className = '',
  avatarClass = '',
  imgSrc = '',
  nickname = '',
  toneType = FIELD_TONE.dark,
  statusClassName = '',
  alt = ''
}) => {
  const statusClasses = `${styles.status} ${styles[toneType]} ${statusClassName}`;
  return (
    <Avatar
      imgSrc={imgSrc}
      className={`${styles.wrap} ${className}`}
      avatarClass={`${styles.avatar} ${avatarClass}`}
      nickname={nickname}
      alt={alt || 'avatar'}
    >
      <UserStatus className={statusClasses} />
    </Avatar>
  );
};

export default HeaderAvatar;
