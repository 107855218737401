import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Component } from '@monorepo/type';

import getTooltipDataAttributes from '../../utils/getTooltipDataAttributes';
import EllipsisText from '../EllipsisText';

type Props = {
  content?: string;
};

const EllipsisTextWithTooltip: Component<Props> = ({ content, className }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const itemRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (itemRef.current) {
      const { scrollWidth, clientWidth } = itemRef.current;
      setIsOverflow(scrollWidth > clientWidth);
    }
  }, [itemRef.current, content]);

  const tooltipAttrs = useMemo(
    () =>
      getTooltipDataAttributes({
        place: 'top',
        content,
        delayShow: 300,
        hidden: !isOverflowed
      }),
    [isOverflowed, content]
  );

  return (
    <EllipsisText className={className} ref={itemRef} {...tooltipAttrs}>
      {content}
    </EllipsisText>
  );
};
export default EllipsisTextWithTooltip;
