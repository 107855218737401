import React, { memo } from 'react';

import { useSelectAuthData } from '@monorepo/auth';
import { FAVORITE_TYPE, FavoriteButton, useSendLog } from '@monorepo/common';
import { googleAnalytics } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import useGameControls from '../../hooks/useGameControls';
import useHover from '../../hooks/useHover';
import GameTile from '../GameTile';
import GameTitle from '../GameTitle';
import InfoButton from '../InfoButton';
import Popup from '../Popup';

import styles from './index.module.css';

type Props = {
  game: Record<string, any>;
  withTutorials: boolean;
  enableFavorite?: boolean;
};

const GameItem: Component<Props> = memo(
  ({ className = '', game, withTutorials, enableFavorite = true }) => {
    const { name, gameId, img, isRealMode, isFunMode, badge } = game || {};
    const authData = useSelectAuthData();
    const { t } = useI18n(NAMESPACES.CASINO);
    const { showInfoButton, eventHandlers } = useHover();

    const { onFreePlay, onRealPlay } = useGameControls(gameId);

    const { handleSendLog } = useSendLog();

    const onRealPlayHandle = () => {
      const ga = googleAnalytics();
      ga.dispatch({
        event: ga.event.webButton,
        eventParam: {
          event_name: 'play_game_real_money',
          userId: authData?.userId
        },
        event_options: {
          element: 'casino_game',
          event_category: 'web_click'
        }
      });
      onRealPlay();
      handleSendLog({
        name: `casino_play`,
        userId: authData?.userId?.toString()
      });
    };

    if (game) {
      return (
        <GameTile
          className={className}
          name={name}
          image={img}
          badge={badge?.class}
        >
          <FlexBox
            {...(withTutorials && eventHandlers)}
            direction={FLEX_DIRECTION.column}
            className={styles.hoverWrapper}
          >
            <FlexBox className={styles.header} align={FLEX_ALIGN.center}>
              {showInfoButton && (
                <Popup
                  name={name}
                  gameId={gameId}
                  isRealMode={isRealMode}
                  isFunMode={isFunMode}
                  className={styles.popupWrapper}
                >
                  <InfoButton />
                </Popup>
              )}
              <GameTitle>{name}</GameTitle>
              {enableFavorite && (
                <FavoriteButton
                  id={gameId}
                  type={FAVORITE_TYPE.CASINO}
                  className={styles.favorite}
                />
              )}
            </FlexBox>
            <FlexBox
              className={styles.controls}
              direction={FLEX_DIRECTION.column}
              justify={FLEX_JUSTIFY.center}
              align={FLEX_ALIGN.center}
            >
              {isRealMode && (
                <Button
                  intent={BUTTON_INTENT.yellow}
                  className={styles.button}
                  onClick={onRealPlayHandle}
                  size={BUTTON_SIZE.h30}
                >
                  {t('game.playnow')}
                </Button>
              )}
              {isFunMode && (
                <Button
                  className={styles.button}
                  onClick={onFreePlay}
                  intent={BUTTON_INTENT.secondary}
                  size={BUTTON_SIZE.h30}
                >
                  {t('game.playfree')}
                </Button>
              )}
            </FlexBox>
          </FlexBox>
        </GameTile>
      );
    }

    return null;
  }
);

export default GameItem;
