import React from 'react';

import { InputField, requiredValidator } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';

import { TFormFieldValues, TPaymentFormField } from '../../../../../types';
import FieldWrap from '../FieldWrap';

type Props = {
  field: TPaymentFormField;
};

const PaymentInputField: Component<Props> = ({ field }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { fieldKey, type } = field;
  const customType = fieldKey === 'phone' ? 'tel' : type;
  return (
    <FieldWrap label={t(`wallet.${fieldKey}`)}>
      <InputField<TFormFieldValues>
        id={fieldKey}
        name={fieldKey}
        type={customType}
        rules={{
          ...requiredValidator()
        }}
      />
    </FieldWrap>
  );
};

export default PaymentInputField;
