import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CustomFC } from '@monorepo/type';

import { TLineParameters } from '../../types';
import BaseParametersProvider from '../BaseParametersProvider';

type Props = {
  params?: Partial<TLineParameters>;
};

const GameDetailsParametersProvider: CustomFC<Props> = ({
  children,
  params = {}
}) => {
  const { gameId, competitionId } =
    useParams<Record<string, string | undefined>>();

  const defaultState = useMemo(
    () => ({
      gameId,
      activeMarketGroup: null,
      competitionId,
      ...params
    }),
    [gameId]
  );

  return (
    <BaseParametersProvider params={defaultState}>
      {children}
    </BaseParametersProvider>
  );
};

export default GameDetailsParametersProvider;
