import { useCallback, useEffect, useState } from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { useGetPlayerInformationLazy } from '@monorepo/cms';
import { getRoster, SHOW_VALUE, useXMPPInstance } from '@monorepo/xmpp';

import useActions from '../useActions';
import useInbox from '../useInbox';
import useSendStatus from '../useSendStatus';
import useSocialStreaming from '../useSocialStreaming';
import useSocialToasts from '../useSocialToasts';

import IqHandler from './handlers/IqHandler';
import MessageHandler from './handlers/MessageHandler';
import PresenceHandler from './handlers/PresenceHandler';
import iqSendStanzaCallback from './utils/iqSendStanzaCallback';
import iqStanzaCallback from './utils/iqStanzaCallback';
import messageStanzaCallback from './utils/messageStanzaCallback';
import presenceStanzaCallback from './utils/presenceStanzaCallback';
import sendMessageStanzaCallback from './utils/sendMessageStanzaCallback';

const useAddSocialsHandlers = () => {
  const xmpp = useXMPPInstance();
  const { isAuthenticated } = useIsAuthenticated();
  const actions = useActions();
  const { resetSliceState } = actions;
  const displayToast = useSocialToasts();
  const [trigger] = useGetPlayerInformationLazy();
  const sendStatus = useSendStatus();
  const [inboxSkip, setInboxSkip] = useState(true);

  const offlineCallback = useCallback(() => {
    if (!isAuthenticated) {
      resetSliceState({});
    }
  }, [isAuthenticated, resetSliceState]);
  const onlineCallback = async () => {
    /*
     * According to https://xmpp.org/rfcs/rfc6121.html#presence-initial-gen
     */
    await xmpp.send(getRoster());
    const { data } = await trigger(undefined);
    const { status, visibility_status: visibilityStatus } = data || {};
    await sendStatus({
      status: status || undefined,
      show:
        visibilityStatus && visibilityStatus !== 'undefined'
          ? visibilityStatus
          : SHOW_VALUE.CHAT
    });
    setInboxSkip(false);
  };

  useSocialStreaming();
  useInbox({ skip: inboxSkip });

  useEffect(() => {
    const handler = new IqHandler(actions);
    const iqCallback = iqStanzaCallback(handler);
    const iqSentCallback = iqSendStanzaCallback(handler);

    xmpp.createListener('stanza', iqCallback);
    xmpp.createListener('send', iqSentCallback);
    xmpp.createListener('online', onlineCallback);

    return () => {
      xmpp.removeListener('stanza', iqCallback);
      xmpp.removeListener('send', iqSentCallback);
      xmpp.removeListener('online', onlineCallback);
    };
  }, []);

  useEffect(() => {
    xmpp.createListener('offline', offlineCallback);

    return () => {
      xmpp.removeListener('offline', offlineCallback);
    };
  }, [offlineCallback]);

  useEffect(() => {
    const messageHandler = new MessageHandler(xmpp, actions, {
      displayToast
    });
    const messageCallback = messageStanzaCallback(messageHandler);
    const sendMessageCallback = sendMessageStanzaCallback(messageHandler);
    const presenceCallback = presenceStanzaCallback(
      new PresenceHandler(xmpp, actions, {
        displayToast
      })
    );

    xmpp.createListener('stanza', messageCallback);
    xmpp.createListener('stanza', presenceCallback);
    xmpp.createListener('send', sendMessageCallback);

    return () => {
      xmpp.removeListener('stanza', messageCallback);
      xmpp.removeListener('stanza', presenceCallback);
      xmpp.removeListener('send', sendMessageCallback);
    };
  }, [displayToast]);
};

export default useAddSocialsHandlers;
