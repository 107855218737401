import React from 'react';

import { Component } from '@monorepo/type';
import { FLEX_DIRECTION, Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  direction?: FLEX_DIRECTION;
};

const SportsBookButtonSkeleton: Component<Props> = ({
  className,
  direction = FLEX_DIRECTION.column
}) => {
  const classes = `${styles.wrap}
    ${styles.disabled}
    ${styles[direction]}
    ${className}`;

  return <Skeleton className={classes} />;
};

export default SportsBookButtonSkeleton;
