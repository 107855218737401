import { useGetUserQuery } from '../../store/messengerUsersApi';
import { TGetUserRequest } from '../../types/requests';
import useMessengerToken from '../useMessengerToken';

const useGetMessengerUser = (
  args: Omit<TGetUserRequest, 'token'> = {},
  options = {}
) => {
  const { skip = false, ...rest } = options;
  const { token, isValid } = useMessengerToken();

  return useGetUserQuery(
    { token, ...args },
    { skip: !isValid || skip, ...rest }
  );
};

export default useGetMessengerUser;
