import React from 'react';

import { Icon } from '@monorepo/icons';
import { classNameProp, CustomFC } from '@monorepo/type';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  hasDescription?: boolean;
} & classNameProp;

const InfoButton: CustomFC<Props> = ({
  hasDescription = true,
  className = ''
}) => {
  const classes = `${styles.button}
    ${className}`;

  if (hasDescription) {
    return (
      <Button className={classes} intent={BUTTON_INTENT.tertiary} isOnlyIcon>
        <Icon name="info" className={styles.icon} title="" />
      </Button>
    );
  }

  return null;
};

export default InfoButton;
