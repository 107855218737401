import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';
import { MESSAGES_TYPES } from '@monorepo/xmpp';

import UnreadMessageGroupCount from '../../../../common/UnreadMessageGroupCount';
import {
  MESSENGER_VIEW,
  SENT_POST_MESSAGE_TYPE,
  SOCIAL_FRAME_NAME
} from '../../../../constants/frames';

import styles from './index.module.css';

type Props = {
  type: MESSAGES_TYPES;
  iconClassName?: string;
  isHorizontal?: boolean;
  isInFrame?: boolean;
};

const SocialButton: Component<Props> = ({
  type,
  isHorizontal,
  iconClassName = '',
  className = '',
  isInFrame = false
}) => {
  const navigate = useNavigate();
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes } = useSiteSettings();
  const { personalChats, communityChats } = generalRoutes;
  const isSingle = type === MESSAGES_TYPES.SINGLE;
  const classes = `${styles.wrap}
    ${isSingle ? styles.friends : styles.community}
    ${isHorizontal ? styles.horizontal : styles.vertical}
    ${className}`;

  const handleClick = useCallback(() => {
    if (isInFrame) {
      // eslint-disable-next-line no-restricted-globals
      return parent.postMessage(
        {
          type: SENT_POST_MESSAGE_TYPE.REDIRECT,
          data: {
            name: SOCIAL_FRAME_NAME.MESSENGER,
            frameData: {
              defaultView: isSingle
                ? MESSENGER_VIEW.PERSONAL
                : MESSENGER_VIEW.COMMUNITY
            }
          }
        },
        '*'
      );
    }
    return navigate(isSingle ? personalChats : communityChats);
  }, [isSingle, personalChats, communityChats, navigate]);

  return (
    <div className={classes}>
      <Button
        className={styles.button}
        intent={BUTTON_INTENT.transparent}
        onClick={handleClick}
      >
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={`${styles.iconWrap} ${iconClassName}`}
        >
          <Icon
            name={isSingle ? 'users' : 'community'}
            title=""
            className={styles.icon}
          />
          <UnreadMessageGroupCount type={type} className={styles.count} />
        </FlexBox>
        <EllipsisText className={styles.text}>
          {t(`friends.${isSingle ? 'friends' : 'community'}`)}
        </EllipsisText>
      </Button>
    </div>
  );
};

export default SocialButton;
