import { useEffect, useMemo, useState } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { MARKET_TYPES } from '../../constants';

const useGetMarketType = (deviceWidth: number) => {
  const { isDesktop } = useDeviceDetector();
  const defaultState = window.innerWidth > deviceWidth;
  const [isFullDesktop, setIsFullDesktop] = useState(defaultState);

  const marketType = useMemo(
    () =>
      isDesktop && isFullDesktop ? MARKET_TYPES.THREE : MARKET_TYPES.SINGLE,
    [isDesktop, isFullDesktop]
  );

  useEffect(() => {
    const handleResize = () =>
      setIsFullDesktop(window.innerWidth > deviceWidth);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return marketType;
};

export default useGetMarketType;
