const getPartnerBonuses = () => ({
  query: () => ({
    body: {
      command: 'get_partner_bonuses_for_request',
      params: {}
    }
  }),
  transformResponse: (response: any): Promise<any> | any => response?.details
});
export default getPartnerBonuses;
