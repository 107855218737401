import React from 'react';

import { AuthLink, AuthWrapper } from '@monorepo/auth';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import Form from './components/Form';

import styles from './index.module.css';

type Config = {
  logoSrc: string;
  header: string;
  redirectTo?: string;
};

const Login: ComponentWithConfig<Config> = ({ config }) => {
  const { header, redirectTo } = config;
  const { t } = useI18n(NAMESPACES.FORMS);
  const { generalRoutes, isModalSignUp } = useSiteSettings();
  const { home, registration } = generalRoutes;

  return (
    <AuthWrapper header={t(header)}>
      <PaddingWrapper isOnlyMobile className={styles.wrap}>
        <FlexBox direction={FLEX_DIRECTION.column} align={FLEX_ALIGN.center}>
          <Form redirectTo={redirectTo} />
          <AuthLink
            to={isModalSignUp ? `${home}?signup=on` : registration}
            className={styles.registerLink}
          >
            {t('login.link.registration')}
          </AuthLink>
        </FlexBox>
      </PaddingWrapper>
    </AuthWrapper>
  );
};

export default Login;
