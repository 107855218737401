import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TGetSportsRecommendedIdsRequest,
  TSportsRecommendedItem
} from '../types';

import getRecommendedGamesIds from './api/getRecommendedGamesIds';

const sportsRecommenderHost = process.env.REACT_APP_RECOMMENDER_HOST;

export const sportsRecommenderApi = createApi({
  reducerPath: 'sportsRecommenderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${sportsRecommenderHost}/api/v3/recommendations/partners/`
  }),
  endpoints: (builder) => ({
    getRecommendedGamesIds: builder.query<
      TSportsRecommendedItem[],
      TGetSportsRecommendedIdsRequest
    >(getRecommendedGamesIds())
  })
});

export const { useGetRecommendedGamesIdsQuery } = sportsRecommenderApi;
