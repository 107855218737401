import openSocketConnection from '../openSocketConnection';

import JackpotSocketClient from './jackpotSocketClient';

// eslint-disable-next-line import/prefer-default-export
export const openSocketJackpot = () =>
  openSocketConnection({
    host: process.env.REACT_APP_JACKPOTS_WS_HOST || '',
    requestIdProperty: 'RequestId',
    isPing: true,
    pingDelay: 10000,
    ClientClass: JackpotSocketClient
  });
