import React from 'react';

import { Component } from '@monorepo/type';
import { Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

const TeamSkeleton: Component = ({ className = '' }) => (
  <Skeleton className={`${styles.wrap} ${styles.skeleton} ${className}`} />
);

export default TeamSkeleton;
