import React, { forwardRef } from 'react';

import { Input, InputProps } from '@monorepo/ui-kit';

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', ...props }, ref) => (
    // @ts-ignore
    <Input className={className} iconName="search_1" ref={ref} {...props} />
  )
);

export default SearchInput;
