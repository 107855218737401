import get from 'lodash/get';

import { AUTHENTICATION_STATUS } from '../../../constants';
import { TAuthData, TLoginRequest } from '../../../types';
import { actions } from '../../index';
import { actions as localActions } from '../../userLocalSlice';
import loginAdapter from '../adapters/login';

const login = () => ({
  query: ({
    login: username,
    password,
    remember,
    gRecaptchaResponse
  }: TLoginRequest) => ({
    body: {
      command: 'login',
      params: {
        username,
        password,
        g_recaptcha_response: gRecaptchaResponse,
        encrypted_token: remember
      }
    },
    checkError: (response) => {
      if (response?.code === 27) {
        return { status: 27, captchaData: response?.data };
      }
      if (response?.code !== 0) {
        return {
          status: get(response, 'data.status', 1002),
          extraData: get(response, 'data.details.ErrorData', {})
        }; // 1002 - invalid credentials
      }
      return false;
    }
  }),
  async onQueryStarted(arg: TLoginRequest, { dispatch, queryFulfilled }) {
    const { setIsAuthenticated } = actions;
    const { setAuthData } = localActions;
    try {
      const { data } = await queryFulfilled;
      const {
        authToken,
        userId,
        jweToken,
        authenticationStatus,
        qrCodeOrigin
      } = data;
      if (authenticationStatus !== AUTHENTICATION_STATUS.NEED_AUTHENTICATION) {
        dispatch(
          setAuthData({
            authToken,
            userId,
            authenticationStatus,
            qrCodeOrigin,
            jweToken,
            ownerId: userId
          })
        );
        dispatch(
          setIsAuthenticated({
            isAuthenticated: true,
            isAuthFetched: true
          })
        );
      } else {
        dispatch(
          setIsAuthenticated({
            isAuthenticated: false,
            isAuthFetched: true
          })
        );
      }
    } catch (e) {}
  },
  transformResponse: (response: TAuthData): Promise<TAuthData> | TAuthData =>
    loginAdapter(response)
});
export default login;
