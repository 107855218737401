import promotionSlotsAdapter from '../../../api/adapters/promotionSlotsAdapter';

const getTags = () => ({
  query: ({ siteId }: { siteId: number }) => ({
    url: `/filters?partner_id=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  transformResponse: (response: any) => promotionSlotsAdapter(response)
});

export default getTags;
