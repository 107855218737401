import { useCallback } from 'react';

import { useDeclineRoomInviteMutation } from '../../store/socialsApi';
import { TDeclineRoomInvite } from '../../types';

const useDeclineRoomInvite = () => {
  const [declineRoomInvite] = useDeclineRoomInviteMutation();

  return useCallback(
    (args: TDeclineRoomInvite) => declineRoomInvite(args),
    [declineRoomInvite]
  );
};

export default useDeclineRoomInvite;
