import { useStore } from 'react-redux';

import { LOGGER_EVENT, useExternalParam } from '@monorepo/auth';
import { TExternalParam } from '@monorepo/auth/src/types/userLocal';
import { useSiteSettings } from '@monorepo/site-settings';

import { useSendLogMutation } from '../../store/appLogger';

const useSendLog = () => {
  const [sendLog] = useSendLogMutation();
  const { siteId, externalLogger = true } = useSiteSettings();
  const { chatId = '' } = (useExternalParam() as TExternalParam) || {};

  const store = useStore();
  const handleSendLog = ({
    type = LOGGER_EVENT.LOG,
    name,
    userId,
    payload
  }: {
    type?: string;
    name?: string | undefined;
    userId?: string;
    payload?: {};
  }) => {
    const logData = type === LOGGER_EVENT.LOG && {
      name,
      user_id: userId,
      timestamp: new Date().getTime()
    };
    return sendLog({
      type,
      sid: store.getState().app.sessionSwarmId,
      partner_id: siteId,
      chat_id: chatId,
      ...payload,
      ...logData
    });
  };
  return { handleSendLog: externalLogger ? handleSendLog : () => null };
};
export default useSendLog;
