import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import SportsBookButton from '../SportsBookButton';

import styles from './index.module.css';

type Props = {
  marketsCount?: number;
  link: string;
};

const ShowMoreButton: Component<Props> = ({
  className = '',
  marketsCount,
  link
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const { isDesktop } = useDeviceDetector();
  const navigate = useNavigate();

  const text = useMemo(() => {
    if (marketsCount) {
      return `+${marketsCount}`;
    }
    return isDesktop ? t('sportsbook.line.noeventsatmoment') : '';
  }, [t, marketsCount, isDesktop]);

  const handleClick = () => navigate(link);

  return (
    <SportsBookButton
      onClick={handleClick}
      className={`${styles.wrap} ${className}`}
    >
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.label}
      >
        <>
          {!marketsCount && <Icon name="lock-1" className={styles.noMarkets} />}
          {text}
        </>
        <Icon name="arrow_2" className={styles.icon} />
      </FlexBox>
    </SportsBookButton>
  );
};

export default ShowMoreButton;
