type TOption = {
  label: string;
  value: string;
};

const optionsTranslate = (
  arr: TOption[],
  prefix: string = '',
  fn: (key: string) => typeof key
): TOption[] =>
  arr.map(({ label, value }) => {
    const key = prefix ? `${prefix}.${label}` : label;

    return {
      value,
      label: fn(key)
    };
  });

export default optionsTranslate;
