import { WALLET_QUERY_KEYS } from '../../../constants';
import { TGetPaymentServices, TPaymentServices } from '../../../types';

const getPaymentServices = () => ({
  query: () => ({
    body: {
      command: 'payment_services',
      params: {
        detailed: true
      }
    }
  }),
  providesTags: [WALLET_QUERY_KEYS.GET_PAYMENTS_DETAILS],
  transformResponse(response: TGetPaymentServices): TPaymentServices {
    if (!response) {
      return { deposit: {}, withdraw: {} };
    }

    const { deposit = {}, withdraw = {} } = response;
    const deposits = deposit.status === 'error' ? {} : deposit;
    const withdraws = withdraw.status === 'error' ? {} : withdraw;

    return { deposit: deposits, withdraw: withdraws } as TPaymentServices;
  }
});
export default getPaymentServices;
