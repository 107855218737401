import React, { useMemo } from 'react';

import { convertToDuration } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';

import useSelectPlayingTime from '../../hooks/selectors/useSelectPlayingTime';

import styles from './index.module.css';

const ProfilePlayingTime: Component = ({ className = '' }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { realityCheckTime } = useSiteSettings();
  const playingTime = useSelectPlayingTime();
  const { h, hours, minutes, seconds } = useMemo(
    () => convertToDuration(playingTime),
    [playingTime]
  );

  const tooltip = useMemo(() => {
    if (realityCheckTime) {
      return t('profile.playingtime.tooltip', {
        minutes: realityCheckTime / 60
      });
    }
    return undefined;
  }, [realityCheckTime, t]);

  return (
    <div className={`${styles.text} ${className}`} title={tooltip}>
      {t('profile.playingtime')}: {h ? `${hours}:` : '00:'}
      {minutes}:{seconds}
    </div>
  );
};

export default ProfilePlayingTime;
