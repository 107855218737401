import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const normalizeGameResponse = (rawResponse: any) => {
  const competition = get(rawResponse, 'data.competition', null);
  const subid = get(rawResponse, 'subid', '');

  return {
    competition: isEmpty(competition) ? null : competition,
    subid
  };
};
