const getTwoFactorApply = () => ({
  query: ({
    code,
    deviceFingerprint
  }: {
    code: number;
    deviceFingerprint: string;
  }) => ({
    body: {
      command: 'apply_two_factor_authentication_code',
      params: {
        code,
        device_fingerprint: deviceFingerprint,
        is_device_trusted: false
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result } = data;
      return !(code === 0 && result === 0) ? result : false;
    }
  })
});

export default getTwoFactorApply;
