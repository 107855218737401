import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const Footer = lazy(() => import('./Footer'));

export default () => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Footer />
  </Suspense>
);
