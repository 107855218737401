import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useDisableTwoFactorMutation } from '../../store/user';

const useTwoFactorDisable = () => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { errorNotification, successNotification } = useNotifications();
  const [disableTwoFactor, { isLoading: isLoadingUpdate }] =
    useDisableTwoFactorMutation();

  const onErrorDefault = (error) => {
    const code = Number(error);
    errorNotification(t(`error.${code < 0 ? code * -1 : code}`));
  };

  const onSuccessUpdate = (ref) => {
    successNotification(
      t('two.factor.auth.disabled.success', { ns: NAMESPACES.ACCOUNT })
    );
    if (ref?.current) {
      ref.current.reset(undefined, { keepValues: false });
    }
  };

  const onDisableTwoFactor = async (password: string, ref) => {
    const response = await disableTwoFactor(password);
    const { error } = response;
    if (error) {
      onErrorDefault(error);
    } else {
      onSuccessUpdate(ref);
    }
  };

  return {
    onDisableTwoFactor,
    isLoading: isLoadingUpdate
  };
};

export default useTwoFactorDisable;
