import React from 'react';

import { useChangeSeparator } from '@monorepo/forms';
import { Component } from '@monorepo/type';

import { SERVICE_TYPES } from '../../../constants';
import useWithdraw from '../../../hooks/useWithdraw';
import { TFormFieldValues } from '../../../types';
import PaymentsForm from '../PaymentsForm';

type Props = {
  serviceId: string;
};

const SubmitWithdraw: Component<Props> = ({ serviceId }) => {
  const { onWithdraw, isLoading } = useWithdraw();
  const { amountMaskToNumber } = useChangeSeparator();

  const handler = async (userData: TFormFieldValues) => {
    const { amount, ...fields } = userData;

    return onWithdraw({
      amount: amountMaskToNumber(amount),
      fields,
      serviceId
    });
  };

  return (
    <PaymentsForm
      serviceId={serviceId}
      serviceType={SERVICE_TYPES.WITHDRAW}
      onSubmit={handler}
      isLoading={isLoading}
    />
  );
};

export default SubmitWithdraw;
