import React from 'react';

import { InnerHTMLSnippet } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { Box, FLEX_DIRECTION, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../../constants';
import usePaymentService from '../../../../hooks/usePaymentService';
import { useGetWithdrawableBalanceQuery } from '../../../../store/wallet';
import NoPaymentSystem from '../../../common/NoPaymentSystem';
import PaymentInfo from '../../../common/PaymentInfo';
import SubmitWithdraw from '../../../common/SubmitWithdraw';
import WithdrawBalance from '../../../common/WithdrawBalance';

import styles from './index.module.css';

type Props = {
  id: string;
};

const WithdrawMenu: Component<Props> = ({ id }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { system } = usePaymentService(SERVICE_TYPES.WITHDRAW, id);
  const { data } = useGetWithdrawableBalanceQuery(undefined);
  const { availableBalance = null } = data || {};
  const { currency } = system || {};
  const { min } = currency || {};

  if (!system) {
    return <NoPaymentSystem />;
  }

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      <PaymentInfo serviceType={SERVICE_TYPES.WITHDRAW} serviceId={id} />
      <WithdrawBalance />
      {availableBalance < min && (
        <FlexBox className={styles.message} justify={FLEX_JUSTIFY.center}>
          {t('wallet.withdraw.wrong.amount')}
        </FlexBox>
      )}

      {availableBalance >= min && (
        <FlexBox direction={FLEX_DIRECTION.column}>
          <Box className={styles.description}>
            <InnerHTMLSnippet html={t(`wallet.withdraw.description.${id}`)} />
          </Box>
          <SubmitWithdraw serviceId={id} />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default WithdrawMenu;
