import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useDebounce } from '@monorepo/helpers';
import { Component } from '@monorepo/type';

import { FIELD_TONE } from '../../../constants';
import SliderShadows from '../../SliderShadows';

import ArrowButtons from './components/ArrowButtons';

import styles from './index.module.css';

type TMenuProps = {
  isWithButtons?: boolean;
  transformStep?: number;
  sliderClassName?: string;
  toneType?: FIELD_TONE;
  translateXLength?: number | null;
};

const SlideMenu: Component<TMenuProps> = ({
  className = '',
  sliderClassName = '',
  isWithButtons = false,
  toneType = FIELD_TONE.dark,
  transformStep = 200,
  children,
  translateXLength
}) => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [scrollLength, setScrollLength] = useState(0);
  const [transformLength, setTransformLength] = useState(0);
  const slider = useRef<HTMLDivElement>(null);
  const { isDesktopDevice } = useDeviceDetector();
  const handleNext = useCallback(() => {
    const transform = transformLength - transformStep;
    setTransformLength(
      Math.abs(transform) > scrollLength ? -scrollLength : transform
    );
  }, [transformLength, scrollLength, transformStep]);

  const handlePrev = useCallback(() => {
    const transform = transformLength + transformStep;
    setTransformLength(transform > 0 ? 0 : transform);
  }, [transformLength]);

  useEffect(() => {
    if (translateXLength == null) return;
    setTransformLength(-translateXLength);
  }, [translateXLength]);

  useEffect(() => {
    if (slider.current) {
      const { scrollWidth = 0, clientWidth = 0 } = slider.current;
      if (scrollWidth > clientWidth) {
        setScrollLength(scrollWidth - clientWidth + 10);
        // setIsNextDisabled(false);
      }
    }
  }, [scrollLength, slider.current?.clientWidth]);

  useEffect(() => {
    if (slider.current?.clientWidth) {
      setIsPrevDisabled(transformLength >= 0);
      setIsNextDisabled(Math.abs(transformLength) >= scrollLength);
    }
  }, [transformLength, scrollLength, slider.current?.clientWidth]);

  const handleScroll = useDebounce(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { scrollLeft, scrollWidth, clientWidth } = e.target || {};
      setIsPrevDisabled(!Math.floor(scrollLeft));
      setIsNextDisabled(Math.floor(scrollWidth - scrollLeft) === clientWidth);
    },
    700
  );

  return (
    <SliderShadows
      className={`${styles.wrapper} ${className}`}
      isWithNext={!isNextDisabled}
      isWithPrev={!isPrevDisabled}
    >
      <div
        className={`${styles.slider} ${sliderClassName}`}
        style={{
          ...(isDesktopDevice && {
            transform: `translateX(${transformLength}px)`
          })
        }}
        ref={slider}
        onScroll={handleScroll}
      >
        {children}
      </div>

      {isWithButtons && (
        <ArrowButtons
          className={styles.arrowButtons}
          toneType={toneType}
          onNext={handleNext}
          onPrev={handlePrev}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
        />
      )}
    </SliderShadows>
  );
};

export default SlideMenu;
