import { memoize } from 'proxy-memoize';

import useGetPlayerInformation from '../useGetPlayerInformation';

const selectFavorites = (data, type) => {
  if (!data.data) {
    return null;
  }
  return type ? data?.data?.favorites[type] || null : data?.data?.favorites;
};

const useGetFavoriteIdsData = (type: string | null = null) => {
  const { data: favorites } = useGetPlayerInformation({
    selectFromResult: memoize((result: any) => ({
      data: selectFavorites(result, type)
    }))
  });
  return favorites;
};

export default useGetFavoriteIdsData;
