import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetPopupsQuery } from '../../store';

const useGetPopups = (type, device) => {
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();
  return useGetPopupsQuery({
    lang: languageLongCode,
    partner: siteId,
    type,
    device
  });
};

export default useGetPopups;
