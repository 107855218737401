import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { formatDate, fromString } from '@monorepo/helpers';

import { TUserProfile } from '../../../types/user';
// import {AUTH_QUERY_KEYS} from "../../../constants";

const formatMailingAddress = (userInfo) => {
  const {
    address,
    city,
    countryCode,
    zipCode,
    sameAddress,
    mailingAddress,
    mailingCity,
    mailingCountryCode,
    mailingZipCode
  } = userInfo;
  if (sameAddress) {
    return [address, city, countryCode, zipCode, sameAddress].join('#');
  }

  return [mailingAddress, mailingCity, mailingCountryCode, mailingZipCode].join(
    ' '
  );
};

const editProfile = () => ({
  query: (userInfo: TUserProfile['profile']) => ({
    body: {
      command: 'update_user',
      params: {
        user_info: omitBy(
          {
            confirmation_code: userInfo.confirmationCode,
            nick_name: userInfo.nickname,
            first_name: userInfo.firstName,
            last_name: userInfo.lastName,
            lang_code: userInfo.language,
            phone: userInfo.rawPhone
              ? userInfo.rawPhone.replace('+', '')
              : userInfo.phone &&
                `${userInfo.phoneCode}${userInfo.phone}`.replace('+', ''),
            password: userInfo.password,
            gender: userInfo.gender,
            doc_number: userInfo.docNumber,
            birth_region: userInfo.birthRegion
              ? userInfo.birthRegion.toUpperCase()
              : '',
            birth_city: userInfo.birthCity,
            birth_date: userInfo.birthDate
              ? formatDate(
                  fromString(userInfo.birthDate, 'dd-MM-yyyy'),
                  'yyyy-MM-dd'
                )
              : '',
            address: userInfo.address,
            city: userInfo.city,
            country_code: userInfo.countryCode,
            zip_code: userInfo.zipCode,
            additional_address: formatMailingAddress(userInfo)
          },
          isNil
        )
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result, result_text: message } = data;
      return !(code === 0 && result === 0) ? { code: result, message } : false;
    }
  })
  // invalidatesTags: [AUTH_QUERY_KEYS.USER]
});
export default editProfile;
