import { useEffect } from 'react';

const useHooryChatHide = (isVisible = true) => {
  useEffect(() => {
    const { $hoory: API } = window;
    if (API?.hasLoaded && isVisible) {
      API.toggleBubbleVisibility('hide');
    }
    return () => {
      if (API?.hasLoaded) {
        API.toggleBubbleVisibility('show');
      }
    };
  }, [isVisible]);
};
export default useHooryChatHide;
