import React from 'react';

import { useUserStatus } from '@monorepo/socials';
import { Component } from '@monorepo/type';
import { StatusMark } from '@monorepo/ui-kit';
import { SHOW_VALUE, useIsServerOnline } from '@monorepo/xmpp';

const UserStatus: Component = ({ className = '' }) => {
  const isServerOnline = useIsServerOnline();
  const { online, show = SHOW_VALUE.CHAT } = useUserStatus();

  return (
    <StatusMark
      className={className}
      isActive={online && show === SHOW_VALUE.CHAT}
      isPending={!isServerOnline}
      isHaveStatus={online}
    />
  );
};

export default UserStatus;
