import React, { ReactElement } from 'react';
import { memoize } from 'proxy-memoize';

import { NAMESPACES, useI18n } from '@monorepo/i18n/src';
import { CustomFC } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit/src';

import useGetMenuSports from '../../../../hooks/useGetMenuSports';
import { selectSportsWithOrder } from '../../../../store/api/selectors';
import SportItem from '../SportItem';
import MenuSkeleton from '../SportItemsListSkeleton';

type TSportsMenu = {
  activeSport: string;
  setSport: (sportAlias: string) => void;
  itemClassName?: string;
  noResultClassName: string;
};

const SportsMenu: CustomFC<TSportsMenu> = ({
  setSport,
  activeSport,
  itemClassName,
  noResultClassName
}): ReactElement => {
  const { data: sports, isLoading } = useGetMenuSports(undefined, {
    selectFromResult: memoize((result: any) => ({
      isLoading: result?.isLoading,
      data: selectSportsWithOrder(result)
    }))
  });
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  if (isLoading) {
    return <MenuSkeleton length={20} />;
  }

  if (!isLoading && !sports.length)
    return (
      <Box className={noResultClassName}>
        {t('sportsbook.sportsmenu.noresults')}
      </Box>
    );

  return (
    <>
      {sports.map((sportId: number) => (
        <SportItem
          key={sportId}
          activeSport={activeSport}
          sportId={sportId}
          setSport={setSport}
          className={itemClassName}
        />
      ))}
    </>
  );
};

export default SportsMenu;
