import React from 'react';

import { Component } from '@monorepo/type';
import { Box, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  label: string;
};

const FieldWrap: Component<Props> = ({ label, children, className = '' }) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    className={`${styles.wrapper} ${className}`}
  >
    <Box className={styles.label}>{label}</Box>
    {children}
  </FlexBox>
);

export default FieldWrap;
