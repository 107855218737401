import { TGameType } from '../types';

export enum MARKET_TYPES {
  SINGLE,
  THREE
}

export const MARKETS_WITH_TITLES = [8, 129, 128, 130, 193, 305, 334];

export enum MARKET_DISPLAY_KEYS {
  WINNER = 'WINNER',
  TOTALS = 'TOTALS',
  HANDICAP = 'HANDICAP'
}

export const MARKETS_ORDER_MAP = {
  [MARKET_TYPES.SINGLE]: [MARKET_DISPLAY_KEYS.WINNER],
  [MARKET_TYPES.THREE]: [
    MARKET_DISPLAY_KEYS.WINNER,
    MARKET_DISPLAY_KEYS.TOTALS,
    MARKET_DISPLAY_KEYS.HANDICAP
  ]
};

export const GAME_TYPES = {
  PREMATCH: 0,
  LIVE: 1,
  FUTURE_LIVE: 2
};

export const GAME_TYPES_MAP = {
  PREMATCH: {
    '@in': [GAME_TYPES.PREMATCH, GAME_TYPES.FUTURE_LIVE]
  },
  LIVE: GAME_TYPES.LIVE
} as Record<string, TGameType>;

export enum OUTCOME_VIEW_MODE {
  LINE = 'line',
  EVENT = 'event'
}

export const OUTCOME_NAME_MAP = {
  W1: '1',
  W2: '2',
  X: 'X',
  Over: 'O',
  Under: 'U',
  Home: '1',
  Away: '2'
} as Record<string, string>;

export const BET_TYPE = {
  SINGLE: 1,
  MULTIPLE: 2,
  SYSTEM: 3
};

export const ODD_MODE = {
  SUPER_BET: -1, // -1 Super bet mode
  NO_CHANGE: 0, // 0 Accept bet ONLY if odd has not been changed
  INCREASED: 1, // 1 Accept bet if odd has not been changed OR if odd has been increased
  ANY_CHANGE: 2 // 2 Accept bet with ANY odd changes
};

export enum BETSLIP_TYPE {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  QUICKBET = 'quickbet'
}

export enum BETSLIP_TYPE_VALUE {
  SINGLE = 1,
  MULTIPLE = 2
}

export enum BETSLIP_STATUS {
  IDLE = 'idle',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  CLOSE = 'close',
  FAILED = 'failed'
}

export enum PRICE_DELTA {
  UP = 'up',
  DOWN = 'down'
}

export const BETSLIP_SUCCESS_TIMER = 15000;

export const POPULAR_ALIAS = 'popular';
export const FAVORITE_TEAM_ALIAS = 'favorite-team';
export const BET_ID_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const DAY_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const BET_RESULTS = {
  WIN: 'Won',
  LOST: 'Lost',
  PAYOUT: 'Payout',
  POSSIBLE_WIN: 'Possible win'
};

export const OUTCOMES_RESULT = {
  NOT_RESULTED: 'Not Resulted',
  LOST: 'Lost',
  RETURNED: 'Returned',
  WON: 'Won',
  WON_RETURN: 'Won Return',
  LOST_RETURN: 'Lost',
  PLACE: 'Place',
  CASHED_OUT: 'CashedOut'
} as const;

export const OUTCOMES_VALUES = {
  NOT_RESULTED: 0,
  LOST: 1,
  RETURNED: 2,
  WON: 3,
  WON_RETURN: 5,
  LOST_RETURN: 6,
  PLACE: 7
} as const;

export const BET_VALUES = {
  NOT_RESULTED: 0,
  LOST: 1,
  RETURNED: 2,
  WON: 3,
  CASHED_OUT: 5,
  LOST_RETURN: 6,
  PLACE: 7
} as const;

export const ODD_TYPES = {
  DECIMAL: 'Decimal',
  FRACTIONAL: 'Fractional',
  AMERICAN: 'American',
  HONGKONG: 'HongKong',
  MALAY: 'Malay',
  INDO: 'Indo'
} as const;

export const BETS_TYPE = {
  SINGLE: 'Single',
  MULTIPLE: 'Multiple'
} as const;

export const BETS_STATUS = {
  WON: 'Won',
  LOST: 'Lost',
  NOT_RESULTED: 'NotResulted',
  CASHED_OUT: 'CashedOut',
  UNSETTLED: 'Unsettled',
  RETURNED: 'Returned',
  LOST_RETURN: 'LostReturn',
  WIN_RETURN: 'WinReturn'
} as const;

export const OUTCOMES = {
  0: OUTCOMES_RESULT.NOT_RESULTED,
  1: OUTCOMES_RESULT.LOST,
  2: OUTCOMES_RESULT.RETURNED,
  3: OUTCOMES_RESULT.WON,
  5: OUTCOMES_RESULT.WON_RETURN,
  6: OUTCOMES_RESULT.LOST_RETURN,
  7: OUTCOMES_RESULT.PLACE
};

export const ODD_TYPE = {
  0: ODD_TYPES.DECIMAL,
  1: ODD_TYPES.FRACTIONAL,
  2: ODD_TYPES.AMERICAN,
  3: ODD_TYPES.HONGKONG,
  4: ODD_TYPES.MALAY,
  5: ODD_TYPES.INDO
};

export const BET_TYPE_RESULT = {
  1: BETS_TYPE.SINGLE,
  2: BETS_TYPE.MULTIPLE
};

export const BET_STATUS_RESULT = {
  1: BETS_STATUS.LOST,
  2: BETS_STATUS.RETURNED,
  3: BETS_STATUS.WON,
  5: BETS_STATUS.CASHED_OUT
};

export const OUTCOME_STATUS_RESULT = {
  1: BETS_STATUS.LOST,
  2: BETS_STATUS.RETURNED,
  3: BETS_STATUS.WON,
  5: BETS_STATUS.WIN_RETURN,
  6: BETS_STATUS.LOST_RETURN
};

export const animationsLang = {
  en: 'eng',
  de: 'ger',
  es: 'spa',
  pt: 'por',
  pb: 'pt-br',
  ru: 'rus',
  hy: 'arm',
  ka: 'geo',
  ar: 'arb',
  tr: 'tur',
  zh: 'chn',
  ko: 'kor',
  pl: 'pol',
  uk: 'ukr',
  fa: 'fas'
};

export const CASH_OUT_TYPE = {
  FULL: 1,
  PARTIAL: 2
};

export const settingItems = [
  {
    label: 'sportsbook.betslip.settings.nochange',
    value: ODD_MODE.NO_CHANGE
  },
  {
    label: 'sportsbook.betslip.settings.increased',
    value: ODD_MODE.INCREASED
  },
  {
    label: 'sportsbook.betslip.settings.anychange',
    value: ODD_MODE.ANY_CHANGE
  }
];

export const cashOutItems = [
  {
    label: 'sportsbook.full.cash_out',
    value: CASH_OUT_TYPE.FULL
  },
  {
    label: 'sportsbook.partial.cash_out',
    value: CASH_OUT_TYPE.PARTIAL
  }
];

export const AMOUNT_REGEXP = /^[0-9]*[.]?[0-9]+$/;

export const SPORT_ALIAS_WITH_DRAW = [
  'soccer',
  'icehockey',
  'chess',
  'futsal',
  'handball',
  'hockey',
  'rinkhockey',
  'gaelicfootball',
  'floorball'
];

export const SPORTS_QUERY_KEYS = {
  BET: 'BET',
  BETS: 'BETS',
  COMPETITION: 'COMPETITION',
  COMPETITIONS: 'COMPETITIONS',
  POPULAR_COMPETITIONS: 'POPULAR_COMPETITIONS',
  GAME: 'GAME',
  GAMES: 'GAMES',
  MARKET_GROUPS: 'MARKET_GROUPS',
  EVENT_MARKETS: 'EVENT_MARKETS',
  MARKETS: 'MARKETS',
  REGIONS: 'REGIONS',
  SPORTS: 'SPORTS',
  BETS_COUNT: 'BETS_COUNT',
  SUGGESTED_GAMES: 'SUGGESTED_GAMES',
  FREE_BET: 'FREE_BET',
  FAVORITE_TEAM: 'FAVORITE_TEAM'
};

export const SPORTS_PERIODS = {
  americanfootball: 'americanfootball',
  aussierules: 'americanfootball',
  basketball: 'americanfootball',
  ebasketball: 'americanfootball',
  netball: 'americanfootball',
  waterpolo: 'waterpolo',
  fieldhockey: 'waterpolo',
  icehockey: 'waterpolo',
  soccer: 'soccer',
  futsal: 'soccer',
  cyberfootball: 'soccer',
  rugbyleague: 'soccer',
  rugbyunion: 'soccer',
  rugbysevens: 'soccer',
  kabaddi: 'soccer',
  handball: 'handball',
  beachfootball: 'handball',
  beachhandball: 'handball',
  floorball: 'handball',
  bandy: 'handball',
  baseball: 'baseball',
  cricket: 'baseball',
  tennis: 'tennis',
  beachvolleyball: 'tennis',
  volleyball: 'tennis',
  tabletennis: 'tennis',
  boxing: 'boxing',
  basketballshots: 'basketballshots',
  valorant: 'counterstrike',
  dota2: 'counterstrike',
  rainbowsix: 'counterstrike',
  leagueoflegends: 'counterstrike',
  counterstrike: 'counterstrike',
  starcraft2: 'counterstrike',
  snooker: 'snooker',
  badminton: 'badminton',
  darts: 'darts',
  kingofglory: 'game',
  mobilelegends: 'game',
  rocketleague: 'game',
  squash: 'game',
  mma: 'boxing'
} as Record<string, string>;

export const SCORE_KEYS = {
  SCORE_1: 'score1',
  SCORE_2: 'score2'
};

export const SCORE_TYPES = {
  OUTRIGHT: 'OUTRIGHT'
};

export const GAME_STATE = {
  NOT_STARTED: 'notstarted'
};

export const SPORTS_COLORS = {
  favoritecompetitions: 'gold',
  'olympic-competitions': '#c63947',
  AirHockey: '#86bad9',
  AgeofEmpires: '#c77e61',
  AllianceofValiantArms: '#847221',
  AlpineSkiing: '#93c6e5',
  AmericanFootball: '#e7685c',
  ApexLegends: '#a81b24',
  Archery: '#f781d9',
  ArcheryH2H: '#a43e8a',
  CompoundArchery: '#a43e8a',
  ArenaofValor: '#3b387f',
  Athletics: '#0b5b99',
  AustralianFootball: '#119a18',
  AutoRacing: '#ac9afd',
  Badminton: '#70c2a7',
  BallHockey: '#168763',
  Bandy: '#5da167',
  Baseball: '#5caaa7',
  Basketball: '#fca601',
  '3x3Basketball': '#e1572a',
  BasketballShots: '#fca601',
  BeachHandball: '#ccbb87',
  BeachSoccer: '#bfaf7e',
  BeachFootball: '#bfaf7e',
  BeachVolleyball: '#d9c68f',
  BrawlStars: '#ff9317',
  Biathlon: '#bedbed',
  Boxing: '#0e7acc',
  CallOfDuty: '#826b4a',
  Canoe: '#0d72bf',
  Chess: '#edbd6f',
  Climbing: '#f7a600',
  Cricket: '#1bab7d',
  CrossCountrySkiing: '#bedbed',
  Curling: '#76bbef',
  Cycling: '#d9c68f',
  'Cyclo-Cross': '#d9c68f',
  CyberFootball: '#308231',
  Bowls: '#2a8c4c',
  Darts: '#d32939',
  Diving: '#118be9',
  EBasketball: '#b04913',
  Electronic: '#5e55a1',
  Equestrian: '#556158',
  FigureSkating: '#b1cdde',
  Floorball: '#72cddb',
  Formula1: '#b8292b',
  Futsal: '#65b5c2',
  Football: '#109121',
  Freestyle: '#edbe13',
  FreestyleWrestling: '#edbe13',
  GaelicFootball: '#358014',
  Go: '#e2e2e2',
  Golf: '#189946',
  GrassHockey: '#189443',
  Gymnastics: '#03b5a5',
  Handball: '#42777f',
  HardAthletics: '#0b5b99',
  Hockey: '#168763',
  HorseRacing: '#6d5e4d',
  Hurling: '#189946',
  IceHockey: '#50a2c6',
  Judo: '#0b5b99',
  LeagueOfLegends: '#034653',
  LeagueofLegendsWildRift: '#3280ff',
  Lacross: '#168763',
  LightAthletics: '#0b5b99',
  Luge: '#99c4de',
  MixedFootball: '#f94d4b',
  MobileLegends: '#a98157',
  Motorbikes: '#8d612e',
  Motorsport: '#8d612e',
  Nascar: '#b92f93',
  Netball: '#716d6d',
  Pesapallo: '#db8e48',
  PokerLive: '#116b4f',
  Poker: '#116b4f',
  Pool: '#a155a0',
  Rally: '#c28540',
  RinkHockey: '#e3bb6d',
  Rowing: '#0f81d9',
  Rugby: '#799755',
  RugbyLeague: '#799755',
  RugbyUnion: '#799755',
  RugbySevens: '#799755',
  Sepaktakraw: '#966a24',
  Shooting: '#d5d345',
  Shorttrackspeedskating: '#b1cdde',
  Skateboarding: '#ab865d',
  SkiJump: '#c4dae8',
  SkiJumping: '#c4dae8',
  Snooker: '#37b764',
  Snowboard: '#9dd3f5',
  Soccer: '#109121',
  Speedway: '#aca696',
  Squash: '#e1b93f',
  Sumo: '#b0a174',
  Surfing: '#3598e4',
  Swimming: '#118be9',
  SynchronizedSwimming: '#009ac9',
  Tablehockey: '#168763',
  TableTennis: '#839d24',
  Tennis: '#a19e3e',
  Triathlon: '#a1c4c6',
  Mma: '#868686',
  MortalKombatXL: '#a76812',
  GrecoRomanWrestling: '#3a54fb',
  GlobalOffensive: '#c28503',
  CounterStrike: '#cc581f',
  Dota: '#5e55a1',
  Dota2: '#9b1013',
  HeroesOfTheStorm: '#655e8a',
  WorldOfTanks: '#464a37',
  Headis: '#d82338',
  Hearthstone: '#9d7337',
  StarCraft: '#326d5d',
  StarCraft2: '#005895',
  Battlefield: '#da6123',
  CrossFire: '#926b25',
  Doom: '#be291e',
  'E-IceHockey': '#4196c2',
  ETennis: '#829d24',
  FIFA: '#efb724',
  FortiteBattleRoyale: '#5e3151',
  GearsofWar: '#a2191d',
  GuildWars2: '#c11d1f',
  HeroesofNewerth: '#76650f',
  iRacing: '#163885',
  Kabaddi: '#ee6c21',
  Karate: '#e61b23',
  KillerInstinct: '#00503b',
  KingOfGlory: '#73588e',
  Madden: '#9c1417',
  MarathonSwimming: '#051f6b',
  MarvelvsCapcom: '#1d418c',
  NBA2K: '#b11419',
  Overwatch: '#f68720',
  Olympics: '#ffbd01',
  Painkiller: '#871d1f',
  PistolShooting: '#3c5182',
  PistolHead2Head: '#3c5182',
  PMUHorseRacing: '#34a854',
  Pokemon: '#e8bc08',
  Politics: '#641f6e',
  ProEvolutionSoccer: '#ca171e',
  ProjectCARS: '#036185',
  PUBG: '#d6751c',
  PUBGMobile: '#d6751c',
  PUBGPlayerUnknwnsBattleground: '#d6751c',
  Quake: '#744428',
  Rainbow_Six_Siege: '#754428',
  RealSubspaceHockeyLeague: '#e56220',
  RocketLeague: '#04477f',
  Smite: '#ac7311',
  SpecialBets: '#ef8a00',
  SpecialForce2: '#917d1d',
  StreetFighter: '#c63a20',
  StreetFighter5: '#c63a20',
  SuperSmashBros: '#37466f',
  TeamFortress2: '#9e411a',
  Tekken: '#bf231b',
  Teqball: '#b84b24',
  Trackmania: '#0267a8',
  Halo: '#0a61a5',
  Oscar: '#dac860',
  RainbowSix: '#666d72',
  TVShowsAndMovies: '#3b6e2b',
  UnrealTournament: '#354d94',
  Vainglory: '#9c5f50',
  Valorant: '#c82833',
  VirtualFootball: '#8555a1',
  VirtualFootballPro: '#8555a1',
  virtualsports: '#8555a1',
  virtualdogs: '#8555a1',
  virtualhorses: '#8555a1',
  VirtualTennis: '#8555a1',
  Volleyball: '#dac179',
  WaterPolo: '#338fd6',
  Wrestling: '#b80f20',
  WarcraftIII: '#bb7013',
  WarThunder: '#b6171c',
  Weightlifting: '#990700',
  WorldOfWarcraft: '#d09214',
  Yachting: '#24537e',
  'X-Sports': '#a61f2d',
  FreeFire: '#786d6d',
  Pelota: '#0f67a7'
} as Record<string, string>;

export const BETS_HISTORY_PATHNAME = {
  LIVE: 'live',
  CASHED: 'cashed',
  OPEN: 'open',
  ALL: 'all'
};

export enum TEAM_ICON_SIZE {
  SMALL = 's',
  MEDIUM = 'm',
  BIG = 'b'
}

export enum ENTITY_NAME {
  EVENT = 'e',
  COMPETITION = 'c'
}

export const IMAGES_AMOUNT = 2000; // amount images in each folder from documentations

export enum TEAMS_ID_NAME {
  TEAM1ID = 'team1Id',
  TEAM2ID = 'team2Id'
}

export const SPORTS_RESULT_DATE_DELTA = 1;

export const EVENT_TABS_TYPE = {
  MARKETS: 'markets',
  STATISTICS: 'statistics',
  CHAT: 'chat'
};

export const QUICK_BUTTON = {
  USD: [10, 20, 100],
  TOM: [500000, 1000000, 5000000],
  IRT: [500, 1000, 5000],
  AOA: [200, 500, 1000]
};

export const DEFAULT_QUICK_BUTTON = [50, 250, 500];

export const TAX_RANGE_TYPE = {
  PROFIT: 1
};
