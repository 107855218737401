import useSelectInboxList from '../selectors/useSelectInboxList';
import useSelectNotifications from '../selectors/useSelectNotifications';

const useCountSocialNotifications = (): number => {
  const notifications = useSelectNotifications();
  const inbox = useSelectInboxList();

  return notifications.length + inbox.length;
};

export default useCountSocialNotifications;
