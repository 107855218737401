import get from 'lodash/get';

// eslint-disable-next-line import/prefer-default-export
export const favoriteTeamMatchesAdapter = (
  rawResponse: Record<string, any>
): any => {
  const game = get(rawResponse, 'data.game', null);
  if (game) {
    return Object.keys(game).map((key) => Number(key));
  }
  return null;
};
