import { TWithdrawalsHistory } from '../../../types';
import withdrawalsHistoryAdapter from '../adapters/withdrawalsHistoryAdapter';

const getWithdrawalsHistory = () => ({
  query: () => ({
    body: {
      command: 'get_withdrawals',
      params: {}
    }
  }),
  keepUnusedDataFor: 0,
  transformResponse(response: any): TWithdrawalsHistory[] {
    return !(
      response.withdrawal_requests &&
      Array.isArray(response.withdrawal_requests.request)
    )
      ? []
      : withdrawalsHistoryAdapter(response.withdrawal_requests.request);
  }
});
export default getWithdrawalsHistory;
