import React, { MouseEvent, useCallback } from 'react';

import { Component, TOptionItem } from '@monorepo/type';

import { FIELD_TONE, FLEX_ALIGN, FLEX_JUSTIFY } from '../../../../constants';
import EllipsisText from '../../../EllipsisText';
import FlexBox from '../../../FlexBox';

import styles from './index.module.css';

type Props = {
  item: TOptionItem;
  onSelect: (item: TOptionItem) => void;
  toneType?: FIELD_TONE;
};

const SelectItem: Component<Props> = ({
  className = '',
  item,
  onSelect,
  toneType = FIELD_TONE.dark
}) => {
  const { value, label } = item;

  const handleSelect = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onSelect(item);
    },
    [item, onSelect]
  );

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.start}
      className={`${styles.item} ${styles[toneType]} ${className}`}
      data-value={value}
      onClick={handleSelect}
      title={label as string}
    >
      <EllipsisText>{label}</EllipsisText>
    </FlexBox>
  );
};

export default SelectItem;
