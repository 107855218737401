import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import {
  FIELD_TONE,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Skeleton
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TProfileMenuSkeleton = {
  className?: string;
};

const ProfileSkeleton = ({ className = '' }: TProfileMenuSkeleton) => (
  <FlexBox justify={FLEX_JUSTIFY.center}>
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      {createSkeletonArray(5).map((i) => (
        <Skeleton
          className={`${styles.skeleton} ${className}`}
          key={i}
          toneType={FIELD_TONE.light}
        />
      ))}
    </FlexBox>
  </FlexBox>
);

export default ProfileSkeleton;
