import { useCallback } from 'react';

import {
  useGetFavoriteIdsData,
  useUpdateFavoritesProfile
} from '@monorepo/cms';

const useUpdateFavoritesIds = () => {
  const favorites = useGetFavoriteIdsData();
  const [onSave, { isLoading }] = useUpdateFavoritesProfile();

  const onSaveFavIds = useCallback(
    async (id: number | string, type: string) => {
      await onSave({
        favorites: {
          ...favorites,
          [type]: [...(favorites[type] || []), id]
        }
      }).reset();
    },
    [onSave, favorites]
  );

  const onRemoveAllFavIds = useCallback(
    async (type: string) => {
      await onSave({
        favorites: { ...favorites, [type]: [] }
      }).reset();
    },
    [onSave, favorites]
  );

  const onRemoveFavIds = useCallback(
    async (id: number | string, type: string) => {
      const updatedFavorites = favorites[type]?.filter(
        (favoriteId: number | string) => favoriteId !== id
      );

      await onSave({
        favorites: { ...favorites, [type]: updatedFavorites }
      }).reset();
    },
    [onSave, favorites]
  );

  return { onSaveFavIds, onRemoveAllFavIds, onRemoveFavIds, isLoading };
};

export default useUpdateFavoritesIds;
