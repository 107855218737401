import { getMucRooms } from '@monorepo/xmpp';

import {
  parseCommunityList,
  parseStanzaTypeError
} from '../../../utils/stanzaParsers';

const getCommunityList = () => ({
  query: () => getMucRooms(),
  transformResponse: parseCommunityList,
  transformErrorResponse: parseStanzaTypeError
});

export default getCommunityList;
