import { camelizeKeys } from 'humps';

import { TBetEvent, TBets, TBetsHistory } from '../../../types';

const betsHistoryAdapter = (response: TBetsHistory): TBets[] => {
  const result = response.bets.map(
    ({
      currency,
      amount,
      date_time,
      odd_type,
      id,
      k,
      outcome,
      possible_win,
      payout,
      type,
      events,
      cash_out
    }) => {
      const EVENTS = events.map((event) => camelizeKeys(event) as TBetEvent);

      return {
        currency,
        amount,
        dateTime: date_time,
        oddType: odd_type,
        id,
        oddCoeficient: k,
        outcome,
        payout,
        possibleWin: possible_win,
        type,
        events: EVENTS,
        cashOut: cash_out
      };
    }
  );

  return result;
};

export default betsHistoryAdapter;
