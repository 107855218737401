import { convertParams } from '@monorepo/helpers';

import {
  TAvailableTournamentsIds,
  TAvailableTournamentsRequest,
  TAvailableTournamentsResponse
} from '../../../types';
import availableTournamentsAdapter from '../adapters/availableTournamentsAdapter';

const getAvailableTournaments = () => ({
  query: ({ partnerId, isMobile, ids }: TAvailableTournamentsRequest) => {
    const params = {
      partner_id: partnerId,
      is_mobile: isMobile,
      ids
    };
    return `getAvailableTournaments?${convertParams(params)}`;
  },
  transformResponse(
    response: TAvailableTournamentsResponse
  ): TAvailableTournamentsIds {
    return !response ? [] : availableTournamentsAdapter(response);
  }
});
export default getAvailableTournaments;
