import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import Element from 'ltx/src/Element';

import { throttleEvents } from '@monorepo/common';
import {
  getIdFromJID,
  isJIDsEqual,
  PRESENCE_TYPE,
  XML_NAMESPACES,
  XMPPClient
} from '@monorepo/xmpp';

import { TSocialCallbacks, TSocialsActions } from '../../../../types';
import getPresenceKickStatus from '../../../../utils/getPresenceKickStatus';
import { createFriendRequestNotification } from '../../../../utils/notifications';
import { filterLocalStorageRequestsList } from '../../../../utils/requestsListStorage';
import {
  parseMucUserPresence,
  parsePresence,
  parseStanzaTypeError
} from '../../../../utils/stanzaParsers';

export default class PresenceHandler {
  reduxActions: TSocialsActions;

  instance: XMPPClient;

  callbacks: TSocialCallbacks;

  updateRoster;

  constructor(
    instance: XMPPClient,
    reduxActions: TSocialsActions,
    callbacks: TSocialCallbacks
  ) {
    this.instance = instance;
    this.reduxActions = reduxActions;
    this.callbacks = callbacks;
    this.updateRoster = throttleEvents(
      (eventsBatch) => {
        const items = values(groupBy(eventsBatch, 'id')).map(
          (events) => events[events.length - 1]
        );
        reduxActions.updateRosterItem(items);
      },
      5000,
      false
    );
  }

  public processStanza(stanza: Element) {
    const { updateUser, addSubscribeRequests } = this.reduxActions;
    const { displayToast } = this.callbacks;

    const xData = stanza.getChild('x', XML_NAMESPACES.MUC);

    if (xData) {
      const presence = parseMucUserPresence(stanza);
      const { status, destroy } = presence;
      // eslint-disable-next-line no-console
      // console.log(`xdata-${stanza?.attrs?.id}`, stanza.toString());
      // eslint-disable-next-line no-console
      // console.log(`xdata-${stanza?.attrs?.id}`, presence);
      const kickStatus = getPresenceKickStatus(status);
      // eslint-disable-next-line no-console
      // console.log(status, kickStatus);
      if (kickStatus || destroy) {
        // eslint-disable-next-line no-console
        console.log('kick'); // todo clear
      }
    } else {
      const { from, type, status, show, delay } = parsePresence(stanza);
      const userId = getIdFromJID(from);

      if (!type && isJIDsEqual(from, this.instance.jid)) {
        updateUser({ status, show, online: true, presence: undefined });
      } else if (!type || type === PRESENCE_TYPE.UNAVAILABLE) {
        this.updateRoster({
          id: userId,
          status,
          show,
          delay,
          online: type !== PRESENCE_TYPE.UNAVAILABLE
        });
      } else if (type === PRESENCE_TYPE.SUBSCRIBE) {
        addSubscribeRequests(from);
        displayToast(createFriendRequestNotification(from));
      } else if (type === PRESENCE_TYPE.UNSUBSCRIBED) {
        filterLocalStorageRequestsList(from);
      } else if (type === PRESENCE_TYPE.ERROR) {
        const error = parseStanzaTypeError(stanza);
        // eslint-disable-next-line no-console
        console.log(`Presence error: ${JSON.stringify(error)}`);
      }
    }
  }
}
