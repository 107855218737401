import React, { useState } from 'react';
import QRCode from 'react-qr-code';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Box, FIELD_TONE, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import TwoFactorAuthAddForm from './components/TwoFactorAuthAddForm';
import TwoFactorMore from './components/TwoFactorMore';

import styles from './index.module.css';

const TwoFactorAuth = ({
  qrCode,
  toneType = FIELD_TONE.light,
  onCustomHandler = () => null
}: {
  qrCode?: string;
  toneType?: FIELD_TONE;
  onCustomHandler?: () => void;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const [showMore, setIsShowMore] = useState(false);
  const handleChange = () => setIsShowMore((prevState) => !prevState);

  return (
    <Box className={`${styles.wrap} ${styles[toneType]}`}>
      {qrCode && (
        <Box>
          <Box className={styles.description}>
            {t('two.factor.activate.text')}
          </Box>
          <Box className={styles.form} onClick={handleChange}>
            <FlexBox justify={FLEX_JUSTIFY.center} className={styles.link}>
              <Box className={styles.decor}>
                <Box className={styles.corner}>
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={qrCode}
                    viewBox="0 0 256 256"
                  />
                </Box>
              </Box>
            </FlexBox>
            <Box className={styles.showMore}>
              {t('two.factor.cant.scan.button')}
            </Box>
          </Box>
          {showMore && (
            <TwoFactorMore qrCodeOrigin={qrCode} toneType={toneType} />
          )}
        </Box>
      )}
      <TwoFactorAuthAddForm
        toneType={toneType}
        onCustomHandler={onCustomHandler}
      />
    </Box>
  );
};

export default TwoFactorAuth;
