import React from 'react';

import { Icon } from '@monorepo/icons';

import { FIELD_TONE, FLEX_ALIGN } from '../../../constants';
import { SelectProps } from '../../../types';
import FlexBox from '../../FlexBox';

import styles from './index.module.css';

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      options = [],
      className = '',
      controlClassName = '',
      toneType = FIELD_TONE.dark,
      disabled,
      placeholder,
      ...props
    },
    ref
  ) => {
    const classes = `${styles.wrap}
      ${styles[toneType]}
      ${disabled ? styles.disabled : ''}
      ${className}`;

    return (
      <FlexBox className={classes} align={FLEX_ALIGN.center}>
        <select
          ref={ref}
          disabled={disabled}
          className={`${controlClassName} ${styles.select}`}
          {...props}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {options.map(({ label, value }) => (
            <option key={value as string} value={value}>
              {label}
            </option>
          ))}
        </select>
        <Icon name="arrow_down-3" className={styles.icon} title="" />
      </FlexBox>
    );
  }
);

export default Select;
