import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const GamesByCategory = lazy(() => import('./GamesByCategory'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <GamesByCategory {...props} />
  </Suspense>
);
