import {
  TWinnersList,
  TWinnersRequest,
  TWinnersResponse
} from '../../../types';
import getWinnersAdapter from '../adapters/getWinnersAdapter';

const getLastWinners = () => ({
  query: ({ count }: TWinnersRequest) => ({
    body: {
      command: 'get_partner_last_wins',
      params: {
        count
      }
    }
  }),
  transformResponse(response: TWinnersResponse): TWinnersList {
    const { result } = response || {};
    const isWithError = result !== 0;
    return !response || isWithError ? [] : getWinnersAdapter(response);
  }
});
export default getLastWinners;
