import { useCallback } from 'react';
import format from 'date-fns/format';

import { DATE_LOCALE_MAP } from '../../constants';
import useI18n from '../useI18n';

const useFormatDate = () => {
  const { language } = useI18n();

  return useCallback(
    (date: Date | number, formatString: string = 'dd-MM-yyyy') =>
      format(date, formatString, { locale: DATE_LOCALE_MAP[language] }),
    [language]
  );
};

export default useFormatDate;
