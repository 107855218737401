// import Element from 'ltx/src/Element';
import { setInbox } from '@monorepo/xmpp';

import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const getInbox = () => ({
  query: () => setInbox(),
  transformResponse: (/* res: Element */) =>
    // console.log(res);
    null,
  transformErrorResponse: parseStanzaTypeError
});

export default getInbox;
