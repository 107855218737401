import React from 'react';

import { Component } from '@monorepo/type';

import { FIELD_TONE, TEXT_SIZE } from '../../../constants';
import Box from '../../Box';

import styles from './index.module.css';

const NotificationText: Component = ({
  className = '',
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small,
  children
}) => (
  <Box
    className={`${styles[toneType]} ${styles[textSize]} ${styles.text} ${className}`}
  >
    {children}
  </Box>
);

export default NotificationText;
