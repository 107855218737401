import { useIsAuthenticated } from '@monorepo/auth';
import useSelectAuthData from '@monorepo/auth/src/hooks/selectors/useSelectAuthData';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetRecentPlayedCasinoGamesQuery } from '../../store/casinoLastGamesApi';

const useGetRecentPlayedCasinoGames = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const authData = useSelectAuthData();
  const { lastPlayedGames } = useSiteSettings();
  return useGetRecentPlayedCasinoGamesQuery(
    { clientId: authData?.userId, limit: lastPlayedGames },
    { pollingInterval: 10000, skip: !isAuthenticated }
  );
};

export default useGetRecentPlayedCasinoGames;
