import { TGetLimit } from '../../../types';
import getUserLimitsAdapter from '../adapters/getUserLimits';

const getUserLimits = () => ({
  query: (type: string) => ({
    body: {
      command: 'user_limits',
      params: {
        type
      }
    }
  }),
  transformResponse(response): Promise<TGetLimit> | TGetLimit {
    return getUserLimitsAdapter(response);
  }
});
export default getUserLimits;
