import { useTypedSelector } from '@monorepo/redux';

import { TStoreInboxMessage } from '../../../types';

export const getInbox = (state: any): Array<TStoreInboxMessage> =>
  state.socialsSlice.inbox.filter(
    ({ unread }: TStoreInboxMessage) => unread !== '0'
  );

const useSelectInboxList = (): Array<TStoreInboxMessage> =>
  useTypedSelector(getInbox);

export default useSelectInboxList;
