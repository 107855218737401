import { useGetPlayerProfileSettings, useSelectAuthData } from '@monorepo/auth';

const getConfigData = ({ data }) =>
  data ? { data: data?.configuration } : { data: null };

const usePlayerProfileSettings = (options = {}) => {
  const authData = useSelectAuthData();

  return useGetPlayerProfileSettings(authData?.ownerId || authData?.userId, {
    ...options,
    selectFromResult: getConfigData
  });
};

export default usePlayerProfileSettings;
