import Element from 'ltx/src/Element';

import { createGroupUnreadMessageNotification } from '../../../../utils/notifications';
import { groupMessageParse } from '../../../../utils/stanzaParsers';
import MessageHandler from '../MessageHandler';

class GroupChatMessageHandler {
  messageHandler: MessageHandler;

  constructor(handler: MessageHandler) {
    this.messageHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { addMessage } = this.messageHandler.reduxActions;
    const { displayToast } = this.messageHandler.callbacks;
    const message = groupMessageParse(stanza, this.messageHandler.jidString);
    if (message?.text) {
      addMessage(message);
      displayToast(
        createGroupUnreadMessageNotification(message.roomJid, message.from)
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(`Empty group chat message ${stanza}`);
    }
  };
}

export default GroupChatMessageHandler;
