import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

const NoPaymentSystem: Component = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.wrap}
    >
      {t('wallet.not_supported_system')}
    </FlexBox>
  );
};

export default NoPaymentSystem;
