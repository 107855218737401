import { camelizeKeys } from 'humps';
import orderBy from 'lodash/orderBy';

import { TGameResults, TResultsGamesRespponse } from '../../../types';

const getResultsGamesAdapter = (
  response: TResultsGamesRespponse
): TGameResults[] => {
  const transformedGames = response?.games?.game?.map(
    (el) => camelizeKeys(el) as TGameResults
  );
  const gamesOrderedByDate = orderBy(transformedGames, 'date');

  return gamesOrderedByDate || [];
};

export default getResultsGamesAdapter;
