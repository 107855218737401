import React, { useMemo } from 'react';

import { TaxInfo } from '@monorepo/auth/src/types';
import { useConvertAmount } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { TAX_RANGE_TYPE } from '../../constants';
import ProfitTaxes from '../ProfitTaxes';

import styles from './index.module.css';

type Props = {
  taxOnStake: number;
  netStake: number;
  possibleWinAfterTax: number;
  currency: string;
  taxAmountRanges?: TaxInfo[];
  className?: string;
};

const PossibleWinAndTaxes = ({
  taxOnStake,
  netStake,
  possibleWinAfterTax,
  currency,
  taxAmountRanges,
  className = ''
}: Props) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  const taxOnStakeAmount = useConvertAmount(taxOnStake);
  const netStakeAmount = useConvertAmount(netStake);
  const possibleWinAfterTaxAmount = useConvertAmount(possibleWinAfterTax);

  const renderProfitTax = useMemo(() => {
    const profit = possibleWinAfterTax - netStake;
    if (taxAmountRanges == null || taxAmountRanges.length <= 0) {
      return null;
    }
    const range = taxAmountRanges.find(
      (r) => profit >= r.from && profit <= r.to
    );

    if (range == null) {
      return null;
    }

    const taxBase =
      possibleWinAfterTax -
      (range.type === TAX_RANGE_TYPE.PROFIT ? netStake : 0) -
      (range.entireAmount ? 0 : range.from);
    const tax = range?.percent ? (taxBase * range.percent) / 100 : 0;

    return tax > 0 ? (
      <ProfitTaxes
        tax={tax}
        possibleWinAfterTax={possibleWinAfterTax}
        currency={currency}
        itemClassName={styles.possibleWinItem}
        amountClassName={styles.amount}
      />
    ) : null;
  }, [possibleWinAfterTax, netStake, taxAmountRanges, t, currency]);

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.possibleWinList} ${className}`}
    >
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        direction={FLEX_DIRECTION.row}
        align={FLEX_ALIGN.center}
        className={styles.possibleWinItem}
      >
        <Box>{t('sportsbook.betslip.bet.tax.on.stake')}</Box>
        <Box className={styles.amount}>
          {taxOnStakeAmount} {currency}
        </Box>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        direction={FLEX_DIRECTION.row}
        align={FLEX_ALIGN.center}
        className={styles.possibleWinItem}
      >
        <Box>{t('sportsbook.betslip.bet.net.stake')}</Box>
        <Box className={styles.amount}>
          {netStakeAmount} {currency}
        </Box>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        direction={FLEX_DIRECTION.row}
        align={FLEX_ALIGN.center}
        className={styles.possibleWinItem}
      >
        <Box>{t('sportsbook.betslip.bet.possible.win')}</Box>
        <Box className={styles.amount}>
          {possibleWinAfterTaxAmount} {currency}
        </Box>
      </FlexBox>
      {renderProfitTax}
    </FlexBox>
  );
};

export default PossibleWinAndTaxes;
