import { useCallback, useEffect, useRef } from 'react';

type TUseAnimationFrame = {
  callback: () => void;
  delay?: number;
  duration?: number;
  shouldAnimate?: boolean;
};

const useAnimationFrame = ({
  callback,
  delay = 0,
  duration = Number.POSITIVE_INFINITY,
  shouldAnimate = true
}: TUseAnimationFrame) => {
  const frameRef = useRef<number>(0);
  const firstTimeRef = useRef(performance.now());
  const previousTimeRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const animate = useCallback(
    (time) => {
      let timeFraction = (time - firstTimeRef.current) / duration;

      if (timeFraction > 1) {
        timeFraction = 1;
      }

      if (timeFraction <= 1) {
        if (previousTimeRef.current) {
          const deltaTime = time - previousTimeRef.current;
          if (deltaTime >= delay) {
            savedCallback.current();
            previousTimeRef.current = time;
          }
        } else {
          previousTimeRef.current = time;
        }
      }

      if (timeFraction !== 1) {
        frameRef.current = requestAnimationFrame(animate);
      }
    },
    [callback, delay, duration]
  );

  useEffect(() => {
    if (shouldAnimate) {
      frameRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(frameRef.current);
    }
    return () => cancelAnimationFrame(frameRef.current);
  }, [shouldAnimate]);
};

export default useAnimationFrame;
