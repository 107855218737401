import React, { ChangeEvent, ReactElement, useState } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  Button,
  BUTTON_INTENT,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Radio
} from '@monorepo/ui-kit';

import { ODD_MODE } from '../../../../constants';
import useActions from '../../../../hooks/useActions';
import { useSelectOddMode } from '../../../../store/api/selectors/betslipSelector';

import styles from './index.module.css';

const settingItems = [
  {
    label: 'sportsbook.betslip.settings.nochange',
    value: ODD_MODE.NO_CHANGE
  },
  {
    label: 'sportsbook.betslip.settings.increased',
    value: ODD_MODE.INCREASED
  },
  {
    label: 'sportsbook.betslip.settings.anychange',
    value: ODD_MODE.ANY_CHANGE
  }
];

type TSetting = {
  label: string;
  value: number;
};

type TBetslipSettings = {
  isLight?: boolean;
  classModal?: string;
  setActiveSetting?: React.Dispatch<React.SetStateAction<TSetting>>;
};

const BetslipSettings: CustomFC<TBetslipSettings> = ({
  isLight = false,
  classModal = '',
  setActiveSetting
}): ReactElement => {
  const [isVisible, setVisible] = useState(false);
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const { setOddMode } = useActions();
  const oddMode = useSelectOddMode();

  const toggleBetslipSetting = () => {
    setVisible((prevState) => !prevState);
  };

  const handleOddModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOddMode(Number(value));

    if (setActiveSetting) {
      const activeSetting = settingItems.filter(
        (el) => el.value === Number(value)
      );
      const [data] = activeSetting;
      setActiveSetting(data);
    }
    toggleBetslipSetting();
  };

  const modalClassName = `${styles.menu} ${classModal}`;
  const toneType = isLight ? FIELD_TONE.light : FIELD_TONE.dark;
  const classes = `${styles[toneType]} ${styles.icon}`;

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.root}
    >
      <Button
        className={styles.settingsBtn}
        isOnlyIcon
        intent={BUTTON_INTENT.tertiary}
        onClick={toggleBetslipSetting}
        data-custom="bet-slip-settings"
      >
        <Icon name="settings_1" title="" className={classes} />
      </Button>
      {isVisible && (
        <FlexBox className={modalClassName} direction={FLEX_DIRECTION.column}>
          <Box className={styles.header}>
            {t('sportsbook.betslip.settings.title')}
          </Box>

          <FlexBox direction={FLEX_DIRECTION.column}>
            {settingItems.map(({ label, value }) => (
              <Radio
                key={value}
                className={styles.input}
                name="setting"
                label={t(`${label}`)}
                value={value}
                onChange={handleOddModeChange}
                checked={oddMode === value}
                toneType={toneType}
              />
            ))}
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default BetslipSettings;
