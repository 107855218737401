import React from 'react';

import { FIELD_TONE } from '../../../constants';
import { TextAreaProps } from '../../../types';

import styles from './index.module.css';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, toneType = FIELD_TONE.dark, ...props }, ref) => (
    <textarea
      ref={ref}
      className={`${styles.input} ${styles[toneType]} ${className}`}
      {...props}
    />
  )
);

export default TextArea;
