import Deposit from '../components/Deposit';
import DepositMobile from '../components/DepositMobile';
import WalletFilters from '../components/WalletFilters';
import WalletHistory from '../components/WalletHistory';
import Withdraw from '../components/Withdraw';
import WithdrawMobile from '../components/WithdrawMobile';
import WithdrawStatus from '../components/WithdrawStatus';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.WALLET_DEPOSIT]: Deposit,
  [COMPONENT_TYPES.WALLET_DEPOSIT_MOBILE]: DepositMobile,
  [COMPONENT_TYPES.WALLET_WITHDRAW]: Withdraw,
  [COMPONENT_TYPES.WALLET_WITHDRAW_MOBILE]: WithdrawMobile,
  [COMPONENT_TYPES.WALLET_WITHDRAW_STATUS]: WithdrawStatus,
  [COMPONENT_TYPES.WALLET_HISTORY]: WalletHistory,
  [COMPONENT_TYPES.WALLET_FILTERS]: WalletFilters
};

export default COMPONENTS_MAP;
