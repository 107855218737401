import { useTypedSelector } from '@monorepo/redux';

import useIsAuthenticated from '../useIsAuthenticated';

const getIsCookiesFetched = (state) => state.user.isCookiesFetched;

const useHasCookies = () => {
  const isCookiesFetched = useTypedSelector(getIsCookiesFetched);
  const { isAuthenticated } = useIsAuthenticated();

  return isAuthenticated && isCookiesFetched;
};
export default useHasCookies;
