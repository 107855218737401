import React from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';

import { CATEGORIES_ICONS_MAP } from '../../constants';

type Props = {
  categoryId: string;
};

const CategoryIcon: Component<Props> = ({ categoryId, className = '' }) => {
  if (CATEGORIES_ICONS_MAP[categoryId]) {
    return (
      <Icon name={CATEGORIES_ICONS_MAP[categoryId]} className={className} />
    );
  }
  return null;
};

export default CategoryIcon;
