import { TLossLimitsRequest } from '../../../types';

const setUserLossLimits = () => ({
  query: ({
    lossPerMonth,
    lossPerDay,
    lossPerDayRadio,
    lossPerMonthRadio
  }: TLossLimitsRequest) => ({
    body: {
      command: 'set_sport_loss_limit',
      params: {
        daily_loss: lossPerDayRadio === '1' ? lossPerDay : '',
        monthly_loss: lossPerMonthRadio === '1' ? lossPerMonth : ''
      }
    }
  })
});
export default setUserLossLimits;
