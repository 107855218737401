import { useContext } from 'react';

import ThemeContext from '../../store/context';

const useTheme = (): string => {
  const [theme] = useContext(ThemeContext);

  return theme;
};

export default useTheme;
