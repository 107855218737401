import React, { SetStateAction, useEffect, useState } from 'react';

import { useSetTimeout } from '@monorepo/helpers';

import { COMPONENTS_MAP, NOTIFICATION_TYPES } from '../../constants';
import useNotifications from '../../hooks/useNotifications';
import useNotificationState from '../../hooks/useNotificationState';
import DefaultNotification from '../DefaultNotification';

const Notification = () => {
  const { closeNotification } = useNotifications();
  const { isShown, notificationType, timeout, successTimeout, onClose } =
    useNotificationState();
  const notificationTimeout =
    notificationType === NOTIFICATION_TYPES.SUCCESS ? successTimeout : timeout;
  const [delay, setDelay] =
    useState<SetStateAction<null | number>>(notificationTimeout);

  const handleMouseEnter = () => setDelay(null);
  const handleMouseLeave = () => setDelay(notificationTimeout);

  useSetTimeout(() => {
    closeNotification();
    onClose?.();
  }, (isShown ? delay : null) as null | number);

  useEffect(() => setDelay(notificationTimeout), [notificationTimeout]);

  if (!isShown) {
    return null;
  }

  const Component =
    (notificationType && COMPONENTS_MAP[notificationType]) ||
    DefaultNotification;

  return <Component {...{ handleMouseEnter, handleMouseLeave }} />;
};

export default Notification;
