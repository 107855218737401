import Element from 'ltx/src/Element';

import MessageHandler from '../../handlers/MessageHandler';

const messageStanzaCallback =
  (messageHandler: MessageHandler) => (stanza: Element) => {
    if (stanza.is('message')) {
      return messageHandler.processStanza(stanza);
    }
    return stanza;
  };

export default messageStanzaCallback;
