import { useMemo } from 'react';

import { useGetFavoriteIdsData } from '@monorepo/cms';

const useIsActiveFavoriteId = (id: string, type: string) => {
  const favorites = useGetFavoriteIdsData(type);

  return useMemo(
    () => (favorites ? favorites.includes(id) : false),
    [id, JSON.stringify(favorites)]
  );
};

export default useIsActiveFavoriteId;
