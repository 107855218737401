import {
  useGetJWTQuery,
  useIsAuthenticated,
  useSelectAuthData
} from '@monorepo/auth';
import { useSiteSettings } from '@monorepo/site-settings';

import useNavigateUnauthenticated from '../useNavigateUnauthenticated';

const useGametaRedirect = () => {
  const { generalRoutes } = useSiteSettings();
  const { isAuthenticated } = useIsAuthenticated();
  const authData = useSelectAuthData();
  const { authToken = '' } = authData || {};
  const { data } = useGetJWTQuery(undefined, { skip: !isAuthenticated });
  const { navigateToLogin } = useNavigateUnauthenticated();

  const checkMeta = (additionalLink: string) => {
    if (!authToken) {
      navigateToLogin(generalRoutes.home);
      return;
    }
    if (data && data?.length && additionalLink) {
      window.location.replace(
        `${additionalLink}?auth_token=${authToken}&jwe_token=${data}`
      );
    }
  };

  return { checkMeta };
};
export default useGametaRedirect;
