import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetMenuQuery } from '../../store';

const useGetMenu = (menuId: number) => {
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();

  return useGetMenuQuery({
    menuId,
    partnerId: siteId,
    lng: languageLongCode
  });
};

export default useGetMenu;
