import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component, TQueryResult } from '@monorepo/type';
import { FLEX_DIRECTION } from '@monorepo/ui-kit';

import { OUTCOME_NAME_MAP, OUTCOME_VIEW_MODE } from '../../constants';
import useAddToBetslip from '../../hooks/useAddToBetslip';
import useGetBoostedOdds from '../../hooks/useGetBoostedOdds';
import useGetLineGames from '../../hooks/useGetLineGames';
import useGetLineMarkets from '../../hooks/useGetLineMarkets';
import {
  selectGameBySportIdRegionIdCompetitionIdGameId,
  selectGameMarket,
  selectIsOutcomeBoosted,
  selectOutcomeByGameIdMarketIdOutcomeId
} from '../../store/api/selectors';
import {
  TBoostedOddsResponse,
  TNormalizedLineMarkets,
  TNormalizedSports
} from '../../types';
import Outcome from '../Outcome';

type Props = {
  gameId: number;
  marketId: number;
  outcomeId: number;
  direction?: FLEX_DIRECTION;
  viewMode?: OUTCOME_VIEW_MODE;
  competitionId: number;
  sportId: number;
  regionId: number;
};

const LineOutcome: Component<Props> = ({
  className = '',
  gameId,
  marketId,
  outcomeId,
  direction = FLEX_DIRECTION.column,
  viewMode,
  competitionId,
  sportId,
  regionId
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const onAddBet = useAddToBetslip();
  const { data: game } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectGameBySportIdRegionIdCompetitionIdGameId(
        result,
        sportId,
        regionId,
        competitionId,
        gameId,
        ['team1_name', 'team2_name', 'start_ts']
      )
    }))
  });

  const { data: market } = useGetLineMarkets(undefined, {
    selectFromResult: memoize(
      (result: TQueryResult<TNormalizedLineMarkets>) => ({
        data: selectGameMarket(result, gameId, marketId)
      })
    )
  });

  const { data: outcome } = useGetLineMarkets(undefined, {
    selectFromResult: memoize(
      (result: TQueryResult<TNormalizedLineMarkets>) => ({
        data: selectOutcomeByGameIdMarketIdOutcomeId(
          result,
          gameId,
          marketId,
          outcomeId
        )
      })
    )
  });

  const { data: isBoosted } = useGetBoostedOdds({
    selectFromResult: memoize((result: TQueryResult<TBoostedOddsResponse>) => ({
      data: selectIsOutcomeBoosted(result, gameId, outcomeId)
    }))
  });

  const { startTs, team1Name, team2Name = '' } = game;
  const { name } = market;
  const { price, type1, name: eventName = '', base } = outcome;

  const handleClick = useCallback(
    () =>
      onAddBet(
        outcomeId,
        gameId,
        marketId,
        price,
        startTs,
        team1Name,
        team2Name,
        name,
        eventName,
        base
      ),
    [outcomeId, gameId, onAddBet]
  );

  if (isEmpty(outcome)) {
    return <Outcome isEmpty className={className} />;
  }

  const labelShort = t(`sportsbook.${OUTCOME_NAME_MAP[type1]}.short`);

  return (
    <Outcome
      coefficient={price}
      label={labelShort}
      id={outcomeId}
      onClick={handleClick}
      isBoosted={Boolean(isBoosted)}
      className={className}
      direction={direction}
      viewMode={viewMode}
    />
  );
};

export default LineOutcome;
