import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TCardsSkeleton = {
  className?: string;
};

const CardsSkeleton = ({ className = '' }: TCardsSkeleton) => (
  <>
    {createSkeletonArray(3).map((i) => (
      <Skeleton className={`${styles.skeleton} ${className}`} key={i} />
    ))}
  </>
);

export default CardsSkeleton;
