import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { memoize } from 'proxy-memoize';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';

import { POPULAR_ALIAS } from '../../../../constants';
import useGetCompetitionsWithSports from '../../../../hooks/useGetCompetitionsWithSports';
import { selectAllGamesCount } from '../../../../store/api/selectors';
import SportMenuItem from '../SportMenuItem';

type Props = {
  activeSport: string;
  setSport: (sportAlias: string) => void;
};

const PopularItem: Component<Props> = ({
  className = '',
  activeSport,
  setSport
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { prematch } = generalRoutes;

  const handleClick = useCallback(() => {
    setSport(POPULAR_ALIAS);
    navigate(`${prematch}/${POPULAR_ALIAS}`);
  }, [setSport]);

  const { data: allPopularGamesCount } = useGetCompetitionsWithSports(
    { favorite: true },
    {
      refetchOnMountOrArgChange: false,
      selectFromResult: memoize((result) => ({
        data: selectAllGamesCount(result)
      }))
    }
  );

  return (
    <SportMenuItem
      iconName={POPULAR_ALIAS}
      name={t('sportsbook.popular')}
      isActive={activeSport === POPULAR_ALIAS}
      onClick={handleClick}
      className={className}
      count={allPopularGamesCount}
      alias=""
    />
  );
};

export default PopularItem;
