import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { usePageContext, USER_TYPES } from '@monorepo/page';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';

import styles from './index.module.css';

const BottomNav: CustomFC = () => {
  const { bottomNav, components } = useSiteSettings();
  const { componentsMap } = usePageContext();
  const { isAuthenticated } = useIsAuthenticated();

  const renderedComponent =
    bottomNav[isAuthenticated ? USER_TYPES.USER : USER_TYPES.GUEST];

  if (!renderedComponent.length) {
    return null;
  }

  return (
    <div className={styles.bottomNav}>
      {renderedComponent.map((id: string) => {
        const componentData = components[id];
        const Component: CustomFC<{ config: {} }> =
          componentsMap[componentData?.type];
        return (
          <div key={id} className={styles.item}>
            {Component ? (
              <Component config={componentData.config || {}} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default BottomNav;
