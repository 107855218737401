import React from 'react';

import { useScrollToTop } from '@monorepo/common';
import { ComponentWithConfig } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import VirtualizedCategoriesList from '../../common/VirtualizedCategoriesList';
import { TGamesSelected } from '../../types';

import styles from '../GamesLobby/index.module.css';

const GamesSelected: ComponentWithConfig<TGamesSelected> = ({ config }) => {
  const {
    limit = 10,
    isWithButtons = true,
    selectedId = [],
    withFavorite = true,
    withTutorials = false
  } = config;

  useScrollToTop();

  return (
    <Box className={styles.wrapper}>
      <VirtualizedCategoriesList
        limit={limit}
        selectedIds={selectedId}
        withTutorials={withTutorials}
        enableFavorite={withFavorite}
        isWithButtons={isWithButtons}
      />
    </Box>
  );
};

export default GamesSelected;
