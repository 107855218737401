import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TSetFavoriteTeamRequest } from '../../../types';

const setFavoriteTeam = () => ({
  query: ({ id }: TSetFavoriteTeamRequest) => ({
    body: {
      command: 'set_favorite_command',
      params: {
        command_id: id
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result, result_text: msg } = data;
      return !(code === 0 && result === 0) ? msg : false;
    }
  }),
  invalidatesTags: [SPORTS_QUERY_KEYS.FAVORITE_TEAM]
});
export default setFavoriteTeam;
