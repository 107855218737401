import { createRoomJid, exitRoom } from '@monorepo/xmpp';

import { TRoomRequest } from '../../../types';
import {
  parseMucUserPresence,
  parseStanzaTypeError
} from '../../../utils/stanzaParsers';
import { actions } from '../../socialsSlice';

const exit = () => ({
  query: ({ roomName, nickname }: TRoomRequest) =>
    exitRoom(createRoomJid(roomName), nickname),
  transformResponse: parseMucUserPresence,
  transformErrorResponse: parseStanzaTypeError,
  async onQueryStarted(
    { roomName }: TRoomRequest,
    { dispatch, queryFulfilled }
  ) {
    const patchResult = dispatch(actions.exitRoom(createRoomJid(roomName)));
    queryFulfilled.catch(patchResult.undo);
  }
});

export default exit;
