import { camelizeKeys } from 'humps';

export const selectFavoriteTeam = (rawResponse, meta, arg): any => {
  if (rawResponse && rawResponse.length !== 0) {
    const { favoriteSportId } = arg;
    return rawResponse
      .map((el) => camelizeKeys(el))
      .filter((el) => el.sportId === favoriteSportId);
  }
  return [];
};
