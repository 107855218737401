import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useSendSmsMutation } from '../../store/user';
import { TSendSMSRequest } from '../../types';

type TOptions = {
  onSuccess?: (data) => void;
  onError?: (error: boolean) => void;
};

const useSendSMS = (options: TOptions = {}) => {
  const { onSuccess, onError } = options;
  const { t } = useI18n(NAMESPACES.FORMS);
  const [sendSMSMutation, { isLoading, isError }] = useSendSmsMutation();
  const { errorNotification } = useNotifications();
  const onSendSMS = async ({ actionType, phoneNumber }: TSendSMSRequest) => {
    const { data } = await sendSMSMutation({
      actionType,
      phoneNumber
    });
    if (data?.result !== 0) {
      onError?.(data?.result);
      errorNotification(t(`error.${data.result}`));
    } else {
      onSuccess?.(data);
    }
  };

  return { onSendSMS, isLoading, isError };
};

export default useSendSMS;
