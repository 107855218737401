import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TChatOpen } from '../../../types/userLocal';

const getChatOpen = (state: any) => state[userLocalSlice.name].chatOpen;

const useSelectChatOpen = (): TChatOpen => useTypedSelector(getChatOpen);

export default useSelectChatOpen;
