import { JID } from '@xmpp/jid';

import { SOCIAL_NOTIFICATION_TYPE } from '../../constants';
import { TStoreNotification } from '../../types';

export const createToastId = (
  jid: string | JID,
  type: SOCIAL_NOTIFICATION_TYPE
) => `${type}-${jid.toString()}`;

export const createUnreadMessageToastId = (roomJid: string | JID) =>
  createToastId(roomJid, SOCIAL_NOTIFICATION_TYPE.UNREAD_MESSAGE);

export const createGroupUnreadMessageToastId = (roomJid: string | JID) =>
  createToastId(roomJid, SOCIAL_NOTIFICATION_TYPE.COMMUNITY_UNREAD_MESSAGE);

export const createFriendRequestToastId = (userJid: string | JID) =>
  createToastId(userJid, SOCIAL_NOTIFICATION_TYPE.FRIEND_REQUEST);

export const createCommunityInviteToastId = (roomJid: string | JID) =>
  createToastId(roomJid, SOCIAL_NOTIFICATION_TYPE.COMMUNITY_INVITE);

type TCreateNotification = {
  roomJid: string | JID;
  userJid: string | JID;
  type: SOCIAL_NOTIFICATION_TYPE;
};

const crateNotification = ({
  roomJid,
  userJid,
  type
}: TCreateNotification): TStoreNotification => ({
  id: createToastId(roomJid, type),
  roomJid: roomJid.toString(),
  userJid: userJid.toString(),
  type
});

export const createFriendRequestNotification = (userJid: string | JID) =>
  crateNotification({
    roomJid: userJid,
    userJid,
    type: SOCIAL_NOTIFICATION_TYPE.FRIEND_REQUEST
  });

export const createUnreadMessageNotification = (userJid: string | JID) =>
  crateNotification({
    roomJid: userJid,
    userJid,
    type: SOCIAL_NOTIFICATION_TYPE.UNREAD_MESSAGE
  });

export const createGroupUnreadMessageNotification = (
  roomJid: string | JID,
  userJid: string | JID
) =>
  crateNotification({
    roomJid,
    userJid,
    type: SOCIAL_NOTIFICATION_TYPE.COMMUNITY_UNREAD_MESSAGE
  });

export const createCommunityInviteNotification = (
  roomJid: string | JID,
  userJid: string | JID
) =>
  crateNotification({
    roomJid,
    userJid,
    type: SOCIAL_NOTIFICATION_TYPE.COMMUNITY_INVITE
  });

export const createDeclineCommunityNotification = (
  roomJid: string | JID,
  userJid: string | JID
) =>
  crateNotification({
    roomJid,
    userJid,
    type: SOCIAL_NOTIFICATION_TYPE.COMMUNITY_DECLINE_INVITE
  });

export const removeNotification = (
  arr: TStoreNotification[],
  id: string
): TStoreNotification[] => arr.filter((item) => id !== item.id);

export const addNotification = (
  arr: TStoreNotification[],
  notification: TStoreNotification
): TStoreNotification[] => [
  notification,
  ...removeNotification(arr, notification.id)
];
