import { AUTH_QUERY_KEYS } from '../../../constants';

const cancelBonus = () => ({
  query: ({ partnerBonusId, bonusId }) => ({
    body: {
      command: 'cancel_bonus',
      params: {
        bonus_id: partnerBonusId,
        client_bonus_id: bonusId
      }
    }
  }),
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },
  invalidatesTags: [AUTH_QUERY_KEYS.BONUSES, AUTH_QUERY_KEYS.FREESPINS]
});
export default cancelBonus;
