import { useMemo } from 'react';

import useSelectInboxList from '../useSelectInboxList';

const useSelectRoomInbox = (jid: string) => {
  const list = useSelectInboxList();

  return useMemo(() => list.find((item) => jid === item.roomJid), [list, jid]);
};

export default useSelectRoomInbox;
