import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TPlayingTime } from '../../../types/userLocal';

const getPlayingTime = (state: any) => state[userLocalSlice.name].playingTime;

const useSelectPlayingTime = (): TPlayingTime =>
  useTypedSelector(getPlayingTime);

export default useSelectPlayingTime;
