import React from 'react';

import { CustomFC } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TTimerItem = {
  timeStamp: number;
  description: string;
};

const TimerItem: CustomFC<TTimerItem> = ({ timeStamp, description }) => (
  <FlexBox direction={FLEX_DIRECTION.column} align={FLEX_ALIGN.center}>
    {timeStamp >= 10 ? (
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.timeItem}
      >
        {timeStamp}
      </FlexBox>
    ) : (
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.timeItem}
      >{`0${timeStamp}`}</FlexBox>
    )}
    <span className={styles.timeStamp}>{description}</span>
  </FlexBox>
);

export default TimerItem;
