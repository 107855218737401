import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const EventChat = lazy(() => import('./EventChat'));

export default ({
  config,
  roomId,
  className
}: ComponentProps<typeof EventChat>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <EventChat roomId={roomId} className={className} config={config} />
  </Suspense>
);
