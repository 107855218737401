import { useSiteSettings } from '@monorepo/site-settings';

import { TGetSportsRequest } from '../../types';
import useGetSports from '../useGetSports';
import useSportsParams from '../useSportsParams';

const useGetMenuSports = (
  args: Partial<TGetSportsRequest> = {},
  options = {}
) => {
  const { languages } = useSiteSettings();
  // custom hook for future purposes of reading parameters both from url and from the LineProvider context
  const { gameType, time, search, competitionIds, gameIds } = useSportsParams();
  return useGetSports(
    {
      gameType,
      time,
      search,
      competitions: competitionIds,
      gameIds,
      languages,
      ...args
    },
    options
  );
};

export default useGetMenuSports;
