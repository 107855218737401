import React, { forwardRef } from 'react';

import { BUTTON_INTENT, BUTTON_SIZE, FIELD_TONE } from '../../constants';
import { ButtonProps } from '../../types';
import Spinner from '../Spinner';

import styles from './index.module.css';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = '',
      type = 'button',
      intent = BUTTON_INTENT.primary,
      isOnlyIcon = false,
      children,
      disabled,
      processing = false,
      toneType = FIELD_TONE.dark,
      size = BUTTON_SIZE.h48,
      ...props
    },
    ref
  ) => {
    const classes = `${styles.button}
    ${styles[toneType]}
    ${styles[size]}
    ${styles[intent]}
    ${isOnlyIcon ? styles.iconButton : ''}
    ${className}`;

    return (
      <button
        ref={ref}
        type={type === 'submit' ? 'submit' : 'button'}
        className={classes}
        disabled={disabled || processing}
        {...props}
      >
        {processing ? <Spinner /> : children}
      </button>
    );
  }
);

export default Button;
