import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const TabsMenu = lazy(() => import('./TabsMenu'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <TabsMenu config={config} />
  </Suspense>
);
