import { WS_QUERY_MESSAGE_TYPE } from '../../constants';
import SocketClient from '../BaseSocketClient';
import { getSocketJackpot, getSocketSWARM } from '../getSocket';

import jackpotMessageHandler from './jackpotMessageHandler';

type TSocketCommand = {
  command: string;
  params: Record<string, any>;
};

export type TCheckError = (response: any) => boolean | Record<string, any>;

type TWsBaseQuery = {
  timeout?: number;
  checkError?: TCheckError;
};

type TWsBaseQueryFn = {
  body: TSocketCommand;
  checkError?: TCheckError;
  providesTags?: string[] | {}[];
  timeout?: number;
};

type TMessageHandler = (
  result: any,
  checkError?: TCheckError
) => { data: any } | { error: any };

const wsQueryCreator =
  (getSocket: () => SocketClient, messageHandler?: TMessageHandler) =>
  ({
    timeout: endpointTimeout,
    checkError: endpointCheckError
  }: TWsBaseQuery = {}) =>
  async (
    {
      body,
      checkError = endpointCheckError,
      timeout = endpointTimeout
    }: TWsBaseQueryFn,
    api: any
  ) => {
    let result;
    let timedOut = false;
    const timeoutId =
      timeout &&
      setTimeout(() => {
        timedOut = true;
        api.abort(WS_QUERY_MESSAGE_TYPE.TIMEOUT_ERROR);
      }, timeout);
    try {
      const socket = getSocket();
      result = await socket.send(body);
    } catch (e) {
      return {
        error: {
          message: timedOut
            ? WS_QUERY_MESSAGE_TYPE.TIMEOUT_ERROR
            : WS_QUERY_MESSAGE_TYPE.SOCKET_ERROR,
          error: String(e)
        }
      };
    } finally {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }

    if (messageHandler) {
      return messageHandler?.(result, checkError);
    }
    const { data } = result;
    const error = checkError?.(result);
    return !error ? { data } : { error };
  };

export const wsBaseQuery = wsQueryCreator(getSocketSWARM);

export const wsJackpotApiQuery = wsQueryCreator(
  getSocketJackpot,
  jackpotMessageHandler
);
