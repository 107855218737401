import get from 'lodash/get';

import { TNormalizedRegions } from '../../../types';

// eslint-disable-next-line import/prefer-default-export
export const normalizeRegionsResponse = (
  rawResponse: Record<string, any>
): TNormalizedRegions => {
  const region = get(rawResponse, 'data.region', {});
  const subid = get(rawResponse, 'subid', '');
  return {
    region,
    subid
  };
};
