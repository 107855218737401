import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { classNameProp, CustomFC } from '@monorepo/type';
import { Button, BUTTON_INTENT, BUTTON_SIZE } from '@monorepo/ui-kit';

const SignInButton: CustomFC<classNameProp> = ({
  className = '',
  ...props
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { login } = generalRoutes;

  const handleSignIn = useCallback(() => {
    navigate(login);
  }, []);

  return (
    <Button
      className={className}
      size={BUTTON_SIZE.h40}
      intent={BUTTON_INTENT.quaternary}
      onClick={handleSignIn}
      {...props}
    >
      {t('burgermenu.signin')}
    </Button>
  );
};

export default SignInButton;
