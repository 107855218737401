export enum MESSAGE_MARK {
  ABORTED = 'aborted',
  PENDING = 'pending',
  TRANSFERRED = 'transferred',
  RECEIVED = 'received',
  DISPLAYED = 'displayed',
  ACKNOWLEDGED = 'acknowledged'
}

export enum MESSAGE_DIRECTION {
  IN = 'in',
  OUT = 'out',
  SYSTEM = 'system'
}
