import React from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { Component } from '@monorepo/type';

type Props = {
  isOnlyDesktop?: boolean;
  isOnlyMobile?: boolean;
  props?: any;
};

const ScrollBarCustom: Component<Props> = ({
  children,
  isOnlyDesktop = false,
  isOnlyMobile = false,
  props
}) => {
  const { isDesktop } = useDeviceDetector();
  return (isOnlyDesktop && isDesktop) ||
    (isOnlyMobile && !isDesktop) ||
    (!isOnlyDesktop && !isOnlyMobile) ? (
    <Scrollbar {...props}>{children}</Scrollbar>
  ) : (
    <>{children}</>
  );
};

export default ScrollBarCustom;
