import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformMenuItems, useGetMenu } from '@monorepo/cms';
import { ComponentWithConfig } from '@monorepo/type';
import {
  HeaderNavMenu as UiHeaderNavMenu,
  Logo,
  TMenuItem
} from '@monorepo/ui-kit';

import CustomButton from '../../common/CustomButton';
import MetaVerseButton from '../../common/MetaVerseButton';
import useCustomButton from '../../hooks/useCustomButton';

import styles from './index.module.css';

type THeaderNavMenu = {
  additional: string[];
  items: TMenuItem[];
  logoName?: string;
  menuId: number;
  menuSecondId: number;
};

const HeaderNavMenu: ComponentWithConfig<THeaderNavMenu> = ({ config }) => {
  const { menuId, menuSecondId, additional, logoName = '' } = config;
  const customButton = useCustomButton();
  const { isLoading, data: cmsMenuItems } = useGetMenu(menuId);
  const { isLoading: isLoadingSecond, data: cmsMenuItemsSecond } =
    useGetMenu(menuSecondId);
  const { isAuthenticated } = useIsAuthenticated();

  if (isLoading || isLoadingSecond) {
    return null;
  }
  const transFormedMenuItems = transformMenuItems(
    cmsMenuItems,
    isAuthenticated
  );
  const transFormedMenuItemsSecond = transformMenuItems(
    cmsMenuItemsSecond,
    isAuthenticated
  );

  return (
    <>
      <UiHeaderNavMenu items={transFormedMenuItems}>
        {additional && <MetaVerseButton item={additional} />}
      </UiHeaderNavMenu>
      <Logo iconName={logoName} className={styles.logo} />
      <UiHeaderNavMenu items={transFormedMenuItemsSecond}>
        {customButton && <CustomButton item={customButton} />}
      </UiHeaderNavMenu>
    </>
  );
};

export default HeaderNavMenu;
