import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  icon?: string;
  onClick: () => void;
  className?: string;
};
const RemoveAllButton: Component<Props> = ({
  icon = 'bin_0',
  onClick,
  className = ''
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  return (
    <FlexBox justify={FLEX_JUSTIFY.center}>
      <FlexBox
        className={`${styles.remove_all} ${className}`}
        onClick={onClick}
        align={FLEX_ALIGN.center}
      >
        {t('remove.all')}
        <Icon className={styles.icon} name={icon} />
      </FlexBox>
    </FlexBox>
  );
};

export default RemoveAllButton;
