import React from 'react';

import { Component } from '@monorepo/type';
import { ActionButton } from '@monorepo/ui-kit';

type Props = {
  id: number;
  dataCustom?: string;
};

const CommentButton: Component<Props> = ({
  id,
  className = '',
  dataCustom
}) => {
  const count = undefined; // todo get comments count & isCommented
  const isCommented = false;

  const handleClick = () => {
    // eslint-disable-next-line no-console
    console.log(`Promotion ${id} is comment`);
  };

  return (
    <ActionButton
      iconName="comment_2"
      className={className}
      count={count}
      onClick={handleClick}
      isActive={isCommented}
      size="medium"
      data-custom={dataCustom}
    />
  );
};

export default CommentButton;
