import trim from 'lodash/trim';

const getAvatarPlaceholder = (name: string) => {
  if (!name) {
    return '';
  }
  const [first, second] = trim(name).toUpperCase().split(' ');

  return `${first[0]}${second ? second[0] : first[1] || ''}`;
};

export default getAvatarPlaceholder;
