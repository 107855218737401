import React, { useEffect } from 'react';

import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { Component } from '@monorepo/type';

import useGetLobbyGames from '../../hooks/useGetLobbyGames';
import { TNormalizedGamesResponseLobby } from '../../types';
import Slider from '../Slider';

import GamesSliderSkeleton from './GamesSliderSkeleton';

type Props = {
  categoryId: string;
  categoryName: string;
  isWithButtons?: boolean;
  rows?: number;
  limit?: number;
  currentPage?: number;
  withTutorials?: boolean;
  enableFavorite?: boolean;
  handleLoaded?: (id: string) => void;
};

const GamesSlider: Component<Props> = ({
  categoryId = '',
  categoryName = '',
  isWithButtons = false,
  rows = 1,
  limit = 10,
  currentPage = 1,
  withTutorials = false,
  enableFavorite = true,
  handleLoaded
}) => {
  const { searchParams } = useSearchParamsWithParsing();
  const { provider } = searchParams;

  const { data, isFetching } = useGetLobbyGames({
    limit,
    provider,
    currentPage,
    categoryId
  });

  const { games = [], totalCount = 0 } =
    (data as TNormalizedGamesResponseLobby) || {};

  useEffect(() => {
    if (data) {
      handleLoaded?.(categoryId);
    }
  }, [data]);

  if (isFetching) {
    return (
      <GamesSliderSkeleton
        isWithButtons={isWithButtons}
        rows={rows}
        limit={limit}
      />
    );
  }

  if (!games?.length) {
    return <div style={{ height: '1px', marginTop: '-1px' }} />;
  }

  return (
    <Slider
      isViewAll={totalCount > 0}
      rows={rows}
      categoryId={categoryId}
      isWithButtons={isWithButtons}
      totalCount={Number(totalCount)}
      games={games}
      categoryName={categoryName}
      withTutorials={withTutorials}
      enableFavorite={enableFavorite}
    />
  );
};

export default GamesSlider;
