import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import { EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox, ScrollBarCustom } from '@monorepo/ui-kit';

import useGetMenuRegions from '../../hooks/useGetMenuRegions';
import { selectRegionsWithOrder } from '../../store/api/selectors';
import { TRegion } from '../../types';
import MenuSkeleton from '../MenuSkeleton';

import CompetitionsList from './components/CompetitionsList';
import Region from './components/Region';

import styles from './index.module.css';

type TMenuProps = {
  basePath: string;
  activeRegion: string;
  activeSport?: string;
};

const RegionsMenu: Component<TMenuProps> = ({
  basePath,
  activeRegion,
  activeSport
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const {
    data: regions,
    isLoading,
    isSuccess
  } = useGetMenuRegions(
    { sportAlias: activeSport },
    {
      selectFromResult: memoize((result: any) => ({
        isLoading: result?.isLoading,
        isSuccess: result?.isSuccess,
        data: selectRegionsWithOrder(result)
      }))
    }
  );

  if (isLoading) {
    return <MenuSkeleton isWithIcons length={20} />;
  }

  if (activeSport && isSuccess && isEmpty(regions)) {
    return (
      <EmptyState
        icon={activeSport?.toLowerCase()}
        title={t('sportsbook.prematch.nocompetitions')}
        subtitle={t('sportsbook.prematch.nocompetitions_sub')}
        defaultIcon="soccer"
        className={styles.empty}
        iconClassName={styles.icon}
      />
    );
  }

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.root}>
      <ScrollBarCustom isOnlyDesktop props={{ noScrollX: true }}>
        <>
          {regions.map(({ id, alias }: TRegion) => (
            <Region
              key={id}
              regionId={id}
              activeSport={activeSport}
              isActive={alias === activeRegion}
            >
              <CompetitionsList
                regionId={id}
                basePath={`${basePath}/${alias}`}
                region={alias}
                activeSport={activeSport}
              />
            </Region>
          ))}
        </>
      </ScrollBarCustom>
    </FlexBox>
  );
};

export default RegionsMenu;
