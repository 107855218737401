import React, { forwardRef } from 'react';

import { Icon } from '@monorepo/icons';

import { BUTTON_INTENT } from '../../constants';
import { ButtonProps } from '../../types';
import Button from '../Button';

import styles from './index.module.css';

type Props = {
  iconName: string;
  iconClassName?: string;
  count?: number | string;
  isActive?: boolean;
  size?: 'small' | 'medium' | 'big';
} & Omit<ButtonProps, 'intent' | 'isOnlyIcon' | 'ref' | 'size'>;

const ActionButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      iconName,
      count,
      isActive = false,
      onClick,
      size = 'big',
      className = '',
      iconClassName = '',
      ...props
    },
    ref
  ) => {
    const classes = `${styles.root}
    ${styles[size]}
    ${isActive ? styles.isActive : ''}
    ${className}`;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      return onClick ? onClick(e) : e;
    };

    return (
      <Button
        isOnlyIcon
        intent={BUTTON_INTENT.tertiary}
        onClick={handleClick}
        className={classes}
        ref={ref}
        {...props}
      >
        <Icon name={iconName} className={`${styles.icon} ${iconClassName}`} />
        {Boolean(count) && <span className={styles.count}>{count}</span>}
      </Button>
    );
  }
);

export default ActionButton;
