import React from 'react';
import { useController } from 'react-hook-form';

import { DATE_FORMAT, DatePicker } from '@monorepo/common';
import { formatDate } from '@monorepo/helpers';
import { FIELD_TONE } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import Control from '../Control';

type DateProps<TFormValues> = {
  minDate?: Date | null;
  maxDate?: Date | null;
  withPortal?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  dropdownMode?: 'scroll' | 'select' | undefined;
  iconName?: string;
  formatString?: string;
  showTimeInput?: boolean;
  onChange?: (date: TFormValues) => void;
} & TFormField<TFormValues>;

const DateField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  className = '',
  rules = {},
  disabled = false,
  placeholder = '',
  inputClassName = '',
  errorClassName = '',
  minDate,
  maxDate,
  withPortal = false,
  showYearDropdown = false,
  showMonthDropdown = false,
  dropdownMode = 'select',
  toneType = FIELD_TONE.dark,
  iconName,
  errorTranslationNS,
  formatString = DATE_FORMAT.SHORT,
  onChange,
  showTimeInput = false
}: DateProps<TFormValues>) => {
  const { field } = useController({ name, rules });

  const requiredLabel = rules ? Object.hasOwn(rules, 'required') : false;

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
      toneType={toneType}
      errorClassName={errorClassName}
      errorTranslationNS={errorTranslationNS}
      requiredLabel={requiredLabel}
    >
      <DatePicker
        className={inputClassName}
        calendarClassName={`datepicker-${toneType}`}
        onChange={(e) => {
          const date = Array.isArray(e) ? e[0] : e;
          if (onChange) {
            onChange(date);
          }
          return field.onChange(date ? formatDate(date, formatString) : null);
        }}
        name={field.name}
        onBlur={field.onBlur}
        value={field.value}
        disabled={disabled}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        toneType={toneType}
        withPortal={withPortal}
        popperPlacement="top"
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        dropdownMode={dropdownMode}
        iconName={iconName}
        showTimeInput={showTimeInput}
        formatString={formatString}
      />
    </Control>
  );
};

export default DateField;
