import React from 'react';
import find from 'lodash/find';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Portal,
  useShutterRef
} from '@monorepo/ui-kit';

import useGameControls from '../../hooks/useGameControls';
import useGetGameInformation from '../../hooks/useGetGameInformation';
import { TPopup } from '../../types';
import PopupWrapper from '../PopupWrapper';

import styles from './index.module.css';

const Popup: Component<TPopup> = ({
  name,
  gameId,
  isRealMode,
  isFunMode,
  children,
  className = ''
}) => {
  const { t } = useI18n(NAMESPACES.CASINO);
  const { shutterRef, setShow, setHide } = useShutterRef();

  // fixme: name - is not the same name to /api/v1/tutorials 'name' param
  const { data } = useGetGameInformation(gameId, 'slot' /* , name */);
  const videoUrl = find(data, { type: 'youtube' })?.url;
  const imgUrl = find(data, { type: 'image/png' })?.url;
  const { onFreePlay, onRealPlay } = useGameControls(gameId);
  /* eslint-disable jsx-a11y/media-has-caption */
  return data?.length ? (
    <>
      <div className={className} onClick={setShow}>
        {children}
      </div>
      <Portal>
        {/* @ts-ignore */}
        <PopupWrapper ref={shutterRef}>
          <FlexBox
            className={styles.popup}
            direction={FLEX_DIRECTION.column}
            align={FLEX_ALIGN.center}
            justify={FLEX_JUSTIFY.between}
          >
            <FlexBox
              direction={FLEX_DIRECTION.row}
              align={FLEX_ALIGN.center}
              justify={FLEX_JUSTIFY.between}
              className={styles.popupHeader}
            >
              {name}
              <Button
                className={styles.close}
                intent={BUTTON_INTENT.tertiary}
                isOnlyIcon
                onClick={setHide}
              >
                <Icon name="close_0" title="" className={styles.iconClose} />
              </Button>
            </FlexBox>

            {videoUrl && (
              <video width="320" height="190" controls autoPlay>
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
            {!videoUrl && imgUrl && (
              <FlexBox>
                <img className={styles.imageGame} alt="img" src={imgUrl} />
              </FlexBox>
            )}
            <FlexBox
              className={styles.controls}
              direction={FLEX_DIRECTION.row}
              justify={FLEX_JUSTIFY.between}
              align={FLEX_ALIGN.center}
            >
              {isRealMode && (
                <Button
                  className={styles.button}
                  onClick={onRealPlay}
                  size={BUTTON_SIZE.h30}
                >
                  {t('game.playnow')}
                </Button>
              )}
              {isFunMode && (
                <Button
                  className={styles.button}
                  onClick={onFreePlay}
                  intent={BUTTON_INTENT.secondary}
                  size={BUTTON_SIZE.h30}
                >
                  {t('game.playfree')}
                </Button>
              )}
            </FlexBox>
          </FlexBox>
        </PopupWrapper>
      </Portal>
    </>
  ) : null;
};

export default Popup;
