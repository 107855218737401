import { useEffect } from 'react';

import { usePlayerProfile } from '@monorepo/cms';

import useSaveNickname from '../useSaveNickname';

const useCheckNickOnLogin = () => {
  const { saveNicknameLogin } = useSaveNickname();
  const { data, isSuccess } = usePlayerProfile();
  const nicknameFromApi = data?.nickname;

  useEffect(() => {
    if (isSuccess && !nicknameFromApi) {
      saveNicknameLogin();
    }
  }, [nicknameFromApi, isSuccess]);
};

export default useCheckNickOnLogin;
