import React from 'react';

import { FIELD_TONE } from '@monorepo/ui-kit';

import { HintProps } from '../../types';

import styles from './index.module.css';

const Hint = ({
  className = '',
  children,
  toneType = FIELD_TONE.dark,
  ...props
}: HintProps) => (
  <div className={`${styles.hint} ${styles[toneType]} ${className}`} {...props}>
    {children}
  </div>
);

export default Hint;
