import React, { ComponentProps } from 'react';
import { useFormState, ValidationMode } from 'react-hook-form';

import { CustomFC } from '@monorepo/type';
import { Button } from '@monorepo/ui-kit';

type Props = {
  mode?: keyof ValidationMode;
  checkDirty?: boolean;
} & Omit<ComponentProps<typeof Button>, 'type'>;

const SubmitButton: CustomFC<Props> = ({
  children,
  processing,
  disabled,
  mode = 'onChange',
  checkDirty = true,
  ...props
}) => {
  const { isSubmitting, isValid, isDirty, isValidating } = useFormState();
  const isOnSubmit = mode === 'onSubmit';

  return (
    <Button
      type="submit"
      disabled={
        (!isOnSubmit && !isValid) || (checkDirty && !isDirty) || disabled
      }
      processing={isSubmitting || isValidating || processing}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
