import React from 'react';

import { usePageContext } from '@monorepo/page';
import { Box, FLEX_DIRECTION, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import Column from '../Column';

import styles from './index.module.css';

const AuthPage = () => {
  const { page } = usePageContext();

  if (page) {
    const { layout } = page;
    const { columns } = layout;

    return (
      <Box className={styles.wrapper}>
        <FlexBox
          className={styles.wrap}
          justify={FLEX_JUSTIFY.center}
          direction={FLEX_DIRECTION.row}
        >
          {columns[0] && (
            <Column
              key={columns[0].join('/')}
              column={columns[0]}
              className={styles.columnLogin}
            />
          )}
          {columns[1] && (
            <Column
              key={columns[1].join('/')}
              column={columns[1]}
              className={styles.columnBanner}
            />
          )}
        </FlexBox>
      </Box>
    );
  }

  return null;
};

export default AuthPage;
