import { usePlayerProfile } from '@monorepo/cms';
import { SHOW_VALUE } from '@monorepo/xmpp';

import useSelectUserStatus from '../selectors/useSelectUserStatus';

const useUserStatus = () => {
  const { status, show, online } = useSelectUserStatus();
  const { data } = usePlayerProfile(['status', 'visibility_status']);
  const { status: profileStatus, visibility_status: visibilityStatus } =
    data || {};

  return {
    status: status || profileStatus || '',
    show: show || visibilityStatus || SHOW_VALUE.CHAT,
    online
  };
};

export default useUserStatus;
