import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form';

import { FIELD_TONE, Input } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import maxLengthValidator from '../../utils/validators/maxLengthValidator';
import Control from '../Control';

type InputProps<TFormValues> = {
  isClearable?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  autoComplete?: string;
  styledClassName?: string;
  labelClassName?: string;
} & TFormField<TFormValues>;

const InputField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label,
  hint,
  type = 'text',
  placeholder,
  disabled = false,
  className = '',
  inputClassName = '',
  labelClassName = '',
  styledClassName = '',
  errorClassName = '',
  rules = {},
  isClearable = false,
  min,
  max,
  toneType = FIELD_TONE.dark,
  autoComplete = '',
  readOnly = false,
  maxLength = 64,
  errorTranslationNS
}: InputProps<TFormValues>) => {
  const [isCheckedPassword, setIsCheckedPassword] = useState(false);
  const { register, setValue, trigger }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();
  const value = useWatch({ name });

  const handleClean = useCallback(() => {
    setValue(name, '' as any);
    trigger(name);
  }, [setValue, trigger, name]);

  useEffect(() => {
    setValue(name, value); // additional set value for fix problem with autocomplete in Chrome
  }, [isCheckedPassword]);

  const requiredLabel = Object.hasOwn(rules, 'required');

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      hint={hint}
      className={className}
      toneType={toneType}
      errorClassName={errorClassName}
      errorTranslationNS={errorTranslationNS}
      labelClassName={labelClassName}
      requiredLabel={requiredLabel}
    >
      <Input
        className={inputClassName}
        inputClassName={styledClassName}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        min={min}
        max={max}
        toneType={toneType}
        onClean={isClearable ? handleClean : undefined}
        inputValue={value}
        setIsCheckedPassword={setIsCheckedPassword}
        autoComplete={autoComplete}
        readOnly={readOnly}
        {...register(name, { ...maxLengthValidator(maxLength), ...rules })}
      />
    </Control>
  );
};

export default InputField;
