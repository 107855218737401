const getAvailableCurrencies = () => ({
  query: ({ currencies }) => ({
    body: {
      command: 'get',
      params: {
        source: 'config.currency',
        what: {
          currency: []
        },
        where: {
          currency: {
            iso_long: {
              '@in': currencies
            }
          }
        }
      }
    }
  }),
  transformResponse(rawResponse) {
    return rawResponse.data?.currency;
  }
});

export default getAvailableCurrencies;
