import { SPORTS_QUERY_KEYS } from '../../../constants';
import { favoriteTeamAdapter } from '../adapters/favoriteTeamAdapter';

const getFavoriteTeam = () => ({
  query: () => ({
    body: {
      command: 'get_favorite_name',
      params: {}
    }
  }),
  keepUnusedDataFor: 0,
  providesTags: [SPORTS_QUERY_KEYS.FAVORITE_TEAM],
  transformResponse: favoriteTeamAdapter
});
export default getFavoriteTeam;
