import { createRoomJid, getDiscoInfo } from '@monorepo/xmpp';

import { SOCIALS_QUERY_TAG } from '../../../constants';
import { TCommunityDiscoInfo } from '../../../types';
import {
  parseRoomDisco,
  parseStanzaTypeError
} from '../../../utils/stanzaParsers';

const getRoomDiscoInfo = () => ({
  query: (roomName: string) => getDiscoInfo(createRoomJid(roomName)),
  transformResponse: parseRoomDisco,
  transformErrorResponse: parseStanzaTypeError,
  providesTags: (
    result: TCommunityDiscoInfo | undefined,
    error: unknown,
    roomName: string
  ) =>
    result
      ? [{ type: SOCIALS_QUERY_TAG.MUC_ROOM_DISCO_INFO, id: roomName }]
      : [SOCIALS_QUERY_TAG.MUC_ROOM_DISCO_INFO]
});

export default getRoomDiscoInfo;
