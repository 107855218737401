import { useEffect } from 'react';

import { useSiteSettings } from '@monorepo/site-settings';

import { useRequestAuthLoginMutation } from '../../store/authLoginV2';
import useSelectIsForceReFetchAuthCookie from '../selectors/useSelectIsForceReFetchAuthCookie';

const useAuthLogin = (jwt?: string) => {
  const isForceReFetchAuthCookie = useSelectIsForceReFetchAuthCookie();
  const { siteId } = useSiteSettings();
  const [authLogin] = useRequestAuthLoginMutation();

  useEffect(() => {
    if (jwt) {
      authLogin({ jwt, partnerId: siteId });
    }
  }, [jwt]);

  useEffect(() => {
    if (isForceReFetchAuthCookie && jwt) {
      authLogin({ jwt, partnerId: siteId });
    }
  }, [isForceReFetchAuthCookie]);
};

export default useAuthLogin;
