export const LAUNCH_URL = `${process.env.REACT_APP_GAME_LAUNCH_HOST}/Inner/authorization.php`;
export const DEMO_URL = `${process.env.REACT_APP_GAME_LAUNCH_HOST}/LaunchGame`;

export const LAUNCH_TYPE = {
  DEMO: 'fun',
  REAL: 'real'
};

export const CATEGORIES_ICONS_MAP = {
  '65': 'sport-summerheatpromo',
  '59': 'sport-jackpot',
  '246': 'sport-tablegamesmegaways',
  '94': 'sport-tablegames',
  '51': 'sport-videoslots',
  '287': 'sport-newgames',
  '247': 'sport-buy_bonus',
  '824': 'sport-aviator'
} as Record<string, string>;

export enum STATUS_VALUE {
  CANCELED = 'Canceled',
  FINISHED = 'Finished',
  LIVE = 'Live',
  UPCOMING = 'Upcoming',
  ALL = 'All'
}

export enum REGISTRATION_STATUS {
  FINISHED = 'Registration Finished',
  STARTED = 'Registration Started'
}

export enum ENTRY_TYPE {
  BUY_IN = 'Buy-in',
  FREE = 'Free Entry'
}

export const TOURNAMENT_STATUS = {
  ALL: '',
  CANCELED: -1,
  FINISHED: 1,
  LIVE: 2,
  UPCOMING: 3
};

export const TOURNAMENT_LABELS = {
  [TOURNAMENT_STATUS.ALL]: STATUS_VALUE.ALL,
  [TOURNAMENT_STATUS.CANCELED]: STATUS_VALUE.CANCELED,
  [TOURNAMENT_STATUS.FINISHED]: STATUS_VALUE.FINISHED,
  [TOURNAMENT_STATUS.LIVE]: STATUS_VALUE.LIVE,
  [TOURNAMENT_STATUS.UPCOMING]: STATUS_VALUE.UPCOMING
};

export enum TOURNAMENT_TYPE_ID {
  CLASSIK = 1,
  TICKETBASED = 2
}

export const TOURNAMENTS_QUERY_KEYS = {
  TOURNAMENT: 'TOURNAMENT',
  TOURNAMENT_LIST: 'TOURNAMENT_LIST'
};

export const JACKPOTS_QUERY_KEYS = {
  JACKPOT_SUBSCRIBE: 'JACKPOT_SUBSCRIBE'
};

export enum PROVIDER_TYPE {
  DEFAULT = 'defaultProvider',
  EXTERNAL = 'externalProvider'
}

export enum ICON_NAMES {
  BOOK = 'book',
  JOKER = 'joker',
  STAR = 'star',
  SEVEN = '777'
}

export const ICON_NAME_VALUE = {
  0: ICON_NAMES.JOKER,
  1: ICON_NAMES.BOOK,
  2: ICON_NAMES.STAR
};

export enum PROVIDER_NAME {
  BETCONSTRUCT = 'BetConstruct JackPot',
  HABANERO = 'Habanero',
  FUGASO = 'FugasoOGS',
  POPOK_GAMING = 'Popok Gaming',
  APOLLO = 'ApolloGames',
  PATEPLAY = 'PatePlay'
}

export enum PROVIDER_IMAGE_NAME {
  BETCONSTRUCT = 'betconstruct',
  HABANERO = 'habanero',
  APOLLO = 'apollo',
  FUGASO = 'fugaso',
  PATEPLAY = 'pateplay'
}

export type PROVIDER_DATA = {
  [key in PROVIDER_NAME]?: PROVIDER_IMAGE_NAME;
};

export const PROVIDER_IMAGE: PROVIDER_DATA = {
  [PROVIDER_NAME.BETCONSTRUCT]: PROVIDER_IMAGE_NAME.BETCONSTRUCT,
  [PROVIDER_NAME.HABANERO]: PROVIDER_IMAGE_NAME.HABANERO,
  [PROVIDER_NAME.APOLLO]: PROVIDER_IMAGE_NAME.APOLLO,
  [PROVIDER_NAME.FUGASO]: PROVIDER_IMAGE_NAME.FUGASO,
  [PROVIDER_NAME.PATEPLAY]: PROVIDER_IMAGE_NAME.PATEPLAY
};
