import React from 'react';

import { Box, PaddingWrapper } from '@monorepo/ui-kit';

import CardsSkeleton from '../CardsSkeleton';

import styles from './index.module.css';

type TCardsSkeletonList = {
  rootClassName?: string;
  skeletonClassName?: string;
  size?: number;
  itemClassName?: string;
};

const CardsSkeletonList = ({
  rootClassName = '',
  itemClassName = '',
  skeletonClassName = '',
  size = 3
}: TCardsSkeletonList) => (
  <PaddingWrapper>
    <Box>
      <Box className={rootClassName}>
        <PaddingWrapper isOnlyDesktop>
          <Box>
            <Box className={`${styles.skeleton} ${skeletonClassName}`}>
              <CardsSkeleton size={size} itemClassName={itemClassName} />
            </Box>
          </Box>
        </PaddingWrapper>
      </Box>
    </Box>
  </PaddingWrapper>
);

export default CardsSkeletonList;
