import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { TOptionItem } from '@monorepo/type';
import { FIELD_TONE, Select } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import Control from '../Control';

type SelectProps<TFormValues> = {
  options: TOptionItem[];
} & TFormField<TFormValues>;

const SelectField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  options,
  label = '',
  placeholder = '',
  disabled = false,
  className = '',
  rules = {},
  inputClassName = '',
  errorClassName = '',
  toneType = FIELD_TONE.dark,
  errorTranslationNS
}: SelectProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const requiredLabel = Object.hasOwn(rules, 'required');

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
      toneType={toneType}
      errorClassName={errorClassName}
      errorTranslationNS={errorTranslationNS}
      requiredLabel={requiredLabel}
    >
      <Select
        className={inputClassName}
        options={options}
        disabled={disabled}
        toneType={toneType}
        placeholder={placeholder}
        {...register(name, { ...rules })}
      />
    </Control>
  );
};

export default SelectField;
