import { useSiteSettings } from '@monorepo/site-settings';

import { amountMaskOptions } from '../../constants';
import amountFieldToNumber from '../../utils/amountFieldToNumber';

const useChangeSeparator = () => {
  const { digitSeparator } = useSiteSettings();
  const maskOptions = { ...amountMaskOptions, decimalSymbol: digitSeparator };

  const changeSeparator = (value: string | number) =>
    Number(`${value}`.replace(digitSeparator, '.'));

  const convertToFloat = (value: string | number) =>
    value.toString().replace('.', digitSeparator);

  const amountMaskToNumber = amountFieldToNumber(maskOptions);

  return {
    changeSeparator,
    convertToFloat,
    amountMaskToNumber,
    amountMaskOptions: maskOptions
  };
};
export default useChangeSeparator;
