import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useGetChildSession,
  useGetPartnerConfig,
  useGetUser
} from '@monorepo/auth';
import { BackHeaderCommon } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Button,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const SwitchProposal = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { onGetChildSession } = useGetChildSession();
  const navigate = useNavigate();
  const { data: partnerConfig } = useGetPartnerConfig();
  const { data: profile } = useGetUser();
  const { currency } = partnerConfig || {};
  const { parentCurrency } = profile || {};

  const handleAcceptTemp = () => {
    onGetChildSession(parentCurrency || currency);
  };

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.switch}
    >
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.modal}>
        <BackHeaderCommon
          className={styles.head}
          title={t('wallet.switch.head')}
          closeLink={() => navigate(-1)}
        />
        <FlexBox className={styles.text}>{t('wallet.switch.text')}</FlexBox>
        <Button className={styles.button} onClick={handleAcceptTemp}>
          {t('wallet.switch.button')}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default SwitchProposal;
