import { xml } from '@xmpp/client';

import { PRESENCE_TYPE } from '../../constants';
import { TPresence } from '../../types';

export const presenceCreator = ({ to, type, show, status }: TPresence) => {
  const presence = xml('presence');

  if (to) {
    presence.attrs.to = to?.toString();
  }
  if (type) {
    presence.attrs.type = type;
  }
  if (show) {
    presence.append(xml('show', {}, show));
  }
  if (status) {
    presence.append(xml('status', {}, status));
  }

  return presence;
};

export const subscribeTo = (address: string) =>
  presenceCreator({ to: address, type: PRESENCE_TYPE.SUBSCRIBE });

export const acceptSubscription = (address: string) =>
  presenceCreator({ to: address, type: PRESENCE_TYPE.SUBSCRIBED });

export const unsubscribeFrom = (address: string) =>
  presenceCreator({ to: address, type: PRESENCE_TYPE.UNSUBSCRIBE });

export const cancelSubscription = (address: string) =>
  presenceCreator({ to: address, type: PRESENCE_TYPE.UNSUBSCRIBED });
