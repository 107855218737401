import React from 'react';

import { Icon } from '@monorepo/icons';

import { BUTTON_INTENT, FIELD_TONE } from '../../../../../constants';
import Button from '../../../../Button';

import styles from './index.module.css';

const ArrowButtons = ({
  onNext,
  onPrev,
  isPrevDisabled,
  isNextDisabled,
  toneType = FIELD_TONE.dark,
  className = ''
}) => (
  <div className={`${styles.arrowButtons} ${className}`}>
    {!isPrevDisabled && (
      <Button
        intent={BUTTON_INTENT.transparent}
        isOnlyIcon
        onClick={onPrev}
        className={`${styles.button} ${styles.buttonLeft} ${styles[toneType]}`}
      >
        <Icon name="close_1" title="" />
      </Button>
    )}
    {!isNextDisabled && (
      <Button
        intent={BUTTON_INTENT.transparent}
        isOnlyIcon
        onClick={onNext}
        toneType={toneType}
        className={`${styles.button} ${styles.buttonRight} ${styles[toneType]}`}
      >
        <Icon name="close_1" className={styles.next} title="" />
      </Button>
    )}
  </div>
);

export default ArrowButtons;
