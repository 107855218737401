const changeSessionLanguage = () => ({
  query: (language: string) => ({
    body: {
      command: 'change_session_language',
      params: {
        language
      }
    }
  })
});
export default changeSessionLanguage;
