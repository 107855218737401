import React from 'react';
import { memoize } from 'proxy-memoize';

import { Component, TQueryResult } from '@monorepo/type';

import useGetMenuRegions from '../../../../hooks/useGetMenuRegions';
import useSportsURLParams from '../../../../hooks/useSportsURLParams';
import { selectCompetitionsByRegionIdCompetitionId } from '../../../../store/api/selectors';
import { TNormalizedRegions } from '../../../../types';
import { formatIconNameFromAlias } from '../../../../utils';
import CompetitionMenuItem from '../../../CompetitionMenuItem';

type TCompetitionProps = {
  competitionId: number;
  regionId: number;
  withIcons: boolean;
  basePath: string;
  activeSport?: string;
  region?: string;
};

const Competition: Component<TCompetitionProps> = ({
  withIcons,
  basePath,
  competitionId,
  regionId,
  region,
  activeSport
}) => {
  const { time } = useSportsURLParams();
  const { data: competition } = useGetMenuRegions(
    { sportAlias: activeSport },
    {
      refetchOnMountOrArgChange: false,
      selectFromResult: memoize((result: TQueryResult<TNormalizedRegions>) => ({
        data: selectCompetitionsByRegionIdCompetitionId(
          result,
          regionId,
          competitionId
        )
      }))
    }
  );
  const { id, name, game } = competition;

  return (
    <CompetitionMenuItem
      link={`${basePath}/${id}${time ? `?time=${time}` : ''}`}
      title={name}
      count={game}
      iconName={formatIconNameFromAlias(region)}
      withIcons={withIcons}
    />
  );
};

export default Competition;
