import { SetStateAction, useRef } from 'react';

import { TShutterHandler } from '../../types';

const useShutterRef = () => {
  const shutterRef = useRef<TShutterHandler>(null);

  const setIsShow = (isShow: SetStateAction<boolean>) => {
    if (shutterRef?.current) {
      shutterRef.current.setIsShow(isShow);
    }
  };

  const setShow = () => setIsShow(true);

  const setHide = () => setIsShow(false);

  return { shutterRef, setIsShow, setShow, setHide };
};

export default useShutterRef;
