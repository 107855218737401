import { camelizeKeys } from 'humps';
import pick from 'lodash/pick';

import { TAuthData } from '../../../../types';

const loginAdapter = (initialData: {}): TAuthData =>
  camelizeKeys(
    pick(initialData, [
      'auth_token',
      'jwe_token',
      'user_id',
      'qr_code_origin',
      'authentication_status'
    ])
  ) as TAuthData;

export default loginAdapter;
