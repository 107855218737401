import { useGetPlayerProfileSettingsQuery } from '@monorepo/cms';
import { useSiteSettings } from '@monorepo/site-settings';

const useGetPlayerProfileSettings = (target: number, options = {}) => {
  const { skip = false, ...restOptions } = options;
  const { siteId } = useSiteSettings();

  return useGetPlayerProfileSettingsQuery(
    {
      userId: target,
      partnerId: siteId
    },
    {
      skip,
      ...restOptions
    }
  );
};

export default useGetPlayerProfileSettings;
