import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { LIKE_TYPE, UGC_QUERY_TAG } from '../constants';
import {
  TAddChatInfoRequest,
  TGetBlogsCategoryResponse,
  TGetChatInfoRequest,
  TGetChatInfoResponse,
  TGetListOfLikesRequest,
  TListOfLikes,
  TToggleLike,
  TToggleLikeRequest,
  TUpdateChatInfoRequest
} from '../types';

import addBlog from './api/addBlog';
import addChatInfo from './api/addChatInfo';
import deleteBlog from './api/deleteBlog';
import getBlog from './api/getBlog';
import getBlogs from './api/getBlogs';
import getBlogsByUser from './api/getBlogsByUser';
import getBlogsCategory from './api/getBlogsCategory';
import getChatInfo from './api/getChatInfo';
import getListOfLikes from './api/getListOfLikes';
import toggleLike from './api/toggleLike';
import updateBlog from './api/updateBlog';
import updateChatInfo from './api/updateChatInfo';

export const userGeneratedContentApi = createApi({
  reducerPath: 'userGeneratedContentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1'
  }),
  tagTypes: [
    LIKE_TYPE.WALL,
    LIKE_TYPE.SPORTSBOOK,
    LIKE_TYPE.CASINO,
    LIKE_TYPE.VIDEO,
    UGC_QUERY_TAG.CHAT_INFO
  ],
  endpoints: (builder) => ({
    toggleLike: builder.mutation<TToggleLike, TToggleLikeRequest>(toggleLike()),
    getListOfLikes: builder.query<TListOfLikes, TGetListOfLikesRequest>(
      getListOfLikes()
    ),
    getChatInfo: builder.query<TGetChatInfoResponse, TGetChatInfoRequest>(
      getChatInfo()
    ),
    addChatInfo: builder.mutation<unknown, TAddChatInfoRequest>(addChatInfo()),
    updateChatInfo: builder.mutation<unknown, TUpdateChatInfoRequest>(
      updateChatInfo()
    ),
    getBlogsCategory: builder.query<TGetBlogsCategoryResponse, undefined>(
      getBlogsCategory()
    ),
    getBlogs: builder.query<any, any>(getBlogs()),
    getBlogsByUser: builder.query<any, any>(getBlogsByUser()),
    getBlog: builder.query<any, any>(getBlog()),
    addBlog: builder.mutation<unknown, any>(addBlog()),
    updateBlog: builder.mutation<unknown, any>(updateBlog()),
    deleteBlog: builder.mutation<unknown, any>(deleteBlog())
  })
});

export const {
  useToggleLikeMutation,
  useGetListOfLikesQuery,
  useGetChatInfoQuery,
  useAddChatInfoMutation,
  useUpdateChatInfoMutation,
  useGetBlogsCategoryQuery,
  useGetBlogsQuery,
  useGetBlogsByUserQuery,
  useGetBlogQuery,
  useAddBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation
} = userGeneratedContentApi;
