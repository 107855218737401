import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

import { Modal, ModalWrap, Portal, TModalHandler } from '@monorepo/ui-kit';

type TModalNotification = {
  handleClose?: () => void;
  classOverlayModal?: string;
  classContentModal?: string;
  iconClass?: string;
  titleClassName?: string;
  header: string;
  children: ReactNode;
  alwaysCenter?: boolean;
};

const ModalWrapper: ForwardRefRenderFunction<
  TModalHandler,
  TModalNotification
> = (
  {
    handleClose,
    classOverlayModal = '',
    classContentModal = '',
    iconClass = '',
    titleClassName = '',
    header,
    children,
    alwaysCenter
  },
  ref
) => (
  <Portal>
    {/* @ts-ignore */}
    <Modal
      classOverlay={classOverlayModal}
      classContent={classContentModal}
      closeOutside={handleClose}
      ref={ref}
      isHasOverlay
      alwaysCenter={alwaysCenter}
    >
      <ModalWrap
        header={header}
        headerClassName={iconClass}
        titleClassName={titleClassName}
        handleClose={handleClose}
      >
        {children}
      </ModalWrap>
    </Modal>
  </Portal>
);
export default forwardRef(ModalWrapper);
