import React from 'react';

import { COMMON_COMPONENT_TYPES } from '@monorepo/common'; // todo decide in what package this type should be
import { usePageContext } from '@monorepo/page';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';

const Footer: CustomFC = () => {
  const { footer } = useSiteSettings();
  const { componentsMap } = usePageContext();
  const Component = componentsMap[COMMON_COMPONENT_TYPES.FOOTER];

  if (!Component) {
    return null;
  }

  return <Component config={footer} />;
};

export default Footer;
