import React from 'react';

import { Component } from '@monorepo/type';
import { FIELD_TONE, StatusMark } from '@monorepo/ui-kit';
import {
  isFriendsSubscription,
  MESSAGES_TYPES,
  SHOW_VALUE,
  SUBSCRIPTION_TYPE,
  useIsServerOnline
} from '@monorepo/xmpp';

import useChatUser from '../../hooks/useChatUser';
import UnreadMessageCount from '../UnreadMessageCount';

type Props = {
  id: string;
  toneType?: FIELD_TONE;
  isWithCount?: boolean;
  countClass?: string;
};

const FriendStatus: Component<Props> = ({
  className = '',
  id,
  toneType,
  isWithCount = true,
  countClass = ''
}) => {
  const isServerOnline = useIsServerOnline();
  const {
    show = SHOW_VALUE.CHAT,
    subscription = SUBSCRIPTION_TYPE.NONE,
    online = false
  } = useChatUser(id);
  const isActive = online && show === SHOW_VALUE.CHAT;
  const isHaveStatus = isFriendsSubscription(subscription);

  return (
    <StatusMark
      className={className}
      isActive={isActive}
      isPending={!isServerOnline}
      isHaveStatus={isHaveStatus}
      toneType={toneType}
    >
      {isWithCount && (
        <UnreadMessageCount
          id={id}
          className={countClass}
          type={MESSAGES_TYPES.SINGLE}
        />
      )}
    </StatusMark>
  );
};

export default FriendStatus;
