import { useTranslation } from 'react-i18next';

import { NAMESPACES } from '../../constants';
import { I18nHook } from '../../types';
import getLongLanguageCode from '../../utils/getLongLanguageCode';
import getSwarmLanguageCode from '../../utils/getSwarmLanguageCode';

const useI18n = (namespace = '' as NAMESPACES | NAMESPACES[]): I18nHook => {
  const { t, i18n } = useTranslation(namespace);

  const languageLongCode = getLongLanguageCode(i18n.language);
  const languageSwarmCode = getSwarmLanguageCode(i18n.language);

  if (namespace) {
    i18n.loadNamespaces(namespace);
  }

  return {
    t,
    i18n,
    language: i18n.language,
    languageLongCode,
    languageSwarmCode
  };
};

export default useI18n;
