import merge from 'lodash/merge';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetCompetitionsRequest } from '../../../types';
import { normalizeCompetitionsResponse } from '../adapters/competionsAdapter';

const getCompetitions = (api) => ({
  query: ({ gameType, sportAlias, regionAlias }: TGetCompetitionsRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        subscribe: true,
        what: {
          competition: ['id', 'name', 'order']
        },
        where: {
          game: {
            type: gameType // Prematch or live
          },
          sport: {
            alias: sportAlias
          },
          region: {
            alias: regionAlias
          }
        }
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.COMPETITIONS],
  transformResponse: normalizeCompetitionsResponse,
  async onCacheEntryAdded(
    args: any,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => merge(draft, newData));
        },
        () =>
          dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.COMPETITIONS]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
});

export default getCompetitions;
