import React from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';

import styles from './index.module.css';

const HeaderPromo = ({ className = '' }) => {
  const { generalRoutes } = useSiteSettings();
  const { promoAll } = generalRoutes || {};

  return (
    <NavLink className={`${styles.link} ${className}`} to={promoAll}>
      <Icon name="gift-0" className={styles.icon} />
    </NavLink>
  );
};
export default HeaderPromo;
