import { camelizeKeys } from 'humps';

import { getSearchString } from '@monorepo/helpers';

const getBlogsByUser = () => ({
  query: ({
    userId,
    offset,
    limit,
    orderBy,
    sort
  }: {
    userId: number;
    limit: number;
    offset: number;
    orderBy: string;
    sort: string;
  }) => {
    const searchParams = getSearchString({
      user_id: userId,
      offset,
      limit,
      order_by: orderBy,
      sort
    });

    return {
      url: `/posts/by_user/${userId}${
        searchParams.length > 0 ? searchParams : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  },
  keepUnusedDataFor: 0,
  transformResponse: (response: any): string[] => {
    const list = response.list.map((item: any) => camelizeKeys(item));
    return { list, total_count: response.total_count };
  }
});

export default getBlogsByUser;
