import { SPORTS_QUERY_KEYS } from '../../../constants';

const removeFavoriteTeam = () => ({
  query: () => ({
    body: {
      command: 'delete_client_favorite_teams',
      params: {}
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result, result_text: msg } = data;
      return !(code === 0 && result === 0) ? msg : false;
    }
  }),
  invalidatesTags: [SPORTS_QUERY_KEYS.FAVORITE_TEAM]
});
export default removeFavoriteTeam;
