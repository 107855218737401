import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetNotificationsQuery } from '../../store';

const useGetNotifications = (slug: string) => {
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();
  return useGetNotificationsQuery({
    lang: languageLongCode,
    partner: siteId,
    slug
  });
};

export default useGetNotifications;
