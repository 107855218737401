import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useGetFavoriteIdsData } from '@monorepo/cms';
import { useSiteSettings } from '@monorepo/site-settings';

import { FAVORITE_LINK_TYPES, FAVORITE_TYPE } from '../../constants';

const useGetFavoriteUrl = () => {
  const { generalRoutes } = useSiteSettings();
  const { pathname } = useLocation();
  const allFavoritesState = useGetFavoriteIdsData();

  const {
    sportFavoritesMatches,
    casinoFavorites,
    sportFavoritesCompetitions,
    wallFavorites
  } = generalRoutes;
  const isActive =
    sportFavoritesMatches === pathname ||
    casinoFavorites === pathname ||
    sportFavoritesCompetitions === pathname ||
    wallFavorites === pathname;

  const getFavoriteUrl = (type: FAVORITE_LINK_TYPES) => {
    if (type === FAVORITE_LINK_TYPES.SPORT) {
      return !isEmpty(get(allFavoritesState, FAVORITE_TYPE.GAME, null))
        ? sportFavoritesMatches
        : sportFavoritesCompetitions;
    }
    if (type === FAVORITE_LINK_TYPES.WALL) {
      return wallFavorites;
    }
    return casinoFavorites;
  };

  return {
    isActive,
    getFavoriteUrl
  };
};

export default useGetFavoriteUrl;
