import { requestRoomRegister } from '@monorepo/xmpp';

import {
  parseRoomRegister,
  parseStanzaTypeError
} from '../../../utils/stanzaParsers';

const requestRoomRegistration = () => ({
  query: (roomName: string) => requestRoomRegister(roomName),
  transformResponse: parseRoomRegister,
  transformErrorResponse: parseStanzaTypeError
});

export default requestRoomRegistration;
