import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Component } from '@monorepo/type';
import { ActionButton } from '@monorepo/ui-kit';
import { useIsServerOnline } from '@monorepo/xmpp';

import { EVENT_TABS_TYPE } from '../../constants';

type Props = {
  id: string | number;
  size?: 'small' | 'medium';
  link: string;
};

const CommentButton: Component<Props> = ({
  size = 'medium',
  className = '',
  link
}) => {
  const navigate = useNavigate();
  const isServerOnline = useIsServerOnline();

  const handleClick = () => navigate(`${link}#${EVENT_TABS_TYPE.CHAT}`);

  return (
    <ActionButton
      iconName="comment_2"
      onClick={handleClick}
      size={size}
      className={className}
      disabled={!isServerOnline}
    />
  );
};

export default CommentButton;
