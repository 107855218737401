import React, { useMemo, useRef, useState } from 'react';

import { useOnClickOutside } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import { Component, TOptionItem } from '@monorepo/type';

import { FIELD_TONE, FLEX_ALIGN } from '../../../../constants';
import Box from '../../../Box';
import EllipsisText from '../../../EllipsisText';
import FlexBox from '../../../FlexBox';
import ScrollBarCustom from '../../../ScrollBarCustom';
import SelectItem from '../SelectItem';

import styles from './index.module.css';

type Props = {
  value?: string;
  options: TOptionItem[];
  itemClassName?: string;
  contentClassName?: string;
  selectedClassName?: string;
  toneType?: FIELD_TONE;
  onChange: (value: string) => void;
};

const SelectDropdown: Component<Props> = ({
  className = '',
  value,
  contentClassName = '',
  itemClassName = '',
  selectedClassName = '',
  options,
  toneType = FIELD_TONE.dark,
  onChange
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isShow, setIsShow] = useState(false);

  const toggleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsShow((prev: boolean) => !prev);
  };

  useOnClickOutside<HTMLDivElement>(selectRef, () => setIsShow(false));

  const activeItem = useMemo(
    () =>
      options.find((item) => item.value.toString() === value?.toString()) ||
      options[0],
    [options, value]
  );

  const optionSelect = (item: TOptionItem) => {
    setIsShow(false);
    onChange(item.value);
  };

  const withScroll = options.length > 7;
  const dropHeight = (withScroll ? 7 * 28 : options.length * 28) + 10;
  return (
    <div
      ref={selectRef}
      className={`${styles.wrap} ${styles[toneType]} ${className}`}
    >
      <FlexBox
        className={`${styles.selected} ${selectedClassName} ${
          isShow ? styles.active : ''
        }`}
        align={FLEX_ALIGN.center}
        onClick={toggleOpen}
        data-option-value={activeItem?.label || ''}
      >
        <EllipsisText className={styles.label}>
          {activeItem?.label || ''}
        </EllipsisText>
        <Icon
          name={isShow ? 'arrow_up-2' : 'arrow_down-3'}
          className={styles.icon}
        />
      </FlexBox>
      {isShow && (
        <Box
          style={{ height: dropHeight }}
          className={`${styles.drop} ${withScroll ? styles.withScroll : ''}`}
        >
          <ScrollBarCustom
            className={styles.scroll}
            isOnlyDesktop={withScroll}
            isOnlyMobile
          >
            <Box className={`${styles.content} ${contentClassName}`}>
              {options.map((item) => (
                <SelectItem
                  key={`${item.value}-${item.label}`}
                  item={item}
                  onSelect={optionSelect}
                  toneType={toneType}
                  className={itemClassName}
                />
              ))}
            </Box>
          </ScrollBarCustom>
        </Box>
      )}
    </div>
  );
};

export default SelectDropdown;
