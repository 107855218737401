import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { TGameInformationGetArgs } from '../../types';

import getGameInformation from './api/getGameInformation';
import { CUSTOM_GAME_API_QUERY_KEYS } from './constants';

type Result = Array<Record<string, any>> | null; // todo types

export const customGameApi = createApi({
  reducerPath: 'customGameApi',
  baseQuery: fetchBaseQuery({ baseUrl: `/api/v1` }),
  tagTypes: [CUSTOM_GAME_API_QUERY_KEYS.GAME_INFORMATION],
  endpoints: (builder) => ({
    getGameInformation: builder.query<Result, TGameInformationGetArgs>(
      getGameInformation()
    )
  })
});

export const { useGetGameInformationQuery } = customGameApi;
