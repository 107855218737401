import { camelizeKeys } from 'humps';

import {
  TResultCompetitions,
  TResultCompetitionsListResponse
} from '../../../types';

const resultCompetitionsAdapter = (
  response: TResultCompetitionsListResponse
): TResultCompetitions[] => {
  const { details } = response;
  return details && Array.isArray(details)
    ? details.map((el) => camelizeKeys(el) as TResultCompetitions)
    : [];
};

export default resultCompetitionsAdapter;
