import { useHasLoginCookies } from '@monorepo/auth';

import { useGetPlayerInfoQuery } from '../../../store/customApiStore';

// For purposes when we need access to player information from the new API
const useGetPlayerInformation = (options = {}) => {
  const { skip, ...rest } = options;
  const isCookiesFetched = useHasLoginCookies();

  return useGetPlayerInfoQuery(
    {},
    {
      skip: !isCookiesFetched || skip,
      ...rest
    }
  );
};

export default useGetPlayerInformation;
