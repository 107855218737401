import { camelizeKeys } from 'humps';

const getData = (res: Record<string, any>) => res?.data;

export const selectGame = (
  response: any,
  gameId: number,
  competitionId: number
) => {
  const data = getData(response);
  if (data?.competition && data.competition[competitionId]) {
    const competition = data.competition[competitionId];
    const {
      id,
      name: competitionName,
      favorite: isFavorite,
      game
    } = competition;

    if (!game) {
      return null;
    }

    return camelizeKeys({
      competitionId: id,
      competitionName,
      isFavorite,
      ...game[gameId]
    });
  }

  return null;
};

export const selectIsGameExist = (response: any) => {
  const data = getData(response);
  return Boolean(data?.competition);
};
