import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Box, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import useGetExchange from '../../hooks/useGetExchange';

import styles from './index.module.css';

const HeaderExchangeWidget = ({ className = '' }) => {
  const { exchangeURL } = useSiteSettings();
  const { data } = useGetExchange();
  const { currencyFrom, currencyTo, lastPrice } = data || [];
  return (
    <Link
      to={exchangeURL}
      target="_blank"
      className={`${styles.link} ${className}`}
      rel="noreferrer"
    >
      <FlexBox direction={FLEX_DIRECTION.row}>
        <Icon name="fastex-0" className={styles.icon} />
        <FlexBox direction={FLEX_DIRECTION.column}>
          <Box className={styles.text}>
            {`1 ${currencyFrom} = ${lastPrice} ${currencyTo}`}
          </Box>
          <Box className={styles.text}>{exchangeURL}</Box>
        </FlexBox>
      </FlexBox>
    </Link>
  );
};
export default HeaderExchangeWidget;
