import get from 'lodash/get';

const getFreeBet = () => ({
  query: ({ args }) => ({
    body: {
      command: 'get_freebets_for_betslip_v3',
      params: {
        IsLive: args.isLive,
        Events: args.bets
      }
    }
  }),
  transformResponse(response: any): null | number {
    return get(response, 'details', null);
  }
});

export default getFreeBet;
