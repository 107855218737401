import merge from 'lodash/merge';

import { getSocketJackpot } from '@monorepo/websocket';

import { JACKPOTS_QUERY_KEYS } from '../../../constants';
import {
  TSubscribeToJackpotData,
  TSubscribeToJackpotRequest,
  TSubscribeToJackpotResponse
} from '../../../types';
import subscribeToJackpotAdapter from '../adapters/subscribeToJackpotAdapter';

const subscribeToJackpot = (casinoJackpots: any) => ({
  query: ({
    jackpotId,
    playerId,
    currency,
    intensity
  }: TSubscribeToJackpotRequest) => ({
    body: {
      Command: 'subscribe',
      Data: {
        JackPotId: jackpotId,
        PartnerId: playerId,
        Currency: currency,
        Intensity: intensity
      }
    }
  }),
  providesTags: [JACKPOTS_QUERY_KEYS.JACKPOT_SUBSCRIBE],
  keepUnusedDataFor: 0,
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const casinoJackpotInstance = getSocketJackpot();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;

      casinoJackpotInstance.subscribe(
        data?.RequestId,
        (newData) => {
          updateCachedData((draft) => merge(draft, newData));
        },
        () =>
          dispatch(
            casinoJackpots.util.invalidateTags([
              JACKPOTS_QUERY_KEYS.JACKPOT_SUBSCRIBE
            ])
          )
      );

      await cacheEntryRemoved;
      if (data?.RequestId) {
        await dispatch(
          casinoJackpots.endpoints.unsubscribeFromJackpot.initiate(
            data?.RequestId
          )
        ).reset();
        casinoJackpotInstance.unsubscribe(data?.RequestId);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
  transformResponse(
    response: TSubscribeToJackpotResponse
  ): TSubscribeToJackpotData | [] {
    return !response ? [] : subscribeToJackpotAdapter(response);
  }
});
export default subscribeToJackpot;
