import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

import { useCancelBonusMutation } from '../../store/user';

const useCancelBonus = () => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { successNotification, errorNotification } = useNotifications();
  const [cancelBonus, { isLoading, isError }] = useCancelBonusMutation();
  const formatError = (code: number) => `error.${code}`;

  const onErrorDefault = (code: number) => {
    const formattedError = formatError(code);
    errorNotification(t(formattedError || ''));
  };
  const onSuccess = () => {
    successNotification(t('bonus.declined'));
  };

  const onCancelBonus = async (partnerBonusId: number, bonusId: number) => {
    const response = await cancelBonus({ partnerBonusId, bonusId });
    const { data = {} } = response;
    if (data?.result === 0) {
      onSuccess();
    } else {
      onErrorDefault(data.result);
    }
  };

  return { onCancelBonus, isLoading, isError, formatError };
};

export default useCancelBonus;
