import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import { Component, TActionsConfig, TQueryResult } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import useGetLineGames from '../../hooks/useGetLineGames';
import { selectCompetitionsBySportIdRegionId } from '../../store/api/selectors';
import { TNormalizedSports } from '../../types';
import LineCompetitionHeader from '../LineCompetitionHeader';
import LineGamesList from '../LineGamesList';
import LineGamesListGroupedByDate from '../LineGamesListGroupedByDate';

import styles from './index.module.css';

type Props = {
  sportId: number;
  regionId: number;
  showTitle?: boolean;
  isGroupedByDate?: boolean;
} & TActionsConfig;

const LineCompetitionsList: Component<Props> = ({
  sportId,
  regionId,
  showTitle,
  isGroupedByDate = false,
  className = ''
}) => {
  const { data: competitions } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectCompetitionsBySportIdRegionId(result, sportId, regionId)
    }))
  });

  if (isEmpty(competitions)) {
    return null;
  }

  return (
    <Box className={className}>
      {competitions.map((competitionId: number) => (
        <Box key={competitionId} className={styles.item}>
          <LineCompetitionHeader
            sportId={sportId}
            regionId={regionId}
            competitionId={competitionId}
            showTitle={showTitle}
          />
          {isGroupedByDate ? (
            <LineGamesListGroupedByDate
              sportId={sportId}
              regionId={regionId}
              competitionId={competitionId}
            />
          ) : (
            <LineGamesList
              sportId={sportId}
              regionId={regionId}
              competitionId={competitionId}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default LineCompetitionsList;
