import { useTypedSelector } from '@monorepo/redux';

import { userSlice } from '../../../store';

const getMessengerAuthToken = (state: any) =>
  state[userSlice.name].messengerAuthToken;

const useSelectMessengerAuthToken = (): string | null =>
  useTypedSelector(getMessengerAuthToken);

export default useSelectMessengerAuthToken;
