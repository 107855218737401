import React from 'react';

import { FavoriteButton, LikeButton, ShareButton } from '@monorepo/common';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { TPromotion, TPromotionsListConfig } from '../../types';
import CommentButton from '../CommentButton';

import styles from './index.module.css';

type Props = Pick<TPromotion, 'id' | 'eventDate'> & TPromotionsListConfig;

const PromotionInfo: Component<Props> = ({
  id,
  eventDate,
  withLike = false,
  withFavorite = false,
  withComment = false,
  // withStream = false,
  withShare = false,
  className = '',
  href = '',
  title = '',
  type
}) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    justify={FLEX_JUSTIFY.between}
    className={`${styles.wrap} ${className}`}
  >
    <FlexBox align={FLEX_ALIGN.center} className={styles.info}>
      {eventDate}
    </FlexBox>
    <FlexBox align={FLEX_ALIGN.center} className={styles.buttons}>
      {withLike && (
        <LikeButton
          id={id}
          className={styles.action}
          type={type}
          dataCustom="wall-like-custom"
        />
      )}
      {withComment && (
        <CommentButton
          id={id}
          className={styles.action}
          dataCustom="wall-comment-custom"
        />
      )}
      {withShare && (
        <ShareButton
          className={styles.action}
          url={href}
          title={title}
          size="medium"
          dataCustom="wall-share-custom"
        />
      )}
      {withFavorite && (
        <FavoriteButton
          type={type}
          id={id}
          className={styles.action}
          dataCustom="wall-favorite-custom"
        />
      )}
    </FlexBox>
  </FlexBox>
);

export default PromotionInfo;
