import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import LineGameCardSkeleton from '../LineGameCard/LineGameCardSkeleton';

import styles from './index.module.css';

type Props = {
  length?: number;
  sportAlias: string;
};

const LineGamesListSkeleton: Component<Props> = ({
  className = '',
  sportAlias,
  length = 5
}) => (
  <Box className={className}>
    {createSkeletonArray(length).map((item) => (
      <LineGameCardSkeleton
        key={item}
        sportAlias={sportAlias}
        className={styles.card}
      />
    ))}
  </Box>
);

export default LineGamesListSkeleton;
