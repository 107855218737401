import { xml } from '@xmpp/client';

import { IQ_TYPE, MESSAGES_TYPES, X_DATA_TYPE } from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';
import { TMamFormFields, TMamQuery } from '../../types';
import createStanzaId from '../createStanzaId';

import { getIqQuery } from './getIqQuery';
import { rsmCreator } from './rsm';
import { fieldCreator, xDataCreator } from './xData';

export const mamFormFields = () => getIqQuery(XML_NAMESPACES.MAM);

export const getMamMetadata = () =>
  xml('iq', { type: IQ_TYPE.GET }, xml('metadata', XML_NAMESPACES.MAM)); // not supported, need urn:xmpp:mam:2#extended

const mamXDataCreator = ({
  start,
  end,
  withJID,
  fullTextSearch
}: TMamFormFields) => {
  const x = xDataCreator(X_DATA_TYPE.SUBMIT);
  x.append(fieldCreator('FORM_TYPE', XML_NAMESPACES.MAM, 'hidden'));

  if (withJID) {
    x.append(fieldCreator('with', withJID.toString()));
  }
  if (start) {
    x.append(fieldCreator('start', start));
  }
  if (end) {
    x.append(fieldCreator('end', end));
  }
  if (fullTextSearch) {
    x.append(fieldCreator('full-text-search', fullTextSearch));
  }

  return x;
};

export const mamQuery = (args: TMamQuery = {}) => {
  const {
    start,
    end,
    withJID,
    max,
    after,
    before,
    queryId,
    id,
    type = MESSAGES_TYPES.SINGLE
  } = args;
  const iq = xml('iq', {
    type: IQ_TYPE.SET
  });
  const query = xml(
    'query',
    { xmlns: XML_NAMESPACES.MAM, queryid: queryId || createStanzaId() },
    mamXDataCreator({
      start,
      end,
      withJID: type === MESSAGES_TYPES.SINGLE ? withJID : undefined
    })
  );
  const rsm = rsmCreator({ max, after, before });

  if (rsm) {
    query.append(rsm);
  }

  if (id) {
    iq.attrs.id = id;
  }
  if (withJID && type === MESSAGES_TYPES.GROUP) {
    iq.attrs.to = withJID;
  }

  // query.append(xml('flip-page')); // not supported, need urn:xmpp:mam:2#extended

  iq.append(query);

  return iq;
};
