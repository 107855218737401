import React from 'react';

import { useConvertAmount } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

type Props = {
  tax: number;
  possibleWinAfterTax: number;
  currency: string;
  itemClassName?: string;
  amountClassName?: string;
};

const ProfitTaxes = ({
  tax,
  possibleWinAfterTax,
  currency,
  itemClassName,
  amountClassName
}: Props) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  const finalPayout = possibleWinAfterTax - tax;

  const taxAmount = useConvertAmount(tax);
  const finalPayoutAmount = useConvertAmount(finalPayout);

  return (
    <>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        direction={FLEX_DIRECTION.row}
        align={FLEX_ALIGN.center}
        className={itemClassName}
      >
        <Box>{t('sportsbook.betslip.bet.tax')}</Box>
        <Box className={amountClassName}>
          {taxAmount} {currency}
        </Box>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        direction={FLEX_DIRECTION.row}
        align={FLEX_ALIGN.center}
        className={itemClassName}
      >
        <Box>{t('sportsbook.betslip.bet.final.payout')}</Box>
        <Box className={amountClassName}>
          {finalPayoutAmount} {currency}
        </Box>
      </FlexBox>
    </>
  );
};

export default ProfitTaxes;
