import { camelizeKeys } from 'humps';

import { UGC_QUERY_TAG } from '../../../constants';

const getBlog = () => ({
  query: ({ siteId, id }: { siteId: number; id: number }) => ({
    url: `/post/${id}?partner_id=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  keepUnusedDataFor: 0,
  transformResponse: (response: any): any => camelizeKeys(response),
  providesTags: (result: unknown, error: unknown, { id }: { id: number }) => [
    { type: UGC_QUERY_TAG.BLOG_INFO, id }
  ]
});

export default getBlog;
