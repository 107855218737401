import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { supportChatOpen } from '@monorepo/common';
import { classNameProp, CustomFC } from '@monorepo/type';

type TItem = {
  link: string;
  activeClassName?: string;
  disabled?: boolean;
} & classNameProp;

const Item: CustomFC<TItem> = ({
  children,
  link = '',
  className = '',
  activeClassName = '',
  disabled = false
}) => {
  if (disabled) {
    return <div className={className}>{children}</div>;
  }
  const isSupportChat = link?.includes('openSupportChat');
  const onMenuItemClick = useCallback(
    (event) => {
      if (isSupportChat) {
        event.stopPropagation();
        event.preventDefault();
        supportChatOpen();
      }
    },
    [isSupportChat]
  );

  return (
    <NavLink
      to={link}
      onClick={onMenuItemClick}
      className={({ isActive }) =>
        `${className} ${isActive ? activeClassName : ''}`
      }
    >
      {children}
    </NavLink>
  );
};

export default Item;
