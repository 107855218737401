import { useMemo } from 'react';

import { useI18n } from '@monorepo/i18n';

type TEventStatsProps = {
  gameId: number;
};

const statsApiId = process.env.REACT_APP_STATS_API_ID;
const pwd = process.env.REACT_APP_STATS_API_PWD;

const useGetEventFullStatistics = ({
  gameId
}: TEventStatsProps): string | undefined => {
  const { language } = useI18n();

  return useMemo(
    () =>
      `https://stats.feedconstruct.com/matchDetails/langId=${language}/pwd=${pwd}/key=${statsApiId}/h2h/matchId=${gameId}`,
    [language, statsApiId, pwd, gameId]
  );
};

export default useGetEventFullStatistics;
