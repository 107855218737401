import { TOURNAMENTS_QUERY_KEYS } from '../../../constants';
import {
  TCasinoTournamentItem,
  TJoinTournament,
  TJoinTournamentResponse
} from '../../../types';
import joinTournamentAdapter from '../adapters/joinTournamentAdapter';

const joinToTournament = () => ({
  query: ({ id }: TCasinoTournamentItem) => ({
    body: {
      command: 'join_to_tournament',
      params: {
        tournament_id: id
      }
    }
  }),
  invalidatesTags: (result, error, arg) => [
    { type: TOURNAMENTS_QUERY_KEYS.TOURNAMENT, id: arg.id },
    TOURNAMENTS_QUERY_KEYS.TOURNAMENT_LIST
  ],
  transformResponse(response: TJoinTournamentResponse): TJoinTournament | {} {
    return !response ? {} : joinTournamentAdapter(response);
  }
});
export default joinToTournament;
