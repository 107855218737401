import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const BetslipInfoShutter = lazy(() => import('./BetslipInfoShutter'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BetslipInfoShutter {...props} />
  </Suspense>
);
