import { useAnimationFrame } from '@monorepo/helpers';
import { useIsServerOnline } from '@monorepo/xmpp';

import { useGetInboxQuery } from '../../store/socialsApi';

const useInbox = ({ skip = false, ...options } = {}) => {
  const isServerOnline = useIsServerOnline();

  const query = useGetInboxQuery(undefined, {
    skip: !isServerOnline || skip,
    ...options
  });

  useAnimationFrame({
    callback: query.refetch,
    delay: 10000,
    shouldAnimate: isServerOnline && query.isSuccess
  });

  return query;
};

export default useInbox;
