import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const SportsBookRedirect = lazy(() => import('./SportsBookRedirect'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <SportsBookRedirect {...props} />
  </Suspense>
);
