import { requestRoomConfiguration } from '@monorepo/xmpp';

import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const requestConfiguration = () => ({
  query: (roomName: string) => requestRoomConfiguration(roomName),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError
});

export default requestConfiguration;
