import { Component } from '@monorepo/type';

import { SOCIAL_NOTIFICATION_TYPE } from '../../constants';
import { TNotificationProps } from '../../types';
import CommunityInviteNotification from '../CommunityInviteNotification';
import DeclineCommunityInviteNotification from '../DeclineCommunityInviteNotification';
import FriendRequestNotification from '../FriendRequestNotification';
import UnreadMessageNotification from '../UnreadMessageNotification';

const notificationsMap = {
  [SOCIAL_NOTIFICATION_TYPE.FRIEND_REQUEST]: FriendRequestNotification,
  [SOCIAL_NOTIFICATION_TYPE.UNREAD_MESSAGE]: UnreadMessageNotification,
  [SOCIAL_NOTIFICATION_TYPE.COMMUNITY_UNREAD_MESSAGE]:
    UnreadMessageNotification,
  [SOCIAL_NOTIFICATION_TYPE.COMMUNITY_INVITE]: CommunityInviteNotification,
  [SOCIAL_NOTIFICATION_TYPE.COMMUNITY_DECLINE_INVITE]:
    DeclineCommunityInviteNotification
} as Record<SOCIAL_NOTIFICATION_TYPE, Component<TNotificationProps>>;

export default notificationsMap;
