import { PROVIDER_TYPE } from '../../../../constants';
import {
  TSubscribeToJackpotData,
  TSubscribeToJackpotResponse
} from '../../../../types';

const subscribeToJackpotAdapter = ({
  RequestId,
  Data
}: TSubscribeToJackpotResponse): TSubscribeToJackpotData => ({
  ...Data,
  RequestId,
  Type: PROVIDER_TYPE.DEFAULT
});

export default subscribeToJackpotAdapter;
