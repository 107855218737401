import React from 'react';

import { Component } from '@monorepo/type';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import {
  ENTITY_NAME,
  IMAGES_AMOUNT,
  TEAM_ICON_SIZE
} from '../../../../constants';
import getTeamIcon from '../../../../utils/getTeamIcon';

import styles from './index.module.css';

type Props = {
  itemId: string | number;
  title: string;
};

const Team: Component<Props> = ({ itemId, title = '' }) => {
  const count = Math.floor(Number(itemId) / IMAGES_AMOUNT);
  const imageUrl = getTeamIcon({
    count,
    itemId,
    size: TEAM_ICON_SIZE.SMALL,
    entity: ENTITY_NAME.EVENT
  });

  return (
    <FlexBox
      key={title}
      className={styles.teamWrap}
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
    >
      {imageUrl && itemId ? (
        <FlexBox
          className={styles.imgWrap}
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
        >
          <img src={imageUrl} alt="" className={styles.img} />
        </FlexBox>
      ) : null}
      <EllipsisText className={styles.teamItem}>{title}</EllipsisText>
    </FlexBox>
  );
};

export default Team;
