import { TWithdrawableBalance, TWithdrawableBalanceResponce } from 'types';

const withdrawableBalanceAdapter = (
  response: TWithdrawableBalanceResponce
): TWithdrawableBalance => {
  const { details } = response;
  const { Limit, UnplayedAmount, UnplayedFee } = details;
  const availableBalance = Number(Limit) - Number(UnplayedAmount);

  return {
    limit: Limit,
    unplayedAmount: UnplayedAmount,
    unplayedFee: UnplayedFee,
    availableBalance
  };
};

export default withdrawableBalanceAdapter;
