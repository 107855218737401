import React from 'react';

import { useConvertAmount } from '@monorepo/common';
import { useCurrencyTransform } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Box, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import getBalance from '../../utils/getBalance';

import styles from './index.module.css';

const UserBalance = ({ profile, className = '' }) => {
  const {
    balance = '',
    currencyName = '',
    bonusBalance = '',
    frozenBalance
  } = profile || {};
  const generalBalance = Number(Math.max(balance - frozenBalance, 0));
  const currencyTransformed = useCurrencyTransform(currencyName);
  const generalBalanceAmount = useConvertAmount(generalBalance || 0);
  return (
    <FlexBox direction={FLEX_DIRECTION.column}>
      {currencyTransformed !== null ? (
        <>
          <div className={`${styles.balance} ${className}`}>
            {generalBalanceAmount} {currencyTransformed}
          </div>
          {bonusBalance !== 0 && (
            <FlexBox className={styles.bonusBalance}>
              {getBalance(profile)}
              <Icon className={styles.bonusBalanceIcon} name="bonuses-0" />
            </FlexBox>
          )}
        </>
      ) : (
        <Box>...</Box>
      )}
    </FlexBox>
  );
};
export default UserBalance;
