import { useEffect, useRef } from 'react';

const useSetTimeout = (
  callback: () => void,
  delay: number | null = 1000,
  deps: any[] = []
): void => {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }

    return () => {};
  }, [delay, ...deps]);
};

export default useSetTimeout;
