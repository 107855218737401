import { useTypedSelector } from '@monorepo/redux';

import { userLocalSlice } from '../../../store/userLocalSlice';
import { TKeyBoardOpen } from '../../../types/userLocal';

const getKeyBoardOpen = (state: any) => state[userLocalSlice.name].keyBoardOpen;

const useKeyBoardOpen = (): TKeyBoardOpen => useTypedSelector(getKeyBoardOpen);

export default useKeyBoardOpen;
