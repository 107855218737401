import React, { useMemo } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { marketBaseNameFromDisplayKey } from '../../utils';

import styles from './index.module.css';

type Props = {
  base: string;
  displayKey?: string;
};

const LineMarketBase: Component<Props> = ({
  className = '',
  base,
  displayKey = ''
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const label = useMemo(
    () => marketBaseNameFromDisplayKey(displayKey),
    [displayKey]
  );

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={`${styles.wrap} ${className}`}
      title={t(`sportsbook.${label}`)}
    >
      {Boolean(label) && (
        <EllipsisText className={styles.label}>
          {t(`sportsbook.${label}.short`)}
        </EllipsisText>
      )}
      <FlexBox align={FLEX_ALIGN.center} className={styles.base}>
        {base}
      </FlexBox>
    </FlexBox>
  );
};

export default LineMarketBase;
