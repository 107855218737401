import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TCommonCMSRequest,
  TGetPromotionRequest,
  TGetPromotionsRequest,
  TGetPromotionsResponse,
  TPromotion,
  TPromotionCategory
} from '../types';

import getBanners from './api/getBanners';
import getMenu from './api/getMenu';
import getNotifications from './api/getNotifications';
import getPopups from './api/getPopups';
import getPromotion from './api/getPromotion';
import getPromotionsList from './api/getPromotions';
import getPromotionsCategories from './api/getPromotionsCategories';

const cmsHost = process.env.REACT_APP_CMS_HOST;

export const cmsApi = createApi({
  reducerPath: 'cmsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${cmsHost}/api/public/v1/` }),
  endpoints: (builder) => ({
    getMenu: builder.query<any[], any>(getMenu()),
    getNotifications: builder.query<any[], any>(getNotifications()),
    getBanners: builder.query<any[], any>(getBanners()),
    getPopups: builder.query<any[], any>(getPopups()),
    getPromotionsList: builder.query<
      TGetPromotionsResponse,
      TGetPromotionsRequest
    >(getPromotionsList()),
    getPromotionsCategories: builder.query<
      TPromotionCategory[],
      TCommonCMSRequest
    >(getPromotionsCategories()),
    getPromotion: builder.query<TPromotion, TGetPromotionRequest>(
      getPromotion()
    )
  })
});

export const {
  useGetMenuQuery,
  useGetNotificationsQuery,
  useGetBannersQuery,
  useGetPromotionsListQuery,
  useGetPromotionsCategoriesQuery,
  useGetPromotionQuery,
  useGetPopupsQuery
} = cmsApi;
