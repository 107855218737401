import React from 'react';

import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import PromotionCardVideo from '../PromotionCardVideo';

import styles from './index.module.css';

type Props = {
  list: any;
  withLike?: boolean;
  withComment?: boolean;
  withFavorite?: boolean;
  withStream?: boolean;
  withShare?: boolean;
};

const VideoBlock: Component<Props> = ({
  list = [],
  withLike = false,
  withComment = false,
  withFavorite = false,
  withStream = false,
  withShare = false
}) => (
  <Box className={styles.grid}>
    {(list || [])?.map(({ id, description, url, createTime }) => (
      <PromotionCardVideo
        key={id}
        id={id}
        description={description}
        url={url}
        createTime={createTime}
        withLike={withLike}
        withComment={withComment}
        withStream={withStream}
        withFavorite={withFavorite}
        withShare={withShare}
        className={styles.list}
      />
    ))}
  </Box>
);

export default VideoBlock;
