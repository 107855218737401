import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const FavoriteSportLine = lazy(() => import('./FavoriteSportLine'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <FavoriteSportLine {...props} />
  </Suspense>
);
