import React from 'react';

import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import Box from '../Box';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

const SiteLoader = () => (
  <FlexBox
    justify={FLEX_JUSTIFY.center}
    align={FLEX_ALIGN.center}
    className={styles.wrap}
  >
    <Box className="appLoaderImage" />
  </FlexBox>
);

export default SiteLoader;
