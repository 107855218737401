import { xml } from '@xmpp/client';

import {
  IQ_TYPE,
  MESSAGES_TYPES,
  MUC_AFFILIATION,
  MUC_ROLE,
  MUC_SERVER,
  PRESENCE_TYPE,
  X_DATA_TYPE
} from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';
import {
  TAffiliationItem,
  TDestroyRoom,
  TField,
  THistory,
  TInvitee,
  TInvites,
  TMediateInvite,
  TMucAffiliationItem,
  TMucItem,
  TMucRoleItem,
  TPresence,
  TRoleItem,
  TXConference
} from '../../types';

import { getIqQuery } from './getIqQuery';
import { presenceCreator } from './presence';
import { getDiscoItems } from './room';
import { fieldCreator, xDataCreator } from './xData';

export const createRoomJid = (roomName: string, server: string = MUC_SERVER) =>
  `${roomName}@${server}`;

export const createHistory = ({
  maxChars,
  maxStanzas,
  seconds,
  since
}: THistory) => {
  const history = xml('history');

  if (maxChars) {
    history.attrs.maxchars = maxChars;
  } else if (maxStanzas) {
    history.attrs.maxstanzas = maxStanzas;
  } else if (seconds) {
    history.attrs.seconds = seconds;
  } else if (since) {
    history.attrs.since = since;
  } else {
    history.attrs.maxchars = '0';
  }

  return history;
};

/**
 * https://xmpp.org/extensions/xep-0045.html#enter
 */
export const enterRoom = (
  roomJid: string,
  nickname: string,
  history: THistory | null = {},
  password?: string
) => {
  const x = xml('x', XML_NAMESPACES.MUC);

  if (password) {
    // https://xmpp.org/extensions/xep-0045.html#enter-pw
    x.append(xml('password', {}, password));
  }

  if (history) {
    // https://xmpp.org/extensions/xep-0045.html#enter-managehistory
    const historyEl = createHistory(history);

    x.append(historyEl);
  }

  return xml('presence', { to: `${roomJid}/${nickname}` }, x);
};

/**
 * https://xmpp.org/extensions/xep-0045.html#changenick
 */
export const changeRoomNick = (roomJid: string, nickname: string) =>
  xml('presence', { to: `${roomJid}/${nickname}` });

/**
 * https://xmpp.org/extensions/xep-0045.html#changepres
 */
export const changeAvailabilityStatus = (
  roomJid: string,
  nickname: string,
  { status, show }: Pick<TPresence, 'status' | 'show'> = {}
) => presenceCreator({ status, show, to: `${roomJid}/${nickname}` });

/**
 * https://xmpp.org/extensions/xep-0249.html
 */
export const directInvitation = (
  roomJid: string,
  to: string,
  params: TXConference = {}
) => {
  const { password, thread, reason } = params;
  const x = xml('x', { xmlns: XML_NAMESPACES.X_CONFERENCE, jid: roomJid });

  if (password) {
    x.attrs.password = password;
  }
  if (reason) {
    x.attrs.reason = reason;
  }
  if (thread) {
    x.attrs.thread = thread;
    x.attrs.continue = 'true';
  }

  return xml('message', { to }, x);
};

/**
 * https://xmpp.org/extensions/xep-0045.html#invite-mediated
 */
const createInvite = (invitee: TInvitee) => {
  if (typeof invitee === 'string') {
    return xml('invite', { to: invitee });
  }
  const { userJid, reason } = invitee;
  const invite = xml('invite', { to: userJid });

  if (reason) {
    invite.append(xml('reason', {}, reason));
  }

  return invite;
};

const createInvites = (invites: TInvites) =>
  Array.isArray(invites) ? invites.map(createInvite) : [createInvite(invites)];

export const mediatedInvite = ({ roomName, server, invites }: TMediateInvite) =>
  xml(
    'message',
    { to: createRoomJid(roomName, server) },
    xml('x', XML_NAMESPACES.MUC_USER, ...createInvites(invites))
  );

export const mediatedDecline = (
  roomJid: string,
  to: string,
  reason?: string
) => {
  const decline = xml('decline', { to });

  if (reason) {
    decline.append(xml('reason', {}, reason));
  }

  return xml(
    'message',
    { to: roomJid },
    xml('x', XML_NAMESPACES.MUC_USER, decline)
  );
};

/**
 * https://xmpp.org/extensions/xep-0045.html#register
 */
export const requestRoomRegister = (roomName: string, server?: string) =>
  getIqQuery(XML_NAMESPACES.REGISTER, createRoomJid(roomName, server));

/**
 * https://xmpp.org/extensions/xep-0045.html#requestvoice
 */
const voiceXDataCreator = (role: MUC_ROLE) => {
  const x = xDataCreator(X_DATA_TYPE.SUBMIT);
  x.append(fieldCreator('FORM_TYPE', XML_NAMESPACES.MUC_REQUEST, 'hidden'));
  x.append(fieldCreator('muc#role', role, 'list-single'));

  return x;
};

export const requestVoice = (
  roomJid: string,
  role: MUC_ROLE = MUC_ROLE.PARTICIPANT
) => xml('message', { to: roomJid }, voiceXDataCreator(role));

/**
 * https://xmpp.org/extensions/xep-0045.html#exit
 */

export const exitRoom = (roomJid: string, nickname: string, status?: string) =>
  presenceCreator({
    to: `${roomJid}/${nickname}`,
    type: PRESENCE_TYPE.UNAVAILABLE,
    status
  });

/**
 * https://xmpp.org/extensions/xep-0045.html#subject-mod
 */
export const changeSubject = (roomJid: string, subject: string = '') =>
  xml(
    'message',
    { to: roomJid, type: MESSAGES_TYPES.GROUP },
    xml('subject', {}, subject)
  );

const itemCreator = ({ nick, jid, affiliation, role, reason }: TMucItem) => {
  const item = xml('item');

  if (nick) {
    item.attrs.nick = nick;
  }
  if (jid) {
    item.attrs.jid = jid;
  }
  if (affiliation) {
    item.attrs.affiliation = affiliation;
  }
  if (role) {
    item.attrs.role = role;
  }
  if (reason) {
    item.append(xml('reason', {}, reason));
  }

  return item;
};

const setRoomItems = (
  roomName: string,
  items: TMucItem | TMucItem[],
  server?: string
) => {
  const list = Array.isArray(items)
    ? items.map(itemCreator)
    : [itemCreator(items)];

  return xml(
    'iq',
    { to: createRoomJid(roomName, server), type: IQ_TYPE.SET },
    xml('query', XML_NAMESPACES.MUC_ADMIN, ...list)
  );
};

const getRoleItem = (item: TRoleItem) => {
  if (typeof item === 'string') {
    return { nick: item };
  }
  return item;
};

const changeRole =
  (role: MUC_ROLE) => (roomName: string, item: TRoleItem, server?: string) =>
    setRoomItems(roomName, { role, ...getRoleItem(item) }, server);

/**
 * https://xmpp.org/extensions/xep-0045.html#kick
 */
export const kickFromRoom = changeRole(MUC_ROLE.NONE);

/**
 * https://xmpp.org/extensions/xep-0045.html#grantvoice
 */
export const grantVoice = changeRole(MUC_ROLE.PARTICIPANT);

/**
 * https://xmpp.org/extensions/xep-0045.html#revokevoice
 */
export const revokeVoice = changeRole(MUC_ROLE.VISITOR);

/**
 * https://xmpp.org/extensions/xep-0045.html#modifyvoice
 */
export const requestRoleList = (role: MUC_ROLE) => (roomJid: string) =>
  xml(
    'iq',
    { to: roomJid, type: IQ_TYPE.GET },
    xml('query', XML_NAMESPACES.MUC_ADMIN, xml('item', { role }))
  );

export const requestVoiceList = requestRoleList(MUC_ROLE.PARTICIPANT);

export const modifyVoiceList = (
  roomName: string,
  list: TMucRoleItem | TMucRoleItem[],
  server?: string
) => setRoomItems(roomName, list, server);

/**
 * https://xmpp.org/extensions/xep-0045.html#voiceapprove
 */
const voiceApproveXDataCreator = (
  jid: string,
  roomNick: string,
  role: MUC_ROLE
) => {
  const x = xDataCreator(X_DATA_TYPE.SUBMIT);
  x.append(fieldCreator('FORM_TYPE', XML_NAMESPACES.MUC_REQUEST, 'hidden'));
  x.append(fieldCreator('muc#role', role));
  x.append(fieldCreator('muc#jid', jid));
  x.append(fieldCreator('muc#roomnick', roomNick));
  x.append(fieldCreator('muc#request_allow', 'true'));

  return x;
};

export const approveVoiceRequest = (
  roomJid: string,
  jid: string,
  roomNick: string,
  role: MUC_ROLE = MUC_ROLE.PARTICIPANT
) =>
  xml(
    'message',
    { to: roomJid },
    voiceApproveXDataCreator(jid, roomNick, role)
  );

const getAffiliationItem = (item: TAffiliationItem) => {
  if (typeof item === 'string') {
    return { jid: item };
  }
  return item;
};

const changeAffiliation =
  (affiliation: MUC_AFFILIATION) =>
  (roomName: string, item: TAffiliationItem, server?: string) =>
    setRoomItems(
      roomName,
      { affiliation, ...getAffiliationItem(item) },
      server
    );

/**
 * https://xmpp.org/extensions/xep-0045.html#ban
 */
export const banUser = changeAffiliation(MUC_AFFILIATION.OUTCAST);

/**
 * https://xmpp.org/extensions/xep-0045.html#modifyban
 */
export const requestAffiliationList = (
  affiliation: MUC_AFFILIATION,
  roomName: string,
  server?: string
) =>
  xml(
    'iq',
    { to: createRoomJid(roomName, server), type: IQ_TYPE.GET },
    xml('query', XML_NAMESPACES.MUC_ADMIN, xml('item', { affiliation }))
  );

const getAffiliationList =
  (affiliation: MUC_AFFILIATION) => (roomName: string, server?: string) =>
    requestAffiliationList(affiliation, roomName, server);

export const requestBanList = getAffiliationList(MUC_AFFILIATION.OUTCAST);

export const modifyAffiliationList = (
  roomName: string,
  list: TMucAffiliationItem | TMucAffiliationItem[],
  server?: string
) => setRoomItems(roomName, list, server);

/**
 * https://xmpp.org/extensions/xep-0045.html#grantmember
 */
export const grantMembership = changeAffiliation(MUC_AFFILIATION.MEMBER);

/**
 * https://xmpp.org/extensions/xep-0045.html#revokemember
 */
export const revokeMembership = changeAffiliation(MUC_AFFILIATION.NONE);

/**
 * https://xmpp.org/extensions/xep-0045.html#modifymember
 */
export const requestMemberList = getAffiliationList(MUC_AFFILIATION.MEMBER);

/**
 * https://xmpp.org/extensions/xep-0045.html#grantmod
 */
export const grantModerator = changeRole(MUC_ROLE.MODERATOR);

/**
 * https://xmpp.org/extensions/xep-0045.html#revokemod
 */
export const revokeModerator = changeRole(MUC_ROLE.PARTICIPANT);

/**
 * https://xmpp.org/extensions/xep-0045.html#modifymod
 */
export const requestModeratorList = requestRoleList(MUC_ROLE.MODERATOR);

/**
 * https://xmpp.org/extensions/xep-0045.html#createroom
 */
export const createRoom = (
  roomName: string,
  nickname: string,
  server?: string
) => {
  const presence = presenceCreator({
    to: `${createRoomJid(roomName, server)}/${nickname}`
  });
  presence.append(xml('x', XML_NAMESPACES.MUC));

  return presence;
};

/**
 * https://xmpp.org/extensions/xep-0045.html#createroom-instant
 */
export const requestInstantRoom = (roomName: string, server?: string) =>
  xml(
    'iq',
    { to: createRoomJid(roomName, server), type: IQ_TYPE.SET },
    xml('query', XML_NAMESPACES.MUC_OWNER, xDataCreator(X_DATA_TYPE.SUBMIT))
  );

/**
 * https://xmpp.org/extensions/xep-0045.html#createroom-reserved
 */
export const requestRoomConfiguration = (roomName: string, server?: string) =>
  getIqQuery(XML_NAMESPACES.MUC_OWNER, createRoomJid(roomName, server));

export const submitConfiguration = (
  roomName: string,
  fields: TField[],
  server?: string
) => {
  const x = xDataCreator(X_DATA_TYPE.SUBMIT);
  x.append(fieldCreator('FORM_TYPE', XML_NAMESPACES.MUC_ROOM_CONFIG, 'hidden'));
  x.append(
    ...fields.map(({ variable, value, type }) =>
      fieldCreator(variable, value, type)
    )
  );

  return xml(
    'iq',
    { to: createRoomJid(roomName, server), type: IQ_TYPE.SET },
    xml('query', XML_NAMESPACES.MUC_OWNER, x)
  );
};

/**
 * following action will destroy room if it was initial configuration:)
 */
export const cancelConfiguration = (roomName: string, server?: string) =>
  xml(
    'iq',
    { to: createRoomJid(roomName, server), type: IQ_TYPE.SET },
    xml('query', XML_NAMESPACES.MUC_OWNER, xDataCreator(X_DATA_TYPE.CANCEL))
  );

/**
 * https://xmpp.org/extensions/xep-0045.html#grantowner
 */
export const grantOwner = changeAffiliation(MUC_AFFILIATION.OWNER);

/**
 * https://xmpp.org/extensions/xep-0045.html#revokeowner
 */
export const revokeOwner = changeAffiliation(MUC_AFFILIATION.ADMIN);

/**
 * https://xmpp.org/extensions/xep-0045.html#modifyowner
 */
export const requestOwnerList = getAffiliationList(MUC_AFFILIATION.OWNER);

/**
 * https://xmpp.org/extensions/xep-0045.html#grantadmin
 */
export const grantAdmin = changeAffiliation(MUC_AFFILIATION.ADMIN);

/**
 * https://xmpp.org/extensions/xep-0045.html#revokeadmin
 */
export const revokeAdmin = changeAffiliation(MUC_AFFILIATION.MEMBER);

/**
 * https://xmpp.org/extensions/xep-0045.html#modifyadmin
 */
export const requestAdminList = getAffiliationList(MUC_AFFILIATION.ADMIN);

/**
 * https://xmpp.org/extensions/xep-0045.html#destroyroom
 */
export const destroyRoom = ({
  roomName,
  server,
  reason,
  alternativeRoomName,
  alternativeRoomPassword
}: TDestroyRoom) => {
  const destroy = xml('destroy');

  if (alternativeRoomName) {
    destroy.attrs.jid = createRoomJid(alternativeRoomName, server);
  }
  if (reason) {
    destroy.append(xml('reason', {}, reason));
  }
  if (alternativeRoomPassword) {
    destroy.append(xml('password', {}, alternativeRoomPassword));
  }

  return xml(
    'iq',
    { to: createRoomJid(roomName, server), type: IQ_TYPE.SET },
    xml('query', XML_NAMESPACES.MUC_OWNER, destroy)
  );
};

/**
 * https://xmpp.org/extensions/xep-0307.html
 */
export const requestRoomName = (server: string = MUC_SERVER) =>
  getIqQuery(XML_NAMESPACES.MUC_UNIQUE, server);

export const getMucRooms = (server: string = MUC_SERVER) =>
  getIqQuery(XML_NAMESPACES.DISCO_ITEMS, server);

/**
 * https://xmpp.org/extensions/xep-0045.html#disco-roomitems
 */
export const requestExistingOccupantsList = (
  roomName: string,
  server?: string
) => getDiscoItems(createRoomJid(roomName, server));
