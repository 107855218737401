const getStreams = () => ({
  query: () => ({
    body: {
      command: 'get',
      params: {
        source: 'notifications',
        what: {
          partner_streams: []
        }
      }
    }
  })
});
export default getStreams;
