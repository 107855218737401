import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useIsAuthenticated } from '@monorepo/auth';
import { USER_TYPES } from '@monorepo/page';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import HeaderColumn from './components/HeaderColumn';

import styles from './index.module.css';

const Header: CustomFC = () => {
  const { header, components } = useSiteSettings();
  const { pageType } = useDeviceDetector();
  const { isAuthenticated } = useIsAuthenticated();
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 1) {
        mainRef.current?.classList.add(styles.scrolled);
      } else {
        mainRef.current?.classList.remove(styles.scrolled);
      }
    };
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const columns = useMemo(() => {
    // @ts-ignore
    const deviceColumns = header[pageType] ? header[pageType] : header.mobile;
    return isAuthenticated
      ? deviceColumns[USER_TYPES.USER]
      : deviceColumns[USER_TYPES.GUEST];
  }, [header, pageType, isAuthenticated]);

  return (
    <div className={styles.header} ref={mainRef}>
      <PaddingWrapper className={styles.wrap}>
        <FlexBox justify={FLEX_JUSTIFY.between} align={FLEX_ALIGN.center}>
          {columns.map((col: Array<string>) => (
            <HeaderColumn
              key={col.join('/')}
              components={col}
              className={styles.column}
              headerComponentsMap={components}
            />
          ))}
        </FlexBox>
      </PaddingWrapper>
    </div>
  );
};

export default memo(Header);
