import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const PromotionsCategoriesFilter = lazy(
  () => import('./PromotionsCategoriesFilter')
);

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <PromotionsCategoriesFilter config={config} />
  </Suspense>
);
