const cancelWithdraw = () => ({
  query: ({ id }) => ({
    body: {
      command: 'withdraw_cancel',
      params: {
        id
      }
    }
  }),
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  }
});
export default cancelWithdraw;
