import React, { useEffect, useMemo, useRef, useState } from 'react';

import { HighlightedSearchText } from '@monorepo/common';
import { FILTER_PARAMS, useSearchParamsWithParsing } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import {
  EllipsisText,
  FLEX_ALIGN,
  FlexBox,
  getTooltipDataAttributes
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  name: string;
  points?: string | number | null;
  isLive?: number;
};

const Team: Component<Props> = ({ name, className = '', children }) => {
  const { searchParams } = useSearchParamsWithParsing();
  const [isOverflowed, setIsOverflow] = useState(false);
  const teamRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (teamRef.current) {
      const { scrollWidth, clientWidth } = teamRef.current;
      setIsOverflow(scrollWidth > clientWidth);
    }
  }, [teamRef.current]);

  const tooltipAttrs = useMemo(
    () =>
      getTooltipDataAttributes({
        place: 'top',
        content: name,
        hidden: !isOverflowed
      }),
    [isOverflowed, name]
  );

  return (
    <FlexBox
      className={`${styles.wrap} ${className}`}
      align={FLEX_ALIGN.center}
    >
      <EllipsisText className={styles.name} ref={teamRef} {...tooltipAttrs}>
        <HighlightedSearchText
          replacingText={name}
          className={styles.searched}
          searchText={searchParams[FILTER_PARAMS.search] as string}
        />
      </EllipsisText>
      {children}
    </FlexBox>
  );
};

export default Team;
