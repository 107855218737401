import { useCallback } from 'react';

import { useUpdateStatus } from '@monorepo/auth';
import { TPresence } from '@monorepo/xmpp';

import useSendStatus from '../useSendStatus';
import useUserStatus from '../useUserStatus';

const useStatusChange = () => {
  const { status, show } = useUserStatus();
  const sendStatus = useSendStatus();
  const { onUpdateStatus } = useUpdateStatus();

  return useCallback(
    async (statusObj: TPresence) => {
      const mergedStatus = { status, show, ...statusObj };
      await onUpdateStatus({
        status: mergedStatus.status,
        visibility_status: mergedStatus.show
      });
      await sendStatus(mergedStatus);
    },
    [status, show, sendStatus, onUpdateStatus]
  );
};

export default useStatusChange;
