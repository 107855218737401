import { ValidationRule, ValidationValue } from 'react-hook-form';

import createErrorWithValue from './createErrorWithValue';

type TKey = string;

const validationRuleCreator = <TValidationValue extends ValidationValue>(
  key: TKey,
  value: TValidationValue,
  message: string
): Record<TKey, ValidationRule<TValidationValue>> => ({
  [key]: {
    value,
    message: createErrorWithValue(message, value)
  }
});

export default validationRuleCreator;
