import React from 'react';
import { useController } from 'react-hook-form';
import { CountryData } from 'react-international-phone/dist/types';

import { FIELD_TONE, PhoneFormInput } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import Control from '../Control';

import styles from './index.module.css';

type Props<TFormValues> = {
  defaultCountry?: string;
  countries?: CountryData[];
} & TFormField<TFormValues>;

const PhoneField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  className = '',
  rules = {},
  defaultCountry,
  countries = [],
  inputClassName = '',
  errorClassName = '',
  toneType = FIELD_TONE.dark,
  errorTranslationNS,
  disabled
}: Props<TFormValues>) => {
  const { field } = useController({ name, rules });

  const handleChange = (value: string) => field.onChange(value);

  const requiredLabel = Object.hasOwn(rules, 'required');

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={`${styles.wrap} ${className}`}
      toneType={toneType}
      errorClassName={errorClassName}
      errorTranslationNS={errorTranslationNS}
      requiredLabel={requiredLabel}
    >
      <PhoneFormInput
        inputClassName={inputClassName}
        value={field.value}
        name={field.name}
        onChange={handleChange}
        onBlur={field.onBlur}
        defaultCountry={defaultCountry}
        countries={countries}
        toneType={toneType}
        disabled={disabled}
      />
    </Control>
  );
};

export default PhoneField;
