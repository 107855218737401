import { setConversationRead } from '@monorepo/xmpp';

import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const setInboxRead = () => ({
  query: (jid: string) => setConversationRead(jid),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError
});

export default setInboxRead;
