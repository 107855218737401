import React, { useCallback, useState } from 'react';

import { useSendSMS } from '@monorepo/auth';
import { convertToDuration, useAnimationFrame } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  userNotificationActions,
  useSelectTimeForEndSMSTimer
} from '@monorepo/notification';
import { CustomFC } from '@monorepo/type';
import { Button, BUTTON_SIZE } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TProps = {
  actionType: 1 | 2 | 3;
  phoneNumber: string;
  resendPeriod?: number;
};

const PING_PERIOD = 1;

const GetCodeButton: CustomFC<TProps> = ({
  actionType,
  phoneNumber,
  resendPeriod = 60
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const endSMSTimer = useSelectTimeForEndSMSTimer();
  const { onSendSMS } = useSendSMS();
  const { setTimeForEndSMSTimer, setClearTimeForSMSTimer } =
    userNotificationActions();
  const [secondsValue, setSecondsValue] = useState('00');
  const onSendCode = () => {
    const startTime = Date.now() / 1000;
    setTimeForEndSMSTimer(Math.round(startTime) + resendPeriod);
    onSendSMS({
      actionType,
      phoneNumber
    });
  };
  const handleCount = useCallback(() => {
    const startTime = Date.now() / 1000;
    const diff = Math.round(endSMSTimer - startTime);
    if (diff > 0) {
      setSecondsValue(diff);
    } else {
      setSecondsValue(0);
      setClearTimeForSMSTimer();
    }
  }, [onSendCode]);

  useAnimationFrame({
    callback: handleCount,
    delay: PING_PERIOD * 1000,
    shouldAnimate: secondsValue >= 0
  });

  const { minutes, seconds } = convertToDuration(secondsValue);
  return (
    <Button
      size={BUTTON_SIZE.h40}
      className={styles.buttonGetCode}
      disabled={seconds !== '00'}
      onClick={onSendCode}
    >
      {seconds !== '00'
        ? `${minutes}:${seconds}`
        : t('smsverification.getcode')}
    </Button>
  );
};

export default GetCodeButton;
