import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import {
  FIELD_TONE,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Radio,
  RadioProps
} from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import Control from '../Control';

import styles from './index.module.css';

type RadioGroupProps<TFormValues> = {
  elements: Array<Partial<RadioProps>>;
  direction?: FLEX_DIRECTION;
} & TFormField<TFormValues>;

const RadioGroupField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label,
  className = '',
  elements,
  inputClassName,
  errorClassName,
  rules,
  disabled,
  toneType = FIELD_TONE.dark,
  direction = FLEX_DIRECTION.row,
  errorTranslationNS
}: RadioGroupProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const inputClass = `${
    direction ? styles[`${direction}Input`] : ''
  } ${inputClassName}`;

  const requiredLabel = rules ? Object.hasOwn(rules, 'required') : false;

  return (
    <Control
      id={id}
      name={name}
      label={label}
      className={className}
      toneType={toneType}
      errorClassName={errorClassName}
      errorTranslationNS={errorTranslationNS}
      requiredLabel={requiredLabel}
    >
      <FlexBox direction={direction} justify={FLEX_JUSTIFY.between}>
        {elements.map((el) => (
          <Radio
            id={el.id || `${name}.${el.value}`}
            key={el.id || `${name}.${el.value}`}
            label={el.label}
            value={el.value}
            className={inputClass}
            labelClass={el.labelClass}
            disabled={disabled || el.disabled}
            toneType={toneType}
            {...register(name, { ...rules })}
          />
        ))}
      </FlexBox>
    </Control>
  );
};

export default RadioGroupField;
