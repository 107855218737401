import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getSlot from './api/getSlot';
import getSlots from './api/getSlots';
import getTags from './api/getTags';

export const casinoWallApi = createApi({
  reducerPath: 'casinoWallApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  endpoints: (builder) => ({
    getTags: builder.query(getTags()),
    getSlots: builder.query(getSlots()),
    getSlot: builder.query(getSlot())
  })
});

export const { useGetTagsQuery, useGetSlotsQuery, useGetSlotQuery } =
  casinoWallApi;
