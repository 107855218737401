import React from 'react';

import { ClipBoardCopy } from '@monorepo/common';
import { getLocationHostName } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  id: string;
};

const ProfileID: Component<Props> = ({ id, className = '' }) => {
  const { generalRoutes } = useSiteSettings();
  const domain = getLocationHostName();
  const { profile: profileUrl } = generalRoutes;
  const { t } = useI18n(NAMESPACES.ACCOUNT);

  return (
    <FlexBox
      className={`${styles.id} ${className}`}
      title={t('profile.id.tooltip')}
    >
      <ClipBoardCopy toCopy={`${domain}${profileUrl}/${id}`}>
        {t('profile.id')}: {id}
        <Icon name="link" className={styles.copy} title="" />
      </ClipBoardCopy>
    </FlexBox>
  );
};

export default ProfileID;
