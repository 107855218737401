import { useIsAuthenticated } from '@monorepo/auth';

import { useGetPaymentServicesQuery } from '../../store/wallet';

const useGetPaymentServices = (options = {}) => {
  const { skip, ...rest } = options;
  const { isAuthenticated } = useIsAuthenticated();

  return useGetPaymentServicesQuery(undefined, {
    skip: !isAuthenticated || skip,
    ...rest
  });
};

export default useGetPaymentServices;
