import { useHasLoginCookies } from '@monorepo/auth';

import { userSearchSymbolsNumber } from '../../../constants';
import { useGetSearchPlayersQuery } from '../../../store/customApiStore';

const useGetSearchPlayers = (search: string = '', options = {}) => {
  const isCookiesFetched = useHasLoginCookies();

  return useGetSearchPlayersQuery(search, {
    skip: !isCookiesFetched || search.length < userSearchSymbolsNumber,
    ...options
  });
};

export default useGetSearchPlayers;
