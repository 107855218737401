const COMPONENT_TYPES = {
  MOBILE_REGIONS_MENU: 'mobile-regions-menu',
  SPORTS_COUPON: 'sports-coupon',
  PRE_MATCH_SPORTS_TABS: 'pre-match-sports-tabs',
  DESKTOP_PRE_MATCH_SPORTS_TABS: 'desktop-pre-type-menu',
  EVENT_HEADER: 'event-header',
  EVENT_TITLE: 'event-title',
  SPORTS_LINE: 'sports-line',
  SPORTS_RECOMMENDED_LINE: 'sports-recommended-line',
  SPORTS_LINE_SEARCH: 'sports-line-search',
  SPORTS_SUGGESTED: 'sports-suggested',
  SPORTS_POPULAR: 'sports-popular',
  SPORT_RESULTS_FILTERS: 'sport-filters',
  SPORT_EVENTS: 'sport-events',
  SPORT_BACK_HEADER_RESULTS: 'back-header-results',
  EVENT_STATISTICS: 'event-statistics',
  EVENT_TABS: 'event-tabs',
  TIME_FILTER: 'time-filter',
  COMPETITION_SWITCHER: 'competition-switcher',
  COMPETITION_HEADER: 'competition-header',
  SPORTS_NAVIGATION: 'sports-navigation',
  SPORTS_RECOMMENDED_NAVIGATION: 'sports-recommended-navigation',
  MOBILE_POPULAR_COMPETITIONS: 'mobile-popular-competitions',
  SPORTS_BOOK_REDIRECT: 'sportsbook-redirect',
  GAME_ANIMATION: 'game-animation',
  ANIMATION_VIEW: 'animation-view',
  LIVE_STREAM_FRAME: 'live-stream-frame',
  SEARCH_SPORTS_COMPETITIONS: 'search-sports-competitions',
  BETSLIP_INFO_SHUTTER: 'betslip-mobile',
  BETSLIP_HOME: 'betslip-home',
  PREMATCH_REDIRECT: 'prematch-redirect',
  QUICKBET: 'quickbet',
  FAVORITES: 'favorites',
  FAVORITESPORTS: 'favorite-sports',
  FAVORITE_TEAM: 'favorite-team',
  FAVORITE_SPORTS_LINE: 'favorite-sports-line',
  FAVORITE_SPORTS_COMPETITIONS: 'favorite-sports-competitions',
  FAVORITE_SPORTS_COMPETITIONS_MAIN: 'favorite-sports-competitions-main',
  BETS_HISTORY_ALL: 'bets-history',
  BETS_FILTERS: 'bets-filters'
};

export default COMPONENT_TYPES;
