import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { usePlayerProfile } from '@monorepo/cms';
import { getNickName, PROFILE_NICKNAME } from '@monorepo/common';
import { AVATAR_SIZE } from '@monorepo/files';
import { useSiteSettings } from '@monorepo/site-settings';
import { Box, FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import HeaderAvatar from '../../common/HeaderAvatar';
import useGetUser from '../../hooks/useGetUser';
import UserBalance from '../UserBalance';

import styles from './index.module.css';

const UserInfo = () => {
  const { data: profile = {} } = useGetUser();
  const { generalRoutes, userDefaultNick = PROFILE_NICKNAME.username } =
    useSiteSettings();
  const { profile: profilePage } = generalRoutes;
  const player = usePlayerProfile();
  const imageNew = `${player?.data?.avatar_img}/${AVATAR_SIZE.S}`;
  const nickname = getNickName(
    profile,
    player?.data,
    userDefaultNick as PROFILE_NICKNAME
  );

  return (
    <FlexBox
      className={styles.wrap}
      justify={FLEX_JUSTIFY.center}
      align={FLEX_ALIGN.center}
    >
      <Link
        to={profilePage}
        className={`${styles.avatar} ${styles.avatarLink}`}
      />
      <HeaderAvatar
        className={styles.avatar}
        avatarClass={styles.headerAvatar}
        statusClassName={styles.status}
        imgSrc={imageNew}
        nickname={nickname}
      />
      <Box className={styles.user}>
        <Box className={styles.name}>{nickname}</Box>
        <UserBalance profile={profile} className={styles.balance} />
      </Box>
    </FlexBox>
  );
};

export default memo(UserInfo);
