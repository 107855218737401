import Element from 'ltx/src/Element';

import { isCommunityRoom, XML_NAMESPACES } from '@monorepo/xmpp';

export const isFromCommunity = (stanza: Element) => {
  const { from } = stanza.attrs;
  return isCommunityRoom(from);
};

export const isPresence = (stanza: Element) => stanza.is('presence');

export const isMessage = (stanza: Element) => stanza.is('message');

export const isInboxMessage = (stanza: Element) =>
  Boolean(stanza.getChild('result', XML_NAMESPACES.INBOX));

export const isInboxConversationMessage = (stanza: Element) =>
  Boolean(stanza.getChild('x', XML_NAMESPACES.INBOX_CONVERSATION));
