import { convertAmount } from '@monorepo/common';

import { TAmountConverter } from '../../types';
import convertAmountHelper from '../convertAmountHelper';

import createErrorWithValue from './createErrorWithValue';
import validateCreator from './validateCreator';

type TAmountValue = {
  value?: number | string;
  currency?: string;
  message?: string;
};

type TAmountFieldValidator = {
  min?: TAmountValue;
  max?: TAmountValue;
};

const amountFieldValidator = (
  { min, max }: TAmountFieldValidator,
  converter: TAmountConverter,
  isErrorValueConverted: boolean = true
) => {
  const validate = (fieldValue: string) => {
    const amount = convertAmountHelper(fieldValue, converter);
    if ((max?.value || max?.value === 0) && amount > max.value) {
      return createErrorWithValue(
        max?.message || 'validators.value.max',
        isErrorValueConverted ? convertAmount(max.value) : max.value,
        max?.currency
      );
    }

    if ((min?.value || min?.value === 0) && amount < min.value) {
      return createErrorWithValue(
        min?.message || 'validators.value.min',
        isErrorValueConverted ? convertAmount(min.value) : min.value,
        min?.currency
      );
    }
    return true;
  };

  return validateCreator<string>(validate);
};

export default amountFieldValidator;
