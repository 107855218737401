import Element from 'ltx/src/Element';

import {
  getBareJID,
  getIdFromJID,
  SUBSCRIPTION_TYPE,
  XML_NAMESPACES
} from '@monorepo/xmpp';

import IqHandler from '../IqHandler';

export default class RosterHandler {
  handler: IqHandler;

  constructor(handler: IqHandler) {
    this.handler = handler;
  }

  public processStanza(stanza: Element) {
    const { type } = stanza.attrs;
    const rosterEl = stanza.getChild('query', XML_NAMESPACES.ROSTER);
    const { setRoster, updateRoster } = this.handler.reduxActions;

    if (type === 'result' || type === 'set') {
      const items = rosterEl?.getChildren('item') || [];
      const roster = items.map((item: any) => {
        const {
          jid,
          name,
          subscription = SUBSCRIPTION_TYPE.NONE,
          ask = null
        } = item.attrs;
        const id = getIdFromJID(jid);
        const bareJid = getBareJID(jid).toString();

        return {
          id,
          jid: bareJid,
          subscription,
          name,
          ask
        };
      });

      if (type === 'set') {
        updateRoster(roster);
      } else {
        setRoster(roster);
      }
    }
  }
}
