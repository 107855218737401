import get from 'lodash/get';

import { TRawCompetitorStatiscs, TRawGameStatistics } from '../../payloadTypes';

export const normalizeGameStatsResponse = (rawResponse: TRawGameStatistics) => {
  const response: TRawCompetitorStatiscs[] = get(
    rawResponse,
    'GeneralStatsInfoResult',
    []
  );

  if (response.length !== 0) {
    const result = response.reduce(
      (
        acc,
        {
          EntityId,
          Points,
          Position,
          GeneralInfoWDLWithKeys
        }: TRawCompetitorStatiscs
      ) => ({
        ...acc,
        [EntityId]: {
          points: Points,
          position: Position,
          history: GeneralInfoWDLWithKeys.map(
            ({ Key, Value }: Record<string, string>) => ({
              key: Key,
              value: Value
            })
          )
        }
      }),
      {} as Record<string, string | []>
    );

    return result;
  }
  return response;
};
