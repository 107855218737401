import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const TimeFilter = lazy(() => import('./TimeFilter'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <TimeFilter {...props} />
  </Suspense>
);
