import React from 'react';
import isNumber from 'lodash/isNumber';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import useNumberChange from '../../hooks/useNumberChange';

import styles from './index.module.css';

type Props = {
  coefficient: number | string;
};

const Coefficient: Component<Props> = ({ coefficient, className = '' }) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const { updateCoefficientTime } = useSiteSettings();
  const move = useNumberChange(coefficient, updateCoefficientTime);

  const formattedCoefficient = isNumber(coefficient)
    ? coefficient.toFixed(2)
    : coefficient;

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.between}
      className={`${styles.wrap} ${move ? styles[move] : ''} ${className}`}
    >
      {formattedCoefficient.toString() === '1.00' ? (
        <Icon
          name="lock-1"
          className={styles.emptyOutcome}
          title={t('sportsbook.outcome.empty')}
        />
      ) : (
        <>
          <Icon name="coef_up" className={`${styles.arrow} ${styles.up}`} />
          <EllipsisText className={styles.label}>
            {formattedCoefficient}
          </EllipsisText>
          <Icon name="coef_down" className={`${styles.arrow} ${styles.down}`} />
        </>
      )}
    </FlexBox>
  );
};

export default Coefficient;
