import merge from 'lodash/merge';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetCompetitionsWithSportsArgs } from '../../../types';
import { createCompetitionWhereIdInQuery } from '../../utils';
import { normalizePopularCompetitionsResponse } from '../adapters/popularCompetitionsAdapter';

const getCompetitionsWithSports = (api) => ({
  query: ({ competitions, favorite }: TGetCompetitionsWithSportsArgs) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          sport: ['id', 'alias', 'name', 'order'],
          region: ['id', 'alias', 'name', 'order'],
          competition: ['id', 'name', 'order', 'favorite_order'],
          game: '@count'
        },
        where: {
          ...(favorite && {
            competition: {
              favorite: true
            }
          }),
          ...createCompetitionWhereIdInQuery(competitions)
        },
        subscribe: true
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.POPULAR_COMPETITIONS],
  transformResponse: normalizePopularCompetitionsResponse,
  async onCacheEntryAdded(
    args: any,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => merge(draft, newData));
        },
        () =>
          dispatch(
            api.util.invalidateTags([SPORTS_QUERY_KEYS.POPULAR_COMPETITIONS])
          )
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
});
export default getCompetitionsWithSports;
