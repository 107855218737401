import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

import { TSignUpDataState } from '../types/signUpData';
import { TSignUpData } from '../types/userLocal';

const initialState = {
  signUpData: {}
};

export const userSignUp = createSlice<
  TSignUpDataState,
  SliceCaseReducers<TSignUpDataState>
>({
  name: 'userSignUp',
  initialState,
  reducers: {
    resetSignUpData: (state) => {
      // eslint-disable-next-line no-param-reassign
      state = initialState;
      return state;
    },
    setSignUpData: (state, { payload }: TPayload<TSignUpData>) => {
      // eslint-disable-next-line no-param-reassign
      state.signUpData = payload;
      return state;
    }
  }
});

export const { actions } = userSignUp;
