import { Context, createContext } from 'react';

type LikesListContext = {
  list: string[];
};

export const initialState: LikesListContext = {
  list: []
};

const LikesContext: Context<any> = createContext(initialState);

export default LikesContext;
