import React from 'react';

import { Component } from '@monorepo/type';

import styles from './index.module.css';

type Props = {
  url: string;
  setResult?: () => void;
};

const PaymentIFrame: Component<Props> = ({ url, className = '' }) => (
  <iframe
    title="paymentModalIframe"
    src={url}
    className={`${styles.frame} ${className}`}
  />
);

export default PaymentIFrame;
