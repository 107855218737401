import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useGeoIP } from '@monorepo/cms';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetLobbyGamesQuery } from '../../store/casino';
import { TGetGamesRequest } from '../../types';

const useGetLobbyGames = (params: Partial<TGetGamesRequest>, options = {}) => {
  const { isDesktopDevice, isIOSOld } = useDeviceDetector();
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();
  const { categoryId, ...rest } = params;
  const { data } = useGeoIP();
  return useGetLobbyGamesQuery(
    {
      partnerId: siteId,
      isMobile: isDesktopDevice ? 0 : 1,
      useWebp: isIOSOld ? 0 : 1,
      lang: languageLongCode,
      country: data?.countryCode,
      categoryId,
      ...rest
    },
    options
  );
};

export default useGetLobbyGames;
