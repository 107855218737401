import ChatsCenterEmpty from '../components/ChatsCenterEmpty';
import ChatsCommunity from '../components/ChatsCommunity';
import ChatsCommunityList from '../components/ChatsCommunityList';
import ChatsNavMenu from '../components/ChatsNavMenu';
import ChatsPage from '../components/ChatsPage';
import ChatsProfile from '../components/ChatsProfile';
import ChatsRightEmpty from '../components/ChatsRightEmpty';
import ChatsTopic from '../components/ChatsTopic';
import ChatsTopicInfo from '../components/ChatsTopicInfo';
import ChatsUserInfo from '../components/ChatsUserInfo';
import ChatsUserList from '../components/ChatsUserList';
import FriendsMenu from '../components/FriendsMenu';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.FRIENDS_MENU]: FriendsMenu,
  [COMPONENT_TYPES.CHATS_MESSENGER]: ChatsPage,
  [COMPONENT_TYPES.CHATS_USER_LIST]: ChatsUserList,
  [COMPONENT_TYPES.CHATS_COMMUNITY_LIST]: ChatsCommunityList,
  [COMPONENT_TYPES.CHATS_USER_INFO]: ChatsUserInfo,
  [COMPONENT_TYPES.CHATS_NAV_MENU]: ChatsNavMenu,
  [COMPONENT_TYPES.CHATS_RIGHT_EMPTY]: ChatsRightEmpty,
  [COMPONENT_TYPES.CHATS_CENTER_EMPTY]: ChatsCenterEmpty,
  [COMPONENT_TYPES.CHATS_TOPIC]: ChatsTopic,
  [COMPONENT_TYPES.CHATS_PROFILE]: ChatsProfile,
  [COMPONENT_TYPES.CHATS_COMMUNITY]: ChatsCommunity,
  [COMPONENT_TYPES.CHATS_TOPIC_INFO]: ChatsTopicInfo
};

export default COMPONENTS_MAP;
