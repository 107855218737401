import SportsSuggestedItem from '../../components/SportsPopularSlider/components/SportsSuggestedItem';
import SportsRecommendedItem from '../SportsRecommendedItem';

export enum SportsSliderItemType {
  Suggested = 'Suggested',
  Recommended = 'Recommended'
}

export type SportSliderItem =
  | typeof SportsSuggestedItem
  | typeof SportsRecommendedItem;
