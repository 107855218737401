import React, { useMemo } from 'react';

import { useGetFavoriteIdsData } from '@monorepo/cms';
import {
  EmptyState,
  FAVORITE_TYPE,
  LikesProvider,
  RemoveAllButton,
  useScrollToTop,
  useUpdateFavoritesIds
} from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import { Box, PaddingWrapper } from '@monorepo/ui-kit';

import VideoBlock from '../../common/VideoBlock';
import useGetSlotsById from '../../hooks/useGetSlotsById';
import { TPromotionsListConfig } from '../../types';

import styles from './index.module.css';

const VideoFavorite: ComponentWithConfig<TPromotionsListConfig> = ({
  config
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);

  const { withShare, withFavorite, withLike, withComment } = config;
  const favoritePromotion = useGetFavoriteIdsData(FAVORITE_TYPE.VIDEO);

  const { data: favoriteVideos } = useGetSlotsById({ id: favoritePromotion });

  const likeIds = useMemo(
    () => favoritePromotion?.map((id) => `${id}`) || [],
    [favoritePromotion]
  );

  const { onRemoveAllFavIds } = useUpdateFavoritesIds();

  const handleClick = () => onRemoveAllFavIds(FAVORITE_TYPE.VIDEO);
  useScrollToTop();

  return (
    <LikesProvider list={likeIds as string[]}>
      <PaddingWrapper isOnlyMobile className={styles.wrapper}>
        <Box>
          {favoritePromotion?.length ? (
            <>
              <RemoveAllButton onClick={handleClick} icon="bin_0" />
              <VideoBlock
                list={favoriteVideos?.result}
                withLike={withLike}
                withComment={withComment}
                withFavorite={withFavorite}
                withShare={withShare}
              />
            </>
          ) : (
            <EmptyState
              icon="empty_favorites-0"
              title={t('no.items')}
              subtitle={t('add.promotion')}
              iconClassName={styles.emptyIcon}
              titleClassName={styles.emptyTitle}
              subtitleClassName={styles.emptySubtitle}
              className={styles.noGames}
            />
          )}
        </Box>
      </PaddingWrapper>
    </LikesProvider>
  );
};

export default VideoFavorite;
