import { matchPath } from 'react-router-dom';

import { TPages } from '../types';

const getMatchedPathFromBatch = (batch: TPages, path: string) => {
  const pathArray = Object.keys(batch).sort((a, b) => {
    const aArr = a.split('/');
    const bArr = b.split('/');
    const isAllWithoutVar = a.indexOf(':') === -1 && b.indexOf(':') === -1;
    const isAllWithVar = a.indexOf(':') !== -1 && b.indexOf(':') !== -1;

    if (isAllWithoutVar || isAllWithVar) {
      if (aArr.length === bArr.length) {
        return 0;
      }
      return aArr.length > bArr.length ? 1 : -1;
    }

    return a.indexOf(':') !== -1 ? 1 : -1;
  });

  const matchedPath = pathArray.find((singlePath) =>
    matchPath(singlePath, path)
  );

  return matchedPath;
};

export default getMatchedPathFromBatch;
