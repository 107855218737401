import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import {
  Carousel,
  PaddingWrapper,
  Skeleton,
  SliderShadows
} from '@monorepo/ui-kit';

import HeaderSkeleton from '../../../../common/SuggestedSliderHeader/HeaderSkeleton';

import styles from '../../index.module.css';

const SportsSuggestedSkeleton = ({
  isWithButtons = false,
  isViewAll = true,
  limit = 10,
  rows = 1
}) => {
  const slides = createSkeletonArray(limit).map((i) => ({
    id: i,
    component: () => <Skeleton className={styles.item} />
  }));

  return (
    <div className={styles.wrapper}>
      <PaddingWrapper isOnlyMobile className={styles.header}>
        <HeaderSkeleton isViewAll={isViewAll} className={styles.header} />
      </PaddingWrapper>
      <SliderShadows className={styles.container} isWithNext={isWithButtons}>
        <Carousel
          slides={slides}
          className={styles.wrap}
          slideClassName={styles.item}
          useSwiper
          params={{
            grid: {
              rows
            },
            slidesPerView: 1,
            spaceBetween: 12,
            autoplay: {
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
              delay: 6000
            },
            breakpoints: {
              440: {
                slidesPerView: 'auto',
                spaceBetween: 12
              },
              1336: {
                spaceBetween: 12,
                slidesPerView: 'auto',
                pagination: false
              }
            },
            freeMode: {
              enabled: true,
              momentumBounce: false,
              momentumRatio: 0.2,
              minimumVelocity: 0.01,
              momentum: true
            },
            lazy: true
          }}
        />
      </SliderShadows>
    </div>
  );
};

export default SportsSuggestedSkeleton;
