import React from 'react';
import { memoize } from 'proxy-memoize';

import { useSiteSettings } from '@monorepo/site-settings';
import { Component, TQueryResult } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import { SCORE_KEYS, SCORE_TYPES } from '../../constants';
import useGetLineGames from '../../hooks/useGetLineGames';
import { selectGameBySportIdRegionIdCompetitionIdGameId } from '../../store/api/selectors';
import { TNormalizedSports } from '../../types';
import SportColor from '../SportColor';
import SportIcon from '../SportIcon';

import styles from './index.module.css';

type Props = {
  gameId: number;
  competitionId: number;
  sportId: number;
  regionId: number;
  scoreKey: string;
  showType: string;
};

const GameScore: Component<Props> = ({
  sportId,
  regionId,
  competitionId,
  gameId,
  scoreKey,
  showType,
  className = ''
}) => {
  const { data: game } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      data: selectGameBySportIdRegionIdCompetitionIdGameId(
        result,
        sportId,
        regionId,
        competitionId,
        gameId,
        ['info', 'stats', 'sport_alias']
      )
    }))
  });
  const { servingSportAliasRejected } = useSiteSettings();

  const isOutright = showType === SCORE_TYPES.OUTRIGHT;

  const serving =
    (scoreKey === SCORE_KEYS.SCORE_1 && game?.info?.passTeam === 'team1') ||
    (scoreKey === SCORE_KEYS.SCORE_2 && game?.info?.passTeam === 'team2');

  if (!game?.info || isOutright) {
    return null;
  }

  return (
    <>
      {serving && !servingSportAliasRejected.includes(game?.sportAlias) && (
        <SportColor alias={game?.sportAlias}>
          <Box>
            <SportIcon
              name={game?.sportAlias.toLowerCase()}
              className={styles.serve}
            />
          </Box>
        </SportColor>
      )}
      <span className={`${styles.points} ${className}`}>
        {game?.info[scoreKey] || 0}
      </span>
    </>
  );
};

export default GameScore;
