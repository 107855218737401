import {
  TPoolMetaDataDataResponse,
  TSubscribeToJackpotData
} from '../../types';

const getCombinedJackpotData = (
  subscribeValue: TSubscribeToJackpotData,
  metaArray: TPoolMetaDataDataResponse[] = []
) => {
  const { PoolGroup } = subscribeValue || {};
  const { PoolList = [] } = PoolGroup || {};
  const fullData = PoolList.map((item) => {
    const { Id } = item;
    const includedItem = metaArray.find((meta) => meta.Id === Id);
    if (includedItem) {
      return { ...item, ...includedItem };
    }
    return item;
  });

  return {
    ...subscribeValue,
    poolGroup: {
      poolList: fullData
    }
  };
};

export default getCombinedJackpotData;
