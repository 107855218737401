import React from 'react';

import { Icon } from '@monorepo/icons';
import { classNameProp, CustomFC } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  TShutterHandler
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = Partial<TShutterHandler> & classNameProp;

const CloseButton: CustomFC<Props> = ({
  setIsShow = () => {},
  className = ''
}) => (
  <FlexBox
    justify={FLEX_JUSTIFY.end}
    align={FLEX_ALIGN.center}
    className={styles.wrapper}
  >
    <Button
      className={className}
      intent={BUTTON_INTENT.transparent}
      isOnlyIcon
      onClick={() => setIsShow(false)}
    >
      <Icon name="close_0" title="" />
    </Button>
  </FlexBox>
);

export default CloseButton;
