import { useContext } from 'react';
import isEmpty from 'lodash/isEmpty';

import LineParametersContext from '../../store/LineParametersContext';
import { TLineParameters } from '../../types';

const useLineParameters = (): TLineParameters => {
  const context = useContext(LineParametersContext);

  // prevent destructuring error if we using this hook outside of LineParametersProvider
  if (!isEmpty(context)) {
    const [lineParameters, setParams] = context;
    return { ...lineParameters, setParams };
  }

  return {} as TLineParameters;
};

export default useLineParameters;
