import React, { forwardRef } from 'react';

import { SpanProps } from '../../types';

import styles from './index.module.css';

const EllipsisText = forwardRef<HTMLSpanElement, SpanProps>(
  ({ children, className = '', ...props }, ref) => (
    <span className={`${styles.ellipsis} ${className}`} {...props} ref={ref}>
      {children}
    </span>
  )
);
export default EllipsisText;
