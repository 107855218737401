import { useCallback } from 'react';

import { AVATAR_UPLOAD_SIZES, FILE_SOURCE } from '../../constants';
import { useUploadFileMutation } from '../../store/mediaStorageApi';

const useAvatarUpload = () => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const upload = useCallback(
    (file: File) =>
      uploadFile({
        file,
        source: FILE_SOURCE.AVATAR,
        sizes: AVATAR_UPLOAD_SIZES
      }),
    [uploadFile]
  );

  return { upload, isLoading };
};

export default useAvatarUpload;
