const addBlog = () => ({
  query: ({
    title,
    description,
    body,
    linkToVideo,
    linkToPicture,
    linkToEvent,
    startTime,
    endTime,
    tags,
    type
  }: any) => ({
    url: `/post`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {
      type,
      title,
      description,
      body,
      link_to_video: linkToVideo,
      link_to_picture: linkToPicture,
      link_to_event: linkToEvent,
      start_time: startTime,
      end_time: endTime,
      tags: [tags]
    }
  })
});

export default addBlog;
