import React, {
  memo,
  MutableRefObject,
  useMemo,
  useRef,
  useState
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import uniq from 'lodash/uniq';

import { useSetTimeout } from '@monorepo/helpers';
import { Component } from '@monorepo/type';

import useCategories from '../../hooks/useCategories';
import { TCategories } from '../../types';
import GamesSlider from '../GamesSlider';
import GamesSliderSkeleton from '../GamesSlider/GamesSliderSkeleton';

type Props = {
  limit?: number;
  isWithButtons?: boolean;
  enableFavorite?: boolean;
  selectedIds?: number[];
  withTutorials?: boolean;
};

type Context = {
  isWithButtons: boolean;
  enableFavorite: boolean;
  withTutorials: boolean;
  limit: number;
  handleLoaded: (id: string) => void;
  loadedRef: MutableRefObject<Array<string>>;
};

type TItem = {
  category: TCategories;
} & Context;

const MemoizedItem = memo(
  ({
    category,
    isWithButtons,
    enableFavorite,
    withTutorials,
    limit,
    loadedRef,
    handleLoaded
  }: TItem) => {
    const [loaded, setLoaded] = useState<boolean>(
      loadedRef.current.includes(category.id)
    );

    useSetTimeout(() => setLoaded(true), loaded ? null : 500);

    if (!loaded) {
      return (
        <GamesSliderSkeleton
          isWithButtons={isWithButtons}
          rows={1}
          limit={limit}
        />
      );
    }

    return (
      <GamesSlider
        key={category.id}
        isWithButtons={isWithButtons}
        categoryName={category.title}
        categoryId={category.id}
        limit={limit}
        withTutorials={withTutorials}
        enableFavorite={enableFavorite}
        handleLoaded={handleLoaded}
      />
    );
  }
);

const itemContent = (i: number, category: TCategories, context: Context) => (
  <MemoizedItem category={category} {...context} />
);
const VirtualizedCategoriesList: Component<Props> = ({
  limit = 10,
  isWithButtons = false,
  withTutorials = false,
  enableFavorite = true,
  selectedIds = []
}) => {
  const { data = [], isLoading } = useCategories();
  const loadedRef = useRef<Array<string>>([]);

  const categories = useMemo(
    () =>
      selectedIds.length
        ? data?.filter((category) => selectedIds.includes(Number(category.id)))
        : data,
    [data, selectedIds]
  );

  const handleLoaded = (id: string) => {
    loadedRef.current = uniq([...loadedRef.current, id]);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Virtuoso<TCategories, Context>
      data={categories}
      context={{
        withTutorials,
        enableFavorite,
        isWithButtons,
        limit,
        handleLoaded,
        loadedRef
      }}
      totalCount={data.length}
      overscan={50}
      useWindowScroll
      itemContent={itemContent}
      components={{
        ScrollSeekPlaceholder: () => (
          <GamesSliderSkeleton
            isWithButtons={isWithButtons}
            rows={1}
            limit={limit}
          />
        )
      }}
      scrollSeekConfiguration={{
        enter: (velocity: number) => Math.abs(velocity) > 5000,
        exit: (velocity: number) => Math.abs(velocity) < 30
      }}
    />
  );
};

export default VirtualizedCategoriesList;
