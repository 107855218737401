import React from 'react';
import { useController } from 'react-hook-form';
import { Mask } from 'react-text-mask';

import { FIELD_TONE, InputMask } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import Control from '../Control';

type MaskedProps<TFormValues> = {
  mask: Mask | ((value: string) => Mask);
  inputMode?:
    | 'text'
    | 'search'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  showMask?: boolean;
  maxLength?: number;
  dataCustom?: string;
} & TFormField<TFormValues>;

const MaskedField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label = '',
  placeholder = '',
  disabled = false,
  className = '',
  inputClassName = '',
  toneType = FIELD_TONE.dark,
  rules = {},
  mask,
  maxLength,
  inputMode = 'text',
  showMask = false,
  errorClassName = '',
  dataCustom = '',
  errorTranslationNS
}: MaskedProps<TFormValues>) => {
  const { field } = useController({ name, rules });
  const requiredLabel = Object.hasOwn(rules, 'required');
  return (
    <Control<TFormValues>
      id={id}
      name={name}
      label={label}
      className={className}
      errorClassName={errorClassName}
      toneType={toneType}
      errorTranslationNS={errorTranslationNS}
      requiredLabel={requiredLabel}
    >
      <InputMask
        inputMode={inputMode}
        className={inputClassName}
        mask={mask}
        placeholder={placeholder}
        inputClassName={inputClassName}
        name={field.name}
        onBlur={field.onBlur}
        value={field.value}
        onChange={field.onChange}
        disabled={disabled}
        toneType={toneType}
        showMask={showMask}
        maxLength={maxLength}
        data-custom={dataCustom}
      />
    </Control>
  );
};

export default MaskedField;
