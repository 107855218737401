import React from 'react';

import { ComponentWithConfig } from '@monorepo/type';

import LineParametersProvider from '../../common/LineParametersProvider';
import { MARKET_TYPES } from '../../constants';
import { TSportHomeConfig } from '../../types';

import PopularSlider from './components/PopularSlider';

const SportsSuggested: ComponentWithConfig<TSportHomeConfig> = ({ config }) => {
  const { showMoreButton, isWithButtons, isViewAll, title, limit } = config;

  return (
    <LineParametersProvider
      params={{ limit, isPopular: true, marketType: MARKET_TYPES.SINGLE }}
    >
      <PopularSlider
        title={title}
        showMoreButton={showMoreButton}
        isWithButtons={isWithButtons}
        isViewAll={isViewAll}
      />
    </LineParametersProvider>
  );
};

export default SportsSuggested;
