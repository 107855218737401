import { memoize } from 'proxy-memoize';

import { useSiteSettings } from '@monorepo/site-settings';

import { useGetSlotQuery } from '../../store/casinoWallApiStore';

const useGetSlotsById = (options = {}) => {
  const { siteId } = useSiteSettings();
  return useGetSlotQuery(
    {
      id: options.id,
      siteId
    },
    {
      ...options,
      selectFromResult: memoize(({ data, isFetching }) => ({
        isFetching,
        data: data || []
      }))
    }
  );
};

export default useGetSlotsById;
