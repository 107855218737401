import React from 'react';

import { ClipBoardCopy } from '@monorepo/common';
import { parseQueryString } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Box, FIELD_TONE, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

const TwoFactorMore = ({
  qrCodeOrigin,
  toneType = FIELD_TONE.light
}: {
  qrCodeOrigin: string;
  toneType?: FIELD_TONE;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const searchParam = parseQueryString(`?${qrCodeOrigin.split('?')[1]}`);
  const secret = searchParam.get('secret');

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.codeSection} ${styles[toneType]}`}
    >
      <Box>{t('two.factor.cant.scan')}</Box>
      <ol className={styles.list}>
        <li>
          <span>{t('two.factor.cant.scan.step1')}</span>
        </li>
        <li>
          <span>{t('two.factor.cant.scan.step2')}</span>
        </li>
        <li>
          <span>{t('two.factor.cant.scan.step3')}</span>
          <Box className={styles.code}>
            <ClipBoardCopy toCopy={secret}>
              {secret}
              <Icon name="copy_outline-2" className={styles.iconCopy} />
            </ClipBoardCopy>
          </Box>
        </li>
        <li>
          <span>{t('two.factor.cant.scan.step4')}</span>
        </li>
      </ol>
    </FlexBox>
  );
};

export default TwoFactorMore;
