import { useTypedSelector } from '@monorepo/redux';

import { notificationsLocalSlice } from '../../../store/notificationsLocalSlice';

const getNotificationsData = (state: any) =>
  state[notificationsLocalSlice.name];

const useSelectNotificationsData = (): number[] | null =>
  useTypedSelector(getNotificationsData);

export default useSelectNotificationsData;
