import { useGetStreamQuery } from '../../store/sportsbook';

const useGetStream = (
  videoId: Record<string, number>,
  providerId: number,
  type: string | null = null,
  withStream: boolean
) =>
  useGetStreamQuery(
    {
      video_id: videoId,
      provider: providerId,
      stream_type: type
    },
    { skip: !withStream }
  );

export default useGetStream;
