import { modifyAffiliationList } from '@monorepo/xmpp';

import {
  MUC_ROOM_AFFILIATIONS_LIST,
  SOCIALS_QUERY_TAG
} from '../../../constants';
import { TRoomSetAffiliationListRequest } from '../../../types';
import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const setAffiliationList = () => ({
  query: ({ roomName, server, items }: TRoomSetAffiliationListRequest) =>
    modifyAffiliationList(roomName, items, server),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError,
  invalidatesTags: (
    result: any | undefined,
    error: unknown,
    { roomName }: TRoomSetAffiliationListRequest
  ) => [
    {
      type: SOCIALS_QUERY_TAG.MUC_ROOM_AFFILIATIONS,
      id: `${MUC_ROOM_AFFILIATIONS_LIST}-${roomName}`
    }
  ]
});

export default setAffiliationList;
