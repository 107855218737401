import { AUTH_QUERY_KEYS } from '../../../constants';
import { TUploadImageRequest } from '../../../types';

const setDocuments = () => ({
  query: ({ imageData, name, imageType }: TUploadImageRequest) => ({
    body: {
      command: 'upload_image',
      params: {
        image_data: imageData,
        name,
        image_type: imageType
      }
    }
  }),
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },
  invalidatesTags: [AUTH_QUERY_KEYS.DOCUMENTS]
});
export default setDocuments;
