import React, { RefObject, useRef } from 'react';

import {
  Form,
  InputField,
  maxLengthValidator,
  minLengthValidator,
  numbersRegExpValidator,
  requiredValidator,
  SubmitButton
} from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ModalWrapper } from '@monorepo/notification/src';
import { CustomFC } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  FIELD_TONE,
  FLEX_ALIGN,
  FlexBox,
  Label
} from '@monorepo/ui-kit';

import GetCodeButton from './GetCodeButton';

import styles from './index.module.css';

type TProps = {
  phoneNumber: string;
  onSubmit: () => void;
  modalRef: RefObject<any>;
  actionType: 1 | 2 | 3;
  resendPeriod?: number;
};

const formSettings = {
  defaultValues: {
    confirmationCode: undefined
  },
  mode: 'onChange'
};

const SmsVerificationModal: CustomFC<TProps> = ({
  phoneNumber,
  onSubmit,
  actionType,
  resendPeriod,
  modalRef
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const formContextRef = useRef(null);

  const onClose = () => {
    modalRef.current.setIsShow(false);
  };

  const onFormSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <ModalWrapper
      classOverlayModal={styles.modalSMS}
      header={t('smsverification.title')}
      ref={modalRef}
    >
      <Label
        toneType={FIELD_TONE.light}
        htmlFor="confirmationCode"
        className={styles.smsVerification}
      >
        {t('smsverification.phone', { phone: phoneNumber })}
      </Label>
      <Form
        onSubmit={onFormSubmit}
        formSettings={formSettings}
        ref={formContextRef}
      >
        <FlexBox align={FLEX_ALIGN.end}>
          <InputField
            id="confirmationCode"
            name="confirmationCode"
            label={t('smsverification.form.confirmationcode.label')}
            placeholder={t('smsverification.form.confirmationcode.label')}
            rules={{
              ...requiredValidator(),
              ...numbersRegExpValidator(),
              ...minLengthValidator(4),
              ...maxLengthValidator(4)
            }}
            toneType={FIELD_TONE.light}
            className={styles.codeInput}
          />
          <GetCodeButton
            actionType={actionType}
            phoneNumber={phoneNumber}
            resendPeriod={resendPeriod}
          />
        </FlexBox>
        <SubmitButton>{t('smsverification.submit')}</SubmitButton>
        <Button
          onClick={onClose}
          intent={BUTTON_INTENT.transparent}
          toneType={FIELD_TONE.light}
        >
          {t('smsverification.close')}
        </Button>
      </Form>
    </ModalWrapper>
  );
};

export default SmsVerificationModal;
