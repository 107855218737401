import { TPaymentActionField } from '../types';

const convertFormToUrl = (action: string, formData?: TPaymentActionField[]) => {
  if (!formData) {
    return '';
  }
  const params = formData.reduce<Record<string, string>>(
    (acc, { name, value }) => {
      acc[name] = value;
      return acc;
    },
    {}
  );
  const query = new URLSearchParams(params).toString();
  return `${action}${
    query.length > 0 && action[action.length - 1] !== '?' ? '?' : ''
  }${query}`;
};

export default convertFormToUrl;
