/* eslint-disable prefer-object-spread,no-param-reassign */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';

import { TPayload } from '@monorepo/type';
import { SUBSCRIPTION_TYPE } from '@monorepo/xmpp';

import { MAM_QUERY_STATUS } from '../constants';
import { MESSAGE_DIRECTION } from '../constants/messages';
import {
  TInboxConversationMessage,
  TRosterItem,
  TSocialState,
  TStoreInboxMessage,
  TStoreMamQuery,
  TStoreMessage,
  TStoreNotification,
  TUpdateInbox,
  TUpdateRosterItem
} from '../types';
import {
  addNotification,
  createFriendRequestNotification,
  createFriendRequestToastId,
  removeNotification
} from '../utils/notifications';

const initialState = {
  activeRoom: null,
  messages: {},
  roomsMessages: {},
  mamQueries: {},
  mamQueriesMessages: {},
  mamMessages: {},
  roster: null,
  user: {},
  subscribeRequests: [],
  notifications: [],
  inbox: [],
  readInbox: []
};

const updateInbox = (inbox: TSocialState['inbox'], payload: TUpdateInbox) => {
  if (!inbox.length) {
    inbox = [{ unread: '1', ...payload }];
  } else {
    const index = findIndex(
      inbox,
      ({ roomJid }) => roomJid === payload.roomJid
    );
    if (index < 0) {
      inbox.push({ unread: '1', ...payload });
    } else if (payload.unread === '0') {
      Object.assign(inbox[index], payload);
    } else {
      const { unread = '0' } = inbox[index];
      Object.assign(inbox[index], payload, {
        unread: String(Number(unread) + 1)
      });
    }
  }
};

export const socialsSlice = createSlice<
  TSocialState,
  SliceCaseReducers<TSocialState>
>({
  name: 'socials',
  initialState,
  reducers: {
    resetSliceState: (state) => {
      state = initialState;
      return state;
    },
    enterRoom: (state, { payload }: TPayload<string>) => {
      state.activeRoom = payload;
      if (!state.roomsMessages[payload]) {
        state.roomsMessages[payload] = [];
      }

      return state;
    },
    exitRoom: (state, { payload }: TPayload<string>) => {
      const { activeRoom } = state;

      if (state.roomsMessages[payload]) {
        state.roomsMessages[payload] = [];
      }
      if (state.mamQueries[payload]) {
        delete state.mamQueries[payload];
      }
      if (state.mamMessages[payload]) {
        delete state.mamMessages[payload];
      }
      if (activeRoom === payload) {
        state.activeRoom = null;
      }
      return state;
    },
    addMessage: (state, { payload }: TPayload<TStoreMessage>) => {
      const { roomJid, id, queryId, direction } = payload;
      if (direction === MESSAGE_DIRECTION.SYSTEM) {
        // todo temporary remove system messages
        return state;
      }
      if (!state.messages[id]) {
        state.messages = Object.assign({}, state.messages, {
          [id]: payload
        });
      }
      if (!state.roomsMessages[roomJid]) {
        state.roomsMessages[roomJid] = [];
      }
      if (queryId) {
        const messages = state.mamQueriesMessages[queryId] || [];
        if (!messages.includes(id)) {
          state.mamQueriesMessages = Object.assign(
            {},
            state.mamQueriesMessages,
            {
              [queryId]: [...messages, id]
            }
          );
        }
      } else {
        const messages = state.roomsMessages[roomJid] || [];
        if (!messages.includes(id)) {
          state.roomsMessages[roomJid] = [...messages, id];
        }
        if (state.activeRoom !== roomJid) {
          updateInbox(state.inbox, payload);
        }
      }

      return state;
    },
    setMamQuery: (state, { payload }: TPayload<TStoreMamQuery>) => {
      const { withJid } = payload;

      const stateQuery = state.mamQueries[withJid] || {};

      state.mamQueries = Object.assign({}, state.mamQueries, {
        [withJid]: Object.assign({}, stateQuery, payload)
      });

      return state;
    },
    updateMamQuery: (state, { payload }: TPayload<Partial<TStoreMamQuery>>) => {
      const { id, count = 0 } = payload;

      const stateQuery = Object.values(state.mamQueries).find(
        (query) => query.id === id
      );
      if (stateQuery) {
        const { firstIndex = count, max } = stateQuery;
        const index = firstIndex - max < 0 ? 0 : firstIndex - max;
        const updatedQuery = Object.assign({}, stateQuery, payload, {
          firstIndex: index
        });
        const { status, queryId, withJid } = updatedQuery;

        if (withJid) {
          state.mamQueries = Object.assign({}, state.mamQueries, {
            [withJid]: updatedQuery
          });
          if (
            queryId &&
            (status === MAM_QUERY_STATUS.COMPLETE ||
              status === MAM_QUERY_STATUS.LOADED)
          ) {
            if (state.mamMessages[withJid]) {
              state.mamMessages[withJid] = [
                ...(state.mamQueriesMessages[queryId] || []),
                ...(state.mamMessages[withJid] || [])
              ];
            } else {
              state.mamMessages[withJid] =
                state.mamQueriesMessages[queryId] || [];
            }
            delete state.mamQueriesMessages[queryId];
          }
        }
      }

      return state;
    },
    updateMessage: (state, { payload }: TPayload<Partial<TStoreMessage>>) => {
      if (payload.id && state.messages[payload.id]) {
        state.messages[payload.id] = Object.assign(
          {},
          state.messages[payload.id],
          payload
        );
      }
      return state;
    },
    setRoster: (state, { payload }: TPayload<TRosterItem[]>) => {
      state.roster = payload.reduce((acc, item) => {
        const stateItem = state.roster?.[item.id] || {};
        const mergedItem = Object.assign({}, stateItem, item);

        return Object.assign({}, acc, {
          [item.id]: mergedItem
        });
      }, state.roster || {});

      return state;
    },
    updateRoster: (state, { payload }: TPayload<TRosterItem[]>) => {
      state.roster = payload.reduce((acc, item) => {
        const stateItem = state.roster?.[item.id] || {};
        const mergedItem = Object.assign({}, stateItem, item);

        if (
          mergedItem.subscription === SUBSCRIPTION_TYPE.TO ||
          mergedItem.subscription === SUBSCRIPTION_TYPE.BOTH
        ) {
          state.subscribeRequests = state.subscribeRequests.filter(
            (request) => request !== mergedItem.jid.toString()
          );
          state.notifications = removeNotification(
            state.notifications,
            createFriendRequestToastId(item.jid)
          );
        }
        return Object.assign({}, acc, {
          [item.id]: mergedItem
        });
      }, state.roster);
      return state;
    },
    updateRosterItem: (
      state,
      { payload }: TPayload<TUpdateRosterItem | TUpdateRosterItem[]>
    ) => {
      const updateItem = (item: TUpdateRosterItem) => {
        const rosterItem = state.roster?.[item.id] || {};
        state.roster = Object.assign({}, state.roster, {
          [item.id]: Object.assign({}, rosterItem, item)
        });
      };
      if (Array.isArray(payload)) {
        payload.forEach(updateItem);
      } else {
        updateItem(payload);
      }
      return state;
    },
    updateUser: (state, { payload }) => {
      state.user = Object.assign({}, state.user, payload);
      return state;
    },
    addSubscribeRequests: (state, { payload }: TPayload<string>) => {
      if (!state.subscribeRequests.includes(payload)) {
        state.subscribeRequests = [...state.subscribeRequests, payload];

        const { subscription } = state.roster?.[payload] || {};
        if (
          subscription !== SUBSCRIPTION_TYPE.TO &&
          subscription !== SUBSCRIPTION_TYPE.BOTH
        ) {
          state.notifications = addNotification(
            state.notifications,
            createFriendRequestNotification(payload)
          );
        }
      }
      return state;
    },
    removeSubscribeRequests: (state, { payload }: TPayload<string>) => {
      state.subscribeRequests = state.subscribeRequests.filter(
        (item) => item !== payload
      );
      state.notifications = removeNotification(
        state.notifications,
        createFriendRequestToastId(payload)
      );
      return state;
    },
    addNotifications: (state, { payload }: TPayload<TStoreNotification>) => {
      state.notifications = addNotification(state.notifications, payload);
      return state;
    },
    removeNotifications: (state, { payload }: TPayload<string>) => {
      state.notifications = removeNotification(state.notifications, payload);
      return state;
    },
    setInbox: (state, { payload }: TPayload<Array<TStoreInboxMessage>>) => {
      state.inbox = payload.filter(
        (item) => !state.readInbox.includes(item.roomJid)
      );
      state.readInbox = [];

      return state;
    },
    setInboxRead: (state, { payload }: TPayload<TInboxConversationMessage>) => {
      if (payload.unread === '0') {
        state.readInbox = [...state.readInbox, payload.roomJid];
      }
      updateInbox(state.inbox, payload);

      return state;
    },
    updateInbox: (state, { payload }: TPayload<TUpdateInbox>) => {
      updateInbox(state.inbox, payload);

      return state;
    }
  }
});

export const { actions } = socialsSlice;

export default socialsSlice.reducer;
