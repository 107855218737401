import { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useAnimationFrame } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';

import useSelectPlayingTime from '../selectors/useSelectPlayingTime';
import useIsAuthenticated from '../useIsAuthenticated';
import useLocalActions from '../useLocalActions';
import useLogoutFreeze from '../useLogoutFreeze';

const PING_PERIOD = 1;

const usePlayingFreeze = () => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useIsAuthenticated();
  const playingTime = useSelectPlayingTime();
  const { setPlayingTime } = useLocalActions();
  const { realityCheckTime, playingTimeRoutesList } = useSiteSettings();
  const shouldCountPlayingTime = useMemo(
    () =>
      playingTimeRoutesList.find((path) =>
        matchPath({ path, end: true }, pathname)
      ),
    [pathname, playingTimeRoutesList.toString()]
  );

  const { onLogoutFreeze } = useLogoutFreeze();

  const handleFreeze = useCallback(() => {
    const value = playingTime + PING_PERIOD;
    if (value <= realityCheckTime) {
      setPlayingTime(value);
    } else {
      onLogoutFreeze();
    }
  }, [onLogoutFreeze]);

  useAnimationFrame({
    callback: handleFreeze,
    delay: PING_PERIOD * 1000,
    shouldAnimate: Boolean(shouldCountPlayingTime && isAuthenticated)
  });
};

export default usePlayingFreeze;
