import BackHeader from '../components/BackHeader';
import BackHeaderAccount from '../components/BackHeaderAccount';
import BackHeaderLogin from '../components/BackHeaderLogin';
import BackHeaderProfile from '../components/BackHeaderProfile';
import BackHeaderTitle from '../components/BackHeaderTitle';
import BackHeaderVideo from '../components/BackHeaderVideo';
import Banner from '../components/Banner';
import BannersCms from '../components/BannersCms';
import BannersCmsWide from '../components/BannersCmsWide';
import BurgerMenu from '../components/BurgerMenu';
import CasinoBannerCms from '../components/CasinoBannerCms';
import ComingSoon from '../components/ComingSoon';
import Footer from '../components/Footer';
import HeaderDeposit from '../components/HeaderDeposit';
import HeaderExchangeWidget from '../components/HeaderExchangeWidget';
import HeaderMobileNavMenu from '../components/HeaderMobileNavMenu';
import HeaderMobLink from '../components/HeaderMobLink';
import HeaderNavMenu from '../components/HeaderNavMenu';
import HeaderPromo from '../components/HeaderPromo';
import HeaderSearch from '../components/HeaderSearch';
import HeaderWall from '../components/HeaderWall';
import LanguageSwitcher from '../components/LanguageSwitcher';
import Logo from '../components/Logo';
import Page404 from '../components/Page404';
import PromoBlock from '../components/PromoBlock';
import RedirectComponent from '../components/RedirectComponent';
import SearchForm from '../components/SearchForm';
import TabMenuCarousel from '../components/TabMenuCarousel';
import TabsMenu from '../components/TabsMenu';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.BANNER]: Banner,
  [COMPONENT_TYPES.PAGE_404]: Page404,
  [COMPONENT_TYPES.HEADER_NAV_MENU]: HeaderNavMenu,
  [COMPONENT_TYPES.HEADER_MOBILE_NAV_MENU]: HeaderMobileNavMenu,
  [COMPONENT_TYPES.BACK_HEADER]: BackHeader,
  [COMPONENT_TYPES.BACK_HEADER_LOGIN]: BackHeaderLogin,
  [COMPONENT_TYPES.BACK_HEADER_ACCOUNT]: BackHeaderAccount,
  [COMPONENT_TYPES.BACK_HEADER_PROFILE]: BackHeaderProfile,
  [COMPONENT_TYPES.BACK_HEADER_VIDEO]: BackHeaderVideo,
  [COMPONENT_TYPES.BACK_HEADER_TITLE]: BackHeaderTitle,
  [COMPONENT_TYPES.HEADER_WALL]: HeaderWall,
  [COMPONENT_TYPES.BANNERS_CMS]: BannersCms,
  [COMPONENT_TYPES.CASINO_BANNERS_CMS]: CasinoBannerCms,
  [COMPONENT_TYPES.BANNERS_CMS_WIDE]: BannersCmsWide,
  [COMPONENT_TYPES.REDIRECT]: RedirectComponent,
  [COMPONENT_TYPES.HEADER_DEPOSIT]: HeaderDeposit,
  [COMPONENT_TYPES.HEADER_PROMO]: HeaderPromo,
  [COMPONENT_TYPES.PROMO_BLOCK]: PromoBlock,
  [COMPONENT_TYPES.HEADER_SEARCH]: HeaderSearch,
  [COMPONENT_TYPES.BURGER_MENU]: BurgerMenu,
  [COMPONENT_TYPES.LANGUAGE_SWITCHER]: LanguageSwitcher,
  [COMPONENT_TYPES.TABS_MENU]: TabsMenu,
  [COMPONENT_TYPES.TAB_MENU_CAROUSEL]: TabMenuCarousel,
  [COMPONENT_TYPES.HEADER_MOB_LINK]: HeaderMobLink,
  [COMPONENT_TYPES.FOOTER]: Footer,
  [COMPONENT_TYPES.COMING_SOON]: ComingSoon,
  [COMPONENT_TYPES.SEARCH_FORM]: SearchForm,
  [COMPONENT_TYPES.LOGO]: Logo,
  [COMPONENT_TYPES.HEADER_EXCHANGE_WIDGET]: HeaderExchangeWidget
};

export default COMPONENTS_MAP;
