import React from 'react';

import { Component } from '@monorepo/type';

import SportsBookButtonSkeleton from '../SportsBookButton/SportsBookButtonSkeleton';

import styles from './index.module.css';

const ShowMoreButtonSkeleton: Component = ({ className = '' }) => (
  <SportsBookButtonSkeleton className={`${styles.wrap} ${className}`} />
);

export default ShowMoreButtonSkeleton;
