import React, { ReactNode } from 'react';

import { Icon } from '@monorepo/icons';

import { FLEX_ALIGN, FLEX_DIRECTION, FLEX_JUSTIFY } from '../../constants';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

const ModalWrap = ({
  header,
  handleClose,
  headerClassName = '',
  wrapClassName = '',
  titleClassName = '',
  children
}: {
  header?: string;
  headerClassName?: string;
  wrapClassName?: string;
  titleClassName?: string;
  handleClose?: () => void;
  children: ReactNode;
}) => {
  if (header || handleClose)
    return (
      <FlexBox
        className={`${styles.wrapper} ${wrapClassName}`}
        direction={FLEX_DIRECTION.column}
      >
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.between}
          className={`${styles.header} ${headerClassName}`}
        >
          {header && (
            <span className={`${styles.title} ${titleClassName}`}>
              {header}
            </span>
          )}
          {handleClose && (
            <Icon
              name="close_0"
              className={styles.icon}
              onClick={handleClose}
            />
          )}
        </FlexBox>
        {children}
      </FlexBox>
    );
  return null;
};
export default ModalWrap;
