import { AUTH_QUERY_KEYS } from '../../../constants';

const getJWT = () => ({
  query: () => ({
    body: {
      command: 'get_jwe_info',
      params: { token_type: 'JWT', alg: 'HS256' }
    }
  }),
  keepUnusedDataFor: 0,
  providesTags: [AUTH_QUERY_KEYS.JWT],
  transformResponse: (response: any): any => response.details.jwe
});
export default getJWT;
