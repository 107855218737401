import { useMemo } from 'react';

import { useGetPlayerProfileSettings } from '@monorepo/auth';
import { createBareJidFromId } from '@monorepo/xmpp';

import useSelectRosterItem from '../selectors/useSelectRosterItem';

const useChatUser = (id: string) => {
  const { data } = useGetPlayerProfileSettings(Number(id), {
    skip: !Number(id)
  });
  const { nickname = null, avatar_img: avatarImg = null } = data || {};
  const rosterItem = useSelectRosterItem(id);

  const isExist = useMemo(
    () => Boolean(Number(id) && data !== null),
    [data, id]
  );

  const displayName = useMemo(() => {
    if (!isExist) {
      return '';
    }
    const { name } = rosterItem || {};
    return name || nickname || id;
  }, [nickname, rosterItem, id, isExist]);

  const avatar = useMemo(() => avatarImg || '', [avatarImg, id]);

  const jid = useMemo(
    () => rosterItem?.jid || createBareJidFromId(id),
    [rosterItem, id]
  );

  return {
    id,
    jid,
    ...rosterItem,
    displayName,
    avatar,
    isExist
  };
};

export default useChatUser;
