import React from 'react';

import { Icon } from '@monorepo/icons';
import { classNameProp, CustomFC } from '@monorepo/type';
import { Box, FLEX_DIRECTION, FlexBox, PreloadImage } from '@monorepo/ui-kit';

import GamePlaceholder from '../../common/GamePlaceholder';

import styles from './index.module.css';

type TTileProps = {
  name: string;
  image: string;
  badge?: string;
  visual?: string;
} & classNameProp;

const GameTile: CustomFC<TTileProps> = ({
  name,
  image,
  className = '',
  badge = '',
  visual = 'sport',
  children
}) => (
  <div className={`${styles.root} ${className} ${styles[visual]}`}>
    <PreloadImage
      className={`${styles.background} ${styles[visual]}`}
      imgClassName={styles.img}
      src={image}
      alt={name}
    >
      <GamePlaceholder name={name} />
    </PreloadImage>
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.overlay}>
      {children}
    </FlexBox>
    <Box className={badge ? `${styles.badge} ${styles[`${badge}`]}` : ''} />
    {badge === 'jackpot' || badge === 'tournament' ? (
      <Icon name={badge} className={`${styles.icon}`} />
    ) : (
      <Box className={`${styles.badge_text} ${styles[`${badge}_text`]}`}>
        {badge}
      </Box>
    )}
  </div>
);
export default GameTile;
