import React from 'react';

import { CustomFC } from '@monorepo/type';

import { FlexBoxProps } from '../../types';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

type Props = {
  isWithNext?: boolean;
  isWithPrev?: boolean;
} & FlexBoxProps;

const SliderShadows: CustomFC<Props> = ({
  children,
  className = '',
  isWithNext = false,
  isWithPrev = false,
  ...props
}) => {
  const classes = `${styles.wrapper}
   ${isWithPrev ? styles.withPrev : ''}
   ${isWithNext ? styles.withNext : ''}
   ${className}`;

  return (
    <FlexBox className={classes} {...props}>
      {children}
    </FlexBox>
  );
};

export default SliderShadows;
