import { useTypedSelector } from '@monorepo/redux';

import { TStoreNotification } from '../../../types';

const getNotifications = (state: any) => state.socialsSlice.notifications;

const useSelectNotifications = (): TStoreNotification[] =>
  useTypedSelector(getNotifications);

export default useSelectNotifications;
