import { useEffect, useState } from 'react';

import { usePrevious, useSetTimeout } from '@monorepo/helpers';

const useNumberChange = (value: number | string, timeout: number = 3000) => {
  const prevValue = usePrevious<number>(Number(value));
  const [move, setMove] = useState<null | 'up' | 'down'>(null);

  const remove = () => setMove(null);

  useSetTimeout(remove, move ? timeout : null, [value]);

  useEffect(() => {
    if (prevValue && prevValue !== value) {
      const diff = Number(value) - prevValue;
      setMove(diff > 0 ? 'up' : 'down');
    }
  }, [value, timeout]);

  return move;
};

export default useNumberChange;
