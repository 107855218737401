import {
  TPoolMetaDataDataResponse,
  TPoolMetaDataResponse
} from '../../../../types';

const getJackpotPoolMetaDataAdapter = ({
  Data
}: TPoolMetaDataResponse): TPoolMetaDataDataResponse[] => Data;

export default getJackpotPoolMetaDataAdapter;
