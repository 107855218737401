import React, { useMemo } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  Box,
  FLEX_JUSTIFY,
  FlexBox,
  SlideMenu,
  TabsNavigation
} from '@monorepo/ui-kit';

import useGetPromotionsCategories from '../../hooks/useGetPromotionsCategories';
import { TPromotionCategory } from '../../types';

import styles from './index.module.css';

type Props = {
  isWithButtons?: boolean;
  baseLink: string;
};

const PromoCategoriesList: Component<Props> = ({
  isWithButtons = false,
  className = ''
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes } = useSiteSettings();
  const { promotion } = generalRoutes;
  const { data } = useGetPromotionsCategories();
  const items = useMemo(() => {
    const allItem = {
      title: t('promotions.categories.all'),
      link: `${promotion}/all`,
      id: 'all',
      iconName: 'all-0'
    };
    const otherItems = (data || []).map(
      ({ title, slug }: TPromotionCategory) => ({
        title,
        link: `${promotion}/${slug}`,
        id: slug,
        iconName: `${slug}-0`
      })
    );
    return [allItem, ...otherItems];
  }, [t, data]);

  return (
    <Box className={styles.wrapperSlider}>
      <FlexBox className={styles.promoTitle} justify={FLEX_JUSTIFY.center}>
        {t('promotions.title')}
      </FlexBox>
      <SlideMenu
        isWithButtons={isWithButtons}
        sliderClassName={styles.slider}
        className={className}
      >
        <TabsNavigation
          items={items}
          linkClassName={styles.link}
          activeClassName={styles.active}
          listClassName={styles.wrap}
          itemClassName={styles.item}
          isActiveFavorite
        />
      </SlideMenu>
    </Box>
  );
};

export default PromoCategoriesList;
