import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';

import styles from './index.module.css';

type TPillItem = {
  isExact?: boolean;
  link: string;
  title: string;
  replace?: boolean;
  active?: boolean;
  iconName?: string;
  activeClassName?: string;
  linkClassName?: string;
  iconClassName?: string;
  onlyIcon?: false;
};

const Pill: CustomFC<TPillItem> = ({
  isExact = false,
  link,
  title,
  replace = false,
  active = true,
  iconName = '',
  activeClassName = '',
  linkClassName = '',
  iconClassName = '',
  onlyIcon = false
}) => {
  const itemClass = `${styles.item} ${linkClassName}`;
  const activeClass = activeClassName ? `${activeClassName}` : styles.active;
  const iconClass = `${styles.icon} ${iconClassName}`;

  const isActiveCallback = useCallback(
    ({ isActive }) => `${itemClass} ${isActive && active ? activeClass : ''}`,
    [itemClass, activeClass, active]
  );

  return (
    <NavLink
      end={isExact}
      to={link}
      key={title}
      replace={replace}
      className={isActiveCallback}
    >
      {iconName && <Icon name={iconName} className={iconClass} />}
      {!onlyIcon && title}
    </NavLink>
  );
};

export default Pill;
