import React, { useCallback, useEffect, useState } from 'react';

import { CustomFC, TOptionItem } from '@monorepo/type';

import { FIELD_TONE } from '../../constants';
import Select from '../forms/Select';

import SelectDropdown from './components/SelectDropdown';

export type Props = {
  value?: string;
  options: TOptionItem[];
  wrapClassName?: string;
  itemClassName?: string;
  contentClassName?: string;
  toneType?: FIELD_TONE;
  onChange: (value: string) => void;
  isUseCustom?: boolean;
  selectedClassName?: string;
};

const SelectCustom: CustomFC<Props> = ({
  value = '',
  options = [],
  wrapClassName = '',
  itemClassName = '',
  contentClassName = '',
  selectedClassName = '',
  toneType = FIELD_TONE.dark,
  onChange,
  isUseCustom = false
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = useCallback(
    (val: string) => {
      setSelected(val);
      onChange(val);
    },
    [onChange, setSelected]
  );

  const nativeSelect = (e: React.ChangeEvent<HTMLSelectElement>) =>
    handleChange(e.target.value);

  return isUseCustom ? (
    <SelectDropdown
      value={selected}
      options={options}
      onChange={handleChange}
      className={wrapClassName}
      contentClassName={contentClassName}
      selectedClassName={selectedClassName}
      itemClassName={itemClassName}
      toneType={toneType}
    />
  ) : (
    <Select
      onChange={nativeSelect}
      options={options}
      value={selected}
      className={wrapClassName}
      toneType={toneType}
    />
  );
};
export default SelectCustom;
