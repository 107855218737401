import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useGetUser } from '@monorepo/auth';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetAvailableTournamentsQuery } from '../../store/casino';
import { useGetTournamentsQuery } from '../../store/tournaments';
import { TCasinoTournament } from '../../types';

const useGetTournaments = (stageList: number[]) => {
  const { isDesktopDevice } = useDeviceDetector();
  const { siteId } = useSiteSettings();
  const {
    data: userData,
    isFetching: isFetching1,
    isLoading: isLoading1
  } = useGetUser();
  const { id } = userData || {};

  const {
    data,
    isSuccess,
    isFetching: isFetching2,
    isLoading: isLoading2,
    isError,
    isUninitialized
  } = useGetTournamentsQuery(
    {
      stageList,
      id
    },
    {
      pollingInterval: 30000
    }
  );

  const availableTournamentsIds = data?.reduce(
    (acc, { id: tournamentId }) => [...acc, tournamentId],
    []
  );

  const {
    data: availableIds = [],
    isFetching: isFetching3,
    isLoading: isLoading3
  } = useGetAvailableTournamentsQuery(
    {
      partnerId: siteId,
      isMobile: isDesktopDevice ? 0 : 1,
      ids: availableTournamentsIds
    },
    { skip: !availableTournamentsIds?.length }
  );

  const availableTournaments = data?.filter((item) =>
    availableIds.includes(item.id)
  ) as TCasinoTournament[];

  const isFetching = isFetching1 || isFetching2 || isFetching3;
  const isLoading = isLoading1 || isLoading2 || isLoading3;

  return {
    data,
    availableTournaments,
    isFetching,
    isSuccess,
    isError,
    isUninitialized,
    isLoading
  };
};

export default useGetTournaments;
