import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isFunction from 'lodash/isFunction';

import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FIELD_TONE,
  FLEX_ALIGN,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  title?: string;
  backLink?: string | (() => void) | false;
  closeLink?: string | (() => void) | false;
  titleClassName?: string;
  tone?: FIELD_TONE;
};

const BackHeader: Component<Props> = ({
  className = '',
  titleClassName = '',
  title,
  backLink,
  closeLink,
  tone = FIELD_TONE.light,
  children
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes } = useSiteSettings();
  const { home } = generalRoutes;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    const redirectUrl = searchParams.get(SEARCH_STRING_PARAMS.redirectUrl);
    if (redirectUrl) {
      return navigate(decodeURIComponent(redirectUrl));
    }
    if (window.history.length === 1 && !backLink) {
      return navigate(home);
    }
    if (backLink) {
      return isFunction(backLink) ? backLink() : navigate(backLink);
    }
    return navigate(-1);
  };

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      className={`${styles.wrap} ${styles[tone]} ${className}`}
    >
      {(backLink || backLink === false) && (
        <Button
          isOnlyIcon
          intent={BUTTON_INTENT.tertiary}
          onClick={handleClick}
          className={styles.button}
        >
          <Icon
            name="close_1"
            className={styles.icon}
            title={t('backheader.back')}
          />
        </Button>
      )}

      <EllipsisText className={`${styles.title} ${titleClassName}`}>
        {title || t('backHeader.title')}
      </EllipsisText>
      {children}
      {(closeLink || closeLink === false) && (
        <Button
          isOnlyIcon
          intent={BUTTON_INTENT.tertiary}
          onClick={handleClick}
          className={styles.button}
        >
          <Icon
            name="close_0"
            className={styles.icon}
            title={t('backheader.back')}
          />
        </Button>
      )}
    </FlexBox>
  );
};

export default BackHeader;
