import { useGetJWTQuery } from '../../store/user';
import useAuthLogin from '../useAuthLoginV2';
import useIsAuthenticated from '../useIsAuthenticated';

const useAuthLoginSWARM = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const { data } = useGetJWTQuery(undefined, {
    skip: !isAuthenticated
  });

  useAuthLogin(data);
};

export default useAuthLoginSWARM;
