import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { UseCountry } from '../../../../../types';

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getCountries: builder.query<UseCountry[], void>({
      query: () => 'countries/countries.json'
    })
  })
});

export const { useGetCountriesQuery } = countriesApi;
