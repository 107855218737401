import React, { useState } from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { useCustomButton } from '@monorepo/common';
import { Icon } from '@monorepo/icons';
import { ComponentWithConfig } from '@monorepo/type';

import { transformCmsIcons, transformMenuItems } from '../../helpers';
import useGetMenu from '../../hooks/useGetMenu';
import { FooterNavMenuConfig } from '../../types';

import Item from './components/Item';
import MenuItem from './components/MenuItem';
import ParentItem from './components/ParentItem';

import styles from './index.module.css';

const FooterNavMenu: ComponentWithConfig<FooterNavMenuConfig> = ({
  config
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { menuId, menuClass } = config;
  const { isLoading, data: cmsMenuItems = [] } = useGetMenu(menuId);
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);

  // Add custom button to the menu
  const customButton = useCustomButton();
  const transformItems = transformMenuItems(cmsMenuItems, isAuthenticated);
  const parentItem = transformItems.find((element) => element.items?.length);
  const avgKey = isAuthenticated ? 4 : 5;
  // TODO: remove when custom button from CMS will be deleted

  if (isLoading) {
    return null;
  }
  // @ts-ignore
  return (
    <ul className={`${styles.list} ${isShowSubMenu ? styles.activeList : ''}`}>
      {transformItems
        ?.slice(0, avgKey)
        .map(({ title, link, iconName = '', disabled = false, id }) =>
          parentItem?.id === id ? (
            <ParentItem
              title={parentItem.title}
              link={parentItem.link}
              items={parentItem.items}
              disabled={parentItem.disabled}
              iconName={parentItem.iconName}
              toggleShowSubMenu={(data) => setIsShowSubMenu(data)}
              menuClass={menuClass}
              id={id}
              key={`parent-${parentItem.title}`}
            />
          ) : (
            <MenuItem
              title={title}
              key={`item-${title}`}
              link={link}
              disabled={disabled}
              iconName={iconName}
              itemClassName={styles.item}
              disabledClassName={styles.disabled}
              iconClassName={styles.icon}
              activeClassName={styles.active}
            />
          )
        )}
      {isAuthenticated && customButton && (
        <li className={styles.item}>
          <Item
            link={customButton.link}
            disabled={customButton.disabled}
            className={`${styles.item} ${
              customButton.disabled ? styles.disabled : ''
            }`}
            activeClassName={styles.active}
          >
            {customButton.iconName && (
              <Icon
                name={transformCmsIcons(customButton.iconName)}
                className={styles.icon}
                title=""
              />
            )}
            {customButton.title}
          </Item>
        </li>
      )}
    </ul>
  );
};

export default FooterNavMenu;
