import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const ChatsNavMenu = lazy(() => import('./ChatsNavMenu'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ChatsNavMenu config={config} />
  </Suspense>
);
