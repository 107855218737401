import React, { ComponentProps } from 'react';

import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import createMarkup from '../../utils/createMarkup';

type Props = {
  html?: string;
} & Omit<ComponentProps<typeof Box>, 'dangerouslySetInnerHTML'>;

const InnerHTMLSnippet: Component<Props> = ({ html = '', ...props }) => (
  <Box dangerouslySetInnerHTML={createMarkup(html)} {...props} />
);

export default InnerHTMLSnippet;
