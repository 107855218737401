import {
  TGetSportsRecommendedIdsRequest,
  TGetSportsRecommendedIdsResponse,
  TSportsRecommendedItem
} from '../../../types';
import getSportsRecommendedGamesIdsAdapter from '../adapters/getSportsRecommendedGamesIdsAdapter';

const getSportsRecommendedIdsAdapter = () => ({
  query: ({ siteId }: TGetSportsRecommendedIdsRequest) => `${siteId}`,
  transformResponse(
    response: TGetSportsRecommendedIdsResponse
  ): TSportsRecommendedItem[] {
    return !response ? [] : getSportsRecommendedGamesIdsAdapter(response);
  }
});
export default getSportsRecommendedIdsAdapter;
