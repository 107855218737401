import React from 'react';
import { memoize } from 'proxy-memoize';

import { Component, TQueryResult } from '@monorepo/type';

import SportsSlider from '../../../../common/SportsSlider';
import { SportsSliderItemType } from '../../../../common/SportsSlider/types';
import useGetLineGames from '../../../../hooks/useGetLineGames';
import { selectSuggestedOrderedGames } from '../../../../store/api/selectors';
import { TNormalizedSports, TSportsRecommendedConfig } from '../../../../types';

const PopularSlider: Component<TSportsRecommendedConfig> = ({
  showMoreButton,
  isWithButtons,
  isViewAll,
  title
}) => {
  const {
    data: games,
    isFetching,
    isUninitialized
  } = useGetLineGames(undefined, {
    selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
      isFetching: result?.isFetching,
      isUninitialized: result?.isUninitialized,
      data: selectSuggestedOrderedGames(result)
    }))
  });

  return (
    <SportsSlider
      title={title}
      showMoreButton={showMoreButton}
      isWithButtons={isWithButtons}
      isViewAll={isViewAll}
      itemType={SportsSliderItemType.Suggested}
      games={games}
      isFetching={isFetching}
      isUninitialized={isUninitialized}
    />
  );
};

export default PopularSlider;
