import { WALLET_QUERY_KEYS } from '../../../constants';
import { TGetPaymentServicesList } from '../../../types';

const getPaymentServicesList = () => ({
  query: () => ({
    body: {
      command: 'payment_services_v2',
      params: {}
    }
  }),
  providesTags: [WALLET_QUERY_KEYS.GET_PAYMENTS_LIST],
  transformResponse(
    response?: TGetPaymentServicesList
  ): TGetPaymentServicesList {
    if (!response) {
      return { deposit: [], withdraw: [] };
    }

    const { deposit = [], withdraw = [] } = response;

    return { deposit, withdraw };
  }
});
export default getPaymentServicesList;
