import React from 'react';

import { Component } from '@monorepo/type';
import { MESSAGES_TYPES } from '@monorepo/xmpp';

import useSelectFriendRequestNotifications from '../../hooks/selectors/useSelectFriendRequestNotifications';
import useSelectInbox from '../../hooks/selectors/useSelectInbox';
import CountUI from '../CountUI';

type Props = {
  type: MESSAGES_TYPES;
};

const UnreadMessageGroupCount: Component<Props> = ({
  type,
  className = ''
}) => {
  const { total } = useSelectInbox(type);
  const list = useSelectFriendRequestNotifications();

  if (type === MESSAGES_TYPES.GROUP && total) {
    return <CountUI className={className}>{total}</CountUI>;
  }
  if (type === MESSAGES_TYPES.SINGLE && list?.length) {
    return <CountUI className={className}>{list?.length}</CountUI>;
  }

  return null;
};

export default UnreadMessageGroupCount;
