import { TLimitsRequest } from '../../../types';

const setUserLimits = () => ({
  query: ({
    depositPerMonth,
    depositPerDay,
    depositPerDayRadio,
    depositPerMonthRadio
  }: TLimitsRequest) => ({
    body: {
      command: 'set_user_limits',
      params: {
        type: 'deposit',
        limits: [
          {
            deposit_limit: depositPerDayRadio === '1' ? depositPerDay : 0,
            period_type: 2,
            period: 9999999
          },
          {
            deposit_limit: depositPerMonthRadio === '1' ? depositPerMonth : 0,
            period_type: 4,
            period: 9999999
          }
        ]
      }
    }
  })
});
export default setUserLimits;
