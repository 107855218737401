import { SOCIAL_NOTIFICATION_TYPE } from '../../../constants';
import useSelectNotifications from '../useSelectNotifications';

const useSelectFriendRequestNotifications = () => {
  const notifications = useSelectNotifications();

  return notifications.filter(
    ({ type }) => type === SOCIAL_NOTIFICATION_TYPE.FRIEND_REQUEST
  );
};

export default useSelectFriendRequestNotifications;
