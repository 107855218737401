import React from 'react';

import { classNameProp, CustomFC } from '@monorepo/type';
import { EllipsisText } from '@monorepo/ui-kit';

import styles from './index.module.css';

const GameTitle: CustomFC<classNameProp> = ({ className = '', children }) => (
  <EllipsisText className={`${styles.title} ${className}`}>
    {children}
  </EllipsisText>
);

export default GameTitle;
