import { TToggleLike, TToggleLikeRequest } from '../../../types';

const toggleLike = () => ({
  query: ({ id, likeType }: TToggleLikeRequest) => ({
    url: `/like/${id}?obj_type=${likeType}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  invalidatesTags: (
    result: TToggleLike,
    error: unknown,
    { likeType }: TToggleLikeRequest
  ) => [likeType]
});

export default toggleLike;
