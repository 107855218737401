import regExpValidator from './regExpValidator';

const numbersRegExpValidator = (
  message: string = 'validators.numbers.format'
) => {
  const regexp = new RegExp('^[0-9]*$');

  return regExpValidator(regexp, message);
};

export default numbersRegExpValidator;
