import React, { useState } from 'react';

import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT, FlexBox } from '@monorepo/ui-kit';

import MultiWalletMenu from './MultiWalletMenu';
import SwitchWallet from './SwitchWallet';

import styles from './index.module.css';

const MultiWallet = ({ menuClassName = '', buttonClassName = '' }) => {
  const [isVisible, setVisible] = useState(false);
  const [currency, setCurrency] = useState('');
  const iconName = isVisible ? 'arrow_up-2' : 'arrow_down-4';
  const onChangeCurrency = (currency) => {
    setCurrency(currency);
    setVisible(false);
  };
  return (
    <>
      <FlexBox className={styles.item}>
        <Button
          className={`${styles.settingsBtn} ${
            isVisible ? styles.active : ''
          } ${buttonClassName}`}
          isOnlyIcon
          intent={BUTTON_INTENT.tertiary}
          onClick={() => setVisible(!isVisible)}
          data-custom="multi-wallet-menu"
        >
          <Icon name={iconName} className={styles.icon} />
        </Button>
        {isVisible && (
          <MultiWalletMenu
            onChangeCurrency={onChangeCurrency}
            menuClassName={menuClassName}
          />
        )}
      </FlexBox>

      {Boolean(currency) && (
        <SwitchWallet currencyId={currency} onClose={() => setCurrency('')} />
      )}
    </>
  );
};
export default MultiWallet;
