import { TGlobalFilters } from '../../types';

const formatGlobalFilters = ({
  paginate,
  limit,
  groupBy,
  pluck,
  only
}: Partial<TGlobalFilters>) => ({
  ...(paginate && { paginate }),
  ...(limit && { limit }),
  ...(groupBy && { group_by: groupBy }),
  ...(pluck && { pluck }),
  ...(only && { only })
});

export default formatGlobalFilters;
