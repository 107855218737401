import { MESSAGES_QUERY_KEYS } from '../../../constants';
import { TMessageResponse, TMessages, TMessagesRequest } from '../../../types';
import getMessagesAdapter from '../adapters/getMessagesAdapter';

const getMessages = () => ({
  query: ({ type }: TMessagesRequest) => ({
    body: {
      command: 'user_messages',
      params: {
        where: {
          type
        }
      }
    }
  }),
  providesTags: [MESSAGES_QUERY_KEYS.INCOMING],
  transformResponse(response: TMessageResponse): TMessages {
    return !response ? [] : getMessagesAdapter(response);
  }
});
export default getMessages;
