import { Context, createContext } from 'react';

import { TPageContext } from '../../types';

export const initialState: TPageContext = {
  componentsMap: {},
  page: null
};

const PageContext: Context<TPageContext> = createContext(initialState);

export default PageContext;
