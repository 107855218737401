import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TTeamMatchesRequest } from '../../../types';
import { favoriteTeamMatchesAdapter } from '../adapters/favoriteTeamMatchesAdapter';

const getTeamMatches = () => ({
  query: ({ id }: TTeamMatchesRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: { game: ['id'] },
        where: {
          game: {
            '@and': [
              {
                '@or': [{ visible_in_prematch: 1 }, { type: { '@in': [0, 2] } }]
              },
              { '@or': [{ team1_id: id }, { team2_id: id }] }
            ]
          },
          sport: { alias: 'Soccer' }
        }
      }
    }
  }),
  providesTags: [SPORTS_QUERY_KEYS.FAVORITE_TEAM],
  transformResponse: favoriteTeamMatchesAdapter
});
export default getTeamMatches;
