import { TBonusData } from '../../../types';
import bonusesAdapter from '../adapters/bonuses';

const getCasinoBonusesDetails = () => ({
  query: () => ({
    body: {
      command: 'get_casino_bonuse_details',
      params: {}
    },
    subscribe: true
  }),
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },
  transformResponse: (response): Promise<TBonusData> | TBonusData =>
    bonusesAdapter(response)
});
export default getCasinoBonusesDetails;
