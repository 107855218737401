import { useMemo } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { TRequestParams } from '../../types';

const useRequestParams = (): TRequestParams => {
  const { isDesktopDevice, isIOSOld } = useDeviceDetector();
  const { siteId } = useSiteSettings();
  const { languageLongCode } = useI18n();

  return useMemo(
    () => ({
      partnerId: siteId,
      isMobile: isDesktopDevice ? 0 : 1,
      useWebp: isIOSOld ? 0 : 1,
      lang: languageLongCode
    }),
    [siteId, isDesktopDevice, isIOSOld, languageLongCode]
  );
};

export default useRequestParams;
