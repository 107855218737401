import { createApi } from '@reduxjs/toolkit/query/react';

import { wsBaseQuery } from '@monorepo/websocket';

import getNetLoss from './api/getNetLoss';

export const casinoLimitationsApi = createApi({
  reducerPath: 'casinoLimitationsApi',
  baseQuery: wsBaseQuery(),
  endpoints: (builder) => ({
    getNetLoss: builder.query(getNetLoss())
  })
});

export const { useGetNetLossQuery } = casinoLimitationsApi;
