import React, { ReactElement } from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { CustomFC } from '@monorepo/type';

import MenuItemSkeleton from '../MenuItemSkeleton';

type TMenuSkeletonProps = {
  length: number;
};

const MenuSkeleton: CustomFC<TMenuSkeletonProps> = ({
  length
}): ReactElement => (
  <>
    {createSkeletonArray(length).map((i) => (
      <MenuItemSkeleton key={i} />
    ))}
  </>
);

export default MenuSkeleton;
