import Element from 'ltx/src/Element';

import { messageReceived, XML_NAMESPACES } from '@monorepo/xmpp';

import { createUnreadMessageNotification } from '../../../../utils/notifications';
import { chatMessageParse } from '../../../../utils/stanzaParsers';
import MessageHandler from '../MessageHandler';

class ChatMessageHandler {
  messageHandler: MessageHandler;

  constructor(handler: MessageHandler) {
    this.messageHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { addMessage } = this.messageHandler.reduxActions;
    const { displayToast } = this.messageHandler.callbacks;
    const request = stanza.getChild('request', XML_NAMESPACES.RECEIPTS);
    const message = chatMessageParse(stanza, this.messageHandler.jidString);

    if (request) {
      this.messageHandler.instance.send(
        messageReceived(message.from, message.id)
      );
    }

    if (message?.text) {
      addMessage(message);
      displayToast(createUnreadMessageNotification(message.roomJid));
    } else {
      // eslint-disable-next-line no-console
      console.log(`Empty chat message ${stanza}`);
    }
  };
}

export default ChatMessageHandler;
