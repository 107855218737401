import React from 'react';

import { Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

const GameSkeleton = ({ className = '' }) => (
  <Skeleton className={`${styles.skeleton} ${className}`} />
);

export default GameSkeleton;
