import { useGetPartnerConfig, useGetUser } from '@monorepo/auth';
import { useSiteSettings } from '@monorepo/site-settings';

import {
  useGetExternalJackpotsListQuery,
  useGetJackpotsListQuery
} from '../../store/casinoJackpots';
import useGetDefaultProvidersData from '../useGetDefaultProvidersData';

const useGetAllJackpotProviders = () => {
  const { siteId } = useSiteSettings();
  const { data: partnerConfig } = useGetPartnerConfig();
  const { currency: partnerCurrency } = partnerConfig || {};
  const { data: profile } = useGetUser();
  const { currencyName } = profile || {};

  const { data: jackpots } = useGetJackpotsListQuery(
    {
      partnerId: siteId,
      currency: currencyName || partnerCurrency
    },
    { skip: !currencyName && !partnerCurrency }
  );
  const [currentJackpotProvider] = jackpots || [];
  const { data: defaultProvider, isFetching: isFetching1 } =
    useGetDefaultProvidersData(currentJackpotProvider);

  const { data: externalJackpots = [], isFetching: isFetching2 } =
    useGetExternalJackpotsListQuery(
      {
        partnerId: siteId,
        currency: currencyName || partnerCurrency
      },
      { skip: !currencyName && !partnerCurrency }
    );

  return {
    providers: [defaultProvider, ...externalJackpots],
    isFetching: isFetching1 || isFetching2
  };
};

export default useGetAllJackpotProviders;
