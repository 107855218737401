import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { FlexBoxProps } from '../../types';
import formatCSSProperty from '../../utils/formatCSSProperty';
import getClasses from '../../utils/getClasses';
import Box from '../Box';

import styles from './index.module.css';

const FlexBox: ForwardRefRenderFunction<HTMLDivElement, FlexBoxProps> = (
  { children, className = '', justify, align, direction, wrap, ...props },
  ref
) => {
  const flexClassNames = formatCSSProperty({
    justify,
    align,
    direction,
    wrap
  });
  const styleClasses = getClasses([...flexClassNames], styles);
  const classes = `${styles.box} ${styleClasses} ${className}`;

  return (
    <Box className={classes} {...props} ref={ref}>
      {children}
    </Box>
  );
};

export default forwardRef(FlexBox);
