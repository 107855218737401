import Element from 'ltx/src/Element';

import MessageHandler from '../../handlers/MessageHandler';

const sendMessageStanzaCallback =
  (messageHandler: MessageHandler) => (stanza: Element) => {
    if (stanza?.is('message')) {
      return messageHandler.processSentStanza(stanza);
    }
    return stanza;
  };

export default sendMessageStanzaCallback;
