import { createApi } from '@reduxjs/toolkit/query/react';

import { wsBaseQuery } from '@monorepo/websocket';

import requestSession from './api/requestSession';

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: wsBaseQuery(),
  endpoints: (builder) => ({
    requestSession: builder.mutation(requestSession())
  })
});

export const { useRequestSessionMutation } = sessionApi;
