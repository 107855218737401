import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { Icon } from '@monorepo/icons';
import { Button } from '@monorepo/ui-kit';

import styles from './index.module.css';

const SearchButton = () => {
  const { watch }: UseFormReturn = useFormContext();
  const isTouched = watch('search');
  const focusedClass = isTouched ? styles.focused : '';
  const searchButton = `${styles.searchButton} ${styles.btn} ${focusedClass}`;

  return (
    <Button type="submit" isOnlyIcon className={searchButton}>
      <Icon name="search_1" className={styles.icon} />
    </Button>
  );
};
export default SearchButton;
