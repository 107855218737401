export enum PlatformType {
  web,
  mobile,
  casino,
  sportsbook
}

export enum ShowTo {
  all,
  authorized,
  guest
}

export const userSearchSymbolsNumber = 2;
