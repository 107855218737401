import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  LinkButton,
  NavItem,
  Popper
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const CustomButton = ({
  item,
  linkClassName
}: {
  item: any;
  linkClassName?: string;
}) => {
  const { title, link, iconName, target } = item;
  const { t } = useI18n(NAMESPACES.COMMON);
  const { generalRoutes } = useSiteSettings();
  const { profilePreferablesCustom } = generalRoutes;

  return (
    <NavItem
      title={title}
      link={link}
      iconName={iconName}
      target={target}
      className={styles.item}
      linkClassName={linkClassName}
    >
      <Popper
        toneType={FIELD_TONE.light}
        classWrapper={styles.popper}
        classInner={styles.inner}
        isShowPopper
      >
        <Box className={styles.info}>
          <Box className={styles.text}>{t('custom.text')}</Box>
          <LinkButton
            size={BUTTON_SIZE.h28}
            intent={BUTTON_INTENT.primary}
            to={profilePreferablesCustom}
            className={styles.button}
          >
            {t('custom.edit')}
          </LinkButton>
        </Box>
      </Popper>
    </NavItem>
  );
};
export default CustomButton;
