import React from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { memoize } from 'proxy-memoize';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC, TQueryResult } from '@monorepo/type';

import { GAME_TYPES_MAP } from '../../../../constants';
import useCreateSportLink from '../../../../hooks/useCreateSportLink';
import useGetMenuSports from '../../../../hooks/useGetMenuSports';
import { selectSportsBySportId } from '../../../../store/api/selectors';
import { TNormalizedSports } from '../../../../types';

import styles from '../../index.module.css';

type Props = {
  sportId: number;
};

const CounterLink: CustomFC<Props> = ({ sportId }) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const { data: sport } = useGetMenuSports(
    { gameType: GAME_TYPES_MAP.PREMATCH, search: '' },
    {
      selectFromResult: memoize((result: TQueryResult<TNormalizedSports>) => ({
        data: selectSportsBySportId(result, sportId)
      }))
    }
  );

  const link = useCreateSportLink(sport?.alias);

  if (isEmpty(sport)) {
    return null;
  }

  const { game } = sport;

  return (
    <Link to={link} className={styles.link}>
      {t('sportsbook.line.viewall', { eventsCount: game })}
      <Icon name="arrows" className={styles.linkIcon} />
    </Link>
  );
};

export default CounterLink;
