import { TRegisterRequest } from '../../../types';

const signUp = () => ({
  query: (userInfo: TRegisterRequest) => ({
    body: {
      command: 'register_user',
      params: {
        user_info: {
          email: userInfo.email,
          password: userInfo.password,
          currency_name: userInfo.currency,
          lang_code: userInfo.langCode,
          phone: userInfo.phone,
          ignore_username: 1,
          country_code: userInfo.citizenship,
          agree: true,
          terms_and_conditions_version: 1,
          birth_date: userInfo.birthday,
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
          g_recaptcha_response: userInfo.recaptchaToken,
          promo_code: userInfo.promocode,
          btag: userInfo.bTag,
          note: userInfo.databox,
          confirmation_code: userInfo.confirmationCode,
          username: userInfo.username,
          gender: userInfo.gender,
          middle_name: userInfo.middleName,
          telegram_id: userInfo.telegramId,
          doc_number: userInfo.docNumber || ''
        }
      }
    },
    checkError: (response) => {
      const { code, data } = response;
      const { result, result_text: message } = data;
      return !(code === 0 && result === 'OK')
        ? { code: result, message }
        : false;
    }
  })
});

export default signUp;
