import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Box,
  Button,
  BUTTON_SIZE,
  FIELD_TONE,
  FLEX_DIRECTION,
  FlexBox
} from '@monorepo/ui-kit';

import TwoFactorAuth from '../TwoFactorAuth';

import styles from './index.module.css';

const TwoFactorEnable = ({
  toneType = FIELD_TONE.dark,
  qrCode,
  handleSubmit
}: {
  toneType?: FIELD_TONE;
  qrCode?: string;
  handleSubmit: () => void;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);

  return !qrCode ? (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      className={`${styles.wrap} ${styles[toneType]}`}
    >
      <Box className={styles.description}>
        {t('two.factor.activate.description')}
      </Box>
      <Box className={styles.head}>{t('two.factor.activate.header')}</Box>
      <Button
        size={BUTTON_SIZE.h30}
        className={styles.button}
        onClick={handleSubmit}
      >
        {t('two.factor.activate.enable')}
      </Button>
    </FlexBox>
  ) : (
    <TwoFactorAuth qrCode={qrCode} toneType={toneType} />
  );
};
export default TwoFactorEnable;
