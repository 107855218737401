import { AUTH_QUERY_KEYS } from '../../../constants';
import { TBonusesData } from '../../../types';
import bonusesAdapter from '../adapters/bonuses';

const getBonuses = () => ({
  query: ({ isFree }) => ({
    body: {
      command: 'get_bonus_details',
      params: { free_bonuses: isFree }
    }
  }),
  providesTags: [AUTH_QUERY_KEYS.BONUSES],
  checkError: (response) => {
    const { code, data } = response;
    const { result, result_text: msg } = data;
    return !(code === 0 && result === 0) ? msg : false;
  },

  transformResponse: (
    response: TBonusesData
  ): Promise<TBonusesData> | TBonusesData =>
    bonusesAdapter(response)?.bonuses || []
});
export default getBonuses;
