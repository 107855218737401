import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { usePlayerProfile } from '@monorepo/cms';
import { getNickName, PROFILE_NICKNAME } from '@monorepo/common';
import { AVATAR_SIZE } from '@monorepo/files';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import MultiWalletMenu from '../../../../common/src/components/MultiWallet';
import HeaderAvatar from '../../common/HeaderAvatar';
import useGetUser from '../../hooks/useGetUser';
import UserBalance from '../UserBalance';
import UserBalanceLoader from '../UserBalanceLoader';

import styles from './index.module.css';

const HeaderUserMenu: ComponentWithConfig = ({ config }) => {
  const { data: profile = {}, isLoading } = useGetUser();
  const { showMultiWallet } = config;
  const { generalRoutes, userDefaultNick = PROFILE_NICKNAME.username } =
    useSiteSettings();
  const { profile: profilePage } = generalRoutes;
  const player = usePlayerProfile();
  const imageNew = `${player?.data?.avatar_img}/${AVATAR_SIZE.S}`;
  const nickname = getNickName(
    profile,
    player?.data,
    userDefaultNick as PROFILE_NICKNAME
  );
  return (
    <FlexBox>
      <FlexBox
        className={styles.wrap}
        justify={FLEX_JUSTIFY.center}
        align={FLEX_ALIGN.center}
      >
        <FlexBox align={FLEX_ALIGN.center}>
          <Link
            to={profilePage}
            className={`${styles.avatar} ${styles.avatarLink}`}
          />
          <HeaderAvatar
            className={styles.avatar}
            avatarClass={styles.headerAvatar}
            imgSrc={imageNew}
            nickname={nickname}
          />
          {isLoading ? (
            <UserBalanceLoader />
          ) : (
            <UserBalance profile={profile} />
          )}
        </FlexBox>
      </FlexBox>
      {showMultiWallet && <MultiWalletMenu />}
    </FlexBox>
  );
};

export default memo(HeaderUserMenu);
