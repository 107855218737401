import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { BackHeaderCommon, MetaTitle } from '@monorepo/common';
import { Component } from '@monorepo/type';
import { Loader } from '@monorepo/ui-kit';

import InnerHTMLSnippet from '../../common/InnerHTMLSnippet';
import useGetSnippet from '../../hooks/useGetSnippet';

import styles from './index.module.css';

type Props = {
  snippetId: string | number;
  onGoBack?: () => void;
};

const HTMLSnippetPage: Component<Props> = ({
  snippetId,
  onGoBack,
  className = ''
}) => {
  const { isFetching, snippet } = useGetSnippet({ id: snippetId });

  if (isFetching) {
    return (
      <div className={`${styles.wrap} ${className}`}>
        <Loader />
      </div>
    );
  }

  if (isEmpty(snippet)) {
    return null;
  }

  const { title } = snippet;

  return (
    <div className={`${styles.wrap} ${className}`}>
      <BackHeaderCommon
        backLink={onGoBack}
        title={title}
        className={styles.header}
      />
      <MetaTitle titlePage={title} />
      <InnerHTMLSnippet className={styles.content} html={snippet?.content} />
    </div>
  );
};

export default HTMLSnippetPage;
