import { BONUS_DATA } from '../../../constants';

const getBonusesRequest = (userApi: any) => ({
  query: ({ bonusTypeId }: { bonusTypeId: number }) => ({
    body: {
      command: 'create_client_bonus_request',
      params: {
        bonus_source: bonusTypeId
      }
    }
  }),
  async onQueryStarted(
    args: any,
    { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: any }
  ) {
    const { data } = await queryFulfilled;
    if (data && data?.result === 0) {
      dispatch(userApi.util.invalidateTags([BONUS_DATA.BONUS_REQUEST]));
    }
  }
});
export default getBonusesRequest;
