import React from 'react';
import { NavLink } from 'react-router-dom';

import { Component } from '@monorepo/type';
import { Box, FLEX_DIRECTION, FlexBox, TMenuItem } from '@monorepo/ui-kit';

import styles from './index.module.css';

const Menu: Component<{
  items: TMenuItem[];
}> = ({ items = [] }) => (
  <FlexBox className={`${styles.list}`} direction={FLEX_DIRECTION.row}>
    {items.map(({ title, items: subItems }) => (
      <FlexBox
        className={styles.column}
        direction={FLEX_DIRECTION.column}
        key={`footer-menu${title}`}
      >
        <Box className={styles.head}>{title}</Box>
        <Box className={styles.sub}>
          {subItems?.map(({ title: titleLink, link, target = '' }) => (
            <NavLink
              to={link}
              target={target}
              className={styles.link}
              key={`footer-menu-link${link}`}
            >
              {titleLink}
            </NavLink>
          ))}
        </Box>
      </FlexBox>
    ))}
  </FlexBox>
);

export default Menu;
