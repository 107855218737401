import BaseSocketClient from '../BaseSocketClient';

class JackpotSocketClient extends BaseSocketClient {
  handleMessage = (messageData: Record<string, any>) => {
    const { Data, [this.requestIdProperty]: RequestId } = messageData;
    this.subscribers.forEach((callback, subid) => {
      if (RequestId === subid) {
        callback(Data);
      }
    });
  };
}

export default JackpotSocketClient;
