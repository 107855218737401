import { AUTH_QUERY_KEYS } from '../../../constants';
import { TGetLimit } from '../../../types';
import getDocumetsAdapter from '../adapters/getDocumetsAdapter';

const getDocuments = () => ({
  query: () => ({
    body: {
      command: 'get_images'
    }
  }),
  providesTags: [AUTH_QUERY_KEYS.DOCUMENTS],
  transformResponse(response): Promise<TGetLimit> | TGetLimit {
    return getDocumetsAdapter(response);
  }
});
export default getDocuments;
