import React from 'react';

import { createSkeletonArray } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import LineCompetitionHeaderSkeleton from '../LineCompetitionHeader/LineCompetitionHeaderSkeleton';
import LineGamesListSkeleton from '../LineGamesList/LineGamesListSkeleton';
import LineGamesListGroupedByDateSkeleton from '../LineGamesListGroupedByDate/LineGamesListGroupedByDateSkeleton';

import styles from './index.module.css';

type Props = {
  length?: number;
  isGroupedByDate?: boolean;
  sportAlias: string;
};

const LineCompetitionsListSkeleton: Component<Props> = ({
  length = 2,
  isGroupedByDate = false,
  sportAlias,
  className = ''
}) => (
  <Box className={className}>
    {createSkeletonArray(length).map((item: number) => (
      <Box key={item} className={styles.item}>
        <LineCompetitionHeaderSkeleton />
        {isGroupedByDate ? (
          <LineGamesListGroupedByDateSkeleton sportAlias={sportAlias} />
        ) : (
          <LineGamesListSkeleton sportAlias={sportAlias} />
        )}
      </Box>
    ))}
  </Box>
);

export default LineCompetitionsListSkeleton;
