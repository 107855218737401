import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const CasinoSection = lazy(() => import('./CasinoSection'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <CasinoSection {...props} />
  </Suspense>
);
