import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';

import useGametaRedirect from '../../hooks/useGametaRedirect';

import styles from './index.module.css';

const MetaVerseButton = ({
  item,
  linkClassName
}: {
  item: any;
  linkClassName?: string;
}) => {
  const { title, link, iconName } = item;
  const { t } = useI18n(NAMESPACES.COMMON);
  const { checkMeta } = useGametaRedirect();
  return (
    <Button
      onClick={() => checkMeta(link)}
      intent={BUTTON_INTENT.transparent}
      className={`${styles.button} ${linkClassName}`}
    >
      <Icon name={iconName} className={styles.metaverseIcon} />
      {t(`navigation.${title.toLowerCase()}`)}
    </Button>
  );
};
export default MetaVerseButton;
