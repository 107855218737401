import React from 'react';

import { InnerHTMLSnippet } from '@monorepo/cms';
import { formatUnixTime } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import {
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  LinkButton,
  NotificationDate,
  NotificationTitle,
  TEXT_SIZE
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TCMSNotification = {
  hrefType: string;
  title: string;
  content: string;
  src: string;
  srcAlt: null | string;
  srcType: string;
  href?: string;
  target?: string;
  showTo: number;
};

type Props = {
  data: TCMSNotification;
  toneType?: FIELD_TONE;
  textSize?: TEXT_SIZE;
  isRead?: boolean;
};

const CMSNotificationDetails: Component<Props> = ({
  data,
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small,
  isRead = true
}) => {
  const { src, srcAlt, title, href, target, hrefType, content, date } = data;
  const formatedDate = data ? formatUnixTime(date, 'dd.MM.yyyy, HH:mm') : null;
  const { t } = useI18n(NAMESPACES.COMMON);

  return (
    <>
      <Box className={styles.contentWrapper}>
        {formatedDate && (
          <NotificationDate className={styles.date}>
            {formatedDate}
          </NotificationDate>
        )}
        <NotificationTitle
          textSize={textSize}
          toneType={toneType}
          className={styles.title}
          isRead={isRead}
        >
          {title}
        </NotificationTitle>
        <InnerHTMLSnippet
          className={`${styles[toneType]} ${styles[textSize]} ${styles.text}`}
          html={content}
        />
        {hrefType === 'link' && href && (
          <LinkButton
            className={styles.link}
            to={href}
            intent={BUTTON_INTENT.primary}
            size={BUTTON_SIZE.h40}
            target={target}
          >
            {t('notifications.follow')}
          </LinkButton>
        )}
      </Box>
    </>
  );
};

export default CMSNotificationDetails;
