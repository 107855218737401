import { useSiteSettings } from '@monorepo/site-settings';

import { TGetGamesRequest } from '../../types';
import useGetGames from '../useGetGames';
import useLineParameters from '../useLineParameters';

const useGetLineGames = (
  args: Partial<TGetGamesRequest> = {},
  options = {}
) => {
  const {
    gameType,
    sportAlias,
    regionAlias,
    competitionId,
    time,
    isPopular,
    limit,
    search,
    gameIds,
    competitionIds,
    nodeLimits
  } = useLineParameters();

  const { languages } = useSiteSettings();

  return useGetGames(
    {
      gameType,
      sportAlias,
      regionAlias,
      competitionId,
      time,
      isPopular,
      limit,
      search,
      gameIds,
      competitions: competitionIds,
      nodeLimits,
      languages,
      ...args
    },
    options
  );
};

export default useGetLineGames;
