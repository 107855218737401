import { TListRange } from '@monorepo/common';

const getPrevRangePeriod = (page: number | null, pagesList: TListRange[]) => {
  if (!pagesList.length || page === null || page === 0) {
    return { prevFromDate: '', prevToDate: '' };
  }
  const prevRangeIndex = page - 1;
  if (prevRangeIndex < 0) {
    return { prevFromDate: '', prevToDate: '' };
  }

  const prevPage = pagesList.filter((item) => item.page === prevRangeIndex);
  const [{ currentFromDate, currentToDate }] = prevPage;

  return {
    prevFromDate: currentFromDate,
    prevToDate: currentToDate
  };
};

export default getPrevRangePeriod;
