import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { BoxProps } from '../../types';
import formatSpacingClassNames from '../../utils/formatSpacingClassNames ';
import getClasses from '../../utils/getClasses';

import styles from './index.module.css';

const Box: ForwardRefRenderFunction<HTMLDivElement, BoxProps> = (
  {
    children,
    className = '',
    margin = '',
    mt,
    mb,
    ml,
    mr,
    padding = '',
    pt,
    pb,
    pl,
    pr,
    ...props
  },
  ref
) => {
  const marginClassNames = formatSpacingClassNames(
    'margin',
    mt,
    mb,
    ml,
    mr,
    margin
  );
  const paddingClassNames = formatSpacingClassNames(
    'padding',
    pt,
    pb,
    pl,
    pr,
    padding
  );
  const styleClasses = getClasses(
    [...marginClassNames, ...paddingClassNames],
    styles
  );
  const classes = `${styleClasses} ${className}`;

  return (
    <div className={classes} {...props} ref={ref}>
      {children}
    </div>
  );
};

export default forwardRef(Box);
