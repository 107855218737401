import React, { useEffect, useState } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';
import { camelizeKeys } from 'humps';
import mapValues from 'lodash/mapValues';

import { useIsAuthenticated } from '@monorepo/auth';
import { usePlayerProfile } from '@monorepo/cms';
import {
  COOKIE_KEYS,
  getCookie,
  getISOStringDate,
  isValidJson,
  setCookie
} from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { userNotificationActions } from '@monorepo/notification';
import { usePageContext } from '@monorepo/page';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  useShutterRef
} from '@monorepo/ui-kit';

import { DEFAULT_COOKIES_SETTINGS } from '../../constants';
import useSetCookiesNotification from '../../hooks/useCookiesNotification';
import {
  useIsShowCookiesSettings,
  useSelectCookiesSettings
} from '../../store/selectors';
import CookieDetailsNotification from '../CookieDetailsNotification';

import styles from './index.module.css';

type TLogoId = {
  logoComponentId?: string;
};

const LogoComponent: CustomFC<TLogoId> = ({ logoComponentId }) => {
  const { components } = useSiteSettings();
  const componentData = components[logoComponentId];
  const context = usePageContext();
  const { componentsMap } = context;
  const Component = componentsMap[componentData?.type];

  if (Component) {
    return <Component config={componentData?.config || {}} />;
  }
  return null;
};

const CookieAgreeNotification: CustomFC<TLogoId> = ({ logoComponentId }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();
  const { generalRoutes } = useSiteSettings();
  const { cookieAgreeHere } = generalRoutes || {};
  const { isAuthenticated, isAuthFetched } = useIsAuthenticated();
  const { value: cookieAgree = '' } = useSelectCookiesSettings() || {};
  const cookieAgreement = getCookie(COOKIE_KEYS.COOKIE_AGREE);
  const unAuthorizedCookie = isValidJson(cookieAgreement)
    ? JSON.parse(cookieAgreement)
    : '';
  const [cookieAgreeUnregistered, setCookieAgreeUnregistered] =
    useState(unAuthorizedCookie);
  const { setCookiesSettings, setShowCookiesSettings, setHideCookiesSettings } =
    userNotificationActions();
  const isShowCookiesSettings = useIsShowCookiesSettings();
  const { onSaveCookies } = useSetCookiesNotification();
  const { shutterRef, setShow, setHide } = useShutterRef();

  const { data: playerInfo, isFetching, isUninitialized } = usePlayerProfile();
  const { private_settings: ps } = playerInfo || {};
  const privateSettings = camelizeKeys(ps);

  const declineCookiesSettings = DEFAULT_COOKIES_SETTINGS;
  const defaultCookiesSettings = mapValues(
    DEFAULT_COOKIES_SETTINGS,
    () => true
  );

  const handleOpen = () => {
    setShowCookiesSettings();
    setShow();
  };

  const handleClose = () => {
    setHideCookiesSettings();
    setHide();
  };

  const handleClick = () => {
    const cookiesSettings = {
      ...defaultCookiesSettings,
      value: getISOStringDate()
    };
    if (!isAuthenticated) {
      setCookieAgreeUnregistered(cookiesSettings);
      setCookie(COOKIE_KEYS.COOKIE_AGREE, JSON.stringify(cookiesSettings), {
        expires: 365
      });
      return;
    }
    onSaveCookies(cookiesSettings);
  };

  const handleReject = () => {
    const cookiesSettings = {
      ...declineCookiesSettings,
      value: getISOStringDate()
    };
    if (!isAuthenticated) {
      setCookieAgreeUnregistered(cookiesSettings);
      setCookie(COOKIE_KEYS.COOKIE_AGREE, JSON.stringify(cookiesSettings), {
        expires: 365
      });
      return;
    }
    onSaveCookies(cookiesSettings);
  };

  useEffect(() => {
    if (isAuthenticated && privateSettings) {
      setCookiesSettings(privateSettings);
    }
    if (!isAuthenticated && unAuthorizedCookie !== '') {
      setCookiesSettings(unAuthorizedCookie);
    }
    if (!isAuthenticated && unAuthorizedCookie === '') {
      setCookiesSettings({ ...defaultCookiesSettings, value: '' });
    }
  }, [playerInfo, isAuthenticated, cookieAgreement]);

  if (
    !isAuthFetched ||
    cookieAgree ||
    cookieAgreeUnregistered ||
    privateSettings?.value ||
    (isAuthenticated && (isFetching || isUninitialized))
  ) {
    return null;
  }

  const logo = isDesktop ? (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.logo}
    >
      <LogoComponent logoComponentId={logoComponentId} />
    </FlexBox>
  ) : null;

  return (
    <FlexBox className={styles.wrap}>
      <CookieDetailsNotification
        setCookieAgreeUnregistered={setCookieAgreeUnregistered}
        handleClose={handleClose}
        ref={shutterRef}
        classContentModal={styles.content}
        classOverlayModal={styles.overlayModal}
      />
      {isShowCookiesSettings ? null : (
        <FlexBox
          className={styles.body}
          direction={isDesktop ? FLEX_DIRECTION.row : FLEX_DIRECTION.column}
        >
          {logo}
          <Box className={styles.message}>
            <Box className={styles.title}>{t('cookie.agree.title')}</Box>
            <Box className={styles.text}>
              {t('cookie.agree.message_1')}
              <span className={styles.link} onClick={handleOpen}>
                {t('cookie.more.details').toLowerCase()}
              </span>
              {t('cookie.agree.message_2')}
              <a href={cookieAgreeHere} className={styles.link}>
                {t('cookie.agree.here')}
              </a>
            </Box>
          </Box>

          <FlexBox
            direction={FLEX_DIRECTION.column}
            className={styles.detailsWrap}
            justify={FLEX_JUSTIFY.end}
          >
            <Button
              intent={BUTTON_INTENT.primary}
              className={styles.button}
              size={BUTTON_SIZE.h40}
              onClick={handleClick}
            >
              {t('cookie.agree.button')}
            </Button>
            <Button
              intent={BUTTON_INTENT.secondary}
              className={styles.button}
              size={BUTTON_SIZE.h40}
              onClick={handleReject}
            >
              {t('cookie.decline.button')}
            </Button>
            <span className={styles.details} onClick={handleOpen}>
              {t('cookie.more.details')}
            </span>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default CookieAgreeNotification;
