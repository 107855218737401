import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import { toneTypeProp } from '../../../types';
import Input from '../Input';

type InputProps = MaskedInputProps | Readonly<MaskedInputProps>;

type Props = toneTypeProp &
  InputProps & { inputClassName?: string; 'data-custom'?: string };

const InputMask = (props: Props) => (
  <MaskedInput
    render={(ref, inputProps) => <Input ref={ref} {...inputProps} />}
    {...props}
  />
);

export default InputMask;
