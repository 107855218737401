import { TGetListOfLikesRequest, TListOfLikes } from '../../../types';

const getListOfLikes = () => ({
  query: ({ ids, siteId, likeType }: TGetListOfLikesRequest) => ({
    url: `/likes/${ids}?obj_type=${likeType}&partner_id=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }),
  providesTags: (
    result: TListOfLikes,
    error: unknown,
    { likeType }: TGetListOfLikesRequest
  ) => [likeType],
  keepUnusedDataFor: 0
});

export default getListOfLikes;
