import React from 'react';

import { Component } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  direction?: FLEX_DIRECTION;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
};

const SportsBookButton: Component<Props> = ({
  isActive = false,
  onClick,
  direction = FLEX_DIRECTION.column,
  disabled = false,
  className = '',
  children
}) => {
  const classes = `${styles.wrap}
    ${styles[direction]}
    ${isActive ? styles.active : ''}
    ${disabled ? styles.disabled : ''}
    ${className}`;

  return (
    <FlexBox
      onClick={disabled ? undefined : onClick}
      direction={direction}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={classes}
    >
      {children}
    </FlexBox>
  );
};

export default SportsBookButton;
