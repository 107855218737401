import React from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const ToastCloseButton: Component<Props> = ({ onClick, className = '' }) => (
  <Box className={`${styles.closeButton} ${className}`} onClick={onClick}>
    <Icon name="close_0" title="" />
  </Box>
);

export default ToastCloseButton;
