import { convertAmount } from '@monorepo/common';

const getBalance = (profile) => {
  const {
    bonusBalance = 0,
    frozenBalance = 0,
    bonusWinBalance = 0
  } = profile || {};
  return convertAmount(
    (bonusWinBalance ? bonusWinBalance + bonusBalance : bonusBalance) +
      frozenBalance || 0
  );
};

export default getBalance;
