import addHours from 'date-fns/addHours';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import getSeconds from 'date-fns/getSeconds';

const normalizeTime = (time: number): string | number =>
  String(time).length === 1 ? `0${time}` : time;

const convertToDuration = (secondsAmount: number) => {
  const milliseconds = secondsAmount * 1000;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / 60;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const h = getHours(dateWithoutTimezoneDiff);
  const m = getMinutes(dateWithoutTimezoneDiff);
  const s = getSeconds(dateWithoutTimezoneDiff);

  const hours = normalizeTime(h);
  const minutes = normalizeTime(m);
  const seconds = normalizeTime(s);

  const time = `${hours}:${minutes}:${seconds}`;

  return { h, m, s, hours, minutes, seconds, time };
};

export default convertToDuration;
