import { camelizeKeys } from 'humps';

import { TCasinoTournament, TTournamentsResponse } from '../../../../types';

const getTournamentsAdapter = (
  response: TTournamentsResponse
): TCasinoTournament[] => {
  const { result } = response;
  return result.map((item) => camelizeKeys(item)) as TCasinoTournament[];
};

export default getTournamentsAdapter;
