import { camelizeKeys } from 'humps';

import { TJoinTournament, TJoinTournamentResponse } from '../../../../types';

const joinTournamentAdapter = (
  response: TJoinTournamentResponse
): TJoinTournament => {
  const { result } = response;
  return camelizeKeys(result) as TJoinTournament;
};

export default joinTournamentAdapter;
