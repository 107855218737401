// const convertAmount = (value: number | string) => {
//   const data = value.toString().replace(/,/g, '.');
//   return Number(data).toLocaleString('de-DE');
// };

// export default convertAmount;

const convertAmount = (
  value: number | string,
  digitSeparator: string = ','
): string => {
  const data = value ? value.toString().replace(/,/g, '.') : null;
  const numberValue = Number(data);

  if (Number.isInteger(numberValue)) {
    const formattedValue = digitSeparator.includes('.')
      ? numberValue.toLocaleString('en-US')
      : numberValue.toLocaleString('de-DE');
    return `${formattedValue}${digitSeparator}00`;
  }

  const decimalValue = numberValue.toFixed(2);
  const parts = decimalValue.split('.');
  const integerPart = digitSeparator.includes('.')
    ? Number(parts[0]).toLocaleString('en-US')
    : Number(parts[0]).toLocaleString('de-DE');

  return `${integerPart}${digitSeparator}${parts[1]}`;
};

export default convertAmount;
