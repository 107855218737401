import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

import { TAuthData } from '../types';
import {
  TChatOpen,
  TExternalParam,
  TKeyBoardOpen,
  TLoginTime,
  TPlayingTime,
  TShowZeroBalance,
  TUserLocalState
} from '../types/userLocal';

const initialState = {
  playingTime: 0,
  loginTime: null,
  authData: null,
  chatOpen: false, // todo do we need it in LS
  signData: null,
  keyBoardOpen: false, // todo do we need it in LS
  externalParam: {},
  showZeroBalance: true
};

export const userLocalSlice = createSlice<
  TUserLocalState,
  SliceCaseReducers<TUserLocalState>
>({
  name: 'userLocal',
  initialState,
  reducers: {
    resetUserLocal: (state) => {
      // eslint-disable-next-line no-param-reassign
      state = initialState;
      return state;
    },
    setPlayingTime: (state, { payload }: TPayload<TPlayingTime>) => {
      // eslint-disable-next-line no-param-reassign
      state.playingTime = payload;
      return state;
    },
    setAuthData: (state, { payload }: TPayload<TAuthData>) => {
      // eslint-disable-next-line no-param-reassign
      state.authData = { ...(state.authData || {}), ...payload };
      return state;
    },
    setExternalParam: (state, { payload }: TPayload<TExternalParam>) => {
      // eslint-disable-next-line no-param-reassign
      state.externalParam = payload;
      return state;
    },
    setSignData: (state, { payload }: TPayload<TAuthData>) => {
      // eslint-disable-next-line no-param-reassign
      state.signData = payload;
      return state;
    },
    setLoginTime: (state, { payload }: TPayload<TLoginTime>) => {
      // eslint-disable-next-line no-param-reassign
      state.loginTime = payload;
      return state;
    },
    setChatIsOpen: (state, { payload }: TPayload<TChatOpen>) => {
      // eslint-disable-next-line no-param-reassign
      state.chatOpen = payload;
      return state;
    },
    setKeyBoardIsOpen: (state, { payload }: TPayload<TKeyBoardOpen>) => {
      // eslint-disable-next-line no-param-reassign
      state.keyBoardOpen = payload;
      return state;
    },
    setShowZeroBalance: (state, { payload }: TPayload<TShowZeroBalance>) => {
      // eslint-disable-next-line no-param-reassign
      state.showZeroBalance = payload;
      return state;
    }
  }
});

export const { actions } = userLocalSlice;
