import { useSetTimeout } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';

import useActions from '../useActions';

const useAddToBetslip = () => {
  const { expireTimeSession = null } = useSiteSettings();
  const { setBetInfo, clearBets, setExpBetsTime } = useActions();

  useSetTimeout(clearBets, expireTimeSession);

  return (
    eventId: number,
    gameId: number,
    marketId: number,
    price: number,
    startTs: number,
    team1Name: string,
    team2Name: string,
    name: string,
    eventName: string,
    base: number
  ) => {
    if (expireTimeSession !== null) {
      setExpBetsTime(Date.now() + expireTimeSession);
    }
    setBetInfo({
      eventId,
      gameId,
      marketId,
      price,
      startTs,
      team1Name,
      team2Name,
      marketName: name,
      eventName,
      base
    });
  };
};

export default useAddToBetslip;
