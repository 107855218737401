import React from 'react';

import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox, Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

const LineSportHeader: Component = ({ className = '' }) => (
  <FlexBox align={FLEX_ALIGN.center} className={`${styles.wrap} ${className}`}>
    <Skeleton className={`${styles.icon} ${styles.skeleton}`} />
    <Skeleton className={`${styles.title} ${styles.skeleton}`} />
    <Skeleton className={`${styles.link} ${styles.skeleton}`} />
  </FlexBox>
);

export default LineSportHeader;
