import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { useNavigateUnauthenticated } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import useChatHeight from '../../hooks/useChatHeight';
import EventChat from '../EventChat';

import styles from './index.module.css';

type Props = {
  gameId: number;
};

const GameChat: ComponentWithConfig<unknown, Props> = ({ gameId, config }) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const { navigateToLogin } = useNavigateUnauthenticated();
  const { isAuthenticated } = useIsAuthenticated();
  const { ref, chatHeight } = useChatHeight(60);

  const handleToLogin = () => navigateToLogin();

  if (isAuthenticated) {
    return (
      <div ref={ref} style={{ flexBasis: chatHeight }} className={styles.wrap}>
        <FlexBox className={styles.root} key={gameId.toString()}>
          <EventChat roomId={gameId.toString()} config={config} />
        </FlexBox>
      </div>
    );
  }

  return (
    <FlexBox
      className={styles.root}
      align={FLEX_ALIGN.center}
      direction={FLEX_DIRECTION.column}
      justify={FLEX_JUSTIFY.center}
    >
      <Button intent={BUTTON_INTENT.secondary} onClick={handleToLogin}>
        {t('sportsbook.event.chat.login.button')}
      </Button>
    </FlexBox>
  );
};

export default GameChat;
