import Element from 'ltx/src/Element';

import { IQ_TYPE, XML_NAMESPACES } from '@monorepo/xmpp';

import { MAM_QUERY_STATUS } from '../../../../constants';
import { parseRsm } from '../../../../utils/stanzaParsers';
import IqHandler from '../IqHandler';

class IqMamHandler {
  iqHandler: IqHandler;

  constructor(handler: IqHandler) {
    this.iqHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { updateMamQuery } = this.iqHandler.reduxActions;
    const { type, id } = stanza.attrs;

    if (type === IQ_TYPE.RESULT) {
      const fin = stanza.getChild('fin');
      const { complete } = fin?.attrs || {};
      const set = fin?.getChild('set', XML_NAMESPACES.RSM);
      const { first, last, count } = set ? parseRsm(set) : {};

      updateMamQuery({
        id,
        first,
        last,
        count,
        status:
          complete === 'true'
            ? MAM_QUERY_STATUS.COMPLETE
            : MAM_QUERY_STATUS.LOADED
      });
    }
  };
}

export default IqMamHandler;
