import { TProfileCustomButtonMenuItem } from '@monorepo/auth';
import { useProfileCustomButton } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { TMenuItem } from '@monorepo/ui-kit';

export default function useCustomButton(): TMenuItem | undefined {
  const {
    // TS error because of incorrect settings file in hook's used as type
    preferablesCustomButton: { customButtonsList },
    generalRoutes
  } = useSiteSettings();
  const { t } = useI18n([NAMESPACES.ACCOUNT, NAMESPACES.COMMON]);

  const { data: customButtonId } = useProfileCustomButton();

  let item: TMenuItem | undefined;

  if (customButtonId > 0) {
    const foundItem = (
      customButtonsList as Array<TProfileCustomButtonMenuItem>
    ).find(({ id }) => id === customButtonId);

    if (foundItem)
      item = {
        id: foundItem.id,
        title: t(foundItem.titleKey),
        link: foundItem.menuLink,
        iconName: foundItem.icon,
        items: []
      } as TMenuItem;
  }

  return (
    item ||
    ({
      id: 'custom',
      title: t('navigation.custom', {
        ns: NAMESPACES.COMMON
      }),
      link: generalRoutes.profilePreferablesCustom,
      iconName: 'edit_0',
      items: []
    } as TMenuItem)
  );
}
