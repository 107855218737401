import { TRosterItem } from '../../../types';
import useSelectRoster from '../useSelectRoster';

const useSelectRosterItem = (
  userId: string
): TRosterItem | undefined | null => {
  const roster = useSelectRoster();

  return roster ? roster[userId] : null;
};

export default useSelectRosterItem;
