import { createRoom } from '@monorepo/xmpp';

import { TCreateRoomRequest } from '../../../types';
import { parseStanzaTypeError } from '../../../utils/stanzaParsers';

const create = () => ({
  query: ({ roomName, userId }: TCreateRoomRequest) =>
    createRoom(roomName, userId),
  transformResponse: () => null,
  transformErrorResponse: parseStanzaTypeError
});

export default create;
