import React, { ReactElement } from 'react';

import { CustomFC } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox, Skeleton } from '@monorepo/ui-kit';

import styles from './index.module.css';

const MenuItemSkeleton: CustomFC = (): ReactElement => (
  <FlexBox
    align={FLEX_ALIGN.center}
    justify={FLEX_JUSTIFY.between}
    className={styles.root}
  >
    <Skeleton className={styles.icon} />
    <Skeleton className={styles.label} />
    <Skeleton className={styles.count} />
  </FlexBox>
);

export default MenuItemSkeleton;
