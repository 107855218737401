import React from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { Checkbox, FIELD_TONE } from '@monorepo/ui-kit';

import { TFormField } from '../../types';
import Control from '../Control';

type InputProps<TFormValues> = TFormField<TFormValues>;

const CheckboxField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  id,
  name,
  label,
  hint,
  disabled = false,
  className = '',
  inputClassName = '',
  errorClassName = '',
  rules = {},
  toneType = FIELD_TONE.dark,
  errorTranslationNS
}: InputProps<TFormValues>) => {
  const { register }: UseFormReturn<TFormValues> =
    useFormContext<TFormValues>();

  const requiredLabel = Object.hasOwn(rules, 'required');

  return (
    <Control<TFormValues>
      id={id}
      name={name}
      hint={hint}
      toneType={toneType}
      className={className}
      errorClassName={errorClassName}
      errorTranslationNS={errorTranslationNS}
      requiredLabel={requiredLabel}
    >
      <Checkbox
        className={inputClassName}
        id={id}
        label={label}
        disabled={disabled}
        toneType={toneType}
        {...register(name, { ...rules })}
      />
    </Control>
  );
};

export default CheckboxField;
