import React, { lazy, Suspense } from 'react';

import { ProfileSkeleton } from '@monorepo/common';

const EditProfileVerifyAccount = lazy(
  () => import('./EditProfileVerifyAccount')
);

export default ({ config }: any) => (
  <Suspense fallback={<ProfileSkeleton />}>
    <EditProfileVerifyAccount config={config} />
  </Suspense>
);
