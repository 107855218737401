import React from 'react';

import { CustomFC } from '@monorepo/type';

import ComponentsConfigContext, {
  TComponentsConfigValues
} from '../../context/ComponentsConfigContext';

type Props = {
  params?: TComponentsConfigValues;
};

const ComponentsConfigProvider: CustomFC<Props> = ({
  children,
  params = {}
}) => (
  <ComponentsConfigContext.Provider value={params}>
    {children}
  </ComponentsConfigContext.Provider>
);

export default ComponentsConfigProvider;
