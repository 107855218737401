const COMPONENT_TYPES = {
  WALLET_DEPOSIT: 'wallet-deposit',
  WALLET_DEPOSIT_MOBILE: 'wallet-deposit-mobile',
  WALLET_WITHDRAW: 'wallet-withdraw',
  WALLET_WITHDRAW_MOBILE: 'wallet-withdraw-mobile',
  WALLET_WITHDRAW_STATUS: 'wallet-withdraw-status',
  WALLET_WITHDRAW_STATUS_MOBILE: 'wallet-withdraw-status-mobile',
  WALLET_HISTORY: 'wallet-history',
  WALLET_FILTERS: 'wallet-filters'
};

export default COMPONENT_TYPES;
