import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { googleAnalytics } from '@monorepo/helpers';

import { TAuthLoginRequestV2, TAuthLoginResponse } from '../types';

import { actions } from './index';

const staggeredQuery = retry(
  fetchBaseQuery({
    baseUrl: `/api/v2/auth/player`
  }),
  { maxRetries: 3 }
);

export const authLoginApiV2 = createApi({
  reducerPath: 'authLoginApiV2',
  baseQuery: staggeredQuery,
  endpoints: (builder) => ({
    requestAuthLogin: builder.mutation<TAuthLoginResponse, TAuthLoginRequestV2>(
      {
        query: ({ jwt, partnerId }) => ({
          method: 'POST',
          url: '',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: { jwt, partner_id: partnerId }
        }),
        extraOptions: { maxRetries: 3 },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          const {
            setIsForceReFetchAuthCookie,
            setJwtAuthToken,
            setMessengerAuthToken
          } = actions;
          try {
            const { data } = await queryFulfilled;
            dispatch(setJwtAuthToken(data.jwt_access));
            if (data.messenger_jwt) {
              dispatch(setMessengerAuthToken(data.messenger_jwt));
            }
            dispatch(setIsForceReFetchAuthCookie(false));
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            // ga  js-error
            const ga = googleAnalytics();
            ga.dispatch({
              event: ga.event.jsError,
              eventParam: {
                event_category: 'js'
              },
              event_options: {
                message: (e as any)?.message,
                data: e
              }
            });
          }
        }
      }
    ),
    requestLogout: builder.mutation<unknown, undefined>({
      query: () => ({
        method: 'DELETE',
        url: '',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { setIsCookiesFetched } = actions;
        try {
          await queryFulfilled;
          dispatch(
            setIsCookiesFetched({
              isCookiesFetched: false
            })
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          // ga  js-error
          const ga = googleAnalytics();
          ga.dispatch({
            event: ga.event.jsError,
            eventParam: {
              event_category: 'js'
            },
            event_options: {
              message: (e as any)?.message,
              data: e
            }
          });
        }
      }
    })
  })
});

export const { useRequestAuthLoginMutation, useRequestLogoutMutation } =
  authLoginApiV2;
