import { xml } from '@xmpp/client';

import { IQ_TYPE, SUBSCRIPTION_TYPE } from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';

import { getIqQuery } from './getIqQuery';

export const getRoster = () => getIqQuery(XML_NAMESPACES.ROSTER);

export const setRosterItem = (address: string, name: string) =>
  xml(
    'iq',
    { type: IQ_TYPE.SET },
    xml(
      'query',
      XML_NAMESPACES.ROSTER,
      xml('item', {
        jid: address,
        name
      })
    )
  );

export const removeRosterItem = (address: string) =>
  xml(
    'iq',
    { type: IQ_TYPE.SET },
    xml(
      'query',
      XML_NAMESPACES.ROSTER,
      xml('item', {
        jid: address,
        subscription: SUBSCRIPTION_TYPE.REMOVE
      })
    )
  );
