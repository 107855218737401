import BaseSocketClient from './BaseSocketClient';
import openSocketConnection from './openSocketConnection';

class MessengerSocketClient extends BaseSocketClient {
  handleMessage = (messageData: Record<string, any>) => {
    const { data, [this.requestIdProperty]: event } = messageData;
    this.subscribers.forEach((callback, subid) => {
      if (event === subid) {
        callback(data);
      }
    });
  };

  checkPong = (data: string) => {
    const parsedData = JSON.parse(data);
    return parsedData?.event === 'pong';
  };
}

const openMessengerSocket = () =>
  openSocketConnection({
    host: `${process.env.REACT_APP_MSGR_WS_HOST}`,
    isPing: true,
    pingDelay: 30000,
    pingMessage: { event: 'ping' },
    // withRequestId: false,
    requestIdProperty: 'event',
    ClientClass: MessengerSocketClient
  });

export default openMessengerSocket;
