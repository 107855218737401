import React from 'react';

import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';

import styles from './index.module.css';

const Spinner: Component = ({ className = '' }) => (
  <Icon name="spinner" className={`${styles.spinner} ${className}`} />
);

export default Spinner;
