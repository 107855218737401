import React from 'react';

import { useScrollToTop } from '@monorepo/common';
import { usePageContext } from '@monorepo/page';
import { classNameProp, CustomFC } from '@monorepo/type';
import {
  Box,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper,
  ScrollBarCustom,
  Sticky
} from '@monorepo/ui-kit';

import Column from '../Column';

import styles from './index.module.css';

const SportPage: CustomFC<classNameProp> = ({ className = '' }) => {
  const { page } = usePageContext();
  if (page) {
    const { layout } = page;
    const { columns, type } = layout;

    useScrollToTop();

    return (
      <PaddingWrapper isOnlyDesktop>
        <Box>
          <FlexBox
            className={`${styles[type] || ''} ${className}`}
            justify={FLEX_JUSTIFY.center}
            direction={FLEX_DIRECTION.row}
          >
            <Column
              key={columns[0].join('/')}
              column={columns[0]}
              className={styles.columnSide}
            />
            <Sticky className={styles.columnMenu}>
              <Column key={columns[1].join('/')} column={columns[1]} />
            </Sticky>
            <Column
              key={columns[2].join('/')}
              column={columns[2]}
              className={styles.columnMain}
            />
            <Sticky className={styles.columnCoupon}>
              <Box>
                <ScrollBarCustom props={{ noScrollX: true }}>
                  <Column key={columns[3].join('/')} column={columns[3]} />
                </ScrollBarCustom>
              </Box>
            </Sticky>
          </FlexBox>
        </Box>
      </PaddingWrapper>
    );
  }

  return null;
};

export default SportPage;
