import React from 'react';

import { Component } from '@monorepo/type';

import { TStoreNotification } from '../../types';

import notificationsMap from './notificationsMap';

const SocialNotification: Component<TStoreNotification> = ({
  type,
  ...props
}) => {
  const Notification = notificationsMap[type];

  if (Notification) {
    return <Notification type={type} {...props} />;
  }

  return null;
};

export default SocialNotification;
