import useSportsURLParams from '../useSportsURLParams';

const useCreateSportLink = (sportAlias?: string) => {
  const { rootPath } = useSportsURLParams();
  if (sportAlias) {
    return `${rootPath}/${sportAlias}`;
  }
  return `${rootPath}`;
};

export default useCreateSportLink;
