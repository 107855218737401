import isEmpty from 'lodash/isEmpty';

import { convertParams } from '@monorepo/helpers';

import { TGetPromotionsRequest } from '../../../types';
import formatGlobalFilters from '../../../utils/formatGlobalFilters';
import promotionsAdapter from '../adapters/promotionsAdapter';

const getPromotionsList = () => ({
  query: ({
    lang,
    partnerId,
    category,
    exclude,
    platform,
    useWebp,
    ...rest
  }: TGetPromotionsRequest) => {
    const params = {
      ...(category && { category }),
      ...(exclude && { exclude }),
      ...((platform || Number.isInteger(platform)) && { platform }),
      ...((useWebp || Number.isInteger(useWebp)) && { use_webp: useWebp }),
      ...formatGlobalFilters(rest)
    };
    const paramsString = isEmpty(params) ? '' : `?${convertParams(params)}`;

    return `${lang}/partners/${partnerId}/promotions${paramsString}`;
  },
  transformResponse: promotionsAdapter
});

export default getPromotionsList;
