import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';

import { ERROR_MESSAGE_SEPARATOR } from '../../constants';
import Error from '../Error';

import styles from './index.module.css';

type TErrorMessage = {
  message: string;
  errorClassName?: string;
  errorTranslationNS?: NAMESPACES;
};

const FormErrorMessage = ({
  message = '',
  errorClassName = '',
  errorTranslationNS = NAMESPACES.FORMS
}: TErrorMessage) => {
  const { t } = useI18n(errorTranslationNS);
  const [key, value] = message.split(ERROR_MESSAGE_SEPARATOR);

  return (
    <Error className={`${styles.error} ${errorClassName}`}>
      {t(key, { value })}
    </Error>
  );
};

export default FormErrorMessage;
