import React from 'react';
import { NavLink } from 'react-router-dom';

import { useSiteSettings } from '@monorepo/site-settings';
import useGetBetsCount from '@monorepo/sports/src/hooks/useGetBetsCount';
import { Count } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TItem = {
  title: string;
  link: string;
  className?: string;
  replace?: boolean;
};

const Item = ({ title, link, className = 'menu', replace = false }: TItem) => {
  const { generalRoutes } = useSiteSettings();
  const { myBetsHistory } = generalRoutes;
  const countBets = useGetBetsCount(); // TODO переписать, чтоб useGetBetsCount вызывался только, если есть пункт меню myBetsHistory
  const countBlock =
    link === myBetsHistory ? (
      <Count count={countBets} className={styles.count} />
    ) : null;
  return (
    <li key={title} className={`${styles.item} ${className}`}>
      <NavLink
        to={link}
        replace={replace}
        className={({ isActive }) =>
          isActive
            ? `${styles.link} ${styles.active} ${styles[className]}`
            : `${styles.link} ${styles[className]}`
        }
      >
        {title}
        {countBlock}
      </NavLink>
    </li>
  );
};
export default Item;
