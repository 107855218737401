import { TGeoIp } from '../../../types';

const getGeo = () => ({
  query: () => '',
  transformResponse(response: TGeoIp) {
    const { countryCode = undefined } = response;
    return { countryCode };
  }
});
export default getGeo;
