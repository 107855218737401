import React from 'react';

import { useGetUser } from '@monorepo/auth';
import { useConvertAmount } from '@monorepo/common';
import { NAMESPACES, useCurrencyTransform, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { useGetWithdrawableBalanceQuery } from '../../../store/wallet';

import styles from './index.module.css';

const WithdrawBalance: CustomFC = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { data: profile } = useGetUser();
  const { data } = useGetWithdrawableBalanceQuery(undefined);
  const {
    limit = null,
    unplayedAmount = null,
    availableBalance = null
  } = data || {};
  const { currencyName = '' } = profile || {};
  const currencyTransformed = useCurrencyTransform(currencyName);
  const availableBalanceAmount = useConvertAmount(availableBalance || 0);
  const limitAmount = useConvertAmount(limit || 0);
  const unPlayedAmount = useConvertAmount(unplayedAmount || 0);

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
        className={styles.amountWrap}
      >
        <Box className={styles.wrapText}>{t('wallet.withdrawable.amount')}</Box>
        <span className={styles.amount}>
          {availableBalanceAmount} {currencyTransformed}
        </span>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
        className={styles.balanceWrap}
      >
        <Box className={styles.wrapText}>
          {t('wallet.withdrawable.balance')}
        </Box>
        <span className={styles.balance}>
          {limitAmount} {currencyTransformed}
        </span>
      </FlexBox>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
        className={styles.unplayedWrap}
      >
        <Box className={styles.wrapText}>{t('wallet.unplayed.amount')}</Box>
        <span className={styles.amount}>
          {unPlayedAmount} {currencyTransformed}
        </span>
      </FlexBox>
    </FlexBox>
  );
};

export default WithdrawBalance;
