import { xml } from '@xmpp/client';

import { MESSAGES_TYPES } from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';
import { TCreateMessage } from '../../types';

export const messageCreator = ({
  id,
  text,
  to,
  type = MESSAGES_TYPES.SINGLE
}: TCreateMessage) => {
  const message = xml('message', { type, to, id }, xml('body', {}, text));
  if (type === MESSAGES_TYPES.SINGLE) {
    message.append(xml('request', XML_NAMESPACES.RECEIPTS));
  }
  return message;
};

export const messageReceived = (to: string, id: string) =>
  xml(
    'message',
    { to },
    xml('received', { xmlns: XML_NAMESPACES.RECEIPTS, id })
  );
