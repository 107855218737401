import { convertParams } from '@monorepo/helpers';

import { TGetGamesRequest } from '../../../types';

const getGames = ({
  partnerId,
  isMobile,
  categoryId,
  provider,
  limit,
  search,
  count,
  except,
  gameId,
  externalId,
  byKey,
  id,
  useWebp,
  lang,
  currentPage = 1,
  jackpotId,
  country,
  requestedQuery,
  extraCategoryId
}: TGetGamesRequest) => {
  const params = {
    partner_id: partnerId,
    lang,
    country
  };
  if (isMobile) {
    params.is_mobile = isMobile;
  }
  if (categoryId) {
    params.category = categoryId;
  }
  if (provider) {
    params.provider = provider;
  }
  if (byKey) {
    params.by_key = byKey;
  }

  if (limit) {
    params.limit = currentPage * limit;
    params.offset = currentPage === 1 ? 0 : currentPage * limit - limit;
  }
  if (search) {
    params.search = search;
  }
  if (count) {
    params.count = count;
  }
  if (except) {
    params.except = except;
  }
  if (gameId) {
    params.id = gameId;
  }
  if (id) {
    params.id = id;
  }
  if (externalId) {
    params.external_id = externalId;
  }
  if (useWebp) {
    params.use_webp = useWebp;
  }
  if (jackpotId) {
    params.jackpot_id = jackpotId;
  }
  if (extraCategoryId) {
    params.extra_category_id = extraCategoryId;
  }

  const query = requestedQuery || 'getGames';

  return `/${query}?${convertParams(params)}`;
};

export default getGames;
