import { ValidationValue } from 'react-hook-form';

import { ERROR_MESSAGE_SEPARATOR } from '../../../constants';

const createErrorWithValue = (
  message: string,
  value: ValidationValue,
  currency?: string
) =>
  `${message}${ERROR_MESSAGE_SEPARATOR}${value}${
    currency ? ` ${currency}` : ''
  }`;

export default createErrorWithValue;
