import { memoize } from 'proxy-memoize';

import { selectIsGameExist } from '../../store/api/selectors/gameSelector';
import useGetGame from '../useGetGame';

type TGameExistsResponse = {
  data: boolean;
  isFetching: boolean;
};

const useIsGameExists = (
  gameId: number,
  competitionId?: number
): TGameExistsResponse =>
  useGetGame(gameId, competitionId, {
    selectFromResult: memoize((result: any) => ({
      isFetching: result?.isFetching,
      data: selectIsGameExist(result)
    }))
  });

export default useIsGameExists;
