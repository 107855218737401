import React from 'react';

import { useGetUser } from '@monorepo/auth';
import {
  AmountField,
  amountFieldValidator,
  requiredValidator,
  useChangeSeparator
} from '@monorepo/forms';
import { NAMESPACES, useCurrencyTransform, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { Box, FlexBox } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../../../constants';
import { TFormFieldValues, TPaymentFormField } from '../../../../../types';

import styles from './index.module.css';

type Props = {
  field: TPaymentFormField;
  min?: string | number;
  max?: string | number;
  serviceType: SERVICE_TYPES;
};

const PaymentsAmountField: Component<Props> = ({
  max,
  min,
  field,
  serviceType
}) => {
  const { fieldKey, type } = field;
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { amountMaskToNumber } = useChangeSeparator();
  const { data } = useGetUser();
  const currencyTransformed = useCurrencyTransform(data?.currencyName);

  return (
    <FlexBox>
      <Box className={styles.label}>{t(`wallet.${fieldKey}`)}</Box>
      <AmountField<TFormFieldValues>
        id={fieldKey}
        className={styles.depositAmount}
        name={fieldKey}
        type={type}
        inputClassName={styles.input}
        errorTranslationNS={NAMESPACES.ACCOUNT}
        rules={{
          ...requiredValidator('wallet.wrong.amount'),
          ...amountFieldValidator(
            {
              min: {
                value: min,
                message: `wallet.${serviceType}.wrong.min`
              },
              max: {
                value: max,
                message: `wallet.${serviceType}.wrong.max`
              }
            },
            amountMaskToNumber
          )
        }}
      />
      <span className={styles.currency}>{currencyTransformed}</span>
    </FlexBox>
  );
};

export default PaymentsAmountField;
