import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { useOnClickOutside } from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { ActionButton } from '@monorepo/ui-kit';

import getHostName from '../../utils/getHostName';

import ShareBlock from './components/ShareBlock';

import styles from './index.module.css';

type Props = {
  title: string;
  url: string;
  icon?: string;
  size?: 'small' | 'medium' | 'big';
  dataCustom?: string;
};

const ShareButton: Component<Props> = ({
  className = '',
  title,
  url,
  icon = 'share-0',
  size = 'medium',
  dataCustom
}) => {
  const location = getHostName();
  const fullUrl = location + url;
  const dropElement = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: cssStyle, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }]
    }
  );

  const toggleOpen = () => {
    setIsOpen((prev: boolean) => !prev);
  };

  const clickSelf = (event: React.MouseEvent) => {
    if (referenceElement === event.target) {
      return;
    }

    if (dropElement?.current?.contains(event?.target as Node)) {
      return;
    }
    setIsOpen(false);
  };

  useOnClickOutside<HTMLDivElement>({ current: referenceElement }, (event) =>
    clickSelf(event)
  );
  const onClosePopper = () => setIsOpen(false);

  return (
    <div className={styles.box}>
      <ActionButton
        ref={setReferenceElement}
        iconName={icon}
        className={className}
        onClick={toggleOpen}
        size={size}
        data-custom={dataCustom}
      />
      {isOpen && (
        <div ref={dropElement}>
          <div
            ref={setPopperElement}
            {...attributes.popper}
            className={styles.wrap}
            style={cssStyle.popper}
          >
            <ShareBlock
              title={title}
              url={fullUrl}
              onClosePopper={onClosePopper}
            />
            <div
              ref={setArrowElement}
              style={cssStyle.arrow}
              className={styles.triangle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
