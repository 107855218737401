import { TNotificationContext } from '../../types';

const initialState: TNotificationContext = [
  {
    isShown: false,
    notificationType: null,
    message: '',
    timeout: 5000,
    successTimeout: 8000
  },
  (value) => value
];

export default initialState;
