import React from 'react';

import { useCurrencyTransform } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { TWinners } from '../../../../../../../types';

import styles from './index.module.css';

type TWinnersItem = {
  winners: TWinners;
};

const WinnersItem: CustomFC<TWinnersItem> = ({ winners }) => {
  const { icon2, icon3, playerUserName, winAmount, currencyId, gameName } =
    winners;
  const icon = icon2 || icon3;
  const transformedValue = useCurrencyTransform(currencyId);

  return (
    <FlexBox align={FLEX_ALIGN.center} justify={FLEX_JUSTIFY.between}>
      <FlexBox align={FLEX_ALIGN.center}>
        <img src={icon} alt="" className={styles.img} />
        <FlexBox direction={FLEX_DIRECTION.column}>
          <span className={styles.player}>{playerUserName}</span>
          <span className={styles.game}>{gameName}</span>
        </FlexBox>
      </FlexBox>
      <span
        className={styles.amount}
      >{`${winAmount} ${transformedValue}`}</span>
    </FlexBox>
  );
};

export default WinnersItem;
