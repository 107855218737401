import { useEffect } from 'react';

import {
  useChangeSessionLanguageMutation,
  useGetUser,
  useIsAuthenticated
} from '@monorepo/auth';
import { usePrevious } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';

import useI18n from '../useI18n';

const useLanguage = () => {
  const { language: storedLang, i18n } = useI18n();
  document.body.dir = i18n.dir(storedLang);
  const {
    defaultLanguage,
    languages,
    isUseProfileLang = true
  } = useSiteSettings();

  const { data: profile } = useGetUser();
  const { language: profileLanguage } = profile || {};
  const [changeSessionLang] = useChangeSessionLanguageMutation();
  const { isAuthenticated, isAuthFetched } = useIsAuthenticated();
  const prevProfileLang = usePrevious(profileLanguage);

  useEffect(() => {
    const browserLang = languages.find((lang) => storedLang.includes(lang));
    const lng = browserLang || defaultLanguage;
    if (storedLang !== lng) {
      changeSessionLang(browserLang || defaultLanguage);
      i18n.changeLanguage(browserLang || defaultLanguage);
      document.body.dir = i18n.dir(browserLang || defaultLanguage);
    }
  }, []);

  useEffect(() => {
    if (
      isUseProfileLang &&
      isAuthenticated &&
      profileLanguage &&
      !prevProfileLang &&
      profileLanguage !== storedLang
    ) {
      changeSessionLang(profileLanguage || storedLang);
      i18n.changeLanguage(profileLanguage || storedLang);
      document.body.dir = i18n.dir(profileLanguage || storedLang);
    }
  }, [isAuthenticated, isAuthFetched, profileLanguage, storedLang]);
};

export default useLanguage;
