import React, { forwardRef } from 'react';

import { FIELD_TONE } from '../../../constants';
import { RadioProps } from '../../../types';
import Label from '../Label';

import styles from './index.module.css';

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id,
      label = '',
      className = '',
      labelClass = '',
      toneType = FIELD_TONE.dark,
      disabled,
      ...props
    },
    ref
  ) => {
    const classes = `${styles.wrap}
      ${styles[toneType]}
      ${disabled ? styles.disabled : ''}
      ${className}`;

    return (
      <Label className={classes} htmlFor={id}>
        <input
          id={id}
          type="radio"
          className={styles.input}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        <span className={styles.radio} />
        {Boolean(label) && (
          <div className={`${styles.label} ${labelClass}`}>{label}</div>
        )}
      </Label>
    );
  }
);

export default Radio;
