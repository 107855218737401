import { TGame, TNormalizedGame } from '../../types';

const normalizeGame = (game: TGame): TNormalizedGame => {
  const {
    name,
    extearnal_game_id: gameId,
    icon_1: img1,
    icon_2: img2,
    icon_3: img3,
    badge,
    types
  } = game;
  const { realMode = 0, funMode = 0 } = types;

  return {
    name,
    gameId,
    badge,
    img: img3 || img2 || img1,
    isRealMode: Boolean(realMode),
    isFunMode: Boolean(funMode)
  };
};

export default normalizeGame;
